import immutable from 'seamless-immutable';
import { Type as ClientType } from '../../actions/control_panel/ClientActions';
export const INITIAL_STATE = immutable({
    clientPosting: false,
    clientPostingSuccess: false,
    clientPostingFailed: false,

    client: [],
    clientObj: {},
    clientFetching: false,
    clientPages: 1,

    regionType: [],
    regionTypeFetching: false,
    regionTypeFetchingFailed: false,

    clientGroupType: [],
    clientGroupTypeFetching: false,
    clientGroupTypeFetchingFailed: false,

    seasonType: [],
    seasonTypeFetching: false,
    seasonTypeFetchingFailed: false,

    deleted: false,
    deleteClientFailed: false,
});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case ClientType.SET_INITIAL_STATE: {
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;
        }

        case ClientType.ADD_CLIENT_CALL: {
            const clientPosting = true;
            return state.merge({ clientPosting });
            break;
        }

        case ClientType.ADD_CLIENT_SUCCESS: {
            const clientPosting = false;
            const clientPostingSuccess = true;
            return state.merge({ clientPostingSuccess, clientPosting, clientObj: INITIAL_STATE.clientObj })
            break;
        }

        case ClientType.ADD_CLIENT_FAILED: {
            const clientPosting = false;
            const clientPostingFailed = true;
            return state.merge({ clientPosting, clientPostingFailed });
            break;
        }

        case ClientType.GET_CLIENT_READ_CALL: {
            const clientFetching = true;
            return state.merge({ clientFetching });
            break;
        }

        case ClientType.GET_CLIENT_READ_SUCCESS: {
            const client = action.data.data;
            const clientFetching = false;
            const clientPages = action.data.total_pages
            return state.merge({ client, clientFetching, clientPages });
            break;
        }

        case ClientType.GET_CLIENT_READ_FAILED: {
            const clientFetchingFailed = true;
            const clientFetching = false;
            return state.merge({ clientFetching, clientFetchingFailed });
            break;
        }

        case ClientType.GET_REGION_TYPE_CALL: {
            const regionTypeFetching = true;
            return state.merge({ regionTypeFetching });
            break;
        }

        case ClientType.GET_REGION_TYPE_SUCCESS: {
            const regionType = action.data.data;
            const regionTypeFetching = false;
            return state.merge({ regionType, regionTypeFetching });
            break;
        }

        case ClientType.GET_REGION_TYPE_FAILED: {
            const regionTypeFetchingFailed = true;
            const regionTypeFetching = false;
            return state.merge({ regionTypeFetching, regionTypeFetchingFailed });
            break;
        }

        case ClientType.GET_CLIENTGROUP_TYPE_CALL: {
            const clientGroupTypeFetching = true;
            return state.merge({ clientGroupTypeFetching });
            break;
        }

        case ClientType.GET_CLIENTGROUP_TYPE_SUCCESS: {
            const clientGroupType = action.data.data;
            const clientGroupTypeFetching = false;
            return state.merge({ clientGroupType, clientGroupTypeFetching });
            break;
        }

        case ClientType.GET_CLIENTGROUP_TYPE_FAILED: {
            const clientGroupTypeFetchingFailed = true;
            const clientGroupTypeFetching = false;
            return state.merge({ clientGroupTypeFetching, clientGroupTypeFetchingFailed });
            break;
        }

        case ClientType.GET_SEASON_TYPE_CALL: {
            const seasonTypeFetching = true;
            return state.merge({ seasonTypeFetching });
            break;
        }

        case ClientType.GET_SEASON_TYPE_SUCCESS: {
            const seasonType = action.data.data;
            const seasonTypeFetching = false;
            return state.merge({ seasonType, seasonTypeFetching });
            break;
        }

        case ClientType.GET_SEASON_TYPE_FAILED: {
            const seasonTypeFetchingFailed = true;
            const seasonTypeFetching = false;
            return state.merge({ seasonTypeFetching, seasonTypeFetchingFailed });
            break;
        }

        case ClientType.DELETE_CLIENT_SUCCESS: {
            const array = state['client'].asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ client: array, deleted: deleted })
            break;
        }

        case ClientType.DELETE_CLIENT_FAILED: {
            const deleteClientFailed = true;
            return state.merge({ deleteClientFailed });
            break;
        }

        case ClientType.SET_CLIENT_ROW_IN_REDUCER: {
            const data = action.rowInfo;
            return state.merge({ clientObj: data })
            break;
        }

        default:
            return state;
    }
}