import React from "react";
import { connect } from 'react-redux';
// import { Link, navigate } from "gatsby"
import i18n from '../../i18n/i18n';
import {
    IconButton, Dialog, DialogContent, DialogTitle, DialogContentText
} from '@material-ui/core';
import { TestingById } from '../../actions/SuperAdminActions';
import { withStyles } from '@material-ui/core/styles';
import { FormGroup, FormControlLabel, Typography, RadioGroup, Radio, Button } from '@material-ui/core';
import { toast } from 'react-toastify';

const styles = theme => ({
    formContainer: {
        backgroundColor: '#D7F0CA',
        padding: '12px 12px'
    },
    question: {
        backgroundColor: '#F1C232',
        padding: '12px 12px'
    },
    answer: {
        backgroundColor: '#FF0000',
        padding: '12px 12px'
    },
    typography: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '15px',
        fontSize: '15px'
    },
    radio: {
        padding: 10
    },
    buttons: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center'
    },
    button: {
        margin: 20
    }
});

class ViewTest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            answers: {}
        }
    }
    componentDidMount() {
        const { TestingById, id_klijent_vlasnik } = this.props;
        TestingById(id_klijent_vlasnik);
    }


    // changeFormControl = (question, e) => {
    //     var answers = { ...this.state.answers };
    //     answers[question] = e.target.value
    //     this.setState({ answers })
    // }

    render() {
        const { form, classes, handleClose, add, edit } = this.props;
        const { answers } = this.state;
        const valuesForReturn = form.length > 0 && form.map((item) => {
            console.log('VVVVV', item)
            if (!item) return null;
            return <div className={classes.formContainer}>
                <Typography className={classes.typography}>{'- ' + item.naziv}</Typography>
                <Typography className={item.tacan_odgovor ? classes.question : classes.answer}>{item.odgovor}</Typography>

            </div>
        })
        return (
            <div className={classes.formContainer} >
                <Dialog
                    open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('View test')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {valuesForReturn}
                    </DialogContent>
                </Dialog>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        form: state.superAdminReducer.form
    };
}
function mapDispatchToProps(dispatch) {
    return {
        TestingById: (id) => dispatch(TestingById(id)),
    };
}
export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewTest));
