import React from "react";
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
    Paper, Table, TableBody, TableCell, TableHead, TableRow, Fab, IconButton, Tooltip,
    GridList, GridListTile, GridListTileBar,
} from '@material-ui/core';
import i18n from '../../i18n/i18n';
import {
    setInitialState, getTicketItemRead, deleteTicketItem,
    lockTicketItem, deleteItemDocument
} from '../../actions/TicketActions';
import moment from 'moment';
import * as Icons from '@material-ui/icons';
import { Delete } from '../../utils/Delete';
import AddTicketItem from './AddTicketItem';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DocumentGrid from '../../utils/DocumentGrid';
const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        height: '20px',
        fontWeight: '500',
        fontSize: 15
    },
    body: {
        fontSize: 13,
        maxWidth: '230px',
        wordWrap: 'break-word'
    },
    root: {
        padding: '5px'
    }
}))(TableCell);

const styles = theme => ({
    item: {
        marginLeft: '35px',
        padding: '10px',
        background: '#d8d8d7',
        width: '97%',
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between'
    },
    paper: {
        width: '20%',
        padding: '10px'
    },
    paper2: {
        padding: '10px',
        width: '79%',
        position: 'relative'
    },
    tableRow: {
        height: '35px'
    },
    fab: {
        position: 'absolute',
        bottom: 2,
        right: 2,
    },
    cssRoot: {
        color: '#e50000',
        '&:hover': {
            color: '#ff0000',
        },
    },

});
class TicketItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            delete: false,
            add: false,
            edit: false,
            selection: {},
            disableAddAndLock: false,
            user: {}
        };
    };

    componentDidMount() {
        const { row } = this.props;
        this.props.getTicketItemRead(row.id);
        // this.props.getUser();

    };

    handleClick = () => {
        const { ticketItems } = this.props;
        if (this.state.edit == true && this.state.add == false) {
            this.setState({ edit: false, selection: {} });
        } else if (this.state.selection !== {} && this.state.add == false && this.state.edit == false) {
            this.setState({ selection: {} });
        }
        if (this.state.disable) {
            this.setState({ disable: false })
        }

        this.setState({
            add: !this.state.add,
            disableAddAndLock: ticketItems.filter((item, index) => item.zakljucan === false).length !== 0 ? true : false,
            selection: ticketItems.length !== 0 && ticketItems[ticketItems.length - 1].id_tiket_status === 3 ? { id_tiket_status: 6 } : {}
        });
    };

    onEditClick(item, i) {
        const { ticketItems } = this.props;
        this.setState({
            add: !this.state.add,
            edit: true,
            selection: item,
            disableAddAndLock: ticketItems.filter((item, index) => item.zakljucan === false && index < i).length !== 0 ? true : false
        });
    }

    onEditClickAdmin(item, i) {
        const { ticketItems } = this.props;
        this.setState({
            add: !this.state.add,
            edit: true,
            selection: item,
            disable: true,
            disableAddAndLock: true
        });
    }


    onDeleteClick = (item) => {
        this.setState({ delete: !this.state.delete, selection: item });
    }

    deleteItem = () => {
        this.props.deleteTicketItem(this.state.selection.id);
        this.setState({ delete: false })
    }

    onLockClick = (ticketItem, i) => {
        let user = JSON.parse(localStorage.getItem('user'));
        const { ticketItems } = this.props;
        if (ticketItem.zakljucan === true && user.id_klijent_grupa !== 12) {
            toast.error(i18n.t("You can't unlock the item!"), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        } else if (ticketItems.filter((item, index) => {
            return item.zakljucan === false && index < i
        }).length !== 0) {
            toast.error(i18n.t("You can't lock an item before the previous ones are locked!"), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        } else this.props.lockTicketItem(ticketItem, this.props.row.id);
    }

    render() {
        let user = JSON.parse(localStorage.getItem('user'));
        const { classes, row, ticketItems } = this.props;
        const ticketItemsRender = ticketItems && ticketItems.map((item, i) => {
            if (!item) return null;
            return <TableRow key={i}>
                <CustomTableCell>{item.korisnik && item.korisnik}</CustomTableCell>
                <CustomTableCell>{item.opis && item.opis}</CustomTableCell>
                <CustomTableCell>{item.tiket_status && item.tiket_status}</CustomTableCell>
                <CustomTableCell>{item.vreme_kreiranja && moment(item.vreme_kreiranja).format('H:mm / DD-MM-YYYY')}</CustomTableCell>
                <CustomTableCell>{item.prioritet && item.prioritet}</CustomTableCell>
                <CustomTableCell>{item.zaduzena_osoba && item.zaduzena_osoba}</CustomTableCell>
                <CustomTableCell>{item.ocekivan_datum && moment(item.ocekivan_datum).format('DD-MM-YYYY')}</CustomTableCell>
                <CustomTableCell>{`${item.vreme_sat || '0'}h ${item.vreme_min || '0'}min`}</CustomTableCell>
                <CustomTableCell style={{ minWidth: '160px' }}>
                    {item.dokument &&
                        <DocumentGrid
                            item={item}
                            Controller='ticket_item'
                            showDelete={!item.zakljucan}
                            deleteDoc={(item) => this.props.deleteItemDocument(item)}
                        />}
                </CustomTableCell>
                <CustomTableCell style={{ width: '185px' }}>
                    <Tooltip title={i18n.t("Edit")}>
                        <IconButton aria-label="edit"
                            disabled={item.zakljucan}
                            color="secondary"
                            onClick={() => this.onEditClick({ ...item, ocekivan_datum: item.ocekivan_datum && moment(item.ocekivan_datum).format('DD.MM.YYYY') }, i)}
                        >
                            <Icons.Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={i18n.t("Delete")}>
                        <IconButton aria-label="Delete"
                            disabled={item.zakljucan}
                            className={classes.cssRoot}
                            onClick={() => this.onDeleteClick(item, i)}
                        >
                            <Icons.Delete />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={item.zakljucan ? i18n.t("Unlock") : i18n.t("Lock")}>
                        <IconButton aria-label="Lock"
                            className={classes.cssRoot}
                            onClick={() => this.onLockClick(item, i)}
                        >
                            {item.zakljucan ?
                                <Icons.Lock /> :
                                <Icons.LockOpen />
                            }
                        </IconButton>
                    </Tooltip>
                    {user.id_klijent_grupa == 12 ?
                        <Tooltip title={i18n.t("Edit")}>
                            <IconButton aria-label="edit"
                                className={classes.cssRoot}
                                onClick={() => this.onEditClickAdmin(item, i)}
                            >
                                <Icons.Edit />
                            </IconButton>
                        </Tooltip>
                        : null}
                </CustomTableCell>
            </TableRow >
        })
        return (
            <div className={classes.item} >
                <Paper className={classes.paper}>
                    <strong>{i18n.t('Question')}:</strong>
                    <br />
                    {row.tiket_pitanje}
                </Paper>
                <Paper className={classes.paper2}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow className={classes.tableRow}>
                                <CustomTableCell>{i18n.t('User')}</CustomTableCell>
                                <CustomTableCell>{i18n.t('Description')}</CustomTableCell>
                                <CustomTableCell>{i18n.t('Status')}</CustomTableCell>
                                <CustomTableCell>{i18n.t('Time and date')}</CustomTableCell>
                                <CustomTableCell>{i18n.t('Priority')}</CustomTableCell>
                                <CustomTableCell>{i18n.t('In charge')}</CustomTableCell>
                                <CustomTableCell>{i18n.t('Expected date')}</CustomTableCell>
                                <CustomTableCell>{i18n.t('Time spent')}</CustomTableCell>
                                <CustomTableCell style={{ minWidth: '160px' }}>{i18n.t('Attachment')}</CustomTableCell>
                                <CustomTableCell style={{ width: '185px' }}>{i18n.t('Edit/Delete/Lock')}</CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ticketItemsRender}
                        </TableBody>
                    </Table>
                    <Fab
                        size="small"
                        color="primary"
                        aria-label="Delete"
                        className={classes.fab}
                        onClick={() => this.handleClick()}>
                        <span className="icon-Plus headerIcon" style={{ fontSize: 20 }} />
                    </Fab>
                </Paper>
                {this.state.add &&
                    <AddTicketItem
                        item={this.state.selection}
                        id_tiket={this.props.row.id}
                        add={this.state.add}
                        edit={this.state.edit}
                        disableAddAndLock={this.state.disableAddAndLock}
                        disable={this.state.disable}
                        handleClose={this.handleClick} />}
                {this.state.delete && <Delete delete={this.state.delete} handleClose={this.onDeleteClick} deleteItem={this.deleteItem} />}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        ticketItems: state.ticketReducer.ticketItems,
        ticketItemsFetching: state.ticketReducer.ticketItemsFetching,
        deletedItem: state.ticketReducer.deletedItem,
        deleteTicketItemFailed: state.ticketReducer.deleteTicketItemFailed,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        setInitialState: () => dispatch(setInitialState()),
        getTicketItemRead: (id) => dispatch(getTicketItemRead(id)),
        deleteTicketItem: (id) => dispatch(deleteTicketItem(id)),
        lockTicketItem: (item, id_tiket) => dispatch(lockTicketItem(item, id_tiket)),
        deleteItemDocument: (item) => dispatch(deleteItemDocument(item))
        // getUser: () => dispatch(getUser()),

    };
}
export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(TicketItem));