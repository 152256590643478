import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import 'react-table/react-table.css';
import {
    getProgramRead, getInstitution, getProgramType, getSectorType, getEnterpriesType,
    setInitialState, setRowInReducer, deleteProgram, getProgramDocuments
} from '../../../actions/financial/ProgramActions';
import i18n from '../../../i18n/i18n';
import Select from 'react-select';
import immutable from 'seamless-immutable';
import { Fab } from '@material-ui/core';
import DatePickerForFilter from '../../../utils/DatePickerForFilter';
import AddProgram from './AddProgram';
import { Delete } from '../../../utils/Delete';
import { exportDocument } from '../../../actions/ExportActions';
import Export from '../../../utils/Export';
import DashboardDrawer from '../../../utils/DashboardDrawer';
import moment from 'moment';
const CheckboxTable = checkboxHOC(ReactTable);;

export class ViewProgram extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            add: false,
            selection: [],
            selectAll: false,
            edit: false,
            delete: false,
        };
        this.tableScrollTop = 0;
    }

    componentDidMount() {
        if (this.props.programType.length == 0) {
            this.props.getProgramType();
        }
        if (this.props.sectorType.length == 0) {
            this.props.getSectorType();
        }
        if (this.props.institution.length == 0) {
            this.props.getInstitution();
        }
        if (this.props.enterpriesType.length == 0) {
            this.props.getEnterpriesType();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.deleted === true) {
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getProgramRead(wrapped.state);
            this.setState({
                selection: [],
                selectAll: false,
            })
            this.props.setInitialState('programObj');
            this.props.setInitialState('deleted');
        }
        if (nextProps.programPostingSuccess === true) {
            this.setState({
                selection: [],
                name: '',
                selectAll: false
            })
            this.handleClick();
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getProgramRead(wrapped.state);
            this.props.setInitialState('programPostingSuccess');
        }
        this.setState({
            program: nextProps.program,
            programFetching: nextProps.programFetching,
            enterpriesType: immutable.asMutable(nextProps.enterpriesType.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            programType: immutable.asMutable(nextProps.programType.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            programTypeFetching: nextProps.programTypeFetching,
            programTypeFetchingFailed: nextProps.programTypeFetchingFailed,
            sectorType: immutable.asMutable(nextProps.sectorType.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            sectorTypeFetching: nextProps.sectorTypeFetching,
            sectorTypeFetchingFailed: nextProps.sectorTypeFetchingFailed,
            institution: immutable.asMutable(nextProps.institution.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            institutionFetching: nextProps.institutionFetching,
            institutionFetchingFailed: nextProps.institutionFetchingFailed,
        })
    }



    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    onExportClick = () => {
        this.setState({ export: !this.state.export });
    }
    exportFunction = () => {
        const table = this.checkboxTable.getWrappedInstance();;
        this.props.exportDocument(this.state.docTitle, table.state, 'api/program/read', this.state.docFormat || 'xlsx');
        this.setState({ export: !this.state.export });
    }

    handleClick = () => {
        if (this.state.edit == true && this.state.add == false) {
            this.setState({ edit: false })
            this.props.setInitialState('programObj');
        } else if (this.state.selection.length > 0 && this.state.add == false && this.state.edit == false) {
            this.props.setInitialState('programObj');
        }
        this.setState({ add: !this.state.add }, () => {
            if (this.state.add == true && this.state.edit == false) {
                this.setState({
                    selection: [],
                    name: '',
                    selectAll: false,
                })
            }
        });
    };

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
            this.props.setInitialState('programObj');
        } else {
            selection.push(key);
        }
        this.setState({ selection }, function () {
            if (selection.length == 1) {
                let row1 = this.props.program.filter((prog) => {
                    return prog.id == selection;
                })
                this.props.setRowInReducer(row1[0], 'programObj');
            }
        });
    };
    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });
    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    onDeleteClick = () => {
        this.setState({ delete: !this.state.delete });
    }

    deleteItem = () => {
        this.props.deleteProgram(this.state.selection);
        this.setState({ delete: false })
    }

    onEditClick() {
        this.setState({ add: !this.state.add, edit: true });
        this.props.getProgramDocuments(this.state.selection[0]);
    }

    onPieClick = () => {
        this.props.history.push('/program_dashboard');
    }

    onTableClick = () => {
        this.props.history.push('/program');
    }


    render() {
        const columns = [
            {
                Header: i18n.t('Title'),
                accessor: 'naziv',
            },
            {
                Header: i18n.t('Type of programs'),
                accessor: 'naziv_vrsta_programa',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.programType}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Program Web link'),
                accessor: 'web_link',
                Cell: ({ value }) => <a href={value} target="_blank">{value}</a>
            },
            {
                Header: i18n.t('Institution'),
                accessor: 'naziv_institucija',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.institution}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Institution Web link'),
                accessor: 'institucija_link',
                Cell: ({ value }) => <a href={value} target="_blank">{value}</a>
            },
            {
                Header: i18n.t('Type of sector'),
                accessor: 'naziv_vrsta_sektora',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.sectorType}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                id: 'datum_pocetka',
                Header: i18n.t('Start date'),
                accessor: d => d.datum_pocetka ? moment(d.datum_pocetka, 'DD.MM.YYYY').format('DD.MM.YYYY') : '',
                Filter: ({ filter, onChange }) =>
                    <DatePickerForFilter
                        onChange={({ startDate, endDate }) => {
                            onChange({ startDate, endDate });
                        }}
                    />
            },
            {
                id: 'datum_zavrsetka',
                Header: i18n.t('Expiry date'),
                accessor: d => d.datum_zavrsetka ? moment(d.datum_zavrsetka, 'DD.MM.YYYY').format('DD.MM.YYYY') : '',
                Filter: ({ filter, onChange }) =>
                    <DatePickerForFilter
                        onChange={({ startDate, endDate }) => {
                            onChange({ startDate, endDate });
                        }}
                    />
            },
            {
                Header: i18n.t('Description'),
                accessor: 'opis'
            }

        ];
        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "#ACDAF1" : "inherit"
                        }
                    };
                }
                return {};
            }
        };
        return (
            <div className="page">
                <div className="table-header">
                    <Fab
                        onClick={() => this.handleClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-Plus headerIcon" />
                    </Fab>
                    <Fab
                        onClick={() => this.onExportClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-download headerIcon" />
                    </Fab>
                    <Fab
                        onClick={() => this.onSearchClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-search headerIcon" />
                    </Fab>

                    {this.state.selection.length > 0 ?
                        <Fab
                            onClick={() => this.onDeleteClick()}
                            color="secondary"
                            aria-label="add"
                            className="action-button"
                        >
                            <span className="icon-Kanta headerIcon" />
                        </Fab>
                        : null
                    }
                    {this.state.selection.length === 1 ?
                        <Fab
                            onClick={() => this.onEditClick()}
                            color="secondary"
                            aria-label="edit"
                            className="action-button"
                        >
                            <span className="icon-Izmeni1 headerIcon" />
                        </Fab>
                        : null
                    }
                </div>
                <CheckboxTable
                    ref={r => (this.checkboxTable = r)}
                    data={this.props.program}
                    getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                    columns={columns}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.props.programPages}
                    loading={this.props.programFetching}
                    manual
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => this.props.getProgramRead(state, instance)}
                    className="-striped -highlight table-drawer"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF',
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
                <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} />
                {this.state.export && <Export
                    export={this.state.export}
                    onTextChange={({ title }) => {
                        this.setState({ docTitle: title });
                    }}
                    changeFormat
                    onformatChange={(format) => {
                        this.setState({ docFormat: format });
                    }}
                    handleClose={this.onExportClick}
                    exportDoc={this.exportFunction} />
                }
                {this.state.add && <AddProgram add={this.state.add} edit={this.state.edit}
                    handleClose={this.handleClick} />}
                {this.state.delete && <Delete delete={this.state.delete} handleClose={this.onDeleteClick} deleteItem={this.deleteItem} />}

            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        program: state.programReducer.program,
        programFetching: state.programReducer.programFetching,
        programPages: state.programReducer.programPages,
        programType: state.programReducer.programType,
        programObj: state.programReducer.programObj,
        programPostingSuccess: state.programReducer.programPostingSuccess,
        deleted: state.programReducer.deleted,
        programTypeFetching: state.programReducer.programTypeFetching,
        programTypeFetchingFailed: state.programReducer.programTypeFetchingFailed,
        sectorType: state.programReducer.sectorType,
        sectorTypeFetching: state.programReducer.sectorTypeFetching,
        sectorTypeFetchingFailed: state.programReducer.sectorTypeFetchingFailed,
        institution: state.programReducer.institution,
        institutionFetching: state.programReducer.institutionFetching,
        institutionFetchingFailed: state.programReducer.institutionFetchingFailed,
        enterpriesType: state.programReducer.enterpriesType,
        enterpriesTypeFetching: state.programReducer.enterpriesTypeFetching,
        enterpriesTypeFetchingFailed: state.programReducer.enterpriesTypeFetchingFailed,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getProgramType: () => dispatch(getProgramType()),
        getSectorType: () => dispatch(getSectorType()),
        getInstitution: () => dispatch(getInstitution()),
        getEnterpriesType: () => dispatch(getEnterpriesType()),
        getProgramRead: (state, instance) => dispatch(getProgramRead(state, instance)),
        setInitialState: (component) => dispatch(setInitialState(component)),
        setRowInReducer: (rowInfo) => dispatch(setRowInReducer(rowInfo)),
        deleteProgram: (selection) => dispatch(deleteProgram(selection)),
        getProgramDocuments: (id) => dispatch(getProgramDocuments(id)),
        exportDocument: (title, state, url, format) => dispatch(exportDocument(title, state, url, format)),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewProgram);