import immutable from 'seamless-immutable';
import { Type as TicketType } from '../actions/TicketActions';


export const INITIAL_STATE = immutable({
    tickets: [],
    ticketsFetching: false,
    ticketsFetchingFailed: false,
    ticketsPages: 1,
    ticketObj: {},
    deleted: false,
    deleteTicketFailed: false,

    ticketItems: [],
    ticketItemsFetching: false,
    ticketItemsFetchingFailed: false,
    deletedItem: false,
    deleteTicketItemFailed: false,
    ticketItemObj: {},

    ticketStatuses: [],
    ticketStatusesFetching: false,
    ticketStatusesFetchingFailed: false,
    users: [],
    usersFetching: false,
    usersFetchingFailed: false,

    ticketTypeDropDown: [],
    ticketTypeFetchingDropDown: false,
    ticketTypeFetchingFailedDropDown: false,
    modules: [],
    modulesFetching: false,
    modulesFetchingFailed: false,
    forms: [],
    formsFetching: false,
    formsFetchingFailed: false,

    ticketDashboard: {},
    ticketDashboardFetching: false,
    ticketDashboardFetchingFailed: false,

    category: [],
    categoryFetching: false,
    categoryFetchingFailed: false,

});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case TicketType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;
        case TicketType.SET_PARCEL_ROW_IN_REDUCER: {
            const data = action.rowInfo.asMutable();
            return state.merge({ ticketObj: data })
            break;
        }

        case TicketType.GET_TICKET_READ_CALL: {
            const ticketsFetching = true;
            return state.merge({ ticketsFetching });
            break;
        }

        case TicketType.GET_TICKET_READ_SUCCESS: {
            const tickets = action.data.data;
            const ticketsFetching = false;
            const ticketsPages = action.data.total_pages;
            return state.merge({ tickets, ticketsFetching, ticketsPages });
            break;
        }

        case TicketType.GET_TICKET_READ_FAILED: {
            const ticketsFetchingFailed = true;
            const ticketsFetching = false;
            return state.merge({ ticketsFetchingFailed, ticketsFetching });
            break;
        }

        case TicketType.DELETE_TICKET_SUCCESS: {
            return state.merge({ deleted: true })
            break;
        }

        case TicketType.DELETE_TICKET_FAILED: {
            const deleteTicketFailed = true;
            return state.merge({ deleteTicketFailed });
            break;
        }


        case TicketType.GET_TICKET_ITEM_READ_CALL: {
            const ticketItemsFetching = true;
            return state.merge({ ticketItemsFetching });
            break;
        }

        case TicketType.GET_TICKET_ITEM_READ_SUCCESS: {
            const ticketItems = action.data.data;
            const ticketItemsFetching = false;
            return state.merge({ ticketItems, ticketItemsFetching });
            break;
        }

        case TicketType.GET_TICKET_ITEM_READ_FAILED: {
            const ticketItemsFetchingFailed = true;
            const ticketItemsFetching = false;
            return state.merge({ ticketItemsFetchingFailed, ticketItemsFetching });
            break;
        }

        case TicketType.DELETE_TICKET_ITEM_SUCCESS: {
            const array = state['ticketItems'].asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                if (array[i] && array[i].id === forDelete) {
                    array.splice(i, 1);
                }
            }
            return state.merge({ ticketItems: array, deletedItem: true })
            break;
        }

        case TicketType.DELETE_TICKET_ITEM_FAILED: {
            const deleteTicketItemFailed = true;
            return state.merge({ deleteTicketItemFailed });
            break;
        }


        case TicketType.GET_TICKET_STATUS_DROPDOWN_CALL: {
            const ticketStatusesFetching = true;
            return state.merge({ ticketStatusesFetching });
            break;
        }

        case TicketType.GET_TICKET_STATUS_DROPDOWN_SUCCESS: {
            const ticketStatuses = action.data.data;
            const ticketStatusesFetching = false;
            return state.merge({ ticketStatuses, ticketStatusesFetching });
            break;
        }

        case TicketType.GET_TICKET_STATUS_DROPDOWN_FAILED: {
            const ticketStatusesFetchingFailed = true;
            const ticketStatusesFetching = false;
            return state.merge({ ticketStatusesFetchingFailed, ticketStatusesFetching });
            break;
        }


        case TicketType.GET_TICKET_USER_DROPDOWN_CALL: {
            const usersFetching = true;
            return state.merge({ usersFetching });
            break;
        }

        case TicketType.GET_TICKET_USER_DROPDOWN_SUCCESS: {
            const users = action.data.data;
            const usersFetching = false;
            return state.merge({ users, usersFetching });
            break;
        }

        case TicketType.GET_TICKET_USER_DROPDOWN_FAILED: {
            const usersFetchingFailed = true;
            const usersFetching = false;
            return state.merge({ usersFetchingFailed, usersFetching });
            break;
        }
        case TicketType.GET_TICKET_TYPE_DROPDOWN_CALL: {
            const ticketTypeFetchingDropDown = true;
            return state.merge({ ticketTypeFetchingDropDown });
            break;
        }

        case TicketType.GET_TICKET_TYPE_DROPDOWN_SUCCESS: {
            const ticketTypeDropDown = action.data.data;
            const ticketTypeFetchingDropDown = false;
            return state.merge({ ticketTypeDropDown, ticketTypeFetchingDropDown });
            break;
        }

        case TicketType.GET_TICKET_TYPE_DROPDOWN_FAILED: {
            const ticketTypeFetchingFailedDropDown = true;
            const ticketTypeFetchingDropDown = false;
            return state.merge({ ticketTypeFetchingDropDown, ticketTypeFetchingFailedDropDown });
            break;
        }

        case TicketType.LOCK_TICKET_ITEM_SUCCESS: {
            const ticketLockPosting = false;
            const ticketLockSuccess = true;
            return state.merge({ ticketLockPosting, ticketLockSuccess })
            break;
        }
        case TicketType.GET_MODULE_DROPDOWN_CALL: {
            const modulesFetching = true;
            return state.merge({ modulesFetching });
            break;
        }

        case TicketType.GET_MODULE_DROPDOWN_SUCCESS: {
            const modules = action.data.data;
            const modulesFetching = false;
            return state.merge({ modules, modulesFetching });
            break;
        }

        case TicketType.GET_MODULE_DROPDOWN_FAILED: {
            const modulesFetchingFailed = true;
            const modulesFetching = false;
            return state.merge({ modulesFetching, modulesFetchingFailed });
            break;
        }
        case TicketType.GET_FORM_DROPDOWN_CALL: {
            const formsFetching = true;
            return state.merge({ formsFetching });
            break;
        }

        case TicketType.GET_FORM_DROPDOWN_SUCCESS: {
            const forms = action.data.data;
            const formsFetching = false;
            return state.merge({ forms, formsFetching });
            break;
        }

        case TicketType.GET_FORM_DROPDOWN_FAILED: {
            const formsFetchingFailed = true;
            const formsFetching = false;
            return state.merge({ formsFetching, formsFetchingFailed });
            break;
        }

        case TicketType.GET_DASHBOARD_CALL: {
            const ticketDashboardFetching = true;
            return state.merge({ ticketDashboardFetching });
            break;
        }

        case TicketType.GET_DASHBOARD_SUCCESS: {
            const ticketDashboard = action.data;
            const ticketDashboardFetching = false;
            return state.merge({ ticketDashboard, ticketDashboardFetching });
            break;
        }

        case TicketType.GET_DASHBOARD_FAILED: {
            const ticketDashboardFetchingFailed = true;
            const ticketDashboardFetching = false;
            return state.merge({ ticketDashboardFetching, ticketDashboardFetchingFailed });
            break;
        }

        case TicketType.GET_CATEGORY_DROPDOWN_CALL: {
            const categoryFetching = true;
            return state.merge({ categoryFetching });
            break;
        }

        case TicketType.GET_CATEGORY_DROPDOWN_SUCCESS: {
            const category = action.data.data;
            const categoryFetching = false;
            return state.merge({ category, categoryFetching });
            break;
        }

        case TicketType.GET_CATEGORY_DROPDOWN_FAILED: {
            const categoryFetchingFailed = true;
            const categoryFetching = false;
            return state.merge({ categoryFetching, categoryFetchingFailed });
            break;
        }

        default:
            return state;
    }
}