import immutable from 'seamless-immutable';
import { Type as FDBType } from '../actions/FDBActions';


export const INITIAL_STATE = immutable({
    fdbs: [],
    fdbFetching: false,
    fdbFetchingFailed: false,
});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case FDBType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;

        case FDBType.GET_FDBS_CALL: {
            const fdbFetching = true;
            return state.merge({ fdbFetching });
            break;
        }

        case FDBType.GET_FDBS_SUCCESS: {
            const fdbs = action.data.data;
            const fdbFetching = false;
            return state.merge({ fdbs, fdbFetching });
            break;
        }

        case FDBType.GET_FDBS_FAILED: {
            const fdbFetchingFailed = true;
            const fdbFetching = false;
            return state.merge({ fdbFetchingFailed, fdbFetching });
            break;
        }

        default:
            return state;
    }
}