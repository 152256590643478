import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Card } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './ImageryStyles';
import savi from './../satelliteinfo/savi.png';
import savi2 from './../satelliteinfo/savi2.png';
import savi3 from './../satelliteinfo/savi3.png';

export class SAVI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.props.language === 1 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                Soil-Adjusted Vegetation Index (SAVI) je vegetativni indeks koji unosi poboljšanja u odnosu na NDVI, jer
                                eliminiše uticaj koji različiti tipovi zemljišta mogu imati na njega.
                            </p>
                            <p className={classes.paragraph}>
                                Istraživanja su pokazala da empirijski podaci NDVI-ja mogu biti nestabilni jer variraju u zavisnosti od boje
                                zemljišta i prisutnosti vlage, funkcije dvosmerne refleksije, atmosferskih uslova i prisutnosti mrtve
                                materije u samim biljkama. Stoga SAVI predstavlja modifikaciju NDVI-ja radi otklanjanja uticaja atmosfere
                                i zemljišta.
                            </p>
                            <div className={classes.image}>
                                <img src={savi} />
                            </div>
                            <div className={classes.image}>
                                <img src={savi2} />
                            </div>
                            <div className={classes.image}>
                                <img src={savi3} />
                            </div>
                        </div>
                    </Card>
                }
                {this.props.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                NDVI (Normalized Difference Vegetation Index) - This most known and used vegetation index is a
                                simple, but effective VI for quantifying green vegetation. It normalizes green leaf scattering in the Near
                                Infra-red wavelength and chlorophyll absorption in the red wavelength.
                            </p>
                            <p className={classes.paragraph}>
                                Values description: The value range of an NDVI is -1 to 1. Negative values of NDVI (values approaching -1)
                                correspond to water. Values close to zero (-0.1 to 0.1) generally correspond to barren areas of rock, sand,
                                or snow. Low, positive values represent shrub and grassland (approximately 0.2 to 0.4), while high values
                                indicate temperate and tropical rainforests (values approaching 1).
                            </p>
                            <div className={classes.image}>
                                <img src={savi} />
                            </div>
                            <div className={classes.image}>
                                <img src={savi2} />
                            </div>
                            <div className={classes.image}>
                                <img src={savi3} />
                            </div>
                        </div>
                    </Card>
                }
                {this.props.language === 4 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                SAVI (Soil Adjusted Vegetation Index) je poboljšan vegetacijski indeks u odnosu na NDVI 
                                jer eliminira utjecaj koji različiti tipovi tla mogu imati na njega.
                            </p>
                            <p className={classes.paragraph}>
                                Istraživanja su pokazala da empirijski NDVI podaci mogu biti nestabilni jer variraju ovisno o 
                                boji tla i prisutnosti vlage, funkciji dvosmjerne refleksije, atmosferskim uvjetima i prisutnosti 
                                mrtve tvari u samim biljkama. Stoga SAVI predstavlja modifikaciju NDVI-ja radi uklanjanja 
                                utjecaja atmosfere i tla.
                            </p>
                            <div className={classes.image}>
                                <img src={savi} />
                            </div>
                            <div className={classes.image}>
                                <img src={savi2} />
                            </div>
                            <div className={classes.image}>
                                <img src={savi3} />
                            </div>
                        </div>
                    </Card>
                }
            </div>
        )
    }
};

export default withStyles(styles)(SAVI);