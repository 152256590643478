
import immutable from 'seamless-immutable';
import { Type as NoteType } from '../../actions/resources/NoteActions';
export const INITIAL_STATE = immutable({

    noteGroupDropDown: [],
    noteGroupDropDownFetching: false,
    noteGroupDropDownFetchingFailed: false,

});


export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case NoteType.GET_NOTE_GROUP_DROPDOWN_CALL: {
            const noteGroupDropDownFetching = true;
            return state.merge({ noteGroupDropDownFetching });
            break;
        }

        case NoteType.GET_NOTE_GROUP_DROPDOWN_SUCCESS: {
            const noteGroupDropDown = action.data.data;
            const noteGroupDropDownFetching = false;
            return state.merge({ noteGroupDropDown, noteGroupDropDownFetching });
            break;
        }

        case NoteType.GET_NOTE_GROUP_DROPDOWN_FAILED: {
            const noteGroupDropDownFetchingFailed = true;
            const noteGroupDropDownFetching = false;
            return state.merge({ noteGroupDropDownFetching, noteGroupDropDownFetchingFailed });
            break;
        }
        default:
            return state;
    }
}