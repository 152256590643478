import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { getStateManagers, getZones, getStates, setInitialState, setRowInReducer } from '../../actions/AdministrationActions';
import i18n from '../../i18n/i18n';
import Select from 'react-select';
import immutable from 'seamless-immutable';
import { Fab } from '@material-ui/core';
import AddState from './AddState';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import { getLocalGov } from '../../actions/FarmRegistarActions';
const CheckboxTable = checkboxHOC(ReactTable);
export class ViewStateManagers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            add: false,
            selection: [],
            selectAll: false,
            view: false
        };
    }

    componentDidMount() {
        this.props.getZones();
        this.props.getStates();
        this.props.getLocalGov();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.stateManagersPostingSuccess === true) {
            this.handleClick();
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getStateManagers(wrapped.state);
            this.props.setInitialState('stateManagersPostingSuccess');
        }
        this.setState({
            stateManagers: nextProps.stateManagers,
            stateManagersFetching: nextProps.stateManagersFetching,
            zones: immutable.asMutable(nextProps.zones.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            localGovernments: immutable.asMutable(nextProps.localGoverments.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            zonesFetching: nextProps.zonesFetching,
            zonesFetchingFailed: nextProps.zonesFetchingFailed,
            states: immutable.asMutable(nextProps.states.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            statesFetching: nextProps.statesFetching,
            statesFetchingFailed: nextProps.statesFetchingFailed,
        })
    }

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    handleClick = () => {
        this.setState({ add: !this.state.add });
    };


    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            selection.push(key);
        }
        this.setState({ selection })
    };

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });
    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    render() {
        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "lightgreen" : "inherit"
                        }
                    };
                }
                return {};
            }
        };
        return (
            <div className="page">
                <div className="table-header">
                    <Fab
                        onClick={() => this.handleClick()}
                        color="primary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-Plus headerIcon" />
                    </Fab>
                    <Fab
                        onClick={() => this.onSearchClick()}
                        color="primary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-search headerIcon" />
                    </Fab>
                </div>
                <CheckboxTable data={this.props.statesManagers}
                    ref={r => (this.checkboxTable = r)}
                    columns={this.columns}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.props.stateManagersPages}
                    loading={this.props.statesManagersFetching}
                    manual
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => this.props.getStateManagers(state, instance)}
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                />
                {this.state.add && <AddState add={this.state.add}
                    handleClose={this.handleClick} />}
            </div>
        )
    }

    columns = [
        {
            Header: i18n.t('State officer'),
            accessor: 'naziv',
            resizable: false,
        },
        {
            Header: i18n.t('Zone'),
            accessor: 'zona_naziv',
            resizable: false,
            Filter: ({ filter, onChange }) =>
                <Select
                    isMulti
                    name="naziv"
                    onChange={value => {
                        onChange(value)
                    }}
                    closeMenuOnSelect={false}
                    options={this.state.zones}
                    className="multi-select-in-filter"
                    classNamePrefix="select"
                    placeholder={i18n.t('Select')}
                />
        },
        {
            Header: i18n.t('State'),
            accessor: 'opstina_naziv',
            resizable: false,
            Filter: ({ filter, onChange }) =>
                <Select
                    isMulti
                    name="naziv"
                    onChange={value => {
                        onChange(value)
                    }}
                    closeMenuOnSelect={false}
                    options={this.state.states}
                    className="multi-select-in-filter"
                    classNamePrefix="select"
                    placeholder={i18n.t('Select')}
                />
        },
        {
            Header: i18n.t('Local government'),
            accessor: 'katastarska_opstina_naziv',
            resizable: false,
            Filter: ({ filter, onChange }) =>
                <Select
                    isMulti
                    name="naziv"
                    onChange={value => {
                        onChange(value)
                    }}
                    closeMenuOnSelect={false}
                    options={this.state.localGovernments}
                    className="multi-select-in-filter"
                    classNamePrefix="select"
                    placeholder={i18n.t('Select')}
                />
        },
        {
            Header: i18n.t('Address'),
            accessor: 'ulica_i_broj',
            resizable: false
        },
        {
            Header: i18n.t('Zip code'),
            accessor: 'postanski_broj',
            resizable: false
        },
        {
            Header: i18n.t('Email'),
            accessor: 'kontakt_mejl',
            resizable: false
        },
        {
            Header: i18n.t('Phone'),
            accessor: 'kontakt_telefon',
            resizable: false
        }
    ];
};

function mapStateToProps(state) {
    return {
        statesManagers: state.administrationReducer.statesManagers,
        statesManagersFetching: state.administrationReducer.statesManagersFetching,
        stateManagersPages: state.administrationReducer.stateManagersPages,
        zones: state.administrationReducer.zones,
        zonesFetching: state.administrationReducer.zonesFetching,
        zonesFetchingFailed: state.administrationReducer.zonesFetchingFailed,
        states: state.administrationReducer.states,
        localGoverments: state.farmRegistarReducer.localGoverments,
        stateManagersPostingSuccess: state.administrationReducer.stateManagersPostingSuccess,
        statesFetching: state.administrationReducer.statesFetching,
        statesFetchingFailed: state.administrationReducer.statesFetchingFailed
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getZones: () => dispatch(getZones()),
        getStates: () => dispatch(getStates()),
        getLocalGov: (id_state) => dispatch(getLocalGov(id_state)),
        setInitialState: (component) => dispatch(setInitialState(component)),
        setRowInReducer: (rowInfo, name) => dispatch(setRowInReducer(rowInfo, name)),
        getStateManagers: (state, instance) => dispatch(getStateManagers(state, instance)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewStateManagers)