import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import { login, setInitialState } from '../../actions/LoginActions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Paper, Button, TextField, IconButton, Link } from '@material-ui/core';
/// FOOTER CSS
var style = {
    textAlign: "center",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "40px",
    lineHeight: "50px",
    width: "98%",
    color: '#ffffff',
    fontSize: 24,
}

export class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            open: false
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.logInFailed === true) {
            if (nextProps.message == 'password') {
                toast.error(i18n.t('Incorrect password!'), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    draggablePercent: 80
                });
            } else if (nextProps.message == 'farmers') {
                toast.error(i18n.t('Farmers can\'t log in here!'), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    draggablePercent: 80
                });
            } else {
                toast.error(i18n.t('Username or password are incorrect!'), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    draggablePercent: 80
                });
            }
            this.props.setInitialState('logInFailed');
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown)
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown)
    }
    handleKeyDown = (event) => {
        if (event.key === "Enter") {
            this.loginPressed()
        }
    }

    handleClick = () => {
        this.props.history.push('forgotpass');
    };


    loginPressed() {
        if (this.state.username && this.state.password) {
            this.props.login(this.state.username, this.state.password)
        } else {
            toast.error(i18n.t('Username and passwords are required.'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    render() {
        return (
            <div>

                <div className="login">
                    <Paper className="roundedBox" >
                        <div className="loginText">
                            LOGIN
                        </div>
                        <div style={{
                            textAlign: 'center',
                        }}>
                            <div className="logo">
                                <img src={require('../../assets/images/login_agrolife.svg')} width="300" height="70" />
                            </div>
                            <TextField
                                label={i18n.t('Username')}
                                required
                                style={{ width: '256px' }}
                                onChange={(event) => this.setState({ username: event.target.value })}
                            />
                            <br />
                            <TextField
                                type="password"
                                label={i18n.t('Password')}
                                required
                                style={{ width: '256px' }}
                                onChange={(event) => this.setState({ password: event.target.value })}
                            />
                            <br />
                            <Button
                                className="loginButton"
                                color="secondary"
                                onClick={() => this.loginPressed()}
                            >
                                {i18n.t('login')}
                            </Button>
                            <br />
                            <div style={{ justifyContent: 'space-between', width: '100%', whiteSpace: 'pre-wrap', margin: '5px 0' }}>
                                <a href="https://manuals.agrolife.world/privacy_policy" target="blank" style={{ color: '#04764e' }}>Privacy policy</a>
                                <span>     </span>
                                <a href="https://manuals.agrolife.world/tos" target="blank" style={{ color: '#04764e' }}>Terms & Conditions</a>
                            </div>
                            {/* <Link
                                onClick={() => this.handleClick()}
                                color="inherit"
                                variant="body2"

                            >
                                {i18n.t('Forgot password?')}

                            </Link> */}
                        </div>
                    </Paper>
                    {/* <div style={style}>
                        <a href="https://manuals.agrolife.world/privacy_policy" target="blank">Privacy policy </a>
                        |
                        <a href="https://manuals.agrolife.world/tos" target="blank">Terms & Conditions</a>
                        |
                        <span className="icon-Facebook loginIcon"></span>
                        <span className="icon-Twiter loginIcon"></span>
                        <span className="icon-Instagram loginIcon"></span>
                        <span className="icon-linkedin loginIcon"></span>
                        <span className="icon-G+ loginIcon"></span>
                        | www.agrolife.world
                    </div> */}
                </div>
            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        logInFailed: state.appReducer.logInFailed,
        token: state.appReducer.token,
        message: state.appReducer.message,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        login: (username, password) => dispatch(login(username, password)),
        setInitialState: (component) => dispatch(setInitialState(component))
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage);