import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n/i18n';
import { Button, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getGroup, getSubgroup, setInitialState, addMachine } from '../../../actions/resources/MachinesActions';
import { getUnit } from '../../../actions/resources/UnitActions';
import { getCrop } from '../../../actions/resources/CropActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
export class AddMachines extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            machineObj: this.props.machineObj
        }
    }

    componentDidMount() {
        this.props.getGroup();
        this.props.getSubgroup();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.machinesPostingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('machinesPostingFailed');
        }
    }

    addMachine() {
        if (this.state.machineObj.naziv
            && this.state.machineObj.id_pogonska_masina_grupa
            && this.state.machineObj.id_pogonska_masina_podgrupa) {
            this.props.addMachine(
                this.state.machineObj
            )

        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    handleChangeValue = name => event => {
        let obj = Object.assign({}, this.state.machineObj);
        obj[name] = event.target.value;
        this.setState({ machineObj: obj });
        if ([name] == 'id_pogonska_masina_grupa') {
            this.props.getSubgroup(event.target.value);
        }
    };


    render() {
        let group = [];
        if (this.props.machinesGroupDropdown && !this.props.groupFetching) {
            group = this.props.machinesGroupDropdown.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let subgroup = [];
        if (this.props.machinesSubgroupDropdown && !this.props.subgroupFetching) {
            subgroup = this.props.machinesSubgroupDropdown.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        return (
            <div style={{ padding: 20 }}>

                {/* <Paper className="roundedBoxMaterial" zdepth={4}> */}
                <Dialog
                    open={this.props.add}
                    onClose={this.props.handleClose}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit Machine') : i18n.t('Add Machine')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-machines">
                            <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label={i18n.t('Code')}
                                        value={this.state.machineObj.sifra}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={this.handleChangeValue('sifra')}
                                        type="number"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label={i18n.t('Title')}
                                        required
                                        value={this.state.machineObj.naziv}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={this.handleChangeValue('naziv')}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} >
                                    <TextField
                                        select
                                        InputLabelProps={this.state.machineObj.id_pogonska_masina_grupa ? { shrink: true } : { shrink: false }}
                                        label={i18n.t('Machines group')}
                                        value={this.state.machineObj.id_pogonska_masina_grupa}
                                        onChange={this.handleChangeValue('id_pogonska_masina_grupa')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        required
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <option value='' />
                                        {group}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <TextField
                                        select
                                        InputLabelProps={this.state.machineObj.id_pogonska_masina_podgrupa ? { shrink: true } : { shrink: false }}
                                        label={i18n.t('Machines subgroup')}
                                        disabled={!this.state.machineObj.id_pogonska_masina_grupa}
                                        value={this.state.machineObj.id_pogonska_masina_podgrupa}
                                        onChange={this.handleChangeValue('id_pogonska_masina_podgrupa')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        required
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <option value='' />
                                        {subgroup}
                                    </TextField>
                                </Grid>
                                <Grid item xs>
                                    <Button
                                        style={{ alignSelf: 'center', backgroundColor: '#58B4E5', color: 'white' }}
                                        onClick={() => this.addMachine()}
                                    >
                                        {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
                {/* </Paper> */}
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        machinesGroupDropdown: state.machinesReducer.machinesGroupDropdown,
        groupFetching: state.machinesReducer.groupFetching,
        groupFetchingFailed: state.machinesReducer.groupFetchingFailed,
        machinesSubgroupDropdown: state.machinesReducer.machinesSubgroupDropdown,
        subgroupFetching: state.machinesReducer.subgroupFetching,
        subgroupFetchingFailed: state.machinesReducer.subgroupFetchingFailed,
        machineObj: state.machinesReducer.machineObj,
        machinesPosting: state.machinesReducer.machinesPosting,
        machinesPostingFailed: state.machinesReducer.machinesPostingFailed
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getGroup: () => dispatch(getGroup()),
        getSubgroup: (id) => dispatch(getSubgroup(id)),
        getCrop: () => dispatch(getCrop()),
        getUnit: () => dispatch(getUnit()),
        setInitialState: (component) => dispatch(setInitialState(component)),
        addMachine: (machineObj) => dispatch(addMachine(machineObj)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddMachines)