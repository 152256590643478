import immutable from 'seamless-immutable';
import { Type as SeasonType } from '../../actions/resources/SeasonsActions';


export const INITIAL_STATE = immutable({
    //sezone
    seasonPosting: false,
    seasonPostingSuccess: false,
    seasonPostingFailed: false,
    season: [],
    seasonObj: {},
    seasonFetching: false,
    seasonFetchingFailed: false,
    seasonDropDown: [],
    seasonFetchingDropDown: false,
    seasonFetchingFailedDropDown: false,
    seasonPages: 1,
    deleted: false,
    deleteSeasonFailed: false,

});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case SeasonType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;

        case SeasonType.SET_SEASON_ROW_IN_REDUCER: {
            const data = action.rowInfo.asMutable();
            return state.merge({ [action.name]: data })
            break;
        }

        case SeasonType.ADD_SEASONS_CALL: {
            const seasonPosting = true;
            return state.merge({ seasonPosting });
            break;
        }

        case SeasonType.ADD_SEASONS_SUCCESS: {
            const seasonPosting = false;
            const seasonPostingSuccess = true;
            return state.merge({ seasonPostingSuccess, seasonPosting, seasonObj: INITIAL_STATE.seasonObj })
            break;
        }

        case SeasonType.ADD_SEASONS_FAILED: {
            const seasonPosting = false;
            const seasonPostingFailed = true;
            return state.merge({ seasonPosting, seasonPostingFailed });
            break;
        }

        case SeasonType.GET_VIEW_SEASONS_CALL: {
            const seasonFetching = true;
            return state.merge({ seasonFetching });
            break;
        }

        case SeasonType.GET_VIEW_SEASONS_SUCCESS: {
            const season = action.data.data;
            const seasonFetching = false;
            const seasonPages = action.data.total_pages
            return state.merge({ season, seasonFetching, seasonPages });
            break;
        }

        case SeasonType.GET_VIEW_SEASONS_FAILED: {
            const seasonFetchingFailed = true;
            const seasonFetching = false;
            return state.merge({ seasonFetching, seasonFetchingFailed });
            break;
        }

        case SeasonType.GET_SEASON_CALL: {
            const seasonFetchingDropDown = true;
            return state.merge({ seasonFetchingDropDown });
            break;
        }

        case SeasonType.GET_SEASON_SUCCESS: {
            const seasonDropDown = action.data.data;
            const seasonFetchingDropDown = false;
            return state.merge({ seasonDropDown, seasonFetchingDropDown });
            break;
        }

        case SeasonType.GET_SEASON_FAILED: {
            const seasonFetchingFailedDropDown = true;
            const seasonFetchingDropDown = false;
            return state.merge({ seasonFetchingDropDown, seasonFetchingFailedDropDown });
            break;
        }


        case SeasonType.DELETE_SEASON_SUCCESS: {
            const array = state[action.name].asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ [action.name]: array, deleted: deleted })
            break;

        }

        case SeasonType.DELETE_SEASON_FAILED: {
            const deleteSeasonFailed = true;
            return state.merge({ deleteSeasonFailed });
            break;
        }

        default:
            return state;
    }
}