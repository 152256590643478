import React from 'react';
import i18n from '../../../i18n/i18n';
import axios from '../../../utils/AxiosWrapper';
import { Bar } from 'react-chartjs-2'
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import DatePickerMeteo from '../../../utils/DatePickerMeteo';
import {formatDiseaseDaily } from './../DataFormatters';
import { withStyles } from '@material-ui/core/styles';
import { MoonLoader } from 'react-spinners';
import MeteoTable from '../MeteoTable';

const styles = {
    value : {background: '#a1c16e', width: '6px', margin: '3px', borderRadius: '3px', padding: '8px', textAlign: 'center', fontWeight: 'bold', font: '12px', color: 'white'},
    rows: {  display: 'flex', flexDirection: 'row', alignItems:'center', lineHeight:'10px'}
};

export class ViticultureGreyMouldRisk extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            station_id: this.props.row.uid,
            startDate: moment().subtract(7, 'days'),
            endDate: moment(),
            disease1: {},
            loadDisease: true,
            barColors: []
        };
    }

    setDates = (start, end) => { this.setState({ startDate: start, endDate: end }, () => { this.getPessl(); }); }

    // Get funkcije za preuzimanje podataka sa stanica samo po potrebi ubaciti, pored Disease-a uvek mora i Data zbog datuma
    getDiseaseDaily = (path, disease) => {
        let obj = {};
        axios.post('/api/testMeteo/meteo', path)
            .then(res => { 
            obj = formatDiseaseDaily(res, obj); 
            this.setState({ [disease]: obj, loadDisease: false });})
            .catch(err => console.log('Error: ', err))
    }

    getPessl = () => {
        let start_date = moment(this.state.startDate).unix();
        let end_date = moment(this.state.endDate).unix();
        let station_id = this.state.station_id;

        // URL-ovi za podatke sa meteo stanica
        let disease_path = '/data/' + station_id + '/daily/from/' + start_date + '/to/' + end_date;

        // Disease mora POST method, za Data i Forecast mora GET method
        this.getDiseaseDaily({ path: disease_path, name: "GeneralGreyMould", method: "POST" }, 'disease1');
    }

    

    render() {
        //console.log('disease1: ', this.state.disease1);

        const { classes } = this.props;

        let graph1 = {};
        let graph2 = {};
        let graph3 = {};

        const options1 = {
            
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[%]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            minTicksLimit:11,
                            max: 100,
                            maxTicksLimit:11
                        },
                    },
                ],
            },
        };

        const options2 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-3',
                        scaleLabel: {
                            display: true,
                            labelString: '[°C]'
                        },
                        gridLines: {
                            drawOnChartArea: false
                        }
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-4',
                        scaleLabel: {
                            display: true,
                            labelString: '[%]'
                        },
                        gridLines: {
                            drawOnChartArea: false,
                        },

                        ticks: {
                            beginAtZero: false,
                            min: 20,
                            max: 100
                        }
                    },
                ],
            },
        };

        const options3 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-5',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: i18n.t('[min/day]')
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 1440,
                            maxTicksLimit: 6,
                        }
                    },
                ],
            },
        };

        if (
            this.state.disease1
        ) 
        {
            graph1 = {
                labels: this.state.disease1.dates,
                datasets: [
                    {
                        data: this.state.disease1['Botrytis Risk'],
                        label: i18n.t('Infection [%]'),
                        backgroundColor: 'transparent',
                        borderColor: 'red',
                        type: 'line',
                        borderWidth: 2,
                        pointRadius: 1,
                        yAxisID: 'y-axis-1',
                    },
                    
                ],
            };
            graph2 = {
                labels: this.state.disease1.dates,
                datasets: [
                    {
                        data: this.state.disease1['HC Air temperature'],
                        label: i18n.t('Temperature [°C]'),
                        backgroundColor: 'transparent',
                        borderColor: 'red',
                        type: 'line',
                        borderWidth: 2,
                        pointRadius: 1,
                        yAxisID: 'y-axis-3',
                    },
                    {
                        data: this.state.disease1['HC Relative humidity'],
                        label: i18n.t('Relative humidity [%]'),
                        type: 'line',
                        backgroundColor: 'transparent',
                        borderColor: '#00BFFF',
                        borderWidth: 2,
                        pointRadius: 1,
                        yAxisID: 'y-axis-4',
                    },

                ],
            };
            graph3 = {
                labels: this.state.disease1.dates,
                datasets: [
                    {
                        data: this.state.disease1['Leaf Wetness'],
                        label: i18n.t('Leaf wetness [min/day]'),
                        backgroundColor: '#7CFC00',
                        borderColor: '#7CFC00',
                        type: 'bar',
                        borderWidth: 2,
                        pointRadius: 1,
                        yAxisID: 'y-axis-5',
                    },
                ],
            };
        };

        return (
            <div style={{ paddingLeft: '35px' }}>
                <div style= {{display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                        <p style={{fontSize: 20, marginRight: 4 }}>{i18n.t('Botrytis Bunch Rot/Grey Mould risk')}</p>
                        <p style={{fontSize: 20, fontStyle: 'italic'}}>(Botrytis cinerea)</p>
                        </div>
                </div>
             <DatePickerMeteo startDate={this.state.startDate} endDate={this.state.endDate} setDates={this.setDates} getPessl={this.getPessl} />
            {(this.state.loadDisease === true ) && <div style={{ marginLeft: '39%', marginTop: '14%' }}> <MoonLoader loading={this.state.loadDisease === true } size={100} /></div>}
                {this.state.loadDisease === false  &&
                    <div>
                        <div style={{ width: '95%', marginTop: '20px' }}>
                            <Bar data={graph1} height={300} width={600} options={options1} />
                        </div>
                        <div style={{ width: '95%', marginTop: '20px' }}>
                            <Bar data={graph2} height={200} width={400} options={options2} />
                        </div>
                        <MeteoTable data={{
                            headers: [i18n.t('Date and time'), i18n.t('Temperature [°C]'), i18n.t('Relative humidity [%]')],
                            dates: this.state.disease1.dates,
                            temperatures: this.state.disease1['HC Air temperature'],
                            humidity: this.state.disease1['HC Relative humidity']
                        }} />
                        <div style={{ width: '95%', marginTop: '20px' }}>
                            <Bar data={graph3} height={200} width={400} options={options3} />
                        </div>
                        <MeteoTable data={{
                            headers: [i18n.t('Date'), i18n.t('Leaf wetness [min/day]')],
                            dates: this.state.disease1.dates,
                            leaf_wetness: this.state.disease1['Leaf Wetness'],
                        }} />
                        <div style={{ marginTop: '20px' }}></div>
                    </div>
                }
            </div>
        );
    }
};

export default withStyles(styles)(ViticultureGreyMouldRisk);
