import React from 'react';
import i18n from '../../../i18n/i18n';
import axios from '../../../utils/AxiosWrapper';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { Button, Card, Link, Typography } from '@material-ui/core';
import DatePickerMeteo from '../../../utils/DatePickerMeteo';
import { formatData, formatForecast, formatDisease } from '../DataFormatters';
import { withStyles } from '@material-ui/core/styles';
import { MoonLoader } from 'react-spinners';
import MeteoTable from '../MeteoTable';
import MeteoInfoButton from '../MeteoInfoButton';
import InfoUVIndex from '../Info/Forecast/InfoUVIndex';

const styles = {};

export class ForecastUVIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            station_id: this.props.row.uid,
            startDate: moment().subtract(1, 'days'),
            endDate: moment(),
            forecast: {},
            week: false,
            load: true
        };
    }

    componentDidMount() {
        this.getPessl();
    }

    setDates = (start, end) => { this.setState({ startDate: start, endDate: end }, () => { this.getPessl(); }); }

    getForecast = (path) => {
        axios.post('/api/testMeteo/meteo', path)
            .then(res => { let obj = formatForecast(res); this.setState({ forecast: obj, load: false }); })
            .catch(err => console.log('Error: ', err))
    }

    getPessl = () => {
        let start_date = moment(this.state.startDate).unix();
        let end_date = moment(this.state.endDate).unix();
        let station_id = this.state.station_id;

        // URL-ovi za podatke sa meteo stanica
        let disease_path = '/data/' + station_id + '/hourly/from/' + start_date + '/to/' + end_date;
        let forecast_path = '/forecast/' + station_id + '/hourly';
        let data_path = '/data/' + station_id + '/hourly/from/' + start_date + '/to/' + end_date;

        this.getForecast({ path: forecast_path, name: 'general7', method: "POST" });
    }

    render() {
        const { classes } = this.props;

        // console.log('disease: ', this.state.disease);
        // console.log('data meteo: ', this.state.data);
        // console.log('forecast: ', this.state.forecast);

        let graph1 = {};
        if (
            this.state.forecast.dates !== undefined
        ) {
            graph1 = {
                labels: this.state.week ? this.state.forecast.dates : this.state.forecast.dates.slice(0, 72),
                datasets: [
                    {
                        data: this.state.week ? this.state.forecast.uvindex : this.state.forecast.uvindex.slice(0, 72),
                        label: i18n.t('UV Index'),
                        backgroundColor: 'rgba(102, 51, 153, 0.4)',
                        borderColor: 'rgb(102, 51, 153)',
                        type: 'line',
                        borderWidth: 2,
                        pointRadius: 2,
                        pointBackgroundColor:'rgb(102, 51, 153)'
                    },
                ],
            };
        }
        const options1 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        gridLines: {
                            drawOnChartArea: false
                        },
                    },
                ],
            },
        };

        return (
            <div style={{ paddingLeft: '35px', height: '100%' }}>
                 <div style= {{display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                        <p style={{fontSize: 20, marginRight: 4}}>{i18n.t('UV Index')}</p>
                        </div>
                        <div style={{marginLeft: 20}}>
                        <MeteoInfoButton
                            title={i18n.t('UV Index')}
                            text={<InfoUVIndex />}
                            height='400px'
                            width='sm'
                            />
                        </div>
                </div>
                <div style={{ display: 'flex', marginLeft: '40%', marginTop: '22px' }}>
                    <Link style={{ marginRight: '3px', marginLeft: '3px', fontSize: '18px', color: 'black', cursor: 'pointer' }}
                        onClick={() => { this.setState({ week: false }); }}
                        variant="contained"
                        color="secondary">
                        <Typography>{i18n.t('3 days')}</Typography>
                    </Link> /
                <Link style={{ marginRight: '3px', marginLeft: '3px', fontSize: '18px', color: 'black', cursor: 'pointer' }}
                        onClick={() => { this.setState({ week: true }); }}
                        variant="contained"
                        color="secondary" >
                        <Typography>{i18n.t('7 days')}</Typography>
                    </Link>
                </div>
                {this.state.load === true && <div style={{ marginLeft: '39%', marginTop: '14%' }}> <MoonLoader loading={this.state.load} size={100} /></div>}
                {this.state.load === false &&
                    <div style={{ width: '95%', marginTop: '10px' }}>
                        <Bar data={graph1} height={300} width={600} options={options1} />
                        <MeteoTable data={{
                        headers: [i18n.t('Date and time'), i18n.t('UV Index'),],
                        dates: this.state.week ? this.state.forecast.dates : this.state.forecast.dates.slice(0, 72),
                        uvindex: this.state.week ? this.state.forecast.uvindex : this.state.forecast.uvindex.slice(0, 72)
                    }} />
                    <div style={{ marginTop: '20px' }}></div>
                    </div>
                }
            </div>
        );
    }
};

export default withStyles(styles)(ForecastUVIndex);