import React from 'react';
import { Card, withStyles, } from '@material-ui/core';
import axios from '../../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../InfoStyles';

export class InfoConvectivePrecipitation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.language === 1 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Konvektivne padavine su kratkotrajni lokalni pljuskovi velikog intenziteta. 
                            To su obično letnje popodnevne padavine karakterističe za tropska područja gde ima toplog i vlažnog vazduha.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                               No text
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 4 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Konvektivne oborine su kratkotrajni lokalni pljuskovi jakog intenziteta. To su obično ljetne poslijepodnevne oborine karakteristične za tropska područja gdje je topao i vlažan zrak.
                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    };
};

export default withStyles(styles)(InfoConvectivePrecipitation);