import axios from '../utils/AxiosWrapper';
export const Type = {

    SET_INITIAL_STATE: 'SET_INITIAL_STATE',

    GET_FARMER_INFO_CALL: 'GET_FARMER_INFO_CALL',
    GET_FARMER_INFO_SUCCESS: 'GET_FARMER_INFO_SUCCESS',
    GET_FARMER_INFO_FAILED: 'GET_FARMER_INFO_FAILED',

    UPDATE_FARMER_INFO_CALL: 'UPDATE_FARMER_INFO_CALL',
    UPDATE_FARMER_INFO_SUCCESS: 'UPDATE_FARMER_INFO_SUCCESS',
    UPDATE_FARMER_INFO_FAILED: 'UPDATE_FARMER_INFO_FAILED',
};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}


export function saveInfo(info) {
    return (dispatch) => {

        dispatch({
            type: Type.UPDATE_FARMER_INFO_CALL
        });

        axios.post('api/farmer_nigeria/create', { data: info })
            .then(function (response) {
                dispatch({
                    type: Type.UPDATE_FARMER_INFO_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.UPDATE_FARMER_INFO_FAILED
                });
            });
    }

}

export function getInfo(id_klijent) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_FARMER_INFO_CALL
        });

        axios.get('api/farmer_nigeria/getByIdFarmer?id_klijent=' + id_klijent)
            .then(function (response) {
                dispatch({
                    type: Type.GET_FARMER_INFO_SUCCESS,
                    data: response.data,
                    name: 'farmerRegistrationObj'
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_FARMER_INFO_FAILED
                });
            });
    }

}

export function saveCustomerInfo(info) {
    return (dispatch) => {

        dispatch({
            type: Type.UPDATE_FARMER_INFO_CALL
        });
        axios.post('api/farmer_nigeria/kyc_create', { data: info })
            .then(function (response) {
                dispatch({
                    type: Type.UPDATE_FARMER_INFO_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.UPDATE_FARMER_INFO_FAILED
                });
            });
    }

}

export function getCustomerInfo(id_klijent) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_FARMER_INFO_CALL
        });

        axios.get('api/farmer_nigeria/getByIdCustomer?id_klijent=' + id_klijent)
            .then(function (response) {
                dispatch({
                    type: Type.GET_FARMER_INFO_SUCCESS,
                    data: response.data,
                    name: 'knowYourCustomerObj'
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_FARMER_INFO_FAILED
                });
            });
    }

}

export function getMapingInfo(id_klijent) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_FARMER_INFO_CALL
        });

        axios.get('api/farmer_nigeria/getByIdCustomer?id_klijent=' + id_klijent)
            .then(function (response) {
                dispatch({
                    type: Type.GET_FARMER_INFO_SUCCESS,
                    data: response.data,
                    name: 'farmIdentificationObj'
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_FARMER_INFO_FAILED
                });
            });
    }

}

export function saveInputInfo(info) {
    return (dispatch) => {
        dispatch({
            type: Type.UPDATE_FARMER_INFO_CALL
        });
        axios.post('api/farmer_nigeria/create_input', { data: info })
            .then(function (response) {
                dispatch({
                    type: Type.UPDATE_FARMER_INFO_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.UPDATE_FARMER_INFO_FAILED
                });
            });
    }

}

export function getInputInfo(id_klijent) {

    return (dispatch) => {

        dispatch({
            type: Type.GET_FARMER_INFO_CALL
        });

        axios.get('api/farmer_nigeria/getByIdInput?id_klijent=' + id_klijent)
            .then(function (response) {
                dispatch({
                    type: Type.GET_FARMER_INFO_SUCCESS,
                    data: response.data,
                    name: 'inputDistributionObj'
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_FARMER_INFO_FAILED
                });
            });
    }
}

export function saveIdentMaping(info) {
    return (dispatch) => {
        dispatch({
            type: Type.UPDATE_FARMER_INFO_CALL
        });
        axios.post('api/farmer_nigeria/create_identification', { data: info })
            .then(function (response) {
                dispatch({
                    type: Type.UPDATE_FARMER_INFO_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.UPDATE_FARMER_INFO_FAILED
                });
            });
    }

}

export function getByIdIdentification(id_klijent) {

    return (dispatch) => {

        dispatch({
            type: Type.GET_FARMER_INFO_CALL
        });

        axios.get('api/farmer_nigeria/getByIdIdentification?id_klijent=' + id_klijent)
            .then(function (response) {
                dispatch({
                    type: Type.GET_FARMER_INFO_SUCCESS,
                    data: response.data,
                    name: 'farmIdentificationObj'
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_FARMER_INFO_FAILED
                });
            });
    }
}


export function saveMonitoring(info) {
    return (dispatch) => {
        dispatch({
            type: Type.UPDATE_FARMER_INFO_CALL
        });
        axios.post('api/farmer_nigeria/create_monitoring', { data: info })
            .then(function (response) {
                dispatch({
                    type: Type.UPDATE_FARMER_INFO_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.UPDATE_FARMER_INFO_FAILED
                });
            });
    }

}

export function getByMonitoring(id_klijent) {

    return (dispatch) => {

        dispatch({
            type: Type.GET_FARMER_INFO_CALL
        });

        axios.get('api/farmer_nigeria/getByIdMonitoring?id_klijent=' + id_klijent)
            .then(function (response) {
                dispatch({
                    type: Type.GET_FARMER_INFO_SUCCESS,
                    data: response.data,
                    name: 'monitoringAndEvaluationObj'
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_FARMER_INFO_FAILED
                });
            });
    }
}

export function getAnchorCOS(id_klijent) {

    return (dispatch) => {

        dispatch({
            type: Type.GET_FARMER_INFO_CALL
        });

        axios.get('api/farmer_nigeria/getAnchorCOS?id_klijent=' + id_klijent)
            .then(function (response) {
                dispatch({
                    type: Type.GET_FARMER_INFO_SUCCESS,
                    data: response.data,
                    name: 'anchorCOSObj'
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_FARMER_INFO_FAILED
                });
            });
    }
}

export function saveAnchorCOS(info) {
    return (dispatch) => {
        dispatch({
            type: Type.UPDATE_FARMER_INFO_CALL
        });
        axios.post('api/farmer_nigeria/create_anchor_cos', { data: info })
            .then(function (response) {
                dispatch({
                    type: Type.UPDATE_FARMER_INFO_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.UPDATE_FARMER_INFO_FAILED
                });
            });
    }

}