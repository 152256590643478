import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts'
import i18n from '../../../i18n/i18n';
import { BOA } from './images.json'
import moment from 'moment';
import axios from '../../../utils/AxiosWrapper';

var cosProfileCall = (id) => axios.get(`api/profile/client_profile?id=${id}`)
    .then(function (response) {
        return response.data.data[0];
    });

export default async (data, anchorProfile) => {
    const { vfs } = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;
    var cosProfile;
    // console.log('dddddddddddd', data);
    var cosProfile = await cosProfileCall(data._subRows[0]._original.id_klijent_society);

    var dd = {
        content: [
            {
                table: {
                    headerRows: 0,
                    widths: ['*', '*', '*', '*'],
                    body: [
                        [
                            {},
                            {},
                            {},
                            {
                                image: BOA,
                                width: 100,
                                alignment: 'center',
                                rowSpan: 2,

                            }
                        ],
                        [
                            {
                                text: i18n.t('Anchor Subsidy Program Application Form'),
                                colSpan: 3,
                                style: 'header',
                                align: 'left',
                                valign: 'bottom',
                            },
                            {},
                            {},
                            {}
                        ]
                    ]
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return i !== 2 ? 0 : 3;
                    },
                    vLineWidth: function (i, node) {
                        return 0;
                    },
                    paddingTop: function (i, node) {
                        if (i === 0 || i === node.table.widths.length) {
                            return null;
                        }
                        return 40;
                    },
                },
                margin: [0, 20, 0, 8]
            },
            {
                columns: [
                    {
                        width: '*',
                        text: data.program_naziv,
                        style: 'header1'
                    },
                    {
                        width: 150,
                        text: `${i18n.t('Date of application')}:\n\n __________________`
                    }
                ],
                margin: [0, 20, 0, 0],
            },
            {
                table: {
                    headerRows: 1,
                    widths: [180, '*'],
                    body: [
                        [{ text: 'Anchor information:', style: 'header2', colSpan: 2 }, {}],
                        [
                            {
                                text: i18n.t('Title'), style: 'header3'
                            },
                            {
                                text: anchorProfile.naziv, style: 'cellHeader'
                            }
                        ],
                        [
                            {
                                text: i18n.t('Contact person'), style: 'header3'
                            },
                            {
                                text: anchorProfile.kontakt_osoba, style: 'cellHeader'
                            }
                        ],
                        [
                            {
                                text: i18n.t('BVN'), style: 'header3'
                            },
                            {
                                text: anchorProfile.bpg, style: 'cellHeader'
                            }
                        ],
                        [
                            {
                                text: i18n.t('Address'), style: 'header3'
                            },
                            {
                                text: `${anchorProfile._opstina}, ${anchorProfile._katastarska_opstina}, ${anchorProfile.ulica_i_broj}`, style: 'cellHeader'
                            }
                        ]
                    ]
                },
                margin: [0, 20, 0, 8],
                layout: 'lightHorizontalLines'
            },
            {
                table: {
                    headerRows: 1,
                    widths: [180, '*'],
                    body: [
                        [{ text: 'Farmers cluster information:', style: 'header2', colSpan: 2 }, {}],
                        [
                            {
                                text: i18n.t('Title'), style: 'header3'
                            },
                            {
                                text: cosProfile.naziv, style: 'cellHeader'
                            }
                        ],
                        [
                            {
                                text: i18n.t('Contact person'), style: 'header3'
                            },
                            {
                                text: cosProfile.kontakt_osoba, style: 'cellHeader'
                            }
                        ],
                        [
                            {
                                text: i18n.t('BVN'), style: 'header3'
                            },
                            {
                                text: cosProfile.bpg, style: 'cellHeader'
                            }
                        ],
                        [
                            {
                                text: i18n.t('Address'), style: 'header3'
                            },
                            {
                                text: `${cosProfile._opstina}, ${cosProfile._katastarska_opstina}, ${cosProfile.ulica_i_broj}`, style: 'cellHeader'
                            }
                        ]
                    ]
                },
                margin: [0, 10, 0, 8],
                layout: 'lightHorizontalLines'
            }
        ],
        styles: {
            header: {
                fontSize: 22,
                bold: true
            },
            header1: {
                fontSize: 20,
                bold: true
            },
            header2: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5]
            },
            header3: {
                fontSize: 14
            },
            tableHeader: {
                fontSize: 14,
                margin: [0, 5, 0, 5],
                alignment: 'center'
            },
            tableBody: {
                fontSize: 13
            },
            cellHeader: {
                fontSize: 12,
                bold: true
            }
        }
    };

    var farmers = {
        style: 'tableExample',
        table: {
            headerRows: 1,
            widths: ['*', 110, 150],
            body: [
                [{ text: i18n.t('Farmer'), style: 'tableHeader' },
                { text: i18n.t('BVN'), style: 'tableHeader' },
                { text: i18n.t('Amount asked(NGN)'), style: 'tableHeader' }]
            ]
        },
        margin: [0, 20, 0, 8],
        layout: {
            hLineWidth: function (i, node) {
                return (i === 0 || i === 1 || i === node.table.body.length) ? 2 : 1;
            },
            vLineWidth: function (i, node) {
                return (i === 0 || i === node.table.widths.length) ? 2 : 1;
            },
            hLineColor: function (i, node) {
                return (i === 0 || i === 1 || i === node.table.body.length) ? 'black' : 'gray';
            },
            vLineColor: function (i, node) {
                return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
            },
            fillColor: function (rowIndex, node, columnIndex) { return rowIndex == 0 ? '#eeeeee' : null; }
        }
    };

    data._subRows.map((item) => {
        farmers.table.body.push([{ text: item.kontakt_osoba, style: 'tableBody', alignment: 'center' },
        { text: item.bpg, style: 'tableBody', alignment: 'center' },
        { text: item.value_asked, style: 'tableBody', alignment: 'right' }])
    });

    farmers.table.body.push([
        { text: 'Total:', style: 'tableHeader', colSpan: 2, alignment: 'left' },
        {},
        { text: data.value_asked, style: 'tableHeader', alignment: 'right' }]);

    dd.content.push(farmers);

    dd.content.push({
        alignment: 'justify',
        columns: [
            {
                text: `${i18n.t('Anchor')}: _________________________`
            },
            {
                text: `${i18n.t('Agent')}: _________________________`
            }
        ],
        margin: [0, 50, 0, 8],
    });
    pdfMake.createPdf(dd).download();
}