import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n/i18n';
import { Button, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCrop, getGroup, getSubgroup, addCrop, setInitialState } from '../../../actions/resources/CropActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
export class AddCrop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cropObj: this.props.cropObj
        }
    }

    componentDidMount() {
        this.props.getGroup();
        this.props.getSubgroup();
        this.props.getCrop();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.cropPostingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('cropPostingFailed');
        }
    }

    handleChangeValue = name => event => {
        let obj = Object.assign({}, this.state.cropObj);
        obj[name] = event.target.value;
        this.setState({ cropObj: obj });
        if ([name] == 'id_kultura_grupa') {
            this.props.getSubgroup(event.target.value);
        }
    };

    addCrop() {
        if (this.state.cropObj.naziv
            && this.state.cropObj.id_kultura_grupa
            && this.state.cropObj.id_kultura_podgrupa) {
            this.props.addCrop(
                this.state.cropObj
            )

        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80,
            });
        }
    }


    render() {
        let group = [];
        if (this.props.cropGroupDropdown && !this.props.groupFetching) {
            group = this.props.cropGroupDropdown.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let subgroup = [];
        if (this.props.cropSubgroupDropdown && !this.props.subgroupFetching) {
            subgroup = this.props.cropSubgroupDropdown.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        return (
            <div style={{ padding: 20 }}>

                {/* <Paper className="roundedBoxMaterial" zdepth={4}> */}
                <Dialog
                    open={this.props.add}
                    onClose={this.props.handleClose}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit crop') : i18n.t('Add crop')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-crop">
                            <Grid container justify="space-between" alignContent="space-between" spacing={8}>
                                <Grid item >
                                    <TextField
                                        label={i18n.t('Title')}
                                        required
                                        value={this.state.cropObj.naziv}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={this.handleChangeValue('naziv')}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('Code')}
                                        value={this.state.cropObj.sifra_kp}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={this.handleChangeValue('sifra_kp')}
                                        type={'number'}
                                    />
                                </Grid>
                                <Grid item >
                                    <TextField
                                        label={i18n.t('Organic production')}
                                        value={this.state.cropObj.sifra_op}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={this.handleChangeValue('sifra_op')}
                                        type={'number'}
                                    />
                                </Grid>
                                <Grid item >
                                    <TextField
                                        label={i18n.t('Parcel color')}
                                        value={this.state.cropObj.boja_table}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={this.handleChangeValue('boja_table')}

                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        select
                                        label={i18n.t('Group')}
                                        value={this.state.cropObj.id_kultura_grupa}
                                        onChange={this.handleChangeValue('id_kultura_grupa')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        required
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <option value='' />
                                        {group}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        select
                                        label={i18n.t('Subgroup')}
                                        disabled={!this.state.cropObj.id_kultura_grupa}
                                        value={this.state.cropObj.id_kultura_podgrupa}
                                        onChange={this.handleChangeValue('id_kultura_podgrupa')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        required
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <option value='' />
                                        {subgroup}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('Average yield')}
                                        value={this.state.cropObj.prosecan_prinos}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={this.handleChangeValue('prosecan_prinos')}
                                        type={'number'}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('Precipitation of')}
                                        value={this.state.cropObj.padavine_od}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={this.handleChangeValue('padavine_od')}
                                        type={'number'}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('Precipitation up to')}
                                        value={this.state.cropObj.padavine_do}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={this.handleChangeValue('padavine_do')}
                                        type={'number'}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('Sum of mean daily temperatures of')}
                                        value={this.state.cropObj.stepeni_od}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={this.handleChangeValue('stepeni_od')}
                                        type={'number'}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('Sum of average daily temperatures up to')}
                                        value={this.state.cropObj.stepeni_do}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={this.handleChangeValue('stepeni_do')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        type={'number'}
                                    />
                                </Grid>
                                <Grid item xs>

                                    <Button
                                        style={{ alignSelf: 'center', backgroundColor: '#58B4E5', color: 'white' }}
                                        onClick={() => this.addCrop()}
                                    >
                                        {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
                {/* </Paper> */}
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        cropGroupDropdown: state.cropReducer.cropGroupDropdown,
        cropObj: state.cropReducer.cropObj,
        groupFetching: state.cropReducer.groupFetching,
        groupFetchingFailed: state.cropReducer.groupFetchingFailed,
        cropSubgroupDropdown: state.cropReducer.cropSubgroupDropdown,
        subgroupFetching: state.cropReducer.subgroupFetching,
        subgroupFetchingFailed: state.cropReducer.subgroupFetchingFailed,
        cropPosting: state.cropReducer.cropPosting,
        cropPostingSuccess: state.cropReducer.cropPostingSuccess,
        cropPostingFailed: state.cropReducer.cropPostingFailed
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getGroup: () => dispatch(getGroup()),
        getSubgroup: (id) => dispatch(getSubgroup(id)),
        getCrop: () => dispatch(getCrop()),
        setInitialState: (component) => dispatch(setInitialState(component)),
        addCrop: (cropObj) => dispatch(addCrop(cropObj)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCrop)