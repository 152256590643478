import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n/i18n';
import { Button, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//  import InputLabel from '@material-ui/core/InputLabel';
import { getMaterialDropdown } from '../../../actions/resources/MaterialActions';
import { addIncentive, getFarmersDropdown, setInitialState } from '../../../actions/financial/IncentiveActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment';
export class AddIncentives extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            incentivesObj: this.props.incentivesObj
        }
    }

    componentDidMount() {
        this.props.getMaterialDropdown();
        this.props.getFarmersDropdown();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.incentivesPostingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('incentivesPostingFailed');
        }
    }

    handleChangeValue = name => event => {
        let obj = Object.assign({}, this.state.incentivesObj);
        obj[name] = event.target.value;
        this.setState({ incentivesObj: obj });
    };

    handleDateChange = name => date => {
        let obj = Object.assign({}, this.state.incentivesObj);
        obj[name] = moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY');
        this.setState({ incentivesObj: obj });
    }

    addIncentive() {
        if (this.state.incentivesObj.id_materijal &&
            this.state.incentivesObj.kolicina &&
            this.state.incentivesObj.id_klijent_ulaz) {
            this.props.addIncentive(this.state.incentivesObj);
        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        }
    }


    renderLabel = (date) => {
        if (date.isValid()) {
            return date.format('DD.MM.YYYY');
        } else {
            return '';
        }
    }


    render() {
        let materialDropdown = [];
        if (this.props.materialDropdown && !this.props.materialDropdownFetching) {
            materialDropdown = this.props.materialDropdown.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let farmers = [];
        if (this.props.farmerDropdown && !this.props.farmerDropdownFetching) {
            farmers = this.props.farmerDropdown.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.kontakt_osoba}</option>
            });
        }
        return (
            <div style={{ padding: 20 }}>

                {/* <Paper className="roundedBoxMaterial" zdepth={4}> */}
                <Dialog
                    open={this.props.add}
                    onClose={this.props.handleClose}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('Add incentive')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <form id="add-material">
                            <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                                <Grid item xs={12} sm={6} >
                                    <TextField
                                        select
                                        label={i18n.t('Material')}
                                        value={this.state.incentivesObj.id_materijal}
                                        onChange={this.handleChangeValue('id_materijal')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        required
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <option value='' />
                                        {materialDropdown}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        select
                                        label={i18n.t('Farmer')}
                                        value={this.state.incentivesObj.id_klijent_ulaz}
                                        onChange={this.handleChangeValue('id_klijent_ulaz')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        required
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <option value='' />
                                        {farmers}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <DatePicker
                                        keyboard
                                        label={i18n.t('Date *')}
                                        format="DD.MM.YYYY"
                                        value={this.state.incentivesObj.datum ? moment(this.state.incentivesObj.datum, 'DD.MM.YYYY') : new Date()}
                                        onChange={this.handleDateChange('datum')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        disableOpenOnEnter
                                        labelFunc={this.renderLabel}
                                        animateYearScrolling={false}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label={i18n.t('Quantity(kg)')}
                                        required
                                        type="number"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.state.incentivesObj.kolicina}
                                        onChange={this.handleChangeValue('kolicina')} />
                                </Grid>
                                <Grid item xs={12} sm={12} >
                                    <TextField
                                        multiline
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        id="full-width"
                                        margin="normal"
                                        label={i18n.t('Description')}
                                        value={this.state.incentivesObj.opis}
                                        onChange={this.handleChangeValue('opis')}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Button
                                        style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white' }}
                                        onClick={() => this.addIncentive()}
                                    >
                                        {i18n.t('Add')}
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
                {/* </Paper> */}
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        materialDropdown: state.materialReducer.materialDropdown,
        materialDropdownFetching: state.materialReducer.materialDropdownFetching,
        materialDropdownFetchingFailed: state.materialReducer.materialDropdownFetchingFailed,
        farmerDropdown: state.incentiveReducer.farmerDropdown,
        incentivesPostingFailed: state.incentiveReducer.incentivesPostingFailed,
        farmerDropdownFetching: state.incentiveReducer.farmerDropdownFetching,
        farmerDropdownFetchingFailed: state.incentiveReducer.farmerDropdownFetchingFailed,
        incentivesObj: state.incentiveReducer.incentivesObj
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getMaterialDropdown: () => dispatch(getMaterialDropdown()),
        getFarmersDropdown: () => dispatch(getFarmersDropdown()),
        setInitialState: () => dispatch(setInitialState()),
        addIncentive: (incentivesObj) => dispatch(addIncentive(incentivesObj)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddIncentives)