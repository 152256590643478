import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { getGroup, getWorkingOperations, setInitialState, setRowInReducer } from '../../../actions/resources/WorkingOperationsActions';
import i18n from '../../../i18n/i18n';
import Select from 'react-select';
import immutable from 'seamless-immutable';
import AddWorkingOperation from './AddWorkingOperation';
import checkboxHOC from "react-table/lib/hoc/selectTable";
const CheckboxTable = checkboxHOC(ReactTable);
export class ViewWorkingOperation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            add: this.props.add,
            edit: this.props.edit,
            showFilters: this.props.showFilters,
            selection: [],
            selectAll: false
        };
    }

    componentDidMount() {
        if (this.props.woGroupDropdown.length == 0) {
            this.props.getGroup();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.deleted === true) {
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getWorkingOperations(wrapped.state);
            this.setState({
                selection: [],
                selectAll: false,
            })
            this.props.setInitialState('deleted');

        }
        if (nextProps.workingOperationPostingSuccess === true) {
            this.setState({
                selection: [],
                name: '',
                selectAll: false
            })
            this.props.handleClose();
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getWorkingOperations(wrapped.state);
            this.props.setInitialState('workingOperationPostingSuccess');
        }
        this.setState({
            add: nextProps.add,
            showFilters: nextProps.showFilters,
            woGroupDropdown: immutable.asMutable(nextProps.woGroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            groupFetching: nextProps.groupFetching,
            groupFetchingFailed: nextProps.groupFetchingFailed,
        })
        if (nextProps.add == true && nextProps.edit == false) {
            this.props.setInitialState('workingOperationObj');
            this.setState({
                selection: [],
                name: '',
                selectAll: false
            })
        }
    }

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            selection.push(key);
            this.props.setRowInReducer(row, 'workingOperationObj');
        }
        this.setState({ selection }, function () {
            if (selection.length == 1) {
                let row1 = this.props.working_operation.filter((wo) => {
                    return wo.id == selection;
                })
                this.props.setRowInReducer(row1[0], 'workingOperationObj');
            }
        });
        this.props.setSelection(selection, 'working_operation');
    };

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });
        this.props.setSelection(selection, 'working_operation');

    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    render() {
        let content;
        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "#ACDAF1" : "inherit"
                        }
                    };
                }
                return {};
            }
        };
        content =
            <div className="page">
                <CheckboxTable data={this.props.working_operation}
                    columns={this.columns}
                    ref={r => (this.checkboxTable = r)}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    showPaginationTop
                    showPaginationBottom={false}
                    pages={this.props.workingOperationPages}
                    loading={this.props.workingOperationFetching}
                    manual
                    onFetchData={(state, instance) => this.props.getWorkingOperations(state, instance)}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF',
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
                {this.state.add && <AddWorkingOperation add={this.state.add} edit={this.props.edit}
                    handleClose={this.props.handleClose} />}
            </div>


        return (
            <div>
                {content}
            </div>
        )
    }

    columns = [
        {
            Header: i18n.t('Title'),
            accessor: 'naziv'
        },
        {
            Header: i18n.t('Code'),
            accessor: 'sifra',
            // Filter: ({filter, onChange }) =>
            //     <Select
            //         isMulti
            //         name="naziv"
            //         onChange={value => {
            //             onChange(value)
            //         }}
            //         closeMenuOnSelect={false}
            //         options={this.state.zones}
            //         className="multi-select-in-filter"
            //         classNamePrefix="select"
            //     />
        },
        {
            Header: i18n.t('Working operation group name'),
            accessor: 'radna_operacija_grupa_naziv',
            Filter: ({ filter, onChange }) =>
                <Select
                    isMulti
                    name="naziv"
                    onChange={value => {
                        onChange(value)
                    }}
                    closeMenuOnSelect={false}
                    options={this.state.woGroupDropdown}
                    className="multi-select-in-filter"
                    classNamePrefix="select"
                    placeholder={i18n.t('Select')}
                />
        },

    ];
};

function mapStateToProps(state) {
    return {
        working_operation: state.workingOperationReducer.working_operation,
        deleted: state.workingOperationReducer.deleted,
        workingOperationFetching: state.workingOperationReducer.workingOperationFetching,
        workingOperationPages: state.workingOperationReducer.workingOperationPages,
        workingOperationPostingSuccess: state.workingOperationReducer.workingOperationPostingSuccess,
        woGroupDropdown: state.workingOperationReducer.woGroupDropdown,
        groupFetching: state.workingOperationReducer.groupFetching,
        groupFetchingFailed: state.workingOperationReducer.groupFetchingFailed,

    }
}

function mapDispatchToProps(dispatch) {
    return {
        getGroup: () => dispatch(getGroup()),
        setInitialState: (component) => dispatch(setInitialState(component)),
        setRowInReducer: (rowInfo, name) => dispatch(setRowInReducer(rowInfo, name)),
        getWorkingOperations: (state, instance) => dispatch(getWorkingOperations(state, instance)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewWorkingOperation)