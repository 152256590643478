import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import 'react-table/react-table.css';
import {
    getLocalGov,
} from '../../../actions/FarmRegistarActions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getStates } from '../../../actions/AdministrationActions';
import { getMaterialDropdown } from '../../../actions/resources/MaterialActions';
import { getIncentives, setInitialState, setRowInReducer, deleteIncentive, getFarmersDropdown } from '../../../actions/financial/IncentiveActions';
import i18n from '../../../i18n/i18n';
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import { getUser } from '../../../utils/CommonFunctions';
import Grid from "@material-ui/core/Grid";
import immutable from 'seamless-immutable';
import { withRouter } from 'react-router-dom'
import { Button, IconButton, Fab } from '@material-ui/core';
import AddIncentives from './AddIncentives';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import GPSReport from "./GPSReport";
import Statistics from "../../reports/Statistics";
import { Delete } from '../../../utils/Delete';
import ProfilePreview from '../../../utils/ProfilePreview';
import { exportDocument } from '../../../actions/ExportActions';
import Export from '../../../utils/Export';
import DashboardDrawer from '../../../utils/DashboardDrawer';
import moment from 'moment';
import { DatePicker } from 'material-ui-pickers';
const CheckboxTable = checkboxHOC(ReactTable);

export class ViewIncentives extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            add: false,
            selection: [],
            selectAll: false,
            delete: false,
            view: false,
            user: {},
            isStatisticShown: false,
            profile: false,
        };
        this.tableScrollTop = 0;
    }

    componentDidMount() {
        if (this.props.states.length === 0) {
            this.props.getStates();
        }
        if (this.props.localGoverments.length === 0) {
            this.props.getLocalGov();
        }
        if (this.props.materialDropdown.length === 0) {
            this.props.getMaterialDropdown();
        }
        if (this.props.farmerDropdown.length === 0) {
            this.props.getFarmersDropdown();
        }
        this.setState({
            user: getUser()
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.deleted === true) {
            const wrapped = this.checkboxTable.getWrappedInstance();;
            this.props.getIncentives(wrapped.state);
            this.setState({
                selection: [],
                selectAll: false,
            })
            this.props.setInitialState('incentivesObj');
            this.props.setInitialState('deleted');
        }
        if (nextProps.incentivesPostingSuccess === true) {
            this.handleClick();
            const wrapped = this.checkboxTable.getWrappedInstance();;
            this.props.getIncentives(wrapped.state);
            this.props.setInitialState('incentivesPostingSuccess');
        }
        if (nextProps.deleteIncentiveFailed === true) {
            toast.error(i18n.t('This item can not be deleted!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('deleteIncentiveFailed');
        }
        this.setState({
            states: immutable.asMutable(nextProps.states.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            localGoverments: immutable.asMutable(nextProps.localGoverments.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            farmerDropdown: immutable.asMutable(nextProps.farmerDropdown.map((item) => { return { label: item.kontakt_osoba, value: item.id } }), { deep: true }),
            materialDropdown: immutable.asMutable(nextProps.materialDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
        })
    }

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    onExportClick = () => {
        this.setState({ export: !this.state.export });
    }
    exportFunction = () => {
        const table = this.checkboxTable.getWrappedInstance();
        this.props.exportDocument(this.state.docTitle, table.state, 'api/incentive/read', this.state.docFormat || 'xlsx');
        this.setState({ export: !this.state.export });
    }

    handleClick = () => {
        this.props.setInitialState('incentivesObj')
        this.setState({ add: !this.state.add }, () => {
            if (this.state.add == true) {
                this.setState({
                    selection: [],
                    name: '',
                    selectAll: false,
                })
            }
        });
    };

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            selection.push(key);
        }
        this.setState({ selection }, function () {
            if (selection.length == 1) {
                let row1 = this.props.incentives.filter((inc) => {
                    return inc.id == selection;
                })
                this.props.setRowInReducer(row1[0], 'incentivesObj');
            }
        });
    };

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });
    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    onDeleteClick = () => {
        this.setState({ delete: !this.state.delete });
    }

    deleteItem = () => {
        this.props.deleteIncentive(this.state.selection);
        this.setState({ delete: false })
    }

    viewClick = () => {
        this.setState({ view: !this.state.view })
    };
    onProfileClick = () => {
        this.setState({ profile: !this.state.profile });
    }

    handleGPSReportOkClick = () => {
        this.setState({ isGPSReportShown: false })
    };

    showGPSReport = () => {
        this.setState({ isGPSReportShown: true });
    };

    showReport = () => {
        this.setState({ isStatisticShown: !this.state.isStatisticShown })
    }

    onPieClick = () => {
        this.props.history.push('/incentive_dashboard');
    }

    onTableClick = () => {
        this.props.history.push('/incentive');
    }

    renderLabel = (date) => {
        if (date.isValid()) {
            return date.format('DD.MM.YYYY');
        } else {
            return '';
        }
    }

    render() {
        const { user } = this.state;
        const columns = [
            {
                Header: i18n.t('Title'),
                accessor: 'titula_naziv',
            },
            {
                Header: i18n.t('Contact person'),
                accessor: 'kontakt_osoba',
            },
            {
                Header: i18n.t('Phone'),
                accessor: 'kontakt_telefon'
            },
            {
                Header: i18n.t('Address'),
                accessor: 'ulica_i_broj'
            },
            {
                Header: i18n.t('State'),
                accessor: 'opstina',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.states}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Local government'),
                accessor: 'katastarska_opstina',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.localGoverments}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('BVN'),
                accessor: 'bpg',
                show: user.id_klijent_grupa == 14 ? false : true,
            },
            {
                Header: i18n.t('Email'),
                accessor: 'kontakt_mejl'
            },
            {
                id: 'datum_rodjenja',
                Header: i18n.t('Date of birth'),
                accessor: d => d.datum_rodjenja ? moment(d.datum_rodjenja).format('DD.MM.YYYY') : '',
            },
            {
                id: 'gazdinstvo',
                Header: i18n.t('Verified farmer'),
                accessor: d => d.gazdinstvo,
                Cell: ({ value }) => value === true ? i18n.t('Yes') : i18n.t('No')
            },
            {
                Header: i18n.t('Farmers name'),
                accessor: 'klijent_ulaz_naziv',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.farmerDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                id: 'datum',
                Header: i18n.t('Date'),
                accessor: d => d.datum ? moment(d.datum, 'DD.MM.YYYY').format('DD.MM.YYYY') : '',
            },
            {
                Header: i18n.t('Material code'),
                accessor: 'materijal_sifra',
            },
            {
                Header: i18n.t('Material title'),
                accessor: 'materijal_naziv',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.materialDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Description'),
                accessor: 'opis',
            },
            {
                Header: i18n.t('Quantity(kg)'),
                accessor: 'kolicina'
            },

        ];
        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "#ACDAF1" : "inherit"
                        }
                    };
                }
                return {};
            }
        };
        return (
            <div className="page">
                <div className="table-header">
                    <Fab
                        onClick={() => this.handleClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-Plus headerIcon" />
                    </Fab>
                    <Fab
                        onClick={() => this.onExportClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-download headerIcon" />
                    </Fab>
                    <Fab
                        onClick={() => this.onSearchClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-search headerIcon" />
                    </Fab>
                    {this.state.selection.length > 0 ?
                        <Fab
                            onClick={() => this.onDeleteClick()}
                            color="secondary"
                            aria-label="add"
                            className="action-button"
                        >
                            <span className="icon-Kanta headerIcon" />
                        </Fab>
                        : null
                    }
                    {this.state.selection.length === 1 ?
                        <Fab
                            onClick={() => this.viewClick()}
                            color="secondary"
                            aria-label="edit"
                            className="action-button"
                        >
                            <span className=" icon-Informacija headerIcon" />
                        </Fab>
                        : null
                    }
                </div>
                <CheckboxTable
                    ref={r => (this.checkboxTable = r)}
                    data={this.props.incentives}
                    getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                    columns={columns}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.props.incentivesPages}
                    loading={this.props.incentivesFetching}
                    manual
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => this.props.getIncentives(state, instance)}
                    className="-striped -highlight table-drawer"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF',
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
                <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} />
                {this.state.export && <Export
                    export={this.state.export}
                    onTextChange={({ title }) => {
                        this.setState({ docTitle: title });
                    }}
                    changeFormat
                    onformatChange={(format) => {
                        this.setState({ docFormat: format });
                    }}
                    handleClose={this.onExportClick}
                    exportDoc={this.exportFunction} />
                }
                {this.state.add && <AddIncentives add={this.state.add}
                    handleClose={this.handleClick} />}
                {this.state.profile &&
                    <ProfilePreview
                        profile={this.state.profile}
                        handleClose={this.onProfileClick}
                        clientId={this.props.incentivesObj.id_klijent_ulaz}
                    />}
                {this.state.delete && <Delete delete={this.state.delete} handleClose={this.onDeleteClick} deleteItem={this.deleteItem} />}
                {this.state.view &&
                    <Dialog
                        open={this.state.view}
                        onClose={this.viewClick}
                        disableBackdropClick
                        maxWidth="sm"
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('View incentive')}
                            <IconButton onClick={this.viewClick}>
                                <span className="icon-cancel-circle closeIcon" />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                                <Grid item xs={12} sm={6} >
                                    <TextField
                                        label={i18n.t('Material')}
                                        defaultValue={this.props.incentivesObj.materijal_naziv}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('Farmer')}
                                        defaultValue={this.props.incentivesObj.klijent_ulaz_naziv}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <DatePicker
                                        label={i18n.t('Date')}
                                        format="DD.MM.YYYY"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={moment(this.props.incentivesObj.datum, 'DD.MM.YYYY')}
                                        animateYearScrolling={false}
                                        labelFunc={this.renderLabel}
                                        disableOpenOnEnter
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('Quantity(kg)')}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.props.incentivesObj.kolicina}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        label={i18n.t('Description')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.props.incentivesObj.opis}
                                        multiline
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button onClick={this.onProfileClick} style={{ backgroundColor: '#61a50e', color: 'white' }}>
                                        {i18n.t('Profile')}
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button onClick={this.showReport} style={{ backgroundColor: '#61a50e', color: 'white' }}>
                                        {i18n.t('Report')}
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button onClick={this.showGPSReport} style={{ backgroundColor: '#61a50e', color: 'white' }}>
                                        {i18n.t('GPS report')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                }
                {this.state.isGPSReportShown &&
                    <Dialog
                        open={this.state.isGPSReportShown}
                        disableBackdropClick
                        maxWidth="sm"
                        aria-labelledby="responsive-dialog-title"
                    >
                        <GPSReport idClient={this.props.incentivesObj.id_klijent_ulaz} handleClose={this.handleGPSReportOkClick} />
                    </Dialog>
                }
                {this.state.isStatisticShown &&
                    <Dialog
                        open={this.state.isStatisticShown}
                        maxWidth="xl"
                        aria-labelledby="responsive-dialog-title"
                    >
                        <Statistics kontakt_osoba={this.props.incentivesObj.kontakt_osoba} isStatisticShown={this.state.isStatisticShown} handleClose={this.showReport} />
                    </Dialog>
                }
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        incentives: state.incentiveReducer.incentives,
        incentivesObj: state.incentiveReducer.incentivesObj,
        incentivesFetching: state.incentiveReducer.incentivesFetching,
        incentivesPostingSuccess: state.incentiveReducer.incentivesPostingSuccess,
        incentivesPages: state.incentiveReducer.incentivesPages,
        deleted: state.incentiveReducer.deleted,
        deleteIncentiveFailed: state.incentiveReducer.deleteIncentiveFailed,
        localGoverments: state.farmRegistarReducer.localGoverments,
        farmerDropdown: state.incentiveReducer.farmerDropdown,
        states: state.administrationReducer.states,
        materialDropdown: state.materialReducer.materialDropdown,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getStates: () => dispatch(getStates()),
        getLocalGov: () => dispatch(getLocalGov()),
        getMaterialDropdown: () => dispatch(getMaterialDropdown()),
        getFarmersDropdown: () => dispatch(getFarmersDropdown()),
        setInitialState: (component) => dispatch(setInitialState(component)),
        setRowInReducer: (rowInfo, name) => dispatch(setRowInReducer(rowInfo, name)),
        getIncentives: (state, instance) => dispatch(getIncentives(state, instance)),
        deleteIncentive: (selection) => dispatch(deleteIncentive(selection)),
        exportDocument: (title, state, url, format) => dispatch(exportDocument(title, state, url, format)),

    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewIncentives));