import React from 'react';
import i18n from '../../../i18n/i18n';
import axios from '../../../utils/AxiosWrapper';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import DatePickerMeteo from '../../../utils/DatePickerMeteo';
import { formatData, formatForecast, formatDisease } from '../DataFormatters';
import { withStyles } from '@material-ui/core/styles';
import { MoonLoader } from 'react-spinners';
import MeteoTable from '../MeteoTable';

const styles = {};

export class AppleGeneralFireblight extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            station_id: this.props.row.uid,
            startDate: moment().subtract(1, 'days'),
            endDate: moment(),
            disease1: {},
            disease2: {},
            data: {},
            loadDisease: true,
            loadData: true
        };
    }

    setDates = (start, end) => { this.setState({ startDate: start, endDate: end }, () => { this.getPessl(); }); }

    // Get funkcije za preuzimanje podataka sa stanica samo po potrebi ubaciti, pored Disease-a uvek mora i Data zbog datuma
    getDisease = (path, disease) => {
        let obj = {};
        axios.post('/api/testMeteo/meteo', path)
            .then(res => { obj = formatDisease(res, obj); this.setState({ [disease]: obj, loadDisease: false }); })
            .catch(err => console.log('Error: ', err))
    }

    getData = (path) => {
        axios.post('/api/testMeteo/meteo', path)
            .then(res => { let obj = formatData(res); this.setState({ data: obj, loadData: false }); })
            .catch(err => console.log('Error: ', err))
    }

    // getForecast = (path) => {
    //     axios.post('/api/testMeteo/meteo', path)
    //         .then(res => { let obj = formatForecast(res); this.setState({ forecast: obj }); })
    //         .catch(err => console.log('Error: ', err))
    // }

    getPessl = () => {
        let start_date = moment(this.state.startDate).unix();
        let end_date = moment(this.state.endDate).unix();
        let station_id = this.state.station_id;

        // URL-ovi za podatke sa meteo stanica
        let disease_path = '/data/' + station_id + '/hourly/from/' + start_date + '/to/' + end_date;
        let forecast_path = '/forecast/' + station_id + '/basic-1h';
        let data_path = '/data/' + station_id + '/hourly/from/' + start_date + '/to/' + end_date;

        // Disease mora POST method, za Data i Forecast mora GET method
        this.getDisease({ path: disease_path, name: "GeneralFireblight", method: "POST" }, 'disease1');
        this.getData({ path: data_path, method: "GET" });
        // this.getForecast({ path: forecast_path, name: 'general7', method: "POST" });
    }

    render() {
        const { classes } = this.props;

        //console.log('disease1: ', this.state.disease1);
        // console.log('disease2: ', this.state.disease2);
        // console.log('data meteo: ', this.state.data);

        let graph1 = {};
        let graph2 = {};
        let graph3 = {};
        let graph4 = {};

        const options = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            minTicksLimit:400,
                            max: 400,
                            maxTicksLimit:400
                        }
                        
                    },
                    //    {
                    //        type: 'linear',
                    //        display: true,
                    //        position: 'right',
                    //        id: 'y-axis-2',
                    //        gridLines: {
                    //            drawOnChartArea: false,
                    //        },
                    //        ticks: {
                    //            beginAtZero: true,
                    //            min: 0,
                    //        }
                    //    },
                ],
            },
        };

        const options2 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-5',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[°C]'
                        },
                        
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-4',
                        gridLines: {
                            drawOnChartArea: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[min/h]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 60
                        }
                    },
                ],
            },
        };

        // const options3 = {
        //     scales: {
        //         yAxes: [
        //             {
        //                 type: 'linear',
        //                 display: true,
        //                 position: 'left',
        //                 id: 'y-axis-3',
        //                 gridLines: {
        //                     drawOnChartArea: false,
        //                 },
        //                 scaleLabel: {
        //                     display: true,
        //                     labelString: '[min/h]'
        //                 },
        //                 ticks: {
        //                     beginAtZero: true,
        //                     min: 0,
        //                     max: 60
        //                 }
        //             },
        //             {
        //                 type: 'linear',
        //                 display: true,
        //                 position: 'right',
        //                 id: 'y-axis-4',
        //                 gridLines: {
        //                     drawOnChartArea: false,
        //                 },
        //                 scaleLabel: {
        //                     display: true,
        //                     labelString: '[min/h]'
        //                 },
        //                 ticks: {
        //                     beginAtZero: true,
        //                     min: 0,
        //                     max: 60
        //                 }
        //             },
        //         ],
        //     },
        // };
        if (
            this.state.data.dates &&
            this.state.disease1 &&
            this.state.disease2
        ) {
            graph1 = {
                labels: this.state.data.dates,
                datasets: [
                    {
                        data: this.state.disease1['fireblight_div'],
                        label: i18n.t('Blight'),
                        backgroundColor: 'transparent',
                        borderColor: 'red',
                        type: 'line',
                        borderWidth: 2,
                        pointRadius: this.state.data.dates.length > 72 ? 0 : 2,
                        yAxisID: 'y-axis-1',
                    },
                    //  {
                    //      data: this.state.disease1['Risk Sum'],
                    //      label: i18n.t('Risk Sum'),
                    //      type: 'line',
                    //      backgroundColor: 'transparent',
                    //      borderColor: 'blue',
                    //      borderWidth: 2,
                    //      pointRadius: this.state.data.dates.length > 72 ? 0 : 2,
                    //      yAxisID: 'y-axis-2',
                    //  },
                ],
            };
            graph3 = {
                labels: this.state.data.dates,
                datasets: [
                    {
                        data: this.state.data.temperatures,
                        label: i18n.t('Temperature [°C]'),
                        backgroundColor: 'transparent',
                        borderColor: 'red',
                        type: 'line',
                        borderWidth: 2,
                        pointRadius: this.state.data.dates.length > 72 ? 0 : 2,
                        yAxisID: 'y-axis-5',
                    },
                    {
                        data: this.state.data.leaf_wetness,
                        label: i18n.t('Leaf wetness [min/h]'),
                        backgroundColor: '#7CFC00',
                        borderColor: '#7CFC00',
                        type: 'bar',
                        borderWidth: 2,
                        pointRadius: this.state.data.dates.length > 72 ? 0 : 2,
                        yAxisID: 'y-axis-4',
                    },

                ],
            };
        };

        return (
            <div style={{ paddingLeft: '35px' }}>
                <div style= {{display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                        <p style={{fontSize: 20, marginRight: 4 }}>{i18n.t('Fire Blight')}</p>
                        <p style={{fontSize: 20, fontStyle: 'italic'}}>(Erwinia amylovora)</p>
                        </div>
                </div>
                <DatePickerMeteo startDate={this.state.startDate} endDate={this.state.endDate} setDates={this.setDates} getPessl={this.getPessl} />
                {(this.state.loadDisease === true || this.state.loadData === true) && <div style={{ marginLeft: '39%', marginTop: '14%' }}> <MoonLoader loading={this.state.loadDisease === true || this.state.loadData === true} size={100} /></div>}
                {this.state.loadDisease === false && this.state.loadData === false &&
                    <div>

                        <div style={{ width: '95%', marginTop: '20px' }}>
                            <Bar data={graph1} height={300} width={600} options={options} />
                        </div>
                        <div style={{ padding: '20px', marginRight: '35px', borderRadius: '8px', boxShadow: ' 0 5px 10px rgba(154, 160, 185, 0.2), 0 15px 40px rgba(166, 173, 201, 0.1)', backgroundColor: 'white !important' }}>
                            <p style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Info:</p>
                            <p>{i18n.t('The daily value of infection refers to temperature conditions, for the prediction model of calculating are used the daily temperatures higher than 18.3℃.')}</p>
                            <p>{i18n.t('When the sum is in the values around 100-150, then the conditions are ideal for the epidemic of blight on the flower.')}</p>
                            <p>{i18n.t('Values of 200+ represent ideal conditions for an outbreak of infection on all plant parts.')}</p>
                        </div>
                        <div style={{ width: '95%', marginTop: '80px', }}>
                            <Bar data={graph3} height={200} width={400} options={options2} />
                        </div>
                        <MeteoTable data={{
                            headers: [i18n.t('Date and time'), i18n.t('Temperature [°C]'), i18n.t('Leaf wetness [min/h]')],
                            dates: this.state.data.dates,
                            temperatures: this.state.data.temperatures,
                            leaf_wetness: this.state.data.leaf_wetness
                        }} />
                          <div style={{ marginTop: '20px' }}></div>
                    </div>
                }
            </div>
        );
    }
};

export default withStyles(styles)(AppleGeneralFireblight);
