import React from 'react';
import i18n from '../../../i18n/i18n';
import axios from '../../../utils/AxiosWrapper';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { Button, Card, Link, Typography } from '@material-ui/core';
import DatePickerMeteo from '../../../utils/DatePickerMeteo';
import { formatData, formatForecast, formatDisease } from './../DataFormatters';
import { withStyles } from '@material-ui/core/styles';
import { MoonLoader } from 'react-spinners';
import MeteoTable from '../MeteoTable';

const styles = {};

export class RaspberryGeneralPhragmidiumViolaceum extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            station_id: this.props.row.uid,
            startDate: moment().subtract(1, 'days'),
            endDate: moment(),
            disease1: {},
            disease2: {},
            data: {},
            oadDisease: true,
            loadData: true
        };
    }

    setDates = (start, end) => { this.setState({ startDate: start, endDate: end }, () => { this.getPessl(); }); }

    // Get funkcije za preuzimanje podataka sa stanica samo po potrebi ubaciti, pored Disease-a uvek mora i Data zbog datuma
    getDisease = (path, disease) => {
        let obj = {};
        axios.post('/api/testMeteo/meteo', path)
            .then(res => { obj = formatDisease(res, obj); this.setState({ [disease]: obj, loadDisease: false }); })
            .catch(err => console.log('Error: ', err))
    }

    getData = (path) => {
        axios.post('/api/testMeteo/meteo', path)
            .then(res => { let obj = formatData(res); this.setState({ data: obj, loadData: false }); })
            .catch(err => console.log('Error: ', err))
    }

    // getForecast = (path) => {
    //     axios.post('/api/testMeteo/meteo', path)
    //         .then(res => { let obj = formatForecast(res); this.setState({ forecast: obj }); })
    //         .catch(err => console.log('Error: ', err))
    // }

    getPessl = () => {
        let start_date = moment(this.state.startDate).unix();
        let end_date = moment(this.state.endDate).unix();
        let station_id = this.state.station_id;

        // URL-ovi za podatke sa meteo stanica
        let disease_path = '/data/' + station_id + '/hourly/from/' + start_date + '/to/' + end_date;
        let forecast_path = '/forecast/' + station_id + '/basic-1h';
        let data_path = '/data/' + station_id + '/hourly/from/' + start_date + '/to/' + end_date;

        // Disease mora POST method, za Data i Forecast mora GET method
        this.getDisease({ path: disease_path, name: "GeneralPhragmidiumViolaceum", method: "POST" }, 'disease1');
        this.getData({ path: data_path, method: "GET" });
        // this.getForecast({ path: forecast_path, name: 'general7', method: "POST" });
    }

    render() {
        const { classes } = this.props;

        //console.log('disease1: ', this.state.disease1);
        // console.log('disease2: ', this.state.disease2);
        // console.log('data meteo: ', this.state.data);

        let graph1 = {};
        let graph2 = {};
        let graph3 = {};
        let graph4 = {};

        const options = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[%]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            minTicksLimit:100,
                            max: 100,
                            maxTicksLimit:100
                        }
                    },
                    // {
                    //     type: 'linear',
                    //     display: false,
                    //     position: 'right',
                    //     id: 'y-axis-2',
                    //     gridLines: {
                    //         drawOnChartArea: false,
                    //     },
                    //     ticks: {
                    //         beginAtZero: true,
                    //         min: 0,
                    //     }
                    // },
                ],
            },
        };

        const options2 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-5',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[°C]'
                        },
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-6',
                        gridLines: {
                            drawOnChartArea: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[%]'
                        },
                        ticks: {
                            beginAtZero: false,
                            min: 20,
                            max: 100
                        }
                    },
                ],
            },
        };

        const options3 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-3',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[min/h]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 60
                        }
                    },
                    // {
                    //     type: 'linear',
                    //     display: true,
                    //     position: 'right',
                    //     id: 'y-axis-4',
                    //     gridLines: {
                    //         drawOnChartArea: false,
                    //     },
                    //     scaleLabel: {
                    //         display: true,
                    //         labelString: '[min/h]'
                    //     },
                    //     ticks: {
                    //         beginAtZero: true,
                    //         min: 0,
                    //         max: 60
                    //     }
                    // },
                ],
            },
        };

        if (
            this.state.data.dates &&
            this.state.disease1 &&
            this.state.disease2
        ) {
            graph1 = {
                labels: this.state.data.dates,
                datasets: [
                    {
                        data: this.state.disease1['infection'],
                        label: i18n.t('Infection [%]'),
                        backgroundColor: 'transparent',
                        borderColor: 'red',
                        type: 'line',
                        borderWidth: 2,
                        pointRadius: this.state.data.dates.length > 72 ? 0 : 2,
                        yAxisID: 'y-axis-1',
                    },
                ],
            };
            graph3 = {
                labels: this.state.data.dates,
                datasets: [
                    {
                        data: this.state.data.temperatures,
                        label: i18n.t('Temperature [°C]'),
                        backgroundColor: 'transparent',
                        borderColor: 'red',
                        type: 'line',
                        borderWidth: 2,
                        pointRadius: this.state.data.dates.length > 72 ? 0 : 2,
                        yAxisID: 'y-axis-5',
                    },
                    {
                        data: this.state.data.humidity,
                        label: i18n.t('Relative humidity [%]'),
                        type: 'line',
                        backgroundColor: 'transparent',
                        borderColor: '#00BFFF',
                        borderWidth: 2,
                        pointRadius: this.state.data.dates.length > 72 ? 0 : 2,
                        yAxisID: 'y-axis-6',
                    },

                ],
            };
            graph4 = {
                labels: this.state.data.dates,
                datasets: [
                    // {
                    //     data: this.state.data.precipitation,
                    //     label: i18n.t('Precipitation [mm]'),
                    //     backgroundColor: 'rgb(70, 130, 180,0.9)',
                    //     borderColor: '#4682B4',
                    //     type: 'line',
                    //     borderWidth: 2,
                    //     pointRadius: this.state.data.dates.length > 72 ? 0 : 2,
                    //     yAxisID: 'y-axis-3',
                    // },
                    {
                        data: this.state.data.leaf_wetness,
                        label: i18n.t('Leaf wetness [min/h]'),
                        backgroundColor: '#7CFC00',
                        borderColor: '#7CFC00',
                        type: 'bar',
                        borderWidth: 2,
                        pointRadius: this.state.data.dates.length > 72 ? 0 : 2,
                        yAxisID: 'y-axis-3',
                    },
                ],
            };
        };

        return (
            <div style={{ paddingLeft: '35px' }}>
                 <div style= {{display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                        <p style={{fontSize: 20, marginRight: 4 }}>{i18n.t('Rust')}</p>
                        <p style={{fontSize: 20, fontStyle: 'italic'}}>(Phragmidium rubi-idaei)</p>
                        </div>
                </div>
                <DatePickerMeteo startDate={this.state.startDate} endDate={this.state.endDate} setDates={this.setDates} getPessl={this.getPessl} />
                {(this.state.loadDisease === true || this.state.loadData === true) && <div style={{ marginLeft: '39%', marginTop: '14%' }}> <MoonLoader loading={this.state.loadDisease === true || this.state.loadData === true} size={100} /></div>}
                {this.state.loadDisease === false && this.state.loadData === false &&
                    <div>
                        <div style={{ width: '95%', marginTop: '10px' }}>
                            <Bar data={graph1} height={300} width={600} options={options} />
                        </div>
                        <div style={{ width: '95%', marginTop: '20px' }}>
                            <Bar data={graph3} height={200} width={400} options={options2} />
                        </div>
                        <MeteoTable data={{
                            headers: [i18n.t('Date and time'), i18n.t('Temperature [°C]'), i18n.t('Relative humidity [%]')],
                            dates: this.state.data.dates,
                            temperatures:this.state.data.temperatures,
                            humidity: this.state.data.humidity
                        }} />
                        <div style={{ width: '95%', marginTop: '20px'}}>
                            <Bar data={graph4} height={200} width={400} options={options3} />
                        </div>
                        <MeteoTable data={{
                            headers: [i18n.t('Date and time'), i18n.t('Leaf wetness [min/h]')],
                            dates: this.state.data.dates,
                            leaf_wetness: this.state.data.leaf_wetness
                        }} />
                        <div style={{ marginTop: '20px' }}></div>
                    </div>
                }
            </div>
        );
    }
};

export default withStyles(styles)(RaspberryGeneralPhragmidiumViolaceum);
