import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { getTestingRead, setRowInReducer, setInitialState, TestingById } from '../../actions/SuperAdminActions';
import i18n from '../../i18n/i18n';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import { Fab } from '@material-ui/core';
import AddField from '../catastral_data/field/AddField';
import ViewTest from './ViewTest';


const CheckboxTable = checkboxHOC(ReactTable);
export class Testing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            add: false,
            edit: false,
            // add: false,
            // edit: false,
            showFilters: this.props.showFilters,
            selection: [],
            selectAll: false
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            add: nextProps.add,
            showFilters: nextProps.showFilters,
        })

    }

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            selection.push(key);
        }
        this.setState({ selection });
    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    onInfoClick = () => {
        this.setState({ add: !this.state.add })
    };


    render() {
        const columns = [
            {
                Header: i18n.t('Name'),
                accessor: 'naziv',
            },
            {
                Header: i18n.t('Email'),
                accessor: 'kontakt_mejl',
            },
            {
                Header: i18n.t('Phone'),
                accessor: 'kontakt_telefon',
            },
            {
                Header: i18n.t('Address'),
                accessor: 'ulica_i_broj',
            },
            {
                Header: i18n.t('State'),
                accessor: 'naziv_opstina',
            },
            {
                Header: i18n.t('Local government'),
                accessor: 'naziv_kat',
            },
            {
                Header: i18n.t('Correct answers'),
                accessor: 'percent',
                Cell: row => {
                    let color = 'white'
                    if (row.value > 1 && row.value <= 30) {
                        color = 'red';
                    } else if (row.value > 30 && row.value <= 50) {
                        color = 'brown';
                    } else if (row.value > 50 && row.value <= 70) {
                        color = 'yellow';
                    } else if (row.value > 70 && row.value <= 80) {
                        color = 'blue';
                    } else if (row.value > 70 && row.value <= 100) {
                        color = 'green';
                    }
                    return (
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundColor: '#dadada',
                                borderRadius: '10px'
                            }}
                        >
                            <div
                                style={{
                                    width: `100%`,
                                    height: '100%',
                                    backgroundColor: color,
                                    borderRadius: '10px',
                                    transition: 'all .2s ease-out',
                                    textAlign: 'center'
                                }}
                            >
                                {row.value + ' %'}
                            </div>
                        </div>
                    )
                }
            }
        ];
        const { toggleSelection, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.kl_id);
                    return {
                        style: {
                            backgroundColor: selected ? "#ACDAF1" : "inherit"
                        }
                    };
                }
                return {};
            }
        };
        return (
            <div className="page">
                <div className="table-header">
                    {this.state.selection.length === 1 ?
                        <Fab
                            onClick={() => this.onInfoClick()}
                            color="secondary"
                            aria-label="edit"
                            className="action-button"
                        >
                            <span className=" icon-Informacija headerIcon" />
                        </Fab>
                        : null
                    }
                </div>
                <CheckboxTable data={this.props.testing}
                    ref={r => (this.checkboxTable = r)}
                    keyField={'kl_id'}
                    getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                    columns={columns}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.props.testingPages}
                    loading={this.props.testingFetching}
                    manual
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => this.props.getTestingRead(state, instance)}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF',
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
                {this.state.add && <ViewTest add={this.state.add} id_klijent_vlasnik={this.state.selection[0]}
                    handleClose={this.onInfoClick} />}
            </div>

        )
    }


};

function mapStateToProps(state) {
    return {
        testing: state.superAdminReducer.testing,
        testingPages: state.superAdminReducer.testingPages,
        testingFetching: state.superAdminReducer.testingFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getTestingRead: (state, instance) => dispatch(getTestingRead(state, instance)),
        setRowInReducer: (row, name) => dispatch(setRowInReducer(row, name)),
        setInitialState: (component) => dispatch(setInitialState(component)),
        TestingById: (id) => dispatch(TestingById(id))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Testing)