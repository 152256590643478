import React from 'react';
import { connect } from 'react-redux';
import i18n from '../i18n/i18n';
import {
    Dialog, DialogContent, DialogTitle, DialogActions,
    Button, TextField, IconButton, Grid
} from '@material-ui/core';
import {
    createTicket, setInitialState
} from '../actions/LoginActions';
import { toast } from 'react-toastify';
import { getModules, getForms } from '../actions/TicketActions';

export class Support extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tiket: {},
        };
    }

    toastConf = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        draggablePercent: 80
    }

    componentDidMount() {
        this.props.getModules();
        this.props.getForms();
    }

    componentDidUpdate(prevProps) {
        const { ticketSentSuccess, ticketSentFailed } = this.props;
        if (ticketSentSuccess === true && prevProps.ticketSentSuccess === false) {
            toast.success(i18n.t('Ticket successfully sent! You will be contacted as soon as possible.'), this.toastConf);
            this.props.setInitialState('ticketSentSuccess');
            this.props.handleClose();
        }
        if (ticketSentFailed === true && prevProps.ticketSentFailed === false) {
            toast.error(i18n.t('Error occured while sending ticket! Please try again'), this.toastConf);
            this.props.setInitialState('ticketSentFailed');
        }
    }

    createTicket = () => {
        if (this.state.tiket &&
            this.state.tiket.naziv &&
            this.state.tiket.tiket_pitanje) {
            this.props.createTicket(this.state.tiket);
        } else {
            toast.error(i18n.t('Please enter all fields!'), this.toastConf);
        }
    }

    handleChangeSupportValue = (name) => event => {
        let obj = Object.assign({}, this.state.tiket);
        obj[name] = event.target.value;
        this.setState({ tiket: obj });
        if ([name] == 'id_modul') {
            this.props.getForms(event.target.value);
        }
    };

    render() {
        let modules = [];
        if (this.props.modules && !this.props.modulesFetching) {
            modules = this.props.modules.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }

        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                disableBackdropClick
                maxWidth="xs"
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('Add ticket')}
                    <IconButton onClick={this.props.handleClose}>
                        <span className="icon-cancel-circle closeIcon" />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <form id="add-ticket">
                        <Grid container justify="space-between" alignContent="space-between" spacing={12}>
                            <Grid xs={12} sm={12}>
                                <TextField
                                    required
                                    onChange={this.handleChangeSupportValue('naziv')}
                                    label={i18n.t('Title')}
                                    style={{ width: '300px', marginTop: '10px', marginBottom: '10px' }}
                                />
                            </Grid>
                            <Grid xs={12} sm={12}>
                                <TextField
                                    required
                                    multiline
                                    fullWidth
                                    onChange={this.handleChangeSupportValue('tiket_pitanje')}
                                    label={i18n.t('Message')}
                                    style={{ width: '300px', marginTop: '10px', marginBottom: '10px' }}
                                />
                            </Grid>
                            {/* <Grid xs={12} sm={12}>
                                <TextField
                                    select
                                    label={i18n.t('Module')}
                                    onChange={this.handleChangeSupportValue('id_modul')}
                                    SelectProps={{ native: true }}
                                    margin="normal"
                                    style={{ width: '300px', marginTop: '10px', marginBottom: '10px' }}
                                >
                                    <option value='' />
                                    {modules}
                                </TextField>
                            </Grid> */}
                            {/* <Grid xs={12} sm={12}>
                                <TextField
                                    select
                                    label={i18n.t('Form')}
                                    onChange={this.handleChangeSupportValue('id_forma')}
                                    SelectProps={{ native: true }}
                                    margin="normal"
                                    style={{ width: '300px', marginTop: '10px', marginBottom: '10px' }}
                                >
                                    <option value='' />
                                    {forms}
                                </TextField>
                            </Grid> */}
                            <Grid xs={12} sm={12}>
                                <Button onClick={this.createTicket} style={{ backgroundColor: '#58B4E5', color: 'white', marginTop: '10px' }}>
                                    {i18n.t('Send message')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
        )
    }
};

function mapStateToProps(state) {
    return {
        ticketSentSuccess: state.appReducer.ticketSentSuccess,
        ticketSentFailed: state.appReducer.ticketSentFailed,
        modules: state.ticketReducer.modules,
        modulesFetching: state.ticketReducer.modulesFetching,
        forms: state.ticketReducer.forms,
        formsFetching: state.ticketReducer.formsFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createTicket: (tiket) => dispatch(createTicket(tiket)),
        setInitialState: (component) => dispatch(setInitialState(component)),
        getForms: (id) => dispatch(getForms(id)),
        getModules: () => dispatch(getModules()),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Support);