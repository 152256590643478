import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n/i18n';
import { Button, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
//import { SketchPicker } from 'react-color';
//import Modal from '@material-ui/core/Modal';
//import InputLabel from '@material-ui/core/InputLabel';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setInitialState, addLayer } from '../../../actions/catastral_data/LayerActions';
import { getSeasonDropDown } from '../../../actions/resources/SeasonsActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export class AddLayers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            layerObj: this.props.layerObj,
        }
    }

    componentDidMount() {
        this.props.getSeasonDropDown();

    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.layerPostingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('layerPostingFailed');
        }
    }

    addLayer() {
        if (this.state.layerObj.naziv &&
            this.state.layerObj.id_sezona) {
            this.props.addLayer(
                this.state.layerObj
            )

        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    handleChangeDropDown = name => event => {
        let obj = Object.assign({}, this.state.layerObj);
        obj[name] = event.target.value;
        this.setState({ layerObj: obj });
    };


    render() {

        let seasonDropDown = [];
        if (this.props.seasonDropDown && !this.props.seasonFetchingDropDown) {
            seasonDropDown = this.props.seasonDropDown.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }

        return (
            <div style={{ padding: 20 }}>

                {/* <Paper className="roundedBoxMaterial" zdepth={4}> */}
                <Dialog
                    open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="xs"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit layer') : i18n.t('Add layer')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <form id="add-layer">
                            <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                                <Grid item>
                                    <TextField
                                        required
                                        label={i18n.t('Title')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.state.layerObj.naziv}
                                        onChange={this.handleChangeDropDown('naziv')}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        select
                                        label={i18n.t('Season')}
                                        value={this.state.layerObj.id_sezona}
                                        onChange={this.handleChangeDropDown('id_sezona')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}

                                    >
                                        <option value='' />
                                        {seasonDropDown}
                                    </TextField>
                                </Grid>
                                <Grid item xs>
                                    <Button
                                        style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white' }}
                                        onClick={() => this.addLayer()}
                                    >
                                        {this.props.edit ? i18n.t('Save') : i18n.t('add')}
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
                {/* </Paper> */}
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        layerPosting: state.layerReducer.layerPosting,
        layerPostingSuccess: state.layerReducer.layerPostingSuccess,
        layerPostingFailed: state.layerReducer.layerPostingFailed,
        layerObj: state.layerReducer.layerObj,
        seasonDropDown: state.seasonReducer.seasonDropDown,
        seasonFetchingDropDown: state.seasonReducer.seasonFetchingDropDown,
        seasonFetchingFailedDropDown: state.seasonReducer.seasonFetchingFailedDropDown,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInitialState: (component) => dispatch(setInitialState(component)),
        getSeasonDropDown: () => dispatch(getSeasonDropDown()),
        addLayer: (layerObj) => dispatch(addLayer(layerObj)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddLayers)