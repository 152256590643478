import React from 'react';
import { connect } from 'react-redux';
import {
    Typography, IconButton, Menu, Button
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { Doughnut, Bar, Pie, HorizontalBar } from 'react-chartjs-2';
import { getTicketDashboard } from '../../actions/TicketActions';
import {
    getRegion,
} from '../../actions/LoginActions';
import randomColor from 'randomcolor';
import DashboardDrawer from '../../utils/DashboardDrawer';
import 'react-toastify/dist/ReactToastify.css';
import i18n from '../../i18n/i18n';
import InputLabel from '@material-ui/core/InputLabel';
import Select, { components } from 'react-select';
import immutable from 'seamless-immutable';
import { getUser } from '../../utils/CommonFunctions';
const IndicatorsContainer = (props) => {
    return (
        <div style={{ background: '#61a50e' }}>
            <components.IndicatorsContainer {...props} />
        </div>
    );
};
export class TicketDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            user: {},
            id_region: []
        };
    }

    componentDidMount() {
        this.props.getTicketDashboard({});

    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            region: immutable.asMutable(nextProps.region.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
        })
    }

    onPieClick = () => {
        this.props.history.push('/ticket_dashboard');
    }

    onTableClick = () => {
        this.props.history.push('/ticket');
    }
    handleChange = name => selectedOption => {
        this.setState({
            [name]: selectedOption
        })
    }
    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };
    handleOk = () => {
        let filters = {};

        filters['id_region'] = this.state.id_region.map((item) => {
            return item.value;
        })
        this.props.getTicketDashboard(filters);
    }
    render() {
        const { anchorEl } = this.state;
        let solvedData = this.solved;
        let statusesData = this.statuses;
        let inChargeData = this.inCharge;
        let categoryData = this.category;
        let priorityData = this.priority;
        let byDateDates = this.days;

        let user = getUser();
        if (this.props.ticketDashboard && this.props.ticketDashboard.solved) {
            solvedData.labels = this.props.ticketDashboard.solved.labels.asMutable();
            solvedData.datasets[0].data = this.props.ticketDashboard.solved.count.asMutable();
            solvedData.datasets[0].data1 = this.props.ticketDashboard.solved.percent.asMutable();
        }
        if (this.props.ticketDashboard && this.props.ticketDashboard.statuses) {
            statusesData.labels = this.props.ticketDashboard.statuses.labels.asMutable();
            statusesData.datasets[0].data = this.props.ticketDashboard.statuses.count.asMutable();
            statusesData.datasets[0].data1 = this.props.ticketDashboard.statuses.percent.asMutable();
        }

        if (this.props.ticketDashboard && this.props.ticketDashboard.inCharge) {
            inChargeData.labels = this.props.ticketDashboard.inCharge.labels.asMutable();
            inChargeData.datasets[0].data = this.props.ticketDashboard.inCharge.count.asMutable();
            inChargeData.datasets[0].data1 = this.props.ticketDashboard.inCharge.percent.asMutable();
        }

        if (this.props.ticketDashboard && this.props.ticketDashboard.groupedDates) {
            byDateDates.labels = this.props.ticketDashboard.groupedDates.labels.asMutable();
            byDateDates.datasets[0].data = this.props.ticketDashboard.groupedDates.count.asMutable();
        }

        if (this.props.ticketDashboard && this.props.ticketDashboard.category) {
            categoryData.labels = this.props.ticketDashboard.category.labels.asMutable();
            categoryData.datasets[0].data = this.props.ticketDashboard.category.count.asMutable();
            categoryData.datasets[0].data1 = this.props.ticketDashboard.category.percent.asMutable();
        }

        if (this.props.ticketDashboard && this.props.ticketDashboard.priority) {
            priorityData.labels = this.props.ticketDashboard.priority.labels.asMutable();
            priorityData.datasets[0].data = this.props.ticketDashboard.priority.count.asMutable();
            priorityData.datasets[0].data1 = this.props.ticketDashboard.priority.percent.asMutable();
        }
        return (
            <div style={{ 'padding': '20px' }}>
                {this.props.ticketDashboard.total > 0 &&
                    <div>
                        <Card style={styles.card}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <h1>{i18n.t('Total number of tickets')} : {this.props.ticketDashboard.total} </h1>
                                <div>
                                    {(user.id_klijent_grupa == 12) &&
                                        <div className="filter-panel-dashboard">
                                            <IconButton
                                                styles={styles.button}
                                                aria-owns={anchorEl ? 'simple-menu' : null}
                                                aria-haspopup="true"
                                                className="action-button-filter"
                                                onClick={this.handleClick}
                                            >
                                                <span className="icon-search headerIcon" />
                                            </IconButton>
                                        </div>
                                    }
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={this.handleClose}
                                    >
                                        {(user.id_klijent_grupa == 8 || user.id_klijent_grupa == 12) &&
                                            <div>
                                                <InputLabel className="filter-label">{i18n.t('Region')}:</InputLabel>
                                                <Select
                                                    isMulti
                                                    closeMenuOnSelect={true}
                                                    isClearable={false}
                                                    value={this.state.id_region}
                                                    components={{
                                                        IndicatorsContainer: IndicatorsContainer
                                                    }}
                                                    maxMenuHeight={150}
                                                    onChange={this.handleChange('id_region')}
                                                    options={this.state.region}
                                                    className="multi-select-in-filter-dashboards"
                                                    classNamePrefix="select"
                                                    placeholder={i18n.t('Select')}
                                                />
                                            </div>
                                        }
                                        <div style={{ padding: '30px' }}>
                                            <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleOk}>{i18n.t('Ok')}</Button>
                                            <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleClose}>{i18n.t('Cancel')}</Button>
                                            {/* <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleClear}>{i18n.t('Clear')}</Button> */}

                                        </div>
                                    </Menu>
                                </div>
                            </div>

                        </Card>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Card className="dashboard-cards" raised={true}>
                                <Typography className="dashboard-title">{i18n.t('Ticket accepted')}:</Typography>
                                {this.props.ticketDashboard && this.props.ticketDashboard.statuses &&
                                    <Doughnut data={solvedData} options={this.options} />
                                }
                            </Card>
                            <Card className="dashboard-cards" raised={true}>
                                <Typography className="dashboard-title">{i18n.t('Ticket Statuses')}:</Typography>
                                {this.props.ticketDashboard && this.props.ticketDashboard.solved &&
                                    <Doughnut data={statusesData} options={this.options} />
                                }
                            </Card>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Card className="dashboard-cards" raised={true}>
                                <Typography className="dashboard-title">{i18n.t('In charge')}:</Typography>
                                {this.props.ticketDashboard && this.props.ticketDashboard.inCharge &&
                                    <Doughnut data={inChargeData} options={this.options} />
                                }
                            </Card>
                            <Card className="dashboard-cards" raised={true}>
                                <Typography className="dashboard-title">{i18n.t('Category')}:</Typography>
                                {this.props.ticketDashboard && this.props.ticketDashboard.category &&
                                    <Doughnut data={categoryData} options={this.options} />
                                }
                            </Card>
                            <Card className="dashboard-cards" raised={true}>
                                <Typography className="dashboard-title">{i18n.t('Priority')}:</Typography>
                                {this.props.ticketDashboard && this.props.ticketDashboard.priority &&
                                    <Doughnut data={priorityData} options={this.options} />
                                }
                            </Card>
                        </div>
                        {(user.id_klijent_grupa == 12) &&
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Card className="dashboard-cards-full" raised={true}>
                                    <HorizontalBar
                                        data={byDateDates}
                                        height={200}
                                    />
                                </Card>
                            </div>
                        }

                        {this.props.global ?
                            null :
                            <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} />
                        }
                    </div>

                }
            </div >
        )
    }
    solvedColor = randomColor({
        count: 30
    })
    statusesColor = randomColor({
        count: 7
    })

    monthsColor = randomColor()
    amountColor = randomColor()
    daysColor = randomColor()

    solved = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.solvedColor,
            hoverBackgroundColor: this.solvedColor
        }]
    };

    statuses = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.statusesColor,
            hoverBackgroundColor: this.statusesColor
        }]
    };

    inCharge = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.solvedColor,
            hoverBackgroundColor: this.solvedColor
        }]
    };

    category = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.solvedColor,
            hoverBackgroundColor: this.solvedColor
        }]
    };

    priority = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.solvedColor,
            hoverBackgroundColor: this.solvedColor
        }]
    };

    days = {
        labels: [],
        datasets: [{
            data: [],
            label: i18n.t('Created by days'),
            backgroundColor: this.daysColor,
            borderColor: this.daysColor,
            borderWidth: 1,
            hoverBackgroundColor: this.daysColor,
            hoverBorderColor: this.daysColor,
        }],
    };

    options = {
        legend: {
            position: 'bottom',
            labels: {
                boxWidth: 10,
                fontSize: 12
            }
        },
        tooltips: {
            mode: 'label',
            callbacks: {
                afterLabel: function (tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex].data1[tooltipItem.index] + '%';
                }
            }
        }
    };
};

function mapStateToProps(state) {
    return {
        ticketDashboard: state.ticketReducer.ticketDashboard,
        appStatuses: state.ticketReducer.appStatuses,
        region: state.appReducer.region,

    }
}

function mapDispatchToProps(dispatch) {
    return {
        getTicketDashboard: (filters) => dispatch(getTicketDashboard(filters)),
        getRegion: () => dispatch(getRegion()),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TicketDashboard);

const styles = {
    card: {
        padding: '10px',
        margin: '10px',
    },
    button: {
        height: 40
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    }
};