import React from 'react';
import i18n from '../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography, Paper } from '@material-ui/core';
import { wentWrong, wentRight } from "../../../utils/ToastConfig";
import axios from '../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const initialState = {
    naziv: '', username: '',
    ime: '', prezime: '',
    region: null, regions: [],
    clientGroup: null, clientGroups: [],
    userGroup: null, userGroups: [],
    opstina: null, opstine: [], opstinaDisabled: true,
    katastarska: null, katastarske: [], katastarskaDisabled: true,
    language: null, languages: [],
    season: null, seasons: [], seasonDisabled: true,
    currency: null, currencies: [],
    workspace: null, workspaces: [],
    x: '', y: '', zoom: '',
    mesto: '', drzava: '', ulica_i_broj: '',
    mobilni: '', telefon: '', osoba: '', mejl: '',
    skype: '', pib: '', bpg: '',
};

export default class AddDefaultSeasons extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {

        this.getByURL('region/filter_region', 'regions');
        this.getByURL('region/filter_klijent_grupa', 'clientGroups');
        this.getByURL('region/filter_korisnik_grupa', 'userGroups');
        this.getByURL('klijent_korisnik/language', 'languages');
        this.getByURL('klijent_korisnik/currency', 'currencies');
        this.getByURL('klijent_korisnik/workspace', 'workspaces');

    }

    getByURL(url, obj, value, name) {
        // url: api/'url' // obj: this.state.obj // value: params value // name: params value name
        value = value || '';
        name = name || '';
        if (value != null) {
            axios.get(`api/${url}`, { params: { [name]: value } })
                .then(res => this.setState({ [obj]: res.data.data }))
                .catch(error => wentWrong('Something went wrong, please try again!'));
        }
    }

    addClients() {
        if (
            this.state.naziv &&
            this.state.username &&
            this.state.ime &&
            this.state.prezime &&
            this.state.region &&
            this.state.opstina &&
            this.state.katastarska &&
            this.state.clientGroup &&
            this.state.userGroup &&
            this.state.language &&
            this.state.currency &&
            this.state.season &&
            this.state.workspace
        ) {
            let account = {
                naziv: this.state.naziv,
                username: this.state.username,
                ime: this.state.ime,
                prezime: this.state.prezime,
                region: Number(this.state.region),
                clientGroup: Number(this.state.clientGroup),
                userGroup: Number(this.state.userGroup),
                opstina: Number(this.state.opstina),
                katastarska: Number(this.state.katastarska),
                language: Number(this.state.language),
                season: Number(this.state.season),
                currency: Number(this.state.currency),
                workspace: this.state.workspace,
                x: Number(this.state.x),
                y: Number(this.state.y),
                zoom: Number(this.state.zoom),
                mesto: this.state.mesto,
                drzava: this.state.drzava,
                ulica_i_broj: this.state.ulica_i_broj,
                mobilni: this.state.mobilni,
                telefon: this.state.telefon,
                osoba: this.state.osoba,
                mejl: this.state.mejl,
                skype: this.state.skype,
                pib: this.state.pib,
                bpg: this.state.bpg,
            }
            if (account.naziv === '') account.naziv = null;
            if (account.username === '') account.username = null;
            if (account.ime === '') account.ime = null;
            if (account.prezime === '') account.prezime = null;
            if (account.mesto === '') account.mesto = null;
            if (account.drzava === '') account.drzava = null;
            if (account.ulica_i_broj === '') account.ulica_i_broj = null;
            if (account.mobilni === '') account.mobilni = null;
            if (account.telefon === '') account.telefon = null;
            if (account.osoba === '') account.osoba = null;
            if (account.mejl === '') account.mejl = null;
            if (account.skype === '') account.skype = null;
            if (account.pib === '') account.pib = null;
            if (account.bpg === '') account.bpg = null;
            if (account.x === '') account.x = null;
            if (account.y === '') account.y = null;
            if (account.zoom === '') account.zoom = null;
            this.resetClient();
            axios.post('api/klijent_korisnik/account', account)
                .then(res => {
                    toast.success('Klijent/Korisnik uspesno kreiran', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        draggablePercent: 80
                    })
                })
                .catch(err => {
                    console.log('Error: ', err);
                })
        }
        else wentWrong('Please enter all required fields!');
    }

    resetClient() { this.setState(initialState); }

    render() {
        return (
            <div>
                <DialogContent>
                    <form id="add-layer">
                        <div style={{ paddingLeft: '35px' }}>
                            <TextField required
                                label={i18n.t('Naziv')}
                                value={this.state.naziv}
                                helperText={i18n.t('Naziv klijenta')}
                                style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                onChange={(e) => { this.setState({ naziv: e.target.value }); }} >
                            </TextField>
                            <TextField required
                                label={i18n.t('Username')}
                                helperText={i18n.t('Login username')}
                                value={this.state.username}
                                style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                onChange={(e) => { this.setState({ username: e.target.value }); }} >
                            </TextField>
                            <TextField required
                                label={i18n.t('Ime')}
                                value={this.state.ime}
                                helperText={i18n.t('Ime korisnika')}
                                style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                onChange={(e) => { this.setState({ ime: e.target.value }); }} >
                            </TextField>
                            <TextField required
                                label={i18n.t('Prezime')}
                                value={this.state.prezime}
                                helperText={i18n.t('Prezime korisnika')}
                                style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                onChange={(e) => { this.setState({ prezime: e.target.value }); }} >
                            </TextField>
                            <TextField required
                                label={i18n.t('Region')}
                                select
                                helperText={i18n.t('Region')}
                                value={this.state.region}
                                SelectProps={{ native: true, }}
                                style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                onChange={(e) => {
                                    this.setState({
                                        region: e.target.value,
                                        opstina: null,
                                        opstinaDisabled: false,
                                        katastarska: null,
                                        katastarskaDisabled: true,
                                        season: null,
                                        seasonDisabled: false,
                                    });
                                    this.getByURL('field/filter_opstina_uslov', 'opstine', e.target.value, 'id_region');
                                    this.getByURL('klijent_korisnik/season', 'seasons', e.target.value, 'id_region');
                                }} >
                                <option value='' />
                                {this.state.regions.map((regions) => <option key={regions.id} value={regions.id}>{regions.naziv}</option>)}
                            </TextField>
                            <TextField required
                                label={i18n.t('Opstina')}
                                select
                                helperText={i18n.t('Opstina')}
                                disabled={this.state.opstinaDisabled}
                                value={this.state.opstina}
                                SelectProps={{ native: true, }}
                                style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                onChange={(e) => {
                                    this.setState({
                                        opstina: e.target.value,
                                        katastarska: null,
                                        katastarskaDisabled: false,
                                    });
                                    this.getByURL('field/filter_kat_opstina_uslov', 'katastarske', e.target.value, 'id_opstina');
                                }} >
                                <option value='' />
                                {this.state.opstine.map((opstine) => <option key={opstine.id} value={opstine.id}>{opstine.naziv}</option>)}
                            </TextField>
                            <TextField required
                                label={i18n.t('Katastarska opstina')}
                                select
                                helperText={i18n.t('Katastarska opstina')}
                                disabled={this.state.katastarskaDisabled}
                                value={this.state.katastarska}
                                SelectProps={{ native: true, }}
                                style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                onChange={(e) => { this.setState({ katastarska: e.target.value }); }} >
                                <option value='' />
                                {this.state.katastarske.map((katastarske) => <option key={katastarske.id} value={katastarske.id}>{katastarske.naziv}</option>)}
                            </TextField>
                            <TextField required
                                label={i18n.t('Season')}
                                select
                                disabled={this.state.seasonDisabled}
                                value={this.state.season}
                                helperText={i18n.t('Default sezona')}
                                SelectProps={{ native: true, }}
                                style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                onChange={(e) => { this.setState({ season: e.target.value }); }} >
                                <option value='' />
                                {this.state.seasons.map((seasons) => <option key={seasons.id} value={seasons.id}>{seasons.naziv}</option>)}
                            </TextField>
                            <TextField required
                                label={i18n.t('Client Group')}
                                select
                                value={this.state.clientGroup}
                                helperText={i18n.t('Klijent grupa')}
                                SelectProps={{ native: true, }}
                                style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                onChange={(e) => { this.setState({ clientGroup: e.target.value }); }} >
                                <option value='' />
                                {this.state.clientGroups.map((clientGroups) => <option key={clientGroups.id} value={clientGroups.id}>{clientGroups.naziv}</option>)}
                            </TextField>
                            <TextField required
                                label={i18n.t('User Group')}
                                select
                                value={this.state.userGroup}
                                helperText={i18n.t('Korisnik grupa')}
                                SelectProps={{ native: true, }}
                                style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                onChange={(e) => { this.setState({ userGroup: e.target.value }); }} >
                                <option value='' />
                                {this.state.userGroups.map((userGroups) => <option key={userGroups.id} value={userGroups.id}>{userGroups.naziv}</option>)}
                            </TextField>
                            <TextField required
                                label={i18n.t('Language')}
                                select
                                value={this.state.language}
                                helperText={i18n.t('Jezik')}
                                SelectProps={{ native: true, }}
                                style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                onChange={(e) => { this.setState({ language: e.target.value }); }} >
                                <option value='' />
                                {this.state.languages.map((languages) => <option key={languages.id} value={languages.id}>{languages.naziv}</option>)}
                            </TextField>
                            <TextField required
                                label={i18n.t('Currency')}
                                select
                                value={this.state.currency}
                                helperText={i18n.t('Valuta')}
                                SelectProps={{ native: true, }}
                                style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                onChange={(e) => { this.setState({ currency: e.target.value }); }} >
                                <option value='' />
                                {this.state.currencies.map((currencies) => <option key={currencies.id} value={currencies.id}>{currencies.naziv}</option>)}
                            </TextField>
                            <TextField required
                                label={i18n.t('Geoserver Workspace')}
                                select
                                SelectProps={{ native: true, }}
                                helperText={i18n.t('Geoserver workspace klijenta')}
                                style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                onChange={(e) => this.setState({ workspace: e.target.value })} >
                                <option value='' />
                                {this.state.workspaces.map((workspace) => <option key={workspace.id} value={workspace.id}>{workspace.geoserver_workspace}</option>)}
                            </TextField>
                            <TextField
                                label={i18n.t('Zoom')}
                                value={this.state.zoom}
                                helperText={i18n.t('Min (Daleko) - 6, Max (Blizu) - 18')}
                                style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                onChange={(e) => { this.setState({ zoom: e.target.value }); }} >
                            </TextField>
                            <TextField
                                label={i18n.t('X')}
                                value={this.state.x}
                                helperText={i18n.t('X koordinata za centar mape')}
                                style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                onChange={(e) => { this.setState({ x: e.target.value }); }} >
                            </TextField>
                            <TextField
                                label={i18n.t('Y')}
                                value={this.state.y}
                                helperText={i18n.t('Y koordinata za centar mape')}
                                style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                onChange={(e) => { this.setState({ y: e.target.value }); }} >
                            </TextField>
                            <TextField
                                label={i18n.t('Mesto')}
                                value={this.state.mesto}
                                helperText={i18n.t('Mesto - text')}
                                style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                onChange={(e) => { this.setState({ mesto: e.target.value }); }} >
                            </TextField>
                            <TextField
                                label={i18n.t('Drzava')}
                                value={this.state.drzava}
                                helperText={i18n.t('Drzava - text')}
                                style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                onChange={(e) => { this.setState({ drzava: e.target.value }); }} >
                            </TextField>
                            <TextField
                                label={i18n.t('Ulica i broj')}
                                value={this.state.ulica_i_broj}
                                helperText={i18n.t('Ulica i broj - text')}
                                style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                onChange={(e) => { this.setState({ ulica_i_broj: e.target.value }); }} >
                            </TextField>
                            <TextField
                                label={i18n.t('Kontakt mobilni')}
                                helperText={i18n.t('Kontakt mobilni')}
                                value={this.state.mobilni}
                                style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                onChange={(e) => { this.setState({ mobilni: e.target.value }); }} >
                            </TextField>
                            <TextField
                                label={i18n.t('Konakt telefon')}
                                helperText={i18n.t('Kontakt telefon')}
                                value={this.state.telefon}
                                style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                onChange={(e) => { this.setState({ telefon: e.target.value }); }} >
                            </TextField>
                            <TextField
                                label={i18n.t('Kontakt osoba')}
                                helperText={i18n.t('Kontakt osoba')}
                                value={this.state.osoba}
                                style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                onChange={(e) => { this.setState({ osoba: e.target.value }); }} >
                            </TextField>
                            <TextField
                                label={i18n.t('Mejl')}
                                helperText={i18n.t('Kontakt mejl')}
                                value={this.state.mejl}
                                style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                onChange={(e) => { this.setState({ mejl: e.target.value }); }} >
                            </TextField>
                            <TextField
                                label={i18n.t('Skype')}
                                helperText={i18n.t('Kontakt skype')}
                                value={this.state.skype}
                                style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                onChange={(e) => { this.setState({ skype: e.target.value }); }} >
                            </TextField>
                            <TextField
                                label={i18n.t('PIB')}
                                value={this.state.pib}
                                helperText={i18n.t('PIB')}
                                style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                onChange={(e) => { this.setState({ pib: e.target.value }); }} >
                            </TextField>
                            <TextField
                                label={i18n.t('BPG')}
                                value={this.state.bpg}
                                helperText={i18n.t('BPG')}
                                style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                                onChange={(e) => { this.setState({ bpg: e.target.value }); }} >
                            </TextField>
                            <br></br>
                            <Button style={{ width: '100px', alignSelf: 'center', backgroundColor: '#58B4E5', color: 'white', marginTop: '20px', marginBottom: '10px' }}
                                onClick={() => {
                                    this.addClients();
                                }} >
                                {i18n.t('Add')}
                            </Button>
                            <Button style={{ width: '100px', alignSelf: 'center', backgroundColor: '#58B4E5', color: 'white', marginTop: '20px', marginBottom: '10px', marginLeft: '30px' }}
                                onClick={() => this.resetClient()} >
                                {i18n.t('Reset')}
                            </Button>
                        </div>
                    </form>
                </DialogContent>
            </div>
        )
    }
};