import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import {
    Button, IconButton, TextField, Grid,
    Dialog, DialogContent, DialogTitle
} from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setInitialState, addTicketItem, addAndLockTicketItem, getTicketStatus, getUser, getCategory } from '../../actions/TicketActions';
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment';
import DocumentDropZone from '../../utils/DocumentDropZone';
import axios from '../../utils/AxiosWrapper';

export class AddTicketItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ticketItemObj: {},
            user: {}
        }
    }

    componentDidMount() {
        this.props.getTicketStatus();
        this.props.getUser();
        this.props.getCategory();
        this.setState({ ticketItemObj: this.props.item, id_tiket: this.props.id_tiket });
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.ticketItemFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('ticketItemFailed');
        }
    }

    addTicketItem() {
        const { ticketItemObj, id_tiket, acceptedDocuments } = this.state;
        if (ticketItemObj.opis) {
            this.props.addTicketItem(ticketItemObj, id_tiket, acceptedDocuments);
            this.props.handleClose();
        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    addAndLockTicketItem() {
        const { ticketItemObj, id_tiket, acceptedDocuments } = this.state;
        if (ticketItemObj.opis) {
            this.props.addAndLockTicketItem(ticketItemObj, id_tiket, acceptedDocuments);
            this.props.handleClose();
        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        }
    }


    handleChangeValue = name => event => {
        let obj = Object.assign({}, this.state.ticketItemObj);
        obj[name] = event.target.value;
        this.setState({ ticketItemObj: obj });
    };

    handleDateChange = name => date => {
        let obj = Object.assign({}, this.state.ticketItemObj);
        obj[name] = moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY');
        this.setState({ ticketItemObj: obj });
    }


    render() {
        const { ticketItemObj, user } = this.state;
        let ticketStatuses = [];
        if (this.props.ticketStatuses && !this.props.seasonFetchingDropDown) {
            ticketStatuses = this.props.ticketStatuses.map((s, i) => {
                return <option key={s.id} value={s.id}>{i18n.language === 'Srpski' ? s.naziv : s.naziv_en}</option>
            });
        }
        let users = [];
        if (this.props.users && !this.props.usersFetching) {
            users = this.props.users.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.username}</option>
            });
        }
        let category = [];
        if (this.props.category && !this.props.categoryFetching) {
            category = this.props.category.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }

        console.log(ticketItemObj)
        console.log(this.state.id_tiket)
        let responseItemObj = ticketItemObj;
        responseItemObj.id = this.state.id_tiket;

        return (
            <Dialog
                open={this.props.add}
                onClose={this.props.handleClose}
                fullWidth={true}
                disableBackdropClick
                maxWidth="sm"
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle disableTypography={true} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit ticket item') : i18n.t('Add ticket item')}
                    <IconButton onClick={this.props.handleClose}>
                        <span className="icon-cancel-circle closeIcon" />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <form id="add-ticket">
                        <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                            {!this.props.disable && <Grid item xs={12} sm={12}>
                                <TextField
                                    required
                                    multiline
                                    fullWidth
                                    label={i18n.t('Description')}
                                    value={ticketItemObj.opis}
                                    style={{ marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('opis')}
                                />
                            </Grid>
                            }
                            {!this.props.disable && <Grid item>
                                <TextField
                                    select
                                    label={i18n.t('Status')}
                                    value={ticketItemObj.id_tiket_status}
                                    onChange={this.handleChangeValue('id_tiket_status')}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    margin="normal"
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                >
                                    <option value='' />
                                    {ticketStatuses}
                                </TextField>
                            </Grid>
                            }
                            <Grid item>
                                <TextField
                                    label={i18n.t('Priority')}
                                    value={ticketItemObj.prioritet}
                                    type='number'
                                    onChange={this.handleChangeValue('prioritet')}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                />
                            </Grid>
                            {!this.props.disable && <Grid item >
                                <TextField
                                    select
                                    label={i18n.t('In charge')}
                                    value={ticketItemObj.id_korisnik_dodeljen}
                                    onChange={this.handleChangeValue('id_korisnik_dodeljen')}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    margin="normal"
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                >
                                    <option value='' />
                                    {users}
                                </TextField>
                            </Grid>
                            }
                            {!this.props.disable && <Grid item >
                                <DatePicker
                                    keyboard
                                    label={i18n.t('Expected date')}
                                    format="DD.MM.YYYY"
                                    value={ticketItemObj.ocekivan_datum ? moment(ticketItemObj.ocekivan_datum, 'DD.MM.YYYY') : null}
                                    onChange={this.handleDateChange('ocekivan_datum')}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    labelFunc={this.renderLabel}
                                    disableOpenOnEnter
                                    animateYearScrolling={false}
                                />
                            </Grid>}
                            <Grid item>
                                <TextField
                                    select
                                    label={i18n.t('Category')}
                                    value={ticketItemObj.id_category}
                                    onChange={this.handleChangeValue('id_category')}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    margin="normal"
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                >
                                    <option value='' />
                                    {category}
                                </TextField>
                            </Grid>
                            <Grid item>
                                <TextField
                                    label={i18n.t('Spent hours')}
                                    value={ticketItemObj.vreme_sat}
                                    type='number'
                                    onChange={this.handleChangeValue('vreme_sat')}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                />
                            </Grid>
                            {/* <Grid item>
                            <TextField
                                label={i18n.t('Spent minutes')}
                                value={ticketItemObj.vreme_min}
                                type='number'
                                inputProps={{ min: "0", max: "60" }}
                                onChange={this.handleChangeValue('vreme_min')}
                                style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                            />
                        </Grid> */}
                            <Grid item xs={12}>
                            </Grid>
                            {!this.props.disable &&
                                <DocumentDropZone
                                    multiple={false}
                                    onDropAccepted={({ accepted, rejected }) => {
                                        this.setState({
                                            acceptedDocuments: accepted,
                                            rejectedDocuments: rejected
                                        });
                                    }}
                                />
                            }
                            <TextField
                                required
                                multiline
                                fullWidth
                                label={i18n.t('E-mail')}
                                value={ticketItemObj.mejl}
                                style={{ marginTop: '10px', marginBottom: '10px' }}
                                onChange={this.handleChangeValue('mejl')}
                            />
                            <Button
                                color='secondary'
                                style={{ marginTop: '10px' }}
                                variant='contained'
                                onClick={() => this.addAndLockTicketItem()}
                                disabled={this.props.disableAddAndLock}
                            >
                                {this.props.edit ? i18n.t('Save and lock') : i18n.t('Add and lock')}
                            </Button>
                            <Button
                                color='secondary'
                                variant='contained'
                                style={{ marginTop: '10px' }}
                                onClick={() => this.addTicketItem()}
                            >
                                {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                            </Button>
                            <Button
                                color='secondary'
                                variant='contained'
                                style={{ marginTop: '10px' }}
                                onClick={() =>
                                    axios.post('api/ticket/respond', responseItemObj)}>
                                {this.props.edit ? i18n.t('Respond') : i18n.t('Respond')}
                            </Button>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog >
        )
    }
};

function mapStateToProps(state) {
    return {
        ticketItem: state.ticketReducer.ticketItem,
        ticketItemSuccess: state.ticketReducer.ticketItemSuccess,
        ticketItemFailed: state.ticketReducer.ticketItemFailed,
        ticketStatuses: state.ticketReducer.ticketStatuses,
        ticketStatusesFetching: state.ticketReducer.ticketStatusesFetching,
        ticketStatusesFetchingFailed: state.ticketReducer.ticketStatusesFetchingFailed,
        users: state.ticketReducer.users,
        usersFetching: state.ticketReducer.usersFetching,
        usersFetchingFailed: state.ticketReducer.usersFetchingFailed,
        category: state.ticketReducer.category,
        categoryFetching: state.ticketReducer.categoryFetching
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInitialState: () => dispatch(setInitialState()),
        getTicketStatus: () => dispatch(getTicketStatus()),
        getUser: () => dispatch(getUser()),
        getCategory: () => dispatch(getCategory()),
        addTicketItem: (ticketItemObj, id_tiket, acceptedDocuments) => dispatch(addTicketItem(ticketItemObj, id_tiket, acceptedDocuments)),
        addAndLockTicketItem: (ticketItemObj, id_tiket, acceptedDocuments) => dispatch(addAndLockTicketItem(ticketItemObj, id_tiket, acceptedDocuments)),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddTicketItem)