import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import i18n from '../../../i18n/i18n';
import Select from 'react-select';
import { Fab } from '@material-ui/core';
import { wentWrong } from "../../../utils/ToastConfig";
import axios from '../../../utils/AxiosWrapper';
import ReactSelect from './../../../utils/ReactSelect';
import NumberRangeForFilter from '../../../utils/NumberRangeForFilter';
import DatePickerForFilter from '../../../utils/DatePickerForFilter';
import { withStyles } from '@material-ui/core/styles';
import { ExportEnterprise } from '../../../utils/ExportEnterprise';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import AddYearlyReport from './AddYearlyReport';

var FileSaver = require('file-saver');
const styles = theme => ({ primaryMain: { backgroundColor: theme.palette.primary.main } });
const CheckboxTable = checkboxHOC(ReactTable);;

export class ViewYearlyReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            layerType: [], seasonType: [], sectorType: [], cropType: [], fieldType: [], statusType: [], woType: [], materialType: [], cropGroupType: [], cropSubgroupType: [],
            productionType: [], localGovType: [],
            // stateType: [],
            showFilters: false, loading: true,
            page: 0, pageSize: 25, sorted: null, filtered: null,
            totalPages: 0, totalData: 0,
            data: [], docTitle: null, user_season: null, export: false, user_layer: null,
            season: null, seasons: [],
            selection: [], selectAll: false, selectedRow: [],
            add: false,
            seasonType: [], user_season: null, user_layer: null, layerType: [], docTitle: ''
        };
    }

    componentDidMount() {
        let user = this.getUser();
        this.setState({ user });

        axios.get('api/season/drop_down')
            .then(res => {
                let seasons = res.data.data.map(item => item.id);
                axios.get('api/layer/layers_by_season', { params: { seasons } })
                    .then(res => {
                        this.setState({ layerType: res.data.data }, () => {
                            axios.get('api/season/activity_season')
                                .then(res => {
                                    this.setState({ user_season: res.data.data[0].id_default_sezona }, () => {
                                        axios.get('api/layer/layer_by_season', { params: { season: this.state.user_season } })
                                            .then(res => {
                                                this.setState({ user_layer: res.data.data[0].id, loading: false }, () => {
                                                    // this.getByURL('katastarska_opstina/drop_down', 'localGovType');
                                                });
                                            })
                                            .catch(err => { console.log(err) })
                                    })
                                })
                                .catch(err => { console.log(err) })
                        });
                    })
                    .catch(err => { console.log(err); })
            })
            .catch(err => { console.log(err); })
    }

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    getData(url, state, instance) {
        this.setState({ loading: true, data: [] });
        axios.get(`api/${url}`, {
            params: { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered }
        })
            .then(res => {
                this.setState({
                    data: res.data.data,
                    totalPages: res.data.total_pages,
                    totalData: res.data.total,
                }, () => {
                    if (this.state.user_season !== null) this.setState({ loading: false })
                });
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: res.data.data }))
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    }

    exportFunction = () => {
        const table = this.checkboxTable.getWrappedInstance();
        this.exportDocument(this.state.docTitle, table.state, 'api/report/yearly', {});
        this.setState({ export: !this.state.export });
    };

    exportDocument(title, state, url, filters) {
        let params = { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered, filename: title };
        if (filters) params = { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered, filename: title, filters };

        return axios({ url: url, method: 'GET', responseType: 'blob', params: params })
            .then(response => FileSaver.saveAs(new Blob([response.data]), `${title || 'file'}.xlsx`))
            .catch(err => console.log(err))
    };

    onExportClick = () => this.setState({ export: !this.state.export });
    isSelected = key => { return this.state.selection.includes(key); };
    onSearchClick = () => this.setState({ showFilters: !this.state.showFilters });
    onImportClick = () => this.setState({ import: !this.state.import });
    onTableClick = () => this.props.history.push('/yearly_report');

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });
    };

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        }
        else selection.push(key);
        let selectedRow = null;
        if (selection.length == 1) selectedRow = this.state.data.filter((el) => el.id == selection)[0];
        this.setState({ selection, selectedRow });
    };

    handleClick = () => {
        if (this.state.edit == true && this.state.add == false) this.setState({ edit: false, selectedRow: [] });
        else if (this.state.selection.length > 0 && this.state.add == false && this.state.edit == false) this.setState({ selectedRow: [] });
        this.setState({ add: !this.state.add }, () => {
            if (this.state.add === false) this.getData('report/yearly', this.state);
            if (this.state.add == true && this.state.edit == false) this.setState({ selection: [], selectAll: false, });
        });
    };

    render() {
        let { theme } = this.props;
        let layerType = this.state.layerType.map((item) => { return { label: item.naziv, value: item.id } });
        let layers = layerType.map(item => item.value);

        // let seasons = seasonType.map(item => item.value);

        const columns = [
            {
                Header: i18n.t('Season'), accessor: 'naziv_layer',
                Filter: ({ filter, onChange }) => {
                    return <ReactSelect
                        filter
                        isMulti
                        name="label"
                        onChange={value => { onChange(value) }}
                        defaultValue={layerType.filter((element) => {
                            if (layers.includes(this.state.user_layer)) return element.value === this.state.user_layer;
                        })}
                        closeMenuOnSelect={false}
                        options={layerType}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
                }
            },
            { Header: i18n.t('Field'), accessor: 'naziv_tabla' },
            { Header: i18n.t('Crop'), accessor: 'naziv_kultura' },
            { Header: i18n.t('State'), accessor: 'naziv_opstina' },
            { Header: i18n.t('Land area'), accessor: 'povrsina', Filter: ({ filter, onChange }) => <NumberRangeForFilter onChange={(s) => onChange(s)} /> },
            { Header: i18n.t('Name'), accessor: 'kontakt_osoba' },
            { Header: i18n.t('BPG'), accessor: 'bpg' },
            { Header: i18n.t('Contract number'), accessor: 'broj_ugovora' },
            {
                Header: i18n.t('Lease value'), accessor: 'zakupnina',
                Cell: ({ value }) => {
                    let valuta = 'KM', valuta_code = 'BAM';
                    let formatted = new Intl.NumberFormat('de-DE', { style: 'currency', currency: valuta_code }).format(value);
                    formatted = formatted.replace(valuta_code, valuta)
                    return formatted;
                },
                Filter: ({ filter, onChange }) => <NumberRangeForFilter onChange={(s) => onChange(s)} />
            },
            {
                Header: i18n.t('Amount payed'), accessor: 'zakupnina_jm',
                Cell: ({ value }) => {
                    let valuta = 'KM', valuta_code = 'BAM';
                    let formatted = new Intl.NumberFormat('de-DE', { style: 'currency', currency: valuta_code }).format(value);
                    formatted = formatted.replace(valuta_code, valuta)
                    return formatted;
                },
                Filter: ({ filter, onChange }) => <NumberRangeForFilter onChange={(s) => onChange(s)} />
            },
            { id: 'jesenja', Header: i18n.t('Autumn sowing'), accessor: d => d.jesenja, Cell: ({ value }) => value != 0 ? i18n.t('Yes') : i18n.t('No') },
            { Header: i18n.t('Cultivated'), accessor: 'obradjeno', Filter: ({ filter, onChange }) => <NumberRangeForFilter onChange={(s) => onChange(s)} /> },
            { Header: i18n.t('Abandoned'), accessor: 'zapusteno', Filter: ({ filter, onChange }) => <NumberRangeForFilter onChange={(s) => onChange(s)} /> },
            { Header: i18n.t('Usurped'), accessor: 'uzurpirano', Filter: ({ filter, onChange }) => <NumberRangeForFilter onChange={(s) => onChange(s)} /> },
            { id: 'analiza_plodnosti', Header: i18n.t('Fertility analysis'), accessor: d => d.analiza_plodnosti, Cell: ({ value }) => value != 0 ? i18n.t('Yes') : i18n.t('No') },
            { Header: i18n.t('Year of field analysis'), accessor: 'godina_analize' },
        ];

        const url = 'report/yearly';

        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll, isSelected, toggleSelection, toggleAll, selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return { style: { backgroundColor: selected ? "#ACDAF1" : "inherit" } };
                }
                return {};
            }
        };

        const filterOptions = {
            defaultFiltered: [
                {
                    id: 'naziv_layer',
                    value: layerType.filter((element) => {
                        if (layers.includes(this.state.user_layer)) return element.value === this.state.user_layer;
                    })
                }
            ]
        };

        return (
            <div className="page">
                <div className="table-header" style={{ display: 'flex' }}>
                    <Fab onClick={() => this.onSearchClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button">
                        <span className="icon-search headerIcon" />
                    </Fab>
                    <Fab onClick={() => this.onExportClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button">
                        <span className="icon-download headerIcon" />
                    </Fab>
                    {this.state.selection.length === 1 ?
                        <Fab onClick={() => this.setState({ add: !this.state.add })}
                            color="secondary"
                            aria-label="edit"
                            className="action-button">
                            <span className="icon-Izmeni1 headerIcon" />
                        </Fab>
                        : null
                    }
                    <div style={{ fontSize: '18px', lineHeight: '32px', margin: '0px 16px' }}>
                        Total: {this.state.totalData}
                    </div>
                </div>
                <CheckboxTable ref={r => (this.checkboxTable = r)}
                    manual
                    data={this.state.data}
                    paging={true}
                    columns={columns}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.state.totalPages}
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => { this.getData(url, state, instance); }}
                    loading={this.state.loading}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    {...filterOptions}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF',
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
                {this.state.export && <ExportEnterprise
                    export={this.state.export}
                    onTextChange={({ title }) => { this.setState({ docTitle: title }); }}
                    handleClose={this.onExportClick}
                    exportDoc={this.exportFunction} />
                }
                {this.state.add && <AddYearlyReport add={this.state.add} handleClose={this.handleClick} data={this.state.selectedRow} edit={true} />}
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ViewYearlyReport);

function FilterSelect(props) {
    return <Select
        isMulti
        name="naziv"
        onChange={value => { props.onChange(value) }}
        closeMenuOnSelect={false}
        options={props.type}
        className="multi-select-in-filter"
        classNamePrefix="select"
        placeholder={i18n.t('Select')}
    />
}