import axios from '../../utils/AxiosWrapper';
export const Type = {
    //IMPLEMENT
    GET_IMPLEMENT_CALL: 'GET_IMPLEMENT_CALL',
    GET_IMPLEMENT_SUCCESS: 'GET_IMPLEMENT_SUCCESS',
    GET_IMPLEMENT_FAILED: 'GET_IMPLEMENT_FAILED',
    //GROUP
    GET_IMPLEMENT_GROUP_DROPDOWN_CALL: 'GET_IMPLEMENT_GROUP_DROPDOWN_CALL',
    GET_IMPLEMENT_GROUP_DROPDOWN_SUCCESS: 'GET_IMPLEMENT_GROUP_DROPDOWN_SUCCESS',
    GET_IMPLEMENT_GROUP_DROPDOWN_FAILED: 'GET_IMPLEMENT_GROUP_DROPDOWN_FAILED',
    //SUBGROUP
    GET_IMPLEMENT_SUBGROUP_DROPDOWN_CALL: 'GET_IMPLEMENT_SUBGROUP_DROPDOWN_CALL',
    GET_IMPLEMENT_SUBGROUP_DROPDOWN_SUCCESS: 'GET_IMPLEMENT_SUBGROUP_DROPDOWN_SUCCESS',
    GET_IMPLEMENT_SUBGROUP_DROPDOWN_FAILED: 'GET_IMPLEMENT_SUBGROUP_DROPDOWN_FAILED',
    //ADD
    ADD_IMPLEMENT_CALL: 'ADD_IMPLEMENT_CALL',
    ADD_IMPLEMENT_SUCCESS: 'ADD_IMPLEMENT_SUCCESS',
    ADD_IMPLEMENT_FAILED: 'ADD_IMPLEMENT_FAILED',
    //VIEW
    GET_VIEW_IMPLEMENT_CALL: 'GET_VIEW_IMPLEMENT_CALL',
    GET_VIEW_IMPLEMENT_SUCCESS: 'GET_VIEW_IMPLEMENT_SUCCESS',
    GET_VIEW_IMPLEMENT_FAILED: 'GET_VIEW_IMPLEMENT_FAILED',
    //
    ADD_IMPLEMENT_GROUP_CALL: 'ADD_IMPLEMENT_GROUP_CALL',
    ADD_IMPLEMENT_GROUP_SUCCESS: 'ADD_IMPLEMENT_GROUP_SUCCESS',
    ADD_IMPLEMENT_GROUP_FAILED: 'ADD_IMPLEMENT_GROUP_FAILED',
    GET_IMPLEMENT_GROUP_CALL: 'GET_IMPLEMENT_GROUP_CALL',
    GET_IMPLEMENT_GROUP_SUCCESS: 'GET_IMPLEMENT_GROUP_SUCCESS',
    GET_IMPLEMENT_GROUP_FAILED: 'GET_IMPLEMENT_GROUP_FAILED',
    //
    GET_IMPLEMENT_SUBGROUP_CALL: 'GET_IMPLEMENT_SUBGROUP_CALL',
    GET_IMPLEMENT_SUBGROUP_SUCCESS: 'GET_IMPLEMENT_SUBGROUP_SUCCESS',
    GET_IMPLEMENT_SUBGROUP_FAILED: 'GET_IMPLEMENT_SUBGROUP_FAILED',
    ADD_IMPLEMENT_SUBGROUP_CALL: 'ADD_IMPLEMENT_SUBGROUP_CALL',
    ADD_IMPLEMENT_SUBGROUP_SUCCESS: 'ADD_IMPLEMENT_SUBGROUP_SUCCESS',
    ADD_IMPLEMENT_SUBGROUP_FAILED: 'ADD_IMPLEMENT_SUBGROUP_FAILED',
    //SET
    SET_IMPLEMENT_ROW_IN_REDUCER: 'SET_IMPLEMENT_ROW_IN_REDUCER',
    DELETE_IMPLEMENT_CALL: 'DELETE_IMPLEMENT_CALL',
    DELETE_IMPLEMENT_SUCCESS: 'DELETE_IMPLEMENT_SUCCESS',
    DELETE_IMPLEMENT_FAILED: 'DELETE_IMPLEMENT_FAILED',
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',

};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function setRowInReducer(rowInfo, name) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_IMPLEMENT_ROW_IN_REDUCER,
            rowInfo: rowInfo,
            name: name
        })
    }
}

export function getImplement() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_IMPLEMENT_CALL
        });

        axios.get('api/implement/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_IMPLEMENT_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_IMPLEMENT_FAILED
                });
            });
    }
}

export function getGroup() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_IMPLEMENT_GROUP_DROPDOWN_CALL
        });

        axios.get('api/implement_group/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_IMPLEMENT_GROUP_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_IMPLEMENT_GROUP_DROPDOWN_FAILED
                });
            });
    }
}

export function getImplementGroup(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_IMPLEMENT_GROUP_CALL
        });
        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/implement_group/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_IMPLEMENT_GROUP_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_IMPLEMENT_GROUP_FAILED
                });
            });
    }
}

export function addImplementGroup(implementGroupObj) {
    return (dispatch) => {
        dispatch({
            type: Type.ADD_IMPLEMENT_GROUP_CALL
        });

        axios.post('api/implement_group/create', implementGroupObj)
            .then(function (response) {
                dispatch({
                    type: Type.ADD_IMPLEMENT_GROUP_SUCCESS,
                    data: response.data
                });
                dispatch(getGroup());
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_IMPLEMENT_GROUP_FAILED
                });
            });
    }
}

export function getSubgroup(id_grupa) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_IMPLEMENT_SUBGROUP_DROPDOWN_CALL
        });

        axios.get(`api/implement_subgroup/drop_down${id_grupa ? '?id_grupa=' + id_grupa:''}`)
            .then(function (response) {
                dispatch({
                    type: Type.GET_IMPLEMENT_SUBGROUP_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_IMPLEMENT_SUBGROUP_DROPDOWN_FAILED
                });
            });
    }
}

export function getImplementSubgroup(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_IMPLEMENT_SUBGROUP_CALL
        });
        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/implement_subgroup/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_IMPLEMENT_SUBGROUP_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_IMPLEMENT_SUBGROUP_FAILED
                });
            });
    }
}

export function addImplementSubgroup(implementSubgroupObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_IMPLEMENT_SUBGROUP_CALL
        });

        axios.post('api/implement_subgroup/create', implementSubgroupObj)
            .then(function (response) {
                dispatch({
                    type: Type.ADD_IMPLEMENT_SUBGROUP_SUCCESS,
                    data: response.data
                });
                dispatch(getSubgroup());
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_IMPLEMENT_SUBGROUP_FAILED
                });
            });
    }
}

export function addImplement(implementObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_IMPLEMENT_CALL
        });

        axios.post('api/implement/create', implementObj)
            .then(function (response) {
                dispatch({
                    type: Type.ADD_IMPLEMENT_SUCCESS,
                    data: response.data
                });
                dispatch(getGroup());
                dispatch(getSubgroup());
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_IMPLEMENT_FAILED
                });
            });
    }
}

export function getViewImplements(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_VIEW_IMPLEMENT_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/implement/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_VIEW_IMPLEMENT_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_VIEW_IMPLEMENT_FAILED
                });
            });
    }
}

export function deleteImplement(selection, name) {
    return (dispatch) => {

        dispatch({
            type: Type.DELETE_IMPLEMENT_CALL,
        });

        axios.post(`api/${name}/delete`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_IMPLEMENT_SUCCESS,
                    data: response.data,
                    name: name,
                    forDelete: selection
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_IMPLEMENT_FAILED
                });
            });
    }
}