import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import {
    TextField, Button, Grid, IconButton,
    Dialog, DialogContent, DialogTitle, Card, LinearProgress
} from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    addAnchor, getStates,
    setInitialState
} from '../../actions/AdministrationActions';
import { DatePicker } from 'material-ui-pickers';
import { getLocalGov } from '../../actions/FarmRegistarActions';
import { getUser } from '../../utils/CommonFunctions';
import moment from 'moment';
import DocumentDropZone from '../../utils/DocumentDropZone';
import DocumentGridList from '../../utils/DocumentGridList';
const styles = {
    card: {
        padding: '10px',
        width: '100%',
        margin: '10px',
        display: 'flex',
        flexWrap: 'wrap',
    },
}
export class AddAnchor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorObj: {}
        };
    }

    async componentDidMount() {
        const { getStates, getLocalGov } = this.props;
        getStates();
        let user = await getUser();
        if (user) {
            getLocalGov(user.id_opstina);
            const { anchorObj } = this.state;
            let obj = Object.assign({}, anchorObj);
            obj.id_opstina = user.id_opstina;
            obj.id_opstina_korisnik = user.id_opstina;
            obj.incorporation_date = moment(new Date, 'DD.MM.YYYY').format('DD.MM.YYYY');
            this.setState({
                anchorObj: obj
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.anchorPostingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('anchorPostingFailed');
        }
    }

    handleChangeValue = name => event => {
        const { anchorObj } = this.state;
        let obj = Object.assign({}, anchorObj);
        obj[name] = event.target.value;
        this.setState({ anchorObj: obj });
    };

    addAnchor() {
        const { anchorObj, acceptedDocuments } = this.state;
        const { addAnchor } = this.props;
        if (anchorObj.kontakt_mejl &&
            anchorObj.id_katastarska_opstina &&
            anchorObj.id_opstina &&
            anchorObj.naziv &&
            anchorObj.rc_number &&
            anchorObj.kontakt_mobilni &&
            anchorObj.boa_number &&
            anchorObj.bvn &&
            anchorObj.ulica_i_broj &&
            anchorObj.ulica_i_broj_korisnik &&
            anchorObj.id_katastarska_opstina_korisnik &&
            anchorObj.id_opstina_korisnik &&
            anchorObj.delatnost &&
            anchorObj.commercial_activity &&
            anchorObj.num_years_business &&
            anchorObj.incorporation_date &&
            anchorObj.postanski_broj &&
            anchorObj.ime &&
            anchorObj.prezime &&
            anchorObj.username &&
            anchorObj.password) {
            addAnchor(
                anchorObj,
                acceptedDocuments
            )
        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    handleDateChange = name => date => {
        const { anchorObj } = this.state;
        let obj = Object.assign({}, anchorObj);
        obj[name] = moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY');
        this.setState({ anchorObj: obj });
    }


    renderLabel = (date) => {
        if (date.isValid()) {
            return date.format('DD.MM.YYYY');
        } else {
            return '';
        }
    }


    render() {
        const { localGoverments, localGovermentsFetching, states, statesFetching, add, handleClose, edit, anchorDocuments, anchorDocumentsFetching } = this.props;
        const { anchorObj } = this.state;
        let localGovermentsArray = [];
        if (localGoverments && !localGovermentsFetching) {
            localGovermentsArray = localGoverments.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let statesArray = [];
        if (states && !statesFetching) {
            statesArray = states.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let typeOfBusiness = []
        const businessArray = [
            { label: i18n.t('Sole Proprietorship'), value: 'Sole Proprietorship' },
            { label: i18n.t('Partnership'), value: 'Partnership' },
            { label: i18n.t('Limited Liability'), value: 'Limited Liability' },
            { label: i18n.t('State Corp.'), value: 'State Corp.' }
        ]
        typeOfBusiness = businessArray.map((s, i) => {
            return <option key={s.id} value={s.value}>{s.label}</option>
        })
        let processingPlants = []
        const processingPlantArr = [
            { label: i18n.t('Yes'), value: 'Yes' },
            { label: i18n.t('No'), value: 'No' }
        ]
        processingPlants = processingPlantArr.map((s, i) => {
            return <option key={s.id} value={s.value}>{s.label}</option>
        })
        return (
            <div style={{ padding: 20 }}>
                <Dialog
                    open={add}
                    onClose={handleClose}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('Add anchor')}
                        <IconButton onClick={handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-zonal">
                            <Grid container justify="space-between" alignContent="space-between" spacing={16} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                                <TextField
                                    label={i18n.t('First name')}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={anchorObj.ime}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('ime')}
                                />
                                <TextField
                                    label={i18n.t('Last name')}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={anchorObj.prezime}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('prezime')}
                                />
                                <TextField
                                    label={i18n.t('Username')}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={anchorObj.username}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('username')}
                                />
                                <TextField
                                    label={i18n.t('Password')}
                                    type="password"
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('password')}
                                />
                                <TextField
                                    label={i18n.t('Bussines name')}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={anchorObj.naziv}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('naziv')}
                                />
                                <TextField
                                    label={i18n.t('RC Number (Corporate)')}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type="number"
                                    value={anchorObj.rc_number}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('rc_number')}
                                />
                                <TextField
                                    label={i18n.t('Mobile Number')}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={anchorObj.kontakt_mobilni}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('kontakt_mobilni')}
                                />
                                <TextField
                                    label={i18n.t('BOA Account Number')}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type="number"
                                    value={anchorObj.boa_number}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('boa_number')}
                                />
                                <TextField
                                    label={i18n.t('Bank Verification No')}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type="number"
                                    value={anchorObj.bvn}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('bvn')}
                                />
                                <TextField
                                    label={i18n.t('Official Email Address')}
                                    value={anchorObj.kontakt_mejl}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('kontakt_mejl')}
                                />
                                <Card raised style={styles.card}>
                                    <h2 style={{ width: '100%', alignSelf: 'center', display: 'flex', justifyContent: 'center' }}>{i18n.t('Residental Address')}</h2>
                                    <TextField
                                        label={i18n.t('Street name')}
                                        value={anchorObj.ulica_i_broj_korisnik}
                                        required
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={this.handleChangeValue('ulica_i_broj_korisnik')}
                                    />

                                    <TextField
                                        select
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label={i18n.t('City/Town')}
                                        value={anchorObj.id_katastarska_opstina_korisnik}
                                        required
                                        onChange={this.handleChangeValue('id_katastarska_opstina_korisnik')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}

                                    >

                                        <option value='' />
                                        {localGovermentsArray}
                                    </TextField>
                                    <TextField
                                        select
                                        label={i18n.t('State')}
                                        value={anchorObj.id_opstina_korisnik}
                                        required
                                        disabled
                                        onChange={this.handleChangeValue('id_opstina_korisnik')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}

                                    >

                                        <option value='' />
                                        {statesArray}
                                    </TextField>
                                </Card>
                                <TextField
                                    select
                                    label={i18n.t('Type of Business')}
                                    required
                                    value={anchorObj.delatnost}
                                    onChange={this.handleChangeValue('delatnost')}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                >
                                    <option value='' />
                                    {typeOfBusiness}
                                </TextField>
                                <TextField
                                    label={i18n.t('Agriculture Value Chain')}
                                    value={anchorObj.agriculture_value}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('agriculture_value')}
                                />
                                {anchorObj.agriculture_value &&
                                    <TextField
                                        select
                                        label={i18n.t('Do you have a Processing plant for this value chain?')}
                                        required
                                        value={anchorObj.processing_plant}
                                        onChange={this.handleChangeValue('processing_plant')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <option value='' />
                                        {processingPlants}
                                    </TextField>
                                }
                                <TextField
                                    label={i18n.t('Commercial Activity (state the type of business activity)')}
                                    multiline
                                    fullWidth
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    id="full-width"
                                    margin="normal"
                                    style={{ marginTop: '10px', marginBottom: '10px' }}
                                    value={anchorObj.commercial_activity}
                                    onChange={this.handleChangeValue('commercial_activity')}
                                />
                                <TextField
                                    label={i18n.t('Number of Years in the Business')}
                                    value={anchorObj.num_years_business}
                                    required
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('num_years_business')}
                                />
                                <DatePicker
                                    keyboard
                                    label={i18n.t('Date of Incorporation / Registration')}
                                    format="DD.MM.YYYY"
                                    disableOpenOnEnter
                                    value={anchorObj.incorporation_date ? moment(anchorObj.incorporation_date, 'DD.MM.YYYY') : moment(new Date, 'DD.MM.YYYY').format('DD.MM.YYYY')}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleDateChange('incorporation_date')}
                                    labelFunc={this.renderLabel}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    animateYearScrolling={false}
                                />
                                <TextField
                                    label={i18n.t('Post Office Box No')}
                                    value={anchorObj.postanski_broj}
                                    required
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('postanski_broj')}
                                />
                                <Card raised style={styles.card}>
                                    <h2 style={{ width: '100%', alignSelf: 'center', display: 'flex', justifyContent: 'center' }}>{i18n.t('Business Address')}</h2>
                                    <TextField
                                        label={i18n.t('Street name')}
                                        value={anchorObj.ulica_i_broj}
                                        required
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={this.handleChangeValue('ulica_i_broj')}
                                    />

                                    <TextField
                                        select
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label={i18n.t('City/Town')}
                                        value={anchorObj.id_katastarska_opstina}
                                        required
                                        onChange={this.handleChangeValue('id_katastarska_opstina')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}

                                    >

                                        <option value='' />
                                        {localGovermentsArray}
                                    </TextField>
                                    <TextField
                                        select
                                        label={i18n.t('State')}
                                        value={anchorObj.id_opstina}
                                        required
                                        disabled
                                        onChange={this.handleChangeValue('id_opstina')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}

                                    >

                                        <option value='' />
                                        {statesArray}
                                    </TextField>
                                </Card>
                                {edit && anchorDocuments && anchorDocuments.length !== 0 ?
                                    <DocumentGridList
                                        documentArray={anchorDocuments}
                                        showDelete={false}
                                    />
                                    : null}
                                <DocumentDropZone
                                    multiple={true}
                                    onDropAccepted={({ accepted, rejected }) => {
                                        this.setState({
                                            acceptedDocuments: accepted,
                                            rejectedDocuments: rejected
                                        });
                                    }}
                                />
                                {anchorDocumentsFetching && < LinearProgress className="spiner" />}
                                <Grid item xs={12}>
                                    <Button
                                        style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white' }}
                                        onClick={() => this.addAnchor()}
                                    >
                                        {i18n.t('Add')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
            </div >

        )
    }
};

function mapStateToProps(state) {
    return {
        states: state.administrationReducer.states,
        statesFetching: state.administrationReducer.statesFetching,
        anchorObj: state.administrationReducer.anchorObj,
        statesFetchingFailed: state.administrationReducer.statesFetchingFailed,
        anchorPostingFailed: state.administrationReducer.anchorPostingFailed,
        localGoverments: state.farmRegistarReducer.localGoverments,
        localGovermentsFetching: state.farmRegistarReducer.localGovermentsFetching,
        localGovermentsFetchingFailed: state.farmRegistarReducer.localGovermentsFetchingFailed,
        anchorDocuments: state.administrationReducer.anchorDocuments,
        anchorDocumentsFetching: state.administrationReducer.anchorDocumentsFetching,
        anchorDocumentsFetchingFailed: state.administrationReducer.anchorDocumentsFetchingFailed
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getStates: () => dispatch(getStates()),
        getLocalGov: (id_state) => dispatch(getLocalGov(id_state)),
        setInitialState: (component) => dispatch(setInitialState(component)),
        addAnchor: (anchorObj, acceptedDocuments) => dispatch(addAnchor(anchorObj, acceptedDocuments)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddAnchor)