import axios from '../utils/AxiosWrapper';
var FileSaver = require('file-saver');
export const Type = {
    EXPORT_CALL: 'EXPORT_CALL',
    EXPORT_PROGRESS: 'EXPORT_PROGRESS',
    EXPORT_SUCCESS: 'EXPORT_SUCCESS',
    EXPORT_FAILED: 'EXPORT_FAILED',
    SET_INITIAL_STATE: 'SET_INITIAL_STATE'
};

export function setInitialStateExport(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function exportDocument(title, state, url, format, options) {
    return (dispatch) => {
        dispatch({
            type: Type.EXPORT_CALL,
        });
        let params = {
            page: state.page,
            pageSize: state.pageSize,
            sorted: state.sorted,
            filtered: state.filtered,
            filename: title || 'file',
            format
        }
        if (options) {
            params = {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered,
                filename: title || 'file',
                format,
                ...options
            }
        }
        return axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
            params: params,
            onDownloadProgress: function (progressEvent) {
                dispatch({
                    type: Type.EXPORT_PROGRESS,
                    data: progressEvent.loaded
                });
            }
        })
            .then(function (response) {
                dispatch({
                    type: Type.EXPORT_SUCCESS
                });
                FileSaver.saveAs(new Blob([response.data]), `${title || 'file'}.${format ? format : 'xlsx'}`);

            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: Type.EXPORT_FAILED
                });
            });
    }
}