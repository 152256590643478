import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import {
    TextField, Button, Grid, Avatar, Card, LinearProgress
} from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';
import { getByIdIdentification, saveIdentMaping } from '../../actions/FarmerInfoActions'
const styles = {
    card: {
        padding: '10px',
        width: '100%',
        margin: '10px',
        display: 'flex',
        flexWrap: 'wrap',
    },
}

export class FarmIdentificationMaping extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            farmIdentificationObj: {}
        };
    }


    componentDidMount() {
        const { getByIdIdentification, id_klijent } = this.props;
        getByIdIdentification(id_klijent);
    }

    componentDidUpdate(prevProps) {
        const { farmIdentificationObj } = this.props;
        if (prevProps.farmIdentificationObj !== farmIdentificationObj) {
            this.setState({
                farmIdentificationObj
            })
        }
    }

    handleChangeValue = name => event => {
        const { farmIdentificationObj } = this.state;
        let obj = Object.assign({}, farmIdentificationObj);
        obj[name] = event.target.value;
        this.setState({ farmIdentificationObj: obj });
    };

    saveIdentificationMaping = () => {
        let { farmIdentificationObj } = this.state;
        const { saveIdentMaping, id_klijent } = this.props;
        farmIdentificationObj.id_klijent = id_klijent;
        saveIdentMaping(farmIdentificationObj);
    }



    render() {
        const { landOwnership, probability, farmerInfoFetching } = this.props
        const { farmIdentificationObj } = this.state;
        return (
            <div style={{ padding: 20 }}>
                {farmerInfoFetching && <LinearProgress />}
                <Card style={styles.card}>
                    <Grid item>
                        <TextField
                            label={i18n.t('Farmers ID')}
                            required
                            disabled
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={farmIdentificationObj.id_klijent}
                            style={{ width: '256px', margin: '10px' }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label={i18n.t('Farm Address')}
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={farmIdentificationObj.address}
                            onChange={this.handleChangeValue('address')}
                            style={{ width: '256px', margin: '10px' }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label={i18n.t('Coordinates')}
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={farmIdentificationObj.coordinates}
                            onChange={this.handleChangeValue('coordinates')}
                            style={{ width: '256px', margin: '10px' }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label={i18n.t('Farm size(ha)')}
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={farmIdentificationObj.farm_size}
                            onChange={this.handleChangeValue('farm_size')}
                            style={{ width: '256px', margin: '10px' }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label={i18n.t('Name of Crop Type')}
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={farmIdentificationObj.name_of_crop_type}
                            onChange={this.handleChangeValue('name_of_crop_type')}
                            style={{ width: '256px', margin: '10px' }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label={i18n.t('Vegetation: Terrain')}
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={farmIdentificationObj.terrain}
                            onChange={this.handleChangeValue('terrain')}
                            style={{ width: '256px', margin: '10px' }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label={i18n.t('Vegetation: Soil Type')}
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ width: '256px', margin: '10px' }}
                            value={farmIdentificationObj.soil_type}
                            onChange={this.handleChangeValue('soil_type')}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            select
                            label={i18n.t('Probability Of Flooding')}
                            required
                            SelectProps={{
                                native: true,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin="normal"
                            value={farmIdentificationObj.probability_of_flooding}
                            onChange={this.handleChangeValue('probability_of_flooding')}
                            style={{ width: '256px', margin: '10px' }}

                        >
                            <option value='' />
                            {probability}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField
                            select
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label={i18n.t('Land Ownership')}
                            required
                            SelectProps={{
                                native: true,
                            }}
                            value={farmIdentificationObj.land_ownewship}
                            onChange={this.handleChangeValue('land_ownewship')}
                            margin="normal"
                            style={{ width: '256px', margin: '10px' }}

                        >
                            <option value='' />
                            {landOwnership}
                        </TextField>
                    </Grid>
                </Card>
                <Grid item xs>
                    <Button
                        style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white' }}
                        onClick={() => this.saveIdentificationMaping()}
                    >
                        {i18n.t('Save')}
                    </Button>
                </Grid>
                <Grid item xs={3}>
                </Grid>
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        farmIdentificationObj: state.farmerInfoReducer.farmIdentificationObj,
        farmerInfoFetching: state.farmerInfoReducer.farmerInfoFetching
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getByIdIdentification: (id_klijent) => dispatch(getByIdIdentification(id_klijent)),
        saveIdentMaping: (info) => dispatch(saveIdentMaping(info))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FarmIdentificationMaping)
