/**
 * Created by pajicv on 5/31/18.
 */

import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Map, LayersControl, Popup } from 'react-leaflet';

import L from 'leaflet';
import 'leaflet.pm'
import 'leaflet.pm/dist/leaflet.pm.css'
import 'proj4leaflet'

import { calculateArea } from '../../utils/MapUtils';

import * as LayerActions from "../../actions/map/LayerActions";
import * as MapActions from "../../actions/map/MapActions";
import * as LayerSelectors from "../../selectors/LayerSelectors";

import BaseLayers from '../../layers/BaseLayers';
import SatelliteImageryLayers from '../../layers/SatelliteImageryLayers';
import ClientLayers from '../../layers/ClientLayers';
import FieldLayer from '../../layers/FieldLayer';
import ParcelLayer from '../../layers/ParcelLayer';
import NoteLayer from '../../layers/NoteLayer';
import InstitutionalLayers from '../../layers/InstitutionalLayers';
import Forecast10Days from '../../layers/Forecast10Days';

//import LayerTree from "./LayerTree/LayerTree_v2";
import LayerTree from "./LayerTree/Drawer/TreePanel";
import ParcelAttributes from "./ParcelAttributes";
import ZoomToMenu from "./Find/ZoomToMenu";
import ForecastMenu from "./ForecastMenu";
import TrackingButton from './Tracking/TrackingButton';

import './MapContainer.css';

import axios from '../../utils/AxiosWrapper';
import { getSeasonDropDown } from "../../actions/resources/SeasonsActions";

class MapPanel extends Component {

    constructor(props) {
        super(props);

        this.EMPTY_FEATURE = {
            type: null,
            isDrawn: false,
            isCompleted: false,
            geometry: null,
            attributes: null
        };

        this.state = {
            mapCenter: {
                lat: 0,
                lng: 0
            },
            mapBounds: null,
            mapZoom: 15,
            newFeature: { ...this.EMPTY_FEATURE },
            zoomToMenuOpen: false,
            isSeasonalForecastMenuOpen: false,
            selectedSeasons: [props.clientProfile.id_default_sezona]
        };

        this.saveParcel = this.saveParcel.bind(this);
        this.clearParcel = this.clearParcel.bind(this);
    }

    updateDimensions() {
        //za sada je hardkodirano, srediti da se izracunava
        const headerHeight = 0;
        const height = window.innerWidth >= 992 ? (window.innerHeight - headerHeight) : 400;
        this.setState({
            height: height
        });
    }

    componentWillMount() {

        this.updateDimensions();

        /*const user = this.getUser();

        const {lat, lng, zoom} = user;

        const latLng =  L.Projection.SphericalMercator.unproject({x: Number(lat), y: Number(lng)});

        console.log(latLng);

        this.setState({mapCenter: { ...latLng }, mapZoom: 6});*/

    }

    /*getUser = () => {

        let user = localStorage.getItem('user');

        let userObject = null;

        try {
            userObject = JSON.parse(user);
        } catch(err) {
            console.log(err);
        }

        return userObject;

    };*/

    loadParcels() {

        const { selectedSeasons } = this.state;

        if (!this.refs.map) return;

        const map = this.refs.map.leafletElement;

        //odredjujemo granice mape
        const bounds = map.getBounds();

        // Bounding box je u formi [min_longitude, min_latitude, max_longitude, max_latitude]
        const mapBounds = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];

        const bbox = mapBounds.join(',');

        //CRS je po defaultu EPSG:4326

        if (this.props.parcelLayer.visible) {
            this.props.getParcels(bbox/*, crs*/);
        }

        if (this.props.fieldLayer.visible) {
            this.props.getFields(bbox, selectedSeasons/*, crs*/);
        }

        if (this.props.noteLayer.visible) {
            this.props.fetchNotes(bbox/*, crs*/);
        }

    }

    loadMarkers() {

        if (!this.refs.map) return;

        this.props.getAgriResearchInstitutes();

        this.props.getRiverBasinAuth();

        this.props.getAgriUniversities();

    }

    zoomToBounds = (stateId, localGovId, fields) => {

        const self = this;

        axios.get(`api/map/zoomToBounds?stateId=${stateId}&localGovId=${localGovId}&fields=${fields.map(field => field.value).join(',')}`)
            .then(function (response) {

                const map = self.refs.map.leafletElement;

                const { latmin, lngmin, latmax, lngmax } = response.data.data;

                const bounds = [[latmin, lngmin], [latmax, lngmax]];

                map.fitBounds(bounds);
                map.setMaxBounds(bounds);

            })
            .catch(function (error) {
                console.log(error);
            });
    };

    zoomToCoordinates = (lat, lng) => {
        if (!this.refs.map) {
            return;
        }

        const map = this.refs.map.leafletElement;

        if (lat && lng) {
            map.flyTo(L.latLng(parseFloat(lat), parseFloat(lng)));
        }
    };

    handleSeasonSelect = (selectedSeasons) => this.setState({ selectedSeasons }, () => {
        if (this.props.fieldLayer.visible) {
            if (!this.refs.map) return;

            const map = this.refs.map.leafletElement;

            //odredjujemo granice mape
            const bounds = map.getBounds();

            // Bounding box je u formi [min_longitude, min_latitude, max_longitude, max_latitude]
            const mapBounds = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];

            const bbox = mapBounds.join(',');

            this.props.getFields(bbox, selectedSeasons/*, crs*/);
        }
    });

    componentDidMount() {

        window.addEventListener("resize", this.updateDimensions.bind(this));

        if (!this.refs.map) {
            return;
        }

        const map = this.refs.map.leafletElement;

        //postavljamo inicijalnu poziciju centra mape
        const latLng = map.getCenter();
        const mapCenter = { ...latLng };

        //postavljamo inicijalne granice mape
        const bounds = map.getBounds();
        const mapBounds = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];

        const mapZoom = map.getZoom();

        this.setState({ mapCenter, mapBounds, mapZoom });

        this.loadParcels();
        this.loadMarkers();
        this.props.getSeasonDropDown();

        map.pm.addControls({
            position: 'topleft', // toolbar position, options are 'topleft', 'topright', 'bottomleft', 'bottomright'
            drawMarker: true, // does not add button to draw markers
            drawPolyline: false, // does not add button to draw markers
            drawRectangle: false, // does not add button to draw markers
            drawPolygon: true, // adds button to draw a polygon
            drawCircle: false, // adds button to draw a cricle
            cutPolygon: false, // adds button to cut a hole in a polygon
            editMode: false, // adds button to toggle edit mode for all layers
            removalMode: false, // adds a button to remove layers
        });

        const self = this;

        map.on('zoomend', e => {

            //odredjujemo nove granice mape
            const bounds = map.getBounds();
            const mapBounds = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];

            const mapZoom = map.getZoom();

            if (mapZoom >= 13) {

                map.getPane('overlayPane').hidden = false;

                self.loadParcels();

            } else {

                map.getPane('overlayPane').hidden = true;

            }

            self.setState({ mapBounds, mapZoom });

        });

        map.on('moveend', e => {

            //odredjujemo novi centar mape
            const latLng = map.getCenter();
            const mapCenter = { lat: latLng.lat, lng: latLng.lng };

            //odredjujemo nove granice mape
            const bounds = map.getBounds();
            const mapBounds = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];

            self.loadParcels();

            self.setState({ mapCenter, mapBounds });
        });

        map.on('pm:create', e => {

            self.drawnFeature = e.layer;

            let newFeature = { ...self.state.newFeature };
            newFeature.type = self.props.editedLayerId;
            newFeature.geometry = e.layer.getLatLngs();
            newFeature.isDrawn = true;
            newFeature.attributes = {
                povrsina: calculateArea(newFeature.geometry)
            };

            self.setState({ newFeature });
        });

    }

    saveParcel(attributes) {
        let { geometry } = this.state.newFeature;
        this.props.saveParcel({ geometry, attributes });
        this.clearParcel();
    }

    clearParcel() {
        const map = this.refs.map.leafletElement;
        map.removeLayer(this.drawnFeature);
        this.setState({ newFeature: { ...this.EMPTY_FEATURE } });
    }

    componentWillUnmount() {
        //iskljucivanje map eventova jer je pucalo na unmount
        if (this.refs.map) {
            this.refs.map.leafletElement.off();
        }
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    handleOpenZoomToMenu = () => this.setState({ zoomToMenuOpen: true });

    handleCloseZoomToMenu = () => this.setState({ zoomToMenuOpen: false });

    handleOpenSeasonalForecastMenu = () => this.setState({ isSeasonalForecastMenuOpen: true });

    handleCloseSeasonalForecastMenu = () => this.setState({ isSeasonalForecastMenuOpen: false });

    render() {

        const { seasons } = this.props;

        const { zoomToMenuOpen, selectedSeasons, isSeasonalForecastMenuOpen } = this.state;

        const maxBounds = L.latLngBounds(L.latLng(this.props.mapBounds[0][0], this.props.mapBounds[0][1]),
            L.latLng(this.props.mapBounds[1][0], this.props.mapBounds[1][1]));

        let mapCenter = { lat: 0, lng: 0 };
        if (this.refs.map) {
            const map = this.refs.map.leafletElement;
            mapCenter = map.getCenter();
        }

        return (
            <div style={{ display: 'flex' }}>
                <div style={{ flexGrow: 1, height: this.state.height }}>
                    <Map
                        bounds={maxBounds}
                        maxBounds={maxBounds}
                        minZoom={6}
                        maxZoom={18}
                        maxBoundsViscosity={0.75}
                        ref="map"
                    >
                        <LayersControl position="topright">
                            <BaseLayers />
                            <SatelliteImageryLayers />
                            <ClientLayers />
                            <ParcelLayer />
                            <FieldLayer />
                            <NoteLayer />
                            <InstitutionalLayers />
                            <Forecast10Days mapBounds={this.state.mapBounds} />
                        </LayersControl>
                    </Map>
                    {zoomToMenuOpen && <ZoomToMenu
                        open={zoomToMenuOpen}
                        zoomToBounds={this.zoomToBounds}
                        zoomToCoordinates={this.zoomToCoordinates}
                        onZoomToMenuClose={this.handleCloseZoomToMenu}
                    />}
                    {
                        isSeasonalForecastMenuOpen && <ForecastMenu
                            open={isSeasonalForecastMenuOpen}
                            onClose={this.handleCloseSeasonalForecastMenu}
                            lat={mapCenter.lat}
                            lng={mapCenter.lng}
                        />
                    }
                    {(this.state.newFeature.isDrawn && !this.state.newFeature.isCompleted) &&
                        <ParcelAttributes attributes={this.state.newFeature.attributes}
                            setParcelAttributes={this.saveParcel} cancelParcelCreate={this.clearParcel} />
                    }
                </div>
                <LayerTree
                    onZoomToMenuOpen={this.handleOpenZoomToMenu}
                    onSeasonalForecastMenuOpen={this.handleOpenSeasonalForecastMenu}
                    seasons={seasons}
                    selectedSeasons={selectedSeasons}
                    onSeasonSelect={this.handleSeasonSelect}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        editedLayerId: LayerSelectors.getEditedLayerId(state),
        mapBounds: LayerSelectors.getMapBounds(state),
        parcelLayer: LayerSelectors.getParcelLayer(state),
        fieldLayer: LayerSelectors.getFieldLayer(state),
        noteLayer: LayerSelectors.getNoteLayer(state),
        mapInitFetching: state.layersReducer.mapInitFetching,
        mapInitFetchingFailed: state.layersReducer.mapInitFetchingFailed,
        mapCenter: state.layersReducer.mapCenter,
        mapBounds: state.layersReducer.mapBounds,
        clientProfile: state.appReducer.clientProfile,
        seasons: state.seasonReducer.seasonDropDown
    }
}

function mapDispatchToProps(dispatch) {
    return {
        saveParcel: payload => dispatch(LayerActions.saveParcel(payload)),
        getParcels: (bbox, crs) => dispatch(LayerActions.getParcels(bbox, crs)),
        saveField: payload => dispatch(LayerActions.saveField(payload)),
        getFields: (bbox, crs) => dispatch(LayerActions.getFields(bbox, crs)),
        getAgriResearchInstitutes: () => dispatch(LayerActions.getAgriResearchInstitutes()),
        getRiverBasinAuth: () => dispatch(LayerActions.getRiverBasinAuth()),
        getAgriUniversities: () => dispatch(LayerActions.getAgriUniversities()),
        fetchNotes: (bbox, crs) => dispatch(LayerActions.fetchNotes(bbox, crs)),
        fetchMapInit: () => dispatch(MapActions.fetchMapInit()),
        getSeasonDropDown: () => dispatch(getSeasonDropDown()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MapPanel);