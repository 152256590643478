import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Card } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './ImageryStyles';
import sr from './../satelliteinfo/sr.png';
import sr2 from './../satelliteinfo/sr2.png';
import sr3 from './../satelliteinfo/sr3.png';

export class SR extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.props.language === 1 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                Simple Ratio (SR) je pokazatelj intenziteta fotosinteze. Oslanja se na svojstvo biljaka da u procesu fotosinteze upijaju
                                crvenu, a reflektuju infracrvenu svetlost. Smanjena fotosinteza može biti posledica nedostatka najpre
                                azota, ali i vode i drugih mineralnih materija.
                            </p>
                            <div className={classes.image}>
                                <img src={sr} />
                            </div>
                            <div className={classes.image}>
                                <img src={sr2} />
                            </div>
                            <div className={classes.image}>
                                <img src={sr3} />
                            </div>
                        </div>
                    </Card>
                }
                {this.props.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                Simple Ratio (SR) is an indicator of the intensity of photosynthesis. It relies on the properties of plants to absorb red in the
                                process of photosynthesis and reflect infrared light. Reduced photosynthesis can be due to the lack of
                                nitrogen, but also of water of other mineral substances
                            </p>
                            <div className={classes.image}>
                                <img src={sr} />
                            </div>
                            <div className={classes.image}>
                                <img src={sr2} />
                            </div>
                            <div className={classes.image}>
                                <img src={sr3} />
                            </div>
                        </div>
                    </Card>
                }
                {this.props.language === 4 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                SR (Simple Ratio) je pokazatelj intenziteta fotosinteze. Oslanja se na svojstvo biljaka da 
                                apsorbiraju crvenu i reflektiraju infracrvenu svjetlost u procesu fotosinteze. 
                                Smanjena fotosinteza može biti posljedica nedostatka dušika, ali i vode i drugih mineralnih tvari.
                            </p>
                            <div className={classes.image}>
                                <img src={sr} />
                            </div>
                            <div className={classes.image}>
                                <img src={sr2} />
                            </div>
                            <div className={classes.image}>
                                <img src={sr3} />
                            </div>
                        </div>
                    </Card>
                }
            </div>
        )
    }
};

export default withStyles(styles)(SR);