import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import i18n from '../../../i18n/i18n';
import Select from 'react-select';
import { Fab, Typography, TextField } from '@material-ui/core';
import { ImportClients } from './ImportClients';
import { wentWrong } from "../../../utils/ToastConfig";
import axios from '../../../utils/AxiosWrapper';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import DatePickerForFilter from './../../../utils/DatePickerForFilter';
const CheckboxTable = checkboxHOC(ReactTable);;

export default class ViewClients extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            regionType: [], clientGroupType: [], seasonType: [], userGroupType: [],
            showFilters: false, import: false, switchFlag: false, loading: false,
            page: 0, pageSize: 25, sorted: null, filtered: null,
            selection: [], selectAll: false, selectedRow: [],
            totalPages: 0, totalClients: 0,
            add: false, edit: false, region: '',
            data: [],
        };
        this.toggleSelection = this.toggleSelection.bind(this);
    }

    componentDidMount() {
        if (this.state.switchFlag === false) {
            this.getByURL('region/filter_region', 'regionType');
            this.getByURL('season/filter_sezona', 'seasonType');
            this.getByURL('region/filter_klijent_grupa', 'clientGroupType');
        }
        else {
            this.getByURL('region/filter_region', 'regionType');
            this.getByURL('korisnik/filter_korisnik_grupa', 'userGroupType');
        }
    }

    getData(url, state, instance) {
        this.setState({ loading: true, data: [] });
        axios.get(`api/${url}`, {
            params: { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered }
        })
            .then(res => {
                this.setState({
                    data: res.data.data,
                    totalPages: res.data.total_pages,
                    totalClients: res.data.total,
                    loading: false
                });
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: res.data.data }))
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    }

    onSwitchClick = () => {
        this.setState({ switchFlag: !this.state.switchFlag });
        if (this.state.switchFlag === true) this.getData('klijent/read', this.state);
        else this.getData('korisnik/read_control_panel', this.state);
    }

    isSelected = key => { return this.state.selection.includes(key); };
    onSearchClick = () => this.setState({ showFilters: !this.state.showFilters });
    onImportClick = () => this.setState({ import: !this.state.import });
    onTableClick = () => this.props.history.push('/client');

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });
    };

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        }
        else selection.push(key);
        let selectedRow = null;
        if (selection.length == 1) selectedRow = this.state.data.filter((el) => el.id == selection)[0];
        this.setState({ selection, selectedRow });
    };

    handleClick = () => {
        if (this.state.edit == true && this.state.add == false) {
            this.setState({ edit: false, selectedRow: [] });
        }
        else if (this.state.selection.length > 0 && this.state.add == false && this.state.edit == false) {
            this.setState({ selectedRow: [] });
        }
        this.setState({ add: !this.state.add }, () => {
            if (this.state.switchFlag === true) this.getData('klijent/read', this.state);
            else this.getData('korisnik/read_control_panel', this.state);
            if (this.state.add == true && this.state.edit == false) {
                this.setState({
                    selection: [],
                    selectAll: false,
                })
            }
        });
    };

    render() {
        let regionType = this.state.regionType.map((item) => { return { label: item.naziv, value: item.id } });
        let seasonType = this.state.seasonType.map((item) => { return { label: item.naziv, value: item.id } });
        let userGroupType = this.state.userGroupType.map((item) => { return { label: item.naziv, value: item.id } });
        let clientGroupType = this.state.clientGroupType.map((item) => { return { label: item.naziv, value: item.id } });
        let zakupacType = [{ label: "Da", value: true }, { label: "Ne", value: false }];

        const columnsClient = [
            { Header: i18n.t('ID'), accessor: 'id' },
            { Header: i18n.t('Naziv'), accessor: 'naziv' },
            {
                id: 'vreme_kreiranja', Header: i18n.t('Date created'), accessor: item => item.vreme_kreiranja, Cell: ({ value }) => {
                    if (value !== null) return value.substring(8, 10) + '-' + value.substring(5, 7) + '-' + value.substring(0, 4);
                    else return '';
                }
            },
            {
                id: 'vreme_kreiranja', Header: i18n.t('Time created'), accessor: item => item.vreme_kreiranja, Cell: ({ value }) => {
                    if (value !== null) return value.substring(11, 19)
                    else return '';
                }
            },
            { Header: i18n.t('Region'), accessor: 'naziv_regiona', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={regionType} /> },
            { Header: i18n.t('Klijent grupa'), accessor: 'naziv_klijent_grupa', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={clientGroupType} /> },
            { Header: i18n.t('Sezona'), accessor: 'naziv_sezone', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={seasonType} /> },
            { Header: i18n.t('Opstina'), accessor: 'naziv_opstine' },
            { Header: i18n.t('Kat. opstina'), accessor: 'naziv_katastarske_opstine' },
            { Header: i18n.t('BPG'), accessor: 'bpg' },
            // { Header: i18n.t('Kontakt e-mail'), accessor: 'kontakt_mejl' },
            { Header: i18n.t('Kontakt osoba'), accessor: 'kontakt_osoba' },
            { Header: i18n.t('Jezik'), accessor: 'naziv_jezika' },
            {
                id: 'zakupac',
                Header: "Zakupac",
                accessor: d => { return d.zakupac ? 'Da' : 'Ne' },
                Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={zakupacType} />
            },
            {
                id: 'navodnjavanje',
                Header: "Navodnjavanje",
                accessor: d => { return d.navodnjavanje ? 'Da' : 'Ne' },
                Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={zakupacType} />
            }
        ];

        const columnsUser = [
            { Header: i18n.t('ID'), accessor: 'id' },
            { Header: i18n.t('Region'), accessor: 'naziv_regiona', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={regionType} /> },
            { Header: i18n.t('Korisnik grupa'), accessor: 'naziv_korisnik_grupe', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={userGroupType} /> },
            { Header: i18n.t('Klijent'), accessor: 'naziv_klijenta' },
            {
                id: 'vreme_kreiranja', Header: i18n.t('Date created'), accessor: item => item.vreme_kreiranja, Cell: ({ value }) => {
                    if (value !== null) return value.substring(8, 10) + '-' + value.substring(5, 7) + '-' + value.substring(0, 4);
                    else return '';
                }
            },
            {
                id: 'vreme_kreiranja', Header: i18n.t('Time created'), accessor: item => item.vreme_kreiranja, Cell: ({ value }) => {
                    if (value !== null) return value.substring(11, 19)
                    else return '';
                }
            },
            { Header: i18n.t('Username'), accessor: 'username' },
            { Header: i18n.t('Ime'), accessor: 'ime' },
            { Header: i18n.t('Prezime'), accessor: 'prezime' },
            { Header: i18n.t('Mejl'), accessor: 'mejl' },
            { Header: i18n.t('Jezik'), accessor: 'naziv_jezika' },
            { Header: i18n.t('Opstina'), accessor: 'naziv_opstine' },
            { Header: i18n.t('Kat. opstina'), accessor: 'naziv_katastarske_opstine' },
            { Header: i18n.t('Valuta'), accessor: 'naziv_valute' },
            {
                id: 'zakupac',
                Header: "Zakupac",
                accessor: d => { return d.zakupac ? 'Da' : 'Ne' },
                Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={zakupacType} />
            },
            {
                id: 'navodnjavanje',
                Header: "Navodnjavanje",
                accessor: d => { return d.navodnjavanje ? 'Da' : 'Ne' },
                Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={zakupacType} />
            }
        ];

        const columns = (this.state.switchFlag) ? columnsUser : columnsClient;
        const url = (this.state.switchFlag) ? 'korisnik/read_control_panel' : 'klijent/read';

        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll, isSelected, toggleSelection, toggleAll, selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return { style: { backgroundColor: selected ? "#ACDAF1" : "inherit" } };
                }
                return {};
            }
        };

        return (
            <div className="page">
                <div className="table-header">
                    <div style={{ display: 'flex' }}>
                        <Fab onClick={() => this.onImportClick()}
                            color="secondary"
                            aria-label="add"
                            className="action-button">
                            <span className="icon-Prikaciti headerIcon" />
                        </Fab>
                        <Fab onClick={() => this.onSearchClick()}
                            color="secondary"
                            aria-label="add"
                            className="action-button">
                            <span className="icon-search headerIcon" />
                        </Fab>
                        <FormControlLabel
                            style={{ marginLeft: "10px" }}
                            control={<Switch
                                checked={this.state.switchFlag}
                                onChange={() => this.onSwitchClick()}
                                name="checkedB"
                                color="primary" />}
                            label={i18n.t('Klijenti / Korisnici')}
                        />
                        {this.state.totalClients > 0 && <Typography style={{ marginLeft: '20px' }}>Total: {this.state.totalClients}</Typography>}
                        <TextField
                            label={i18n.t('Region')}
                            select
                            SelectProps={{ native: true }}
                            style={{ width: '256px', marginBottom: '10px', position: 'absolute', marginLeft: '500px', marginTop: '-15px' }}
                            onChange={(e) => {
                                console.log(e)
                                this.setState({ region: e.target.value }, () => {
                                    axios.get('api/season/season_region', { params: { id_region: this.state.region } })
                                        .then(res => this.setState({ seasonType: res.data.data }))
                                        .catch(err => console.log(err))
                                });
                            }}>
                            <option value='' />
                            {regionType.map((region) => <option key={region.value} value={region.value}>{region.label}</option>)}
                        </TextField>
                    </div>
                </div>
                <CheckboxTable ref={r => (this.checkboxTable = r)}
                    manual
                    data={this.state.data}
                    paging={true}
                    columns={columns}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.state.totalPages}
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => { this.getData(url, state, instance); }}
                    loading={this.state.loading}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF',
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
                {this.state.import && <ImportClients open={this.state.import} onClose={this.onImportClick} />}
            </div>
        )
    }
};

function FilterSelect(props) {
    return <Select
        isMulti
        name="naziv"
        onChange={value => { props.onChange(value) }}
        closeMenuOnSelect={false}
        options={props.type}
        className="multi-select-in-filter"
        classNamePrefix="select"
        placeholder={i18n.t('Select')}
    />
}