import i18next from 'i18next';

i18next
    .init({
        keySeparator: false,
        interpolation: {
            // React already does escaping
            escapeValue: false,
        },
        lng: 'English', // 'English'
        resources: {
            Hrvatski: {
                translation: require('./translations/cr.json')
            },
            Srpski: {
                translation: require('./translations/sr.json')
            },
            French: {
                translation: require('./translations/fr.json')
            },
        },
    })

export default i18next