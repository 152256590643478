import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import {
    TextField, Button, Grid, Avatar, Card, LinearProgress
} from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';
import DocumentGridList from '../../utils/DocumentGridList';
import { DatePicker } from 'material-ui-pickers';
import { getByMonitoring, saveMonitoring } from '../../actions/FarmerInfoActions';
import moment from 'moment'
const styles = {
    card: {
        padding: '10px',
        width: '100%',
        margin: '10px',
        display: 'flex',
        flexWrap: 'wrap',
    },
}

export class MonitoringAndEvaluation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            monitoringAndEvaluationObj: {}
        };
    }

    componentDidMount() {
        const { getByMonitoring, id_klijent } = this.props;
        getByMonitoring(id_klijent);
    }

    componentDidUpdate(prevProps) {
        const { monitoringAndEvaluationObj } = this.props;
        if (prevProps.monitoringAndEvaluationObj !== monitoringAndEvaluationObj) {
            this.setState({
                monitoringAndEvaluationObj
            })
        }
    }

    handleChangeValue = name => event => {
        const { monitoringAndEvaluationObj } = this.state;
        let obj = Object.assign({}, monitoringAndEvaluationObj);
        obj[name] = event.target.value;
        this.setState({ monitoringAndEvaluationObj: obj });
    };

    handleDateChange = name => date => {
        const { monitoringAndEvaluationObj } = this.state;
        let obj = Object.assign({}, monitoringAndEvaluationObj);
        obj[name] = moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY');
        this.setState({ monitoringAndEvaluationObj: obj });
    }


    renderLabel = (date) => {
        if (date.isValid()) {
            return date.format('DD.MM.YYYY');
        } else {
            return '';
        }
    }

    saveMonitoring = () => {
        let { monitoringAndEvaluationObj } = this.state;
        const { saveMonitoring, id_klijent } = this.props;
        monitoringAndEvaluationObj.id_klijent = id_klijent;
        saveMonitoring(monitoringAndEvaluationObj);
    }



    render() {
        const { monitoringAndEvaluationObj } = this.state;
        const { farmerRating, farmerInfoFetching } = this.props;
        return (
            <div style={{ padding: 20 }}>
                {farmerInfoFetching && <LinearProgress />}
                <Card style={styles.card}>
                    <Grid item>
                        <TextField
                            label={i18n.t('Farmer ID')}
                            required
                            disabled
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={monitoringAndEvaluationObj.id_klijent}
                            style={{ width: '256px', margin: '10px' }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label={i18n.t("Extension Officer's Name")}
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={monitoringAndEvaluationObj.officers_name}
                            onChange={this.handleChangeValue('officers_name')}
                            style={{ width: '256px', margin: '10px' }}
                        />
                    </Grid>
                    <Grid item>
                        <DatePicker
                            keyboard
                            label={i18n.t('Date of Contact with Farmer')}
                            format="DD.MM.YYYY"
                            value={monitoringAndEvaluationObj.date_of_contact ? moment(monitoringAndEvaluationObj.date_of_contact, 'DD.MM.YYYY') : new Date()}
                            style={{ width: '256px', margin: '10px' }}
                            onChange={this.handleDateChange('date_of_contact')}
                            labelFunc={this.renderLabel}
                            disableOpenOnEnter
                            animateYearScrolling={false}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label={i18n.t('Confirmation of inputs application')}
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={monitoringAndEvaluationObj.confirmation_of_inputs}
                            onChange={this.handleChangeValue('confirmation_of_inputs')}
                            style={{ width: '256px', margin: '10px' }}
                        />
                    </Grid>
                    <Grid item xl={12}>
                        <TextField
                            multiline
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ width: '100%' }}
                            variant="outlined"
                            id="full-width"
                            margin="normal"
                            value={monitoringAndEvaluationObj.description}
                            onChange={this.handleChangeValue('description')}
                            label={i18n.t('Brief description of farm activities as at time of visit')}
                        />
                    </Grid>
                    <Grid item xl={12} style={{ paddingBottom: '20px', paddingTop: '20px' }}>
                        <DocumentGridList
                            documentArray={[]}
                            showDelete={false}
                            title={i18n.t('Pictures of farm ')}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            select
                            label={i18n.t('Rating of farming challenges')}
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}
                            SelectProps={{
                                native: true,
                            }}
                            value={monitoringAndEvaluationObj.rating}
                            onChange={this.handleChangeValue('rating')}
                            margin="normal"
                            style={{ width: '256px', margin: '10px' }}

                        >
                            <option value='' />
                            {farmerRating}
                        </TextField>
                    </Grid>
                    <Grid item xl={12} style={{ paddingBottom: '20px', paddingTop: '20px' }}>
                        <DocumentGridList
                            documentArray={[]}
                            showDelete={false}
                            title={i18n.t('Confirmation of extension worker contact with farmer  (Farmers biometric)')}
                        />
                    </Grid>
                </Card>
                <Grid item xs>
                    <Button
                        style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white' }}
                        onClick={() => this.saveMonitoring()}
                    >
                        {i18n.t('Save')}
                    </Button>
                </Grid>
                <Grid item xs={3}>
                </Grid>
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        monitoringAndEvaluationObj: state.farmerInfoReducer.monitoringAndEvaluationObj,
        farmerInfoFetching: state.farmerInfoReducer.farmerInfoFetching
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getByMonitoring: (id_klijent) => dispatch(getByMonitoring(id_klijent)),
        saveMonitoring: (info) => dispatch(saveMonitoring(info))
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MonitoringAndEvaluation)
