import React from 'react';
import { connect } from 'react-redux';
import { withStyles, TextField, Fab, Button, Table, Tooltip, TableBody, TableCell, TableHead, TableRow, Dialog, DialogTitle, DialogContent, IconButton, Typography } from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';
import ReactSelect from '../../utils/ReactSelect';
import i18n from '../../i18n/i18n';
import { getFieldDropDown } from '../../actions/catastral_data/FieldActions';
import { getFarmersDropdown } from '../../actions/financial/IncentiveActions';
import immutable from 'seamless-immutable'
import AddIcon from '@material-ui/icons/Add';
import * as Icons from '@material-ui/icons';
import { toast } from 'react-toastify';
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment';
import { Delete } from '../../utils/Delete';

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        height: '45px',
        fontWeight: '400',
        fontSize: 17
    },
    body: {
        fontSize: 14,
    },
    root: {
        padding: '5px',
        textAlign: 'left',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        verticalAlign: 'inherit'
    }
}))(TableCell);
const styles = theme => ({
    innerContainer: {
        margin: 20,
        width: '100%',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },
    innerContainerTable: {
        width: '95%',
        textAlign: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },

    card: {
        margin: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    cssRoot: {
        color: '#e50000',
        '&:hover': {
            color: '#ff0000',
        },
    },
    textInput: {
        width: 300,
    },
    button: {
        marginLeft: 30,
        width: 65,
        height: 57
    },
    buttonsRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    dialogContent: {
        minHeight: '200px'
    }
});
export class PublicProcurementField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            table: [],
            fieldDropDown: [],
            farmerDropdown: [],
            tabla: {},
            add: false,
            edit: false,
            delete: false,
            index: null,
            id_katastarska_opstina: null
        }
    }

    componentDidMount() {
        this.props.getFarmersDropdown();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.fieldDropDown !== this.props.fieldDropDown) {
            this.setState({
                fieldDropDown: immutable.asMutable(this.props.fieldDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            })
        }
        if (prevProps.farmerDropdown !== this.props.farmerDropdown) {
            this.setState({
                farmerDropdown: immutable.asMutable(this.props.farmerDropdown.map((item) => { return { label: item.kontakt_osoba, value: item.id } }), { deep: true }),
            })
        }
        if (this.props.publicProcurementObj.table && prevState.table !== this.props.publicProcurementObj.table) {
            this.setState({
                table: this.props.publicProcurementObj.table
            });
        }
        if (this.state.id_katastarska_opstina !== this.props.publicProcurementObj.id_katastarska_opstina) {
            this.setState({ id_katastarska_opstina: this.props.publicProcurementObj.id_katastarska_opstina }, () => {
                const { publicProcurementObj } = this.props;
                this.props.getFieldDropDown(publicProcurementObj.id_katastarska_opstina);
            })
        }
    }

    handleChangeValue = name => selected => {
        this.setState((state) => {
            let tabla = state[name];
            let tb = selected && selected || {};
            let addedNaziv = Object.assign(tabla, tb, { tabla_naziv: tb.label, id_tabla: tb.value })
            return { ...state, tabla: addedNaziv };
        })
    }

    handleChangeProperty = name => selected => {
        this.setState((state) => {
            let tabla = Object.assign({}, state['tabla']);
            tabla[name] = selected && selected.value;
            tabla['klijent_zakupac'] = this.getFarmerName(tabla.id_klijent_zakupac);
            return { ...state, tabla };
        })
    }

    handleTextFieldChange = name => event => {
        event.persist();
        this.setState((state) => {
            let tabla = Object.assign({}, state['tabla']);
            tabla[name] = event.target.value;
            return { ...state, tabla };
        })
    }

    handleAdd = (tabla) => {
        const { handleAddField, handleEditChild } = this.props;
        const { index, table } = this.state;
        if (table.some(item => item.id_tabla === tabla.id_tabla) && index === null) {
            toast.error(i18n.t('Field already selected!'), {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } else if (index !== null) {
            handleEditChild(tabla);
            this.setState({
                tabla: {},
                index: null
            });
            this.handlePopup();
        } else {
            handleAddField('table', tabla);
            this.setState({
                tabla: {},
                index: null
            });
            this.handlePopup();
        }
    }

    isDisabled(tabla) {
        if (tabla.id_tabla) {
            return false;
        } else {
            return true;
        }
    }

    handlePopup = () => {
        if (this.state.edit == true && this.state.add == false) {
            this.setState({ edit: false })
        }
        this.setState({ add: !this.state.add }, () => {
            if (this.state.add == true && this.state.edit == false) {
                this.setState({
                    tabla: {}
                })
            }
        });
    }

    getFarmerName = (id_klijent_zakupac) => {
        const { farmerDropdown } = this.state;
        let result = farmerDropdown.filter((unit) => {
            if (unit.value === id_klijent_zakupac) {
                return unit.label;
            }
        })[0]
        return result && result.label || '';
    }

    onDeleteClick = (tabla, index) => {
        this.setState({
            tabla,
            index,
            delete: !this.state.delete
        })
    }

    deleteItem = () => {
        const { handleDeleteChild } = this.props;
        const { tabla, index } = this.state;
        handleDeleteChild(index, tabla);
        this.setState(prevState => {
            let newTables = prevState.table;
            return {
                table: newTables.filter((_, i) => i !== index),
                delete: false
            };
        })
    }

    onEditClick = (tabla, i) => {
        let mat = Object.assign({}, tabla);
        mat['label'] = mat.tabla_naziv;
        mat['value'] = mat.id_tabla;
        this.setState({
            tabla: mat,
            index: i,
            add: true,
            edit: true
        })
    }

    handleChangeDateValue = name => date => {
        this.setState((state) => {
            let tabla = Object.assign({}, state['tabla']);
            tabla[name] = moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY');
            return { ...state, tabla };
        })
    }

    renderLabel = (date) => {
        if (date.isValid()) {
            return date.format('DD.MM.YYYY');
        } else {
            return '';
        }
    }

    render() {
        const { classes, theme } = this.props;
        const { table } = this.state;
        const fieldRender = table.length !== 0 && table.map((item, i) => {
            if (!item) return null;
            return <TableRow key={i}>
                <CustomTableCell>{item.tabla_naziv || (item.tabla && item.tabla.naziv)}</CustomTableCell>
                <CustomTableCell>{item.broj_odluke}</CustomTableCell>
                <CustomTableCell>{item.klijent_zakupac || (item.klijent && item.klijent.naziv)}</CustomTableCell>
                <CustomTableCell>{item.cena_po_ha_rsd}</CustomTableCell>
                <CustomTableCell>{item.cena_po_ha_eur}</CustomTableCell>
                <CustomTableCell>{item.depozit}</CustomTableCell>
                <CustomTableCell>{item.datum}</CustomTableCell>
                <CustomTableCell>
                    <Tooltip title={i18n.t("Edit")}>
                        <IconButton aria-label="edit"
                            color="secondary"
                            onClick={() => this.onEditClick(item, i)}
                        >
                            <Icons.Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={i18n.t("Delete")}>
                        <IconButton aria-label="Delete"
                            className={classes.cssRoot}
                            onClick={() => this.onDeleteClick(item, i)}
                        >
                            <Icons.Delete />
                        </IconButton>
                    </Tooltip>
                </CustomTableCell>
            </TableRow>
        })
        return (
            <div className={classes.innerContainer} >
                {this.state.add &&
                    <Dialog
                        open={this.state.add}
                        onClose={this.handlePopup}
                        maxWidth="xs"
                        disableBackdropClick
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle
                            style={{ background: theme.palette.secondary.dark }}
                            disableTypography={true}
                            className="responsive-dialog-title">
                            {this.state.edit ? i18n.t('Edit farmer') : i18n.t('Add field')}
                            <IconButton onClick={this.handlePopup}>
                                <span className="icon-cancel-circle closeIcon" />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            <div className={classes.card}>
                                <ReactSelect
                                    closeMenuOnSelect={true}
                                    isClearable={true}
                                    maxMenuHeight={150}
                                    isDisabled={this.state.edit}
                                    value={this.state.fieldDropDown.find((element) => {
                                        return element.value === this.state.tabla.id_tabla || element.label === this.state.tabla.tabla_naziv
                                    })}
                                    onChange={this.handleChangeValue('tabla')}
                                    options={this.state.fieldDropDown}
                                    classNamePrefix="select"
                                    label={i18n.t('Field') + '*'}
                                    placeholder={i18n.t('Select')}
                                    className={classes.textInput}
                                />
                                {this.state.edit &&
                                    <div className={classes.textInput}>
                                        <TextField
                                            label={i18n.t('Decision number')}
                                            className={classes.textInput}
                                            value={this.state.tabla.broj_odluke}
                                            onChange={this.handleTextFieldChange('broj_odluke')}
                                        />
                                        < ReactSelect
                                            closeMenuOnSelect={true}
                                            isClearable={true}
                                            maxMenuHeight={150}
                                            value={this.state.farmerDropdown.find((element) => {
                                                return element.value === this.state.tabla.id_klijent_zakupac || element.label === this.state.tabla.klijent_zakupac
                                            })}
                                            onChange={this.handleChangeProperty('id_klijent_zakupac')}
                                            options={this.state.farmerDropdown}
                                            classNamePrefix="select"
                                            label={i18n.t('Farmer') + '*'}
                                            className={classes.textInput}
                                        />
                                        <DatePicker
                                            keyboard
                                            label={i18n.t('Date *')}
                                            format="DD.MM.YYYY"
                                            value={this.state.tabla.datum && moment(this.state.tabla.datum, 'DD.MM.YYYY')}
                                            onChange={this.handleChangeDateValue('datum')}
                                            className={classes.textInput}
                                        />
                                        <TextField
                                            type="number"
                                            label={i18n.t('Price per ha rsd')}
                                            className={classes.textInput}
                                            value={this.state.tabla.cena_po_ha_rsd}
                                            onChange={this.handleTextFieldChange('cena_po_ha_rsd')}
                                        />
                                        <TextField
                                            type="number"
                                            label={i18n.t('Price per ha eur')}
                                            className={classes.textInput}
                                            value={this.state.tabla.cena_po_ha_eur}
                                            onChange={this.handleTextFieldChange('cena_po_ha_eur')}
                                        />
                                        <TextField
                                            type="number"
                                            label={i18n.t('Deposit')}
                                            className={classes.textInput}
                                            value={this.state.tabla.depozit}
                                            onChange={this.handleTextFieldChange('depozit')}
                                        />
                                    </div>
                                }
                                <div className={classes.buttonsRow}>
                                    <Button disabled={this.isDisabled(this.state.tabla)} onClick={() => this.handleAdd(this.state.tabla)}>{this.state.edit ? i18n.t('Edit') : i18n.t('Add')}</Button>
                                    <Button onClick={this.handlePopup}>{i18n.t('Cancel')}</Button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                }
                <Typography variant="title">{i18n.t('Fields')}</Typography>
                <div className={classes.innerContainerTable}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell >{i18n.t('Field')}</CustomTableCell>
                                <CustomTableCell >{i18n.t('Decision number')}</CustomTableCell>
                                <CustomTableCell >{i18n.t('Farmer')}</CustomTableCell>
                                <CustomTableCell >{i18n.t('Price per ha rsd')}</CustomTableCell>
                                <CustomTableCell >{i18n.t('Price per ha eur')}</CustomTableCell>
                                <CustomTableCell >{i18n.t('Deposit')}</CustomTableCell>
                                <CustomTableCell >{i18n.t('Date')}</CustomTableCell>
                                <CustomTableCell >{i18n.t('Edit/Delete')}</CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fieldRender}
                        </TableBody>
                    </Table>
                    <Fab
                        color="primary"
                        aria-label="Add"
                        className={classes.button}
                        onClick={this.handlePopup}
                    >
                        <AddIcon />
                    </Fab>
                    {this.state.delete && <Delete delete={this.state.delete} handleClose={this.onDeleteClick} deleteItem={this.deleteItem} />}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        fieldDropDown: state.fieldReducer.fieldDropDown,
        farmerDropdown: state.incentiveReducer.farmerDropdown,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getFieldDropDown: (opstina) => dispatch(getFieldDropDown(opstina)),
        getFarmersDropdown: () => dispatch(getFarmersDropdown()),
    }
}
export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(PublicProcurementField))