import React from 'react';
import MeteoInfo from './MeteoInfo';

export default class MeteoInfoButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    };

    onClose = () => {
        this.setState({ open: !this.state.open });
    };

    render() {
        return (
            <div>
                <span
                    className="icon-info menuIcon"
                    onClick={() => this.onClose()}
                    style={{
                        background: '#04764e',
                        borderRadius: '50%', padding: '7px',
                        cursor: 'pointer',
                        userSelect: 'none',
                        fontSize: '22px',
                        lineHeight: '36px',
                    }}>
                </span>
                {this.state.open &&
                    <MeteoInfo
                        open={this.state.open}
                        onClose={this.onClose}
                        text={this.props.text}
                        title={this.props.title}
                        width={this.props.width}
                        height={this.props.height}
                    />}
            </div >
        );
    };
};