import React from 'react';
// import ReactTable from 'react-table';
// import 'react-table/react-table.css';
import i18n from '../../i18n/i18n';
// import Select from 'react-select';
import { Typography, Button, TextField } from '@material-ui/core';
import { wentWrong } from "../../utils/ToastConfig";
import axios from '../../utils/AxiosWrapper';
// import checkboxHOC from "react-table/lib/hoc/selectTable";


export default class MapControlPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search_user: null,
            data: []
        };
    }

    render() {
        return (
            <div className="page">
                <div style={{ display: 'flex' }}>
                    <TextField required
                        label={i18n.t('User')}
                        variant="outlined"
                        helperText="Username korisnika"
                        value={this.state.search_user}
                        InputLabelProps={{ shrink: true }}
                        style={{ width: '256px', marginBottom: '10px', marginRight: '10px', marginLeft: '30px', marginTop: '20px' }}
                        onChange={(e) => { this.setState({ search_user: e.target.value }); }} >
                    </TextField>
                    <Button style={{ height: '40px', alignSelf: 'center' }} onClick={() => {
                        axios.get('api/korisnik/read_by_id_map', { params: { username: this.state.search_user } })
                            .then(res => { this.setState({ data: res.data.data }) })
                            .catch(err => { console.log(err); wentWrong('Something went wrong') })
                    }}>{i18n.t('Search')}</Button>
                </div>
                <div>
                    <table style={{ width: '100%', textAlignLast: 'center' }}>
                        <tr>
                            <th>{i18n.t('ID')}</th>
                            <th>{i18n.t('Title')}</th>
                            <th>{i18n.t('Land area')}</th>
                            <th>{i18n.t('Activity')}</th>
                            <th>{i18n.t('Zakupac')}</th>
                            <th>{i18n.t('Delete')}</th>
                        </tr>
                        {this.state.data ?
                            this.state.data.map((item, i) => {
                                return <tr>
                                    <td>{item.id}</td>
                                    <td>{item.naziv}</td>
                                    <td>{item.povrsina}</td>
                                    <td>{item.has_activity}</td>
                                    <td>{item.zakupac ? i18n.t('Yes') : i18n.t('No')}</td>
                                    <td>
                                        {item.has_activity === '0' ?
                                            <Button onClick={() => {
                                                axios.get('api/korisnik/delete_by_id', { params: { id_tabla: item.id } })
                                                    .then(res => {
                                                        axios.get('api/korisnik/read_by_id_map', { params: { username: this.state.search_user } })
                                                            .then(res => { this.setState({ data: res.data.data }) })
                                                            .catch(err => { console.log(err) })
                                                    })
                                                    .catch(err => { console.log(err) })
                                            }}>{i18n.t('Delete')}</Button> :
                                            <Button onClick={() => { }}>⠀⠀⠀⠀⠀⠀</Button>
                                        }
                                    </td>
                                </tr>
                            })
                            : null
                        }
                    </table>
                </div>
            </div>
        )
    }
};
