import React from 'react';
import { connect } from 'react-redux';
import {
    Button, IconButton, Menu, Typography
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { Doughnut, Pie, Bar } from 'react-chartjs-2';
import DashboardDrawer from '../../utils/DashboardDrawer';
import {
    getFarmerDashboard, getFarmersCount
} from '../../actions/FarmRegistarActions';
import {
    getLocalGov,
} from '../../actions/FarmRegistarActions';
import InputLabel from '@material-ui/core/InputLabel';
import { getStates, getZones } from '../../actions/AdministrationActions';
import randomColor from 'randomcolor';
import immutable from 'seamless-immutable';
import Select, { components } from 'react-select';
import i18n from '../../i18n/i18n';
import { getUser } from '../../utils/CommonFunctions';
const IndicatorsContainer = (props) => {
    return (
        <div style={{ background: '#61a50e' }}>
            <components.IndicatorsContainer {...props} />
        </div>
    );
};
export class FarmDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            id_opstina: [],
            id_katastarska_opstina: [],
            id_zona: [],
            user: {}
        };
    }

    async componentDidMount() {
        const { getFarmerDashboard, getFarmersCount, getStates, getLocalGov, getZones, states, localGoverments, zones } = this.props;
        let user = await getUser();
        if (user.id_region != 1) {
            getFarmerDashboard({});
        } else {
            getFarmersCount();
        }
        if (states.length === 0) {
            getStates();
        }
        if (localGoverments.length === 0) {
            getLocalGov();
        }
        if (zones.length === 0) {
            getZones();
        }
        this.setState({ user })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            zones: immutable.asMutable(nextProps.zones.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            states: immutable.asMutable(nextProps.states.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            localGoverments: immutable.asMutable(nextProps.localGoverments.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
        })
    }

    onPieClick = () => {
        this.props.history.push('/farm_dashboard')
    }

    onTableClick = () => {
        this.props.history.push('/farmer');
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleClear = () => {
        this.setState({
            id_opstina: [],
            id_katastarska_opstina: [],
            id_zona: []
        }, function () {
            this.props.getFarmerDashboard({});
        });
    };


    handleOk = () => {
        let filters = {};
        filters['id_zona'] = this.state.id_zona.map((item) => {
            return item.value;
        })
        filters['id_opstina'] = this.state.id_opstina.map((item) => {
            return item.value;
        })
        filters['id_katastarska_opstina'] = this.state.id_katastarska_opstina.map((item) => {
            return item.value;
        });
        this.props.getFarmerDashboard(filters);
    }

    handleChange = name => selectedOption => {
        this.setState({
            [name]: selectedOption
        })
    }

    render() {
        const { anchorEl, user } = this.state;
        const { farmersItems, global } = this.props;
        let localGovData = this.confLocalGov;
        let statesData = this.confStates;
        let zonesData = this.confZones;
        let blackListData = this.blackListStates;

        if (farmersItems && farmersItems.zones) {
            zonesData.labels = farmersItems.zones.labels.asMutable();
            zonesData.datasets[0].data = farmersItems.zones.count.asMutable();
            zonesData.datasets[0].data1 = farmersItems.zones.percent.asMutable();
        }

        if (farmersItems && farmersItems.localGoverments) {
            localGovData.labels = farmersItems.localGoverments.labels.asMutable();
            localGovData.datasets[0].data = farmersItems.localGoverments.count.asMutable();
            localGovData.datasets[0].data1 = farmersItems.localGoverments.percent.asMutable();
        }

        if (farmersItems && farmersItems.states) {
            statesData.labels = farmersItems.states.labels.asMutable();
            statesData.datasets[0].data = farmersItems.states.count.asMutable();
            statesData.datasets[0].data1 = farmersItems.states.percent.asMutable();
        }

        if (farmersItems && farmersItems.blackList) {
            blackListData.labels = farmersItems.blackList.labels.asMutable();
            blackListData.datasets[0].data = farmersItems.blackList.count.asMutable();
            blackListData.datasets[0].data1 = farmersItems.blackList.percent.asMutable();
        }

        return (
            <div style={{ 'padding': '20px' }}>
                {user.id_klijent_grupa === 12 &&
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
                            <h1>{i18n.t('Total number of farmers in Serbia')} : {farmersItems.totalSerbia} </h1>
                            <h1>{i18n.t('Total number of farmers in Nigeria')} : {farmersItems.totalNigeria} </h1>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
                            <Button onClick={() => this.props.getFarmerDashboard({}, 2)}>{i18n.t('See by states(Serbia)')} </Button>
                            <Button onClick={() => this.props.getFarmerDashboard({}, 13)}>{i18n.t('See by states(Nigeria)')}</Button>
                        </div>
                    </div>
                }
                {farmersItems.total > 0 &&
                    <div>
                        {user.id_klijent_grupa !== 12 &&
                            <Card style={styles.card}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    {farmersItems.total > 0 && user.id_klijent_grupa !== 12 &&
                                        <h1>{i18n.t('Total number of farmers')} : {farmersItems.total} </h1>
                                    }
                                        <div>
                                            <div className="filter-panel-dashboard">
                                                <IconButton
                                                    styles={styles.button}
                                                    aria-owns={anchorEl ? 'simple-menu' : null}
                                                    aria-haspopup="true"
                                                    className="action-button-filter"
                                                    onClick={this.handleClick}
                                                >
                                                    <span className="icon-search headerIcon" />
                                                </IconButton>
                                            </div>
                                            <Menu
                                                id="simple-menu"
                                                anchorEl={anchorEl}
                                                open={Boolean(anchorEl)}
                                                onClose={this.handleClose}
                                            >
                                                
                                               
                                                {(user.id_klijent_grupa == 8 || user.id_klijent_grupa == 11) &&
                                                    <div>
                                                        <InputLabel className="filter-label">{i18n.t('Local governments')}:</InputLabel>
                                                        <Select
                                                            isMulti
                                                            closeMenuOnSelect={false}
                                                            isClearable={false}
                                                            value={this.state.id_katastarska_opstina}
                                                            components={{
                                                                IndicatorsContainer: IndicatorsContainer
                                                            }}
                                                            maxMenuHeight={150}
                                                            onChange={this.handleChange('id_katastarska_opstina')}
                                                            options={this.state.localGoverments}
                                                            className="multi-select-in-filter-dashboards"
                                                            classNamePrefix="select"
                                                            placeholder={i18n.t('Select')}
                                                        />
                                                    </div>
                                                }
                                                <div style={{ padding: '30px' }}>
                                                    <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleOk}>{i18n.t('Ok')}</Button>
                                                    <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleClose}>{i18n.t('Cancel')}</Button>
                                                    <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleClear}>{i18n.t('Clear')}</Button>

                                                </div>
                                            </Menu>
                                        </div>
                                </div>
                            </Card>
                        }
                        {user.id_klijent_grupa == 8 || user.id_klijent_grupa == 12 &&
                            <Card className="dashboard-cards-full" raised={true}>
                                <Typography className="dashboard-title">{i18n.t('Farmers by zones')}:</Typography>
                                <Bar data={zonesData}
                                    height={80} />
                            </Card>
                        }
                        {(user.id_klijent_grupa == 8 || user.id_klijent_grupa == 9 || user.id_klijent_grupa == 12) &&
                            <Card className="dashboard-cards-full" raised={true}>
                                <Typography className="dashboard-title">{i18n.t('Farmers by states')}:</Typography>
                                <Bar data={statesData}
                                    height={80} />
                            </Card>
                        }
                        {(user.id_klijent_grupa == 8 || user.id_klijent_grupa == 11 || user.id_klijent_grupa == 12) &&
                            <Card className="dashboard-cards-full" raised={true} >
                                <Typography className="dashboard-title">{i18n.t('Farmers by local governments')}:</Typography>
                                <Bar data={localGovData}
                                    height={80} />
                            </Card>
                        }
                         {(user.id_klijent_grupa == 8 || user.id_klijent_grupa == 9 || user.id_klijent_grupa == 12) &&
                            <Card className="dashboard-cards-full" raised={true}>
                                <Typography className="dashboard-title">{i18n.t('Black list by states')}:</Typography>
                                <Bar data={blackListData}
                                    height={80} />
                            </Card>
                        }
                        {global ?
                            null :
                            <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} />
                        }
                    </div>
                }
            </div>
        )
    }


    statesColor = randomColor({
        count: 50
    })


    localGovColor = randomColor({
        count: 50
    })

    zonesColor = randomColor({
        count: 10
    })

    confStates = {
        labels: [],
        datasets: [{
            data: [],
            label: i18n.t('Farmers by states'),
            backgroundColor: this.statesColor,
            borderColor: this.statesColor,
            borderWidth: 1,
            hoverBackgroundColor: this.statesColor,
            hoverBorderColor: this.statesColor,
        }]
    };

    confZones = {
        labels: [],
        datasets: [{
            data: [],
            label: i18n.t('Farmers by zones'),
            backgroundColor: this.zonesColor,
            borderColor: this.zonesColor,
            borderWidth: 1,
            hoverBackgroundColor: this.zonesColor,
            hoverBorderColor: this.zonesColor,
        }]
    };

    confLocalGov = {
        labels: [],
        datasets: [{
            data: [],
            label: i18n.t('Farmers by local governments'),
            backgroundColor: this.localGovColor,
            borderColor: this.localGovColor,
            borderWidth: 1,
            hoverBackgroundColor: this.localGovColor,
            hoverBorderColor: this.localGovColor,
        }]
    };

    blackListStates = {
        labels: [],
        datasets: [{
            data: [],
            label: i18n.t('Black list by branch'),
            backgroundColor: this.statesColor,
            borderColor: this.statesColor,
            borderWidth: 1,
            hoverBackgroundColor: this.statesColor,
            hoverBorderColor: this.statesColor,
        }]
    };

    options = {
        legend: {
            position: 'left',
            labels: {
                boxWidth: 10,
                fontSize: 12
            }
        },
        tooltips: {
            mode: 'label',
            callbacks: {

                afterLabel: function (tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex].data1[tooltipItem.index] + '%';
                },

            },
        }
    };

};

function mapStateToProps(state) {
    return {
        farmersItems: state.farmRegistarReducer.farmersItems,
        states: state.administrationReducer.states,
        localGoverments: state.farmRegistarReducer.localGoverments,
        zones: state.administrationReducer.zones,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getStates: () => dispatch(getStates()),
        getLocalGov: () => dispatch(getLocalGov()),
        getFarmerDashboard: (filters, id_region) => dispatch(getFarmerDashboard(filters, id_region)),
        getZones: () => dispatch(getZones()),
        getFarmersCount: () => dispatch(getFarmersCount())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FarmDashboard);

const styles = {
    card: {
        minWidth: 275,
        padding: '10px',
        margin: '10px',
    },
    cardMin: {
        width: '100%',
        padding: '10px',
        margin: '10px',
        position: 'relative',
        float: 'left'
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
};