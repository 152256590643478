import React from 'react';
import i18n from '../../i18n/i18n';
import axios from '../../utils/AxiosWrapper';
import styles from './MinistarstvoLandingNew.module.css';
import { MoonLoader } from 'react-spinners';

export default class MinistartstvoLandingNew extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }
    componentDidMount() {
        this.getUser()
    }

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        this.setState({
            user: userObject
        })
        this.getSeason()

        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    getSeason() {
        axios.get('api/season/activity_season')
            .then(res => {
                let choosenSeason = res.data.data[0].naziv;
                let seasonId = res.data.data[0].id_default_sezona;
                this.setState({
                    choosenSeason: choosenSeason
                }, () => {
                    axios.get('api/ministarstvo/region_zakupci')
                        .then(
                            res => {
                                let count = res.data.data[0].count
                                this.setState({
                                    zakupciCount: count.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                                }, () => {
                                    axios.get('api/ministarstvo/zakupci_table', { params: { seasonId } })
                                        .then(res => {
                                            let count = res.data.data[0].count
                                            this.setState({
                                                tableZakupci: count.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                                            }, () => {
                                                axios.get('api/ministarstvo/povrsina_table_zakupci', { params: { seasonId } })
                                                    .then(res => {
                                                        let dataAll = res.data.data
                                                        if (dataAll.length > 0) {
                                                            let data = dataAll.filter(item => {
                                                                return item.opstina_id !== null
                                                            })
                                                            if (this.state.user.id_region === 20) {
                                                                let opstinaBrod = data.filter(item => {
                                                                    return item.opstina_naziv == 'Brod'
                                                                })
                                                                let areaBrod = opstinaBrod.map(field => Number(field.povrsina))
                                                                let totalAreaBrod = areaBrod.reduce((a, x) => a + x)

                                                                let opstinaSrbac = data.filter(item => {
                                                                    return item.opstina_naziv == 'Srbac'
                                                                })
                                                                let areaSrbac = opstinaSrbac.map(field => Number(field.povrsina))
                                                                let totalAreaSrbac = areaSrbac.reduce((a, x) => a + x)
                                                                let opstinaTrebinje = data.filter(item => {
                                                                    return item.opstina_naziv == 'Trebinje'
                                                                })
                                                                let areaTrebinje = opstinaTrebinje.map(field => Number(field.povrsina))
                                                                let totalAreaTrebinje = areaTrebinje.reduce((a, x) => a + x)
                                                                let opstinaPrijedor = data.filter(item => {
                                                                    return item.opstina_naziv == 'Prijedor'
                                                                })
                                                                let areaPrijedor = opstinaPrijedor.map(field => Number(field.povrsina))
                                                                let totalAreaPrijedor = areaPrijedor.reduce((a, x) => a + x)

                                                                let totalArea = totalAreaBrod + totalAreaSrbac + totalAreaPrijedor + totalAreaTrebinje;
                                                                this.setState({ totalArea: totalArea })

                                                            } else {
                                                                let area = data.map(field => Number(field.povrsina))
                                                                let totalArea = area.reduce((a, x) => a + x)
                                                                this.setState({ totalArea: totalArea })
                                                            }
                                                            let opstine = data.map(field => field.opstina_id)
                                                            let uniqueOpstine = [...new Set(opstine)]
                                                            let resultat = uniqueOpstine.filter(item => {
                                                                return item !== null
                                                            }
                                                            )
                                                            let uniqueWithArea = resultat.map(item => {
                                                                return { opstina_id: item, povrsine: [], opstina_naziv: [], id_klijent: [] }
                                                            })
                                                            data.forEach((item, i) => {
                                                                let opstina = item.opstina_id;
                                                                let id = uniqueWithArea.findIndex(el => el.opstina_id === opstina)
                                                                uniqueWithArea[id].povrsine.push(Number(item.povrsina))
                                                                uniqueWithArea[id].opstina_naziv.push(item.opstina_naziv)
                                                                uniqueWithArea[id].id_klijent.push(item.klijent_vlasnik)
                                                            })
                                                            this.setState({
                                                                uniqueWithAreaByOpstina: uniqueWithArea

                                                            }, () => {
                                                                axios.get('api/ministarstvo/zakupci_wo', { params: { seasonId } })
                                                                    .then(res => {
                                                                        let count = res.data.data[0].count
                                                                        this.setState({
                                                                            woCount: count.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                                                                            loading: false
                                                                        })
                                                                    })
                                                                    .catch(err => console.log(err))
                                                            })
                                                        } else {
                                                            this.setState({
                                                                totalArea: 0,
                                                                woCount: 0,
                                                                uniqueOpstine: null,
                                                                uniqueWithAreaByOpstina: 0,
                                                                loading: false
                                                            })
                                                        }
                                                    })
                                            })
                                        })
                                })
                            }
                        )
                })
            })
            .catch(err => console.log(err))
    }
    isOdd = (num) => { return num % 2; };

    render() {
        let byOpstina = []
        if (this.state.uniqueWithAreaByOpstina) {
            byOpstina = this.state.uniqueWithAreaByOpstina
        }
        let naziviOpstina = []
        if (byOpstina.length > 0) {
            let naziv = byOpstina.map(item => item.opstina_naziv)
            naziviOpstina = naziv.map(
                item => [...new Set(item)]
            )
        }

        let povrsinaPoOpstini = []
        if (byOpstina.length > 0) {
            let povrsinePO = byOpstina.map(item => item.povrsine)
            povrsinePO.forEach((field, i) => {
                let totalArea = field.reduce((a, x) => a + x);
                if (totalArea) {
                    povrsinaPoOpstini.push(Number(totalArea.toFixed(2)));
                }
            })
        }

        let zakupciPoOpstini = []
        if (byOpstina.length > 0) {
            let zakupciPO = byOpstina.map(item => item.id_klijent)

            //treba uniquw
            zakupciPO.forEach((field, i) => {
                let uniqZakupci = [...new Set(field)]
                let totalZakupci = uniqZakupci.length;
                if (totalZakupci) {
                    zakupciPoOpstini.push(Number(totalZakupci));
                }
            })
        }

        let ukupnoZakupaca = 0;
        if (zakupciPoOpstini.length > 0) {
            ukupnoZakupaca = zakupciPoOpstini.reduce((a, x) => a + x);
        }

        return (
            <div className={styles.container}>
                {this.state.loading === true && <div style={{ marginLeft: '40%', paddingTop: '200px', paddingBottom: '400px' }}> <MoonLoader loading={true} size={100} /></div>}
                {this.state.loading === false && <div>
                    <div className={styles.title}>{i18n.t('Overview').toUpperCase()} - {this.state.choosenSeason.toUpperCase()}</div>
                    <div className={styles.featuredRow}>
                        <div className={styles.featuredItem}>
                            <div className={styles.feturedTop}>
                                <span className={styles.featuredTitle}>{i18n.t('Land tenants')}</span>
                                <img style={{ width: '30px', height: '30px', padding: '5px', boxShadow: '0px 0px 15px -10px rgba(0,0,0,0.75)' }} src='./za.svg' />
                            </div>
                            <div className={styles.featuredContainer}>
                                <span className={styles.featuredNumber}>{this.state.zakupciCount}</span>
                            </div>
                            <span className={styles.featuredSub}>{i18n.t('Total count of land tenants in project')}</span>
                        </div>
                        <div className={styles.featuredItem}>
                            <div className={styles.feturedTop}>
                                <span className={styles.featuredTitle}>{i18n.t('Land area')}</span>
                                <img style={{ width: '30px', height: '30px', padding: '5px', boxShadow: '0px 0px 15px -10px rgba(0,0,0,0.75)' }} src='./area.svg' />
                            </div>
                            <div className={styles.featuredContainer}>
                                <span className={styles.featuredNumber}>{this.state.totalArea.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} ha</span>
                            </div>
                            <span className={styles.featuredSub}>{i18n.t('Total area of all tables assigned to the land tenants')}</span>
                        </div>
                        <div className={styles.featuredItem}>
                            <div className={styles.feturedTop}>
                                <span className={styles.featuredTitle}>{i18n.t('Tables')}</span>
                                <img style={{ width: '30px', height: '30px', padding: '5px', boxShadow: '0px 0px 15px -10px rgba(0,0,0,0.75)' }} src='./fields1.svg' />
                            </div>
                            <div className={styles.featuredContainer}>
                                <span className={styles.featuredNumber}>{this.state.tableZakupci}</span>
                            </div>
                            <span className={styles.featuredSub}>{i18n.t('Total number of tables assigned to the land tenants')}</span>
                        </div>
                        <div className={styles.featuredItem}>
                            <div className={styles.feturedTop}>
                                <span className={styles.featuredTitle}>{i18n.t('Activities')}</span>
                                <img style={{ width: '30px', height: '30px', padding: '5px', boxShadow: '0px 0px 15px -10px rgba(0,0,0,0.75)' }} src='./wo.svg' />
                            </div>
                            <div className={styles.featuredContainer}>
                                <span className={styles.featuredNumber}>{this.state.woCount}</span>
                            </div>
                            <span className={styles.featuredSub}>{i18n.t('Total number of activities from the land tenants')}</span>
                        </div>
                    </div>
                    <div className={styles.featuredRowBottom}>
                        <div className={styles.title2}>{i18n.t('Lease data based on at least one assigned board').toUpperCase()} - {this.state.choosenSeason.toUpperCase()}</div>
                        <div className={styles.tableCenter}>

                            {byOpstina.length > 0 &&
                                <div className={styles.content_container}>
                                    <div className={styles.content_border} />
                                    <div className={styles.table_div}>
                                        <table className={styles.table}>
                                            <tr className={styles.table_row}>
                                                <th className={styles.table_header}>{i18n.t('Municipality').toUpperCase()}</th>
                                                <th className={styles.table_header}>{i18n.t('Area ha').toUpperCase()}</th>
                                                <th className={styles.table_header}>{i18n.t('Number of tenants').toUpperCase()}</th>
                                            </tr>
                                            {byOpstina.map((item, i) => {
                                                let valueName = naziviOpstina[i]
                                                let povrsinaOpstina = povrsinaPoOpstini[i]
                                                let zakupciOpstina = zakupciPoOpstini[i]
                                                return <tr className={this.isOdd(i) === 0 ? styles.table_row_odd : styles.table_row}>
                                                    <td className={styles.table_row_text}>{valueName}</td>
                                                    <td className={styles.table_row_text}>{povrsinaOpstina.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                    <td className={styles.table_row_text}>{zakupciOpstina}</td>
                                                </tr>
                                            })}
                                        </table>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }
}
