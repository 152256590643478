import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import {
    TextField, Button, Paper, Grid, IconButton,
    Dialog, DialogAction, DialogContent, DialogTitle
} from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    getZones, addZonalOffice, getStates,
    setInitialState
} from '../../actions/AdministrationActions';
import { getLocalGov } from '../../actions/FarmRegistarActions';


export class AddZonalOffice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            zonalObj: {}
        };
    }

    componentDidMount() {
        this.props.getZones();
        this.props.getStates();
        this.props.getLocalGov();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.zonesPostingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('zonesPostingFailed');
        }
        this.setState({
            zones: nextProps.zones,
            zonesFetching: nextProps.zonesFetching,
            zonesFetchingFailed: nextProps.zonesFetchingFailed,

        })
    }


    handleChangeValue = name => event => {
        const { zonalObj } = this.state;
        let obj = Object.assign({}, zonalObj);
        obj[name] = event.target.value;
        this.setState({ zonalObj: obj });
    };

    addZonalOffice() {
        const { zonalObj } = this.state;
        if (zonalObj.kontakt_mejl &&
            zonalObj.id_katastarska_opstina &&
            zonalObj.id_opstina &&
            zonalObj.ime &&
            zonalObj.prezime &&
            zonalObj.username &&
            zonalObj.password) {
            this.props.addZonalOffice(
                zonalObj
            )

        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        }
    }


    render() {
        const { localGoverments, localGovermentsFetching, states, statesFetching, zones, zonesFetching, add, handleClose } = this.props;
        const { zonalObj } = this.state;
        let zonesArray = [];
        if (zones && !zonesFetching) {
            zonesArray = zones.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let statesArray = [];
        if (states && !statesFetching) {
            statesArray = states.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let localGovermentsArray = [];
        if (localGoverments && !localGovermentsFetching) {
            localGovermentsArray = localGoverments.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        return (
            <div style={{ padding: 20 }}>
                <Dialog
                    open={add}
                    onClose={handleClose}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('Add zonal officer')}
                        <IconButton onClick={handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-zonal">
                            <Grid container justify="space-between" alignContent="space-between" spacing={16} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                                <TextField
                                    label={i18n.t('First name')}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={zonalObj.ime}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('ime')}
                                />
                                <TextField
                                    label={i18n.t('Last name')}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={zonalObj.prezime}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('prezime')}
                                />
                                <TextField
                                    label={i18n.t('Username')}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={zonalObj.username}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('username')}
                                />
                                <TextField
                                    label={i18n.t('Password')}
                                    type="password"
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('password')}
                                />
                                <TextField
                                    select
                                    label={i18n.t('Zone')}
                                    value={zonalObj.id_zona}
                                    required
                                    onChange={this.handleChangeValue('id_zona')}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}

                                >

                                    <option value='' />
                                    {zonesArray}
                                </TextField>
                                <TextField
                                    select
                                    label={i18n.t('State')}
                                    value={zonalObj.id_opstina}
                                    required
                                    onChange={this.handleChangeValue('id_opstina')}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}

                                >

                                    <option value='' />
                                    {statesArray}
                                </TextField>

                                <TextField
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label={i18n.t('Local government')}
                                    value={zonalObj.id_katastarska_opstina}
                                    required
                                    onChange={this.handleChangeValue('id_katastarska_opstina')}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    margin="normal"
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}

                                >

                                    <option value='' />
                                    {localGovermentsArray}
                                </TextField>

                                <TextField
                                    label={i18n.t('Address')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={zonalObj.ulica_i_broj}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('ulica_i_broj')}
                                />

                                <TextField
                                    label={i18n.t('Zip code')}
                                    value={zonalObj.postanski_broj}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('postanski_broj')}
                                />

                                <TextField
                                    label={i18n.t('Email')}
                                    value={zonalObj.kontakt_mejl}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('kontakt_mejl')}
                                />

                                <TextField
                                    label={i18n.t('Phone')}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={zonalObj.kontakt_telefon}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('kontakt_telefon')}
                                />
                                <Grid item xs={12}>
                                </Grid>
                                <Grid item xs>
                                    <Button
                                        style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white' }}
                                        onClick={() => this.addZonalOffice()}
                                    >
                                        {i18n.t('Add')}
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        zones: state.administrationReducer.zones,
        zonesFetching: state.administrationReducer.zonesFetching,
        zonalObj: state.administrationReducer.zonalObj,
        zonesFetchingFailed: state.administrationReducer.zonesFetchingFailed,
        states: state.administrationReducer.states,
        statesFetching: state.administrationReducer.statesFetching,
        statesFetchingFailed: state.administrationReducer.statesFetchingFailed,
        zonesPostingSuccess: state.administrationReducer.zonesPostingSuccess,
        zonesPosting: state.administrationReducer.zonesPosting,
        zonesPostingFailed: state.administrationReducer.zonesPostingFailed,
        localGoverments: state.farmRegistarReducer.localGoverments,
        localGovermentsFetching: state.farmRegistarReducer.localGovermentsFetching,
        localGovermentsFetchingFailed: state.farmRegistarReducer.localGovermentsFetchingFailed,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getZones: () => dispatch(getZones()),
        getStates: () => dispatch(getStates()),
        getLocalGov: (id_state) => getLocalGov(id_state),
        setInitialState: (component) => dispatch(setInitialState(component)),
        addZonalOffice: (zonalObj) => dispatch(addZonalOffice(zonalObj)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddZonalOffice)