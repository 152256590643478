import axios from '../../utils/AxiosWrapper';
export const Type = {

    //ADD
    ADD_FORM_MODULE_CALL: 'ADD_FORM_MODULE_CALL',
    ADD_FORM_MODULE_SUCCESS: 'ADD_FORM_MODULE_SUCCESS',
    ADD_FORM_MODULE_FAILED: 'ADD_FORM_MODULE_FAILED',

    //GET
    GET_FORM_MODULE_READ_CALL: 'GET_FORM_MODULE_READ_CALL',
    GET_FORM_MODULE_READ_SUCCESS: 'GET_FORM_MODULE_READ_SUCCESS',
    GET_FORM_MODULE_READ_FAILED: 'GET_FORM_MODULE_READ_FAILED',

    //DELETE
    DELETE_FORM_MODULE_CALL: 'DELETE_FORM_MODULE_CALL',
    DELETE_FORM_MODULE_SUCCESS: 'DELETE_FORM_MODULE_SUCCESS',
    DELETE_FORM_MODULE_FAILED: 'DELETE_FORM_MODULE_FAILED',

    //FORM
    GET_FORM_TYPE_CALL: 'GET_FORM_TYPE_CALL',
    GET_FORM_TYPE_SUCCESS: 'GET_FORM_TYPE_SUCCESS',
    GET_FORM_TYPE_FAILED: 'GET_FORM_TYPE_FAILED',

    //MODULE
    GET_MODULE_TYPE_CALL: 'GET_MODULE_CALL',
    GET_MODULE_TYPE_SUCCESS: 'GET_MODULE_SUCCESS',
    GET_MODULE_TYPE_FAILED: 'GET_MODULE_FAILED',

    //SET
    SET_FORM_MODULE_ROW_IN_REDUCER: 'SET_FORM_MODULE_ROW_IN_REDUCER',
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',
};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function getFormType() {
    return (dispatch) => {
        dispatch({
            type: Type.GET_FORM_TYPE_CALL
        });

        axios.get('api/form_modul/filter_form')
            .then(function (response) {
                dispatch({
                    type: Type.GET_FORM_TYPE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_FORM_TYPE_FAILED
                });
            });
    }
}

export function getModuleType() {
    return (dispatch) => {
        dispatch({
            type: Type.GET_MODULE_TYPE_CALL
        });

        axios.get('api/klijent_grupa_modul/filter_modul')
            .then(function (response) {
                dispatch({
                    type: Type.GET_MODULE_TYPE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MODULE_TYPE_FAILED
                });
            });
    }
}


export function getFormModules(state, instance) {
    return (dispatch) => {
        dispatch({
            type: Type.GET_FORM_MODULE_READ_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/form_modul/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_FORM_MODULE_READ_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_FORM_MODULE_READ_FAILED
                });
            });
    }
}

export function deleteFormModule(selection) {
    return (dispatch) => {
        dispatch({
            type: Type.DELETE_FORM_MODULE_CALL,
        });

        axios.post(`api/form_modul/delete`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_FORM_MODULE_SUCCESS,
                    data: response.data,
                    forDelete: selection
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_FORM_MODULE_FAILED
                });
            });
    }
}

export function addFormModule(formModuleObj) {
    return (dispatch) => {
        dispatch({
            type: Type.ADD_FORM_MODULE_CALL
        });

        axios.post('api/form_modul/create', formModuleObj)
            .then(function (response) {
                dispatch({
                    type: Type.ADD_FORM_MODULE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_FORM_MODULE_FAILED
                });
            });
    }
}


export function setRowInReducer(rowInfo) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_FORM_MODULE_ROW_IN_REDUCER,
            rowInfo: rowInfo
        })
    }
}