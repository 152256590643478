import React from 'react';
import { connect } from 'react-redux';
import {
    Button, IconButton, Menu, MenuItem, Typography
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { Doughnut, Bar, Pie, Radar } from 'react-chartjs-2';
import { getIncentivesDashboard } from '../../../actions/financial/IncentiveActions';
import randomColor from 'randomcolor';
import immutable from 'seamless-immutable';
import DashboardDrawer from '../../../utils/DashboardDrawer';
import Select, { components } from 'react-select';
import {
    getLocalGov,
} from '../../../actions/FarmRegistarActions';
import { getStates, getZones } from '../../../actions/AdministrationActions';
import InputLabel from '@material-ui/core/InputLabel';
import 'react-toastify/dist/ReactToastify.css';
import i18n from '../../../i18n/i18n';
import { getMaterialDropdown } from '../../../actions/resources/MaterialActions';
import { getUser } from '../../../utils/CommonFunctions';

const IndicatorsContainer = (props) => {
    return (
        <div style={{ background: '#61a50e' }}>
            <components.IndicatorsContainer {...props} />
        </div>
    );
};
export class IncentivesDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            id_zona: [],
            id_opstina: [],
            id_katastarska_opstina: [],
            id_materijal: [],
        };
    }

    componentDidMount() {
        this.props.getIncentivesDashboard({});
        if (this.props.states.length === 0) {
            this.props.getStates();
        }
        if (this.props.localGoverments.length === 0) {
            this.props.getLocalGov();
        }
        if (this.props.materialDropdown.length === 0) {
            this.props.getMaterialDropdown();
        }
        if (this.props.zones.length === 0) {
            this.props.getZones();
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            zones: immutable.asMutable(nextProps.zones.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            states: immutable.asMutable(nextProps.states.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            localGoverments: immutable.asMutable(nextProps.localGoverments.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            materialDropdown: immutable.asMutable(nextProps.materialDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),

        })
    }

    onPieClick = () => {
        this.props.history.push('/incentive_dashboard');
    }

    onTableClick = () => {
        this.props.history.push('/incentive');
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleClear = () => {
        this.setState({
            id_opstina: [],
            id_katastarska_opstina: [],
            id_materijal: [],
            id_zona: [],
        }, function () {
            this.props.getIncentivesDashboard({});
        });
    };


    handleOk = () => {
        let filters = {};
        filters['id_zona'] = this.state.id_zona.map((item) => {
            return item.value;
        })
        filters['id_opstina'] = this.state.id_opstina.map((item) => {
            return item.value;
        })
        filters['id_katastarska_opstina'] = this.state.id_katastarska_opstina.map((item) => {
            return item.value;
        });
        filters['id_materijal'] = this.state.id_materijal.map((item) => {
            return item.value;
        });
        this.props.getIncentivesDashboard(filters);
    }

    handleChange = name => selectedOption => {
        this.setState({
            [name]: selectedOption
        })
    }

    render() {
        const { anchorEl } = this.state;
        let zonesData = this.zones;
        let statesData = this.states;
        let localGovData = this.localGov;
        let materialData = this.material;
        let user = getUser();
        if (this.props.incentivesItems && this.props.incentivesItems.zones) {
            zonesData.labels = this.props.incentivesItems.zones.labels.asMutable();
            zonesData.datasets[0].data = this.props.incentivesItems.zones.count.asMutable();
            zonesData.datasets[0].data1 = this.props.incentivesItems.zones.percent.asMutable();
        }
        if (this.props.incentivesItems && this.props.incentivesItems.states) {
            statesData.labels = this.props.incentivesItems.states.labels.asMutable();
            statesData.datasets[0].data = this.props.incentivesItems.states.count.asMutable();
            statesData.datasets[0].data1 = this.props.incentivesItems.states.percent.asMutable();
        }
        if (this.props.incentivesItems && this.props.incentivesItems.LocalGov) {
            localGovData.labels = this.props.incentivesItems.LocalGov.labels.asMutable();
            localGovData.datasets[0].data = this.props.incentivesItems.LocalGov.count.asMutable();
            localGovData.datasets[0].data1 = this.props.incentivesItems.LocalGov.percent.asMutable();
        }
        if (this.props.incentivesItems && this.props.incentivesItems.material) {
            materialData.labels = this.props.incentivesItems.material.labels.asMutable();
            materialData.datasets[0].data = this.props.incentivesItems.material.quantity.asMutable();
            materialData.datasets[0].data1 = this.props.incentivesItems.material.count.asMutable();
        }
        return (
            <div style={{ 'padding': '20px' }}>
                <div>
                    <Card style={styles.card}>

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <h1>{i18n.t('Total number of incentives')} : {this.props.incentivesItems.total} </h1>
                            {!this.props.global &&
                                <div>
                                    <div className="filter-panel-dashboard">
                                        <IconButton
                                            styles={styles.button}
                                            aria-owns={anchorEl ? 'simple-menu' : null}
                                            aria-haspopup="true"
                                            className="action-button-filter"
                                            onClick={this.handleClick}
                                        >
                                            <span className="icon-search headerIcon" />
                                        </IconButton>
                                    </div>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={this.handleClose}
                                    >
                                        {user.id_klijent_grupa == 8 &&
                                            <div>
                                                <InputLabel className="filter-label">{i18n.t('Zones')}:</InputLabel>
                                                <Select
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    isClearable={false}
                                                    value={this.state.id_zona}
                                                    components={{
                                                        IndicatorsContainer: IndicatorsContainer
                                                    }}
                                                    maxMenuHeight={150}
                                                    menuPlacement='auto'
                                                    onChange={this.handleChange('id_zona')}
                                                    options={this.state.zones}
                                                    className="multi-select-in-filter-dashboards"
                                                    classNamePrefix="select"
                                                    placeholder={i18n.t('Select')}
                                                />
                                            </div>
                                        }
                                        {(user.id_klijent_grupa == 8 || user.id_klijent_grupa == 9) &&
                                            <div>
                                                <InputLabel className="filter-label">{i18n.t('States')}:</InputLabel>
                                                <Select
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    isClearable={false}
                                                    value={this.state.id_opstina}
                                                    components={{
                                                        IndicatorsContainer: IndicatorsContainer
                                                    }}
                                                    maxMenuHeight={150}
                                                    menuPlacement='auto'
                                                    onChange={this.handleChange('id_opstina')}
                                                    options={this.state.states}
                                                    className="multi-select-in-filter-dashboards"
                                                    classNamePrefix="select"
                                                    placeholder={i18n.t('Select')}
                                                />
                                            </div>
                                        }
                                        {(user.id_klijent_grupa == 8 || user.id_klijent_grupa == 11) &&
                                            <div>
                                                <InputLabel className="filter-label">{i18n.t('Local governments')}:</InputLabel>
                                                <Select
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    isClearable={false}
                                                    value={this.state.id_katastarska_opstina}
                                                    components={{
                                                        IndicatorsContainer: IndicatorsContainer
                                                    }}
                                                    maxMenuHeight={150}
                                                    onChange={this.handleChange('id_katastarska_opstina')}
                                                    options={this.state.localGoverments}
                                                    className="multi-select-in-filter-dashboards"
                                                    classNamePrefix="select"
                                                    placeholder={i18n.t('Select')}
                                                />
                                            </div>
                                        }
                                        <InputLabel className="filter-label">{i18n.t('Material')}:</InputLabel>
                                        <Select
                                            isMulti
                                            closeMenuOnSelect={false}
                                            isClearable={false}
                                            value={this.state.id_materijal}
                                            components={{
                                                IndicatorsContainer: IndicatorsContainer
                                            }}
                                            maxMenuHeight={150}
                                            onChange={this.handleChange('id_materijal')}
                                            options={this.state.materialDropdown}
                                            className="multi-select-in-filter-dashboards"
                                            classNamePrefix="select"
                                            placeholder={i18n.t('Select')}
                                        />
                                        <div style={{ padding: '30px' }}>
                                            <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleOk}>{i18n.t('Ok')}</Button>
                                            <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleClose}>{i18n.t('Cancel')}</Button>
                                            <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleClear}>{i18n.t('Clear')}</Button>

                                        </div>
                                    </Menu>
                                </div>
                            }
                        </div>
                    </Card>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {user.id_klijent_grupa == 8 &&
                            <Card className="dashboard-cards" raised={true}>
                                <Typography className="dashboard-title">{i18n.t('Incentives by Zones')}:</Typography>
                                <Pie data={zonesData} options={this.options} />
                            </Card>

                        }
                        {(user.id_klijent_grupa == 8 || user.id_klijent_grupa == 9) &&

                            <Card className="dashboard-cards" raised={true}>
                                <Typography className="dashboard-title">{i18n.t('Incentives by States')}:</Typography>
                                <Pie data={statesData} options={this.options} />
                            </Card>
                        }
                    </div >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {(user.id_klijent_grupa == 8 || user.id_klijent_grupa == 11) &&
                            <Card className="dashboard-cards" raised={true}>
                                <Typography className="dashboard-title">{i18n.t('Incentives by Local governments')}:</Typography>
                                <Pie data={localGovData} options={this.options} />
                            </Card>
                        }
                    </div >
                    <div style={{ display: 'flex' }}>
                        <Card className="dashboard-cards-full" raised={true}>
                            <Typography className="dashboard-title">{i18n.t('Material quantity in incentives')}:</Typography>
                            <Radar data={materialData} options={this.materialOptions} />
                        </Card>
                    </div>
                    {this.props.global ?
                        null :
                        <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} />
                    }
                </div >

            </div>
        )
    }
    materialColor = randomColor({
        count: 30
    })

    statesColor = randomColor({
        count: 50
    })

    localGovColor = randomColor({
        count: 50
    })

    zonesColor = randomColor({
        count: 10
    })

    zones = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.zonesColor,
            hoverBackgroundColor: this.zonesColor
        }]
    };

    states = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.statesColor,
            hoverBackgroundColor: this.statesColor
        }]
    };
    localGov = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.localGovColor,
            hoverBackgroundColor: this.localGovColor
        }]
    };

    material = {
        labels: [],
        datasets: [{
            data: [],
            label: i18n.t('Materials and quantity'),
            backgroundColor: this.materialColor,
            borderColor: this.materialColor,
            pointBackgroundColor: this.materialColor,
            pointBorderColor: this.materialColor,
            pointHoverBackgroundColor: this.materialColor,
            pointHoverBorderColor: this.materialColor,
        },
        ]
    };

    options = {
        legend: {
            position: 'left',
            labels: {
                boxWidth: 10,
                fontSize: 12
            }
        },
        tooltips: {
            mode: 'label',
            callbacks: {

                afterLabel: function (tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex].data1[tooltipItem.index] + '%';
                },

            },
        }
    };
    materialOptions = {
        tooltips: {
            mode: 'label',
            callbacks: {

                label: function (tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + 'kg';
                },

            },
        }
    };
};

function mapStateToProps(state) {
    return {
        incentivesItems: state.incentiveReducer.incentivesItems,
        states: state.administrationReducer.states,
        localGoverments: state.farmRegistarReducer.localGoverments,
        materialDropdown: state.materialReducer.materialDropdown,
        zones: state.administrationReducer.zones,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getIncentivesDashboard: (filters) => dispatch(getIncentivesDashboard(filters)),
        getStates: () => dispatch(getStates()),
        getLocalGov: () => dispatch(getLocalGov()),
        getMaterialDropdown: () => dispatch(getMaterialDropdown()),
        getZones: () => dispatch(getZones())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IncentivesDashboard);

const styles = {
    card: {
        padding: '10px',
        margin: '10px',
    },
    button: {
        height: 40
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
};