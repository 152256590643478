/**
 * Created by pajicv on 7/1/18.
 */

import React from 'react';

import {connect} from 'react-redux';

import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { getStates } from '../../../actions/AdministrationActions';
import { getLocalGovByState } from '../../../actions/FarmRegistarActions';

import ZoomToAdministrative from './ZoomToAdministrative';
import ZoomToField from './ZoomToField';
import ZoomToCoordinates from './ZoomToCoordinates';

import { ALL_STATES_ID, ALL_LOCAL_GOVS_ID } from '../../../utils/constants';

const styles = theme => ({
    dialog: {
        height: 600
    },
    button: {
        height: 40,
        padding: 0
    },
});

class ZoomToMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedStateId: ALL_STATES_ID,
            selectedLocalGovId: ALL_LOCAL_GOVS_ID,
            selectedLayers: [],
            selectedFields: [],
            field: '',
            selectedTab: 'administrative',
            latitude: null,
            longitude: null
        };
    }

    componentDidMount = () => {
        this.props.getStates();
    };

    handleOk = () => {
        const {selectedStateId, selectedLocalGovId, selectedFields, selectedTab, latitude, longitude} = this.state;
        const { zoomToBounds, zoomToCoordinates } = this.props;
        if (selectedTab === 'coordinates') {
            zoomToCoordinates(latitude, longitude);
        } else {
            zoomToBounds(selectedStateId, selectedLocalGovId, selectedFields);
        }
    };

    onStateSelected = selectedStateId => {
        this.setState({ selectedStateId });

        if(Number(selectedStateId) !== ALL_STATES_ID) {
            this.props.getLocalGov(selectedStateId);
        }
    };

    onLocalGovSelected = selectedLocalGovId => {
        this.setState({ selectedLocalGovId });
    };

    onFieldChange = value => {
        this.setState({field: value});
    };

    handleLayerChange = selectedLayers => this.setState({ selectedLayers });

    handleFieldChange = selectedFields => this.setState({ selectedFields });

    handleLatitudeChange = latitude => this.setState({ latitude });

    handleLongitudeChange = longitude => this.setState({ longitude });

    handleClose = () => {
        const { onZoomToMenuClose } = this.props;
        onZoomToMenuClose();
    };

    handleChangeTab = (event, selectedTab) => this.setState({ selectedTab });

    render() {
        const { selectedStateId, selectedLocalGovId, selectedLayers, selectedFields, selectedTab, latitude, longitude } = this.state;

        const { classes, open, states, localGovernments, onZoomToMenuClose } = this.props;

        return (
            <div>
        <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
            >
            <DialogTitle id="form-dialog-title">
                <AppBar position="static">
                    <Tabs value={selectedTab} onChange={this.handleChangeTab}>
                        <Tab value="administrative" label="Administrative" />
                        <Tab value="fields" label="Fields" />
                        <Tab value="coordinates" label="Coordinates" />
                    </Tabs>
                </AppBar>
            </DialogTitle>
            <DialogContent>
                { selectedTab === 'administrative' &&
                    <ZoomToAdministrative
                        states={states}
                        selectedStateId={selectedStateId}
                        onStateSelected={this.onStateSelected}
                        localGovernments={localGovernments}
                        selectedLocalGovId={selectedLocalGovId}
                        onLocalGovSelected={this.onLocalGovSelected}
                    />
                }
                { selectedTab === 'fields' &&
                    <ZoomToField
                        selectedLayers={selectedLayers}
                        selectedFields={selectedFields}
                        onFieldChange={this.handleFieldChange}
                        onLayerChange={this.handleLayerChange}
                    />
                }
                { selectedTab === 'coordinates' &&
                    <ZoomToCoordinates
                      latitude={latitude}
                      longitude={longitude}
                      onLatitudeChange={this.handleLatitudeChange}
                      onLongitudeChange={this.handleLongitudeChange}
                    />
                }
        </DialogContent>
        <DialogActions>
            <Button onClick={onZoomToMenuClose} color="primary">
                Cancel
            </Button>
            <Button onClick={this.handleOk} color="primary">
                Ok
            </Button>
        </DialogActions>
    </Dialog>
    </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        states: state.administrationReducer.states,
        statesFetching: state.administrationReducer.statesFetching,
        statesFetchingFailed: state.administrationReducer.statesFetchingFailed,
        localGovernments: state.farmRegistarReducer.localGoverments,
        localGovernmentsFetching: state.farmRegistarReducer.localGovermentsFetching,
        localGovernmentsFetchingFailed: state.farmRegistarReducer.localGovermentsFetchingFailed
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getStates: () => dispatch(getStates()),
        getLocalGov: stateId => dispatch(getLocalGovByState(stateId)),
    }
}


export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(ZoomToMenu));
