import React from 'react';
import { connect } from 'react-redux';
import i18n from '../i18n/i18n';
import {
    Button, DialogActions, IconButton,
    Dialog, DialogContent, DialogTitle
} from '@material-ui/core';

import { getClientProfile } from '../actions/LoginActions';
import Statistics from "../components/reports/Statistics";
import ViewField from "../components/catastral_data/field/ViewField";
import ViewFieldOneFarmer from "../components/catastral_data/field/ViewFieldOneFarmer";
import ViewParcelOneFarmer from '../components/catastral_data/parcel/ViewParcelOneFarmer';
import ViewSubsidies from "../components/financial/subsidies/ViewSubsidies";
import GPSReport from "../components/financial/incentives/GPSReport";
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import { getUser } from '../utils/CommonFunctions';


export class ProfilePreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isStatisticShown: false,
            isFieldShown: false,
            isSubsidiesShown: false,
            isParcelShown: false,
            user: {},
        }
    }

    componentDidMount() {
        if (this.props.profile && this.props.clientId) {
            this.props.getClientProfile(this.props.clientId);
        }
        this.setState({
            user: getUser(),
        })
    }

    showReport = () => {
        this.setState({ isStatisticShown: !this.state.isStatisticShown })
    }

    showField = () => {
        this.setState({ isFieldShown: !this.state.isFieldShown })
    }
    showParcel = () => {
        this.setState({ isParcelShown: !this.state.isParcelShown })
    }
    showSubsidies = () => {
        this.setState({ isSubsidiesShown: !this.state.isSubsidiesShown })
    }
    handleGPSReportOkClick = () => {
        this.setState({ isGPSReportShown: false })
    };

    showGPSReport = () => {
        this.setState({ isGPSReportShown: true });
    };
    render() {
        const { user } = this.state;
        return (
            <div>
                <Dialog
                    open={this.props.profile}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"

                >
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('Profile')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                            <Grid item style={{ display: 'flex', flexDirection: 'row', alignContent: "space-between" }}>
                                <Grid item>
                                    <img src={this.props.clientProfile.signedURL} alt={this.props.clientProfile.naziv} height="120" width="120" style={{ padding: '10px' }} />
                                </Grid>
                            </Grid>

                            <Grid item style={{ display: 'flex', flexDirection: 'column' }}>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('Name surname')}
                                        value={this.props.clientProfile.kontakt_osoba}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                    </TextField>
                                </Grid>
                                {user.id_klijent_grupa !== 14 &&
                                    <Grid item>
                                        <TextField
                                            label={i18n.t('BVN')}
                                            value={this.props.clientProfile.bpg}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            margin="normal"
                                            style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        >
                                        </TextField>
                                    </Grid>
                                }
                            </Grid>
                            <Grid item>
                                <TextField
                                    label={i18n.t('Farmer')}
                                    value={this.props.clientProfile.naziv}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                >
                                </TextField>
                            </Grid>
                            <Grid item>
                                <TextField
                                    label={i18n.t('State')}
                                    value={this.props.clientProfile._opstina}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                >
                                </TextField>
                            </Grid>
                            <Grid item>
                                <TextField
                                    label={i18n.t('Local government')}
                                    value={this.props.clientProfile._katastarska_opstina}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                >
                                </TextField>
                            </Grid>
                            <Grid item>
                                <TextField
                                    label={i18n.t('Current season')}
                                    value={this.props.clientProfile._default_sezona}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                >
                                </TextField>
                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions className="profile-buttons">
                        {this.props.showFarmersInfo &&
                            <div>
                                <Button onClick={this.showReport} style={{ backgroundColor: '#58B4E5', color: 'white', marginRight: '5px', marginLeft: '5px' }}>
                                    {i18n.t('Report')}
                                </Button>
                                {/* <Button onClick={this.showParcel} style={{ backgroundColor: '#58B4E5', color: 'white', marginRight: '5px', marginLeft: '5px' }}>
                                    {i18n.t('Parcel')}
                                </Button> */}
                                <Button onClick={user.id_region === 29 ? this.showParcel : this.showField} style={{ backgroundColor: '#58B4E5', color: 'white', marginRight: '5px', marginLeft: '5px' }}>
                                    {user.id_region === 29 ? i18n.t('Parcels'): i18n.t('Field')}
                                </Button>
                                {/* <Button onClick={this.showSubsidies} style={{ backgroundColor: '#58B4E5', color: 'white', marginRight: '5px', marginLeft: '5px' }}>
                                    {i18n.t('Subsidies')}
                                </Button> */}
                                {/* <Button onClick={this.showGPSReport} style={{ backgroundColor: '#58B4E5', color: 'white', marginRight: '5px', marginLeft: '5px' }}>
                                    {i18n.t('GPS report')}
                                </Button> */}
                            </div>
                        }
                        {/* <Button onClick={this.props.handleClose} style={{ marginTop: '5px', backgroundColor: '#333333', color: 'white' }}>
                            {i18n.t('Close')}
                        </Button> */}
                    </DialogActions>
                </Dialog>
                {this.state.isStatisticShown &&
                    <Dialog
                        open={this.state.isStatisticShown}
                        maxWidth="xl"
                        aria-labelledby="responsive-dialog-title"
                    >
                        <Statistics kontakt_osoba={this.props.contactPerson} id_klijent={this.props.clientId} isStatisticShown={this.state.isStatisticShown} handleClose={this.showReport} />
                    </Dialog>}
                {this.state.isFieldShown &&
                    <Dialog
                        open={this.state.isFieldShown}
                        maxWidth="xl"
                        aria-labelledby="responsive-dialog-title"
                    >
                        <ViewFieldOneFarmer kontakt_osoba={this.props.contactPerson} id_klijent={this.props.clientId} isFieldShown={this.state.isFieldShown} handleCloseField={this.showField} />
                    </Dialog>}
                {this.state.isParcelShown &&
                    <Dialog
                        open={this.state.isParcelShown}
                        maxWidth="xl"
                        aria-labelledby="responsive-dialog-title"
                    >
                        <ViewParcelOneFarmer klijent_naziv={this.props.clientTitle} id_klijent={this.props.clientId} isParcelShown={this.state.isParcelShown} handleCloseParcel={this.showParcel} />
                    </Dialog>}
                {this.state.isSubsidiesShown &&
                    <Dialog
                        open={this.state.isSubsidiesShown}
                        maxWidth="xl"
                        aria-labelledby="responsive-dialog-title"
                    >
                        <ViewSubsidies kontakt_osoba={this.props.contactPerson} isSubsidiesShown={this.state.isSubsidiesShown} handleCloseSubsidies={this.showSubsidies} />
                    </Dialog>}
                {this.state.isGPSReportShown &&
                    <Dialog
                        open={this.state.isGPSReportShown}
                        disableBackdropClick
                        maxWidth="xl"
                        aria-labelledby="responsive-dialog-title"
                    >
                        <GPSReport idClient={this.props.clientId} handleClose={this.handleGPSReportOkClick} />
                    </Dialog>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        clientProfile: state.appReducer.clientProfile,
        clientProfileFetching: state.appReducer.clientProfileFetching,
        clientProfilePages: state.appReducer.clientProfilePages,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getClientProfile: (id) => dispatch(getClientProfile(id)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfilePreview);