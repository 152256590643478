import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import 'react-table/react-table.css';
import {
    getLayers, getRegionType, getSeasonType,
    setInitialState, setRowInReducer, deleteLayer
} from '../../../actions/control_panel/LayerActions';
import i18n from '../../../i18n/i18n';
import Select from 'react-select';
import immutable from 'seamless-immutable';
import { Fab } from '@material-ui/core';
import AddLayers from './AddLayers';
import { Delete } from '../../../utils/Delete';
const CheckboxTable = checkboxHOC(ReactTable);;

export class ViewLayers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            add: false,
            selection: [],
            selectAll: false,
            edit: false,
            delete: false,
        };
        this.tableScrollTop = 0;
    }

    componentDidMount() {
        if (this.props.regionType.length == 0) {
            this.props.getRegionType();
        }
        if (this.props.seasonType.length == 0) {
            this.props.getSeasonType();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.deleted === true) {
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getLayers(wrapped.state);
            this.setState({
                selection: [],
                selectAll: false,
            })
            this.props.setInitialState('layerObj');
            this.props.setInitialState('deleted');
        }
        if (nextProps.layerPostingSuccess === true) {
            this.setState({
                selection: [],
                name: '',
                selectAll: false
            })
            this.handleClick();
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getLayers(wrapped.state);
            this.props.setInitialState('layerPostingSuccess');
        }

        this.setState({
            layer: nextProps.layer,
            layerFetching: nextProps.layerFetching,

            regionType: immutable.asMutable(nextProps.regionType.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            regionTypeFetching: nextProps.regionTypeFetching,
            regionTypeFetchingFailed: nextProps.regionTypeFetchingFailed,

            seasonType: immutable.asMutable(nextProps.seasonType.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            seasonTypeFetching: nextProps.seasonTypeFetching,
            seasonTypeFetchingFailed: nextProps.seasonTypeFetchingFailed,
        })
    }

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    onExportClick = () => {
        this.setState({ export: !this.state.export });
    }

    handleClick = () => {
        if (this.state.edit == true && this.state.add == false) {
            this.setState({ edit: false })
            this.props.setInitialState('layerObj');
        } else if (this.state.selection.length > 0 && this.state.add == false && this.state.edit == false) {
            this.props.setInitialState('layerObj');
        }
        this.setState({ add: !this.state.add }, () => {
            if (this.state.add == true && this.state.edit == false) {
                this.setState({
                    selection: [],
                    name: '',
                    selectAll: false,
                })
            }
        });
    };

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
            this.props.setInitialState('layerObj');
        } else {
            selection.push(key);
        }
        this.setState({ selection }, function () {
            if (selection.length == 1) {
                let row1 = this.props.layer.filter((cl) => {
                    return cl.id == selection;
                })
                this.props.setRowInReducer(row1[0], 'layerObj');
            }
        });
    };

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });
    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    onDeleteClick = () => {
        this.setState({ delete: !this.state.delete });
    }

    deleteItem = () => {
        this.props.deleteLayer(this.state.selection);
        this.setState({ delete: false })
    }

    onEditClick() {
        this.setState({ add: !this.state.add, edit: true });
    }

    onTableClick = () => {
        this.props.history.push('/layer_cp');
    }


    render() {
        const columns = [
            {
                Header: i18n.t('Naziv'),
                accessor: 'naziv'
            },
            {
                Header: i18n.t('Region'),
                accessor: 'naziv_regiona',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.regionType}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Sezona'),
                accessor: 'naziv_sezone',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.seasonType}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('ID sezone'),
                accessor: 'id_sezona'
            }
        ];

        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "#ACDAF1" : "inherit"
                        }
                    };
                }
                return {};
            }
        };
        return (
            <div className="page">
                <div className="table-header">
                    <Fab onClick={() => this.handleClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button">
                        <span className="icon-Plus headerIcon" />
                    </Fab>
                    <Fab onClick={() => this.onSearchClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button"    >
                        <span className="icon-search headerIcon" />
                    </Fab>

                    {this.state.selection.length > 0 ?
                        <Fab onClick={() => this.onDeleteClick()}
                            color="secondary"
                            aria-label="add"
                            className="action-button">
                            <span className="icon-Kanta headerIcon" />
                        </Fab>
                        : null
                    }
                    {this.state.selection.length === 1 ?
                        <Fab onClick={() => this.onEditClick()}
                            color="secondary"
                            aria-label="edit"
                            className="action-button">
                            <span className="icon-Izmeni1 headerIcon" />
                        </Fab>
                        : null
                    }
                </div>
                <CheckboxTable ref={r => (this.checkboxTable = r)}
                    data={this.props.layer}
                    getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                    columns={columns}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.props.layerPages}
                    loading={this.props.layerFetching}
                    manual
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => this.props.getLayers(state, instance)}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF',
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />

                {this.state.add && <AddLayers add={this.state.add} edit={this.state.edit} handleClose={this.handleClick} />}
                {this.state.delete && <Delete delete={this.state.delete} handleClose={this.onDeleteClick} deleteItem={this.deleteItem} />}

            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        layer: state.layerReducerCP.layer,
        layerFetching: state.layerReducerCP.layerFetching,
        layerPages: state.layerReducerCP.layerPages,
        layerObj: state.layerReducerCP.layerObj,
        layerPostingSuccess: state.layerReducerCP.layerPostingSuccess,

        deleted: state.layerReducerCP.deleted,

        regionType: state.layerReducerCP.regionType,
        regionTypeFetching: state.layerReducerCP.regionTypeFetching,
        regionTypeFetchingFailed: state.layerReducerCP.regionTypeFetchingFailed,

        seasonType: state.layerReducerCP.seasonType,
        seasonTypeFetching: state.layerReducerCP.seasonTypeFetching,
        seasonTypeFetchingFailed: state.layerReducerCP.seasonTypeFetchingFailed,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getLayers: (state, instance) => dispatch(getLayers(state, instance)),

        setInitialState: (component) => dispatch(setInitialState(component)),
        setRowInReducer: (rowInfo) => dispatch(setRowInReducer(rowInfo)),

        deleteLayer: (selection) => dispatch(deleteLayer(selection)),

        getRegionType: () => dispatch(getRegionType()),
        getSeasonType: () => dispatch(getSeasonType()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewLayers);