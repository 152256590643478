import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n/i18n';
import {
    Button, TextField, Grid, LinearProgress, IconButton,
    Dialog, DialogContent, DialogTitle
} from '@material-ui/core';

import DocumentDropZone from '../../../utils/DocumentDropZone';
import DocumentGridList from '../../../utils/DocumentGridList';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    setInitialState, addProgram, getInstitution, getProgramType,
    getSectorType, deleteProgramDocument
} from '../../../actions/financial/ProgramActions';
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment';
export class AddProgram extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            programObj: this.props.programObj,
        }
    }

    componentDidMount() {
        if (this.props.programType.length === 0) {
            this.props.getProgramType();
        }
        if (this.props.sectorType.length === 0) {
            this.props.getSectorType();
        }
        if (this.props.institution.length === 0) {
            this.props.getInstitution();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.programPostingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('programPostingFailed');
        }
    }

    addProgram() {
        if (this.state.programObj.naziv &&
            this.state.programObj.id_vrsta_programa &&
            this.state.programObj.datum_pocetka &&
            this.state.programObj.datum_zavrsetka) {
            this.props.addProgram(
                this.state.programObj,
                this.state.acceptedDocuments
            )
        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    handleChangeValue = name => event => {
        let obj = Object.assign({}, this.state.programObj);
        obj[name] = event.target.value;
        this.setState({ programObj: obj });
    };

    handleDateChange = name => date => {
        let obj = Object.assign({}, this.state.programObj);
        obj[name] = moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY');
        this.setState({ programObj: obj });
    }

    renderLabel = (date) => {
        if (date.isValid()) {
            return date.format('DD.MM.YYYY');
        } else {
            return '';
        }
    }

    render() {
        let programType = [];
        if (this.props.programType && !this.props.programTypeFetching) {
            programType = this.props.programType.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let sectorType = [];
        if (this.props.sectorType && !this.props.sectorTypeFetching) {
            sectorType = this.props.sectorType.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let institution = [];
        if (this.props.institution && !this.props.institutionFetching) {
            institution = this.props.institution.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }

        return (
            <div style={{ padding: 20 }}>

                {/* <Paper className="roundedBoxMaterial" zdepth={4}> */}
                <Dialog
                    open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit program') : i18n.t('Add program')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-program">
                            <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                                <Grid item>
                                    <TextField
                                        required
                                        label={i18n.t('Title')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.state.programObj.naziv}
                                        onChange={this.handleChangeValue('naziv')}
                                    />
                                </Grid>
                                <Grid item >
                                    <TextField
                                        label={i18n.t('Type of program')}
                                        required
                                        select
                                        SelectProps={{
                                            native: true,
                                        }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        defaultValue={this.state.programObj.id_vrsta_programa}
                                        onChange={this.handleChangeValue('id_vrsta_programa')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                        <option value='' />
                                        {programType}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('Institution')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        select
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        defaultValue={this.state.programObj.id_institucija}
                                        onChange={this.handleChangeValue('id_institucija')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                        <option value='' />
                                        {institution}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('Type of sector')}
                                        select
                                        SelectProps={{
                                            native: true,
                                        }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        defaultValue={this.state.programObj.id_vrsta_sektor}
                                        onChange={this.handleChangeValue('id_vrsta_sektor')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                        <option value='' />
                                        {sectorType}
                                    </TextField>
                                </Grid>

                                <Grid item>
                                    <DatePicker
                                        keyboard
                                        label={i18n.t('Start date') + ' *'}
                                        format="DD.MM.YYYY"
                                        value={this.state.programObj.datum_pocetka ? moment(this.state.programObj.datum_pocetka, 'DD.MM.YYYY') : new Date()}
                                        onChange={this.handleDateChange('datum_pocetka')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        labelFunc={this.renderLabel}
                                        disableOpenOnEnter
                                        animateYearScrolling={false}
                                    />
                                </Grid>
                                <Grid item>
                                    <DatePicker
                                        keyboard
                                        label={i18n.t('Expiry date') + " *"}
                                        format="DD.MM.YYYY"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.state.programObj.datum_zavrsetka ? moment(this.state.programObj.datum_zavrsetka, 'DD.MM.YYYY') : new Date()}
                                        onChange={this.handleDateChange('datum_zavrsetka')}
                                        labelFunc={this.renderLabel}
                                        disableOpenOnEnter
                                        animateYearScrolling={false}
                                    />
                                </Grid>
                                <TextField
                                    multiline
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    id="full-width"
                                    margin="normal"
                                    label={i18n.t('Description')}
                                    value={this.state.programObj.opis}
                                    onChange={this.handleChangeValue('opis')}
                                />
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        multiline
                                        label={i18n.t('Program Web link')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.state.programObj.web_link}
                                        onChange={this.handleChangeValue('web_link')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        multiline
                                        label={i18n.t('Institution Web link')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.state.programObj.institucija_link}
                                        onChange={this.handleChangeValue('institucija_link')}
                                    />
                                </Grid>
                                {this.props.edit && this.props.programDocuments && this.props.programDocuments.length !== 0 ?
                                    <DocumentGridList
                                        documentArray={this.props.programDocuments}
                                        Controller='program'
                                        showDelete={true}
                                        deleteDoc={(document) => this.props.deleteProgramDocument(document)}
                                    />
                                    : null}
                                <DocumentDropZone
                                    multiple={true}
                                    onDropAccepted={({ accepted, rejected }) => {
                                        this.setState({
                                            acceptedDocuments: accepted,
                                            rejectedDocuments: rejected
                                        });
                                    }}
                                />
                                {this.props.programPosting && < LinearProgress className="spiner" />}
                                <Grid item xs>
                                    <Button
                                        style={{ alignSelf: 'center', backgroundColor: '#58B4E5', color: 'white' }}
                                        onClick={() => this.addProgram()}
                                        disabled={this.props.programPosting}
                                    >
                                        {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
                {/* </Paper> */}
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        programPosting: state.programReducer.programPosting,
        programPostingSuccess: state.programReducer.programPostingSuccess,
        programObj: state.programReducer.programObj,
        programPostingFailed: state.programReducer.programPostingFailed,
        programType: state.programReducer.programType,
        programTypeFetching: state.programReducer.programTypeFetching,
        programTypeFetchingFailed: state.programReducer.programTypeFetchingFailed,
        sectorType: state.programReducer.sectorType,
        sectorTypeFetching: state.programReducer.sectorTypeFetching,
        sectorTypeFetchingFailed: state.programReducer.sectorTypeFetchingFailed,
        institution: state.programReducer.institution,
        institutionFetching: state.programReducer.institutionFetching,
        institutionFetchingFailed: state.programReducer.institutionFetchingFailed,
        programDocuments: state.programReducer.programDocuments,
        programDocumentsFetching: state.programReducer.programDocumentsFetching,
        programDocumentsFetchingFailed: state.programReducer.programDocumentsFetchingFailed,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInitialState: (component) => dispatch(setInitialState(component)),
        addProgram: (programObj, acceptedDocuments) => dispatch(addProgram(programObj, acceptedDocuments)),
        getProgramType: () => dispatch(getProgramType()),
        getSectorType: () => dispatch(getSectorType()),
        getInstitution: () => dispatch(getInstitution()),
        deleteProgramDocument: (document) => dispatch(deleteProgramDocument(document)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddProgram)