import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { getMaterialGroup, getMaterialSubgroup, getMaterial, setInitialState, setRowInReducer } from '../../../actions/resources/MaterialActions';
import { getUnit } from '../../../actions/resources/UnitActions';
import { getCrop } from '../../../actions/resources/CropActions';
import Select from 'react-select';
import i18n from '../../../i18n/i18n';
import immutable from 'seamless-immutable';
import AddMaterial from './AddMaterial';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import ForBooleanFilter from '../../../utils/ForBooleanFilter';
const CheckboxTable = checkboxHOC(ReactTable);
export class ViewMaterials extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            add: this.props.add,
            edit: this.props.edit,
            showFilters: this.props.showFilters,
            selection: [],
            selectAll: false
        };
    }

    componentDidMount() {
        if (this.props.materialGroupDropdown.length === 0) {
            this.props.getMaterialGroup();
        }
        if (this.props.materialSubgroupDropdown.length === 0) {
            this.props.getMaterialSubgroup();
        }
        if (this.props.crops.length === 0) {
            this.props.getCrop();
        }
        if (this.props.unit.length === 0) {
            this.props.getUnit();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.deleted === true) {
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getMaterial(wrapped.state);
            this.setState({
                selection: [],
                selectAll: false,
            })
            this.props.setInitialState('deleted');

        }
        if (nextProps.materialPostingSuccess === true) {
            this.setState({
                selection: [],
                name: '',
                selectAll: false
            })
            this.props.handleClose();
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getMaterial(wrapped.state);
            this.props.setInitialState('materialPostingSuccess');
        }
        this.setState({
            add: nextProps.add,
            showFilters: nextProps.showFilters,
            material: nextProps.material,
            materialsFetching: nextProps.materialsFetching,
            materialGroupDropdown: immutable.asMutable(nextProps.materialGroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            groupFetching: nextProps.groupFetching,
            groupFetchingFailed: nextProps.groupFetchingFailed,
            materialSubgroupDropdown: immutable.asMutable(nextProps.materialSubgroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            subgroupFetching: nextProps.subgroupFetching,
            subgroupFetchingFailed: nextProps.subgroupFetchingFailed,
            crops: immutable.asMutable(nextProps.crops.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            cropFetching: nextProps.cropFetching,
            cropFetchingFailed: nextProps.cropFetchingFailed,
            unit: immutable.asMutable(nextProps.unit.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            unitFetching: nextProps.unitFetching,
            unitFetchingFailed: nextProps.unitFetchingFailed,
        })
        if (nextProps.add == true && nextProps.edit == false) {
            this.props.setInitialState('materialObj');
            this.setState({
                selection: [],
                name: '',
                selectAll: false
            })
        }

    }


    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            selection.push(key);
        }
        this.setState({ selection }, function () {
            if (selection.length == 1) {
                let row1 = this.props.material.filter((mat) => {
                    return mat.id == selection;
                })
                this.props.setRowInReducer(row1[0], 'materialObj');
            }
        });
        this.props.setSelection(selection, 'material');
    };

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });
        this.props.setSelection(selection, 'material');

    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };



    render() {
        let content;
        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "#ACDAF1" : "inherit"
                        }
                    };
                }
                return {};
            }
        };
        content =
            <div className="page">
                <CheckboxTable data={this.props.material}
                    ref={r => (this.checkboxTable = r)}
                    keyField={'id'}
                    columns={this.columns}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    showPaginationTop
                    showPaginationBottom={false}
                    pages={this.props.materialsPages}
                    loading={this.props.materialsFetching}
                    manual
                    onFetchData={(state, instance) => this.props.getMaterial(state, instance)}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF',
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
                {this.state.add && <AddMaterial add={this.state.add} edit={this.props.edit}
                    handleClose={this.props.handleClose} />}
            </div>


        return (
            <div>
                {content}
            </div>
        )
    }

    columns = [
        {
            Header: i18n.t('Title'),
            accessor: 'naziv'
        },
        {
            Header: i18n.t('Code'),
            accessor: 'sifra',
        },
        {
            id: 'organska_proizvodnja',
            Header: i18n.t('Permitted in organic production'),
            accessor: d => d.organska_proizvodnja,
            Cell: ({ value }) => value === true ? i18n.t('Yes') : i18n.t('No'),
            Filter: ({ filter, onChange }) =>
                <ForBooleanFilter
                    onChange={(s) => {
                        onChange(s);
                    }}
                />
        },
        {
            Header: i18n.t('Price'),
            accessor: 'cena',
        },
        {
            Header: i18n.t('Crop type'),
            accessor: 'kultura_naziv',
            Filter: ({ filter, onChange }) =>
                <Select
                    isMulti
                    name="naziv"
                    onChange={value => {
                        onChange(value)
                    }}
                    closeMenuOnSelect={false}
                    options={this.state.crops}
                    className="multi-select-in-filter"
                    classNamePrefix="select"
                    placeholder={i18n.t('Select')}
                />
        },
        {
            Header: i18n.t('Material group name'),
            accessor: 'materijal_grupa_naziv',
            Filter: ({ filter, onChange }) =>
                <Select
                    isMulti
                    name="naziv"
                    onChange={value => {
                        onChange(value)
                    }}
                    closeMenuOnSelect={false}
                    options={this.state.materialGroupDropdown}
                    className="multi-select-in-filter"
                    placeholder={i18n.t('Select')}
                    classNamePrefix="select"
                />
        },
        {
            Header: i18n.t('Material subgroup name'),
            accessor: 'materijal_podgrupa_naziv',
            Filter: ({ filter, onChange }) =>
                <Select
                    isMulti
                    name="naziv"
                    onChange={value => {
                        onChange(value)
                    }}
                    closeMenuOnSelect={false}
                    options={this.state.materialSubgroupDropdown}
                    className="multi-select-in-filter"
                    placeholder={i18n.t('Select')}
                    classNamePrefix="select"
                />
        },
        {
            Header: i18n.t('Unit'),
            accessor: 'unit',
            Filter: ({ filter, onChange }) =>
                <Select
                    isMulti
                    name="naziv"
                    onChange={value => {
                        onChange(value)
                    }}
                    closeMenuOnSelect={false}
                    options={this.state.unit}
                    className="multi-select-in-filter"
                    classNamePrefix="select"
                    placeholder={i18n.t('Select')}
                />
        },
        {
            id: 'stari_podatak',
            Header: i18n.t('Old data'),
            accessor: d => d.stari_podatak,
            Cell: ({ value }) => value === true ? i18n.t('Yes') : i18n.t('No'),
            Filter: ({ filter, onChange }) =>
                <ForBooleanFilter
                    onChange={(s) => {
                        onChange(s);
                    }}
                />
        }
    ];
};

function mapStateToProps(state) {
    return {
        material: state.materialReducer.material,
        materialsFetching: state.materialReducer.materialsFetching,
        materialsPages: state.materialReducer.materialsPages,
        materialGroupDropdown: state.materialReducer.materialGroupDropdown,
        groupFetching: state.materialReducer.groupFetching,
        groupFetchingFailed: state.materialReducer.groupFetchingFailed,
        materialSubgroupDropdown: state.materialReducer.materialSubgroupDropdown,
        subgroupFetching: state.materialReducer.subgroupFetching,
        subgroupFetchingFailed: state.materialReducer.subgroupFetchingFailed,
        crops: state.cropReducer.crops,
        cropFetching: state.cropReducer.cropFetching,
        cropFetchingFailed: state.cropReducer.cropFetchingFailed,
        unit: state.unitReducer.unit,
        unitFetching: state.unitReducer.unitFetching,
        unitFetchingFailed: state.unitReducer.unitFetchingFailed,
        materialPostingSuccess: state.materialReducer.materialPostingSuccess,
        deleted: state.materialReducer.deleted,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getMaterialGroup: () => dispatch(getMaterialGroup()),
        getMaterialSubgroup: () => dispatch(getMaterialSubgroup()),
        getCrop: () => dispatch(getCrop()),
        getUnit: () => dispatch(getUnit()),
        setInitialState: (component) => dispatch(setInitialState(component)),
        setRowInReducer: (rowInfo, name) => dispatch(setRowInReducer(rowInfo, name)),
        getMaterial: (state, instance) => dispatch(getMaterial(state, instance)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewMaterials)