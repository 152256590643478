import React from 'react';
import i18n from '../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { wentWrong } from "../../../utils/ToastConfig";
import axios from '../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';

export default class AddDefaultSeasons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            default_season: null,
            default_seasons: [],
        }
    }

    componentDidMount() {
        this.getByURL('season/season_default', 'default_seasons', this.props.data.id, 'id_region')
    }

    getByURL(url, obj, event, required) {
        event = event || '';
        required = required || '';
        if (event != null) {
            axios.get(`api/${url}`, { params: { [required]: event } })
                .then(res => this.setState({ [obj]: res.data.data }))
                .catch(error => wentWrong('Something went wrong, please try again!'));
        }
    }

    addDefaultSeason() {
        if (this.state.default_season) {
            let data = this.props.data;
            data.id_default_sezona = this.state.default_season;

            axios.post('api/region/edit_default_season', data)
                .then(res => {
                    this.props.handleClose();
                })
                .catch(error => {
                    wentWrong('Something went wrong, please try again!');
                    console.log(`Error: ${error}`);
                })
        }
        else wentWrong('Please enter all required fields!');
    }

    render() {
        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit default season') : i18n.t('Add default season')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-layer">
                            <Grid container justify="space-between" alignContent="space-between" >

                                <Grid item xs={12}>
                                    <Typography style={{ fontSize: '20px' }}>
                                        {this.state.data.id} - {this.state.data.naziv}:
                                    </Typography>

                                    <TextField required
                                        label={i18n.t('Seasons')}
                                        select
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginBottom: '10px' }}
                                        onChange={(e) => { this.setState({ default_season: e.target.value }); }} >
                                        <option value='' />
                                        {this.state.default_seasons.map((default_seasons) => <option key={default_seasons.id} value={default_seasons.id}>{default_seasons.naziv}</option>)}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12}>
                                    <Button style={{ alignSelf: 'center', backgroundColor: '#58B4E5', color: 'white', marginTop: '20px', marginBottom: '10px' }}
                                        onClick={() => this.addDefaultSeason()}
                                        disabled={this.props.layerPosting} >
                                        {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                                    </Button>
                                </Grid>

                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
};