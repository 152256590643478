import axios from '../utils/AxiosWrapper';
export const Type = {

    GET_HIERARCHY_CALL: 'GET_HIERARCHY_CALL',
    GET_HIERARCHY_SUCCESS: 'GET_HIERARCHY_SUCCESS',
    GET_HIERARCHY_FAILED: 'GET_HIERARCHY_FAILED',

    SET_INITIAL_STATE: 'SET_INITIAL_STATE',

};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function getHierarchy(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_HIERARCHY_CALL
        });

        var config = {
            params: {
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/hierarchy/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_HIERARCHY_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_HIERARCHY_FAILED
                });
            });
    }
}




