import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import 'react-table/react-table.css';
import {
    getLocalGov, getTitle, getMaritalStatus,
    getIdentificationDoc, setInitialState, setRowInReducer
} from '../../actions/FarmRegistarActions';
import {
    getStates, getAnchorAgent, getAgentDropdown,
    addAnchorAgent, deleteAgentAnchor
} from '../../actions/AdministrationActions';
import i18n from '../../i18n/i18n';
import Select from 'react-select';
import immutable from 'seamless-immutable';
import ReactSelect from '../../utils/ReactSelect';
import { withRouter } from 'react-router-dom'
import {
    Button, TextField, Grid, IconButton, Fab,
    Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, Card
} from '@material-ui/core';
import DatePickerForFilter from '../../utils/DatePickerForFilter';
import moment from 'moment';
import * as Icons from '@material-ui/icons';
import { Delete } from "../../utils/Delete";
import { getUser } from '../../utils/CommonFunctions';
import { withStyles } from '@material-ui/core/styles';
const CheckboxTable = checkboxHOC(ReactTable);
const styles = theme => ({
    primaryMain: {
        backgroundColor: theme.palette.primary.main,
    },
    center: {
        textAlign: 'center'
    },
    starButton: {
        padding: '3px',
    },
    card: {
        height: 500,
        width: 600,
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column'
    }
});

export class ViewAnchorAgent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            addFarmers: false,
            delete: false,
            selection: [],
            selectAll: false,
        };
        this.tableScrollTop = 0;
    }

    componentDidMount() {
        let genders = [
            { label: i18n.t('Male'), value: i18n.t('Male') },
            { label: i18n.t('Female'), value: i18n.t('Female') }
        ]
        this.props.getStates();
        this.props.getLocalGov();
        this.props.getTitle();
        this.props.getMaritalStatus();
        this.props.getIdentificationDoc();
        this.props.getAgentDropdown(this.props.selectionLocGov);
        this.setState({
            user: getUser(),
            genders
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.addAnchorAgentPostingSuccess === true) {
            const { id_klijent_soc } = this.props;
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getAnchorAgent(wrapped.state, null, id_klijent_soc);
            this.props.setInitialState('addAnchorAgentPostingSuccess');
        }
        this.setState({
            states: immutable.asMutable(nextProps.states.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            title: immutable.asMutable(nextProps.title.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            maritalStatus: immutable.asMutable(nextProps.maritalStatus.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            identificationDoc: immutable.asMutable(nextProps.identificationDoc.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            localGoverments: immutable.asMutable(nextProps.localGoverments.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            statesFetching: nextProps.statesFetching,
            statesFetchingFailed: nextProps.statesFetchingFailed,
            agentDropdown: immutable.asMutable(nextProps.agentDropdown.map((item) => { return { label: item.kontakt_osoba, value: item.id } }), { deep: true }),
        })
    }

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    handleClick = () => {
        this.setState({ add: !this.state.add }, () => {
            this.props.setInitialState('AnchorAgentArr');
            this.setState({
                selection: [],
                name: '',
                selectAll: false,
            })
        });
    };

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
            this.props.setInitialState('AnchorAgentArr');
        } else {
            selection.push(key);
        }
        this.setState({ selection }, function () {
            if (selection.length == 1) {
                let row1 = this.props.anchorAgent.filter((farm) => {
                    return farm.id == selection;
                })
                this.props.setRowInReducer(row1[0], 'AnchorAgentArr');
            }
        });
    };
    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });
    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };


    onDeleteClick = (AnchorRow) => {
        this.setState({ deleteAnchor: !this.state.deleteAnchor, AnchorRow });
    }
    handleClick = () => {
        this.setState({ addFarmers: !this.state.addFarmers })
    }


    deleteItem = () => {
        const { AnchorRow } = this.state;
        this.props.deleteAnchor(AnchorRow.original.id);
        this.setState({ deleteAnchor: false });
    }

    handleChangeDropdown = selectedOption => {
        this.setState({ usersSelected: selectedOption });
    }
    handleAddAnchorAgent = () => {
        const { usersSelected } = this.state;
        this.props.addAnchorAgent(this.props.id_klijent_soc, usersSelected);
        this.setState({ addFarmers: false });
    }

    onDeleteClick = () => {
        this.setState({ delete: !this.state.delete });
    }

    deleteItem = () => {
        this.props.deleteAgentAnchor(this.state.selection);
        this.setState({ delete: false })
    }

    render() {
        const { theme, classes, info, handleClose, id_klijent_soc } = this.props;
        const { selectAll, addFarmers, agentDropdown } = this.state;
        const { toggleSelection, toggleAll, isSelected } = this;
        const columns = [
            {
                Header: i18n.t('Agent'),
                accessor: 'naziv',
                resizable: false,
            },
            {
                Header: i18n.t('Zone'),
                accessor: 'zona_naziv',
                resizable: false,
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.zones}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('State'),
                accessor: 'opstina_naziv',
                resizable: false,
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.states}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Local government'),
                accessor: 'katastarska_opstina_naziv',
                resizable: false,
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.localGovernments}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Address'),
                accessor: 'ulica_i_broj',
                resizable: false
            },
            {
                Header: i18n.t('Zip code'),
                accessor: 'postanski_broj',
                resizable: false
            },
            {
                Header: i18n.t('Email'),
                accessor: 'kontakt_mejl',
                resizable: false
            },
            {
                Header: i18n.t('Phone'),
                accessor: 'kontakt_telefon',
                resizable: false
            }
        ];

        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "lightgreen" : "inherit"
                        }
                    };
                }
                return {};
            }
        };
        return (
            <Dialog
                open={info}
                onClose={handleClose}
                disableBackdropClick
                maxWidth="xl"
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('Agents by anchor')}
                    <IconButton onClick={handleClose}>
                        <span className="icon-cancel-circle closeIcon" />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className="page">
                        <div className="table-header">
                            <Fab
                                onClick={() => this.handleClick()}
                                color="primary"
                                aria-label="add"
                                className="action-button"
                            >
                                <span className="icon-Plus headerIcon" />
                            </Fab>
                            <Fab
                                onClick={() => this.onSearchClick()}
                                color="primary"
                                aria-label="add"
                                className="action-button"
                            >
                                <span className="icon-search headerIcon" />
                            </Fab>
                            {this.state.selection.length > 0 &&
                                <Fab
                                    onClick={() => this.onDeleteClick()}
                                    color="primary"
                                    aria-label="add"
                                    className="action-button"
                                >
                                    <span className="icon-Kanta headerIcon" />
                                </Fab>
                            }
                        </div>
                        <CheckboxTable
                            ref={r => (this.checkboxTable = r)}
                            data={this.props.anchorAgent}
                            getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                            columns={columns}
                            keyField={'id'}
                            pageSizeOptions={[10, 25, 50, 100]}
                            defaultPageSize={25}
                            filterable={this.state.showFilters}
                            pages={this.props.anchorAgentPages}
                            loading={this.props.anchorAgentFetching}
                            manual
                            showPaginationTop
                            showPaginationBottom={false}
                            onFetchData={(state, instance) => this.props.getAnchorAgent(state, instance, id_klijent_soc)}
                            className="-striped -highlight table-dialog"
                            previousText={i18n.t('Previous')}
                            nextText={i18n.t('Next')}
                            loadingText={i18n.t('Loading...')}
                            noDataText={i18n.t('No rows found')}
                            pageText={i18n.t('Page')}
                            ofText={i18n.t('of')}
                            rowsText={i18n.t('rows')}
                            {...checkboxProps}
                        />

                        {this.state.delete &&
                            <Delete
                                delete={this.state.delete}
                                handleClose={this.onDeleteClick}
                                deleteItem={this.deleteItem} />
                        }

                        {addFarmers &&
                            <Dialog
                                open={addFarmers}
                                onClose={this.handleClick}
                                disableBackdropClick
                                maxWidth="xl"
                                aria-labelledby="responsive-dialog-title"
                            >
                                <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('Agents')}
                                    <IconButton onClick={this.handleClick}>
                                        <span className="icon-cancel-circle closeIcon" />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <Card raised className={classes.card}>
                                        <ReactSelect
                                            // closeMenuOnSelect={true}
                                            isClearable={true}
                                            maxMenuHeight={400}
                                            isMulti={true}
                                            menuPlacement="auto"
                                            defaultMenuIsOpen
                                            // value={anchorDropdown.filter((element) => {
                                            //     return anchoredUsers && anchoredUsers.some((item) => item.id === anchoredUsers.value)
                                            // })}
                                            onChange={this.handleChangeDropdown}
                                            options={agentDropdown}
                                            className="react-select-dropdown-farmers"
                                            classNamePrefix="select"
                                            placeholder={i18n.t('Select')}
                                        />
                                    </Card>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={this.handleAddAnchorAgent}
                                        variant="contained"
                                        color="primary">
                                        {i18n.t('Ok')}
                                    </Button>
                                    <Button
                                        onClick={this.handleClick}
                                        variant="contained"
                                        color="primary">
                                        {i18n.t('Cancel')}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        }
                    </div>
                </DialogContent>
            </Dialog>
        )
    }
};

function mapStateToProps(state) {
    return {
        AnchorAgentArr: state.administrationReducer.AnchorAgentArr,
        anchorAgent: state.administrationReducer.anchorAgent,
        anchorAgentFetching: state.administrationReducer.anchorAgentFetching,
        anchorAgentPages: state.administrationReducer.anchorAgentPages,
        agentDropdown: state.administrationReducer.agentDropdown,
        localGoverments: state.farmRegistarReducer.localGoverments,
        title: state.farmRegistarReducer.title,
        maritalStatus: state.farmRegistarReducer.maritalStatus,
        identificationDoc: state.farmRegistarReducer.identificationDoc,
        states: state.administrationReducer.states,
        addAnchorAgentPostingSuccess: state.administrationReducer.addAnchorAgentPostingSuccess
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getStates: () => dispatch(getStates()),
        getLocalGov: () => dispatch(getLocalGov()),
        getTitle: () => dispatch(getTitle()),
        setInitialState: (component) => dispatch(setInitialState(component)),
        setRowInReducer: (rowInfo, name) => dispatch(setRowInReducer(rowInfo, name)),
        getIdentificationDoc: () => dispatch(getIdentificationDoc()),
        getMaritalStatus: () => dispatch(getMaritalStatus()),
        getAnchorAgent: (state, instance, id) => dispatch(getAnchorAgent(state, instance, id)),
        getAgentDropdown: (id) => dispatch(getAgentDropdown(id)),
        deleteAgentAnchor: (selection) => dispatch(deleteAgentAnchor(selection)),
        addAnchorAgent: (id_klijent_soc, selected) => dispatch(addAnchorAgent(id_klijent_soc, selected))
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewAnchorAgent)));