import immutable from 'seamless-immutable';
import { Type as ClientGMType } from '../../actions/control_panel/ClientGroupModuleActions';
export const INITIAL_STATE = immutable({
    clientGMPosting: false,
    clientGMPostingSuccess: false,
    clientGMPostingFailed: false,

    clientGM: [],
    clientGMObj: {},
    clientGMFetching: false,
    clientGMPages: 1,

    regionType: [],
    regionTypeFetching: false,
    regionTypeFetchingFailed: false,

    clientGroupType: [],
    clientGroupTypeFetching: false,
    clientGroupTypeFetchingFailed: false,

    moduleType: [],
    moduleTypeFetching: false,
    moduleTypeFetchingFailed: false,

    deleted: false,
    deleteClientFailed: false,
});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case ClientGMType.SET_INITIAL_STATE: {
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;
        }

        case ClientGMType.ADD_CLIENTGM_CALL: {
            const clientGMPosting = true;
            return state.merge({ clientGMPosting });
            break;
        }

        case ClientGMType.ADD_CLIENTGM_SUCCESS: {
            const clientGMPosting = false;
            const clientGMPostingSuccess = true;
            return state.merge({ clientGMPostingSuccess, clientGMPosting, clientGMObj: INITIAL_STATE.clientGMObj })
            break;
        }

        case ClientGMType.ADD_CLIENTGM_FAILED: {
            const clientGMPosting = false;
            const clientGMPostingFailed = true;
            return state.merge({ clientGMPosting, clientGMPostingFailed });
            break;
        }

        case ClientGMType.GET_CLIENTGM_READ_CALL: {
            const clientGMFetching = true;
            return state.merge({ clientGMFetching });
            break;
        }

        case ClientGMType.GET_CLIENTGM_READ_SUCCESS: {
            const clientGM = action.data.data;
            const clientGMFetching = false;
            const clientGMPages = action.data.total_pages
            return state.merge({ clientGM, clientGMFetching, clientGMPages });
            break;
        }

        case ClientGMType.GET_CLIENTGM_READ_FAILED: {
            const clientGMFetchingFailed = true;
            const clientGMFetching = false;
            return state.merge({ clientGMFetching, clientGMFetchingFailed });
            break;
        }

        case ClientGMType.GET_REGION_TYPE_CALL: {
            const regionTypeFetching = true;
            return state.merge({ regionTypeFetching });
            break;
        }

        case ClientGMType.GET_REGION_TYPE_SUCCESS: {
            const regionType = action.data.data;
            const regionTypeFetching = false;
            return state.merge({ regionType, regionTypeFetching });
            break;
        }

        case ClientGMType.GET_REGION_TYPE_FAILED: {
            const regionTypeFetchingFailed = true;
            const regionTypeFetching = false;
            return state.merge({ regionTypeFetching, regionTypeFetchingFailed });
            break;
        }

        case ClientGMType.GET_CLIENTGROUP_TYPE_CALL: {
            const clientGroupTypeFetching = true;
            return state.merge({ clientGroupTypeFetching });
            break;
        }

        case ClientGMType.GET_CLIENTGROUP_TYPE_SUCCESS: {
            const clientGroupType = action.data.data;
            const clientGroupTypeFetching = false;
            return state.merge({ clientGroupType, clientGroupTypeFetching });
            break;
        }

        case ClientGMType.GET_CLIENTGROUP_TYPE_FAILED: {
            const clientGroupTypeFetchingFailed = true;
            const clientGroupTypeFetching = false;
            return state.merge({ clientGroupTypeFetching, clientGroupTypeFetchingFailed });
            break;
        }

        case ClientGMType.GET_MODULE_TYPE_CALL: {
            const moduleTypeFetching = true;
            return state.merge({ moduleTypeFetching });
            break;
        }

        case ClientGMType.GET_MODULE_TYPE_SUCCESS: {
            const moduleType = action.data.data;
            const moduleTypeFetching = false;
            return state.merge({ moduleType, moduleTypeFetching });
            break;
        }

        case ClientGMType.GET_MODULE_TYPE_FAILED: {
            const moduleTypeFetchingFailed = true;
            const moduleTypeFetching = false;
            return state.merge({ moduleTypeFetching, moduleTypeFetchingFailed });
            break;
        }

        case ClientGMType.DELETE_CLIENTGM_SUCCESS: {
            const array = state['clientGM'].asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ clientGM: array, deleted: deleted })
            break;
        }

        case ClientGMType.DELETE_CLIENTGM_FAILED: {
            const deleteClientGMFailed = true;
            return state.merge({ deleteClientGMFailed });
            break;
        }

        case ClientGMType.SET_CLIENTGM_ROW_IN_REDUCER: {
            const data = action.rowInfo;
            return state.merge({ clientGMObj: data })
            break;
        }

        default:
            return state;
    }
}