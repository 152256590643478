import immutable from 'seamless-immutable';
import { Type as FarmerInformationType } from '../actions/FarmerInfoActions';


export const INITIAL_STATE = immutable({
    farmerInfoFetching: false,
    farmerInfoFetchingFailed: false,

    farmerRegistrationObj: {},
    knowYourCustomerObj: {},
    farmIdentificationObj: {},
    inputDistributionObj: {},
    monitoringAndEvaluationObj: {},
    anchorCOSObj: {}

});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case FarmerInformationType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;

        case FarmerInformationType.GET_FARMER_INFO_CALL: {
            const farmerInfoFetching = true;
            return state.merge({ farmerInfoFetching })
        }

        case FarmerInformationType.GET_FARMER_INFO_SUCCESS: {
            const farmerInfoFetching = false;
            const valueForMerge = action.data;
            return state.merge({ farmerInfoFetching, [action.name]: valueForMerge })
        }

        case FarmerInformationType.GET_FARMER_INFO_FAILED: {
            const farmerInfoFetching = false;
            const farmerInfoFetchingFailed = true;
            return state.merge({ farmerInfoFetching, farmerInfoFetchingFailed })
        }

        default:
            return state;
    }
}