import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import {
    TextField, Button, Grid, Avatar, Card, LinearProgress
} from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DocumentGridList from '../../utils/DocumentGridList';
import { saveInfo, getInfo } from '../../actions/FarmerInfoActions';
import { getUser } from '../../utils/CommonFunctions';

const styles = {
    card: {
        padding: '10px',
        width: '100%',
        margin: '10px',
        display: 'flex',
        flexWrap: 'wrap',
    },
}

export class FarmersInformations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            farmerRegistrationObj: {
                black_list_agent: false
            },
            user: {},
        };
    }

    componentDidMount() {
        const { getInfo, id_klijent } = this.props;
        getInfo(id_klijent);
        this.setState({
            user: getUser(),
        })
    }

    componentDidUpdate(prevProps) {
        const { farmerRegistrationObj } = this.props;
        if (prevProps.farmerRegistrationObj !== farmerRegistrationObj) {
            this.setState({
                farmerRegistrationObj
            })
        }
    }

    handleChangeValue = name => event => {
        const { farmerRegistrationObj } = this.state;
        let obj = Object.assign({}, farmerRegistrationObj);
        obj[name] = event.target.value;
        this.setState({ farmerRegistrationObj: obj });
    };

    saveFarmerRegistration = () => {
        const { farmerRegistrationObj } = this.state;
        const { saveInfo } = this.props;
        saveInfo(farmerRegistrationObj);
    }
    handleChangeCheckbox = name => event => {
        let obj = Object.assign({}, this.state.farmerRegistrationObj);
        obj[name] = event.target.checked;
        this.setState({ farmerRegistrationObj: obj });
    };


    render() {

        const { localGoverments, states, farmerInfoFetching } = this.props
        const { farmerRegistrationObj, user } = this.state;
        var avatarSrc = require('../../assets/images/profileImage.png');
        return (
            <div style={{ padding: 20 }}>
                {farmerInfoFetching && <LinearProgress />}
                <Card style={styles.card}>
                    <Grid item xl={12}>
                        <Avatar
                            src={avatarSrc}
                            className="userProfile-avatar"
                        />
                    </Grid>
                    <br />
                    <Grid item>
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label={i18n.t('Farmer ID')}
                            disabled
                            value={farmerRegistrationObj.id}
                            style={{ width: '256px', margin: '10px' }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label={i18n.t('Farmers Phone Number')}
                            required
                            value={farmerRegistrationObj.kontakt_telefon}
                            style={{ width: '256px', margin: '10px' }}
                            onChange={this.handleChangeValue('kontakt_telefon')}
                        />
                    </Grid>
                    <Grid item xl={12} style={{ paddingBottom: '20px', paddingTop: '20px' }}>
                        <DocumentGridList
                            documentArray={[]}
                            showDelete={false}
                            title={i18n.t('Farmers Biometric')}
                        />
                    </Grid>
                    {/* <Grid item>
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label={i18n.t('Name of Cluster')}
                            required
                            value={farmerRegistrationObj.name_cooperative_society}
                            style={{ width: '256px', margin: '10px' }}
                            onChange={this.handleChangeValue('name_cooperative_society')}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label={i18n.t('Name of Cluster')}
                            required
                            value={farmerRegistrationObj.name_cluster}
                            style={{ width: '256px', margin: '10px' }}
                            onChange={this.handleChangeValue('name_cluster')}
                        />
                    </Grid> */}
                    <Grid item>
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                            }}
                            select
                            label={i18n.t('Name of State')}
                            required
                            value={farmerRegistrationObj.id_opstina}
                            SelectProps={{
                                native: true,
                            }}
                            onChange={this.handleChangeValue('id_opstina')}
                            margin="normal"
                            style={{ width: '256px', margin: '10px' }}

                        >
                            <option value='' />
                            {states}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                            }}
                            select
                            label={i18n.t('Local government area')}
                            required
                            value={farmerRegistrationObj.id_katastarska_opstina}
                            SelectProps={{
                                native: true,
                            }}
                            onChange={this.handleChangeValue('id_katastarska_opstina')}
                            margin="normal"
                            style={{ width: '256px', margin: '10px' }}

                        >
                            <option value='' />
                            {localGoverments}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label={i18n.t('BVN')}
                            required
                            value={farmerRegistrationObj.bpg}
                            onChange={this.handleChangeValue('bpg')}
                            style={{ width: '256px', margin: '10px' }}
                        />
                    </Grid>
                    {user.id_klijent_grupa === 13 &&
                        <Grid item xs={12} sm={6} >
                            <FormControlLabel
                                label={i18n.t('Black list')}
                                style={{ marginTop: '10px', marginBottom: '10px' }}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={this.state.farmerRegistrationObj.black_list_agent}
                                        onChange={this.handleChangeCheckbox('black_list_agent')}
                                        value="black_list_agent"
                                    />
                                }
                            />
                        </Grid>
                    }
                    {user.id_klijent_grupa === 10 && farmerRegistrationObj.black_list_agent &&
                        <Grid item xs={12} sm={6} >

                            <FormControlLabel
                                label={i18n.t('Black list')}
                                style={{ marginTop: '10px', marginBottom: '10px' }}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={farmerRegistrationObj.black_list_branch}
                                        onChange={this.handleChangeCheckbox('black_list_branch')}
                                        value="black_list_branch"
                                    />
                                }
                            />

                        </Grid>
                    }
                    {(user.id_klijent_grupa === 13 || user.id_klijent_grupa === 10) &&
                        farmerRegistrationObj.black_list_agent === true &&
                        <Grid item xs={12} sm={6} >
                            <TextField
                                multiline
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={user.id_klijent_grupa === 10}
                                id="full-width"
                                margin="normal"
                                label={i18n.t('Description')}
                                value={farmerRegistrationObj.opis_black_list}
                                onChange={this.handleChangeValue('opis_black_list')}
                            />
                        </Grid>
                    }

                </Card>
                <Grid item xs>
                    <Button
                        style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white' }}
                        onClick={() => this.saveFarmerRegistration()}
                    >
                        {i18n.t('Save')}
                    </Button>
                </Grid>
                <Grid item xs={3}>
                </Grid>
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        farmerRegistrationObj: state.farmerInfoReducer.farmerRegistrationObj,
        farmerInfoFetching: state.farmerInfoReducer.farmerInfoFetching
    }
}

function mapDispatchToProps(dispatch) {
    return {
        saveInfo: (obj) => dispatch(saveInfo(obj)),
        getInfo: (id_klijent) => dispatch(getInfo(id_klijent))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FarmersInformations)
