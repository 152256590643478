import React from 'react';
import i18n from '../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { wentWrong } from "../../../utils/ToastConfig";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from '../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';

export default class AddYearlyReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            broj_ugovora: '',
            povrsina: '',
            zakupnina: '',
            zakupnina_jm: '',
            obradjeno: '',
            zapusteno: '',
            uzurpirano: '',
            jesenja: false,
            analiza_plodnosti: false,
            godina_analize: ''
        }
    }

    componentDidMount() {
        // this.getByURL('worker/filter_sector', 'sectors');


        if (this.props.edit === true) {
            this.setState({
                povrsina: this.props.data.povrsina,
                broj_ugovora: this.props.data.broj_ugovora,
                zakupnina: this.props.data.zakupnina,
                zakupnina_jm: this.props.data.zakupnina_jm,
                godina_analize: this.props.data.godina_analize,
                obradjeno: this.props.data.obradjeno,
                zapusteno: this.props.data.zapusteno,
                uzurpirano: this.props.data.uzurpirano,
                jesenja: this.props.data.jesenja,
                analiza_plodnosti: this.props.data.analiza_plodnosti,
            })
        }
    }

    getByURL(url, obj, event, required) {
        event = event || '';
        required = required || '';
        if (event != null) {
            axios.get(`api/${url}`, { params: { [required]: event } })
                .then(res => this.setState({ [obj]: res.data.data }))
                .catch(error => wentWrong('Something went wrong, please try again!'));
        }
    }

    addField() {
        if (
            this.state.povrsina
        ) {
            let data = {
                id: this.props.data.id, // da prepozna za edit na backendu
                broj_ugovora: this.state.broj_ugovora ? this.state.broj_ugovora : null,
                zakupnina: this.state.zakupnina ? Number(this.state.zakupnina) : null,
                zakupnina_jm: this.state.zakupnina_jm ? Number(this.state.zakupnina_jm) : null,
                obradjeno: this.state.obradjeno ? Number(this.state.obradjeno) : null,
                zapusteno: this.state.zapusteno ? Number(this.state.zapusteno) : null,
                uzurpirano: this.state.uzurpirano ? Number(this.state.uzurpirano) : null,
                jesenja: this.state.jesenja,
                analiza_plodnosti: this.state.analiza_plodnosti,
                godina_analize: this.state.godina_analize ? this.state.godina_analize : null,
            };
            axios.post('api/field/edit_yearly', { data: data })
                .then(res => {
                    this.props.handleClose();
                })
                .catch(error => {
                    wentWrong('Something went wrong, please try again!');
                    console.log(`Error: ${error}`);
                })
        }
        else wentWrong('Please enter all required fields!');
    }

    render() {
        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e' }} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit lease') : i18n.t('Add Lease')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-layer">
                            <div style={{ marginLeft: '10px' }}>
                                <Grid container justify="space-between" alignContent="space-between" >

                                    <Grid xs={6}>
                                        <TextField
                                            label={i18n.t('Contract number')}
                                            value={this.state.broj_ugovora}
                                            style={{ width: '256px', marginBottom: '10px', marginTop: '10px' }}
                                            InputLabelProps={this.state.broj_ugovora ? { shrink: true } : null}
                                            onChange={(e) => { this.setState({ broj_ugovora: e.target.value }); }} >
                                        </TextField>
                                    </Grid>

                                    <Grid xs={6}>
                                        <TextField required
                                            type="number"
                                            label={i18n.t('Land area')}
                                            value={this.state.povrsina}
                                            style={{ width: '256px', marginBottom: '10px', marginTop: '10px' }}
                                            inputProps={{ readOnly: true }}
                                            onChange={(e) => { this.setState({ povrsina: e.target.value }); }} >
                                        </TextField>
                                    </Grid>

                                    <Grid xs={4}>
                                        <TextField
                                            type="number"
                                            label={i18n.t('Lease value')}
                                            value={this.state.zakupnina}
                                            style={{ width: '164px', marginBottom: '10px', marginTop: '10px' }}
                                            InputLabelProps={this.state.zakupnina ? { shrink: true } : null}
                                            onChange={(e) => { this.setState({ zakupnina: e.target.value }); }} >
                                        </TextField>
                                    </Grid>

                                    <Grid xs={4}>
                                        <TextField
                                            type="number"
                                            label={i18n.t('Amount payed')}
                                            value={this.state.zakupnina_jm}
                                            style={{ width: '164px', marginBottom: '10px', marginTop: '10px' }}
                                            InputLabelProps={this.state.zakupnina_jm ? { shrink: true } : null}
                                            onChange={(e) => { this.setState({ zakupnina_jm: e.target.value }); }} >
                                        </TextField>
                                    </Grid>

                                    <Grid xs={4}>
                                        <TextField
                                            type="number"
                                            label={i18n.t('Year of field analysis')}
                                            value={this.state.godina_analize}
                                            style={{ width: '164px', marginBottom: '10px', marginTop: '10px' }}
                                            InputLabelProps={this.state.godina_analize ? { shrink: true } : null}
                                            onChange={(e) => { this.setState({ godina_analize: e.target.value }); }} >
                                        </TextField>
                                    </Grid>

                                    <Grid xs={4}>
                                        <TextField
                                            type="number"
                                            label={i18n.t('Cultivated')}
                                            value={this.state.obradjeno}
                                            style={{ width: '164px', marginBottom: '10px', marginTop: '10px' }}
                                            InputLabelProps={this.state.obradjeno ? { shrink: true } : null}
                                            onChange={(e) => { this.setState({ obradjeno: e.target.value }); }} >
                                        </TextField>
                                    </Grid>

                                    <Grid xs={4}>
                                        <TextField
                                            type="number"
                                            label={i18n.t('Abandoned')}
                                            value={this.state.zapusteno}
                                            style={{ width: '164px', marginBottom: '10px', marginTop: '10px' }}
                                            InputLabelProps={this.state.zapusteno ? { shrink: true } : null}
                                            onChange={(e) => { this.setState({ zapusteno: e.target.value }); }} >
                                        </TextField>
                                    </Grid>

                                    <Grid xs={4}>
                                        <TextField
                                            type="number"
                                            label={i18n.t('Usurped')}
                                            value={this.state.uzurpirano}
                                            style={{ width: '164px', marginBottom: '10px', marginTop: '10px' }}
                                            InputLabelProps={this.state.uzurpirano ? { shrink: true } : null}
                                            onChange={(e) => { this.setState({ uzurpirano: e.target.value }); }} >
                                        </TextField>
                                    </Grid>

                                    <Grid xs={4}>
                                        <Button variant="contained" style={{ marginLeft: '15%', marginBottom: '15px' }} color="primary"
                                            onClick={() => {
                                                let povrsina = Number(this.state.povrsina);
                                                let obradjeno = Number(this.state.obradjeno);
                                                let zapusteno = Number(this.state.zapusteno);
                                                let uzurpirano = Number(this.state.uzurpirano);

                                                if (zapusteno > povrsina || uzurpirano > povrsina) {
                                                    if (zapusteno > povrsina) {
                                                        wentWrong(i18n.t('Abandoned area cant be more than land area value'));
                                                    }
                                                    if (uzurpirano > povrsina) {
                                                        wentWrong(i18n.t('Usurped area cant be more than land area value'));
                                                    }
                                                }
                                                else if ((zapusteno + uzurpirano) > povrsina) {
                                                    wentWrong(i18n.t('Sum of abandoned and usurped area cant be more than land area value'));
                                                }
                                                else {
                                                    if (zapusteno !== '') {
                                                        if (uzurpirano !== '') {
                                                            let total = zapusteno + uzurpirano;
                                                            let result = povrsina - total;
                                                            this.setState({ obradjeno: result.toFixed(4) });
                                                        }
                                                        else {
                                                            let total = zapusteno;
                                                            let result = povrsina - total;
                                                            this.setState({ obradjeno: result.toFixed(4) });
                                                        }
                                                    }
                                                    else {
                                                        if (uzurpirano !== '') {
                                                            let total = uzurpirano;
                                                            let result = povrsina - total;
                                                            this.setState({ obradjeno: result.toFixed(4) });
                                                        }
                                                        else {
                                                            let result = povrsina;
                                                            this.setState({ obradjeno: result.toFixed(4) });
                                                        }
                                                    }
                                                }
                                            }}
                                        >{i18n.t('Calculate')}</Button>
                                    </Grid>

                                    <Grid xs={4}>
                                        <Button variant="contained" style={{ marginLeft: '15%', marginBottom: '15px' }} color="primary"
                                            onClick={() => {
                                                let povrsina = Number(this.state.povrsina);
                                                let obradjeno = Number(this.state.obradjeno);
                                                let zapusteno = Number(this.state.zapusteno);
                                                let uzurpirano = Number(this.state.uzurpirano);

                                                if (obradjeno > povrsina || uzurpirano > povrsina) {
                                                    if (obradjeno > povrsina) {
                                                        wentWrong(i18n.t('Cultivated area cant be more than land area value'));
                                                    }
                                                    if (uzurpirano > povrsina) {
                                                        wentWrong(i18n.t('Usurped area cant be more than land area value'));
                                                    }
                                                }
                                                else if ((obradjeno + uzurpirano) > povrsina) {
                                                    wentWrong(i18n.t('Sum of cultivated and usurped area cant be more than land area value'));
                                                }
                                                else {
                                                    if (obradjeno !== '') {
                                                        if (uzurpirano !== '') {
                                                            let total = obradjeno + uzurpirano;
                                                            let result = povrsina - total;
                                                            this.setState({ zapusteno: result.toFixed(4) });
                                                        }
                                                        else {
                                                            let total = obradjeno;
                                                            let result = povrsina - total;
                                                            this.setState({ zapusteno: result.toFixed(4) });
                                                        }
                                                    }
                                                    else {
                                                        if (uzurpirano !== '') {
                                                            let total = uzurpirano;
                                                            let result = povrsina - total;
                                                            this.setState({ zapusteno: result.toFixed(4) });
                                                        }
                                                        else {
                                                            let result = povrsina;
                                                            this.setState({ zapusteno: result.toFixed(4) });
                                                        }
                                                    }
                                                }
                                            }}
                                        >{i18n.t('Calculate')}</Button>
                                    </Grid>

                                    <Grid xs={4}>
                                        <Button variant="contained" style={{ marginLeft: '15%', marginBottom: '15px' }} color="primary"
                                            onClick={() => {
                                                let povrsina = Number(this.state.povrsina);
                                                let obradjeno = Number(this.state.obradjeno);
                                                let zapusteno = Number(this.state.zapusteno);
                                                let uzurpirano = Number(this.state.uzurpirano);

                                                if (obradjeno > povrsina || zapusteno > povrsina) {
                                                    if (obradjeno > povrsina) {
                                                        wentWrong(i18n.t('Cultivated area cant be more than land area value'));
                                                    }
                                                    if (zapusteno > povrsina) {
                                                        wentWrong(i18n.t('Abandoned area cant be more than land area value'));
                                                    }
                                                }
                                                else if ((obradjeno + zapusteno) > povrsina) {
                                                    wentWrong(i18n.t('Sum of cultivated and abandoned area cant be more than land area value'));
                                                }
                                                else {
                                                    if (obradjeno !== '') {
                                                        if (zapusteno !== '') {
                                                            let total = obradjeno + zapusteno;
                                                            let result = povrsina - total;
                                                            this.setState({ uzurpirano: result.toFixed(4) });
                                                        }
                                                        else {
                                                            let total = obradjeno;
                                                            let result = povrsina - total;
                                                            this.setState({ uzurpirano: result.toFixed(4) });
                                                        }
                                                    }
                                                    else {
                                                        if (zapusteno !== '') {
                                                            let total = zapusteno;
                                                            let result = povrsina - total;
                                                            this.setState({ uzurpirano: result.toFixed(4) });
                                                        }
                                                        else {
                                                            let result = povrsina;
                                                            this.setState({ uzurpirano: result.toFixed(4) });
                                                        }
                                                    }
                                                }
                                            }}
                                        >{i18n.t('Calculate')}</Button>
                                    </Grid>

                                    <Grid xs={12}>
                                        <FormControlLabel
                                            style={{}}
                                            control={<Switch
                                                checked={this.state.jesenja}
                                                onChange={() => this.setState({ jesenja: !this.state.jesenja })}
                                                name="checkedB"
                                                color="primary" />}
                                            label={i18n.t('Autumn sowing')}
                                        />
                                    </Grid>

                                    <Grid xs={12}>
                                        <FormControlLabel
                                            style={{}}
                                            control={<Switch
                                                checked={this.state.analiza_plodnosti}
                                                onChange={() => this.setState({ analiza_plodnosti: !this.state.analiza_plodnosti })}
                                                name="checkedB"
                                                color="primary" />}
                                            label={i18n.t('Fertility analysis')}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                    </Grid>
                                </Grid>
                                <Button variant="contained" style={{ margin: '15px 0px' }} color="primary"
                                    onClick={() => this.addField()}
                                >{i18n.t('Edit')}</Button>
                            </div>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
};