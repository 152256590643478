import React from 'react';
import { connect } from 'react-redux';
import i18n from '../i18n/i18n';
import { withStyles } from '@material-ui/core/styles';
import {
    Button, DialogActions, TextField, IconButton,
    Dialog, DialogContent, DialogTitle, Tooltip
} from '@material-ui/core';
import classNames from 'classnames';
import { green, red, blue, lightBlue } from '@material-ui/core/colors';
const styles = theme => ({
    row: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between'
    },
    buttons: {
        display: 'flex',
        flexFlow: 'row wrap',
        maxWidth: '240px'
    },
    disabledButton: {
        backgroundColor: 'lightGray',
    },
    xlsx: {
        color: green[500],
        '&:hover': {
            color: green[700],
        }
    },
    csv: {
        color: blue[500],
        '&:hover': {
            color: blue[700],
        }
    },
    txt: {
        color: lightBlue[500],
        '&:hover': {
            color: lightBlue[700],
        }
    },
    pdf: {
        color: red[500],
        '&:hover': {
            color: red[700],
        }
    }
});

export class Export extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            format: 'xlsx'
        }
    }
    componentDidMount() {
        this.onFormatClick('xlsx')
    }

    handleChangeValue = name => event => {
        this.setState({ [name]: event.target.value });
        this.props.onTextChange({ [name]: event.target.value })
    };

    onFormatClick = (format) => {
        this.setState({ format });
        this.props.onformatChange && this.props.onformatChange(format);
    }

    render() {
        const { classes, changeFormat } = this.props;
        const { format } = this.state;
        return (
            <Dialog
                open={this.props.export}
                onClose={this.props.handleClose}
                fullWidth={true}
                disableBackdropClick
                maxWidth="sm"
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{i18n.t('Export')}</DialogTitle>
                <DialogContent className={classes.row}>
                    <TextField
                        required
                        label={i18n.t('Document title')}
                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                        value={this.state.title}
                        onChange={this.handleChangeValue('title')}
                    />
                    {changeFormat && <div className={classes.buttons}>
                        <Tooltip title='XLSX'>
                            <IconButton
                                className={classes.button}
                                classes={{ disabled: classes.disabledButton }}
                                aria-label="xlsx"
                                onClick={() => this.onFormatClick('xlsx')}
                                disabled={format === 'xlsx'}>
                                <span className={classNames("icon-file-excel", classes.xlsx)} />
                            </IconButton>
                        </Tooltip>
                        {/* <Tooltip title='CSV'>
                            <IconButton
                                className={classes.button}
                                classes={{ disabled: classes.disabledButton }}
                                aria-label="csv"
                                onClick={() => this.onFormatClick('csv')}
                                disabled={format === 'csv'}>
                                <span className={classNames("icon-csv-file", classes.csv)} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='TXT'>
                            <IconButton
                                className={classes.button}
                                classes={{ disabled: classes.disabledButton }}
                                aria-label="txt"
                                onClick={() => this.onFormatClick('txt')}
                                disabled={format === 'txt'}>
                                <span className={classNames("icon-txt-file", classes.txt)} />
                            </IconButton>
                        </Tooltip> */}
                        <Tooltip title='PDF'>
                            <IconButton
                                className={classes.button}
                                classes={{ disabled: classes.disabledButton }}
                                aria-label="pdf"
                                onClick={() => this.onFormatClick('pdf')}
                                disabled={format === 'pdf'}>
                                <span className={classNames("icon-pdf-file", classes.pdf)} />
                            </IconButton>
                        </Tooltip>
                    </div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.exportDoc} style={{ backgroundColor: '#58B4E5', color: 'white' }}>
                        {i18n.t('Ok')}
                    </Button>
                    <Button onClick={this.props.handleClose} style={{ backgroundColor: '#58B4E5', color: 'white' }}>
                        {i18n.t('Cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(Export));