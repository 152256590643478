import React from 'react';
import { connect } from 'react-redux';
import i18n from '../i18n/i18n';
import {
    Button, DialogActions, TextField,
    Dialog, DialogContent, DialogTitle
} from '@material-ui/core';

export class ExportEnterprise extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: ''
        }
    }

    handleChangeValue = name => event => {
        this.setState({ [name]: event.target.value });
        this.props.onTextChange({ [name]: event.target.value })
    };

    render() {
        return (
            <Dialog
                open={this.props.export}
                onClose={this.props.handleClose}
                fullWidth={true}
                disableBackdropClick
                maxWidth="sm"
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{i18n.t('Export')}</DialogTitle>
                <DialogContent>
                    <TextField
                        required
                        label={i18n.t('Document title')}
                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                        value={this.state.title}
                        onChange={this.handleChangeValue('title')}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.props.exportDoc}
                        variant="contained"
                        color="primary">
                        {i18n.t('Ok')}
                    </Button>
                    <Button
                        onClick={this.props.handleClose}
                        variant="contained"
                        color="primary">
                        {i18n.t('Cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExportEnterprise);