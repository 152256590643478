import React from 'react';
import { connect } from 'react-redux';
import i18n from '../i18n/i18n';
import {
    Button, DialogActions,
    Dialog, DialogContent, DialogTitle
} from '@material-ui/core';

export class SignActivity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <Dialog
                open={this.props.sign}
                onClose={this.props.handleClose}
                disableBackdropClick
                maxWidth="sm"
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{i18n.t('Sign activity')}</DialogTitle>
                <DialogContent>
                    {i18n.t('Are you sure you want to sign this activity?')}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.signActivity} style={{ backgroundColor: '#61a50e', color: 'white' }}>
                        {i18n.t('Ok')}
                    </Button>
                    <Button onClick={this.props.handleClose} style={{ backgroundColor: '#61a50e', color: 'white' }}>
                        {i18n.t('Cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignActivity);