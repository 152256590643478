import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import 'react-table/react-table.css';
import {
    getMyFarmOffices, getLocalGov, getTitle, getMaritalStatus, verifyBVN,
    getIdentificationDoc, setInitialState, setRowInReducer
} from '../../actions/FarmRegistarActions';
import { getStates } from '../../actions/AdministrationActions';
import i18n from '../../i18n/i18n';
import Select from 'react-select';
import immutable from 'seamless-immutable';
import { withRouter } from 'react-router-dom'
import { Fab } from '@material-ui/core';
import AddFarmers from './AddFarmers';
import FarmersInformations from './FarmersInformations';
import DatePickerForFilter from '../../utils/DatePickerForFilter';
import { exportDocument } from '../../actions/ExportActions';
import Export from '../../utils/Export';
import ProfilePreview from '../../utils/ProfilePreview';
import DashboardDrawer from '../../utils/DashboardDrawer';
import moment from 'moment';
import { getUser } from '../../utils/CommonFunctions';
import { withStyles } from '@material-ui/core/styles';
const CheckboxTable = checkboxHOC(ReactTable);
const styles = theme => ({
    primaryMain: {
        backgroundColor: theme.palette.primary.main,
    },
    center: {
        textAlign: 'center'
    },
    starButton: {
        padding: '3px',
    },
    card: {
        height: 500,
        width: 600,
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column'
    }
});

export class ViewMyFarmers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            add: false,
            selection: [],
            selectAll: false,
            profile: false,
            farmers: [],
            deleteAnchor: false,
            user: {},
            editActive: false,
            verifyBVNPopup: false
        };
        this.tableScrollTop = 0;
    }

    componentDidMount() {
        let genders = [
            { label: i18n.t('Male'), value: i18n.t('Male') },
            { label: i18n.t('Female'), value: i18n.t('Female') }
        ]
        this.props.getStates();
        this.props.getLocalGov();
        this.props.getTitle();
        this.props.getMaritalStatus();
        this.props.getIdentificationDoc();
        this.setState({
            user: getUser(),
            genders
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.farmPostingSuccess === true) {
            this.setState({
                add: false
            })
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getMyFarmOffices(wrapped.state);
            this.props.setInitialState('farmPostingSuccess');
        }
        this.setState({
            myFarmers: nextProps.myFarmers,
            myFarmersFetching: nextProps.myFarmersFetching,
            states: immutable.asMutable(nextProps.states.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            title: immutable.asMutable(nextProps.title.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            maritalStatus: immutable.asMutable(nextProps.maritalStatus.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            identificationDoc: immutable.asMutable(nextProps.identificationDoc.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            localGoverments: immutable.asMutable(nextProps.localGoverments.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            statesFetching: nextProps.statesFetching,
            statesFetchingFailed: nextProps.statesFetchingFailed,
        })
        if (nextProps.addAnchorSuccess === true) {
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getMyFarmOffices(wrapped.state);
            this.props.setInitialState('addAnchorSuccess')
        }
        if (nextProps.deleteAnchorSuccess === true) {
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getMyFarmOffices(wrapped.state);
            this.props.setInitialState('deleteAnchorSuccess')
        }
    }

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    handleClick = () => {
        this.setState({ add: !this.state.add }, () => {
            this.props.setInitialState('myFarmersObj');
            this.setState({
                selection: [],
                name: '',
                selectAll: false,
            })
        });
    };

    onExportClick = () => {
        this.setState({ export: !this.state.export });
    }

    exportFunction = () => {
        const table = this.checkboxTable.getWrappedInstance();
        this.props.exportDocument(this.state.docTitle, table.state, 'api/farmRegistar/read', this.state.docFormat || 'xlsx');
        this.setState({ export: !this.state.export });
    }


    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
            this.props.setInitialState('myFarmersObj');
        } else {
            selection.push(key);
        }
        this.setState({ selection }, function () {
            if (selection.length == 1) {
                let row1 = this.props.myFarmers.filter((farm) => {
                    return farm.id == selection;
                })
                this.props.setRowInReducer(row1[0], 'myFarmersObj');
            }
        });
    };
    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });
    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    onProfileClick = () => {
        this.setState({ profile: !this.state.profile });
    }

    onPieClick = () => {
        this.props.history.push('/farmer_dashboard')
    }

    onTableClick = () => {
        this.props.history.push('/farmer');
    }


    handleChangeDropdown = selectedOption => {
        this.setState({ usersSelected: selectedOption });
    }

    onEditClick = () => {
        this.setState({ editActive: !this.state.editActive })
    }

    renderImage = () => {
        const { farmerImage } = this.props;
        if (farmerImage === 'none') {
            return null;
        } else {
            return <img src={`data:image/jpeg;charset=utf-8;base64,${farmerImage}`} height="450" width="350" style={{ padding: '10px' }} />
        }
    }

    render() {
        const { myFarmersObj } = this.props;
        const { selectAll, user, editActive } = this.state;
        const { toggleSelection, toggleAll, isSelected } = this;
        const columns = [
            {
                Header: i18n.t('Title'),
                accessor: 'titula_naziv',
                className: 'tr-head-50',
                headerClassName: 'tr-head-50'
            },
            {
                Header: i18n.t('Contact person'),
                accessor: 'kontakt_osoba',
                className: 'tr-head-150',
                headerClassName: 'tr-head-150'
            },
            {
                Header: i18n.t('Phone'),
                accessor: 'kontakt_telefon'
            },
            {
                Header: i18n.t('Address'),
                accessor: 'ulica_i_broj'
            },
            {
                Header: i18n.t('Email'),
                accessor: 'kontakt_mejl'
            },
            {
                Header: i18n.t('State'),
                accessor: 'opstina_naziv',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.states}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Local government'),
                accessor: 'katastarska_opstina_naziv',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.localGoverments}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Marital status'),
                accessor: 'bracni_status_naziv',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.maritalStatus}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Identification document'),
                accessor: 'identifikacioni_dokument_naziv',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.identificationDoc}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Place of birth'),
                accessor: 'mesto_rodjenja_naziv',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.states}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                id: 'placeOfBirth',
                Header: i18n.t('Date of birth'),
                accessor: d => d.datum_rodjenja ? moment(d.datum_rodjenja, 'DD.MM.YYYY').format('DD.MM.YYYY') : '',
                Filter: ({ filter, onChange }) =>
                    <DatePickerForFilter
                        onChange={({ startDate, endDate }) => {
                            onChange({ startDate, endDate });
                        }}

                    />
            },
            {
                Header: i18n.t('Gender'),
                accessor: 'pol',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.genders}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />

            },
            {
                Header: i18n.t('BVN'),
                accessor: 'bpg',
                show: user.id_klijent_grupa == 14 ? false : true,
            },
            {
                Header: i18n.t('Cluster'),
                accessor: 'naziv_cos',
            },
            {
                Header: i18n.t('Branch'),
                accessor: 'naziv_branch',
            }
        ];
        // if (this.props.location.state.showAnchor === false) { columns.shift(); }

        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "lightgreen" : "inherit"
                        }
                    };
                }
                return {};
            }
        };
        return (
            <div className="page">
                <div className="table-header">
                    {user.id_klijent_grupa != 14 &&
                        <Fab
                            onClick={() => this.handleClick()}
                            color="primary"
                            aria-label="add"
                            className="action-button"
                        >
                            <span className="icon-Plus headerIcon" />
                        </Fab>
                    }
                    <Fab
                        onClick={() => this.onExportClick()}
                        color="primary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-download headerIcon" />
                    </Fab>
                    <Fab
                        onClick={() => this.onSearchClick()}
                        color="primary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-search headerIcon" />
                    </Fab>
                    {/* {(user.id_klijent_grupa === 14) && this.state.selection.length === 1 &&
                        <Fab
                            onClick={() => this.onEditClick()}
                            color="primary"
                            aria-label="edit"
                            className="action-button"
                        >
                            <span className=" icon-Izmeni1 headerIcon" />
                        </Fab>
                    } */}
                    {this.state.selection.length === 1 &&
                        <Fab
                            onClick={() => this.onProfileClick()}
                            color="primary"
                            aria-label="edit"
                            className="action-button"
                        >
                            <span className=" icon-Informacija headerIcon" />
                        </Fab>
                    }
                </div>
                <CheckboxTable
                    ref={r => (this.checkboxTable = r)}
                    data={this.props.myFarmers}
                    getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                    columns={columns}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.props.myFarmersPages}
                    loading={this.props.myFarmersFetching}
                    manual
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => this.props.getMyFarmOffices(state, instance)}
                    className="-striped -highlight table-drawer"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                />
                <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} />
                {this.state.add && <AddFarmers add={this.state.add}
                    handleClose={this.handleClick} />}
                {this.state.export && <Export
                    export={this.state.export}
                    onTextChange={({ title }) => {
                        this.setState({ docTitle: title });
                    }}
                    changeFormat
                    onformatChange={(format) => {
                        this.setState({ docFormat: format });
                    }}
                    handleClose={this.onExportClick}
                    exportDoc={this.exportFunction} />
                }
                {this.state.profile &&
                    <ProfilePreview
                        profile={this.state.profile}
                        handleClose={this.onProfileClick}
                        clientId={this.state.selection[0]}
                        contactPerson={this.props.myFarmersObj.kontakt_osoba}
                        clientTitle={this.props.myFarmersObj.naziv}
                        showFarmersInfo={true}
                    />}
                {editActive && <FarmersInformations id_klijent={this.state.selection[0]} editActive={editActive} myFarmersObj={myFarmersObj} handleClose={this.onEditClick} />}
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        myFarmers: state.farmRegistarReducer.myFarmers,
        myFarmersObj: state.farmRegistarReducer.myFarmersObj,
        farmPostingSuccess: state.farmRegistarReducer.farmPostingSuccess,
        myFarmersFetching: state.farmRegistarReducer.myFarmersFetching,
        myFarmersPages: state.farmRegistarReducer.myFarmersPages,
        localGoverments: state.farmRegistarReducer.localGoverments,
        localGovermentsFetching: state.farmRegistarReducer.localGovermentsFetching,
        localGovermentsFetchingFailed: state.farmRegistarReducer.localGovermentsFetchingFailed,
        title: state.farmRegistarReducer.title,
        titleFetching: state.farmRegistarReducer.titleFetching,
        titleFetchingFailed: state.farmRegistarReducer.titleFetchingFailed,
        maritalStatus: state.farmRegistarReducer.maritalStatus,
        maritalStatusFetching: state.farmRegistarReducer.maritalStatusFetching,
        maritalStatusFetchingFailed: state.farmRegistarReducer.maritalStatusFetchingFailed,
        identificationDoc: state.farmRegistarReducer.identificationDoc,
        identificationDocFetching: state.farmRegistarReducer.identificationDocFetching,
        identificationDocFetchingFailed: state.farmRegistarReducer.identificationDocFetchingFailed,
        states: state.administrationReducer.states,
        statesFetching: state.administrationReducer.statesFetching,
        statesFetchingFailed: state.administrationReducer.statesFetchingFailed,
        verifyBVNFailed: state.farmRegistarReducer.verifyBVNFailed,
        verifyBVNSuccess: state.farmRegistarReducer.verifyBVNSuccess,
        bvnImage: state.farmRegistarReducer.bvnImage,
        verifyBVNFetching: state.farmRegistarReducer.verifyBVNFetching,
        farmerImage: state.farmRegistarReducer.farmerImage

    }
}

function mapDispatchToProps(dispatch) {
    return {
        getStates: () => dispatch(getStates()),
        getLocalGov: () => dispatch(getLocalGov()),
        getTitle: () => dispatch(getTitle()),
        setInitialState: (component) => dispatch(setInitialState(component)),
        setRowInReducer: (rowInfo, name) => dispatch(setRowInReducer(rowInfo, name)),
        getIdentificationDoc: () => dispatch(getIdentificationDoc()),
        getMaritalStatus: () => dispatch(getMaritalStatus()),
        getMyFarmOffices: (state, instance) => dispatch(getMyFarmOffices(state, instance)),
        exportDocument: (title, state, url, format) => dispatch(exportDocument(title, state, url, format)),
        verifyBVN: (bvn) => dispatch(verifyBVN(bvn))

    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewMyFarmers)));