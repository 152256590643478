import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import i18n from '../../i18n/i18n';
import Select from 'react-select';
import { Fab, IconButton } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import { wentWrong } from "../../utils/ToastConfig";
import axios from '../../utils/AxiosWrapper';
import ReactSelect from './../../utils/ReactSelect';
import DatePickerForFilter from '../../utils/DatePickerForFilter';
import NumberRangeForFilter from '../../utils/NumberRangeForFilter';
import { ExportEnterprise } from '../../utils/ExportEnterprise';
import Export from '../../utils/Export';
import moment from 'moment';

var FileSaver = require('file-saver');

export default class Statistics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            regionType: [], institutionType: [], seasonType: [], stateType: [],
            woType: [], cropType: [], fieldType: [], vpType: [], materialType: [],
            showFilters: false, switchFlag: false, loading: false,
            page: 0, pageSize: 25, sorted: null, filtered: null,
            selection: [], selectAll: false, selectedRow: [],
            totalPages: 0, totalData: 0, user_season: null,
            add: false, edit: false, delete: false, export: false, docFormat: '',
            data: [], user: [],
        };
    }

    componentDidMount() {
        let user = this.getUser();
        this.setState({ user });

        axios.get('api/season/drop_down')
            .then(res => {
                this.setState({ seasonType: res.data.data }, () => {
                    axios.get('api/season/activity_season')
                        .then(res => this.setState({ user_season: res.data.data[0].id_default_sezona, loading: false }, () => {
                            this.getByURL('opstina/drop_down', 'stateType');
                            this.getByURL('field/drop_down', 'fieldType');
                            this.getByURL('working_operation/drop_down', 'woType');
                            this.getByURL('crop/drop_down', 'cropType');
                            this.getByURL('vrsta_proizvodnje/drop_down', 'vpType');
                            this.getByURL('material/drop_down', 'materialType');
                        }))
                        .catch(err => { console.log(err) })
                })
            })
            .catch(err => { console.log(err) })
    }

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    getData(url, state, instance, props) {
        this.setState({ loading: true, data: [] });
        let user = this.getUser();
        axios.get(`api/${url}`, {
            params: { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered, region: user.id_region, id_klijent: this.props.id_klijent }
        })
            .then(res => {
                this.setState({
                    data: res.data.data,
                    totalPages: res.data.total_pages,
                    totalData: res.data.total,
                }, () => {
                    if (this.state.user_season !== null) this.setState({ loading: false })
                });
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: res.data.data }))
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    };

    exportFunction = () => {
        const table = this.reactTable;
        this.exportDocument(this.state.docTitle, table.state, 'api/report/farmer_statistic', [], this.state.docFormat || 'xlsx');
        this.setState({ export: !this.state.export });
    };

    exportDocument(title, state, url, filters, format) {
        let params = { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered, filename: title, format: format };
        if (filters) params = { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered, filename: title, filters, format: format };

        return axios({ url: url, method: 'GET', responseType: 'blob', params: params })
            .then(response => FileSaver.saveAs(new Blob([response.data]), `${title || 'file'}.${format || 'xlsx'}`))
            .catch(err => console.log(err))
    };

    onSearchClick = () => this.setState({ showFilters: !this.state.showFilters });
    onExportClick = () => this.setState({ export: !this.state.export });

    render() {
        let materialType = this.state.materialType.map((item) => { return { label: item.naziv, value: item.id } });
        let seasonType = this.state.seasonType.map((item) => { return { label: item.naziv, value: item.id } });
        let stateType = this.state.stateType.map((item) => { return { label: item.naziv, value: item.id } });
        let fieldType = this.state.fieldType.map((item) => { return { label: item.naziv, value: item.id } });
        let cropType = this.state.cropType.map((item) => { return { label: item.naziv, value: item.id } });
        let woType = this.state.woType.map((item) => { return { label: item.naziv, value: item.id } });
        let vpType = this.state.vpType.map((item) => { return { label: item.naziv, value: item.id } });
        let seasons = seasonType.map(item => item.value);

        const columns = [
            // { Header: i18n.t('Activity'), accessor: 'id_radni_nalog' },
            {
                Header: i18n.t('Season'), accessor: 'sezona',
                Filter: ({ filter, onChange }) => {
                    return <ReactSelect
                        filter
                        isMulti
                        name="label"
                        onChange={value => { onChange(value) }}
                        defaultValue={seasonType.filter((element) => {
                            if (seasons.includes(this.state.user_season)) return element.value === this.state.user_season;
                        })}
                        closeMenuOnSelect={false}
                        options={seasonType}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
                }
            },
            { Header: i18n.t('Title'), accessor: 'klijent_naziv', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={stateType} /> },
            { id: 'datum_pocetka', Header: i18n.t('Start date'), accessor: d => d.datum_pocetka ? moment(d.datum_pocetka, 'DD.MM.YYYY').format('DD.MM.YYYY') : '', Filter: ({ filter, onChange }) => <div style={{}}> <DatePickerForFilter onChange={({ startDate, endDate }) => onChange({ startDate, endDate })} /> </div> },
            { id: 'datum_zavrsetka', Header: i18n.t('End date'), accessor: d => d.datum_zavrsetka ? moment(d.datum_zavrsetka, 'DD.MM.YYYY').format('DD.MM.YYYY') : '', Filter: ({ filter, onChange }) => <div style={{}}>  <DatePickerForFilter onChange={({ startDate, endDate }) => onChange({ startDate, endDate })} />  </div> },
            { Header: i18n.t('Working operation'), accessor: 'radna_operacija_naziv', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={woType} /> },
            { Header: i18n.t('Crop'), accessor: 'kultura_naziv', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={cropType} /> },
            { Header: i18n.t('Field'), accessor: 'tabla' },
            { Header: i18n.t('Type of production'), accessor: 'naziv_proizvodnje', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={vpType} /> },
            { Header: i18n.t('Cultivated area'), accessor: 'povrsina', Filter: ({ filter, onChange }) => <NumberRangeForFilter onChange={(s) => onChange(s)} /> },
            { Header: i18n.t('Material'), accessor: 'materijal_naziv', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={materialType} /> },
            { Header: i18n.t('Consumption'), accessor: 'utroseno', Filter: ({ filter, onChange }) => <NumberRangeForFilter onChange={(s) => onChange(s)} /> },
            { Header: i18n.t('Yield'), accessor: 'prinos', Filter: ({ filter, onChange }) => <NumberRangeForFilter onChange={(s) => onChange(s)} /> },
        ];

        const url = 'report/farmer_statistic';
        const checkboxProps = { getTrProps: (state, rowInfo, instance) => { if (rowInfo) { return { style: { backgroundColor: "inherit" } }; } return {}; } };

        const filterOptions = {
            defaultFiltered: [
                {
                    id: 'sezona',
                    value: seasonType.filter((element) => {
                        if (seasons.includes(this.state.user_season)) return element.value === this.state.user_season;
                    })
                }
            ]
        };

        return (
            <div className="page">
                {this.props.isStatisticShown ?
                    <div>
                        <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('Farmer report')}
                            <IconButton onClick={this.props.handleClose}>
                                <span className="icon-cancel-circle closeIcon" />
                            </IconButton>
                        </DialogTitle>
                    </div>
                    : null}
                <div className="table-header">
                    <Fab onClick={() => this.onSearchClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button">
                        <span className="icon-search headerIcon" />
                    </Fab>
                    <Fab onClick={() => this.onExportClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button">
                        <span className="icon-download headerIcon" />
                    </Fab>
                </div>
                <ReactTable
                    manual
                    ref={r => (this.reactTable = r)}
                    data={this.state.data}
                    paging={true}
                    columns={columns}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.state.totalPages}
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => { this.getData(url, state, instance, this.props); }}
                    loading={this.state.loading}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    style={{ display: 'contents' }}
                    {...checkboxProps}
                    {...filterOptions}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF',
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
                {/* {this.state.export && <Export
                    export={this.state.export}
                    onTextChange={({ title }) => { this.setState({ docTitle: title }); }}
                    handleClose={this.onExportClick}
                    exportDoc={this.exportFunction} />
                } */}
                {this.state.export && <Export
                    export={this.state.export}
                    onTextChange={({ title }) => { this.setState({ docTitle: title }); }}
                    changeFormat
                    onformatChange={(format) => { this.setState({ docFormat: format }); }}
                    handleClose={this.onExportClick}
                    exportDoc={this.exportFunction} />
                }
            </div>
        )
    }
};

function FilterSelect(props) {
    return <Select
        isMulti
        name="naziv"
        onChange={value => { props.onChange(value) }}
        closeMenuOnSelect={false}
        options={props.type}
        className="multi-select-in-filter"
        classNamePrefix="select"
        placeholder={i18n.t('Select')}
    />
}