import React from 'react';
import { connect } from 'react-redux';
import i18n from '../i18n/i18n';
import { DateRangePicker } from "react-dates";

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { format } from 'date-fns';
import {
    Grow, Popper, ClickAwayListener, Input, Fab
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

function formatDateDisplay(date, defaultText) {
    if (!date) return defaultText;
    return format(date, 'DD/MM/YYYY');
}
const styles = theme => ({
    div: {
        display: 'flex',
        flexFlow: 'row wrap',
        position: 'relative'
    },
    input: {
        width: '50%',
        minWidth: '100px',
        fontSize: '13px'
    },
    button: {
        position: 'absolute',
        right: 0,
        height: '25px',
        width: '25px',
        minHeight: '25px',
        top: '50%',
        transform: 'translate( 0%, -50%)',
    }
})
export class DatePickerForFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            dateRange: {
                selection: {
                    startDate: null,
                    endDate: null,
                    key: 'selection',
                },
            }
        }
    }
    // onChange = ({ startDate, endDate }) => {
    //     this.setState({ startDate, endDate })
    //     startDate = startDate ? startDate.format('MM.DD.YYYY') : null;
    //     endDate = endDate ? endDate.format('MM.DD.YYYY') : null;
    //     // onChange({ startDate, endDate });
    //     this.props.onChange({ startDate, endDate });

    // }

    handleRangeChange(which, payload) {
        // console.log('fffff', which, payload);
        this.setState({
            [which]: {
                ...this.state[which],
                ...payload,
            },
        });
        let startDate = payload.selection.startDate ? format(payload.selection.startDate, 'MM.DD.YYYY') : null,
            endDate = payload.selection.endDate ? format(payload.selection.endDate, 'MM.DD.YYYY') : null;
        this.props.onChange({ startDate, endDate });
    }

    handleClick = event => {
        this.setState(state => ({ open: !state.open }));
    };

    handleClose = event => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }
        this.setState({ open: false });
    };
    handleClear = () => {
        this.setState({
            dateRange: {
                ...this.state['dateRange'],
                ...{
                    selection: {
                        startDate: null,
                        endDate: null,
                        key: 'selection'
                    }
                }
            }
        });
        this.props.onChange({ startDate: null, endDate: null });
    }

    render() {
        const { classes } = this.props;
        const { open } = this.state;
        return (
            <div>
                {/* <DateRangePicker
                    startDate={this.state.startDate}
                    startDateId="START_DATE"
                    endDate={this.state.endDate}
                    endDateId="END_DATE"
                    readOnly={true}
                    onDatesChange={({ startDate, endDate }) => this.onChange({ startDate, endDate })}
                    focusedInput={this.state.focusedInput}
                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                    numberOfMonths={1}
                    minimumNights={0}
                    isOutsideRange={() => false}
                    customArrowIcon={' '}
                    showClearDates
                    displayFormat="DD.MM.YYYY"
                    startDatePlaceholderText={i18n.t('Start date')}
                    endDatePlaceholderText={i18n.t('End date')}
                /> */}
                <div className={classes.div}>
                    <Input
                        className={classes.input}
                        type="text"
                        readOnly
                        onClick={this.handleClick}
                        value={formatDateDisplay(this.state.dateRange.selection.startDate, i18n.t('Start date'))}
                    />
                    <Input
                        className={classes.input}
                        type="text"
                        readOnly
                        onClick={this.handleClick}
                        inputRef={node => {
                            this.anchorEl = node;
                        }}
                        value={formatDateDisplay(this.state.dateRange.selection.endDate, i18n.t('End date'))}
                    />
                    <Fab
                        className={classes.button}
                        onClick={this.handleClear} >
                        <Icons.Clear />
                    </Fab>
                </div>

                <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            id="menu-list-grow"
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <ClickAwayListener onClickAway={this.handleClose}>
                                <DateRange
                                    onChange={this.handleRangeChange.bind(this, 'dateRange')}
                                    ranges={[this.state.dateRange.selection]}
                                    className={'PreviewArea'}
                                    showDateDisplay={false}
                                />
                            </ClickAwayListener>
                        </Grow>
                    )}
                </Popper>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(DatePickerForFilter));