/**
 * Created by pajicv on 7/9/18.
 */

import React from 'react';

import { connect } from 'react-redux';

import * as LayerSelectors from '../../../selectors/LayerSelectors';

import axios from '../../../utils/AxiosWrapper';

import { Map, Polyline, TileLayer } from 'react-leaflet';

import { IconButton, DialogTitle } from '@material-ui/core';
import i18next from '../../../i18n/i18n';
class GPSReport extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            trajectories: {},
            mapCenter: {
                lat: 9,
                lng: 7
            }
        }

    }

    render() {

        const { trajectories, mapCenter } = this.state;

        const coords = Object.keys(trajectories).map(trajectoryId => trajectories[trajectoryId].map(point => [point.lat, point.lon]));

        return (
            <div className="gps-report-panel">
                <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18next.t('GPS report')}
                    <IconButton onClick={this.props.handleClose}>
                        <span className="icon-cancel-circle closeIcon" />
                    </IconButton>
                </DialogTitle>
                <div className="gps-report-map-container">
                    <Map center={[mapCenter.lat, mapCenter.lng]} zoom={5} minZoom={4} maxZoom={18} maxBoundsViscosity={0.75}>
                        <TileLayer
                            attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Polyline positions={coords} color="#ff8800" />
                    </Map>
                </div>
            </div >
        )
    }

    componentDidMount() {

        const self = this;;;

        axios.get(`api/gps/klijent_putanja?id_klijent=${self.props.idClient}`)
            .then(function (response) {

                const trajectories = response.data.data;

                const firstKey = Object.keys(trajectories).shift();

                const firstPoint = trajectories[firstKey][0];

                self.setState({ mapCenter: { lat: firstPoint.lat, lng: firstPoint.lon }, trajectories: response.data.data })

            })
            .catch(function (error) {
                console.log(error);
            });

    }

};

function mapStateToProps(state) {
    return {
        mapBounds: LayerSelectors.getMapBounds(state)
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GPSReport);