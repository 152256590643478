export const styles = {
    list_item: { backgroundColor: '#e6e6e6', borderRightStyle: 'solid', borderRightWidth: '1px', borderRightColor: '#e6e6e6' },
    list: { width: '100%', borderTopStyle: 'solid', borderTopWidth: '1px', borderTopColor: '#e6e6e6', padding: '0px' },
    pressed: { backgroundColor: '#FFFFFF', '&:focus': { backgroundColor: '#e6e6e6' }, borderRightStyle: 'solid', borderRightWidth: '1px', borderRightColor: '#e6e6e6' },
    pressedLast: { backgroundColor: '#FFFFFF', borderBottomStyle: 'solid', borderBottomWidth: '1px', borderBottomColor: '#e6e6e6', '&:focus': { backgroundColor: '#e6e6e6' } },
    tab: { width: '150px', color: 'white', fontSize: '18px', backgroundColor: '#04764e' },
    tab_work: { width: 'auto', color: 'white', fontSize: '18px', backgroundColor: '#04764e' },
    pressedTab: { width: '150px', color: 'white', fontSize: '18px', backgroundColor: '#046d48' },
    pressedTab_work: { width: 'auto', color: 'white', fontSize: '18px', backgroundColor: '#046d48' },
    group: { borderBottomStyle: 'solid', borderBottomWidth: '1px', borderBottomColor: '#e6e6e6', borderRightStyle: 'solid', borderRightWidth: '1px', borderRightColor: '#e6e6e6' },
    page: { flex: '8', height: '80vh', overflow: 'auto', backgroundColor: 'white' },
    drawer: { flex: '2', height: '80vh', overflow: 'auto' },
    collapse: { borderBottomStyle: 'solid', borderBottomWidth: '1px', borderBottomColor: '#e6e6e6' },
    subheader: { position: 'static', borderBottomStyle: 'solid', borderBottomWidth: '1px', borderBottomColor: '#e6e6e6' },
    container: { display: 'flex', overflow: 'hidden' }
};