import axios from '../../utils/AxiosWrapper';
export const Type = {
    //GROUP
    GET_MATERIAL_GROUP_DROPDOWN_CALL: 'GET_MATERIAL_GROUP_DROPDOWN_CALL',
    GET_MATERIAL_GROUP_DROPDOWN_SUCCESS: 'GET_MATERIAL_GROUP_DROPDOWN_SUCCESS',
    GET_MATERIAL_GROUP_DROPDOWN_FAILED: 'GET_MATERIAL_GROUP_DROPDOWN_FAILED',
    //SUBGROUP
    GET_MATERIAL_SUBGROUP_DROPDOWN_CALL: 'GET_MATERIAL_SUBGROUP_DROPDOWN_CALL',
    GET_MATERIAL_SUBGROUP_DROPDOWN_SUCCESS: 'GET_MATERIAL_SUBGROUP_DROPDOWN_SUCCESS',
    GET_MATERIAL_SUBGROUP_DROPDOWN_FAILED: 'GET_MATERIAL_SUBGROUP_DROPDOWN_FAILED',
    //MATERIAL DROPDOWN
    GET_MATERIAL_DROPDOWN_CALL: 'GET_MATERIAL_DROPDOWN_CALL',
    GET_MATERIAL_DROPDOWN_SUCCESS: 'GET_MATERIAL_DROPDOWN_SUCCESS',
    GET_MATERIAL_DROPDOWN_FAILED: 'GET_MATERIAL_DROPDOWN_FAILED',
    //ADD
    ADD_MATERIAL_CALL: 'ADD_MATERIAL_CALL',
    ADD_MATERIAL_SUCCESS: 'ADD_MATERIAL_SUCCESS',
    ADD_MATERIAL_FAILED: 'ADD_MATERIAL_FAILED',
    //VIEW
    GET_MATERIAL_CALL: 'GET_MATERIAL_CALL',
    GET_MATERIAL_SUCCESS: 'GET_MATERIAL_SUCCESS',
    GET_MATERIAL_FAILED: 'GET_MATERIAL_FAILED',

    GET_MATERIAL_GROUP_CALL: 'GET_MATERIAL_GROUP_CALL',
    GET_MATERIAL_GROUP_SUCCESS: 'GET_MATERIAL_GROUP_SUCCESS',
    GET_MATERIAL_GROUP_FAILED: 'GET_MATERIAL_GROUP_FAILED',

    ADD_MATERIAL_GROUP_CALL: 'ADD_MATERIAL_GROUP_CALL',
    ADD_MATERIAL_GROUP_SUCCESS: 'ADD_MATERIAL_GROUP_SUCCESS',
    ADD_MATERIAL_GROUP_FAILED: 'ADD_MATERIAL_GROUP_FAILED',

    GET_MATERIAL_SUBGROUP_CALL: 'GET_MATERIAL_SUBGROUP_CALL',
    GET_MATERIAL_SUBGROUP_SUCCESS: 'GET_MATERIAL_SUBGROUP_SUCCESS',
    GET_MATERIAL_SUBGROUP_FAILED: 'GET_MATERIAL_SUBGROUP_FAILED',

    ADD_MATERIAL_SUBGROUP_CALL: 'ADD_MATERIAL_SUBGROUP_CALL',
    ADD_MATERIAL_SUBGROUP_SUCCESS: 'ADD_MATERIAL_SUBGROUP_SUCCESS',
    ADD_MATERIAL_SUBGROUP_FAILED: 'ADD_MATERIAL_SUBGROUP_FAILED',

    SET_MATERIAL_ROW_IN_REDUCER: 'SET_MATERIAL_ROW_IN_REDUCER',

    DELETE_MATERIAL_CALL: 'DELETE_MATERIAL_CALL',
    DELETE_MATERIAL_SUCCESS: 'DELETE_MATERIAL_SUCCESS',
    DELETE_MATERIAL_FAILED: 'DELETE_MATERIAL_FAILED',
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',


};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function setRowInReducer(rowInfo, name) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_MATERIAL_ROW_IN_REDUCER,
            rowInfo: rowInfo,
            name: name
        })
    }
}

export function getMaterialDropdown(id_podgrupa) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_MATERIAL_DROPDOWN_CALL
        });

        axios.get(`api/material/drop_down${id_podgrupa ? '?id_podgrupa=' +id_podgrupa:''}`)
            .then(function (response) {
                dispatch({
                    type: Type.GET_MATERIAL_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MATERIAL_DROPDOWN_FAILED
                });
            });
    }
}

export function getMaterialGroup() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_MATERIAL_GROUP_DROPDOWN_CALL
        });

        axios.get('api/material_group/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_MATERIAL_GROUP_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MATERIAL_GROUP_DROPDOWN_FAILED
                });
            });
    }
}

export function getMaterijalGroups(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_MATERIAL_GROUP_CALL
        });
        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/material_group/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_MATERIAL_GROUP_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MATERIAL_GROUP_FAILED
                });
            });
    }
}

export function addMaterialGroup(materialGroupObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_MATERIAL_GROUP_CALL
        });

        axios.post('api/material_group/create', materialGroupObj)
            .then(function (response) {
                dispatch({
                    type: Type.ADD_MATERIAL_GROUP_SUCCESS,
                    data: response.data
                });
                dispatch(getMaterialGroup());
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_MATERIAL_GROUP_FAILED
                });
            });
    }
}

export function getMaterialSubgroup(id_grupa) {
    return (dispatch) => {
        dispatch({
            type: Type.GET_MATERIAL_SUBGROUP_DROPDOWN_CALL
        });

        axios.get(`api/material_subgroup/drop_down${id_grupa ? '?id_grupa=' + id_grupa:''}`)
            .then(function (response) {
                dispatch({
                    type: Type.GET_MATERIAL_SUBGROUP_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MATERIAL_SUBGROUP_DROPDOWN_FAILED
                });
            });
    }
}

export function getMaterijalSubgroups(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_MATERIAL_SUBGROUP_CALL
        });
        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/material_subgroup/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_MATERIAL_SUBGROUP_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MATERIAL_SUBGROUP_FAILED
                });
            });
    }
}

export function addMaterialSubgroup(materialSubgroupObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_MATERIAL_SUBGROUP_CALL
        });

        axios.post('api/material_subgroup/create', materialSubgroupObj)
            .then(function (response) {
                dispatch({
                    type: Type.ADD_MATERIAL_SUBGROUP_SUCCESS,
                    data: response.data
                });
                dispatch(getMaterialSubgroup());
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_MATERIAL_SUBGROUP_FAILED
                });
            });
    }
}


export function addMaterial(materialObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_MATERIAL_CALL
        });

        axios.post('api/material/create', materialObj)
            .then(function (response) {
                dispatch({
                    type: Type.ADD_MATERIAL_SUCCESS,
                    data: response.data
                });
                dispatch(getMaterialGroup());
                dispatch(getMaterialSubgroup());
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_MATERIAL_FAILED
                });
            });
    }
}

export function getMaterial(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_MATERIAL_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/material/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_MATERIAL_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MATERIAL_FAILED
                });
            });
    }
}

export function deleteMaterial(selection, name) {
    return (dispatch) => {

        dispatch({
            type: Type.DELETE_MATERIAL_CALL,
        });

        axios.post(`api/${name}/delete`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_MATERIAL_SUCCESS,
                    data: response.data,
                    name: name,
                    forDelete: selection
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_MATERIAL_FAILED
                });
            });
    }
}