import React from 'react';
import {
    GridList, GridListTile, GridListTileBar,
    IconButton
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import axios from './AxiosWrapper';
var FileSaver = require('file-saver');

export class DocumentGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onDownloadClick = (path, naziv) => {
        axios({
            url: `api/document/download?path=${path}&naziv=${naziv}`,
            method: 'GET',
            responseType: 'blob'
        })
            .then(function (response) {
                FileSaver.saveAs(new Blob([response.data]), naziv);

            })
            .catch(function (error) {
                console.log(error);
            });
    }
    onDeleteClick = (document) => {
        this.props.deleteDoc(document);
    }

    render() {
        const { item } = this.props;
        var pathParts = item.dokument.split('.');
        var extension = pathParts[pathParts.length - 1];
        var sourcee

        if (extension.toLowerCase() == 'jpg' || extension.toLowerCase() == 'jpeg' || extension.toLowerCase() == 'png' || extension.toLowerCase() == 'apng' || extension.toLowerCase() == 'bmp' || extension.toLowerCase() == 'gif' || extension.toLowerCase() == 'svg' || extension.toLowerCase() == 'bmp ico' || extension.toLowerCase() == 'png ico') {
            sourcee = item.signedURL;
        } else {
            sourcee = require('../assets/images/document.png');
        }

        return (
            item &&
            <GridList className='documentGridList'>
                <GridListTile className='documentGridList' key={item.dokument}>
                    <img src={sourcee} alt={item.dokument} />
                    <GridListTileBar
                        // title={item.naziv}
                        className='documentTileBar'
                        // subtitle={<span>by: {tile.author}</span>}
                        actionIcon={
                            <div>
                                <IconButton onClick={() => this.onDownloadClick(item.dokument, item.dokument)}>
                                    <Icons.CloudDownload style={{ color: 'white' }} />
                                </IconButton>
                                {this.props.showDelete &&
                                    <IconButton onClick={() => this.onDeleteClick(item)}>
                                        <Icons.Delete style={{ color: 'white' }} />
                                    </IconButton>}
                            </div>
                        }
                    />
                </GridListTile>
            </GridList>
        );
    }
}

export default DocumentGrid;