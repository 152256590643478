import React from 'react';
import i18n from '../../../i18n/i18n';
import axios from '../../../utils/AxiosWrapper';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import DatePickerMeteo from '../../../utils/DatePickerMeteo';
import { formatData, formatDisease } from './../DataFormatters';
import { withStyles } from '@material-ui/core/styles';
import { MoonLoader } from 'react-spinners';
import MeteoTable from '../MeteoTable';

const styles = {};

export class AppleCodlingMoth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            station_id: this.props.row.uid,
            startDate: moment().subtract(1, 'days'),
            endDate: moment(),
            disease1: {},
            disease2: {},
            data: {},
            loadDisease: true,
            loadData: true
        };
    }

    setDates = (start, end) => { this.setState({ startDate: start, endDate: end }, () => { this.getPessl(); }); }

    // Get funkcije za preuzimanje podataka sa stanica samo po potrebi ubaciti, pored Disease-a uvek mora i Data zbog datuma
    getDisease = (path, disease) => {
        let obj = {};
        axios.post('/api/testMeteo/meteo', path)
            .then(res => { obj = formatDisease(res, obj); this.setState({ [disease]: obj, loadDisease: false }); })
            .catch(err => console.log('Error: ', err))
    }

    getData = (path) => {
        axios.post('/api/testMeteo/meteo', path)
            .then(res => { let obj = formatData(res); this.setState({ data: obj, loadData: false }); })
            .catch(err => console.log('Error: ', err))
    }

    getPessl = () => {
        let start_date = moment(this.state.startDate).unix();
        let end_date = moment(this.state.endDate).unix();
        let station_id = this.state.station_id;

        // URL-ovi za podatke sa meteo stanica
        let disease_path = '/data/' + station_id + '/hourly/from/' + start_date + '/to/' + end_date;
        let data_path = '/data/' + station_id + '/hourly/from/' + start_date + '/to/' + end_date;

        // Disease mora POST method, za Data i Forecast mora GET method
        this.getDisease({ path: disease_path, name: "Apple/CodlingMoth", method: "POST" }, 'disease1');
        this.getData({ path: data_path, method: "GET" });
    }

    render() {

        let graph1 = {};
        let graph1a = {};
        let graph2 = {};

        const options1 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        ticks: {
                            fixedStepSize: 1,
                            beginAtZero: true,
                            min: 0,
                            max: 5
                        }
                    },
                ],
            },
        };
        const options1a = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        ticks: {
                            fixedStepSize: 1,
                            beginAtZero: true,
                            min: 0,
                            max: 6
                        }

                    },
                ],
            },
        };

        const options2 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-5',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[°C]'
                        },
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-6',
                        gridLines: {
                            drawOnChartArea: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[%]'
                        },

                    },
                ],
            },
        };

        if (
            this.state.data.dates &&
            this.state.disease1 &&
            this.state.disease2
        ) {
            graph1 = {
                labels: this.state.data.dates,
                datasets: [
                    // {
                    //     data: this.state.disease1['codling_moth'],
                    //     label: i18n.t('Codling Moth Sum'),
                    //     backgroundColor: 'transparent',
                    //     borderColor: 'red',
                    //     type: 'line',
                    //     borderWidth: 2,
                    //     pointRadius: this.state.data.dates.length > 72 ? 0 : 2,
                    //     yAxisID: 'y-axis-1',
                    // },
                    {
                        data: this.state.disease1['codling_moth_adult'],
                        label: i18n.t('Codling Moth Adult'),
                        type: 'line',
                        backgroundColor: 'transparent',
                        borderColor: 'blue',
                        borderWidth: 2,
                        pointRadius: this.state.data.dates.length > 72 ? 0 : 2,
                        yAxisID: 'y-axis-1',
                    },
                    // {
                    //     data: this.state.disease1['larval_instars'],
                    //     label: i18n.t('First Larve Instar'),
                    //     type: 'line',
                    //     backgroundColor: 'transparent',
                    //     borderColor: 'green',
                    //     borderWidth: 2,
                    //     pointRadius: this.state.data.dates.length > 72 ? 0 : 2,
                    //     yAxisID: 'y-axis-1',
                    // }
                ],
            };

            graph1a = {
                labels: this.state.data.dates,
                datasets: [
                    // {
                    //     data: this.state.disease1['codling_moth'],
                    //     label: i18n.t('Codling Moth Sum'),
                    //     backgroundColor: 'transparent',
                    //     borderColor: 'red',
                    //     type: 'line',
                    //     borderWidth: 2,
                    //     pointRadius: this.state.data.dates.length > 72 ? 0 : 2,
                    //     yAxisID: 'y-axis-1',
                    // },
                    {
                        data: this.state.disease1['larval_instars'],
                        label: i18n.t('Larval Instars'),
                        type: 'line',
                        backgroundColor: 'transparent',
                        borderColor: 'red',
                        borderWidth: 2,
                        pointRadius: this.state.data.dates.length > 72 ? 0 : 2,
                        yAxisID: 'y-axis-1',
                    },
                    // {
                    //     data: this.state.disease1['larval_instars'],
                    //     label: i18n.t('First Larve Instar'),
                    //     type: 'line',
                    //     backgroundColor: 'transparent',
                    //     borderColor: 'green',
                    //     borderWidth: 2,
                    //     pointRadius: this.state.data.dates.length > 72 ? 0 : 2,
                    //     yAxisID: 'y-axis-1',
                    // }
                ],
            };
            graph2 = {
                labels: this.state.data.dates,
                datasets: [
                    {
                        data: this.state.data.temperatures,
                        label: i18n.t('Temperature [°C]'),
                        backgroundColor: 'transparent',
                        borderColor: 'red',
                        type: 'line',
                        borderWidth: 2,
                        pointRadius: this.state.data.dates.length > 72 ? 0 : 2,
                        yAxisID: 'y-axis-5',
                    },
                    {
                        data: this.state.data.humidity,
                        label: i18n.t('Relative humidity [%]'),
                        type: 'line',
                        backgroundColor: 'transparent',
                        borderColor: '#00BFFF',
                        borderWidth: 2,
                        pointRadius: this.state.data.dates.length > 72 ? 0 : 2,
                        yAxisID: 'y-axis-6',
                    },
                ],
            };
        };

        return (
            <div style={{ paddingLeft: '35px' }}>
              <div style= {{display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                        <p style={{fontSize: 20, marginRight: 4 }}>{i18n.t('Codling Moth')}</p>
                        <p style={{fontSize: 20, fontStyle: 'italic'}}>(Cydia pomonella)</p>
                        </div>
                </div>  
                <DatePickerMeteo startDate={this.state.startDate} endDate={this.state.endDate} setDates={this.setDates} getPessl={this.getPessl} />
                {(this.state.loadDisease === true || this.state.loadData === true) && <div style={{ marginLeft: '39%', marginTop: '14%' }}> <MoonLoader loading={this.state.loadDisease === true || this.state.loadData === true} size={100} /></div>}
                {this.state.loadDisease === false && this.state.loadData === false &&
                    <div>

                        <div style={{ width: '95%', marginTop: '10px' }}>
                            <Bar data={graph1} height={300} width={600} options={options1} />
                        </div>
                        
                        <div style={{ padding: '20px', borderRadius: '8px', boxShadow: ' 0 5px 10px rgba(154, 160, 185, 0.2), 0 15px 40px rgba(166, 173, 201, 0.1)', backgroundColor: 'white !important', marginRight: '35px' }}>
                            <p style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Info:</p>
                            <p>{i18n.t('1 - Flight activity, non-egg laying')}</p>
                            <p>{i18n.t('2 - Flight activity with possible egg laying')}</p>
                            <p>{i18n.t('3 - Flight activity with some egg laying')}</p>
                            <p>{i18n.t('4 - Flight activity with reasonable to strong egg laying')}</p>
                            <p>{i18n.t('5 - Flight activity with strong egg laying')}</p>
                        </div>


                        <div style={{ width: '95%', marginTop: '80px' }}>
                            <Bar data={graph1a} height={300} width={600} options={options1a} />
                        </div>
                        
                        <div style={{ padding: '20px', borderRadius: '8px', boxShadow: ' 0 5px 10px rgba(154, 160, 185, 0.2), 0 15px 40px rgba(166, 173, 201, 0.1)', backgroundColor: 'white !important', marginRight: '35px' }}>
                            <p style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Info:</p>
                            <p>{i18n.t('In optimal weather conditions, the egg develops into a caterpillar of the first larval stage in 7-14 days, which get into the fruit.')}</p>
                            <p>{i18n.t('After the caterpillar in the fruit goes through all the developmental stages, the fully developed caterpillar leaves the fruit and looks for a place to spent the winter.')}</p>
                        </div>
                    
                        <div style={{ width: '95%', marginTop: '80px', }}>
                            <Bar data={graph2} height={200} width={400} options={options2} />
                        </div>
                        <MeteoTable data={{
                            headers: [i18n.t('Date and time'), i18n.t('Temperature [°C]'), i18n.t('Relative humidity [%]')],
                            dates: this.state.data.dates,
                            temperatures: this.state.data.temperatures,
                            relative_humidity: this.state.data.humidity
                        }} />
                          <div style={{ marginTop: '20px' }}></div>
                    </div>
                }
            </div>
        );
    }
};

export default withStyles(styles)(AppleCodlingMoth);