import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setInitialState, copyResources, getCopyFrom, getCopyTo } from '../../../actions/control_panel/RegionActions';

export class CopyRegion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    copyRegions() {
        this.props.copyResources(this.state.from, this.state.to)
        // ne kopirati iz srbije za pogonske masine, pogonske masine su dobre za nigeriju i republiku srpsku
    };

    componentDidMount() {
        this.props.getCopyFrom();
        this.props.getCopyTo();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            copyFrom: nextProps.copyFrom,
            copyFromFetching: nextProps.copyFromFetching,

            copyTo: nextProps.copyTo,
            copyToFetching: nextProps.copyToFetching,
        })
    }

    handleChangeValue = name => event => {
        let obj = Object.assign({}, this.state.regionObj);
        obj[name] = event.target.value;
        this.setState({ regionObj: obj });
    };

    render() {

        let copyFrom = [];
        if (this.props.copyFrom && !this.props.copyFromFetching) {
            copyFrom = this.props.copyFrom.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }

        let copyTo = [];
        if (this.props.copyTo && !this.props.copyToFetching) {
            copyTo = this.props.copyTo.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }

        return (
            <Dialog open={this.props.open}
                onClose={this.props.onClose}
                fullWidth={true}
                disableBackdropClick
                maxWidth="xs"
                aria-labelledby="responsive-dialog-title">
                <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('Copy region')}
                    <IconButton onClick={this.props.onClose}>
                        <span className="icon-cancel-circle closeIcon" />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <form id="add-region">
                        <Grid container justify="space-between" alignContent="space-between" >
                            <Grid item xs={12} sm={12} >
                                <TextField select
                                    label={i18n.t('Copy from region')}
                                    onChange={(e) => this.setState({ from: e.target.value })}
                                    SelectProps={{ native: true }}
                                    required
                                    margin="normal"
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}>
                                    <option value='' />
                                    {copyFrom}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12} >
                                <TextField select
                                    label={i18n.t('Copy to region')}
                                    onChange={(e) => this.setState({ to: e.target.value })}
                                    SelectProps={{ native: true }}
                                    required
                                    margin="normal"
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}>
                                    <option value='' />
                                    {copyTo}
                                </TextField>
                            </Grid>
                            <Grid>
                                <Button style={{ alignSelf: 'center', backgroundColor: '#58B4E5', color: 'white', marginTop: '20px', marginBottom: '10px' }}
                                    onClick={() => this.copyRegions()}>
                                    {i18n.t('Copy')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
        )
    }
};

function mapStateToProps(state) {
    return {
        copyFrom: state.regionReducer.copyFrom,
        copyFromFetching: state.regionReducer.copyFromFetching,

        copyTo: state.regionReducer.copyTo,
        copyToFetching: state.regionReducer.copyToFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInitialState: (component) => dispatch(setInitialState(component)),
        getCopyFrom: (state, instance) => dispatch(getCopyFrom(state, instance)),
        getCopyTo: (state, instance) => dispatch(getCopyTo(state, instance)),
        copyResources: (from, to) => dispatch(copyResources(from, to)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CopyRegion);