import immutable from 'seamless-immutable';
import { Type as LayerType } from '../../actions/control_panel/LayerActions';
export const INITIAL_STATE = immutable({
    layerPosting: false,
    layerPostingSuccess: false,
    layerPostingFailed: false,

    layer: [],
    layerObj: {},
    layerFetching: false,
    layerPages: 1,

    regionType: [],
    regionTypeFetching: false,
    regionTypeFetchingFailed: false,

    seasonType: [],
    seasonTypeFetching: false,
    seasonTypeFetchingFailed: false,

    deleted: false,
    deleteLayerFailed: false,
});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case LayerType.SET_INITIAL_STATE: {
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;
        }

        case LayerType.ADD_LAYER_CALL: {
            const layerPosting = true;
            return state.merge({ layerPosting });
            break;
        }

        case LayerType.ADD_LAYER_SUCCESS: {
            const layerPosting = false;
            const layerPostingSuccess = true;
            return state.merge({ layerPostingSuccess, layerPosting, layerObj: INITIAL_STATE.layerObj })
            break;
        }

        case LayerType.ADD_LAYER_FAILED: {
            const layerPosting = false;
            const layerPostingFailed = true;
            return state.merge({ layerPosting, layerPostingFailed });
            break;
        }

        case LayerType.GET_LAYER_READ_CALL: {
            const layerFetching = true;
            return state.merge({ layerFetching });
            break;
        }

        case LayerType.GET_LAYER_READ_SUCCESS: {
            const layer = action.data.data;
            const layerFetching = false;
            const layerPages = action.data.total_pages
            return state.merge({ layer, layerFetching, layerPages });
            break;
        }

        case LayerType.GET_LAYER_READ_FAILED: {
            const layerFetchingFailed = true;
            const layerFetching = false;
            return state.merge({ layerFetching, layerFetchingFailed });
            break;
        }

        case LayerType.GET_REGION_TYPE_CALL: {
            const regionTypeFetching = true;
            return state.merge({ regionTypeFetching });
            break;
        }

        case LayerType.GET_REGION_TYPE_SUCCESS: {
            const regionType = action.data.data;
            const regionTypeFetching = false;
            return state.merge({ regionType, regionTypeFetching });
            break;
        }

        case LayerType.GET_REGION_TYPE_FAILED: {
            const regionTypeFetchingFailed = true;
            const regionTypeFetching = false;
            return state.merge({ regionTypeFetching, regionTypeFetchingFailed });
            break;
        }

        case LayerType.GET_SEASON_TYPE_CALL: {
            const seasonTypeFetching = true;
            return state.merge({ seasonTypeFetching });
            break;
        }

        case LayerType.GET_SEASON_TYPE_SUCCESS: {
            const seasonType = action.data.data;
            const seasonTypeFetching = false;
            return state.merge({ seasonType, seasonTypeFetching });
            break;
        }

        case LayerType.GET_SEASON_TYPE_FAILED: {
            const seasonTypeFetchingFailed = true;
            const seasonTypeFetching = false;
            return state.merge({ seasonTypeFetching, seasonTypeFetchingFailed });
            break;
        }

        case LayerType.DELETE_LAYER_SUCCESS: {
            const array = state['layer'].asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ layer: array, deleted: deleted })
            break;
        }

        case LayerType.DELETE_LAYER_FAILED: {
            const deleteLayerFailed = true;
            return state.merge({ deleteLayerFailed });
            break;
        }

        case LayerType.SET_LAYER_ROW_IN_REDUCER: {
            const data = action.rowInfo;
            return state.merge({ layerObj: data })
            break;
        }

        default:
            return state;
    }
}