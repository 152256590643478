import axios from '../../utils/AxiosWrapper';
export const Type = {

    //ADD
    ADD_PUBLIC_PROCUREMENT_CALL: 'ADD_PUBLIC_PROCUREMENT_CALL',
    ADD_PUBLIC_PROCUREMENT_SUCCESS: 'ADD_PUBLIC_PROCUREMENT_SUCCESS',
    ADD_PUBLIC_PROCUREMENT_FAILED: 'ADD_PUBLIC_PROCUREMENT_FAILED',
    //VIEW
    GET_VIEW_PUBLIC_PROCUREMENT_CALL: 'GET_VIEW_PUBLIC_PROCUREMENT_CALL',
    GET_VIEW_PUBLIC_PROCUREMENT_SUCCESS: 'GET_VIEW_PUBLIC_PROCUREMENT_SUCCESS',
    GET_VIEW_PUBLIC_PROCUREMENT_FAILED: 'GET_VIEW_PUBLIC_PROCUREMENT_FAILED',

    GET_BASIS_OF_LEASE_DROP_DOWN_CALL: 'GET_BASIS_OF_LEASE_DROP_DOWN_CALL',
    GET_BASIS_OF_LEASE_DROP_DOWN_SUCCESS: 'GET_BASIS_OF_LEASE_DROP_DOWN_SUCCESS',
    GET_BASIS_OF_LEASE_DROP_DOWN_FAILED: 'GET_BASIS_OF_LEASE_DROP_DOWN_FAILED',
    //DELETE
    DELETE_PUBLIC_PROCUREMENT_CALL: 'DELETE_PUBLIC_PROCUREMENT_CALL',
    DELETE_PUBLIC_PROCUREMENT_SUCCESS: 'DELETE_PUBLIC_PROCUREMENT_SUCCESS',
    DELETE_PUBLIC_PROCUREMENT_FAILED: 'DELETE_PUBLIC_PROCUREMENT_FAILED',
    //DASHBOAR
    GET_PUBLIC_PROCUREMENT_DASHBOARD_CALL: 'GET_PUBLIC_PROCUREMENT_DASHBOARD_CALL',
    GET_PUBLIC_PROCUREMENT_DASHBOARD_SUCCESS: 'GET_PUBLIC_PROCUREMENT_DASHBOARD_SUCCESS',
    GET_PUBLIC_PROCUREMENT_DASHBOARD_FAILED: 'GET_PUBLIC_PROCUREMENT_DASHBOARD_FAILED',
    //SETTERS
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',

    GET_PUBLIC_PROCUREMENT_BY_ID_CALL: 'GET_PUBLIC_PROCUREMENT_BY_ID_CALL',
    GET_PUBLIC_PROCUREMENT_BY_ID_SUCCESS: 'GET_PUBLIC_PROCUREMENT_BY_ID_SUCCESS',
    GET_PUBLIC_PROCUREMENT_BY_ID_FAILED: 'GET_PUBLIC_PROCUREMENT_BY_ID_FAILED',

    DELETE_CHILD_CALL: 'DELETE_CHILD_CALL',
    DELETE_CHILD_SUCCESS: 'DELETE_CHILD_SUCCESS',
    DELETE_CHILD_FAILED: 'DELETE_CHILD_FAILED',

    UPDATE_CHILD_CALL: 'UPDATE_CHILD_CALL',
    UPDATE_CHILD_SUCCESS: 'UPDATE_CHILD_SUCCESS',
    UPDATE_CHILD_FAILED: 'UPDATE_CHILD_FAILED',
};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function addPublicProcurement(publicProcurementObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_PUBLIC_PROCUREMENT_CALL
        });

        axios.post('api/public_procurement/create', publicProcurementObj)
            .then(function (response) {
                dispatch({
                    type: Type.ADD_PUBLIC_PROCUREMENT_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_PUBLIC_PROCUREMENT_FAILED
                });
            });
    }
}

export function getPublicProcurement(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_VIEW_PUBLIC_PROCUREMENT_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/public_procurement/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_VIEW_PUBLIC_PROCUREMENT_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_VIEW_PUBLIC_PROCUREMENT_FAILED
                });
            });
    }
}


export function getBasisOfLeaseDropDown() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_BASIS_OF_LEASE_DROP_DOWN_CALL
        });

        axios.get('api/basis_of_lease/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_BASIS_OF_LEASE_DROP_DOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_BASIS_OF_LEASE_DROP_DOWN_FAILED
                });
            });
    }
}

export function deletePublicProcurement(selection) {
    return (dispatch) => {

        dispatch({
            type: Type.DELETE_PUBLIC_PROCUREMENT_CALL,
        });

        axios.post(`api/public_procurement/delete`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_PUBLIC_PROCUREMENT_SUCCESS,
                    data: response.data,
                    forDelete: selection
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_PUBLIC_PROCUREMENT_FAILED
                });
            });
    }
}

export function getPublicProcurementDashboards(filters) {
    return (dispatch) => {
        dispatch({
            type: Type.GET_PUBLIC_PROCUREMENT_DASHBOARD_CALL,
        });

        return axios.get('api/public_procurement/dashboard', { params: { filters } })
            .then(function (response) {
                dispatch({
                    type: Type.GET_PUBLIC_PROCUREMENT_DASHBOARD_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_PUBLIC_PROCUREMENT_DASHBOARD_FAILED
                });
            });
    }
}

export function getPublicProcurementById(id) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_PUBLIC_PROCUREMENT_BY_ID_CALL
        });

        axios.get(`api/public_procurement/getById?id=` + id)
            .then(function (response) {
                dispatch({
                    type: Type.GET_PUBLIC_PROCUREMENT_BY_ID_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_PUBLIC_PROCUREMENT_BY_ID_FAILED
                });
            });
    }
}

export function deleteChildFromPublicProcurement(id, pp_id) {
    return (dispatch) => {

        dispatch({
            type: Type.DELETE_CHILD_CALL,
        });
        axios.post(`api/public_procurement/delete_child`, { id })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_CHILD_SUCCESS,
                });
                dispatch(getPublicProcurementById(pp_id))
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_CHILD_FAILED
                });
            });
    }
}

export function updateChild(id, data) {
    return (dispatch) => {
        dispatch({
            type: Type.UPDATE_CHILD_CALL,
        });
        axios.post(`api/public_procurement/update_child`, { id, data })
            .then(function () {
                dispatch(getPublicProcurementById(id));
            })
            .catch(function (error) {
                dispatch({
                    type: Type.UPDATE_CHILD_FAILED
                });
            });
    }
}


