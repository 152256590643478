import immutable from 'seamless-immutable';
import { Type as UnitType } from '../../actions/resources/UnitActions';


export const INITIAL_STATE = immutable({

    unit: [],
    unitFetching: false,
    unitFetchingFailed: false,

});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case UnitType.GET_UNIT_CALL: {
            const unitFetching = true;
            return state.merge({ unitFetching });
            break;
        }

        case UnitType.GET_UNIT_SUCCESS: {
            const unit = action.data.data;
            const unitFetching = false;
            return state.merge({ unit, unitFetching });
            break;
        }

        case UnitType.GET_UNIT_FAILED: {
            const unitFetchingFailed = true;
            const unitFetching = false;
            return state.merge({ unitFetchingFailed, unitFetching });
            break;
        }


        default:
            return state;
    }
}