import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Select from 'react-select';

import { getCropGroups, getGroup, setRowInReducer, setInitialState } from '../../../actions/resources/CropActions';
import i18n from '../../../i18n/i18n';
import immutable from 'seamless-immutable';
import AddCropGroup from './AddCropGroup';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import { getUser } from '../../../utils/CommonFunctions';
const CheckboxTable = checkboxHOC(ReactTable);
export class ViewCropGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            add: this.props.add,
            edit: this.props.edit,
            showFilters: this.props.showFilters,
            selection: [],
            selectAll: false
        };
    }
    CLIENT_GROUP_ID_WITH_PRIORITY_ACCESSOR = 12

    componentDidMount() {
        if (this.props.cropGroupDropdown.length === 0) {
            this.props.getGroup();
        }
        const user = getUser();
        if (user.id_klijent_grupa === this.CLIENT_GROUP_ID_WITH_PRIORITY_ACCESSOR) {
            this.columns = [...this.columns, { Header: i18n.t('Priority'), accessor: 'prioritet'}]
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.deleted === true) {
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getCropGroups(wrapped.state);
            this.setState({
                selection: [],
                selectAll: false,
            })
            this.props.setInitialState('deleted');
        }
        if (nextProps.cropGroupPostingSuccess === true) {
            this.setState({
                selection: [],
                name: '',
                selectAll: false
            })
            this.props.handleClose();
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getCropGroups(wrapped.state);
            this.props.setInitialState('cropGroupPostingSuccess');
        } this.setState({
            add: nextProps.add,
            showFilters: nextProps.showFilters,
            cropGroupDropdown: immutable.asMutable(nextProps.cropGroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            groupFetching: nextProps.groupFetching,
            groupFetchingFailed: nextProps.groupFetchingFailed,
        })
        if (nextProps.add == true && nextProps.edit == false) {
            this.props.setInitialState('cropGroupObj');
            this.setState({
                selection: [],
                name: '',
                selectAll: false
            })
        }
    }


    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            selection.push(key);
        }
        this.setState({ selection }, function () {
            if (selection.length == 1) {
                let row1 = this.props.crop_group.filter((cropGroup) => {
                    return cropGroup.id == selection;
                })
                this.props.setRowInReducer(row1[0], 'cropGroupObj');
            }
        });
        this.props.setSelection(selection, 'crop_group');
    };
    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });
        this.props.setSelection(selection, 'crop_group');

    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    render() {
        let content;
        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "#ACDAF1" : "inherit"
                        }
                    };
                }
                return {};
            }
        };
        content =
            <div className="page">
                <CheckboxTable data={this.props.crop_group}
                    ref={r => (this.checkboxTable = r)}
                    columns={this.columns}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    showPaginationTop
                    keyField={'id'}
                    showPaginationBottom={false}
                    pages={this.props.cropGroupPages}
                    loading={this.props.cropGroupsFetching}
                    manual
                    onFetchData={(state, instance) => this.props.getCropGroups(state, instance)}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF',
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
                {this.state.add && <AddCropGroup add={this.state.add} edit={this.props.edit}
                    handleClose={this.props.handleClose} />}
            </div>
        return (
            <div>
                {content}
            </div>
        )
    }

    columns = [
        {
            Header: i18n.t('Title'),
            accessor: 'naziv',
            Filter: ({ filter, onChange }) =>
                <Select
                    isMulti
                    name="naziv"
                    onChange={value => {
                        onChange(value)
                    }}
                    closeMenuOnSelect={false}
                    options={this.state.cropGroupDropdown}
                    className="multi-select-in-filter"
                    classNamePrefix="select"
                    placeholder={i18n.t('Select')}
                />
        },
        {
            Header: i18n.t('Parcel color'),
            accessor: 'boja_table',
        },
        {
            Header: i18n.t('Icon name'),
            accessor: 'ime_ikonice',
        },
        {
            Header: i18n.t('Color'),
            accessor: 'boja',
        },

    ];
};

function mapStateToProps(state) {
    return {
        cropGroupDropdown: state.cropReducer.cropGroupDropdown,
        groupFetching: state.cropReducer.groupFetching,
        groupFetchingFailed: state.cropReducer.groupFetchingFailed,
        crop_group: state.cropReducer.crop_group,
        cropGroupsFetching: state.cropReducer.cropGroupsFetching,
        cropGroupsFetchingFailed: state.cropReducer.cropGroupsFetchingFailed,
        cropGroupPostingSuccess: state.cropReducer.cropGroupPostingSuccess,
        deleted: state.cropReducer.deleted,
        cropGroupPages: state.cropReducer.cropGroupPages
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getGroup: () => dispatch(getGroup()),
        setInitialState: (component) => dispatch(setInitialState(component)),
        setRowInReducer: (rowInfo, name) => dispatch(setRowInReducer(rowInfo, name)),
        getCropGroups: (state, instance) => dispatch(getCropGroups(state, instance))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewCropGroup)