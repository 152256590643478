import React from 'react';
import { connect } from 'react-redux';
import {
    Button, IconButton, Menu, MenuItem, Typography
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { Doughnut, Bar, Pie } from 'react-chartjs-2';
import { getDashboardItems, getApplicationStatuses } from '../../../actions/financial/ApplicationActions';
import randomColor from 'randomcolor';
import immutable from 'seamless-immutable';
import DashboardDrawer from '../../../utils/DashboardDrawer';
import Select, { components } from 'react-select';
import {
    getLocalGov,
} from '../../../actions/FarmRegistarActions';
import { getStates, getZones } from '../../../actions/AdministrationActions';
import InputLabel from '@material-ui/core/InputLabel';
import 'react-toastify/dist/ReactToastify.css';
import {
    getProgramType
} from '../../../actions/financial/ProgramActions';
import i18n from '../../../i18n/i18n';
import { getUser } from '../../../utils/CommonFunctions';

const IndicatorsContainer = (props) => {
    return (
        <div style={{ background: '#61a50e' }}>
            <components.IndicatorsContainer {...props} />
        </div>
    );
};
export class SubsidiesDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            id_opstina: [],
            id_katastarska_opstina: [],
            id_vrsta_programa: [],
            id_aplikacija_status: [],
            id_zona: []
        };
    }

    componentDidMount() {
        this.props.getDashboardItems({});
        if (this.props.states.length === 0) {
            this.props.getStates();
        }
        if (this.props.localGoverments.length === 0) {
            this.props.getLocalGov();
        }
        if (this.props.appStatuses.length === 0) {
            this.props.getApplicationStatuses();
        }
        if (this.props.programType.length == 0) {
            this.props.getProgramType();
        }
        if (this.props.zones.length === 0) {
            this.props.getZones();
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            zones: immutable.asMutable(nextProps.zones.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            appStatuses: immutable.asMutable(nextProps.appStatuses.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            programType: immutable.asMutable(nextProps.programType.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            states: immutable.asMutable(nextProps.states.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            localGoverments: immutable.asMutable(nextProps.localGoverments.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
        })
    }

    onPieClick = () => {
        this.props.history.push('/subsidies_dashboard');
    }

    onTableClick = () => {
        this.props.history.push('/subsidies');
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleClear = () => {
        this.setState({
            id_zona: [],
            id_opstina: [],
            id_katastarska_opstina: [],
            id_vrsta_programa: [],
            id_aplikacija_status: []
        }, function () {
            this.props.getDashboardItems({});
        });
    };


    handleOk = () => {
        let filters = {};
        filters['id_zona'] = this.state.id_zona.map((item) => {
            return item.value;
        })
        filters['id_opstina'] = this.state.id_opstina.map((item) => {
            return item.value;
        })
        filters['id_katastarska_opstina'] = this.state.id_katastarska_opstina.map((item) => {
            return item.value;
        });
        filters['id_aplikacija_status'] = this.state.id_aplikacija_status.map((item) => {
            return item.value;
        });
        filters['id_vrsta_programa'] = this.state.id_vrsta_programa.map((item) => {
            return item.value;
        });
        this.props.getDashboardItems(filters);
    }

    handleChange = name => selectedOption => {
        this.setState({
            [name]: selectedOption
        })
    }

    render() {
        const { anchorEl } = this.state;
        let programsData = this.programs;
        let zonesData = this.zones;
        let statesData = this.states;
        let localGovData = this.localGov;
        let statusesData = this.statuses;
        let monthsData = this.months;
        let user = getUser();
        if (this.props.dashboardItems && this.props.dashboardItems.programs) {
            programsData.labels = this.props.dashboardItems.programs.labels.asMutable();
            programsData.datasets[0].data = this.props.dashboardItems.programs.count.asMutable();
            programsData.datasets[0].data1 = this.props.dashboardItems.programs.percent.asMutable();
        }
        if (this.props.dashboardItems && this.props.dashboardItems.zones) {
            zonesData.labels = this.props.dashboardItems.zones.labels.asMutable();
            zonesData.datasets[0].data = this.props.dashboardItems.zones.count.asMutable();
            zonesData.datasets[0].data1 = this.props.dashboardItems.zones.percent.asMutable();
        }
        if (this.props.dashboardItems && this.props.dashboardItems.states) {
            statesData.labels = this.props.dashboardItems.states.labels.asMutable();
            statesData.datasets[0].data = this.props.dashboardItems.states.count.asMutable();
            statesData.datasets[0].data1 = this.props.dashboardItems.states.percent.asMutable();
        }
        if (this.props.dashboardItems && this.props.dashboardItems.localGoverments) {
            localGovData.labels = this.props.dashboardItems.localGoverments.labels.asMutable();
            localGovData.datasets[0].data = this.props.dashboardItems.localGoverments.count.asMutable();
            localGovData.datasets[0].data1 = this.props.dashboardItems.localGoverments.percent.asMutable();
        }
        if (this.props.dashboardItems && this.props.dashboardItems.statuses) {
            statusesData.labels = this.props.dashboardItems.statuses.labels.asMutable();
            statusesData.datasets[0].data = this.props.dashboardItems.statuses.count.asMutable();
            statusesData.datasets[0].data1 = this.props.dashboardItems.statuses.percent.asMutable();
        }
        if (this.props.dashboardItems && this.props.dashboardItems.dates) {
            monthsData.labels = this.props.dashboardItems.dates.labels.asMutable();
            monthsData.datasets[0].data = this.props.dashboardItems.dates.count.asMutable();
            monthsData.datasets[1].data = this.props.dashboardItems.dates.amount.asMutable();
        }
        return (
            <div style={{ 'padding': '20px' }}>
                {this.props.dashboardItems.total > 0 &&
                    <div>
                        <Card style={styles.card}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <h1>{i18n.t('Total number of subsidies')} : {this.props.dashboardItems.total} </h1>
                                {!this.props.global &&
                                    <div>
                                        <div className="filter-panel-dashboard">
                                            <IconButton
                                                styles={styles.button}
                                                aria-owns={anchorEl ? 'simple-menu' : null}
                                                aria-haspopup="true"
                                                className="action-button-filter"
                                                onClick={this.handleClick}
                                            >
                                                <span className="icon-search headerIcon" />
                                            </IconButton>
                                        </div>
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={this.handleClose}
                                        >
                                            {user.id_klijent_grupa == 8 &&
                                                <div>
                                                    <InputLabel className="filter-label">{i18n.t('Zones')}:</InputLabel>
                                                    <Select
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        isClearable={false}
                                                        value={this.state.id_zona}
                                                        components={{
                                                            IndicatorsContainer: IndicatorsContainer
                                                        }}
                                                        maxMenuHeight={150}
                                                        menuPlacement='auto'
                                                        onChange={this.handleChange('id_zona')}
                                                        options={this.state.zones}
                                                        className="multi-select-in-filter-dashboards"
                                                        classNamePrefix="select"
                                                        placeholder={i18n.t('Select')}
                                                    />
                                                </div>
                                            }
                                            {(user.id_klijent_grupa == 8 || user.id_klijent_grupa == 9) &&
                                                <div>
                                                    <InputLabel className="filter-label">{i18n.t('States')}:</InputLabel>
                                                    <Select
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        isClearable={false}
                                                        value={this.state.id_opstina}
                                                        components={{
                                                            IndicatorsContainer: IndicatorsContainer
                                                        }}
                                                        maxMenuHeight={150}
                                                        menuPlacement='auto'
                                                        onChange={this.handleChange('id_opstina')}
                                                        options={this.state.states}
                                                        className="multi-select-in-filter-dashboards"
                                                        classNamePrefix="select"
                                                        placeholder={i18n.t('Select')}
                                                    />
                                                </div>
                                            }
                                            {(user.id_klijent_grupa == 8 || user.id_klijent_grupa == 11) &&
                                                <div>
                                                    <InputLabel className="filter-label">{i18n.t('Local governments')}:</InputLabel>
                                                    <Select
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        isClearable={false}
                                                        value={this.state.id_katastarska_opstina}
                                                        components={{
                                                            IndicatorsContainer: IndicatorsContainer
                                                        }}
                                                        maxMenuHeight={150}
                                                        onChange={this.handleChange('id_katastarska_opstina')}
                                                        options={this.state.localGoverments}
                                                        className="multi-select-in-filter-dashboards"
                                                        classNamePrefix="select"
                                                        placeholder={i18n.t('Select')}
                                                    />
                                                </div>
                                            }
                                            <InputLabel className="filter-label">{i18n.t('Type of program')}:</InputLabel>
                                            <Select
                                                isMulti
                                                closeMenuOnSelect={false}
                                                isClearable={false}
                                                components={{
                                                    IndicatorsContainer: IndicatorsContainer
                                                }}
                                                maxMenuHeight={150}
                                                value={this.state.id_vrsta_programa}
                                                onChange={this.handleChange('id_vrsta_programa')}
                                                options={this.state.programType}
                                                className="multi-select-in-filter-dashboards"
                                                classNamePrefix="select"
                                                placeholder={i18n.t('Select')}
                                            />
                                            <InputLabel className="filter-label">{i18n.t('Application status')}:</InputLabel>
                                            <Select
                                                isMulti
                                                closeMenuOnSelect={false}
                                                isClearable={false}
                                                components={{
                                                    IndicatorsContainer: IndicatorsContainer
                                                }}
                                                maxMenuHeight={150}
                                                value={this.state.id_aplikacija_status}
                                                onChange={this.handleChange('id_aplikacija_status')}
                                                options={this.state.appStatuses}
                                                className="multi-select-in-filter-dashboards"
                                                classNamePrefix="select"
                                                placeholder={i18n.t('Select')}
                                            />
                                            <div style={{ padding: '30px' }}>
                                                <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleOk}>{i18n.t('Ok')}</Button>
                                                <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleClose}>{i18n.t('Cancel')}</Button>
                                                <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleClear}>{i18n.t('Clear')}</Button>

                                            </div>
                                        </Menu>
                                    </div>
                                }
                            </div>
                        </Card>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                            <Card className="dashboard-cards" raised={true}>
                                <Typography className="dashboard-title">{i18n.t('Subsidies by Programs')}:</Typography>
                                {this.props.dashboardItems && this.props.dashboardItems.programs &&
                                    <Doughnut data={programsData} options={this.options} />
                                }
                            </Card>
                           
                            <Card className="dashboard-cards" raised={true}>
                                <Typography className="dashboard-title">{i18n.t('Subsidies status')}:</Typography>
                                {this.props.dashboardItems && this.props.dashboardItems.statuses &&
                                    <Doughnut data={statusesData} options={this.options} />
                                }
                            </Card>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {user.id_klijent_grupa == 8 &&

                                <Card className="dashboard-cards" raised={true}>
                                    <Typography className="dashboard-title">{i18n.t('Subsidies by Zones')}:</Typography>
                                    {this.props.dashboardItems && this.props.dashboardItems.zones &&
                                        <Pie data={zonesData} options={this.options} />
                                    }
                                </Card>
                            }
                            {(user.id_klijent_grupa == 8 || user.id_klijent_grupa == 9) &&

                                <Card className="dashboard-cards" raised={true}>
                                    <Typography className="dashboard-title">{i18n.t('Subsidies by States')}:</Typography>
                                    {this.props.dashboardItems && this.props.dashboardItems.states &&
                                        <Pie data={statesData} options={this.options} />
                                    }
                                </Card>
                            }
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {(user.id_klijent_grupa == 8 || user.id_klijent_grupa == 11) &&
                                <Card className="dashboard-cards-full" raised={true}>
                                    <Typography className="dashboard-title">{i18n.t('Subsidies by Local governments')}:</Typography>
                                    {this.props.dashboardItems && this.props.dashboardItems.localGoverments &&
                                        <Pie data={localGovData} options={this.options} />
                                    }
                                </Card>
                            }
                        </div>

                        <Card className="dashboard-cards-full" raised={true}>
                            <Bar
                                data={monthsData}
                                height={100}
                            />
                        </Card>

                        {this.props.global ?
                            null :
                            <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} />
                        }
                    </div>

                }
            </div >
        )
    }
    programsColor = randomColor({
        count: 30
    })

    statesColor = randomColor({
        count: 50
    })

    localGovColor = randomColor({
        count: 50
    })

    zonesColor = randomColor({
        count: 10
    })

    statusesColor = randomColor({
        count: 5
    })

    monthsColor = randomColor()
    amountColor = randomColor()

    programs = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.programsColor,
            hoverBackgroundColor: this.programsColor
        }]
    };
    zones = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.zonesColor,
            hoverBackgroundColor: this.zonesColor
        }]
    };
    states = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.statesColor,
            hoverBackgroundColor: this.statesColor
        }]
    };
    localGov = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.localGovColor,
            hoverBackgroundColor: this.localGovColor
        }]
    };
    statuses = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.statusesColor,
            hoverBackgroundColor: this.statusesColor
        }]
    };

    months = {
        labels: [],
        datasets: [{
            data: [],
            label: i18n.t('Subsidies by month'),
            backgroundColor: this.monthsColor,
            borderColor: this.monthsColor,
            borderWidth: 1,
            hoverBackgroundColor: this.monthsColor,
            hoverBorderColor: this.monthsColor,
        },
        {
            data: [],
            label: i18n.t('Amount(NGN)*million'),
            backgroundColor: this.amountColor,
            borderColor: this.amountColor,
            borderWidth: 1,
            hoverBackgroundColor: this.amountColor,
            hoverBorderColor: this.amountColor,
        }]
    };
    options = {
        legend: {
            position: 'bottom',
            labels: {
                boxWidth: 10,
                fontSize: 12
            }
        },
        tooltips: {
            mode: 'label',
            callbacks: {

                afterLabel: function (tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex].data1[tooltipItem.index] + '%';
                },

            },
        }
    };
};

function mapStateToProps(state) {
    return {
        dashboardItems: state.applicationReducer.dashboardItems,
        appStatuses: state.applicationReducer.appStatuses,
        states: state.administrationReducer.states,
        localGoverments: state.farmRegistarReducer.localGoverments,
        programType: state.programReducer.programType,
        zones: state.administrationReducer.zones,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getDashboardItems: (filters) => dispatch(getDashboardItems(filters)),
        getStates: () => dispatch(getStates()),
        getLocalGov: () => dispatch(getLocalGov()),
        getProgramType: () => dispatch(getProgramType()),
        getApplicationStatuses: () => dispatch(getApplicationStatuses()),
        getZones: () => dispatch(getZones())

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubsidiesDashboard);

const styles = {
    card: {
        padding: '10px',
        margin: '10px',
    },
    button: {
        height: 40
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
};