import React from 'react';
import i18n from '../../i18n/i18n';
import axios from '../../utils/AxiosWrapper';
import styles from './OpstinaLandingNew.module.css';
import { MoonLoader } from 'react-spinners';
import { Pie} from 'react-chartjs-2';
import OpstinaWeather from './OpstinaWeather';
import * as Icons from '@material-ui/icons';

export default class OpstinaLandingNew extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }
    componentDidMount() {
        this.getUser()
    }

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        this.setState({
            user: userObject
        })
        this.getSeason()

        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    getSeason() {
        axios.get('api/season/activity_season')
            .then(res => {
                let choosenSeason = res.data.data[0].naziv;
                let seasonId = res.data.data[0].id_default_sezona;

                this.setState({
                    choosenSeason: choosenSeason,
                }, () => {
                    axios.get('api/opstina/statistics')
                        .then(
                            res => {
                                let count = res.data.data[0].count
                                this.setState({
                                    zakupciCount: count.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")

                                }, () => {
                                    axios.get('api/opstina/statistics_tables', { params: { seasonId } })
                                        .then(
                                            res => {
                                                let count = res.data.data[0].count
                                                this.setState({
                                                    tableZakupci: count.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),

                                                }, () => {
                                                    axios.get('api/opstina/statistics_tables_area', { params: { seasonId } })
                                                        .then(
                                                            res => {
                                                                let dataAll = res.data.data

                                                                
                                                                if(dataAll.length > 0) {
                                                                    let data = dataAll.filter( item => {
                                                                        return item.naziv_kulture !== null
                                                                    })
                                                                    let count = dataAll.map(field => Number(field.povrsina))
                                                                    let nazivi = data.map(field => field.naziv_kulture)
                                                                    let uniqueNaziv = [...new Set(nazivi)]
                                                                    
                                                                    let resultat = uniqueNaziv.filter(item =>{
                                                                        return item !== null
                                                                    }
                                                                        )
    
                                                                    let uniqueWithArea = resultat.map(item => {
                                                                        return { naziv_kulture: item, povrsine: [], boja_table: [] }
                                                                    })

                                                                    
                                                                    data.forEach((item, i) => {
                                                                        let kultura = item.naziv_kulture;
                                                                        let id = uniqueWithArea.findIndex(el => el.naziv_kulture === kultura)
                                                                        uniqueWithArea[id].povrsine.push(Number(item.povrsina))
                                                                        uniqueWithArea[id].boja_table.push(item.boja_table)
                                                                    })
                                                                    let totalArea = count.reduce((a, x) => a + x)
                                                                    this.setState({
                                                                        tablePovrsina: totalArea,
                                                                        uniqueWithArea: uniqueWithArea,
                                                                        uniqueNazivi: uniqueNaziv,
                                                                        dataAll: dataAll
                                                                        
                                                                    }, () => {
                                                                        axios.get('api/opstina/statistics_wo', { params: { seasonId } })
                                                                        .then( res =>{
                                                                            let count = res.data.data[0].count
                                                                            this.setState({
                                                                                woCount: count.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                                                                                loading: false
                                                                            })
                                                                        })
                                                                        .catch(err=>console.log(err))
                                                                    })
                                                                }else{
                                                                    this.setState({
                                                                        tablePovrsina: 0,
                                                                        uniqueWithArea: 0,
                                                                        uniqueNazivi: null,
                                                                        dataAll: '0',
                                                                        woCount:'0',
                                                                        loading:false
                                                                    })
                                                                }
                                                                
                                                            }

                                                        )
                                                        .catch(err => { console.log(err) })
                                                })
                                            }
                                        )
                                        .catch(err => { console.log(err) })
                                })
                            }
                        )
                        .catch(err => console.log(err))
                })
            })
            .catch(err => { console.log(err) })
    }

    isOdd = (num) => { return num % 2; };
    render() {

        let sumArray = [];
        let othersArray = [];
        let bigArray= [];
        let naziviChart = [];
        let bojeChart = [];
        let areaSumWithCultures = 0;
       
        

        if (this.state.uniqueWithArea) {
            let dataFull = this.state.uniqueWithArea.filter(item => item.naziv_kulture !== null)
            let graphData = dataFull.map(item => item.povrsine)
            graphData.forEach((item, i) => {
            
                    let totalArea = item.reduce((a, x) => a + x);
                    
                    if(totalArea) {
                        sumArray.push(Number(totalArea.toFixed(2)));
                    }
            })
        }

        if(sumArray.length) {
            areaSumWithCultures = sumArray.reduce((a, b) => {
                return a + b;
              });
        }


        if (this.state.uniqueWithArea) {
            let dataLabelsFull = this.state.uniqueWithArea.filter(item => item.naziv_kulture !== null)
            naziviChart = dataLabelsFull.map(item => item.naziv_kulture)
        }

        let newColors = [];

        if (this.state.uniqueWithArea) {
            let dataColorsFull = this.state.uniqueWithArea.map(item => item.boja_table)
            dataColorsFull.forEach((item, i) => {
                let chartColor = item.filter(el =>{
                    return el !== null
                }
                    )
                    chartColor.map(item =>{
                        newColors.push(item)
                    })
            })
        }

        bojeChart = [...new Set(newColors)]

        const chartData = {
            labels: naziviChart,
            datasets: [
                {
                    data: sumArray,
                    backgroundColor: bojeChart,
                    borderColor:bojeChart,
                    borderWidth: 1,
                },
            ],
        };
        const options = {
            legend: {
                display: false
             }
          };

        let bojeContent;
        if(this.state.loading === false) {
            bojeContent = 
            bojeChart
        }

        let valuesContent;  
        if(this.state.loading === false) {
            
            valuesContent = 
            sumArray
        }
        let naziviContent;
        if(this.state.loading === false) {
            naziviContent = 
            naziviChart
        }

        return (
            <div className={styles.container}>
                {this.state.loading === true && <div style={{ marginLeft: '40%', paddingTop: '200px', paddingBottom: '400px' }}> <MoonLoader loading={true} size={100} /></div>}
                {this.state.loading === false && <div>
                    <div className={styles.title}>{i18n.t('Overview').toUpperCase()} - {this.state.choosenSeason.toUpperCase()}</div>
                    <div className={styles.featuredRow}>
                        <div className={styles.featuredItem}>
                            <div className={styles.feturedTop}>
                                <span className={styles.featuredTitle}>{i18n.t('Land tenants')}</span>
                                <img style={{ width: '30px', height: '30px', padding: '5px', boxShadow: '0px 0px 15px -10px rgba(0,0,0,0.75)' }} src='./za.svg' />

                            </div>

                            <div className={styles.featuredContainer}>
                                <span className={styles.featuredNumber}>{this.state.zakupciCount}</span>
                            </div>
                            <span className={styles.featuredSub}>{i18n.t('Total count of land tenants in the municipality/city')}</span>
                        </div>
                        <div className={styles.featuredItem}>
                            <div className={styles.feturedTop}>
                                <span className={styles.featuredTitle}>{i18n.t('Tables')}</span>
                                <img style={{ width: '30px', height: '30px', padding: '5px', boxShadow: '0px 0px 15px -10px rgba(0,0,0,0.75)' }} src='./fields1.svg' />

                            </div>

                            <div className={styles.featuredContainer}>
                                <span className={styles.featuredNumber}>{this.state.tableZakupci}</span>
                            </div>
                            <span className={styles.featuredSub}>{i18n.t('Total number of tables assigned to the land tenants in the municipality/city')}</span>
                        </div>
                        <div className={styles.featuredItem}>
                            <div className={styles.feturedTop}>
                                <span className={styles.featuredTitle}>{i18n.t('Land area')}</span>
                                <img style={{ width: '30px', height: '30px', padding: '5px', boxShadow: '0px 0px 15px -10px rgba(0,0,0,0.75)' }} src='./area.svg' />

                            </div>

                            <div className={styles.featuredContainer}>
                                <span className={styles.featuredNumber}>{this.state.tablePovrsina.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} ha</span>
                            </div>
                            <span className={styles.featuredSub}>{i18n.t('Total area of all tables assigned to the land tenants in the municipality/city')}</span>
                        </div>
                        <div className={styles.featuredItem}>
                            <div className={styles.feturedTop}>
                                <span className={styles.featuredTitle}>{i18n.t('Activities')}</span>
                                <img style={{ width: '30px', height: '30px', padding: '5px', boxShadow: '0px 0px 15px -10px rgba(0,0,0,0.75)' }} src='./wo.svg' />

                            </div>

                            <div className={styles.featuredContainer}>
                                <span className={styles.featuredNumber}>{this.state.woCount}</span>
                            </div>
                            <span className={styles.featuredSub}>{i18n.t('Total number of activities from the land tenants in the municipality/city')}</span>
                        </div>

                    </div>
                    
                   
                    <div className={styles.featuredRowBottom}>
                    <div className={styles.featuredContainerBottom}>
                        <div className={styles.featuredItemBottom1}>
                        <div className={styles.title}>{i18n.t('Weather forecast').toUpperCase()}</div>
                        {/* <Pie data={chartData} options={options} /> */}
                        <OpstinaWeather user={this.state.user}/>
                        
                        </div>
                        <div className={styles.featuredItemBottom2}>
                        <div className={styles.title}>{i18n.t('Seeding statistics').toUpperCase()} - {this.state.choosenSeason.toUpperCase()}</div>
                    
                        <div style={{marginTop:'20px'}}>
                            <div style={{display:'flex'}}>
                            <div className={styles.featuredSub}>{i18n.t('* based on verified production plots')}</div>
                            <div className={styles.featuredSub2}> - {areaSumWithCultures.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} ha</div>
                            </div>
                           
                            {valuesContent.length>0 && 

                                 <div className={styles.content_container}>
                                    <div className={styles.content_border} />
                                    <div className={styles.table_div}>
                                        <table className={styles.table}>
                                            <tr className={styles.table_row}>
                                                <th className={styles.table_header}></th>
                                                <th className={styles.table_header}>{i18n.t('Crop').toUpperCase()}</th>
                                                <th className={styles.table_header}> ha</th>
                                                <th className={styles.table_header}>{i18n.t('%')}</th>
                                            </tr>
                                            {valuesContent.map((item, i) => {
                                let colorCircle = bojeContent[i]
                                let valueHa = valuesContent[i]
                                let valueParcent = valuesContent[i]
                                let valueName = naziviContent[i]
                                                return <tr className={this.isOdd(i) === 0 ? styles.table_row_odd : styles.table_row}>
                                                    <td className={styles.table_row_text}><div style={{width:'8px', height:'8px',borderRadius:'50px', backgroundColor:`${colorCircle}`}}></div></td>
                                                    <td className={styles.table_row_text}>{valueName}</td>
                                                    <td className={styles.table_row_text_bold}>{valueHa} ha</td>
                                                    <td className={styles.table_row_text_bold}>{((valueHa/areaSumWithCultures)*100).toFixed(2)} %</td>
                                                </tr>
                                            })}
                                        </table>
                                    </div>
                                   
                                </div>
                            }

                            
                            </div>
                            
                         
                        </div>
                    </div>    
                    </div>
                </div>
                }
            </div>
        )
    }
}
