import axios from '../utils/AxiosWrapper';
export const Type = {
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',
    SET_REGION_LAYER_IN_REDUCER: 'SET_REGION_LAYER_IN_REDUCER',

    GET_REGION_LAYER_GROUP_DROPDOWN_CALL: 'GET_REGION_LAYER_GROUP_DROPDOWN_CALL',
    GET_REGION_LAYER_GROUP_DROPDOWN_SUCCESS: 'GET_REGION_LAYER_GROUP_DROPDOWN_SUCCESS',
    GET_REGION_LAYER_GROUP_DROPDOWN_FAILED: 'GET_REGION_LAYER_GROUP_DROPDOWN_FAILED',

    GET_REGION_LAYERS_READ_CALL: 'GET_REGION_LAYERS_READ_CALL',
    GET_REGION_LAYERS_READ_SUCCESS: 'GET_REGION_LAYERS_READ_SUCCESS',
    GET_REGION_LAYERS_READ_FAILED: 'GET_REGION_LAYERS_READ_FAILED',

    GET_REGION_LAYERS_GROUP_READ_CALL: 'GET_REGION_LAYERS_GROUP_READ_CALL',
    GET_REGION_LAYERS_GROUP_READ_SUCCESS: 'GET_REGION_LAYERS_GROUP_READ_SUCCESS',
    GET_REGION_LAYERS_GROUP_READ_FAILED: 'GET_REGION_LAYERS_GROUP_READ_FAILED',


    ADD_REGION_LAYER_GROUP_CALL: 'ADD_REGION_LAYER_GROUP_CALL',
    ADD_REGION_LAYER_GROUP_SUCCESS: 'ADD_REGION_LAYER_GROUP_SUCCESS',
    ADD_REGION_LAYER_GROUP_FAILED: 'ADD_REGION_LAYER_GROUP_FAILED',


    ADD_REGION_LAYER_CALL: 'ADD_REGION_LAYER_CALL',
    ADD_REGION_LAYER_SUCCESS: 'ADD_REGION_LAYER_SUCCESS',
    ADD_REGION_LAYER_FAILED: 'ADD_REGION_LAYER_FAILED',

    DELETE_CALL: 'DELETE_CALL',
    DELETE_SUCCESS: 'DELETE_SUCCESS',
    DELETE_FAILED: 'DELETE_FAILED',

    GET_TESTING_READ_CALL: 'GET_TESTING_READ_CALL',
    GET_TESTING_READ_SUCCESS: 'GET_TESTING_READ_SUCCESS',
    GET_TESTING_READ_FAILED: 'GET_TESTING_READ_FAILED',

    FORM_READ_CALL: 'FORM_READ_CALL',
    FORM_READ_SUCCESS: 'FORM_READ_SUCCESS',
    FORM_READ_FAILED: 'FORM_READ_FAILED',

};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function setRowInReducer(rowInfo, name) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_REGION_LAYER_IN_REDUCER,
            rowInfo: rowInfo,
            name: name
        })
    }
}

export function getRegionLayerGroupDropdown() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_REGION_LAYER_GROUP_DROPDOWN_CALL
        });

        axios.get('api/region_layer_grupa/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_REGION_LAYER_GROUP_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_REGION_LAYER_GROUP_DROPDOWN_FAILED
                });
            });
    }
}

export function getRegionLayersRead(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_REGION_LAYERS_GROUP_READ_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/region_layer_grupa/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_REGION_LAYERS_GROUP_READ_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_REGION_LAYERS_GROUP_READ_FAILED
                });
            });
    }
}



export function getRegionLayers(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_REGION_LAYERS_READ_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/region_lejer/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_REGION_LAYERS_READ_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_REGION_LAYERS_READ_FAILED
                });
            });
    }
}

export function addRegionLayer(regionLayerObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_REGION_LAYER_CALL
        });

        axios.post('api/region_lejer/create', regionLayerObj)
            .then(function (response) {
                dispatch({
                    type: Type.ADD_REGION_LAYER_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_REGION_LAYER_FAILED
                });
            });
    }
}

export function addRegionLayerGroup(regionLayerGroupObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_REGION_LAYER_GROUP_CALL
        });

        axios.post('api/region_layer_grupa/create', regionLayerGroupObj)
            .then(function (response) {
                dispatch({
                    type: Type.ADD_REGION_LAYER_GROUP_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_REGION_LAYER_GROUP_FAILED
                });
            });
    }
}

export function deleteRegionLayer(selection, name) {
    return (dispatch) => {

        dispatch({
            type: Type.DELETE_CALL,
        });

        axios.post(`api/${name}/delete`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_SUCCESS,
                    data: response.data,
                    name: name,
                    forDelete: selection
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_FAILED
                });
            });
    }
}

export function getTestingRead(state) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_TESTING_READ_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/dls/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_TESTING_READ_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_TESTING_READ_FAILED
                });
            });
    }
}

export function TestingById(id_klijent_vlasnik) {
    return (dispatch) => {

        dispatch({
            type: Type.FORM_READ_CALL
        });

        axios.get('api/dls/testingById?id_klijent_vlasnik='+ id_klijent_vlasnik)
            .then(function (response) {
                dispatch({
                    type: Type.FORM_READ_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.FORM_READ_FAILED
                });
            });
    }
}

