import React from 'react';
import { Card, withStyles, } from '@material-ui/core';
import axios from '../../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../InfoStyles';

export class InfoSowingWindowGeneral extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.language === 1 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Pojam Generalno odnosi se na sve kulture sem krompira, šećerne repe, kukuruza, uljane repice i suncokreta. 
                            Za navedene kulture uslove za setvu/sadnju pogledajte u sekcijama sa nazivom tih kultura.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            The term Generally refers to all crops except potato, sugar beet, corn, oilseed rape, and sunflower. 
                            For the mentioned crops see the conditions for sowing/planting in the sections with the name of those crops.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 4 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Pojam se općenito odnosi na sve usjeve osim krumpira, šećerne repe, kukuruza, uljane repice i suncokreta. 
                            Za navedene usjeve pogledajte uvjete za sjetvu/sadnju u odjeljcima s nazivom tih usjeva.
                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    };
};

export default withStyles(styles)(InfoSowingWindowGeneral);
