import immutable from 'seamless-immutable';
import { Type as HierarchyType } from '../actions/HierarchyActions';


export const INITIAL_STATE = immutable({
    
    hierarchys: [],
    hierarchyFetching: false,
    hierarchyFetchingFailed: false,

});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case HierarchyType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;

        case HierarchyType.GET_HIERARCHY_CALL: {
            const hierarchyFetching = true;
            return state.merge({ hierarchyFetching });
            break;
        }

        case HierarchyType.GET_HIERARCHY_SUCCESS: {
            const hierarchys = action.data.data;
            const hierarchyFetching = false;
            return state.merge({ hierarchys, hierarchyFetching });
            break;
        }

        case HierarchyType.GET_HIERARCHY_FAILED: {
            const hierarchyFetchingFailed = true;
            const hierarchyFetching = false;
            return state.merge({ hierarchyFetchingFailed, hierarchyFetching });
            break;
        }


        default:
            return state;
    }
}