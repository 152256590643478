import immutable from 'seamless-immutable';
import { Type as PublicProcurementType } from '../../actions/auction/PublicProcurementActions';


export const INITIAL_STATE = immutable({
    //ADD
    publicProcuremenPosting: false,
    publicProcurementPostingSuccess: false,
    publicProcurementPostingFailed: false,
    //VIEW
    publicProcurement: [],
    publicProcurementObj: {},
    publicProcurementObjFetching: false,
    publicProcurementObjFetchingFailed: false,
    publicProcurementFetching: false,
    publicProcurementFetchingFailed: false,
    publicProcurementPages: 1,
    //DROPDOWN
    publicProcurementDropDown: [],
    publicProcurementFetchingDropDown: false,
    publicProcurementFetchingFailedDropDown: false,

    basisOfLeaseDropDown: [],
    basisOfLeaseFetchingDropDown: false,
    basisOfLeaseFetchingFailedDropDown: false,
    //DASHBOARD
    publicProcurementItems: {},
    publicProcurementItemsFetching: false,
    publicProcurementItemsFetchingFailed: false,
    //DELETE
    deleted: false,
    deletePublicProcurementFailed: false

});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {


        case PublicProcurementType.ADD_PUBLIC_PROCUREMENT_CALL: {
            const publicProcuremenPosting = true;
            return state.merge({ publicProcuremenPosting });
            break;
        }

        case PublicProcurementType.ADD_PUBLIC_PROCUREMENT_SUCCESS: {
            const publicProcuremenPosting = false;
            const publicProcurementPostingSuccess = true;
            return state.merge({ publicProcurementPostingSuccess, publicProcuremenPosting, publicProcurementObj: INITIAL_STATE.publicProcurementObj })
            break;
        }

        case PublicProcurementType.ADD_PUBLIC_PROCUREMENT_FAILED: {
            const publicProcuremenPosting = false;
            const publicProcurementPostingFailed = true;
            return state.merge({ publicProcuremenPosting, publicProcurementPostingFailed });
            break;
        }

        case PublicProcurementType.GET_VIEW_PUBLIC_PROCUREMENT_CALL: {
            const publicProcurementFetching = true;
            return state.merge({ publicProcurementFetching });
            break;
        }

        case PublicProcurementType.GET_VIEW_PUBLIC_PROCUREMENT_SUCCESS: {
            const publicProcurement = action.data.data;
            const publicProcurementFetching = false;
            const publicProcurementPages = action.data.total_pages
            return state.merge({ publicProcurement, publicProcurementFetching, publicProcurementPages });
            break;
        }

        case PublicProcurementType.GET_VIEW_PUBLIC_PROCUREMENT_FAILED: {
            const publicProcurementFetchingFailed = true;
            const publicProcurementFetching = false;
            return state.merge({ publicProcurementFetching, publicProcurementFetchingFailed });
            break;
        }

        case PublicProcurementType.GET_BASIS_OF_LEASE_DROP_DOWN_CALL: {
            const basisOfLeaseFetchingDropDown = true;
            return state.merge({ basisOfLeaseFetchingDropDown });
            break;
        }

        case PublicProcurementType.GET_BASIS_OF_LEASE_DROP_DOWN_SUCCESS: {
            const basisOfLeaseDropDown = action.data.data;
            const basisOfLeaseFetchingDropDown = false;
            return state.merge({ basisOfLeaseDropDown, basisOfLeaseFetchingDropDown });
            break;
        }

        case PublicProcurementType.GET_BASIS_OF_LEASE_DROP_DOWN_FAILED: {
            const basisOfLeaseFetchingFailedDropDown = true;
            const basisOfLeaseFetchingDropDown = false;
            return state.merge({ basisOfLeaseFetchingDropDown, basisOfLeaseFetchingFailedDropDown });
            break;
        }

        case PublicProcurementType.GET_PUBLIC_PROCUREMENT_DASHBOARD_CALL: {
            const publicProcurementItemsFetching = true;
            return state.merge({ publicProcurementItemsFetching });
            break;
        }

        case PublicProcurementType.GET_PUBLIC_PROCUREMENT_DASHBOARD_SUCCESS: {
            const publicProcurementItems = action.data;
            const publicProcurementItemsFetching = false;
            return state.merge({ publicProcurementItems, publicProcurementItemsFetching });
            break;
        }

        case PublicProcurementType.GET_PUBLIC_PROCUREMENT_DASHBOARD_FAILED: {
            const publicProcurementItemsFetchingFailed = true;
            const publicProcurementItemsFetching = false;
            return state.merge({ publicProcurementItemsFetching, publicProcurementItemsFetchingFailed });
            break;
        }


        case PublicProcurementType.DELETE_PUBLIC_PROCUREMENT_SUCCESS: {
            const array = state['publicProcurement'].asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ publicProcurement: array, deleted: deleted })
            break;

        }

        case PublicProcurementType.DELETE_PUBLIC_PROCUREMENT_FAILED: {
            const deletePublicProcurementFailed = true;
            return state.merge({ deletePublicProcurementFailed });
            break;
        }

        case PublicProcurementType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;


        case PublicProcurementType.GET_PUBLIC_PROCUREMENT_BY_ID_CALL: {
            const publicProcurementObjFetching = true;
            return state.merge({ publicProcurementObjFetching });
            break;
        }

        case PublicProcurementType.GET_PUBLIC_PROCUREMENT_BY_ID_SUCCESS: {
            const publicProcurementObj = action.data.data;
            const publicProcurementObjFetching = false;
            return state.merge({ publicProcurementObj, publicProcurementObjFetching });
            break;
        }

        case PublicProcurementType.GET_PUBLIC_PROCUREMENT_BY_ID_FAILED: {
            const publicProcurementObjFetchingFailed = true;
            const publicProcurementObjFetching = false;
            return state.merge({ publicProcurementObjFetching, publicProcurementObjFetchingFailed });
            break;
        }


        default:
            return state;
    }
}