import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Fab } from '@material-ui/core';
import i18n from '../../../i18n/i18n';
import { toast } from 'react-toastify';
import DatePickerForFilter from '../../../utils/DatePickerForFilter';
import { getSeason, deleteSeason, setInitialState, setRowInReducer } from '../../../actions/resources/SeasonsActions';
import AddSeason from './AddSeason';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import moment from 'moment';
import { Delete } from "../../../utils/Delete";

const CheckboxTable = checkboxHOC(ReactTable);
export class ViewSeasons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            add: false,
            edit: false,
            delete: false,
            showFilters: false,
            selection: [],
            selectAll: false,
            name: ''
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            season: nextProps.season,
            seasonFetching: nextProps.seasonFetching,
        })
        if (nextProps.deleted === true) {
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getSeason(wrapped.state);
            this.setState({
                selection: [],
                selectAll: false,
                name: ''
            })
            this.props.setInitialState('deleted');
        }
        if (nextProps.seasonPostingSuccess === true) {
            this.setState({
                selection: [],
                name: '',
                selectAll: false
            })
            this.handleClick();
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getSeason(wrapped.state);
            this.props.setInitialState('seasonPostingSuccess');
        }
        if (nextProps.deleteSeasonFailed === true) {
            toast.error(i18n.t('This item can not be deleted!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('deleteSeasonFailed');
        }


    }

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    handleClick = () => {
        if (this.state.edit == true && this.state.add == false) {
            this.setState({ edit: false })
            this.props.setInitialState('seasonObj');
        } else if (this.state.selection.length > 0 && this.state.add == false && this.state.edit == false) {
            this.props.setInitialState('seasonObj');
        }
        this.setState({ add: !this.state.add }, () => {
            if (this.state.add == true && this.state.edit == false) {
                this.props.setInitialState('seasonObj');
                this.setState({
                    selection: [],
                    name: '',
                    selectAll: false,
                })
            }
        });

    };

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            selection.push(key);
        }
        this.setState({ selection: selection, name: 'season' }, function () {
            if (selection.length == 1) {
                let row1 = this.props.season.filter((season) => {
                    return season.id == selection;
                })
                this.props.setRowInReducer(row1[0], 'seasonObj');
            }
        });

    };

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection, name: 'season' });


    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    onDeleteClick = () => {
        this.setState({ delete: !this.state.delete });
    }

    deleteItem = () => {
        this.props.deleteSeason(this.state.selection, this.state.name);
        this.setState({ delete: false })
    }

    onEditClick() {
        this.setState({ add: !this.state.add, edit: true });
    }


    render() {

        const columns = [
            {
                Header: i18n.t('ID'),
                accessor: 'id'
            },
            {
                Header: i18n.t('Title'),
                accessor: 'naziv'
            },
            {
                id: 'startDate',
                Header: i18n.t('Start date'),
                accessor: d => moment(d.datum_od).format('DD.MM.YYYY'),
                Filter: ({ filter, onChange }) =>
                    <DatePickerForFilter
                        onChange={({ startDate, endDate }) => {
                            onChange({ startDate, endDate });
                        }}
                    />
            },
            {
                id: 'endDate',
                Header: i18n.t('End date'),
                accessor: d => moment(d.datum_do).format('DD.MM.YYYY'),
                Filter: ({ filter, onChange }) =>
                    <DatePickerForFilter
                        onChange={({ startDate, endDate }) => {
                            onChange({ startDate, endDate });
                        }}
                    />
            },
            {
                Header: i18n.t('Region'),
                accessor: 'naziv_regiona'
            },
            {
                Header: i18n.t('Region ID'),
                accessor: 'id_region'
            },
        ];
        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "#ACDAF1" : "inherit"
                        }
                    };
                }
                return {};
            }
        };
        return (
            <div className="page">
                <div className="table-header">
                    <Fab
                        onClick={() => this.handleClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-Plus headerIcon" />
                    </Fab>
                    <Fab
                        onClick={() => this.onSearchClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-search headerIcon" />
                    </Fab>
                    {this.state.selection.length > 0 ?
                        <Fab
                            onClick={() => this.onDeleteClick()}
                            color="secondary"
                            aria-label="add"
                            className="action-button"
                        >
                            <span className="icon-Kanta headerIcon" />
                        </Fab>
                        : null
                    }
                    {this.state.selection.length === 1 ?
                        <Fab
                            onClick={() => this.onEditClick()}
                            color="secondary"
                            aria-label="edit"
                            className="action-button"
                        >
                            <span className="icon-Izmeni1 headerIcon" />
                        </Fab>
                        : null
                    }
                </div>
                <CheckboxTable data={this.props.season}
                    ref={r => (this.checkboxTable = r)}
                    keyField={'id'}
                    getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                    columns={columns}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.props.seasonPages}
                    loading={this.props.seasonFetching}
                    manual
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => this.props.getSeason(state, instance)}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF',
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
                {this.state.add && <AddSeason add={this.state.add} edit={this.state.edit}
                    handleClose={this.handleClick} />}
                {this.state.delete && <Delete delete={this.state.delete} handleClose={this.onDeleteClick} deleteItem={this.deleteItem} />}
            </div>

        )
    }


};

function mapStateToProps(state) {
    return {
        season: state.seasonReducer.season,
        seasonFetching: state.seasonReducer.seasonFetching,
        seasonPages: state.seasonReducer.seasonPages,
        deleted: state.seasonReducer.deleted,
        deleteSeasonFailed: state.seasonReducer.deleteSeasonFailed,
        seasonPostingSuccess: state.seasonReducer.seasonPostingSuccess
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getSeason: (state, instance) => dispatch(getSeason(state, instance)),
        setInitialState: (component) => dispatch(setInitialState(component)),
        setRowInReducer: (rowInfo, name) => dispatch(setRowInReducer(rowInfo, name)),
        deleteSeason: (selection, name) => dispatch(deleteSeason(selection, name)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewSeasons)