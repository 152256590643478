import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n/i18n';
import { Button, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import { SketchPicker } from 'react-color';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//  import InputLabel from '@material-ui/core/InputLabel';
import { setInitialState, addImplementGroup } from '../../../actions/resources/ImplementsActions';
import Dialog from '@material-ui/core/Dialog';
import Modal from '@material-ui/core/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
export class AddImplementGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            implementGroupObj: this.props.implementGroupObj,
            displayColorPicker: false,
            openModal: false,
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.implementGroupsPostingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('implementsGroupPostingFailed');
        }
    }

    addImplementGroupFunction = () => {
        if (this.state.implementGroupObj.naziv) {
            this.props.addImplementGroup(
                this.state.implementGroupObj
            )

        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    handleChangeComplete = (color) => {
        let obj = Object.assign({}, this.state.implementGroupObj);
        obj.boja = color.hex;
        this.setState({ implementGroupObj: obj });
    };

    handleChangeValue = name => event => {
        let obj = Object.assign({}, this.state.implementGroupObj);
        obj[name] = event.target.value;
        this.setState({ implementGroupObj: obj });
    };

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker, openModal: !this.state.openModal })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false, openModal: false })
    };


    render() {

        return (
            <div style={{ padding: 20 }}>

                {/* <Paper className="roundedBoxMaterial" zdepth={4}> */}
                <Dialog
                    open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="xs"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit Implement group') : i18n.t('Add Implement group')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-machine-group">
                            <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                                <Grid item>
                                    <TextField
                                        required
                                        label={i18n.t('Title')}
                                        value={this.state.implementGroupObj.naziv}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={this.handleChangeValue('naziv')}
                                    />
                                </Grid>
                                <Grid item >
                                    <TextField
                                        label={i18n.t('Icon name')}
                                        value={this.state.implementGroupObj.ime_ikonice}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={this.handleChangeValue('ime_ikonice')}
                                    />
                                </Grid>
                                <Grid item >
                                    <TextField
                                        label={i18n.t('Priority')}
                                        value={this.state.implementGroupObj.prioritet}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={this.handleChangeValue('prioritet')}
                                        type="number"
                                    />
                                </Grid>
                                <Grid item >
                                    <TextField
                                        label={i18n.t('Color')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px', backgroundColor: this.state.implementGroupObj.boja && this.state.implementGroupObj.boja.includes('#') ? `${this.state.implementGroupObj.boja}` : `#${this.state.implementGroupObj.boja}` }}
                                        onClick={this.handleClick}
                                        value={this.state.implementGroupObj.boja}
                                    >
                                    </TextField>
                                    {this.state.displayColorPicker ? <div style={{
                                        position: 'absolute',
                                        zIndex: '2',
                                    }}>
                                        <Modal
                                            aria-labelledby="simple-modal-title"
                                            aria-describedby="simple-modal-description"
                                            open={this.state.openModal}
                                            onClose={this.handleClose}
                                            style={{
                                                position: 'absolute',
                                                left: '50%',
                                                top: '50%',
                                                right: '50%',
                                                bottom: '50%',
                                            }}
                                        >
                                            <SketchPicker color={this.state.implementGroupObj.boja != null ? this.state.implementGroupObj.boja : ''} onChange={this.handleChangeComplete} />
                                        </Modal>
                                    </div> : null}
                                </Grid>
                                <Grid item xs>
                                    <Button
                                        style={{ alignSelf: 'center', backgroundColor: '#58B4E5', color: 'white' }}
                                        onClick={this.addImplementGroupFunction}
                                    >
                                        {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
                {/* </Paper> */}
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        implementGroupsPosting: state.implementReducer.implementGroupsPosting,
        implementGroupObj: state.implementReducer.implementGroupObj,
        implementGroupsPostingFailed: state.implementReducer.implementGroupsPostingFailed
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInitialState: (component) => dispatch(setInitialState(component)),
        addImplementGroup: (implementGroupObj) => dispatch(addImplementGroup(implementGroupObj)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddImplementGroup)