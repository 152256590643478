import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { getGroup, getSubgroup, getViewImplements, setRowInReducer, setInitialState } from '../../../actions/resources/ImplementsActions';
import i18n from '../../../i18n/i18n';
import Select from 'react-select';
import immutable from 'seamless-immutable';
import AddImplement from './AddImplement';
import checkboxHOC from "react-table/lib/hoc/selectTable";
const CheckboxTable = checkboxHOC(ReactTable);
export class ViewImplement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            add: this.props.add,
            edit: this.props.edit,
            showFilters: this.props.showFilters,
            selection: [],
            selectAll: false
        };
    }

    componentDidMount() {
        if (this.props.implementGroupDropdown.length === 0) {
            this.props.getGroup();
        }
        if (this.props.implementSubgroupDropdown.length === 0) {
            this.props.getSubgroup();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.deleted === true) {
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getViewImplements(wrapped.state);
            this.setState({
                selection: [],
                selectAll: false,
            })
            this.props.setInitialState('deleted');

        }
        if (nextProps.implementPostingSuccess === true) {
            this.setState({
                selection: [],
                name: '',
                selectAll: false
            })
            this.props.handleClose();
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getViewImplements(wrapped.state);
            this.props.setInitialState('implementPostingSuccess');
        }
        this.setState({
            add: nextProps.add,
            showFilters: nextProps.showFilters,
            implement: nextProps.implement,
            implementFetching: nextProps.implementFetching,
            implementGroupDropdown: immutable.asMutable(nextProps.implementGroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            groupFetching: nextProps.groupFetching,
            groupFetchingFailed: nextProps.groupFetchingFailed,
            implementSubgroupDropdown: immutable.asMutable(nextProps.implementSubgroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            subgroupFetching: nextProps.subgroupFetching,
            subgroupFetchingFailed: nextProps.subgroupFetchingFailed,
        })
        if (nextProps.add == true && nextProps.edit == false) {
            this.props.setInitialState('implementObj');
            this.setState({
                selection: [],
                name: '',
                selectAll: false
            })
        }

    }

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            selection.push(key);
        }
        this.setState({ selection }, function () {
            if (selection.length == 1) {
                let row1 = this.props.implement.filter((imp) => {
                    return imp.id == selection;
                })
                this.props.setRowInReducer(row1[0], 'implementObj');
            }
        });
        this.props.setSelection(selection, 'implement');
    };

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });
        this.props.setSelection(selection, 'implement');

    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };



    render() {
        let content;
        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "#ACDAF1" : "inherit"
                        }
                    };
                }
                return {};
            }
        };
        content =
            <div className="page">
                <CheckboxTable data={this.props.implement}
                    ref={r => (this.checkboxTable = r)}
                    keyField={'id'}
                    columns={this.columns}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    showPaginationTop
                    showPaginationBottom={false}
                    pages={this.props.implementPages}
                    loading={this.props.implementFetching}
                    manual
                    onFetchData={(state, instance) => this.props.getViewImplements(state, instance)}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF',
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
                {this.state.add && <AddImplement add={this.state.add} edit={this.props.edit}
                    handleClose={this.props.handleClose} />}
            </div>


        return (
            <div>
                {content}
            </div>
        )
    }

    columns = [
        {
            Header: i18n.t('Title'),
            accessor: 'naziv'
        },
        {
            Header: i18n.t('Code'),
            accessor: 'sifra',
        },
        {
            Header: i18n.t('Implement group name'),
            accessor: 'prikljucna_masina_grupa_naziv',
            Filter: ({ filter, onChange }) =>
                <Select
                    isMulti
                    name="naziv"
                    onChange={value => {
                        onChange(value)
                    }}
                    closeMenuOnSelect={false}
                    options={this.state.implementGroupDropdown}
                    className="multi-select-in-filter"
                    classNamePrefix="select"
                    placeholder={i18n.t('Select')}
                />
        },
        {
            Header: i18n.t('Implement subgroup name'),
            accessor: 'prikljucna_masina_podgrupa_naziv',
            Filter: ({ filter, onChange }) =>
                <Select
                    isMulti
                    name="naziv"
                    onChange={value => {
                        onChange(value)
                    }}
                    closeMenuOnSelect={false}
                    options={this.state.implementSubgroupDropdown}
                    className="multi-select-in-filter"
                    classNamePrefix="select"
                    placeholder={i18n.t('Select')}
                />
        },

    ];
};

function mapStateToProps(state) {
    return {
        implement: state.implementReducer.implement,
        implementFetching: state.implementReducer.implementFetching,
        implementPages: state.implementReducer.implementPages,
        implementGroupDropdown: state.implementReducer.implementGroupDropdown,
        groupFetching: state.implementReducer.groupFetching,
        groupFetchingFailed: state.implementReducer.groupFetchingFailed,
        implementSubgroupDropdown: state.implementReducer.implementSubgroupDropdown,
        subgroupFetching: state.implementReducer.subgroupFetching,
        subgroupFetchingFailed: state.implementReducer.subgroupFetchingFailed,
        deleted: state.implementReducer.deleted,
        implementPostingSuccess: state.implementReducer.implementPostingSuccess
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getGroup: () => dispatch(getGroup()),
        getSubgroup: () => dispatch(getSubgroup()),
        setInitialState: (component) => dispatch(setInitialState(component)),
        setRowInReducer: (rowInfo, name) => dispatch(setRowInReducer(rowInfo, name)),
        getViewImplements: (state, instance) => dispatch(getViewImplements(state, instance)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewImplement)