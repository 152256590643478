import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import { Button, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setInitialState, addRegionLayer, getRegionLayerGroupDropdown } from '../../actions/SuperAdminActions';
import { getRegionType } from '../../actions/control_panel/RegionActions';
import Dialog from '@material-ui/core/Dialog';
import ImageDropZone from '../../utils/ImageDropZone';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
export class AddRegionLayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            regionLayerObj: this.props.regionLayerObj,
            displayColorPicker: false,
            openModal: false,
        }
    }

    componentDidMount() {
        this.props.getRegionLayerGroupDropdown();

        if (this.props.regionType.length === 0) {
            this.props.getRegionType();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.regionLayerPostingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('regionLayerPostingFailed');
        }
    }

    addRegionLayer() {
        if (this.state.regionLayerObj.naziv) {
            this.props.addRegionLayer(
                this.state.regionLayerObj
            )

        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    handleChangeValue = name => event => {
        let obj = Object.assign({}, this.state.regionLayerObj);
        obj[name] = event.target.value;
        this.setState({ regionLayerObj: obj });
    };

    handleChangeCheckbox = name => event => {
        let obj = Object.assign({}, this.state.regionLayerObj);
        obj[name] = event.target.checked;
        this.setState({ regionLayerObj: obj });
    };

    handleChangeImage = image => {
        let obj = Object.assign({}, this.state.regionLayerObj);
        obj['icon'] = image;
        this.setState({ regionLayerObj: obj });
    }

    render() {

        let regionType = [];
        if (this.props.regionType && !this.props.regionTypeFetching) {
            regionType = this.props.regionType.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }

        let id_region_lejer_grupa;
        if (this.state.regionLayerObj.id_region == 13) {
            let regionFilter = this.props.regionLayerGroupDropdown.filter((rlg) => {
                return rlg.id_region == 13;
            })
            id_region_lejer_grupa = regionFilter.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        } else if (this.state.regionLayerObj.id_region != 13) {
            let regionFilter = this.props.regionLayerGroupDropdown.filter((rlg) => {
                return rlg.id_region == this.state.regionLayerObj.id_region;
            })
            id_region_lejer_grupa = regionFilter.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        return (
            <div style={{ padding: 20 }}>

                {/* <Paper className="roundedBoxMaterial" zdepth={4}> */}
                <Dialog
                    open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="xs"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit region layer') : i18n.t('Add region layer')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-material-group">
                            <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                                <Grid item>
                                    <TextField
                                        required
                                        label={i18n.t('Layer ID')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.state.regionLayerObj.id_lejer}
                                        onChange={this.handleChangeValue('id_lejer')}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        required
                                        label={i18n.t('Title')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.state.regionLayerObj.naziv}
                                        onChange={this.handleChangeValue('naziv')}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        select
                                        label={i18n.t('Region')}
                                        value={this.state.regionLayerObj.id_region}
                                        onChange={this.handleChangeValue('id_region')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        // required
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <option value='' />
                                        {/* <option key={13} value={13}>{i18n.t('Nigeria')}</option>
                                        <option key={2} value={2}>{i18n.t('Serbia')}</option> */}
                                        {regionType}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        select
                                        label={i18n.t('Type')}
                                        value={this.state.regionLayerObj.tip}
                                        onChange={this.handleChangeValue('tip')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        // required
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <option value='' />
                                        <option key={'point'} value={'point'}>Point</option>
                                        <option key={'wms'} value={'wms'}>Wms</option>
                                        <option key={'polygon'} value={'polygon'}>Polygon</option>
                                        <option key={'forecast'} value={'forecast'}>Forecast</option>
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        select
                                        label={i18n.t('Source')}
                                        value={this.state.regionLayerObj.izvor}
                                        onChange={this.handleChangeValue('izvor')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        // required
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <option value='' />
                                        <option key={'agrolife'} value={'agrolife'}>Agrolife</option>
                                        <option key={'sentinel'} value={'sentinel'}>Sentinel</option>
                                        <option key={'geoserver'} value={'geoserver'}>Geoserver</option>
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        // required
                                        label={i18n.t('URL')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.state.regionLayerObj.url}
                                        onChange={this.handleChangeValue('url')}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        // required
                                        label={i18n.t('WMS layers')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.state.regionLayerObj.wms_layers}
                                        onChange={this.handleChangeValue('wms_layers')}
                                    />
                                </Grid>
                                {this.state.regionLayerObj.id_region &&
                                    <Grid item>
                                        <TextField
                                            select
                                            label={i18n.t('Region layer group')}
                                            value={this.state.regionLayerObj.id_region_lejer_grupa}
                                            onChange={this.handleChangeValue('id_region_lejer_grupa')}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            // required
                                            margin="normal"
                                            style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        >
                                            <option value='' />
                                            {id_region_lejer_grupa}
                                        </TextField>
                                    </Grid>
                                }
                                <Grid item>
                                    <ImageDropZone
                                        multiple={false}
                                        onDropAccepted={(accepted) => {
                                            console.log('accepted', accepted);
                                            this.handleChangeImage(accepted);
                                        }}
                                    />
                                </Grid>
                                <Grid item style={{ display: 'flex', flexDirection: 'row', alignContent: "space-between" }}>
                                    <Grid item>
                                        {this.state.regionLayerObj.icon &&
                                            <img height="40" width="40" style={{ padding: '10px' }}
                                                src={this.state.regionLayerObj.icon} />
                                        }
                                    </Grid>
                                </Grid>
                                <Grid>
                                    <FormControlLabel
                                        style={{ marginTop: '10px', marginBottom: '10px' }}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={this.state.regionLayerObj.vidljivost}
                                                onChange={this.handleChangeCheckbox('vidljivost')}
                                                value="vidljivost"
                                            />
                                        }
                                        label={i18n.t('Visibility')}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Button
                                        style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white' }}
                                        onClick={() => this.addRegionLayer()}
                                    >
                                        {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
                {/* </Paper> */}
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        regionLayerObj: state.superAdminReducer.regionLayerObj,
        regionLayerPostingFailed: state.superAdminReducer.regionLayerPostingFailed,
        regionLayerGroupDropdown: state.superAdminReducer.regionLayerGroupDropdown,

        regionType: state.regionReducer.regionType,
        regionTypeFetching: state.regionReducer.regionTypeFetching,
        regionTypeFetchingFailed: state.regionReducer.regionTypeFetchingFailed,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInitialState: (component) => dispatch(setInitialState(component)),
        addRegionLayer: (regionLayerObj) => dispatch(addRegionLayer(regionLayerObj)),
        getRegionLayerGroupDropdown: () => dispatch(getRegionLayerGroupDropdown()),
        getRegionType: () => dispatch(getRegionType()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddRegionLayer)