import immutable from 'seamless-immutable';
import { Type as AdministrationType } from '../actions/AdministrationActions';


export const INITIAL_STATE = immutable({
    //Dropdowns
    zones: [],
    zonesFetching: false,
    zonesFetchingFailed: false,
    states: [],
    statesFetching: false,
    statesFetchingFailed: false,
    branches: [],
    branchesFetching: false,
    branchesFetchingFailed: false,

    //Zonal managers
    zonalOffices: [],
    zonalObj: {},
    zonalOfficesFetching: false,
    zonalOfficesFetchingFailed: false,
    zonesPosting: false,
    zonesPostingSuccess: false,
    zonesPostingFailed: false,
    zonalOfficePages: 1,

    //State managers
    statesObj: {},
    statesManagers: [],
    statesManagersFetching: false,
    statesManagersFetchingFailed: false,
    stateManagersPages: 1,
    stateManagersPostingSuccess: false,
    stateManagersPosting: false,
    stateManagersPostingFailed: false,

    //Branch managers
    branchObj: {},
    branchOffices: [],
    branchOfficesFetching: false,
    branchOfficesFetchingFailed: false,
    branchOfficesPages: 1,
    branchPostingSuccess: false,
    branchPosting: false,
    branchPostingFailed: false,

    //Agents
    agentObj: {},
    agents: [],
    agentsFetching: false,
    agentsFetchingFailed: false,
    agentsPages: 1,
    agentPostingSuccess: false,
    agentPosting: false,
    agentPostingFailed: false,

    //Field managers
    fmObj: {},
    fieldManagers: [],
    fmsFetching: false,
    fmsFetchingFailed: false,
    fmsPages: 1,
    fmPostingSuccess: false,
    fmPosting: false,
    fmPostingFailed: false,
    //Anchors
    anchors: [],
    anchorsFetching: false,
    anchorsPages: 1,
    anchorsFetchingFailed: false,
    anchorPosting: false,
    anchorPostingSuccess: false,
    anchorPostingFailed: false,
    anchorObj: {},
    anchorDocuments: [],
    anchorDocumentsFetching: false,
    anchorDocumentsFetchingFailed: false,

    //Societies
    societies: [],
    societiesFetching: false,
    societiesPages: 1,
    societiesFetchingFailed: false,
    societyPosting: false,
    societyPostingSuccess: false,
    societyPostingFailed: false,
    societyObj: {},

    farmersBySociety: [],
    farmersBySocietyFetching: false,
    farmersBySocietyPages: 1,
    farmersDropdown: [],
    farmersDropdownFetching: false,
    farmersArr: [],
    addFarmersToSocietySuccess: false,

    //COS-AGENT
    addAgentCosPosting: false,
    addAgentCosPostingSuccess: false,
    addAgentCosPostingFailed: false,
    CosAgentArr: [],
    cosDropdown: [],
    cosDropdownFetching: false,
    cosByAgent: [],
    cosByAgentFetching: false,
    cosByAgentFetchingFailed: false,
    cosByAgentPages: 1,

    //ANCHOR-AGENT
    addAnchorAgentPosting: false,
    addAnchorAgentPostingSuccess: false,
    addAnchorAgentPostingFailed: false,
    AnchorAgentArr: [],
    agentDropdown: [],
    agentDropdownFetching: false,
    anchorAgent: [],
    anchorAgentFetching: false,
    anchorAgentFetchingFailed: false,
    anchorAgentPages: 1,

    anchorAgentDropdown: [],
    anchorAgentDropdownFetching: false,
    anchorAgentDropdownFetchingFailed: false,
    anchorAgentDropdownPages: 1,

    anchorDropdown: [],
    anchorDropdownFetching: false,
    //Users
    users: [],
    usersFetching: false,
    usersFetchingFailed: false,
    usersPages: 1,

    userObj: {},
    userPosting: false,
    userPostingSuccess: false,
    userPostingFailed: false,

    //registration
    registrationItems: {},
    registrationFetching: false,
    registrationFetchingFailed: false,

    //Executives
    executives: [],
    executivesObj: {},
    executivesFetching: false,
    executivesFetchingFailed: false,
    executivesPosting: false,
    executivesPostingSuccess: false,
    executivesPostingFailed: false,
    executivesPages: 1,


});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case AdministrationType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;

        case AdministrationType.SET_ADMINISTRATION_ROW_IN_REDUCER: {
            const data = action.rowInfo.asMutable();
            return state.merge({ [action.name]: data })
            break;
        }

        case AdministrationType.GET_ZONES_CALL: {
            const zonesFetching = true;
            return state.merge({ zonesFetching });
            break;
        }

        case AdministrationType.GET_ZONES_SUCCESS: {
            const zones = action.data.data;
            const zonesFetching = false;
            return state.merge({ zones, zonesFetching });
            break;
        }

        case AdministrationType.GET_ZONES_FAILED: {
            const zonesFetchingFailed = true;
            const zonesFetching = false;
            return state.merge({ zonesFetchingFailed, zonesFetching });
            break;
        }

        case AdministrationType.GET_STATES_CALL: {
            const statesFetching = true;
            return state.merge({ statesFetching });
            break;
        }

        case AdministrationType.GET_STATES_SUCCESS: {
            const states = action.data.data;
            const statesFetching = false;
            return state.merge({ states, statesFetching });
            break;
        }

        case AdministrationType.GET_BRANCHES_CALL: {
            const branchesFetching = true;
            return state.merge({ branchesFetching });
            break;
        }

        case AdministrationType.GET_BRANCHES_SUCCESS: {
            const branches = action.data.data;
            const branchesFetching = false;
            return state.merge({ branches, branchesFetching });
            break;
        }

        case AdministrationType.ADD_ZONAL_OFFICE_CALL: {
            const zonesPosting = true;
            return state.merge({ zonesPosting });
            break;
        }

        case AdministrationType.ADD_ZONAL_OFFICE_SUCCESS: {
            const zonesPosting = false;
            const zonesPostingSuccess = true;
            return state.merge({ zonesPostingSuccess, zonesPosting })
            break;
        }

        case AdministrationType.ADD_ZONAL_OFFICE_FAILED: {
            const zonesPosting = false;
            const zonesPostingFailed = true;
            return state.merge({ zonesPosting, zonesPostingFailed });
            break;
        }

        case AdministrationType.GET_STATES_FAILED: {
            const statesFetchingFailed = true;
            const statesFetching = false;
            return state.merge({ statesFetching, statesFetchingFailed });
            break;
        }

        case AdministrationType.GET_REGISTRATION_COUNT_CALL: {
            const registrationFetching = true;
            return state.merge({ registrationFetching });
            break;
        }

        case AdministrationType.GET_REGISTRATION_COUNT_SUCCESS: {
            let registrationItems = state.registrationItems.asMutable();
            registrationItems = { ...registrationItems, ...action.data };
            const registrationFetching = false;
            return state.merge({ registrationItems: registrationItems, registrationFetching });
            break;
        }

        case AdministrationType.GET_REGISTRATION_COUNT_FAILED: {
            const registrationFetchingFailed = true;
            const registrationFetching = false;
            return state.merge({ registrationFetching, registrationFetchingFailed });
            break;
            break;
        }

        case AdministrationType.GET_ZONAL_OFFICE_CALL: {
            const zonalOfficesFetching = true;
            return state.merge({ zonalOfficesFetching });
            break;
        }

        case AdministrationType.GET_ZONAL_OFFICE_SUCCESS: {
            const zonalOffices = action.data.data;
            const zonalOfficesFetching = false;
            const zonalOfficePages = action.data.total_pages
            return state.merge({ zonalOffices, zonalOfficesFetching, zonalOfficePages });
            break;
        }

        case AdministrationType.GET_ZONAL_OFFICE_FAILED: {
            const zonalOfficesFetchingFailed = true;
            const zonalOfficesFetching = false;
            return state.merge({ zonalOfficesFetching, zonalOfficesFetchingFailed });
            break;
        }


        case AdministrationType.ADD_BRANCH_OFFICE_CALL: {
            const branchPosting = true;
            return state.merge({ branchPosting });
            break;
        }

        case AdministrationType.ADD_BRANCH_OFFICE_SUCCESS: {
            const branchPosting = false;
            const branchPostingSuccess = true;
            return state.merge({ branchPostingSuccess, branchPosting })
            break;
        }

        case AdministrationType.ADD_BRANCH_OFFICE_FAILED: {
            const branchPosting = false;
            const branchPostingFailed = true;
            return state.merge({ branchPosting, branchPostingFailed });
            break;
        }

        case AdministrationType.GET_BRANCH_OFFICE_CALL: {
            const branchOfficesFetching = true;
            return state.merge({ branchOfficesFetching });
            break;
        }

        case AdministrationType.GET_BRANCH_OFFICE_SUCCESS: {
            const branchOffices = action.data.data;
            const branchOfficesFetching = false;
            const branchOfficesPages = action.data.total_pages
            return state.merge({ branchOffices, branchOfficesFetching, branchOfficesPages });
            break;
        }

        case AdministrationType.GET_BRANCH_OFFICE_FAILED: {
            const branchOfficesFetchingFailed = true;
            const branchOfficesFetching = false;
            return state.merge({ branchOfficesFetching, branchOfficesFetchingFailed });
            break;
        }

        case AdministrationType.ADD_AGENT_CALL: {
            const agentPosting = true;
            return state.merge({ agentPosting });
            break;
        }

        case AdministrationType.ADD_AGENT_SUCCESS: {
            const agentPosting = false;
            const agentPostingSuccess = true;
            return state.merge({ agentPostingSuccess, agentPosting })
            break;
        }

        case AdministrationType.ADD_AGENT_FAILED: {
            const agentPosting = false;
            const agentPostingFailed = true;
            return state.merge({ agentPosting, agentPostingFailed });
            break;
        }

        case AdministrationType.GET_AGENTS_CALL: {
            const agentsFetching = true;
            return state.merge({ agentsFetching });
            break;
        }

        case AdministrationType.GET_AGENTS_SUCCESS: {
            const agents = action.data.data;
            const agentsFetching = false;
            const agentsPages = action.data.total_pages
            return state.merge({ agents, agentsFetching, agentsPages });
            break;
        }

        case AdministrationType.GET_AGENTS_FAILED: {
            const agentsFetchingFailed = true;
            const agentsFetching = false;
            return state.merge({ agentsFetching, agentsFetchingFailed });
            break;
        }

        case AdministrationType.ADD_FM_CALL: {
            const fmPosting = true;
            return state.merge({ fmPosting });
            break;
        }

        case AdministrationType.ADD_FM_SUCCESS: {
            const fmPosting = false;
            const fmPostingSuccess = true;
            return state.merge({ fmPostingSuccess, fmPosting })
            break;
        }

        case AdministrationType.ADD_FM_FAILED: {
            const fmPosting = false;
            const fmPostingFailed = true;
            return state.merge({ fmPosting, fmPostingFailed });
            break;
        }

        case AdministrationType.GET_FMS_CALL: {
            const fmsFetching = true;
            return state.merge({ fmsFetching });
            break;
        }

        case AdministrationType.GET_FMS_SUCCESS: {
            const fieldManagers = action.data.data;
            const fmsFetching = false;
            const fmsPages = action.data.total_pages
            return state.merge({ fieldManagers, fmsFetching, fmsPages });
            break;
        }

        case AdministrationType.GET_FMS_FAILED: {
            const fmsFetchingFailed = true;
            const fmsFetching = false;
            return state.merge({ fmsFetching, fmsFetchingFailed });
            break;
        }


        case AdministrationType.ADD_STATE_MANAGER_CALL: {
            const stateManagersPosting = true;
            return state.merge({ stateManagersPosting });
            break;
        }

        case AdministrationType.ADD_STATE_MANAGER_SUCCESS: {
            const stateManagersPosting = false;
            const stateManagersPostingSuccess = true;
            return state.merge({ stateManagersPostingSuccess, stateManagersPosting })
            break;
        }

        case AdministrationType.ADD_STATE_MANAGER_FAILED: {
            const stateManagersPosting = false;
            const stateManagersPostingFailed = true;
            return state.merge({ stateManagersPosting, stateManagersPostingFailed });
            break;
        }

        case AdministrationType.GET_STATE_MANAGERS_CALL: {
            const statesManagersFetching = true;
            return state.merge({ statesManagersFetching });
            break;
        }

        case AdministrationType.GET_STATE_MANAGERS_SUCCESS: {
            const statesManagers = action.data.data;
            const statesManagersFetching = false;
            const stateManagersPages = action.data.total_pages
            return state.merge({ statesManagers, statesManagersFetching, stateManagersPages });
            break;
        }

        case AdministrationType.GET_STATE_MANAGERS_FAILED: {
            const statesManagersFetchingFailed = true;
            const statesManagersFetching = false;
            return state.merge({ statesManagersFetching, statesManagersFetchingFailed });
            break;
        }

        case AdministrationType.GET_USERS_CALL: {
            const usersFetching = true;
            return state.merge({ usersFetching });
            break;
        }

        case AdministrationType.GET_USERS_SUCCESS: {
            const users = action.data.data;
            const usersPages = action.data.total_pages
            const usersFetching = false;
            return state.merge({ users, usersPages, usersFetching });
            break;
        }

        case AdministrationType.GET_USERS_FAILED: {
            const usersFetchingFailed = true;
            return state.merge({ usersFetchingFailed });
            break;
        }


        case AdministrationType.ADD_USER_CALL: {
            const userPosting = true;
            return state.merge({ userPosting })
            break;
        }

        case AdministrationType.ADD_USER_SUCCESS: {
            const userPosting = false;
            const userPostingSuccess = true;
            return state.merge({ userPosting, userPostingSuccess });
            break;
        }

        case AdministrationType.ADD_USER_FAILED: {
            const userPosting = false;
            const userPostingFailed = true;
            return state.merge({ userPosting, userPostingFailed });
            break;
        }

        case AdministrationType.GET_ANCHORS_CALL: {
            const anchorsFetching = true;
            return state.merge({ anchorsFetching });
            break;
        }

        case AdministrationType.GET_ANCHORS_SUCCESS: {
            const anchors = action.data.data;
            const anchorsPages = action.data.total_pages
            const anchorsFetching = false;
            return state.merge({ anchors, anchorsPages, anchorsFetching });
            break;
        }

        case AdministrationType.GET_ANCHORS_FAILED: {
            const anchorsFetchingFailed = true;
            return state.merge({ anchorsFetchingFailed });
            break;
        }


        case AdministrationType.ADD_ANCHORS_CALL: {
            const anchorPosting = true;
            return state.merge({ anchorPosting })
            break;
        }

        case AdministrationType.ADD_ANCHORS_SUCCESS: {
            const anchorPosting = false;
            const anchorPostingSuccess = true;
            return state.merge({ anchorPosting, anchorPostingSuccess });
            break;
        }

        case AdministrationType.ADD_ANCHORS_FAILED: {
            const anchorPosting = false;
            const anchorPostingFailed = true;
            return state.merge({ anchorPosting, anchorPostingFailed });
            break;
        }

        case AdministrationType.GET_SOCIETIES_CALL: {
            const societiesFetching = true;
            return state.merge({ societiesFetching });
            break;
        }

        case AdministrationType.GET_SOCIETIES_SUCCESS: {
            const societies = action.data.data;
            const societiesPages = action.data.total_pages
            const societiesFetching = false;
            return state.merge({ societies, societiesPages, societiesFetching });
            break;
        }

        case AdministrationType.GET_SOCIETIES_FAILED: {
            const societiesFetchingFailed = true;
            return state.merge({ societiesFetchingFailed });
            break;
        }


        case AdministrationType.ADD_SOCIETY_CALL: {
            const societyPosting = true;
            return state.merge({ societyPosting })
            break;
        }

        case AdministrationType.ADD_SOCIETY_SUCCESS: {
            const societyPosting = false;
            const societyPostingSuccess = true;
            return state.merge({ societyPosting, societyPostingSuccess });
            break;
        }

        case AdministrationType.ADD_SOCIETY_FAILED: {
            const societyPosting = false;
            const societyPostingFailed = true;
            return state.merge({ societyPosting, societyPostingFailed });
            break;
        }

        case AdministrationType.GET_FARMERS_BY_SOCIETY_CALL: {
            const farmersBySocietyFetching = true;
            return state.merge({ farmersBySocietyFetching });
            break;
        }

        case AdministrationType.GET_FARMERS_BY_SOCIETY_SUCCESS: {
            const farmersBySociety = action.data.data;
            const farmersBySocietyFetching = false;
            const farmersBySocietyPages = action.data.total_pages
            return state.merge({ farmersBySociety, farmersBySocietyFetching, farmersBySocietyPages });
            break;
        }

        case AdministrationType.GET_FARMERS_BY_SOCIETY_FAILED: {
            const farmersBySocietyFetchingFailed = true;
            const farmersBySocietyFetching = false;
            return state.merge({ farmersBySocietyFetchingFailed, farmersBySocietyFetching });
            break;
        }

        case AdministrationType.GET_FARMER_DROPDOWN_SUCCESS: {
            const farmersDropdown = action.data.data;
            const farmersDropdownFetching = false;
            return state.merge({ farmersDropdown, farmersDropdownFetching });
            break;
        }

        case AdministrationType.ADD_FARMERS_TO_SOCIETY_SUCCESS: {
            const addFarmersToSocietySuccess = true;
            return state.merge({ addFarmersToSocietySuccess })
        }
        //////////////
        case AdministrationType.ADD_AGENT_COS_CALL: {
            const addAgentCosPosting = true;
            return state.merge({ addAgentCosPosting })
            break;
        }

        case AdministrationType.ADD_AGENT_COS_SUCCESS: {
            const addAgentCosPosting = false;
            const addAgentCosPostingSuccess = true;
            return state.merge({ addAgentCosPosting, addAgentCosPostingSuccess });
            break;
        }

        case AdministrationType.ADD_AGENT_COS_FAILED: {
            const addAgentCosPosting = false;
            const addAgentCosPostingFailed = true;
            return state.merge({ addAgentCosPosting, addAgentCosPostingFailed });
            break;
        }

        case AdministrationType.GET_COS_DROPDOWN_SUCCESS: {
            const cosDropdown = action.data.data;
            const cosDropdownFetching = false;
            return state.merge({ cosDropdown, cosDropdownFetching });
            break;
        }

        case AdministrationType.GET_ANCHOR_DROPDOWN_SUCCESS: {
            const anchorDropdown = action.data.data;
            const anchorDropdownFetching = false;
            return state.merge({ anchorDropdown, anchorDropdownFetching });
            break;
        }

        case AdministrationType.GET_COS_BY_AGENT_CALL: {
            const cosByAgentFetching = true;
            return state.merge({ cosByAgentFetching });
            break;
        }

        case AdministrationType.GET_COS_BY_AGENT_SUCCESS: {
            const cosByAgent = action.data.data;
            const cosByAgentFetching = false;
            const cosByAgentPages = action.data.total_pages
            return state.merge({ cosByAgent, cosByAgentFetching, cosByAgentPages });
            break;
        }

        case AdministrationType.GET_COS_BY_AGENT_FAILED: {
            const cosByAgentFetchingFailed = true;
            const cosByAgentFetching = false;
            return state.merge({ cosByAgentFetchingFailed, cosByAgentFetching });
            break;
        }


        case AdministrationType.DELETE_COS_AGENT_SUCCESS: {
            const array = state['cosByAgent'].asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ cosByAgent: array, deleted: deleted })
            break;

        }

        ///
        //
        case AdministrationType.ADD_ANCHOR_AGENT_CALL: {
            const addAnchorAgentPosting = true;
            return state.merge({ addAnchorAgentPosting })
            break;
        }

        case AdministrationType.ADD_ANCHOR_AGENT_SUCCESS: {
            const addAnchorAgentPosting = false;
            const addAnchorAgentPostingSuccess = true;
            return state.merge({ addAnchorAgentPosting, addAnchorAgentPostingSuccess });
            break;
        }

        case AdministrationType.ADD_ANCHOR_AGENT_FAILED: {
            const addAnchorAgentPosting = false;
            const addAnchorAgentPostingFailed = true;
            return state.merge({ addAnchorAgentPosting, addAnchorAgentPostingFailed });
            break;
        }

        case AdministrationType.GET_AGENT_DROPDOWN_SUCCESS: {
            const agentDropdown = action.data.data;
            const agentDropdownFetching = false;
            return state.merge({ agentDropdown, agentDropdownFetching });
            break;
        }

        case AdministrationType.GET_ANCHOR_AGENT_CALL: {
            const anchorAgentFetching = true;
            return state.merge({ anchorAgentFetching });
            break;
        }

        case AdministrationType.GET_ANCHOR_AGENT_SUCCESS: {
            const anchorAgent = action.data.data;
            const anchorAgentFetching = false;
            const anchorAgentPages = action.data.total_pages
            return state.merge({ anchorAgent, anchorAgentFetching, anchorAgentPages });
            break;
        }

        case AdministrationType.GET_ANCHOR_AGENT_FAILED: {
            const anchorAgentFetchingFailed = true;
            const anchorAgentFetching = false;
            return state.merge({ anchorAgentFetchingFailed, anchorAgentFetching });
            break;
        }


        case AdministrationType.DELETE_AGENT_ANCHOR_SUCCESS: {
            const array = state['anchorAgent'].asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ anchorAgent: array, deleted: deleted })
            break;

        }
        case AdministrationType.GET_ANCHOR_AGENT_DROPDOWN_CALL: {
            const anchorAgentDropdownFetching = true;
            return state.merge({ anchorAgentDropdownFetching });
            break;
        }

        case AdministrationType.GET_ANCHOR_AGENT_DROPDOWN_SUCCESS: {
            const anchorAgentDropdown = action.data.data;
            const anchorAgentDropdownFetching = false;
            return state.merge({ anchorAgentDropdown, anchorAgentDropdownFetching });
            break;
        }

        case AdministrationType.GET_ANCHOR_AGENT_FAILED: {
            const anchorAgentDropdownFetchingFailed = true;
            const anchorAgentDropdownFetching = false;
            return state.merge({ anchorAgentDropdownFetchingFailed, anchorAgentDropdownFetching });
            break;
        }

        case AdministrationType.ANCHOR_DOCUMENTS_CALL: {
            const anchorDocumentsFetching = true;
            return state.merge({ anchorDocumentsFetching });
            break;
        }

        case AdministrationType.ANCHOR_DOCUMENTS_SUCCESS: {
            const anchorDocuments = action.data.data;
            const anchorDocumentsFetching = false;
            return state.merge({ anchorDocuments, anchorDocumentsFetching });
            break;
        }

        case AdministrationType.ANCHOR_DOCUMENTS_FAILED: {
            const anchorDocumentsFetchingFailed = true;
            const anchorDocumentsFetching = false;
            return state.merge({ anchorDocumentsFetching, anchorDocumentsFetchingFailed });
            break;
        }

        case AdministrationType.GET_EXECUTIVE_CALL: {
            const executivesFetching = true;
            return state.merge({ executivesFetching });
            break;
        }

        case AdministrationType.GET_EXECUTIVE_SUCCESS: {
            const executives = action.data.data;
            const executivesFetching = false;
            return state.merge({ executives, executivesFetching });
            break;
        }

        case AdministrationType.GET_EXECUTIVE_FAILED: {
            const executivesFetchingFailed = true;
            const executivesFetching = false;
            return state.merge({ executivesFetchingFailed, executivesFetching });
            break;
        }

        case AdministrationType.ADD_EXECUTIVE_CALL: {
            const executivesPosting = true;
            return state.merge({ executivesPosting });
            break;
        }

        case AdministrationType.ADD_EXECUTIVE_SUCCESS: {
            const executivesPosting = false;
            const executivesPostingSuccess = true;
            return state.merge({ executivesPostingSuccess, executivesPosting })
            break;
        }

        case AdministrationType.ADD_EXECUTIVE_FAILED: {
            const executivesPosting = false;
            const executivesPostingFailed = true;
            return state.merge({ executivesPosting, executivesPostingFailed });
            break;
        }

        //
        //
        //
        //


        case AdministrationType.DELETE_FARMERS_SUCCESS: {
            const array = state['farmersBySociety'].asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ farmersBySociety: array, deleted: deleted })
            break;
        }

        default:
            return state;
    }
}