import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Card } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './ImageryStyles';

export class LAI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.props.language === 1 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                LAI (Indeks lisne površine) predstavlja odnos površine listova prema površini vegetacionog prostora.
                                Koristi se za procenu primarne fotosintetičke produkcije, procenu dinamike formiranja organske
                                materije, procenu evapotranspiracije, procenu uticaja klime na stanje vegetacije i dr
                            </p>
                            <p className={classes.paragraph}>
                                LAI je bezdimenzionalna vrednost u opsegu od 0 do 10. Vrednost 0 označava golo zemljište, vrednost 1
                                označava da postoji jedan sloj lišća koji u potpunosti prekriva jedinicu površine, vrednost od 0 do 1
                                označava da lišće ne pokriva u potpunosti jediničnu površinu a vrednosti preko 1 označavaju da postoji
                                više slojeva lišća po jediničnoj površini.
                            </p>
                        </div>
                    </Card>
                }
                {this.props.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                Leaf area index (LAI) is a dimensionless quantity that characterizes plant canopies. It is defined as the
                                one-sided green leaf area per unit ground surface area (LAI = leaf area / ground area, m2 / m2)
                                in broadleaf canopies. In conifers, three definitions for LAI have been used:
                            </p>
                            <ul>
                                <li className={classes.paragraph}>Half of the total needle surface area per unit ground surface area</li>
                                <li className={classes.paragraph}>Projected (or one-sided, in accordance the definition for broadleaf canopies) needle area perunit ground area</li>
                                <li className={classes.paragraph}>Total needle surface area per unit ground area</li>
                            </ul>
                            <p className={classes.paragraph}>
                                LAI ranges from 0 (bare ground) to over 10 (dense conifer forests)
                            </p>
                            <p className={classes.paragraph}>
                                LAI is used to predict photosynthetic primary production, evapotranspiration and as a reference tool
                                for crop growth. As such, LAI plays an essential role in theoretical production ecology.
                            </p>
                        </div>
                    </Card>
                }
                {this.props.language === 4 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            LAI (Leaf Area Index) predstavlja omjer lisne površine i površine vegetacijske površine. 
                            Koristi se za ocjenu primarne fotosintetske produkcije, procjenu dinamike stvaranja organske tvari, 
                            procjenu evapotranspiracije, ocjenu utjecaja klime na stanje vegetacije itd.    
                            </p>
                            <p className={classes.paragraph}>
                            LAI je bezdimenzionalna vrijednost u rasponu od 0 do 10. Vrijednost 0 označava golo tlo a 
                            vrijednost 1 označava da postoji jedan sloj lišća koji potpuno prekriva jedinicu površine. 
                            Vrijednosti od 0 do 1 označavaju da lišće u potpunosti ne pokriva jedinicu površine, 
                            a vrijednosti iznad 1 označavaju da postoji više slojeva lišća po jedinici površine.
                            </p>
                        </div>
                    </Card>
                    
                }
            </div>
        )
    }
};

export default withStyles(styles)(LAI);