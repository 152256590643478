/**
 * Created by pajicv on 12/5/18.
 */
import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    button: {
        background: '#fff',
        position: 'absolute',
        top: '10px',
        left: '120px',
        width: '42px',
        height: '42px',
        zIndex: '10002',
        padding: '5px 5px 5px 5px',
        font: '13px Arial, Helvetica, sans-serif',
        borderRadius: '5px',
        border: '1px solid rgba(0,0,0,1)'
    }
});

const TrackingButton = ({ classes, onClick }) => (
    <div className={classes.button}
         onClick={onClick}>
        <img
            width={36}
            height={36}
            src={require('../../../assets/images/agrolife.png')}
        />
    </div>
);

export default withStyles(styles)(TrackingButton);