import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import i18n from '../../i18n/i18n';
import Select from 'react-select';
import { Fab, Typography } from '@material-ui/core';
import { wentWrong } from "../../utils/ToastConfig";
import axios from '../../utils/AxiosWrapper';
import checkboxHOC from "react-table/lib/hoc/selectTable";

const CheckboxTable = checkboxHOC(ReactTable);;

export default class ViewActivities extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            regionType: [], activityType: [],
            showFilters: false, switchFlag: false, loading: false,
            page: 0, pageSize: 25, sorted: null, filtered: null,
            selection: [], selectAll: false, selectedRow: [],
            totalPages: 0, totalActivities: 0,
            add: false, edit: false, delete: false,
            data: [],
        };
    }

    componentDidMount() {
        this.getByURL('region/filter_region', 'regionType');
        // this.getByURL('institution/filter_institution', 'institutionType');
    }

    getData(url, state, instance) {
        this.setState({ loading: true, data: [] });
        axios.get(`api/${url}`, {
            params: { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered }
        })
            .then(res => {
                this.setState({
                    data: res.data.data,
                    totalPages: res.data.total_pages,
                    totalActivities: res.data.total,
                    loading: false
                });
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: res.data.data }))
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    }

    onTableClick = () => this.props.history.push('/ap_activities');
    onSearchClick = () => this.setState({ showFilters: !this.state.showFilters });
    isSelected = key => { return this.state.selection.includes(key); };

    render() {
        let regionType = this.state.regionType.map((item) => { return { label: item.naziv, value: item.id } });
        let zakupacType = [{ label: "Da", value: true }, { label: "Ne", value: false }];
        // let institutionType = this.state.institutionType.map((item) => { return { label: item.naziv, value: item.id } });

        const columns = [
            { Header: i18n.t('ID'), accessor: 'id', width: 60 },
            { Header: i18n.t('Working operation'), accessor: 'naziv_radna_operacija' },
            { Header: i18n.t('Status'), accessor: 'naziv_status' },
            { Header: i18n.t('Client'), accessor: 'naziv_klijent' },
            { Header: i18n.t('Client group'), accessor: 'naziv_klijent_grupa' },
            { Header: i18n.t('User group'), accessor: 'naziv_korisnik_grupa' },
            // { Header: i18n.t('Time created'), accessor: 'vreme_kreiranja' },
            {
                id: 'vreme_kreiranja', Header: i18n.t('Date created'), accessor: item => item.vreme_kreiranja, Cell: ({ value }) => {
                    if (value !== null) return value.substring(8, 10) + '-' + value.substring(5, 7) + '-' + value.substring(0, 4);
                    else return '';
                }
            },
            {
                id: 'vreme_kreiranja', Header: i18n.t('Time created'), accessor: item => item.vreme_kreiranja, Cell: ({ value }) => {
                    if (value !== null) return value.substring(11, 19)
                    else return '';
                }
            },
            { Header: i18n.t('Region'), accessor: 'naziv_region' },
            { Header: i18n.t('State'), accessor: 'naziv_opstina' },
            { Header: i18n.t('Local government'), accessor: 'naziv_katastarska_opstina' },
            { Header: i18n.t('Season'), accessor: 'naziv_sezona' },
            // { Header: i18n.t('Region'), accessor: 'naziv_regiona', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={regionType} /> },
            { id: 'aktivan', Header: i18n.t('Aktivan'), accessor: d => d.aktivan, Cell: ({ value }) => value != 0 ? i18n.t('Yes') : i18n.t('No') },
            {
                id: 'zakupac',
                Header: "Zakupac",
                accessor: d => { return d.zakupac ? 'Da' : 'Ne' },
                Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={zakupacType} />
            },
            {
                id: 'navodnjavanje',
                Header: "Navodnjavanje",
                accessor: d => { return d.navodnjavanje ? 'Da' : 'Ne' },
                Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={zakupacType} />
            }
        ];

        const url = 'activity/read';

        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll, isSelected, toggleSelection, toggleAll,
            // selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return { style: { backgroundColor: selected ? "#ACDAF1" : "inherit" } };
                }
                return {};
            },
            // getTheadFilterProps: () => { return { style: { backgroundColor: '#9B9758' } }; },
            // getTheadTrProps: () => { return { style: { backgroundColor: '#04764e', color: '#FFFFFF' } }; },
        };

        return (
            <div className="page">
                <div className="table-header">
                    <div style={{ display: 'flex' }}>
                        <Fab onClick={() => this.onSearchClick()}
                            color="secondary"
                            aria-label="add"
                            className="action-button">
                            <span className="icon-search headerIcon" />
                        </Fab>
                        {this.state.totalActivities > 0 && <Typography style={{ marginLeft: '20px' }}>Total: {this.state.totalActivities}</Typography>}
                    </div>
                </div>
                <ReactTable ref={r => (this.checkboxTable = r)}
                    manual
                    data={this.state.data}
                    paging={true}
                    columns={columns}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.state.totalPages}
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => { this.getData(url, state, instance); }}
                    loading={this.state.loading}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF',
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
            </div>
        )
    }
};

function FilterSelect(props) {
    return <Select
        isMulti
        name="naziv"
        onChange={value => { props.onChange(value) }}
        closeMenuOnSelect={false}
        options={props.type}
        className="multi-select-in-filter"
        classNamePrefix="select"
        placeholder={i18n.t('Select')}
    />
}