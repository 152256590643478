import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Fab, FormControlLabel, Checkbox, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import i18n from '../../i18n/i18n';
import Select from 'react-select';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import { setInitialState, setRowInReducer, getTicketRead, deleteTicket, getTicketTypeDropDown } from '../../actions/TicketActions';
import { getRegion } from '../../actions/LoginActions';
import moment from 'moment';
import { Delete } from '../../utils/Delete';
import TicketItem from './TicketItem'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUser } from '../../utils/CommonFunctions';
import Support from '../Support';
import NumberRangeForFilter from '../../utils/NumberRangeForFilter';
import immutable from 'seamless-immutable';
import DashboardDrawer from '../../utils/DashboardDrawer';
import { exportDocument } from '../../actions/ExportActions';
import Export from '../../utils/Export';
import _ from 'lodash';

const CheckboxTable = checkboxHOC(ReactTable);

const styles = theme => ({
    center: {
        textAlign: 'center'
    },
    expander: {
        cursor: "pointer",
        fontSize: 25,
        padding: "0",
        textAlign: "center",
        userSelect: "none"
    },
    checkbox: {
        padding: '0 12px'
    }
});

export class ViewTicket extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            selection: [],
            delete: false,
            selectAll: false,
            name: '',
            edit: false,
            expanded: {},
            mineTickets: false,
            support: false,
            user: {}
        };
    }


    async componentDidMount() {
        let user = await getUser();
        if (user) {
            this.setState({ user: user })
        }
        if (this.props.ticketTypeDropDown.length == 0) {
            this.props.getTicketTypeDropDown();
        }
        if (this.props.region.length == 0) {
            this.props.getRegion();
        }

    }
    componentWillReceiveProps(nextProps) {

        this.setState({
            tickets: nextProps.tickets,
            ticketsFetching: nextProps.ticketsFetching,
            ticketTypeDropDown: immutable.asMutable(nextProps.ticketTypeDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            region: immutable.asMutable(nextProps.region.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            regionFetching: nextProps.regionFetching,
            regionFetchingFailed: nextProps.regionFetchingFailed,
        })
        if (nextProps.deleted === true) {
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getTicketRead(wrapped.state, '', this.state.mineTickets);
            this.setState({
                selection: [],
                selectAll: false,
                name: ''
            })
            this.props.setInitialState('ticketObj');
            this.props.setInitialState('deleted');
        }
        if (nextProps.deleteTicketFailed === true) {
            toast.error(i18n.t('This item can not be deleted!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('deleteTicketFailed');
        }
        if (nextProps.ticketLockSuccess === true) {
            this.setState({
                selection: [],
                name: '',
                selectAll: false
            })
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getTicketRead(wrapped.state, '', this.state.mineTickets);
            this.props.setInitialState('ticketLockSuccess');
        }
        if (nextProps.ticketSentSuccess === true) {
            this.setState({
                selection: [],
                name: '',
                selectAll: false
            })
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getTicketRead(wrapped.state, '', this.state.mineTickets);
        }

    }

    onSupportClick = () => {
        this.setState({ support: !this.state.support });
    }

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
            this.props.setInitialState('ticketObj');
        } else {
            selection.push(key);
        }
        this.setState({ selection }, function () {
            if (selection.length == 1) {
                let row1 = this.props.tickets.filter((parc) => {
                    return parc.id == selection;
                })
                this.props.setRowInReducer(row1[0]);
            }
        });
    };

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });
    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    onDeleteClick = () => {
        this.setState({ delete: !this.state.delete });
    }

    deleteItem = () => {
        this.props.deleteTicket(this.state.selection);
        this.setState({ delete: false })
    }

    onEditClick() {
        this.setState({ add: !this.state.add, edit: true });
    }

    handleRowExpanded(newExpanded, index, event) {
        if (this.state.expanded.hasOwnProperty(index)) {
            this.setState({
                expanded: {}
            });
        } else this.setState({
            expanded: { [index]: true }
        });
    }
    handleExpandClose() {
        this.setState({ expanded: {} });
    }

    handleChangeCheckbox = name => event => {
        this.setState({ [name]: event.target.checked, expanded: {} }, () => {
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getTicketRead(wrapped.state, '', this.state.mineTickets);
        });
    };

    onPieClick = () => {
        this.props.history.push('/ticket_dashboard');
    }

    onTableClick = () => {
        this.props.history.push('/ticket');
    }

    onExportClick = () => {
        this.setState({ export: !this.state.export });
    }

    exportFunction = () => {
        const table = this.checkboxTable.getWrappedInstance();
        this.props.exportDocument(this.state.docTitle, table.state, 'api/ticket/read', this.state.docFormat || 'xlsx');
        this.setState({ export: !this.state.export });
    }

    render() {
        const { classes } = this.props;
        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll, user } = this.state;
        const columns = [
            {
                show: user.id_klijent_grupa == 12 ? true : false,
                Header: i18n.t('Region'),
                accessor: 'naziv_region',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.region}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('User'),
                accessor: 'korisnik',
                maxWidth: 200,
            },
            {
                Header: i18n.t('Title'),
                accessor: 'naziv',
            },

            // {
            //     Header: i18n.t('Date'),
            //     id: 'primljen_tiket_datum',
            //     accessor: d => d.primljen_tiket_datum ? moment(d.primljen_tiket_datum, 'YYYY-MM-DD').format('DD.MM.YYYY') : ''
            // },
            // {
            //     Header: i18n.t('Type'),
            //     accessor: 'vrsta',
            //     maxWidth: 100,
            //     Filter: ({ filter, onChange }) =>
            //         <Select
            //             isMulti
            //             name="naziv"
            //             onChange={value => {
            //                 onChange(value)
            //             }}
            //             closeMenuOnSelect={false}
            //             options={this.state.ticketTipeDropDown}
            //             className="multi-select-in-filter"
            //             classNamePrefix="select"
            //             placeholder={i18n.t('Select')}
            //         />
            // },
            // {
            //     Header: i18n.t('Module'),
            //     accessor: 'modul',
            //     maxWidth: 100,
            // },
            // {
            //     Header: i18n.t('Form'),
            //     accessor: 'forma',
            //     maxWidth: 100,
            // },
            {
                Header: i18n.t('Category'),
                accessor: 'category',
                maxWidth: 100,
            },
            {
                Header: i18n.t('In charge'),
                accessor: 'zaduzena_osoba',
                maxWidth: 180,
            },
            {
                id: 'vreme_izmene',
                Header: i18n.t('Last change'),
                aggregate: (vals, rows) => _.uniq(vals).join(','),
                accessor: d => d.vreme_izmene ? moment(d.vreme_izmene).format('DD.MM.YYYY') : '',
                maxWidth: 100,
            },
            {
                Header: i18n.t('Status'),
                accessor: 'tiket_status',
                maxWidth: 100,
            },
            {
                Header: i18n.t('Waiting for approval'),
                accessor: 'na_cekanju',
                maxWidth: 70,
                Cell: ({ value }) => value == true ? i18n.t('Yes') : i18n.t('No'),
                Filter: ({ filter, onChange }) =>
                    <select
                        onChange={event => {
                            if (event.target.value == 'true') {
                                return onChange(true)
                            } else if (event.target.value == 'false') {
                                return onChange(false)
                            }
                            return onChange()
                        }}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                    >
                        <option value="all">{i18n.t('All')}</option>
                        <option value={true}>{i18n.t('Yes')}</option>
                        <option value={false}>{i18n.t('No')}</option>
                    </select>
            },
            {
                Header: i18n.t('Accepted'),
                accessor: 'resen_tiket',
                maxWidth: 70,
                Cell: ({ value }) => value == true ? i18n.t('Yes') : i18n.t('No'),
                Filter: ({ filter, onChange }) =>
                    <select
                        onChange={event => {
                            if (event.target.value == 'true') {
                                return onChange(true)
                            } else if (event.target.value == 'false') {
                                return onChange(false)
                            }
                            return onChange()
                        }}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                    >
                        <option value="all">{i18n.t('All')}</option>
                        <option value={true}>{i18n.t('Yes')}</option>
                        <option value={false}>{i18n.t('No')}</option>
                    </select>
            },
            {
                Header: i18n.t('Number'),
                accessor: 'id',
                maxWidth: 70,
                className: classes.center,
                Filter: ({ filter, onChange }) =>
                    <NumberRangeForFilter
                        onChange={(s) => {
                            onChange(s);
                        }}

                    />
            },

            {
                Header: i18n.t('Spent hours'),
                accessor: 'vreme_sat',
                maxWidth: 100,
                Footer: (
                    <span>
                        <strong>{i18n.t('Total')}:</strong>{" "}
                        {this.props.tickets &&
                            this.props.tickets.reduce((acc, current) => acc += parseInt(current.vreme_sat), 0)}
                    </span>
                ),
            },
            {
                Header: i18n.t('Priority'),
                accessor: 'prioritet',
                maxWidth: 70,
                className: classes,
                Cell: row => {
                    let color = 'white'
                    if (row.value === 1) {
                        color = 'brown';
                    } else if (row.value > 1 && row.value <= 10) {
                        color = 'red';
                    } else if (row.value > 10 && row.value <= 20) {
                        color = 'yellow';
                    } else if (row.value > 20 && row.value <= 30) {
                        color = 'green';
                    } else if (row.value > 30 && row.value <= 70) {
                        color = '#21fcbf';
                    } else if (row.value > 70 && row.value <= 100) {
                        color = 'blue';
                    }
                    return (
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundColor: '#dadada',
                                borderRadius: '10px'
                            }}
                        >
                            <div
                                style={{
                                    width: `100%`,
                                    height: '100%',
                                    backgroundColor: color,
                                    borderRadius: '10px',
                                    transition: 'all .2s ease-out',
                                    textAlign: 'center'
                                }}
                            >
                                {row.value}
                            </div>
                        </div>
                    )
                }
            },
            {
                Header: () => <strong>{i18n.t('Item')}</strong>,
                width: 65,
                expander: true,
                className: classes.expander,
                Expander: ({ isExpanded, ...rest }) =>
                    <div>
                        {isExpanded
                            ? <span>&#x2296;</span>
                            : <span>&#x2295;</span>}
                    </div>
            }
        ];
        const filterOptions = {
            defaultFiltered:
                [
                    {
                        id: 'resen_tiket',
                        value: false
                    }
                ]
        };
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "lightgreen" : "inherit"
                        }
                    };
                }
                return {};
            }
        };
        const subComponentProps = {
            SubComponent: (row) => {
                return <TicketItem row={row.original} />
            }
        };

        return (
            <div className="page" >
                <div className="table-header">
                    <Tooltip title={i18n.t('Create ticket')}>
                        <Fab
                            onClick={() => this.onSupportClick()}
                            color="primary"
                            aria-label="add"
                            className="action-button"
                        >
                            <span className="icon-Plus headerIcon" />
                        </Fab>
                    </Tooltip>
                    <Fab
                        onClick={() => this.onExportClick()}
                        color="primary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-download headerIcon" />
                    </Fab>
                    {!this.props.klijent_naziv ?
                        <Fab
                            onClick={() => this.onSearchClick()}
                            color="primary"
                            aria-label="add"
                            className="action-button"
                        >
                            <span className="icon-search headerIcon" />
                        </Fab> : null}
                    {this.state.selection.length > 0 ?
                        <Fab
                            onClick={() => this.onDeleteClick()}
                            color="primary"
                            aria-label="add"
                            className="action-button"
                        >
                            <span className="icon-Kanta headerIcon" />
                        </Fab>
                        : null
                    }

                    {
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.mineTickets}
                                    className={classes.checkbox}
                                    onChange={this.handleChangeCheckbox('mineTickets')}
                                    value="mineTickets"
                                />
                            }
                            label={i18n.t('My tickets')}
                            labelPlacement="start"
                        />
                    }
                </div>
                <CheckboxTable data={this.props.tickets}
                    ref={r => (this.checkboxTable = r)}
                    keyField={'id'}
                    getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                    columns={columns}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.props.ticketsPages}
                    loading={this.props.ticketsFetching}
                    manual
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => {
                        this.handleExpandClose()
                        return this.props.getTicketRead(state, instance, this.state.mineTickets)
                    }}
                    className="-striped -highlight table-drawer"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    expanded={this.state.expanded}
                    onExpandedChange={(newExpanded, index, event) => this.handleRowExpanded(newExpanded, index, event)}
                    defaultSorted={[
                        {
                            id: "id",
                            desc: true
                        }
                    ]}
                    {...checkboxProps}
                    {...filterOptions}
                    {...subComponentProps}
                />
                <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} />
                {this.state.support && <Support open={this.state.support}
                    handleClose={this.onSupportClick} />}
                {this.state.delete && <Delete delete={this.state.delete} handleClose={this.onDeleteClick} deleteItem={this.deleteItem} />}
                {this.state.export && <Export
                    export={this.state.export}
                    onTextChange={({ title }) => {
                        this.setState({ docTitle: title });
                    }}
                    changeFormat
                    onformatChange={(format) => {
                        this.setState({ docFormat: format });
                    }}
                    handleClose={this.onExportClick}
                    exportDoc={this.exportFunction} />
                }
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        tickets: state.ticketReducer.tickets,
        ticketsFetching: state.ticketReducer.ticketsFetching,
        ticketsPages: state.ticketReducer.ticketsPages,
        deleted: state.ticketReducer.deleted,
        deleteTicketFailed: state.ticketReducer.deleteTicketFailed,
        ticketTypeDropDown: state.ticketReducer.ticketTypeDropDown,
        ticketLockSuccess: state.ticketReducer.ticketLockSuccess,
        ticketSentSuccess: state.appReducer.ticketSentSuccess,
        region: state.appReducer.region,
        regionFetching: state.appReducer.regionFetching,
        regionFetchingFailed: state.appReducer.regionFetchingFailed,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setRowInReducer: (rowInfo) => dispatch(setRowInReducer(rowInfo)),
        setInitialState: (component) => dispatch(setInitialState(component)),
        deleteTicket: (selection) => dispatch(deleteTicket(selection)),
        getTicketTypeDropDown: () => dispatch(getTicketTypeDropDown()),
        getTicketRead: (state, instance, mineTickets) => dispatch(getTicketRead(state, instance, mineTickets)),
        getRegion: () => dispatch(getRegion()),
        exportDocument: (title, state, url, format) => dispatch(exportDocument(title, state, url, format)),
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewTicket))