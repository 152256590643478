import immutable from 'seamless-immutable';
import { Type as IncentivesType } from '../../actions/financial/IncentiveActions';


export const INITIAL_STATE = immutable({
    incentives: [],
    incentivesFetching: false,
    incentivesFetchingFailed: false,
    incentivesPages: 1,
    incentivesObj: {},

    //dropdown
    farmerDropdown: [],
    farmerDropdownFetching: false,
    farmerDropdownFetchingFailed: false,
    //ADD
    incentivesPostingFailed: false,
    incentivesPosting: false,
    incentivesPostingSuccess: false,
    //DASHBOARD
    incentivesItems: {},
    incentivesItemsFetching: false,
    incentivesItemsFetchingFailed: false,

    deleted: false,
    deleteIncentiveFailed: false

});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case IncentivesType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;

        case IncentivesType.SET_INCETIVES_ROW_IN_REDUCER: {
            const data = action.rowInfo.asMutable();
            return state.merge({ [action.name]: data })
            break;
        }

        case IncentivesType.GET_FARMER_DROP_DOWN_CALL: {
            const farmerDropdownFetching = true;
            return state.merge({ farmerDropdownFetching });
            break;
        }

        case IncentivesType.GET_FARMER_DROP_DOWN_SUCCESS: {
            const farmerDropdown = action.data.data;
            const farmerDropdownFetching = false;
            return state.merge({ farmerDropdown, farmerDropdownFetching });
            break;
        }

        case IncentivesType.GET_FARMER_DROP_DOWN_FAILED: {
            const farmerDropdownFailed = true;
            const farmerDropdown = false;
            return state.merge({ farmerDropdownFailed, farmerDropdown });
            break;
        }

        case IncentivesType.GET_INCENTIVES_CALL: {
            const incentivesFetching = true;
            return state.merge({ incentivesFetching });
            break;
        }

        case IncentivesType.GET_INCENTIVES_SUCCESS: {
            const incentives = action.data.data;
            const incentivesFetching = false;
            const incentivesPages = action.data.total_pages
            return state.merge({ incentives, incentivesFetching, incentivesPages });
            break;
        }

        case IncentivesType.GET_INCENTIVES_FAILED: {
            const incentivesFetchingFailed = true;
            const incentivesFetching = false;
            return state.merge({ incentivesFetching, incentivesFetchingFailed });
            break;
        }


        case IncentivesType.GET_INCENTIVE_DASHBOARD_CALL: {
            const incentivesItemsFetching = true;
            return state.merge({ incentivesItemsFetching });
            break;
        }

        case IncentivesType.GET_INCENTIVE_DASHBOARD_SUCCESS: {
            const incentivesItems = action.data;
            const incentivesItemsFetching = false;
            return state.merge({ incentivesItems, incentivesItemsFetching });
            break;
        }

        case IncentivesType.GET_INCENTIVE_DASHBOARD_FAILED: {
            const incentivesItemsFetchingFailed = true;
            const incentivesItemsFetching = false;
            return state.merge({ incentivesItemsFetching, incentivesItemsFetchingFailed });
            break;
        }


        case IncentivesType.ADD_INCENTIVE_CALL: {
            const incentivesPosting = true;
            return state.merge({ incentivesPosting });
            break;
        }

        case IncentivesType.ADD_INCENTIVE_SUCCESS: {
            const incentivesPosting = false;
            const incentivesPostingSuccess = true;
            return state.merge({ incentivesPostingSuccess, incentivesPosting, incentivesObj: INITIAL_STATE.incentivesObj })
            break;
        }

        case IncentivesType.ADD_INCENTIVE_FAILED: {
            const incentivesPosting = false;
            const incentivesPostingFailed = true;
            return state.merge({ incentivesPosting, incentivesPostingFailed });
            break;
        }

        case IncentivesType.DELETE_INCENTIVE_SUCCESS: {
            const array = state['incentives'].asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ incentives: array, deleted: deleted })
            break;
        }

        case IncentivesType.DELETE_INCENTIVE_FAILED: {
            const deleteIncentiveFailed = true;
            return state.merge({ deleteIncentiveFailed })
        }

        default:
            return state;
    }
}