import immutable from 'seamless-immutable';
import { Type as FieldType } from '../../actions/catastral_data/FieldActions';


export const INITIAL_STATE = immutable({
    //ADD
    fieldPosting: false,
    fieldPostingSuccess: false,
    fieldPostingFailed: false,
    //VIEW
    field: [],
    fieldObj: {},
    fieldFetching: false,
    fieldPages: 1,
    //DROPDOWN
    fieldDropDown: [],
    fieldFetchingDropDown: false,
    fieldFetchingFailedDropDown: false,
    typeProductionDropDown: [],
    typeProductionFetchingDropDown: false,
    typeProductionFetchingFailedDropDown: false,

    //DASHBOARD
    fieldItems: {},
    fieldItemsFetching: false,
    fieldItemsFetchingFailed: false,

    deleted: false,
    deleteFieldFailed: false

});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {


        case FieldType.ADD_FIELD_CALL: {
            const fieldPosting = true;
            return state.merge({ fieldPosting });
            break;
        }

        case FieldType.ADD_FIELD_SUCCESS: {
            const fieldPosting = false;
            const fieldPostingSuccess = true;
            return state.merge({ fieldPostingSuccess, fieldPosting, fieldObj: INITIAL_STATE.fieldObj })
            break;
        }

        case FieldType.ADD_FIELD_FAILED: {
            const fieldPosting = false;
            const fieldPostingFailed = true;
            return state.merge({ fieldPosting, fieldPostingFailed });
            break;
        }

        case FieldType.GET_VIEW_FIELDS_CALL: {
            const fieldFetching = true;
            return state.merge({ fieldFetching });
            break;
        }

        case FieldType.GET_VIEW_FIELDS_SUCCESS: {
            const field = action.data.data;
            const fieldFetching = false;
            const fieldPages = action.data.total_pages
            return state.merge({ field, fieldFetching, fieldPages });
            break;
        }

        case FieldType.GET_VIEW_FIELDS_FAILED: {
            const fieldFetchingFailed = true;
            const fieldFetching = false;
            return state.merge({ fieldFetching, fieldFetchingFailed });
            break;
        }

        case FieldType.GET_FIELD_CALL: {
            const fieldFetchingDropDown = true;
            return state.merge({ fieldFetchingDropDown });
            break;
        }

        case FieldType.GET_FIELD_SUCCESS: {
            const fieldDropDown = action.data.data;
            const fieldFetchingDropDown = false;
            return state.merge({ fieldDropDown, fieldFetchingDropDown });
            break;
        }

        case FieldType.GET_FIELD_FAILED: {
            const fieldFetchingFailedDropDown = true;
            const fieldFetchingDropDown = false;
            return state.merge({ fieldFetchingDropDown, fieldFetchingFailedDropDown });
            break;
        }

        case FieldType.GET_FIELDS_DASHBOARD_CALL: {
            const fieldItemsFetching = true;
            return state.merge({ fieldItemsFetching });
            break;
        }

        case FieldType.GET_FIELDS_DASHBOARD_SUCCESS: {
            const fieldItems = action.data;
            const fieldItemsFetching = false;
            return state.merge({ fieldItems, fieldItemsFetching });
            break;
        }

        case FieldType.GET_FIELDS_DASHBOARD_FAILED: {
            const fieldItemsFetchingFailed = true;
            const fieldItemsFetching = false;
            return state.merge({ fieldItemsFetching, fieldItemsFetchingFailed });
            break;
        }


        case FieldType.GET_TYPE_PRODUCTION_CALL: {
            const typeProductionFetchingDropDown = true;
            return state.merge({ typeProductionFetchingDropDown });
            break;
        }

        case FieldType.GET_TYPE_PRODUCTION_SUCCESS: {
            const typeProductionDropDown = action.data.data;
            const typeProductionFetchingDropDown = false;
            return state.merge({ typeProductionDropDown, typeProductionFetchingDropDown });
            break;
        }

        case FieldType.GET_TYPE_PRODUCTION_FAILED: {
            const typeProductionFetchingFailedDropDown = true;
            const typeProductionFetchingDropDown = false;
            return state.merge({ typeProductionFetchingDropDown, typeProductionFetchingFailedDropDown });
            break;
        }
   
        case FieldType.DELETE_FIELD_SUCCESS: {
            const array = state['field'].asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ field: array, deleted: deleted })
            break;

        }

        case FieldType.DELETE_FIELD_FAILED: {
            const deleteFieldFailed = true;
            return state.merge({ deleteFieldFailed });
            break;
        }

        case FieldType.SET_FIELD_ROW_IN_REDUCER: {
            const data = action.rowInfo.asMutable();
            return state.merge({ fieldObj: data })
            break;
        }

        case FieldType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;



        default:
            return state;
    }
}