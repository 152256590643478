import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import i18n from '../../i18n/i18n';
import Select from 'react-select';
import { Fab, Button, Typography } from '@material-ui/core';
import { wentWrong } from "../../utils/ToastConfig";
import axios from '../../utils/AxiosWrapper';
import Switch from '@material-ui/core/Switch';
import checkboxHOC from "react-table/lib/hoc/selectTable";
const CheckboxTable = checkboxHOC(ReactTable);;

export default class TopList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            regionType: [], clientGroupType: [],
            showFilters: false, import: false, switchFlag: false, loading: false,
            page: 0, pageSize: 25, sorted: null, filtered: null,
            selection: [], selectAll: false, selectedRow: [],
            totalPages: 0, totalClients: 0,
            add: false, edit: false, delete: false,
            data: [],
            newFav: '',
            row: {},
        };
    }

    componentDidMount() {
    }

    getData(url, state, instance) {
        this.setState({ loading: true, data: [] });
        axios.get(`api/${url}`, {
            params: { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered }
        })
            .then(res => {
                this.setState({
                    data: res.data.data,
                    totalPages: res.data.total_pages,
                    totalClients: res.data.total,
                    loading: false
                });
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: res.data.data }))
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    }

    onSearchClick = () => this.setState({ showFilters: !this.state.showFilters });
    onImportClick = () => this.setState({ import: !this.state.import });
    onTableClick = () => this.props.history.push('/client');

    addNewFavorite = (event) => {
        event.preventDefault();
        axios.put(`api/top_clients/add_fav`, {
            params: { naziv: this.state.newFav }
        })
            .then(res => {
                this.refReactTable.fireFetchData()
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    myChangeHandler = (event) => {
        this.setState({ newFav: event.target.value });
    }

    handleDeleteButton = (cell) => {
        let row = {
            id: cell.id,
        }
        this.setState({ row }, () => { this.handleDelete() });
    };

    handleDelete = () =>
        axios.put(`api/top_clients/delete_fav`, {
            params: { id: this.state.row }
        })
            .then(res => {
                this.refReactTable.fireFetchData()
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })


    render() {

        const columns = [
            { Header: i18n.t('Naziv'), accessor: 'naziv' },
            { Header: i18n.t('Broj radnih naloga'), accessor: 'brojradnihnaloga' },
            { Header: i18n.t('Broj Tabli'), accessor: 'brojtabli' },
            { Header: i18n.t('Broj terenskih beleski'), accessor: 'brojterenskihbeleski' },
            { Header: i18n.t('Klijent Grupa'), accessor: 'klijentgrupa' },

            {
                width: 300,
                Header: '',
                accessor: 'id',
                Cell: cell => (
                    <Button onClick={() => this.handleDeleteButton(cell.row)} style={{ backgroundColor: 'transparent', color: 'black', height: '20px', width: '30px', textTransform: 'none' }}>
                        {i18n.t('Delete')}
                    </Button>
                )
            },
        ];

        const url = 'top_clients/top_list';

        const checkboxProps = {
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    return { style: { backgroundColor: "inherit" } };
                }
                return {};
            }
        };

        return (
            <div className="page">
                <div className="table-header">
                    <div style={{ display: 'flex' }}>
                        <Fab onClick={() => this.onSearchClick()}
                            color="primary"
                            aria-label="add"
                            className="action-button">
                            <span className="icon-search headerIcon" />
                        </Fab>
                        <form onSubmit={this.addNewFavorite}>
                            <label>
                                Dodaj favorita
                                <input type="text" onChange={this.myChangeHandler} />
                            </label>
                            <input type="submit" />
                        </form>
                    </div>
                </div>
                <ReactTable ref={(refReactTable) => { this.refReactTable = refReactTable; }}
                    manual
                    data={this.state.data}
                    paging={true}
                    columns={columns}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.state.totalPages}
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => { this.getData(url, state, instance); }}
                    loading={this.state.loading}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps} />

            </div >
        )
    }

};

function FilterSelect(props) {
    return <Select
        isMulti
        name="naziv"
        onChange={value => { props.onChange(value) }}
        closeMenuOnSelect={false}
        options={props.type}
        className="multi-select-in-filter"
        classNamePrefix="select"
        placeholder={i18n.t('Select')}
    />
}