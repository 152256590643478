import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import i18n from '../../i18n/i18n';
import Select from 'react-select';
import { Fab, Typography } from '@material-ui/core';
import { wentWrong } from "../../utils/ToastConfig";
import axios from '../../utils/AxiosWrapper';
import checkboxHOC from "react-table/lib/hoc/selectTable";

const CheckboxTable = checkboxHOC(ReactTable);;
function isOdd(num) { return num % 2; }

export default class EventTracking extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }

    componentDidMount() {
        this.loadData();
    }

    editArea = (id) => {
        let povrsina = prompt("Unesi povrsinu: ", "");
        if (povrsina != null) {
            // alert(name)
            axios.get('api/opstina/sombor_edit', { params: { id: id, povrsina: Number(povrsina) } })
                .then(res => {
                    this.loadData();
                    alert(`Izmenjena povrsina na ${povrsina} ha, za id tablu: ${id}.`)
                })
                .catch(err => wentWrong(i18n.t('Something went wrong')))
        }
    }

    loadData = () => {
        axios.get('api/opstina/sombor_table')
            .then(res => {
                this.setState({ data: res.data.data })
            })
            .catch(err => wentWrong(i18n.t('Something went wrong')))
    }

    render() {
        return (
            <div className="page">
                <table style={{ width: '100%', textAlign: 'center' }}>
                    <tr style={{
                        position: 'sticky',
                        insetBlockStart: '0',
                        background: '#fff',
                        border: '0px'
                    }}>
                        <th>rbr</th>
                        <th>tabla_id</th>
                        <th>naziv</th>
                        <th>povrsina</th>
                        <th>edit</th>
                        <th>kl_id</th>
                        <th>kl_naziv</th>
                        <th>bpg</th>
                    </tr>
                    {this.state.data.length > 0 && this.state.data.map((item, i) => {
                        return <tr style={{ background: isOdd(i) ? '#fff' : '#f8f8f8' }}>
                            <td>{i + 1}</td>
                            <td>{item.id}</td>
                            <td>{item.naziv}</td>
                            <td>{item.povrsina}</td>
                            <td>
                                <button onClick={() => { this.editArea(item.id) }}>
                                    edit
                                </button>
                            </td>
                            <td>{item.kl_id}</td>
                            <td>{item.kl_naziv}</td>
                            <td>{item.bpg}</td>
                        </tr>
                    })}
                </table>
            </div>
        )
    }
};
