import React from 'react';
import { connect } from 'react-redux';
import {
    Card, IconButton, Divider, Button, TextField, Tooltip,
    DialogActions, Dialog, DialogContent, DialogTitle
} from '@material-ui/core';
import i18n from '../../i18n/i18n';
import { addPublicProcurement, deleteChildFromPublicProcurement, updateChild } from '../../actions/auction/PublicProcurementActions';
import { withStyles } from '@material-ui/core/styles';
import PublicProcurementInfo from './PublicProcurementInfo';
import PublicProcurementField from './PublicProcurementField';
const styles = theme => ({
    root: {
        width: '100%',
        padding: 0
    },
    connectorLine: {
        transition: theme.transitions.create('border-color'),
    },
    actionsContainer: {
        marginBottom: theme.spacing.unit * 2,
    },
    primaryMain: {
        backgroundColor: theme.palette.primary.main,
        height: 50,
        minHeight: 50
    },
    secondaryMain: {
        backgroundColor: theme.palette.secondary.main,
        background: theme.palette.secondary.main,
    },
    secondaryDark: {
        backgroundColor: theme.palette.secondary.dark,
        background: theme.palette.secondary.dark,
    },
    secondaryLight: {
        backgroundColor: theme.palette.secondary.light,
        background: theme.palette.secondary.light,
    },
    cardWrapper: {
        padding: 0,
        width: '96%',
        margin: 20,
        overflowY: 'auto',
        maxHeight: '500px'
    },
    floatingFooter: {
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    descriptionField: {
        width: 460,
        margin: 20,
        paddingBottom: 50
    },
    tooltipLeft: {
        height: 50
    }
});

export class PublicProcurementMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            publicProcurementObj: {}
        };
    }

    handleChangeProperty = (name, value) => {
        this.setState((state) => {
            let publicProcurementObj = state.publicProcurementObj;
            publicProcurementObj[name] = (value && value.value) || value;
            return { ...state, publicProcurementObj };
        })
    }

    componentDidMount() {
        const { clientProfile } = this.props;
        if (Object.keys(this.props.publicProcurementObj).length) {
            this.setState({
                publicProcurementObj: Object.assign({}, this.props.publicProcurementObj)
            })
        } else {
            this.setState({
                publicProcurementObj: { id_sezona: clientProfile.id_default_sezona }
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.publicProcurementObj !== this.props.publicProcurementObj) {
            this.setState({
                publicProcurementObj: Object.assign({}, this.props.publicProcurementObj)
            })
        }
    }

    handleAddChild = (name, data) => {
        if (this.state.publicProcurementObj.id) {
            this.props.updateChild(this.state.publicProcurementObj.id, data);
        } else if (this.state.publicProcurementObj) {
            this.setState((state) => {
                let publicProcurementObj = state.publicProcurementObj;
                if (publicProcurementObj.hasOwnProperty(name) === true) {
                    publicProcurementObj[name] = [...publicProcurementObj[name], data];
                } else {
                    publicProcurementObj[name] = [data];
                }
                return { ...state, publicProcurementObj };
            })
        }
    }


    handleDeleteChild = (index, child) => {
        if (child.id && this.state.publicProcurementObj.id) {
            this.props.deleteChildFromPublicProcurement([child.id], this.state.publicProcurementObj.id);
        } else {
            this.setState(state => {
                let newChild = state.publicProcurementObj;
                newChild['table'].filter((_, i) => i !== index)
                return { ...state, publicProcurementObj: newChild };
            })
        }
    }

    handleEditChild = (data) => {
        this.props.updateChild(this.state.publicProcurementObj.id, data);
    }

    onAddPublicProcurement = () => {
        const { publicProcurementObj } = this.state;
        this.props.addPublicProcurement(publicProcurementObj);
    }

    cancelPublicProcurement = () => {
        const { handleClose } = this.props;
        const { publicProcurementObj } = this.state;
        if (publicProcurementObj.id_sezona && publicProcurementObj.id_osnov_zakupa && publicProcurementObj.datum && publicProcurementObj.table) {
            this.setState({
                publicProcurementCanceled: true
            })
        } else {
            handleClose();
        }
    }

    isDisabled(publicProcurementObj) {
        if (publicProcurementObj.sifra && publicProcurementObj.id_sezona && publicProcurementObj.id_osnov_zakupa && publicProcurementObj.datum && publicProcurementObj.id_katastarska_opstina) {
            return false;
        } else {
            return true;
        }
    }

    renderTooltip() {
        const { publicProcurementObj } = this.state;
        if (publicProcurementObj.sifra && publicProcurementObj.id_sezona && publicProcurementObj.id_osnov_zakupa && publicProcurementObj.datum) {
            return i18n.t('Add');
        } else {
            return i18n.t('Enter required fields marked with *');
        }
    }

    render() {
        const { publicProcurementObj } = this.state;
        const { classes, theme } = this.props;
        return (
            <Dialog
                open={this.props.add}
                onClose={this.props.handleClose}
                disableBackdropClick
                aria-labelledby="responsive-dialog-title"
                maxWidth="lg"
            >
                <DialogTitle disableTypography={true} style={{ background: theme.palette.secondary.dark }} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit public bidding') : i18n.t('Add public bidding')}
                    <IconButton onClick={this.props.handleClose}>
                        <span className="icon-cancel-circle closeIcon" />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.root}>
                    <PublicProcurementInfo publicProcurementObj={publicProcurementObj} handleDateChange={this.handleDateChange} handleChangeProperty={this.handleChangeProperty} />
                    <Card raised className={classes.cardWrapper}>
                        <PublicProcurementField handleDeleteChild={this.handleDeleteChild} handleEditChild={this.handleEditChild} handleAddField={this.handleAddChild} publicProcurementObj={publicProcurementObj} />
                    </Card>
                    <Card raised className={classes.floatingFooter}>
                        <Tooltip title={this.renderTooltip()}>
                            <Button onClick={this.onAddPublicProcurement}
                                disabled={this.isDisabled(publicProcurementObj)}
                                className="add-rn-button">{i18n.t('Add')}</Button>
                        </Tooltip>
                        <Button onClick={this.cancelPublicProcurement} className="cancel-rn-button ">{i18n.t('Cancel')}</Button>
                    </Card>
                </DialogContent>
                <Dialog
                    open={this.state.publicProcurementCanceled}
                    onClose={this.props.handleClose}
                    disableBackdropClick>
                    <DialogTitle id="responsive-dialog-title">{i18n.t('Cancel')}</DialogTitle>
                    <DialogContent>
                        {i18n.t('Are you sure you want to discard this public bidding?')}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.props.handleClose}
                            variant="contained"
                            color="primary">
                            {i18n.t('Yes')}
                        </Button>
                        <Button
                            onClick={this.onAddPublicProcurement}
                            variant="contained"
                            color="primary">
                            {i18n.t('Save and close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Dialog>
        )
    }
};

function mapStateToProps(state) {
    return {
        publicProcurementObj: state.publicProcurementReducer.publicProcurementObj,
        publicProcurementObjFetching: state.publicProcurementReducer.publicProcurementObjFetching,
        clientProfile: state.appReducer.clientProfile
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addPublicProcurement: (jn) => dispatch(addPublicProcurement(jn)),
        deleteChildFromPublicProcurement: (id, jn_id) => dispatch(deleteChildFromPublicProcurement(id, jn_id)),
        updateChild: (id, data) => dispatch(updateChild(id, data))
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(PublicProcurementMain));