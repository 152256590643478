import axios from '../../utils/AxiosWrapper';
export const Type = {
    //DROPDOWN
    GET_NOTE_GROUP_DROPDOWN_CALL: 'GET_NOTE_GROUP_DROPDOWN_CALL',
    GET_NOTE_GROUP_DROPDOWN_SUCCESS: 'GET_NOTE_GROUP_DROPDOWN_SUCCESS',
    GET_NOTE_GROUP_DROPDOWN_FAILED: 'GET_NOTE_GROUP_DROPDOWN_FAILED',

    NOTE_SENDING_CALL: 'NOTE_SENDING_CALL',
    NOTE_SENDING_SUCCESS: 'NOTE_SENDING_SUCCESS',
    NOTE_SENDING_FAILED: 'NOTE_SENDING_FAILED',

    SEND_NOTE_DOCUMENT_SUCCESS: 'SEND_NOTE_DOCUMENT_SUCCESS',
    SEND_NOTE_DOCUMENT_FAILED: 'SEND_NOTE_DOCUMENT_FAILED',

    NOTE_DOCUMENTS_CALL: 'NOTE_DOCUMENTS_CALL',
    NOTE_DOCUMENTS_SUCCESS: 'NOTE_DOCUMENTS_SUCCESS',
    NOTE_DOCUMENTS_FAILED: 'NOTE_DOCUMENTS_FAILED',

    NOTE_FETCHING_CALL: 'NOTE_FETCHING_CALL',
    NOTE_FETCHING_SUCCESS: 'NOTE_FETCHING_SUCCESS',
    NOTE_FETCHING_FAILED: 'NOTE_FETCHING_FAILED',


};

export function getNoteGroupDropDown() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_NOTE_GROUP_DROPDOWN_CALL
        });

        axios.get('api/note/group_drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_NOTE_GROUP_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_NOTE_GROUP_DROPDOWN_FAILED
                });
            });
    }
}

export function addFieldNote(noteObj, acceptedDocuments) {
    return (dispatch) => {

        dispatch({
            type: Type.NOTE_SENDING_CALL
        });

        axios.post('api/note/create', noteObj)
            .then(function (response) {
                if (!!acceptedDocuments && acceptedDocuments.length != 0) {
                    var data = new FormData();

                    acceptedDocuments.map(doc => {
                        data.append('id_terenska_beleska', response.data.data.id);
                        data.append('document', doc);
                    })

                    axios({
                        url: `api/note/upload_document`,
                        method: 'POST',
                        onUploadProgress: function (progressEvent) {
                            console.log('wwww', Math.round((progressEvent.loaded * 100) / progressEvent.total));
                        },
                        data: data,
                        headers: { 'Content-Type': 'multipart/form-data' }
                    }).then((resp) => {
                        dispatch({
                            type: Type.SEND_NOTE_DOCUMENT_SUCCESS
                        });
                        dispatch({
                            type: Type.NOTE_SENDING_SUCCESS,
                            data: response.data
                        });
                    }).catch(function (error) {
                        dispatch({
                            type: Type.SEND_NOTE_DOCUMENT_FAILED
                        })
                    });
                } else {
                    dispatch({
                        type: Type.SEND_NOTE_DOCUMENT_SUCCESS
                    });
                    dispatch({
                        type: Type.NOTE_SENDING_SUCCESS,
                        data: response.data
                    });
                }
            })
            .catch(function (error) {
                dispatch({
                    type: Type.NOTE_SENDING_FAILED
                });
            });
    }
}

export function getNoteDocument(id_terenska_beleska) {
    return (dispatch) => {

        dispatch({
            type: Type.NOTE_DOCUMENTS_CALL,
        });

        axios.get(`api/note/document?id_terenska_beleska=${id_terenska_beleska}`)
            .then(function (response) {
                dispatch({
                    type: Type.NOTE_DOCUMENTS_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.NOTE_DOCUMENTS_FAILED
                });
            });
    }
}

export function getFieldNoteById(id) {
    return (dispatch) => {

        dispatch({
            type: Type.NOTE_FETCHING_CALL
        });

        axios.get('api/note/getById?id=' + id)
            .then(function (response) {
                dispatch({
                    type: Type.NOTE_FETCHING_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.NOTE_FETCHING_FAILED
                });
            });
    }
}

