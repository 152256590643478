import axios from '../../utils/AxiosWrapper';
export const Type = {
    //GROUP DROPDOWN
    GET_WORKING_OPERATION_GROUP_CALL: 'GET_WORKING_OPERATION_GROUP_CALL',
    GET_WORKING_OPERATION_GROUP_SUCCESS: 'GET_WORKING_OPERATION_GROUP_SUCCESS',
    GET_WORKING_OPERATION_GROUP_FAILED: 'GET_WORKING_OPERATION_GROUP_FAILED',

    // DROPDOWN
    GET_WORKING_OPERATION_DROPDOWN_CALL: 'GET_WORKING_OPERATION_DROPDOWN_CALL',
    GET_WORKING_OPERATION_DROPDOWN_SUCCESS: 'GET_WORKING_OPERATION_DROPDOWN_SUCCESS',
    GET_WORKING_OPERATION_DROPDOWN_FAILED: 'GET_WORKING_OPERATION_DROPDOWN_FAILED',
    //VIEW
    GET_WORKING_OPERATION_CALL: 'GET_WORKING_OPERATION_CALL',
    GET_WORKING_OPERATION_SUCCESS: 'GET_WORKING_OPERATION_SUCCESS',
    GET_WORKING_OPERATION_FAILED: 'GET_WORKING_OPERATION_FAILED',

    GET_WORKING_OPERATION_VIEW_CALL: 'GET_WORKING_OPERATION_VIEW_CALL',
    GET_WORKING_OPERATION_VIEW_SUCCESS: 'GET_WORKING_OPERATION_VIEW_SUCCESS',
    GET_WORKING_OPERATION_VIEW_FAILED: 'GET_WORKING_OPERATION_VIEW_FAILED',

    //ADD
    ADD_WORKING_OPERATION_CALL: 'ADD_WORKING_OPERATION_CALL',
    ADD_WORKING_OPERATION_SUCCESS: 'ADD_WORKING_OPERATION_SUCCESS',
    ADD_WORKING_OPERATION_FAILED: 'ADD_WORKING_OPERATION_FAILED',

    ADD_WORKING_OPERATION_GROUP_CALL: 'ADD_WORKING_OPERATION_GROUP_CALL',
    ADD_WORKING_OPERATION_GROUP_SUCCESS: 'ADD_WORKING_OPERATION_GROUP_SUCCESS',
    ADD_WORKING_OPERATION_GROUP_FAILED: 'ADD_WORKING_OPERATION_GROUP_FAILED',
    //DELETE
    DELETE_WORKING_OPERATION_CALL: 'DELETE_WORKING_OPERATION_CALL',
    DELETE_WORKING_OPERATION_SUCCESS: 'DELETE_WORKING_OPERATION_SUCCESS',
    DELETE_WORKING_OPERATION_FAILED: 'DELETE_WORKING_OPERATION_FAILED',

    //SETTERS
    SET_WORKING_OPERATION_ROW_IN_REDUCER: 'SET_WORKING_OPERATION_ROW_IN_REDUCER',
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',



};


export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function setRowInReducer(rowInfo, name) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_WORKING_OPERATION_ROW_IN_REDUCER,
            rowInfo: rowInfo,
            name: name
        })
    }
}

export function getWODropdown() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_WORKING_OPERATION_DROPDOWN_CALL
        });

        axios.get('api/working_operation/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_WORKING_OPERATION_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_WORKING_OPERATION_DROPDOWN_FAILED
                });
            });
    }
}

export function getGroup() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_WORKING_OPERATION_GROUP_CALL
        });

        axios.get('api/working_operation_group/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_WORKING_OPERATION_GROUP_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_WORKING_OPERATION_GROUP_FAILED
                });
            });
    }
}

export function getWorkingOperationGroup(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_WORKING_OPERATION_VIEW_CALL
        });
        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/working_operation_group/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_WORKING_OPERATION_VIEW_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_WORKING_OPERATION_VIEW_FAILED
                });
            });
    }
}

export function addWorkingOperationGroup(workingOperationGroupObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_WORKING_OPERATION_GROUP_CALL
        });

        axios.post('api/working_operation_group/create', workingOperationGroupObj)
            .then(function (response) {
                dispatch({
                    type: Type.ADD_WORKING_OPERATION_GROUP_SUCCESS,
                    data: response.data
                });
                dispatch(getGroup())
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_WORKING_OPERATION_GROUP_FAILED
                });
            });
    }
}

export function addWorkingOperation(workingOperationObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_WORKING_OPERATION_CALL
        });

        axios.post('api/working_operation/create', workingOperationObj)
            .then(function (response) {
                dispatch({
                    type: Type.ADD_WORKING_OPERATION_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_WORKING_OPERATION_FAILED
                });
            });
    }
}

export function getWorkingOperations(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_WORKING_OPERATION_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/working_operation/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_WORKING_OPERATION_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_WORKING_OPERATION_FAILED
                });
            });
    }
}

export function deleteWorkingOperation(selection, name) {
    return (dispatch) => {

        dispatch({
            type: Type.DELETE_WORKING_OPERATION_CALL,
        });

        axios.post(`api/${name}/delete`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_WORKING_OPERATION_SUCCESS,
                    data: response.data,
                    name: name,
                    forDelete: selection
                });
                dispatch(getGroup())
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_WORKING_OPERATION_FAILED
                });
            });
    }
}