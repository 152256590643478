import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import {
    TextField, Button, Grid, Card, LinearProgress
} from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment'
import { getCustomerInfo, saveCustomerInfo } from '../../actions/FarmerInfoActions';
const styles = {
    card: {
        padding: '10px',
        width: '100%',
        margin: '10px',
        display: 'flex',
        flexWrap: 'wrap',
    },
}

export class KnowYourCustomer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            knowYourCustomerObj: {}
        };
    }

    componentDidMount() {
        const { getCustomerInfo, id_klijent } = this.props;
        getCustomerInfo(id_klijent);
    }

    componentDidUpdate(prevProps) {
        const { knowYourCustomerObj } = this.props;
        if (prevProps.knowYourCustomerObj !== knowYourCustomerObj) {
            this.setState({
                knowYourCustomerObj
            })
        }
    }

    handleChangeValue = name => event => {
        const { knowYourCustomerObj } = this.state;
        let obj = Object.assign({}, knowYourCustomerObj);
        obj[name] = event.target.value;
        this.setState({ knowYourCustomerObj: obj });
    };

    handleDateChange = name => date => {
        const { knowYourCustomerObj } = this.state;
        let obj = Object.assign({}, knowYourCustomerObj);
        obj[name] = moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY');
        this.setState({ knowYourCustomerObj: obj });
    }


    renderLabel = (date) => {
        if (date.isValid()) {
            return date.format('DD.MM.YYYY');
        } else {
            return '';
        }
    }

    saveYourCustomer = () => {
        const { knowYourCustomerObj } = this.state;
        const { saveCustomerInfo } = this.props;
        saveCustomerInfo(knowYourCustomerObj);
    }


    render() {
        const { localGoverments, states, identificationDoc, pol, maritalStatus, farmerInfoFetching } = this.props
        const { knowYourCustomerObj } = this.state;
        return (
            <div style={{ padding: 20 }}>
                {farmerInfoFetching && <LinearProgress />}
                <Card style={styles.card}>
                    <Grid item>
                        <TextField
                            label={i18n.t('Farmer ID')}
                            disabled
                            value={knowYourCustomerObj.id}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ width: '256px', margin: '10px' }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label={i18n.t('First name')}
                            required
                            style={{ width: '256px', margin: '10px' }}
                            value={knowYourCustomerObj.ime}
                            onChange={this.handleChangeValue('ime')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label={i18n.t('Middle name(optional)')}
                            style={{ width: '256px', margin: '10px' }}
                            value={knowYourCustomerObj.srednje_ime}
                            onChange={this.handleChangeValue('srednje_ime')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label={i18n.t('Surname')}
                            required
                            style={{ width: '256px', margin: '10px' }}
                            value={knowYourCustomerObj.prezime}
                            onChange={this.handleChangeValue('prezime')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <DatePicker
                            keyboard
                            label={i18n.t('Date of birth')}
                            format="DD.MM.YYYY"
                            style={{ width: '256px', margin: '10px' }}
                            disableOpenOnEnter
                            value={knowYourCustomerObj.datum_rodjenja ? moment(knowYourCustomerObj.datum_rodjenja, 'DD.MM.YYYY') : new Date()}
                            style={{ width: '256px', margin: '10px' }}
                            onChange={this.handleDateChange('datum_rodjenja')}
                            labelFunc={this.renderLabel}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            animateYearScrolling={false}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            select
                            label={i18n.t('Gender')}
                            SelectProps={{
                                native: true,
                            }}
                            margin="normal"
                            style={{ width: '256px', margin: '10px' }}
                            value={knowYourCustomerObj.pol}
                            onChange={this.handleChangeValue('pol')}
                            InputLabelProps={{
                                shrink: true,
                            }}

                        >
                            <option value='' />
                            {pol}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField
                            label={i18n.t('Nationality')}
                            value={knowYourCustomerObj.nacionalnost}
                            onChange={this.handleChangeValue('nacionalnost')}
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ width: '256px', margin: '10px' }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label={i18n.t('Occupation')}
                            required
                            value={knowYourCustomerObj.delatnost}
                            onChange={this.handleChangeValue('delatnost')}
                            style={{ width: '256px', margin: '10px' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label={i18n.t('Company Name(optional)')}
                            required
                            value={knowYourCustomerObj.pun_naziv}
                            onChange={this.handleChangeValue('pun_naziv')}
                            style={{ width: '256px', margin: '10px' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            select
                            label={i18n.t('Marital status')}
                            SelectProps={{
                                native: true,
                            }}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ width: '256px', margin: '10px' }}
                            value={knowYourCustomerObj.id_bracni_status}
                            onChange={this.handleChangeValue('id_bracni_status')}

                        >
                            <option value='' />
                            {maritalStatus}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField
                            label={i18n.t('Spouse name')}
                            required
                            value={knowYourCustomerObj.spouse_name}
                            onChange={this.handleChangeValue('spouse_name')}
                            style={{ width: '256px', margin: '10px' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Card raised style={styles.card}>
                        <h2 style={{ width: '100%', alignSelf: 'center', display: 'flex', justifyContent: 'center' }}>{i18n.t('Farmers Home Address')}</h2>
                        <Grid item>
                            <TextField
                                label={i18n.t('House Number')}
                                required
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={knowYourCustomerObj.house_number}
                                onChange={this.handleChangeValue('house_number')}
                                style={{ width: '256px', margin: '10px' }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                label={i18n.t('Street')}
                                required
                                value={knowYourCustomerObj.street}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={this.handleChangeValue('street')}
                                style={{ width: '256px', margin: '10px' }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                label={i18n.t('Area City')}
                                required
                                value={knowYourCustomerObj.area_city}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={this.handleChangeValue('area_city')}
                                style={{ width: '256px', margin: '10px' }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                select
                                label={i18n.t('Local Government Area')}
                                required
                                SelectProps={{
                                    native: true,
                                }}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={knowYourCustomerObj.id_katastarska_opstina}
                                onChange={this.handleChangeValue('id_katastarska_opstina')}
                                style={{ width: '256px', margin: '10px' }}

                            >
                                <option value='' />
                                {localGoverments}
                            </TextField>
                        </Grid>
                        <Grid item>
                            <TextField
                                select
                                label={i18n.t('State')}
                                required
                                SelectProps={{
                                    native: true,
                                }}
                                value={knowYourCustomerObj.id_opstina}
                                onChange={this.handleChangeValue('id_opstina')}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ width: '256px', margin: '10px' }}

                            >
                                <option value='' />
                                {states}
                            </TextField>
                        </Grid>
                        <Grid item>
                            <TextField
                                label={i18n.t('Country')}
                                required
                                value={knowYourCustomerObj.country}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={this.handleChangeValue('country')}
                                style={{ width: '256px', margin: '10px' }}

                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                label={i18n.t('Email Address(optional)')}
                                style={{ width: '256px', margin: '10px' }}
                                value={knowYourCustomerObj.email}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={this.handleChangeValue('email')}
                            />
                        </Grid>
                    </Card>
                    <Card raised style={styles.card}>
                        <h2 style={{ width: '100%', alignSelf: 'center', display: 'flex', justifyContent: 'center' }}>{i18n.t("Photo Capture: Farmer's Valid ID or Utility Bill")}</h2>
                        <Grid item>
                            <TextField
                                select
                                required
                                label={i18n.t('Identification document')}
                                SelectProps={{
                                    native: true,
                                }}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={knowYourCustomerObj.id_identifikacioni_dokument}
                                onChange={this.handleChangeValue('id_identifikacioni_dokument')}
                                style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}

                            >
                                <option value='' />
                                {identificationDoc}
                            </TextField>
                        </Grid>
                        <Grid item>
                            <TextField
                                label={i18n.t('Identification number')}
                                required
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={knowYourCustomerObj.identifikacioni_broj}
                                onChange={this.handleChangeValue('identifikacioni_broj')}
                                style={{ width: '256px', margin: '10px' }}
                            />
                        </Grid>
                    </Card>
                    <Card raised style={styles.card}>
                        <h2 style={{ width: '100%', alignSelf: 'center', display: 'flex', justifyContent: 'center' }}>{i18n.t('Next of Kin')}</h2>
                        <Grid item>
                            <TextField
                                label={i18n.t('Surname')}
                                required
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={knowYourCustomerObj.kin_surname}
                                onChange={this.handleChangeValue('kin_surname')}
                                style={{ width: '256px', margin: '10px' }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                label={i18n.t('First name')}
                                value={knowYourCustomerObj.kin_name}
                                onChange={this.handleChangeValue('kin_name')}
                                required
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ width: '256px', margin: '10px' }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                select
                                label={i18n.t('Relationship')}
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={knowYourCustomerObj.bracni_status_kin}
                                onChange={this.handleChangeValue('bracni_status_kin')}
                                margin="normal"
                                style={{ width: '256px', margin: '10px' }}

                            >
                                <option value='' />
                                {maritalStatus}
                            </TextField>
                        </Grid>
                        <Card raised style={styles.card}>
                            <h2 style={{ width: '100%', alignSelf: 'center', display: 'flex', justifyContent: 'center' }}>{i18n.t('Address')}</h2>
                            <Grid item>
                                <TextField
                                    label={i18n.t('House Number')}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={knowYourCustomerObj.kin_house_number}
                                    onChange={this.handleChangeValue('kin_house_number')}
                                    style={{ width: '256px', margin: '10px' }}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    label={i18n.t('Street')}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={knowYourCustomerObj.kin_street}
                                    onChange={this.handleChangeValue('kin_street')}
                                    style={{ width: '256px', margin: '10px' }}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    label={i18n.t('Area City')}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={knowYourCustomerObj.kin_area_city}
                                    onChange={this.handleChangeValue('kin_area_city')}
                                    style={{ width: '256px', margin: '10px' }}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    select
                                    label={i18n.t('Local Government Area')}
                                    required
                                    value={knowYourCustomerObj.id_katastarska_opstina_kin}
                                    onChange={this.handleChangeValue('id_katastarska_opstina_kin')}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    style={{ width: '256px', margin: '10px' }}

                                >
                                    <option value='' />
                                    {localGoverments}
                                </TextField>
                            </Grid>
                            <Grid item>
                                <TextField
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label={i18n.t('State')}
                                    required
                                    SelectProps={{
                                        native: true,
                                    }}
                                    value={knowYourCustomerObj.id_opstina_kin}
                                    onChange={this.handleChangeValue('id_opstina_kin')}
                                    margin="normal"
                                    style={{ width: '256px', margin: '10px' }}

                                >
                                    <option value='' />
                                    {states}
                                </TextField>
                            </Grid>
                            <Grid item>
                                <TextField
                                    label={i18n.t('Country')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={knowYourCustomerObj.kin_country}
                                    onChange={this.handleChangeValue('kin_country')}
                                    required
                                    style={{ width: '256px', margin: '10px' }}
                                />
                            </Grid>
                        </Card>
                    </Card>
                    <Grid item>
                        <TextField
                            label={i18n.t('Mobile Phone')}
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={knowYourCustomerObj.kontakt_mobilni}
                            onChange={this.handleChangeValue('kontakt_mobilni')}
                            style={{ width: '256px', margin: '10px' }}

                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label={i18n.t('Farmers Signature')}
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ width: '256px', margin: '10px' }}
                        />
                    </Grid>
                </Card>
                <Grid item xs>
                    <Button
                        style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white' }}
                        onClick={() => this.saveYourCustomer()}
                    >
                        {i18n.t('Save')}
                    </Button>
                </Grid>
                <Grid item xs={3}>
                </Grid>
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        knowYourCustomerObj: state.farmerInfoReducer.knowYourCustomerObj,
        farmerInfoFetching: state.farmerInfoReducer.farmerInfoFetching

    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCustomerInfo: (id_klijent) => dispatch(getCustomerInfo(id_klijent)),
        saveCustomerInfo: (info) => dispatch(saveCustomerInfo(info))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(KnowYourCustomer)
