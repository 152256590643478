import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { toast } from 'react-toastify';
import 'react-table/react-table.css';
import { getUsers, deleteUser, setInitialState, setRowInReducer } from '../../actions/AdministrationActions';
import AddUser from './AddUser';
import i18n from '../../i18n/i18n';
import { Fab } from '@material-ui/core';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import { getUser } from '../../utils/CommonFunctions';

const CheckboxTable = checkboxHOC(ReactTable);
export class ViewUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            add: false,
            selection: [],
            selectAll: false,
            view: false
        };
    }

    componentDidMount() {
        this.setState({
            user: getUser()
        })
    }

    componentDidUpdate(prevProps) {
        const { deleted, userPostingSuccess, deleteFailed, getUsers, setInitialState } = this.props;
        if (!prevProps.deleted && deleted) {
            const wrapped = this.checkboxTable.getWrappedInstance();
            getUsers(wrapped.state);
            this.setState({
                selection: [],
                selectAll: false,
            })
            setInitialState('userObj');
            setInitialState('deleted');
        }
        if (!prevProps.userPostingSuccess && userPostingSuccess) {
            this.handleClick();
            const wrapped = this.checkboxTable.getWrappedInstance();
            getUsers(wrapped.state);
            setInitialState('userPostingSuccess');
        }
        if (!prevProps.deleteFailed && deleteFailed) {
            toast.error(i18n.t('This item can not be deleted!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            setInitialState('deleteFailed');
        }

    }

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    handleClick = () => {
        this.setState({ add: !this.state.add });
    };


    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            selection.push(key);
        }
        this.setState({ selection })
    };

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });
    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    render() {
        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "lightgreen" : "inherit"
                        }
                    };
                }
                return {};
            }
        };
        return (
            <div className="page">
                <div className="table-header">
                    <Fab
                        onClick={() => this.handleClick()}
                        color="primary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-Plus headerIcon" />
                    </Fab>
                    <Fab
                        onClick={() => this.onSearchClick()}
                        color="primary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-search headerIcon" />
                    </Fab>
                </div>
                <CheckboxTable data={this.props.users}
                    ref={r => (this.checkboxTable = r)}
                    columns={this.columns}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.props.usersPages}
                    loading={this.props.usersFetching}
                    manual
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => this.props.getUsers(state, instance)}
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                />
                {this.state.add && <AddUser add={this.state.add}
                    handleClose={this.handleClick} />}
            </div>
        )
    }

    columns = [
        {
            Header: i18n.t('ID'),
            accessor: 'id'
        },
        {
            Header: i18n.t('Username'),
            accessor: 'username'
        },
        {
            Header: i18n.t('Client name'),
            accessor: 'klijent_naziv'
        },
        {
            Header: i18n.t('Email'),
            accessor: 'mejl'
        },
        {
            Header: i18n.t('First name'),
            accessor: 'ime'
        },
        {
            Header: i18n.t('Last name'),
            accessor: 'prezime'
        },
        {
            Header: i18n.t('User role'),
            accessor: 'korisnik_grupa'
        },
        {
            Header: i18n.t('Mobile phone'),
            accessor: 'mobilni'
        },
    ];
};

function mapStateToProps(state) {
    return {
        userPostingSuccess: state.administrationReducer.userPostingSuccess,
        users: state.administrationReducer.users,
        usersPages: state.administrationReducer.usersPages,
        usersFetching: state.administrationReducer.usersFetching
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInitialState: (component) => dispatch(setInitialState(component)),
        setRowInReducer: (rowInfo, name) => dispatch(setRowInReducer(rowInfo, name)),
        getUsers: (state, instance) => dispatch(getUsers(state, instance)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewUsers)