import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import {
    TextField, Button, Grid, IconButton,
    Dialog, DialogContent, DialogTitle, Card
} from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    addAgent, getStates,
    setInitialState
} from '../../actions/AdministrationActions';
import { DatePicker } from 'material-ui-pickers';
import { getLocalGov } from '../../actions/FarmRegistarActions';
import { getUser } from '../../utils/CommonFunctions';
import moment from 'moment';
const styles = {
    card: {
        padding: '10px',
        width: '100%',
        margin: '10px',
        display: 'flex',
        flexWrap: 'wrap',
    },
}
export class AddAgent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            agentObj: {}
        };
    }

    async componentDidMount() {
        const { getStates, getLocalGov } = this.props;
        getStates();
        let user = await getUser();
        if (user) {
            getLocalGov(user.id_opstina);
            const { agentObj } = this.state;
            let obj = Object.assign({}, agentObj);
            obj.id_opstina = user.id_opstina;
            obj.id_opstina_korisnik = user.id_opstina;
            obj.incorporation_date = moment(new Date, 'DD.MM.YYYY').format('DD.MM.YYYY');
            this.setState({
                agentObj: obj
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.agentPostingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('agentPostingFailed');
        }
    }

    handleChangeValue = name => event => {
        const { agentObj } = this.state;
        let obj = Object.assign({}, agentObj);
        obj[name] = event.target.value;
        this.setState({ agentObj: obj });
    };

    addAgent() {
        const { agentObj } = this.state;
        const { addAgent } = this.props;
        if (agentObj.kontakt_mejl &&
            agentObj.id_katastarska_opstina &&
            agentObj.id_opstina &&
            agentObj.naziv &&
            agentObj.kontakt_mobilni &&
            agentObj.boa_number &&
            agentObj.bvn &&
            agentObj.ulica_i_broj &&
            agentObj.ulica_i_broj_korisnik &&
            agentObj.id_katastarska_opstina_korisnik &&
            agentObj.id_opstina_korisnik &&
            agentObj.delatnost &&
            agentObj.commercial_activity &&
            agentObj.num_years_business &&
            agentObj.incorporation_date &&
            agentObj.postanski_broj &&
            agentObj.ime &&
            agentObj.prezime &&
            agentObj.username &&
            agentObj.password) {
            addAgent(
                agentObj
            )
        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    handleDateChange = name => date => {
        const { agentObj } = this.state;
        let obj = Object.assign({}, agentObj);
        obj[name] = moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY');
        this.setState({ agentObj: obj });
    }


    renderLabel = (date) => {
        if (date.isValid()) {
            return date.format('DD.MM.YYYY');
        } else {
            return '';
        }
    }


    render() {
        const { localGoverments, localGovermentsFetching, states, statesFetching, add, handleClose } = this.props;
        const { agentObj } = this.state;
        let localGovermentsArray = [];
        if (localGoverments && !localGovermentsFetching) {
            localGovermentsArray = localGoverments.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let statesArray = [];
        if (states && !statesFetching) {
            statesArray = states.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let typeOfBusiness = []
        const businessArray = [
            { label: i18n.t('Sole Proprietorship'), value: 'Sole Proprietorship' },
            { label: i18n.t('Partnership'), value: 'Partnership' },
            { label: i18n.t('Limited Liability'), value: 'Limited Liability' },
            { label: i18n.t('State Corp.'), value: 'State Corp.' }
        ]
        typeOfBusiness = businessArray.map((s, i) => {
            return <option key={s.id} value={s.value}>{s.label}</option>
        })
        return (
            <div style={{ padding: 20 }}>
                <Dialog
                    open={add}
                    onClose={handleClose}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('Add agent')}
                        <IconButton onClick={handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-zonal">
                            <Grid container justify="space-between" alignContent="space-between" spacing={16} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                                <TextField
                                    label={i18n.t('First name')}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={agentObj.ime}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('ime')}
                                />
                                <TextField
                                    label={i18n.t('Last name')}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={agentObj.prezime}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('prezime')}
                                />
                                <TextField
                                    label={i18n.t('Username')}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={agentObj.username}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('username')}
                                />
                                <TextField
                                    label={i18n.t('Password')}
                                    type="password"
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('password')}
                                />
                                <TextField
                                    label={i18n.t('Name of Proposed Agent/Business')}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={agentObj.naziv}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('naziv')}
                                />
                                <TextField
                                    label={i18n.t('Mobile Number')}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={agentObj.kontakt_mobilni}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('kontakt_mobilni')}
                                />
                                <TextField
                                    label={i18n.t('BOA Account Number')}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type="number"
                                    value={agentObj.boa_number}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('boa_number')}
                                />
                                <TextField
                                    label={i18n.t('Bank Verification No')}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type="number"
                                    value={agentObj.bvn}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('bvn')}
                                />
                                <TextField
                                    label={i18n.t('Official Email Address')}
                                    value={agentObj.kontakt_mejl}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('kontakt_mejl')}
                                />
                                <Card raised style={styles.card}>
                                    <h2 style={{ width: '100%', alignSelf: 'center', display: 'flex', justifyContent: 'center' }}>{i18n.t('Residental Address')}</h2>
                                    <TextField
                                        label={i18n.t('Street name')}
                                        value={agentObj.ulica_i_broj_korisnik}
                                        required
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={this.handleChangeValue('ulica_i_broj_korisnik')}
                                    />

                                    <TextField
                                        select
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label={i18n.t('City/Town')}
                                        value={agentObj.id_katastarska_opstina_korisnik}
                                        required
                                        onChange={this.handleChangeValue('id_katastarska_opstina_korisnik')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}

                                    >

                                        <option value='' />
                                        {localGovermentsArray}
                                    </TextField>
                                    <TextField
                                        select
                                        label={i18n.t('State')}
                                        value={agentObj.id_opstina_korisnik}
                                        required
                                        disabled
                                        onChange={this.handleChangeValue('id_opstina_korisnik')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}

                                    >

                                        <option value='' />
                                        {statesArray}
                                    </TextField>
                                </Card>
                                <TextField
                                    select
                                    label={i18n.t('Type of Business')}
                                    required
                                    value={agentObj.delatnost}
                                    onChange={this.handleChangeValue('delatnost')}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                >
                                    <option value='' />
                                    {typeOfBusiness}
                                </TextField>
                                <TextField
                                    label={i18n.t('Commercial Activity (state the type of business activity)')}
                                    multiline
                                    fullWidth
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    id="full-width"
                                    margin="normal"
                                    style={{ marginTop: '10px', marginBottom: '10px' }}
                                    value={agentObj.commercial_activity}
                                    onChange={this.handleChangeValue('commercial_activity')}
                                />
                                <TextField
                                    label={i18n.t('Number of Years in the Business')}
                                    value={agentObj.num_years_business}
                                    required
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('num_years_business')}
                                />
                                <DatePicker
                                    keyboard
                                    label={i18n.t('Date of Incorporation / Registration')}
                                    format="DD.MM.YYYY"
                                    disableOpenOnEnter
                                    value={agentObj.incorporation_date && moment(agentObj.incorporation_date, 'DD.MM.YYYY')}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleDateChange('incorporation_date')}
                                    labelFunc={this.renderLabel}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    animateYearScrolling={false}
                                />
                                <TextField
                                    label={i18n.t('Post Office Box No')}
                                    value={agentObj.postanski_broj}
                                    required
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('postanski_broj')}
                                />
                                <Card raised style={styles.card}>
                                    <h2 style={{ width: '100%', alignSelf: 'center', display: 'flex', justifyContent: 'center' }}>{i18n.t('Business Address')}</h2>
                                    <TextField
                                        label={i18n.t('Street name')}
                                        value={agentObj.ulica_i_broj}
                                        required
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={this.handleChangeValue('ulica_i_broj')}
                                    />

                                    <TextField
                                        select
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label={i18n.t('City/Town')}
                                        value={agentObj.id_katastarska_opstina}
                                        required
                                        onChange={this.handleChangeValue('id_katastarska_opstina')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}

                                    >

                                        <option value='' />
                                        {localGovermentsArray}
                                    </TextField>
                                    <TextField
                                        select
                                        label={i18n.t('State')}
                                        value={agentObj.id_opstina}
                                        required
                                        disabled
                                        onChange={this.handleChangeValue('id_opstina')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}

                                    >

                                        <option value='' />
                                        {statesArray}
                                    </TextField>
                                </Card>
                                <Grid item xs={12}>
                                    <Button
                                        style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white' }}
                                        onClick={() => this.addAgent()}
                                    >
                                        {i18n.t('Add')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
            </div >

        )
    }
};

function mapStateToProps(state) {
    return {
        states: state.administrationReducer.states,
        statesFetching: state.administrationReducer.statesFetching,
        agentObj: state.administrationReducer.agentObj,
        statesFetchingFailed: state.administrationReducer.statesFetchingFailed,
        agentPostingFailed: state.administrationReducer.agentPostingFailed,
        localGoverments: state.farmRegistarReducer.localGoverments,
        localGovermentsFetching: state.farmRegistarReducer.localGovermentsFetching,
        localGovermentsFetchingFailed: state.farmRegistarReducer.localGovermentsFetchingFailed,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getStates: () => dispatch(getStates()),
        getLocalGov: (id_state) => dispatch(getLocalGov(id_state)),
        setInitialState: (component) => dispatch(setInitialState(component)),
        addAgent: (agentObj) => dispatch(addAgent(agentObj)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddAgent)