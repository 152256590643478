/**
 * Created by pajicv on 9/7/18.
 */

import axios from '../../utils/AxiosWrapper';

export const MAP_GET_INIT_CALL = 'MAP_GET_INIT_CALL';
export const MAP_GET_INIT_SUCCESS = 'MAP_GET_INIT_SUCCESS';
export const MAP_GET_INIT_FAILED = 'MAP_GET_INIT_FAILED';

export function fetchMapInit() {
    return (dispatch, getState) => {

        dispatch({
            type: MAP_GET_INIT_CALL
        });

        const url = 'api/map/init';

        const clientProfile = getState().appReducer.clientProfile;

        axios.get(url)
            .then(function (response) {
                try {
                    const initialMapParams = response.data;
                    dispatch({
                        type: MAP_GET_INIT_SUCCESS,
                        data: { ...initialMapParams, selectedLayers: clientProfile.id_default_sezona }
                    });
                } catch (err) {
                    console.log(err);
                    dispatch({
                        type: MAP_GET_INIT_FAILED
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: MAP_GET_INIT_FAILED
                });
            });
    }
}