import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Card } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './ImageryStyles';
import evi from './../satelliteinfo/evi.png';

export class EVI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.props.language === 1 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                EVI (Poboljšani vegetacioni indeks) je optimizovani vegetativni indeks koji unosi određena
                                poboljšanja u odnosu na NDVI. Dizajniran je tako da minimizuje uticaj atmosfere i zemlje koja se nalazi
                                ispod biljaka. Takođe, mnogo je osetljiviji na male razlike među biljkama u kasnijim fazama razvoja.
                            </p>
                            <p className={classes.paragraph}>
                                Indeks NDVI je osetljiv na hlorofil, dok je indeks EVI osetljiviji na strukturnu varijaciju vegetacije kao što
                                su tip vegetacije i fizionomija biljaka. Ova dva indeksa su komplementarna i često se zajedno koriste u
                                proučavanju promena biofizičkih karakteristika vegetacije
                            </p>
                            <p className={classes.paragraph}>
                                Vrednost indeksa EVI je u opsegu od -1 do +1 a uobičajene vrednosti vegetacije su od 0,2 do 0,8. EVI se
                                najčešće upotrebljava se procenu biomase, određivanje biofizičkih karakteristika vegetacije,
                                kvantifikaciju evapotranspiracije ili efikasnost korišćenja vode.
                            </p>
                            <div className={classes.image}>
                                <img src={evi} />
                            </div>
                        </div>
                    </Card>
                }
                {this.props.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                The enhanced vegetation index (EVI) is an 'optimized' vegetation index designed to enhance the
                                vegetation signal with improved sensitivity in high biomass regions and improved vegetation monitoring
                                through a de-coupling of the canopy background signal and a reduction in atmosphere influences.
                            </p>
                            <p className={classes.paragraph}>
                                In areas of dense canopy where the leaf area index (LAI) is high, the NDVI values can be improved by
                                leveraging information in the blue wavelength. Information in this portion of the spectrum can help
                                correct for soil background signals and atmospheric influences.
                            </p>
                            <p className={classes.paragraph}>
                                Values description: The range of values for the EVI is -1 to 1, where healthy vegetation generally falls
                                between values of 0.20 to 0.80.
                            </p>
                            <div className={classes.image}>
                                <img src={evi} />
                            </div>
                        </div>
                    </Card>
                }
                {this.props.language === 4 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                EVI (Enhanced Vegetation Index) je optimizirani indeks vegetacije koji čini određena poboljšanja u 
                                odnosu na NDVI. Dizajniran je tako da minimalizira utjecaj atmosfere i tla ispod biljaka. 
                                Također je puno osjetljiviji na male razlike između biljaka u kasnijim fazama razvoja.
                            </p>
                            <p className={classes.paragraph}>
                                NDVI indeks je osjetljiv na klorofil, dok je EVI indeks osjetljiviji na strukturne varijacije 
                                vegetacije kao što su tip vegetacije i fizionomija biljke. Ova dva indeksa su komplementarna 
                                i često se zajedno koriste u proučavanju promjena biofizičkih karakteristika vegetacije.
                            </p>
                            <p className={classes.paragraph}>
                                Vrijednost EVI indeksa je u rasponu od -1 do +1, a uobičajene vegetacijske vrijednosti su 
                                od 0,2 do 0,8. EVI se najčešće koristi za procjenu biomase, određivanje biofizičkih 
                                karakteristika vegetacije, kvantificiranje evapotranspiracije ili učinkovitosti korištenja vode.
                            </p>
                            <div className={classes.image}>
                                <img src={evi} />
                            </div>
                        </div>
                    </Card>
                }
            </div>
        )
    }
};

export default withStyles(styles)(EVI);