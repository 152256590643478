import immutable from 'seamless-immutable';
import { Type as FormModuleType } from '../../actions/control_panel/FormModuleActions';
export const INITIAL_STATE = immutable({
    formModulePosting: false,
    formModulePostingSuccess: false,
    formModulePostingFailed: false,

    formModule: [],
    formModuleObj: {},
    formModuleFetching: false,
    formModulePages: 1,

    formType: [],
    formTypeFetching: false,
    formTypeFetchingFailed: false,

    moduleType: [],
    moduleTypeFetching: false,
    moduleTypeFetchingFailed: false,

    deleted: false,
    deleteFormModuleFailed: false,
});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case FormModuleType.SET_INITIAL_STATE: {
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;
        }

        case FormModuleType.ADD_FORM_MODULE_CALL: {
            const formModulePosting = true;
            return state.merge({ formModulePosting });
            break;
        }

        case FormModuleType.ADD_FORM_MODULE_SUCCESS: {
            const formModulePosting = false;
            const formModulePostingSuccess = true;
            return state.merge({ formModulePostingSuccess, formModulePosting, formModuleObj: INITIAL_STATE.formModuleObj })
            break;
        }

        case FormModuleType.ADD_FORM_MODULE_FAILED: {
            const formModulePosting = false;
            const formModulePostingFailed = true;
            return state.merge({ formModulePosting, formModulePostingFailed });
            break;
        }

        case FormModuleType.GET_FORM_MODULE_READ_CALL: {
            const formModuleFetching = true;
            return state.merge({ formModuleFetching });
            break;
        }

        case FormModuleType.GET_FORM_MODULE_READ_SUCCESS: {
            const formModule = action.data.data;
            const formModuleFetching = false;
            const formModulePages = action.data.total_pages
            return state.merge({ formModule, formModuleFetching, formModulePages });
            break;
        }

        case FormModuleType.GET_FORM_MODULE_READ_FAILED: {
            const formModuleFetchingFailed = true;
            const formModuleFetching = false;
            return state.merge({ formModuleFetching, formModuleFetchingFailed });
            break;
        }

        case FormModuleType.GET_FORM_TYPE_CALL: {
            const formTypeFetching = true;
            return state.merge({ formTypeFetching });
            break;
        }

        case FormModuleType.GET_FORM_TYPE_SUCCESS: {
            const formType = action.data.data;
            const formTypeFetching = false;
            return state.merge({ formType, formTypeFetching });
            break;
        }

        case FormModuleType.GET_FORM_TYPE_FAILED: {
            const formTypeFetchingFailed = true;
            const formTypeFetching = false;
            return state.merge({ formTypeFetching, formTypeFetchingFailed });
            break;
        }

        case FormModuleType.GET_MODULE_TYPE_CALL: {
            const moduleTypeFetching = true;
            return state.merge({ moduleTypeFetching });
            break;
        }

        case FormModuleType.GET_MODULE_TYPE_SUCCESS: {
            const moduleType = action.data.data;
            const moduleTypeFetching = false;
            return state.merge({ moduleType, moduleTypeFetching });
            break;
        }

        case FormModuleType.GET_MODULE_TYPE_FAILED: {
            const moduleTypeFetchingFailed = true;
            const moduleTypeFetching = false;
            return state.merge({ moduleTypeFetching, moduleTypeFetchingFailed });
            break;
        }

        case FormModuleType.DELETE_FORM_MODULE_SUCCESS: {
            const array = state['formModule'].asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ formModule: array, deleted: deleted })
            break;
        }

        case FormModuleType.DELETE_FORM_MODULE_FAILED: {
            const deleteFormModuleFailed = true;
            return state.merge({ deleteFormModuleFailed });
            break;
        }

        case FormModuleType.SET_FORM_MODULE_ROW_IN_REDUCER: {
            const data = action.rowInfo;
            return state.merge({ formModuleObj: data })
            break;
        }

        default:
            return state;
    }
}