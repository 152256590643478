import axios from '../utils/AxiosWrapper';
import moment from 'moment';
export const Type = {
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',
    SET_PARCEL_ROW_IN_REDUCER: 'SET_PARCEL_ROW_IN_REDUCER',

    GET_TICKET_DROPDOWN_CALL: 'GET_TICKET_DROPDOWN_CALL',
    GET_TICKET_DROPDOWN_SUCCESS: 'GET_TICKET_DROPDOWN_SUCCESS',
    GET_TICKET_DROPDOWN_FAILED: 'GET_TICKET_DROPDOWN_FAILED',

    GET_TICKET_READ_CALL: 'GET_TICKET_READ_CALL',
    GET_TICKET_READ_SUCCESS: 'GET_TICKET_READ_SUCCESS',
    GET_TICKET_READ_FAILED: 'GET_TICKET_READ_FAILED',

    GET_TICKET_ITEM_READ_CALL: 'GET_TICKET_ITEM_READ_CALL',
    GET_TICKET_ITEM_READ_SUCCESS: 'GET_TICKET_ITEM_READ_SUCCESS',
    GET_TICKET_ITEM_READ_FAILED: 'GET_TICKET_ITEM_READ_FAILED',

    DELETE_TICKET_CALL: 'DELETE_TICKET_CALL',
    DELETE_TICKET_SUCCESS: 'DELETE_TICKET_SUCCESS',
    DELETE_TICKET_FAILED: 'DELETE_TICKET_FAILED',

    DELETE_TICKET_ITEM_CALL: 'DELETE_TICKET_ITEM_CALL',
    DELETE_TICKET_ITEM_SUCCESS: 'DELETE_TICKET_ITEM_SUCCESS',
    DELETE_TICKET_ITEM_FAILED: 'DELETE_TICKET_ITEM_FAILED',


    ADD_TICKET_ITEM_CALL: 'ADD_TICKET_ITEM_CALL',
    ADD_TICKET_ITEM_SUCCESS: 'ADD_TICKET_ITEM_SUCCESS',
    ADD_TICKET_ITEM_FAILED: 'ADD_TICKET_ITEM_FAILED',

    GET_TICKET_STATUS_DROPDOWN_CALL: 'GET_TICKET_STATUS_DROPDOWN_CALL',
    GET_TICKET_STATUS_DROPDOWN_SUCCESS: 'GET_TICKET_STATUS_DROPDOWN_SUCCESS',
    GET_TICKET_STATUS_DROPDOWN_FAILED: 'GET_TICKET_STATUS_DROPDOWN_FAILED',

    GET_TICKET_USER_DROPDOWN_CALL: 'GET_TICKET_USER_DROPDOWN_CALL',
    GET_TICKET_USER_DROPDOWN_SUCCESS: 'GET_TICKET_USER_DROPDOWN_SUCCESS',
    GET_TICKET_USER_DROPDOWN_FAILED: 'GET_TICKET_USER_DROPDOWN_FAILED',

    GET_TICKET_TYPE_DROPDOWN_CALL: 'GET_TICKET_TYPE_DROPDOWN_CALL',
    GET_TICKET_TYPE_DROPDOWN_SUCCESS: 'GET_TICKET_TYPE_DROPDOWN_SUCCESS',
    GET_TICKET_TYPE_DROPDOWN_FAILED: 'GET_TICKET_TYPE_DROPDOWN_FAILED',

    LOCK_TICKET_ITEM_CALL: 'LOCK_TICKET_ITEM_CALL',
    LOCK_TICKET_ITEM_SUCCESS: 'LOCK_TICKET_ITEM_SUCCESS',
    LOCK_TICKET_ITEM_FAILED: 'LOCK_TICKET_ITEM_FAILED',

    GET_FORM_DROPDOWN_CALL: 'GET_FORM_DROPDOWN_CALL',
    GET_FORM_DROPDOWN_SUCCESS: 'GET_FORM_DROPDOWN_SUCCESS',
    GET_FORM_DROPDOWN_FAILED: 'GET_FORM_DROPDOWN_FAILED',

    GET_MODULE_DROPDOWN_CALL: 'GET_MODULE_DROPDOWN_CALL',
    GET_MODULE_DROPDOWN_SUCCESS: 'GET_MODULE_DROPDOWN_SUCCESS',
    GET_MODULE_DROPDOWN_FAILED: 'GET_MODULE_DROPDOWN_FAILED',
    GET_DASHBOARD_CALL: 'GET_DASHBOARD_CALL',
    GET_DASHBOARD_SUCCESS: 'GET_DASHBOARD_SUCCESS',
    GET_DASHBOARD_FAILED: 'GET_DASHBOARD_FAILED',

    SEND_ITEM_DOCUMENT_SUCCESS: 'SEND_ITEM_DOCUMENT_SUCCESS',
    SEND_ITEM_DOCUMENT_FAILED: 'SEND_ITEM_DOCUMENT_FAILED',
    DELETE_ITEM_DOCUMENT_CALL: 'DELETE_ITEM_DOCUMENT_CALL',
    DELETE_ITEM_DOCUMENT_SUCCESSL: 'DELETE_ITEM_DOCUMENT_SUCCESSL',
    DELETE_ITEM_DOCUMENT_FAILED: 'DELETE_ITEM_DOCUMENT_FAILED',

    GET_CATEGORY_DROPDOWN_CALL: 'GET_CATEGORY_DROPDOWN_CALL',
    GET_CATEGORY_DROPDOWN_SUCCESS: 'GET_CATEGORY_DROPDOWN_SUCCESS',
    GET_CATEGORY_DROPDOWN_FAILED: 'GET_CATEGORY_DROPDOWN_FAILED',
};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function setRowInReducer(rowInfo) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_PARCEL_ROW_IN_REDUCER,
            rowInfo: rowInfo,
        })
    }
}

export function getTicketDropdown() {
    return (dispatch) => {
        dispatch({
            type: Type.GET_TICKET_DROPDOWN_CALL
        });
        axios.get('api/ticket/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_TICKET_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_TICKET_DROPDOWN_FAILED
                });
            });
    }
}

export function getTicketRead(state, instance, mineTickets) {
    return (dispatch) => {
        dispatch({
            type: Type.GET_TICKET_READ_CALL
        });
        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered,
                mineTickets
            }
        };
        axios.get('api/ticket/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_TICKET_READ_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_TICKET_READ_FAILED
                });
            });
    }
}

export function getTicketItemRead(id_tiket) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_TICKET_ITEM_READ_CALL
        });

        var config = {
            params: {
                id_tiket
            }
        };
        axios.get('api/ticket_item/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_TICKET_ITEM_READ_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_TICKET_ITEM_READ_FAILED
                });
            });
    }
}

export function deleteTicket(selection) {
    return (dispatch) => {
        dispatch({
            type: Type.DELETE_TICKET_CALL,
        });
        axios.post(`api/ticket/delete`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_TICKET_SUCCESS,
                    data: response.data,
                    forDelete: selection
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_TICKET_FAILED
                });
            });
    }
}

export function deleteTicketItem(selection) {
    return (dispatch) => {
        dispatch({
            type: Type.DELETE_TICKET_ITEM_CALL,
        });
        axios.post(`api/ticket_item/delete`, { id: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_TICKET_ITEM_SUCCESS,
                    data: response.data,
                    forDelete: selection
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_TICKET_ITEM_FAILED
                });
            });
    }
}

export function addTicketItem(ticketItemObj, id_tiket, acceptedDocuments) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_TICKET_ITEM_CALL
        });

        axios.post('api/ticket_item/create', { id_tiket, ...ticketItemObj, ocekivan_datum: ticketItemObj.ocekivan_datum && moment(ticketItemObj.ocekivan_datum, 'DD.MM.YYYY').format('YYYY-MM-DD') })
            .then(function (response) {
                if (!!acceptedDocuments && acceptedDocuments.length != 0) {
                    var data = new FormData();

                    acceptedDocuments.map(doc => {
                        data.append('id_tiket_stavka', response.data.data.id);
                        data.append('document', doc);
                    })

                    axios({
                        url: `api/ticket_item/upload_document`,
                        method: 'POST',
                        onUploadProgress: function (progressEvent) {
                            console.log('wwww', Math.round((progressEvent.loaded * 100) / progressEvent.total));
                        },
                        data: data,
                        headers: { 'Content-Type': 'multipart/form-data' }
                    }).then((resp) => {
                        dispatch({
                            type: Type.SEND_ITEM_DOCUMENT_SUCCESS
                        });
                        dispatch({
                            type: Type.ADD_TICKET_ITEM_SUCCESS,
                            data: response.data
                        });
                        dispatch(getTicketItemRead(id_tiket));
                    }).catch(function (error) {
                        dispatch({
                            type: Type.SEND_ITEM_DOCUMENT_FAILED
                        })
                    });
                } else {
                    dispatch({
                        type: Type.SEND_ITEM_DOCUMENT_SUCCESS
                    });
                    dispatch({
                        type: Type.ADD_TICKET_ITEM_SUCCESS,
                        data: response.data
                    });
                    dispatch(getTicketItemRead(id_tiket));
                }
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_TICKET_ITEM_FAILED
                });
            });
    }
}

export function deleteItemDocument(item) {
    return (dispatch) => {

        dispatch({
            type: Type.DELETE_ITEM_DOCUMENT_CALL,
        });

        axios.post(`api/ticket_item/document_delete`, item)
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_ITEM_DOCUMENT_SUCCESS,
                    data: response.data
                });
                dispatch(getTicketItemRead(item.id_tiket));
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_ITEM_DOCUMENT_FAILED
                });
            });
    }
}

export function getTicketStatus() {
    return (dispatch) => {
        dispatch({
            type: Type.GET_TICKET_STATUS_DROPDOWN_CALL
        });
        axios.get('api/ticket_status/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_TICKET_STATUS_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_TICKET_STATUS_DROPDOWN_FAILED
                });
            });
    }
}
export function getUser() {
    return (dispatch) => {
        dispatch({
            type: Type.GET_TICKET_USER_DROPDOWN_CALL
        });
        axios.get('api/ticket/user_drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_TICKET_USER_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_TICKET_USER_DROPDOWN_FAILED
                });
            });
    }
}
export function getTicketTypeDropDown() {
    return (dispatch) => {
        dispatch({
            type: Type.GET_TICKET_TYPE_DROPDOWN_CALL
        });
        axios.get('api/ticket/drop_down_tiket_vrsta')
            .then(function (response) {
                dispatch({
                    type: Type.GET_TICKET_TYPE_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_TICKET_TYPE_DROPDOWN_FAILED
                });
            });
    }
}

export function addAndLockTicketItem(ticketItemObj, id_tiket, acceptedDocuments) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_TICKET_ITEM_CALL
        });

        axios.post('api/ticket_item/create', { id_tiket, ...ticketItemObj, ocekivan_datum: ticketItemObj.ocekivan_datum && moment(ticketItemObj.ocekivan_datum, 'DD.MM.YYYY').format('YYYY-MM-DD') })
            .then(function (response) {
                if (!!acceptedDocuments && acceptedDocuments.length != 0) {
                    var data = new FormData();

                    acceptedDocuments.map(doc => {
                        data.append('id_tiket_stavka', response.data.data.id);
                        data.append('document', doc);
                    })

                    axios({
                        url: `api/ticket_item/upload_document`,
                        method: 'POST',
                        onUploadProgress: function (progressEvent) {
                        },
                        data: data,
                        headers: { 'Content-Type': 'multipart/form-data' }
                    }).then((resp) => {
                        dispatch({
                            type: Type.SEND_ITEM_DOCUMENT_SUCCESS
                        });
                        dispatch({
                            type: Type.ADD_TICKET_ITEM_SUCCESS,
                            data: response.data
                        });
                        dispatch(getTicketItemRead(id_tiket));
                        dispatch(lockTicketItem({ zakljucan: false, ...response.data.data }));

                    }).catch(function (error) {
                        dispatch({
                            type: Type.SEND_ITEM_DOCUMENT_FAILED
                        })
                    });
                } else {
                    dispatch({
                        type: Type.ADD_TICKET_ITEM_SUCCESS,
                        data: response.data
                    });
                    dispatch(lockTicketItem({ zakljucan: false, ...response.data.data }));
                }
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_TICKET_ITEM_FAILED
                });
            });
    }
}

export function lockTicketItem(ticketItemObj, id_tiket) {
    return (dispatch) => {

        dispatch({
            type: Type.LOCK_TICKET_ITEM_CALL
        });

        axios.post('api/ticket/lock', { id_tiket, ...ticketItemObj })
            .then(function (response) {
                dispatch({
                    type: Type.LOCK_TICKET_ITEM_SUCCESS,
                    data: response.data
                });
                dispatch(getTicketItemRead(id_tiket || ticketItemObj.id_tiket));
            })
            .catch(function (error) {
                dispatch({
                    type: Type.LOCK_TICKET_ITEM_FAILED
                });
            });
    }
}

export function getModules() {
    return (dispatch) => {
        dispatch({
            type: Type.GET_MODULE_DROPDOWN_CALL
        });
        axios.get('api/ticket/drop_down_modul')
            .then(function (response) {
                dispatch({
                    type: Type.GET_MODULE_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MODULE_DROPDOWN_FAILED
                });
            });
    }
}

export function getForms(id_modul) {
    return (dispatch) => {
        dispatch({
            type: Type.GET_FORM_DROPDOWN_CALL
        });
        axios.get(`api/ticket/drop_down_form${id_modul ? '?id_modul=' + id_modul : ''}`)
            .then(function (response) {
                dispatch({
                    type: Type.GET_FORM_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_FORM_DROPDOWN_FAILED
                });
            });
    }
}

export function getTicketDashboard(filters) {
    return (dispatch) => {
        dispatch({
            type: Type.GET_DASHBOARD_CALL,
        });

        return axios.get('api/ticket/dashboard', { params: { filters } })
            .then(function (response) {
                dispatch({
                    type: Type.GET_DASHBOARD_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_DASHBOARD_FAILED
                });
            });
    }
}

export function getCategory() {
    return (dispatch) => {
        dispatch({
            type: Type.GET_CATEGORY_DROPDOWN_CALL
        });
        axios.get('api/ticket/drop_down_category')
            .then(function (response) {
                dispatch({
                    type: Type.GET_CATEGORY_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_CATEGORY_DROPDOWN_FAILED
                });
            });
    }
}