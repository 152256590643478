import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { getGroup, getSubgroup, getViewCrop, setRowInReducer, setInitialState } from '../../../actions/resources/CropActions';
import i18n from '../../../i18n/i18n';
import Select from 'react-select';
import immutable from 'seamless-immutable';
import AddCrop from './AddCrop';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import {
    IconButton, Tooltip,
    Button, DialogActions,
    Dialog, DialogContent, DialogTitle
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import StageCrop from './StageCrop';
const CheckboxTable = checkboxHOC(ReactTable);

const styles = theme => ({

    center: {
        textAlign: 'center'
    },
    starButton: {
        padding: '3px',
        // color: '#f2ce3e'
    }
});
export class ViewCrop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            add: this.props.add,
            edit: this.props.edit,
            showFilters: this.props.showFilters,
            selection: [],
            selectAll: false,
            stagePopup: false
        };
    }

    componentDidMount() {
        if (this.props.cropGroupDropdown.length === 0) {
            this.props.getGroup();
        }
        if (this.props.cropSubgroupDropdown.length === 0) {
            this.props.getSubgroup();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.deleted === true) {
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getViewCrop(wrapped.state);
            this.setState({
                selection: [],
                selectAll: false,
            })
            this.props.setInitialState('deleted');
        }
        if (nextProps.cropPostingSuccess === true) {
            this.setState({
                selection: [],
                name: '',
                selectAll: false,
            })
            this.props.handleClose();
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getViewCrop(wrapped.state);
            this.props.setInitialState('cropPostingSuccess');
        }
        this.setState({
            add: nextProps.add,
            showFilters: nextProps.showFilters,
            crops: nextProps.crops,
            cropsFetching: nextProps.cropsFetching,
            cropGroupDropdown: immutable.asMutable(nextProps.cropGroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            groupFetching: nextProps.groupFetching,
            groupFetchingFailed: nextProps.groupFetchingFailed,
            cropSubgroupDropdown: immutable.asMutable(nextProps.cropSubgroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            subgroupFetching: nextProps.subgroupFetching,
            subgroupFetchingFailed: nextProps.subgroupFetchingFailed
        })
        if (nextProps.add == true && nextProps.edit == false) {
            this.props.setInitialState('cropObj');
            this.setState({
                selection: [],
                name: '',
                selectAll: false
            })
        }
    }


    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            selection.push(key);
        }
        this.setState({ selection }, function () {
            if (selection.length == 1) {
                let row1 = this.props.crop.filter((crop) => {
                    return crop.id == selection;
                })
                this.props.setRowInReducer(row1[0], 'cropObj');
            }
        });
        this.props.setSelection(selection, 'crop');
    };
    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });
        this.props.setSelection(selection, 'crop');
    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };
    handlePopUpStage = (row) => {
        this.setState({ stagePopup: true, cropRow: row })
    }
    closePopup = () => {
        this.setState({ stagePopup: false })
    }

    render() {
        let content;
        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const { classes } = this.props;

        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "#ACDAF1" : "inherit"
                        }
                    };
                }
                return {};
            }
        };

        const columns = [
            // {
            //     Header: i18n.t('Stages of development'),
            //     accessor: "faze_razvoja",
            //     filterable: false,
            //     width: 60,
            //     Cell: (row) => {
            //         return (
            //             <div className={classes.center}>
            //                 <Tooltip title={i18n.t('Stages of development')}>
            //                     <IconButton
            //                         className={classes.starButton}
            //                         style={{ color: '#61a50e' }}
            //                         onClick={() => this.handlePopUpStage(row)}>
            //                         <Icons.StrikethroughS />
            //                     </IconButton>
            //                 </Tooltip>
            //             </div >
            //         );
            //     }
            // },
            {
                Header: i18n.t('Title'),
                accessor: 'naziv'
            },
            {
                Header: i18n.t('Code'),
                accessor: 'sifra_kp',
            },
            {
                Header: i18n.t('Organic production'),
                accessor: 'sifra_op',
            },
            {
                Header: i18n.t('Parcel color'),
                accessor: 'boja_table',
            },
            {
                Header: i18n.t('Group'),
                accessor: 'kultura_grupa_naziv',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.cropGroupDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Subgroup'),
                accessor: 'kultura_podgrupa_naziv',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.cropSubgroupDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            // {
            //     Header: i18n.t('Average yield'),
            //     accessor: 'prosecan_prinos'
            // },
            // {
            //     Header: i18n.t('Precipitation of'),
            //     accessor: 'padavine_od'
            // },
            // {
            //     Header: i18n.t('Precipitation up to'),
            //     accessor: 'padavine_do'
            // },
            // {
            //     Header: i18n.t('Sum of mean daily temperatures of'),
            //     accessor: 'stepeni_od'
            // },
            // {
            //     Header: i18n.t('Sum of average daily temperatures up to'),
            //     accessor: 'stepeni_do'
            // }
        ];
        content =
            <div className="page">
                <CheckboxTable
                    ref={r => (this.checkboxTable = r)}
                    data={this.props.crop}
                    columns={columns}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    showPaginationTop
                    keyField={'id'}
                    showPaginationBottom={false}
                    pages={this.props.cropsPages}
                    loading={this.props.cropsFetching}
                    manual
                    onFetchData={(state, instance) => this.props.getViewCrop(state, instance)}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF',
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
                {this.state.add && <AddCrop add={this.state.add} edit={this.props.edit}
                    handleClose={this.props.handleClose} />}


                {this.state.stagePopup &&
                    <Dialog
                        open={this.state.stagePopup}
                        onClose={this.closePopup}
                        disableBackdropClick
                        maxWidth="sm"
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle disableTypography={true} className="responsive-dialog-title">
                            {i18n.t('Stages of development')}
                            <IconButton onClick={this.closePopup}>
                                <span className="icon-cancel-circle closeIcon" />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent style={{ minHeight: '280px' }}>
                            <StageCrop />
                        </DialogContent>

                    </Dialog>
                }
            </div >

        return (
            <div>
                {content}
            </div >
        )
    }


};

function mapStateToProps(state) {
    return {
        crop: state.cropReducer.crop,
        cropsFetching: state.cropReducer.cropsFetching,
        cropsPages: state.cropReducer.cropsPages,
        cropGroupDropdown: state.cropReducer.cropGroupDropdown,
        deleted: state.cropReducer.deleted,
        groupFetching: state.cropReducer.groupFetching,
        groupFetchingFailed: state.cropReducer.groupFetchingFailed,
        cropSubgroupDropdown: state.cropReducer.cropSubgroupDropdown,
        subgroupFetching: state.cropReducer.subgroupFetching,
        subgroupFetchingFailed: state.cropReducer.subgroupFetchingFailed,
        cropPosting: state.cropReducer.cropPosting,
        cropPostingSuccess: state.cropReducer.cropPostingSuccess,
        cropPostingFailed: state.cropReducer.cropPostingFailed
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getGroup: () => dispatch(getGroup()),
        setInitialState: (component) => dispatch(setInitialState(component)),
        setRowInReducer: (rowInfo, name) => dispatch(setRowInReducer(rowInfo, name)),
        getSubgroup: () => dispatch(getSubgroup()),
        getViewCrop: (state, instance) => dispatch(getViewCrop(state, instance)),
    }
}

export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewCrop));