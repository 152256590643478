import React from 'react';
import { connect } from 'react-redux';
import FarmDashboard from './farmRegistar/FarmDashboard';
import AdminReport from './admin/AdminReport';
import OpstinaLanding from './opstina/OpstinaLanding';
import OpstinaLandingNew from './opstina/OpstinaLandingNew';
import MinistarstvoLandingNew from './ministarstvo/MinistarstvoLandingNew';
import SubsidiesDashboard from './financial/subsidies/SubsidiesDashboard';
import IncentivesDashboard from './financial/incentives/IncentivesDashboard';
import ProgramDashboard from './financial/program/ProgramDashboard';
import NoteDashboard from './reports/Dashboards/NoteDashboard';
import AgroProductionDashboard from './fieldManager/AgroProductionDashboard';
import RegistrationCount from './admin/RegistrationCount';
import { getUser } from '../utils/CommonFunctions';
import Card from '@material-ui/core/Card';
import i18n from '../i18n/i18n';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
export class GlobalDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            global: true,
            value: 0,
            user: {}
        };
    }

    async componentDidMount() {
        let user = await getUser();
        this.setState({ user })
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };



    render() {
        const { value, user } = this.state;
        return (
            <div style={{ width: '100%'}}>
                {user.id_klijent_grupa === 1 &&
                    <Tabs className="tabContainer">
                        <Tab label={user.ime_prezime} />
                    </Tabs>

                }
                {user.id_klijent_grupa === 8 &&
                    <Tabs className="tabContainer">
                        <Tab label={user.ime_prezime} />
                    </Tabs>

                }
                {user.id_klijent_grupa === 1 &&
                    <OpstinaLandingNew />

                }
                {/* {user.id_region !== 1 &&
                    <Tabs className="tabContainer" value={value} onChange={this.handleChange}>
                        <Tab className={value == 0 ? "tabs" : ""} label={i18n.t("Farmers")} />
                        <Tab className={value == 1 ? "tabs" : ""} label={i18n.t("Subsidies")} />
                        <Tab className={value == 2 ? "tabs" : ""} label={i18n.t("Incentives")} />
                        <Tab className={value == 3 ? "tabs" : ""} label={i18n.t("Financial programs")} />
                    </Tabs>
                } */}
                {user.id_klijent_grupa !== 1 && user.id_klijent_grupa !== 8 &&
                    <Tabs className="tabContainer" value={value} onChange={this.handleChange}>
                        <Tab className={value == 0 ? "tabs" : ""} label={i18n.t("Farmers")} />
                        <Tab className={value == 1 ? "tabs" : ""} label={i18n.t("Notes")} />
                        <Tab className={value == 2 ? "tabs" : ""} label={i18n.t("Activities")} />
                        <Tab className={value == 3 ? "tabs" : ""} label={i18n.t("Registrations")} />
                    </Tabs>
                }
                {user.id_klijent_grupa !== 1 && <div>
                    {/* <Card style={styles.card}>
                    <FarmDashboard global={this.state.global} />
                </Card> */}
                    <MinistarstvoLandingNew />
                </div>
                }

                {value === 1 && user.id_region != 1 && <Card style={styles.card}>
                    <SubsidiesDashboard global={this.state.global} />
                </Card>}
                {value === 2 && user.id_region != 1 && <Card style={styles.card}>
                    <IncentivesDashboard global={this.state.global} />
                </Card>}
                {value === 3 && user.id_region != 1 && <Card style={styles.card}>
                    <ProgramDashboard global={this.state.global} />
                </Card>}
                {user.id_region == 1 &&
                    <div>
                        {value === 1 && <Card style={styles.card}>
                            <NoteDashboard global={this.state.global} />
                        </Card>}
                        {value === 2 && <Card style={styles.card}>
                            <AgroProductionDashboard global={this.state.global} />
                        </Card>}
                        {value === 3 && <Card style={styles.card}>
                            <RegistrationCount global={this.state.global} />
                        </Card>}
                    </div>
                }
            </div >
        )
    }
};

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GlobalDashboard);
const styles = {
    card: {
        minWidth: 275,
        padding: '10px',
        margin: '10px'
    },
}