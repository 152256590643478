import axios from '../../utils/AxiosWrapper';
export const Type = {
    //DROPDOWN
    GET_FARMER_DROP_DOWN_CALL: 'GET_FARMER_DROP_DOWN_CALL',
    GET_FARMER_DROP_DOWN_SUCCESS: 'GET_FARMER_DROP_DOWN_SUCCESS',
    GET_FARMER_DROP_DOWN_FAILED: 'GET_FARMER_DROP_DOWN_FAILED',
    //READ
    GET_INCENTIVES_CALL: 'GET_INCENTIVES_CALL',
    GET_INCENTIVES_SUCCESS: 'GET_INCENTIVES_SUCCESS',
    GET_INCENTIVES_FAILED: 'GET_INCENTIVES_FAILED',
    //CREATE
    ADD_INCENTIVE_CALL: 'ADD_INCENTIVE_CALL',
    ADD_INCENTIVE_SUCCESS: 'ADD_INCENTIVE_SUCCESS',
    ADD_INCENTIVE_FAILED: 'ADD_INCENTIVE_FAILED',
    //DELETE
    DELETE_INCENTIVE_CALL: 'DELETE_INCENTIVE_CALL',
    DELETE_INCENTIVE_SUCCESS: 'DELETE_INCENTIVE_SUCCESS',
    DELETE_INCENTIVE_FAILED: 'DELETE_INCENTIVE_FAILED',
    //DASHBOARD
    GET_INCENTIVE_DASHBOARD_CALL: 'GET_INCENTIVE_DASHBOARD_CALL',
    GET_INCENTIVE_DASHBOARD_SUCCESS: 'GET_INCENTIVE_DASHBOARD_SUCCESS',
    GET_INCENTIVE_DASHBOARD_FAILED: 'GET_INCENTIVE_DASHBOARD_FAILED',
    //SET
    SET_INCETIVES_ROW_IN_REDUCER: 'SET_INCETIVES_ROW_IN_REDUCER',
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',

};

export function getIncentives(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_INCENTIVES_CALL
        });
        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/incentive/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_INCENTIVES_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_INCENTIVES_FAILED
                });
            });
    }
}

export function getFarmersDropdown() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_FARMER_DROP_DOWN_CALL
        });

        axios.get('api/farmRegistar/drop_down_farmer')
            .then(function (response) {
                dispatch({
                    type: Type.GET_FARMER_DROP_DOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_FARMER_DROP_DOWN_FAILED
                });
            });
    }
}


export function addIncentive(incentiveObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_INCENTIVE_CALL
        });

        axios.post('api/incentive/create', incentiveObj)
            .then(function (response) {
                dispatch({
                    type: Type.ADD_INCENTIVE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_INCENTIVE_FAILED
                });
            });
    }
}

export function deleteIncentive(selection) {
    return (dispatch) => {

        dispatch({
            type: Type.DELETE_INCENTIVE_CALL,
        });

        axios.post(`api/incentive/delete`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_INCENTIVE_SUCCESS,
                    data: response.data,
                    forDelete: selection
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_INCENTIVE_FAILED
                });
            });
    }
}

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function setRowInReducer(rowInfo, name) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INCETIVES_ROW_IN_REDUCER,
            rowInfo: rowInfo,
            name: name
        })
    }
}

export function getIncentivesDashboard(filters){
    return (dispatch) => {
        dispatch({
            type: Type.GET_INCENTIVE_DASHBOARD_CALL,
        });

        return axios.get('api/incentive/dashboard', { params: { filters } })
            .then(function (response) {
                dispatch({
                    type: Type.GET_INCENTIVE_DASHBOARD_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_INCENTIVE_DASHBOARD_FAILED
                });
            });
    }
}