import React from 'react';
import { connect } from 'react-redux';
import {
    Button, IconButton, Menu, MenuItem
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { Doughnut, Bar, Pie, HorizontalBar } from 'react-chartjs-2';
import immutable from 'seamless-immutable';
import Select, { components } from 'react-select';
import {
    getLocalGov,
} from '../../../actions/FarmRegistarActions';
import { getStates } from '../../../actions/AdministrationActions';
import InputLabel from '@material-ui/core/InputLabel';
import 'react-toastify/dist/ReactToastify.css';
import { getMaterialDropdown, getMaterialGroup, getMaterialSubgroup } from '../../../actions/resources/MaterialActions';
import i18n from '../../../i18n/i18n';
import randomColor from 'randomcolor';
import { getStatisticsDashboard } from '../../../actions/reports/ReportsActions';
import DashboardDrawer from '../../../utils/DashboardDrawer';
import { getUser } from '../../../utils/CommonFunctions';

const IndicatorsContainer = (props) => {
    return (
        <div style={{ background: '#61a50e' }}>
            <components.IndicatorsContainer {...props} />
        </div>
    );
};
export class StatisticsDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            id_opstina: [],
            id_katastarska_opstina: [],
            id_materijal: [],
            id_materijal_grupa: [],
            id_materijal_podgrupa: [],
        };
    }

    componentDidMount() {
        this.props.getStatisticsDashboard({});
        if (this.props.states.length === 0) {
            this.props.getStates();
        }
        if (this.props.localGoverments.length === 0) {
            this.props.getLocalGov();
        }
        if (this.props.materialDropdown.length === 0) {
            this.props.getMaterialDropdown();
        }
        if (this.props.materialGroupDropdown.length === 0) {
            this.props.getMaterialGroup();
        }
        if (this.props.materialSubgroupDropdown.length === 0) {
            this.props.getMaterialSubgroup();
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            states: immutable.asMutable(nextProps.states.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            localGoverments: immutable.asMutable(nextProps.localGoverments.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            materialDropdown: immutable.asMutable(nextProps.materialDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            materialGroupDropdown: immutable.asMutable(nextProps.materialGroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            materialSubgroupDropdown: immutable.asMutable(nextProps.materialSubgroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),

        })
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleClear = () => {
        this.setState({
            id_opstina: [],
            id_katastarska_opstina: [],
            id_materijal: [],
            id_materijal_grupa: [],
            id_materijal_podgrupa: [],
        }, function () {
            this.props.getStatisticsDashboard({});
        });
    };


    handleOk = () => {
        let filters = {};
        filters['id_opstina'] = this.state.id_opstina.map((item) => {
            return item.value;
        })
        filters['id_katastarska_opstina'] = this.state.id_katastarska_opstina.map((item) => {
            return item.value;
        });
        filters['id_materijal'] = this.state.id_materijal.map((item) => {
            return item.value;
        });
        filters['id_materijal_grupa'] = this.state.id_materijal_grupa.map((item) => {
            return item.value;
        });
        filters['id_materijal_podgrupa'] = this.state.id_materijal_podgrupa.map((item) => {
            return item.value;
        });
        this.props.getStatisticsDashboard(filters);
    }

    handleChange = name => selectedOption => {
        this.setState({
            [name]: selectedOption
        })
    }


    onPieClick = () => {
        this.props.history.push('/statistics_dashboard');
    }

    // onTableClick = () => {
    //     this.props.history.push('/statistics');
    // }



    render() {
        const { anchorEl } = this.state;
        let materialGroup = this.materialGroup;
        let materials = this.materials;
        let materialSubgroup = this.materialSubgroup;
        let height = 200;
        let statesData = this.states;
        let localGovData = this.localGov;
        let user = getUser();
        if (this.props.statisticsItems && this.props.statisticsItems.total > 500) {
            height = 500;
        }

        if (this.props.statisticsItems && this.props.statisticsItems.states) {
            statesData.labels = this.props.statisticsItems.states.labels.asMutable();
            statesData.datasets[0].data = this.props.statisticsItems.states.count.asMutable();
            statesData.datasets[0].data1 = this.props.statisticsItems.states.percent.asMutable();
        }
        if (this.props.statisticsItems && this.props.statisticsItems.localGoverments) {
            localGovData.labels = this.props.statisticsItems.localGoverments.labels.asMutable();
            localGovData.datasets[0].data = this.props.statisticsItems.localGoverments.count.asMutable();
            localGovData.datasets[0].data1 = this.props.statisticsItems.localGoverments.percent.asMutable();
        }
        if (this.props.statisticsItems && this.props.statisticsItems.materialGroup) {
            materialGroup.labels = this.props.statisticsItems.materialGroup.labels.asMutable();
            materialGroup.datasets[0].data = this.props.statisticsItems.materialGroup.count.asMutable();
            materialGroup.datasets[0].data1 = this.props.statisticsItems.materialGroup.quantity.asMutable();
        }

        if (this.props.statisticsItems && this.props.statisticsItems.materialSubgroup) {
            materialSubgroup.labels = this.props.statisticsItems.materialSubgroup.labels.asMutable();
            materialSubgroup.datasets[0].data = this.props.statisticsItems.materialSubgroup.count.asMutable();
            materialSubgroup.datasets[0].data1 = this.props.statisticsItems.materialSubgroup.quantity.asMutable();
        }

        if (this.props.statisticsItems && this.props.statisticsItems.material) {
            materials.labels = this.props.statisticsItems.material.labels.asMutable();
            materials.datasets[0].data = this.props.statisticsItems.material.count.asMutable();
            materials.datasets[0].data1 = this.props.statisticsItems.material.quantity.asMutable();
        }


        return (
            <div style={{ 'padding': '20px' }}>

                <Card style={styles.card}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <h1>{i18n.t('Total number of statistics report')} : {this.props.statisticsItems.total} </h1>
                        <div className="filter-panel-dashboard">
                            <IconButton
                                styles={styles.button}
                                aria-owns={anchorEl ? 'simple-menu' : null}
                                aria-haspopup="true"
                                className="action-button-filter"
                                onClick={this.handleClick}
                            >
                                <span className="icon-search headerIcon" />
                            </IconButton>
                        </div>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={this.handleClose}
                        >
                            <InputLabel className="filter-label">{i18n.t('States')}:</InputLabel>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                isClearable={false}
                                value={this.state.id_opstina}
                                components={{
                                    IndicatorsContainer: IndicatorsContainer
                                }}
                                maxMenuHeight={150}
                                menuPlacement='auto'
                                onChange={this.handleChange('id_opstina')}
                                options={this.state.states}
                                className="multi-select-in-filter-dashboards"
                                classNamePrefix="select"
                                placeholder={i18n.t('Select')}
                            />
                            <InputLabel className="filter-label">{i18n.t('Local government')}:</InputLabel>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                isClearable={false}
                                value={this.state.id_katastarska_opstina}
                                components={{
                                    IndicatorsContainer: IndicatorsContainer
                                }}
                                maxMenuHeight={150}
                                onChange={this.handleChange('id_katastarska_opstina')}
                                options={this.state.localGoverments}
                                className="multi-select-in-filter-dashboards"
                                classNamePrefix="select"
                                placeholder={i18n.t('Select')}
                            />
                            <InputLabel className="filter-label">{i18n.t('Material group')}:</InputLabel>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                isClearable={false}
                                value={this.state.id_materijal_grupa}
                                components={{
                                    IndicatorsContainer: IndicatorsContainer
                                }}
                                maxMenuHeight={150}
                                onChange={this.handleChange('id_materijal_grupa')}
                                options={this.state.materialGroupDropdown}
                                className="multi-select-in-filter-dashboards"
                                classNamePrefix="select"
                                placeholder={i18n.t('Select')}
                            />
                            <InputLabel className="filter-label">{i18n.t('Material subgroup')}:</InputLabel>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                isClearable={false}
                                value={this.state.id_materijal_podgrupa}
                                components={{
                                    IndicatorsContainer: IndicatorsContainer
                                }}
                                maxMenuHeight={150}
                                onChange={this.handleChange('id_materijal_podgrupa')}
                                options={this.state.materialSubgroupDropdown}
                                className="multi-select-in-filter-dashboards"
                                classNamePrefix="select"
                                placeholder={i18n.t('Select')}
                            />
                            <InputLabel className="filter-label">{i18n.t('Material')}:</InputLabel>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                isClearable={false}
                                value={this.state.id_materijal}
                                components={{
                                    IndicatorsContainer: IndicatorsContainer
                                }}
                                maxMenuHeight={150}
                                onChange={this.handleChange('id_materijal')}
                                options={this.state.materialDropdown}
                                className="multi-select-in-filter-dashboards"
                                classNamePrefix="select"
                                placeholder={i18n.t('Select')}
                            />
                            <div style={{ padding: '30px' }}>
                                <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleOk}>{i18n.t('Ok')}</Button>
                                <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleClose}>{i18n.t('Cancel')}</Button>
                                <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleClear}>{i18n.t('Clear')}</Button>

                            </div>
                        </Menu>
                    </div>
                </Card>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Card style={styles.cardMin}>
                        <h1>{i18n.t('States')}:</h1>
                        <Pie data={statesData} options={this.teritoryOptions} />
                    </Card>
                    {user.id_klijent_grupa !== 1 &&
                        <Card style={styles.cardMin}>
                            <h1>{i18n.t('Local governments')}:</h1>
                            <Pie data={localGovData} options={this.teritoryOptions} />
                        </Card>
                    }
                </div >
                <Card style={styles.card}>
                    <h1>{i18n.t('Material consumption by group')}:</h1>
                    <HorizontalBar height={100} data={materialGroup} options={this.options} />
                </Card>

                <Card style={styles.card}>
                    <h1>{i18n.t('Material consumption by subgroup')}:</h1>
                    <HorizontalBar height={150} data={materialSubgroup} options={this.options} />
                </Card>

                <Card style={styles.card}>
                    <h1>{i18n.t('Material consumption')}: </h1>
                    {this.props.statisticsItems && this.props.statisticsItems.material &&
                        <HorizontalBar height={height} data={materials} options={this.options} />
                    }
                </Card>
                {/* <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} /> */}
            </div>
        )
    }

    materialGroupColor = randomColor({
        count: 30
    })

    materialsColor = randomColor({
        count: 200
    })

    materialSubgroupColor = randomColor({
        count: 50
    })

    statesColor = randomColor({
        count: 50
    })

    localGovColor = randomColor({
        count: 50
    })

    states = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.statesColor,
            hoverBackgroundColor: this.statesColor
        }]
    };
    localGov = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.localGovColor,
            hoverBackgroundColor: this.localGovColor
        }]
    };



    materialGroup = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            label: i18n.t('Material group consumption'),
            backgroundColor: this.materialGroupColor,
            borderColor: this.materialGroupColor,
            borderWidth: 1,
            hoverBackgroundColor: this.materialGroupColor,
            hoverBorderColor: this.materialGroupColor,
        }]
    };
    materialSubgroup = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            label: i18n.t('Material subgroup consumption'),
            backgroundColor: this.materialSubgroupColor,
            borderColor: this.materialSubgroupColor,
            borderWidth: 1,
            hoverBackgroundColor: this.materialSubgroupColor,
            hoverBorderColor: this.materialSubgroupColor,
        }]
    };
    materials = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            label: i18n.t('Material consumption'),
            backgroundColor: this.materialsColor,
            borderColor: this.materialsColor,
            borderWidth: 1,
            hoverBackgroundColor: this.materialsColor,
            hoverBorderColor: this.materialsColor,
        }]
    };

    teritoryOptions = {
        tooltips: {
            mode: 'label',
            callbacks: {

                afterLabel: function (tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex].data1[tooltipItem.index] + '%';
                },

            },
        }
    };

    options = {
        tooltips: {
            mode: 'label',
            callbacks: {

                afterLabel: function (tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex].data1[tooltipItem.index] + 'kg';
                },

            },
        }
    };



};

function mapStateToProps(state) {
    return {
        statisticsItems: state.reportsReducer.statisticsItems,
        states: state.administrationReducer.states,
        localGoverments: state.farmRegistarReducer.localGoverments,
        materialDropdown: state.materialReducer.materialDropdown,
        materialGroupDropdown: state.materialReducer.materialGroupDropdown,
        materialSubgroupDropdown: state.materialReducer.materialSubgroupDropdown,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getStatisticsDashboard: (filters) => dispatch(getStatisticsDashboard(filters)),
        getMaterialGroup: () => dispatch(getMaterialGroup()),
        getMaterialSubgroup: () => dispatch(getMaterialSubgroup()),
        getStates: () => dispatch(getStates()),
        getLocalGov: () => dispatch(getLocalGov()),
        getMaterialDropdown: () => dispatch(getMaterialDropdown())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StatisticsDashboard);

const styles = {
    card: {
        minWidth: 275,
        padding: '10px',
        margin: '10px'
    },
    cardMin: {
        width: '45%',
        padding: '10px',
        margin: '10px',
        position: 'relative',
        float: 'left'
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
};