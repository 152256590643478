import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n/i18n';
import { Button, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
//import { SketchPicker } from 'react-color';
//import Modal from '@material-ui/core/Modal';
//  import InputLabel from '@material-ui/core/InputLabel';
import { DatePicker } from 'material-ui-pickers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addSeason, setInitialState } from '../../../actions/resources/SeasonsActions';
import { getRegionType } from '../../../actions/control_panel/RegionActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export class AddSeasons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            seasonObj: this.props.seasonObj,
            displayColorPicker: false,
            openModal: false,
        }
    }

    componentDidMount() {
        if (this.props.regionType.length === 0) {
            this.props.getRegionType();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.seasonPostingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('seasonPostingFailed');
        }
    }

    addSeason() {
        if (this.state.seasonObj.naziv &&
            this.state.seasonObj.datum_od &&
            this.state.seasonObj.datum_do &&
            this.state.seasonObj.id_region
        ) {
            this.props.addSeason(
                this.state.seasonObj
            )

        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    handleChangeValue = name => event => {
        let obj = Object.assign({}, this.state.seasonObj);
        obj[name] = event.target.value;
        this.setState({ seasonObj: obj });
    };

    handleDateChange = name => date => {
        let obj = Object.assign({}, this.state.seasonObj);
        obj[name] = date;
        this.setState({ seasonObj: obj });
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker, openModal: !this.state.openModal })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false, openModal: false })
    };


    render() {
        let regionType = [];
        if (this.props.regionType && !this.props.regionTypeFetching) {
            regionType = this.props.regionType.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        return (
            <div style={{ padding: 20 }}>
                {/* <Paper className="roundedBoxMaterial" zdepth={4}> */}
                <Dialog
                    open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="xs"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit season') : i18n.t('Add season')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-season">
                            <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                                <Grid item>
                                    <TextField
                                        required
                                        label={i18n.t('Title')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.state.seasonObj.naziv}
                                        onChange={this.handleChangeValue('naziv')}
                                    />
                                </Grid>
                                <Grid item >
                                    <DatePicker
                                        keyboard
                                        label={i18n.t('Start date')}
                                        format="DD.MM.YYYY"
                                        value={this.state.seasonObj.datum_od}
                                        onChange={this.handleDateChange('datum_od')}
                                        disableOpenOnEnter
                                        animateYearScrolling={false}
                                    />
                                </Grid>
                                <Grid item>
                                    <DatePicker
                                        keyboard
                                        label={i18n.t('End date')}
                                        format="DD.MM.YYYY"
                                        value={this.state.seasonObj.datum_do}
                                        onChange={this.handleDateChange('datum_do')}
                                        disableOpenOnEnter
                                        animateYearScrolling={false}
                                    />
                                </Grid>
                                <Grid item >
                                    <TextField required
                                        label={i18n.t('Region type')}
                                        select
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        defaultValue={this.state.seasonObj.id_region}
                                        onChange={this.handleChangeValue('id_region')} >
                                        <option value='' />
                                        {regionType}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button style={{ alignSelf: 'center', backgroundColor: '#58B4E5', color: 'white' }}
                                        onClick={() => this.addSeason()} >
                                        {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
                {/* </Paper> */}
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        seasonPosting: state.seasonReducer.seasonPosting,
        seasonObj: state.seasonReducer.seasonObj,
        seasonPostingFailed: state.seasonReducer.seasonPostingFailed,

        regionType: state.clientReducer.regionType,
        regionTypeFetching: state.clientReducer.regionTypeFetching,
        regionTypeFetchingFailed: state.clientReducer.regionTypeFetchingFailed,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInitialState: (component) => dispatch(setInitialState(component)),
        addSeason: (seasonObj) => dispatch(addSeason(seasonObj)),

        getRegionType: () => dispatch(getRegionType()),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddSeasons)