import immutable from 'seamless-immutable';
import { Type as FarmRegistarType } from '../actions/FarmRegistarActions';


export const INITIAL_STATE = immutable({
    localGoverments: [],
    localGovermentsFetching: false,
    localGovermentsFetchingFailed: false,
    title: [],
    titleFetching: false,
    titleFetchingFailed: false,
    maritalStatus: [],
    maritalStatusFetching: false,
    maritalStatusFetchingFailed: false,
    identificationDoc: [],
    identificationDocFetching: false,
    identificationDocFetchingFailed: false,
    farmPosting: [],
    farmPostingSuccess: false,
    farmPostingFailed: false,

    farmersItems: {},
    farmersFetching: false,
    farmersFetchingFailed: false,

    farmers: [],
    farmerObj: {},
    farmersFetching: false,
    farmersFetchingFailed: false,
    farmersPages: 1,

    users: [],
    usersObj: {},
    usersFetching: false,
    usersFetchingFailed: false,
    usersPages: 1,

    anchorDropdown: [],
    anchorDropdownFetching: false,
    anchorDropdownFailed: false,

    addAnchorSuccess: false,
    deleteAnchorSuccess: false,
    verifyBVNFetching: false,
    verifyBVNFailed: false,
    verifyBVNSuccess: false,
    bvnImage: '',
    farmerImage: '',

    myFarmers: [],
    myFarmersObj: {},
    myFarmersFetching: false,
    myFarmersFetchingFailed: false,
    myFarmersPages: 1,

    getAnchorForFarmerSuccess:false

});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case FarmRegistarType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;

        case FarmRegistarType.SET_FARMER_ROW_IN_REDUCER: {
            const data = action.rowInfo.asMutable();
            return state.merge({ [action.name]: data })
            break;
        }
        case FarmRegistarType.GET_LOCAL_GOV_CALL: {
            const localGovermentsFetching = true;
            return state.merge({ localGovermentsFetching });
            break;
        }

        case FarmRegistarType.GET_LOCAL_GOV_SUCCESS: {
            const localGoverments = action.data.data;
            const localGovermentsFetching = false;
            return state.merge({ localGoverments, localGovermentsFetching });
            break;
        }

        case FarmRegistarType.GET_LOCAL_GOV_FAILED: {
            const localGovermentsFetchingFailed = true;
            const localGovermentsFetching = false;
            return state.merge({ localGovermentsFetchingFailed, localGovermentsFetching });
            break;
        }

        case FarmRegistarType.GET_TITLE_CALL: {
            const titleFetching = true;
            return state.merge({ titleFetching });
            break;
        }

        case FarmRegistarType.GET_TITLE_SUCCESS: {
            const title = action.data.data;
            const titleFetching = false;
            return state.merge({ title, titleFetching });
            break;
        }

        case FarmRegistarType.GET_TITLE_FAILED: {
            const titleFetchingFailed = true;
            const titleFetching = false;
            return state.merge({ titleFetchingFailed, titleFetching });
            break;
        }

        case FarmRegistarType.GET_FARM_OFFICE_CALL: {
            const farmersFetching = true;
            return state.merge({ farmersFetching });
            break;
        }

        case FarmRegistarType.GET_FARM_OFFICE_SUCCESS: {
            const farmers = action.data.data;
            const farmersFetching = false;
            const farmersPages = action.data.total_pages
            return state.merge({ farmers, farmersFetching, farmersPages });
            break;
        }


        case FarmRegistarType.GET_FARM_OFFICE_FAILED: {
            const farmersFetchingFailed = true;
            const farmersFetching = false;
            return state.merge({ farmersFetchingFailed, farmersFetching });
            break;
        }

        case FarmRegistarType.GET_FARMDASHBOARD_CALL: {
            const farmersFetching = true;
            return state.merge({ farmersFetching });
            break;
        }

        case FarmRegistarType.GET_FARMDASHBOARD_SUCCESS: {
            let farmersItems = state.farmersItems.asMutable();
            farmersItems = { ...farmersItems, ...action.data };
            const farmersFetching = false;
            return state.merge({ farmersItems: farmersItems, farmersFetching });
            break;
        }

        case FarmRegistarType.GET_FARMERS_COUNT_SUCCESS: {
            let farmersItems = state.farmersItems.asMutable();
            farmersItems.totalSerbia = action.data.totalSerbia;
            farmersItems.totalNigeria = action.data.totalNigeria;
            return state.merge({ farmersItems });
            break;
        }

        case FarmRegistarType.GET_FARMDASHBOARD_FAILED: {
            const farmersFetchingFailed = true;
            const farmersFetching = false;
            return state.merge({ farmersFetching, farmersFetchingFailed });
            break;
        }

        case FarmRegistarType.GET_MARITAL_STATUS_CALL: {
            const maritialStatusFetching = true;
            return state.merge({ maritialStatusFetching });
            break;
        }

        case FarmRegistarType.GET_MARITAL_STATUS_SUCCESS: {
            const maritalStatus = action.data.data;
            const maritalStatusFetching = false;
            return state.merge({ maritalStatus, maritalStatusFetching });
            break;
        }

        case FarmRegistarType.GET_MARITAL_STATUS_FAILED: {
            const maritalStatusFetchingFailed = true;
            const maritalStatusFetching = false;
            return state.merge({ maritalStatusFetchingFailed, maritalStatusFetching });
            break;
        }

        case FarmRegistarType.BVN_VALIDATION_CALL: {
            const verifyBVNFetching = true;
            return state.merge({ verifyBVNFetching })
        }

        case FarmRegistarType.BVN_VALIDATION_SUCCESS: {
            let farmerImage;
            const verifyBVNSuccess = true;
            const verifyBVNFetching = false;
            let bvnData = action.data.data.data.split(',');
            const bvnImage = bvnData[0];
            const image = bvnData[1];
            if (image == 'null') {
                farmerImage = 'none'
            } else {
                farmerImage = image;
            }
            return state.merge({ verifyBVNSuccess, bvnImage, farmerImage, verifyBVNFetching })
        }

        case FarmRegistarType.BVN_VALIDATION_FAILED: {
            const verifyBVNFetching = false;
            const verifyBVNFailed = true;
            return state.merge({ verifyBVNFailed, verifyBVNFetching })
        }

        case FarmRegistarType.GET_IDENTIFICATION_DOC_CALL: {
            const identificationDocFetching = true;
            return state.merge({ identificationDocFetching });
            break;
        }

        case FarmRegistarType.GET_IDENTIFICATION_DOC_SUCCESS: {
            const identificationDoc = action.data.data;
            const identificationDocFetching = false;
            return state.merge({ identificationDoc, identificationDocFetching });
            break;
        }

        case FarmRegistarType.GET_IDENTIFICATION_DOC_FAILED: {
            const identificationDocFetchingFailed = true;
            const identificationDocFetching = false;
            return state.merge({ identificationDocFetchingFailed, identificationDocFetching });
            break;
        }

        case FarmRegistarType.ADD_FARM_OFFICE_CALL: {
            const farmPosting = true;
            return state.merge({ farmPosting });
            break;
        }

        case FarmRegistarType.ADD_FARM_OFFICE_SUCCESS: {
            const farmPosting = false;
            const farmPostingSuccess = true;
            return state.merge({ farmPostingSuccess, farmPosting })
            break;
        }

        case FarmRegistarType.ADD_FARM_OFFICE_FAILED: {
            const farmPosting = false;
            const farmPostingFailed = true;
            return state.merge({ farmPosting, farmPostingFailed });
            break;
        }

        case FarmRegistarType.ADD_ANCHOR_SUCCESS: {
            const addAnchorSuccess = true;
            return state.merge({ addAnchorSuccess })
        }

        case FarmRegistarType.DELETE_ANCHOR_SUCCESS: {
            const deleteAnchorSuccess = true;
            return state.merge({ deleteAnchorSuccess })
        }

        case FarmRegistarType.GET_USERS_CALL: {
            const usersFetching = true;
            return state.merge({ usersFetching });
            break;
        }

        case FarmRegistarType.GET_USERS_SUCCESS: {
            const users = action.data.data;
            const usersFetching = false;
            const usersPages = action.data.total_pages
            return state.merge({ users, usersFetching, usersPages });
            break;
        }


        case FarmRegistarType.GET_USERS_FAILED: {
            const usersFetchingFailed = true;
            const usersFetching = false;
            return state.merge({ usersFetchingFailed, usersFetching });
            break;
        }

        //
        case FarmRegistarType.GET_ANCHOR_DROP_DOWN_CALL: {
            const anchorDropdownFetching = true;
            return state.merge({ anchorDropdownFetching });
            break;
        }

        case FarmRegistarType.GET_ANCHOR_DROP_DOWN_SUCCESS: {
            const anchorDropdown = action.data.data;
            const anchorDropdownFetching = false;
            return state.merge({ anchorDropdown, anchorDropdownFetching });
            break;
        }

        case FarmRegistarType.GET_ANCHOR_DROP_DOWN_FAILED: {
            const anchorDropdownFailed = true;
            const anchorDropdownFetching = false;
            return state.merge({ anchorDropdownFailed, anchorDropdownFetching });
            break;
        }

        case FarmRegistarType.GET_MY_FARM_OFFICE_CALL: {
            const myFarmersFetching = true;
            return state.merge({ myFarmersFetching });
            break;
        }

        case FarmRegistarType.GET_MY_FARM_OFFICE_SUCCESS: {
            const myFarmers = action.data.data;
            const myFarmersFetching = false;
            const myFarmersPages = action.data.total_pages
            return state.merge({ myFarmers, myFarmersFetching, myFarmersPages });
            break;
        }


        case FarmRegistarType.GET_MY_FARM_OFFICE_FAILED: {
            const myFarmersFetchingFailed = true;
            const myFarmersFetching = false;
            return state.merge({ myFarmersFetchingFailed, myFarmersFetching });
            break;
        }

        case FarmRegistarType.GET_ANCHOR_FOR_FARMER_SUCCESS: {
            const getAnchorForFarmerSuccess = true;
            return state.merge({ getAnchorForFarmerSuccess })
        }

        default:
            return state;
    }
}