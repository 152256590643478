/**
 * Created by pajicv on 6/29/18.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import L from 'leaflet';

import { LayersControl, FeatureGroup, Popup, Marker, Tooltip } from 'react-leaflet';

import * as LayerSelectors from '../selectors/LayerSelectors';

import * as LayerActions from '../actions/map/LayerActions';

import './InstitutionalLayers.css';

const { Overlay } = LayersControl;

class InstitutionalLayers extends Component {

    render() {

        const { layers, removeLayer, removeLayerControl, addOverlay  } = this.props;

        if(!layers) return null;

        const renderedLayers = layers.map(layer => {
            return (
                <Overlay checked={ layer.visible }
                         name={layer.name}
                         addOverlay={addOverlay}
                         removeLayer={removeLayer}
                         removeLayerControl={removeLayerControl}>
                    <FeatureGroup visible={layer.visible}>
                        {layer.markers.map(marker => {

                            if(!marker.latitude || !marker.longitude) return;

                            const position = [marker.latitude, marker.longitude];

                            return (
                                <Marker icon={L.icon({iconUrl: layer.icon})} position={position}>
                                    {layer.id !== 'agriUniversities' &&
                                        <Popup>
                                            <div>
                                                <h2>Area of operation</h2>
                                                <p>{marker.area_of_operation}</p>
                                                <h2>Contacts</h2>
                                                <p>{marker.contacts}</p>
                                            </div>
                                        </Popup>
                                    }
                                    <Tooltip  className="institutional-layer-label-tooltip" direction="center" permanent>
                                        <p className="institutional-layer-label-tooltip-text">{marker.name_of_authority}</p>
                                    </Tooltip>
                                </Marker>
                            )
                        })}
                    </FeatureGroup>
                </Overlay>
            )
        });

        return renderedLayers;

    }


}

function mapStateToProps(state) {
    return {
        layers: LayerSelectors.getInstitutionalLayers(state)
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InstitutionalLayers);
