import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n/i18n';
import { Button, TextField, Grid, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { toast } from 'react-toastify';
import Modal from '@material-ui/core/Modal';
import { SketchPicker } from 'react-color';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import 'react-toastify/dist/ReactToastify.css';
import { setInitialState, addField, getFieldDropDown, getTypeProductiondDropDown } from '../../../actions/catastral_data/FieldActions';
import { getCrop, getGroup, getSubgroup } from '../../../actions/resources/CropActions';
import { getFarmersDropdown } from '../../../actions/financial/IncentiveActions';
import { getLayerDropDown } from '../../../actions/catastral_data/LayerActions';
import { getLocalGov } from '../../../actions/FarmRegistarActions';
import { DatePicker } from 'material-ui-pickers';
import { getUser } from '../../../utils/CommonFunctions';
import moment from 'moment';
export class AddField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldObj: this.props.fieldObj,
            displayColorPicker: false,
            openModal: false,
        }
    }

    async componentDidMount() {
        if (this.props.fieldDropDown.length === 0) {
            this.props.getFieldDropDown();
        }
        if (this.props.crops.length === 0) {
            this.props.getCrop();
        }
        if (this.props.cropGroupDropdown.length === 0) {
            this.props.getGroup();
        }
        if (this.props.cropSubgroupDropdown.length === 0) {
            this.props.getSubgroup();
        }
        if (this.props.farmerDropdown.length === 0) {
            this.props.getFarmersDropdown();
        }
        if (this.props.layerDropDown.length === 0) {
            this.props.getLayerDropDown();
        }
        if (this.props.typeProductionDropDown.length === 0) {
            this.props.getTypeProductiondDropDown();
        }
        if (this.props.id_klijent_zakupac) {
            let obj = Object.assign({}, this.state.fieldObj);
            obj['id_klijent_zakupac'] = this.props.id_klijent_zakupac;
            this.setState({ fieldObj: obj });
        }
        const user = await getUser();
        if (user) {
            this.props.getLocalGov(user.id_opstina);

        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.fieldPostingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('fieldPostingFailed');
        }
    }

    addField() {
        if (this.state.fieldObj.naziv &&
            this.state.fieldObj.id_layer &&
            this.state.fieldObj.povrsina) {
            this.props.addField(
                this.state.fieldObj
            )
        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    handleChangeValue = name => event => {
        let obj = Object.assign({}, this.state.fieldObj);
        obj[name] = event.target.value;
        this.setState({ fieldObj: obj });
        if ([name] == 'id_kultura_grupa') {
            this.props.getSubgroup(event.target.value);
        }
        if ([name] == 'id_kultura_podgrupa') {
            this.props.getCrop(event.target.value);
        }
    };

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker, openModal: !this.state.openModal })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false, openModal: false })
    };
    handleChangeComplete = (color) => {
        let obj = Object.assign({}, this.state.fieldObj);
        obj.boja = color.hex;
        this.setState({ fieldObj: obj });
    };
    handleChangeCheckbox = name => event => {
        let obj = Object.assign({}, this.state.fieldObj);
        obj[name] = event.target.checked;
        this.setState({ fieldObj: obj });
    };

    handleDateChange = name => date => {
        let obj = Object.assign({}, this.state.fieldObj);
        obj[name] = moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY');
        this.setState({ fieldObj: obj });
    }

    renderLabel = (date) => {
        if (date.isValid()) {
            return date.format('DD.MM.YYYY');
        } else {
            return '';
        }
    }

    render() {
        let fieldDropDown = [];
        if (this.props.fieldDropDown && !this.props.fieldFetchingDropDown) {
            fieldDropDown = this.props.fieldDropDown.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let crops = [];
        if (this.props.crops && !this.props.cropFetching) {
            crops = this.props.crops.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let group = [];
        if (this.props.cropGroupDropdown && !this.props.groupFetching) {
            group = this.props.cropGroupDropdown.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let subgroup = [];
        if (this.props.cropSubgroupDropdown && !this.props.subgroupFetching) {
            subgroup = this.props.cropSubgroupDropdown.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let farmerDropdown = [];
        if (this.props.farmerDropdown && !this.props.farmerDropdownFetching) {
            farmerDropdown = this.props.farmerDropdown.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.kontakt_osoba}</option>
            });
        }
        let layerDropDown = [];
        if (this.props.layerDropDown && !this.props.layerFetchingDropDown) {
            layerDropDown = this.props.layerDropDown.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let typeProductionDropDown = [];
        if (this.props.typeProductionDropDown && !this.props.typeProductionFetchingDropDown) {
            typeProductionDropDown = this.props.typeProductionDropDown.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }

        let localGov = [];
        if (this.props.localGoverments && !this.props.localGovermentsFetching) {
            localGov = this.props.localGoverments.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }

        let probability = []
        const probabilityArr = [
            { value: true, label: i18n.t('Yes') },
            { value: false, label: i18n.t('No') }
        ]
        probability = probabilityArr.map((s, i) => {
            return <option key={s.id} value={s.value}>{s.label}</option>
        })

        return (
            <div style={{ padding: 20 }}>

                {/* <Paper className="roundedBoxMaterial" zdepth={4}> */}
                <Dialog
                    open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit field') : i18n.t('Add field')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <form id="add-field">
                            <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                                <Grid item >
                                    <TextField
                                        label={i18n.t('Layer')}
                                        required
                                        select
                                        SelectProps={{
                                            native: true,
                                        }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        defaultValue={this.state.fieldObj.id_layer}
                                        onChange={this.handleChangeValue('id_layer')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                        <option value='' />
                                        {layerDropDown}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        required
                                        label={i18n.t('Title')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.state.fieldObj.naziv}
                                        onChange={this.handleChangeValue('naziv')}
                                    />
                                </Grid>
                                <Grid item >
                                    <TextField
                                        label={i18n.t('Main field')}
                                        // required
                                        select
                                        SelectProps={{
                                            native: true,
                                        }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        defaultValue={this.state.fieldObj.id_glavna_tabla}
                                        onChange={this.handleChangeValue('id_glavna_tabla')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                        <option value='' />
                                        {fieldDropDown}
                                    </TextField>
                                </Grid>
                                <Grid item >
                                    <TextField
                                        label={i18n.t('Local goverment')}
                                        select
                                        SelectProps={{
                                            native: true,
                                        }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.state.fieldObj.id_katastarska_opstina}
                                        onChange={this.handleChangeValue('id_katastarska_opstina')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                        <option value='' />
                                        {localGov}
                                    </TextField>
                                </Grid>
                                <Grid item >
                                    <TextField
                                        label={i18n.t('Crop group')}
                                        // required
                                        select
                                        SelectProps={{
                                            native: true,
                                        }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        defaultValue={this.state.fieldObj.id_kultura_grupa}
                                        onChange={this.handleChangeValue('id_kultura_grupa')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                        <option value='' />
                                        {group}
                                    </TextField>
                                </Grid>
                                <Grid item >
                                    <TextField
                                        label={i18n.t('Crop subgroup')}
                                        // required
                                        disabled={!this.state.fieldObj.id_kultura_grupa}
                                        select
                                        SelectProps={{
                                            native: true,
                                        }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        defaultValue={this.state.fieldObj.id_kultura_podgrupa}
                                        onChange={this.handleChangeValue('id_kultura_podgrupa')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                        <option value='' />
                                        {subgroup}
                                    </TextField>
                                </Grid>
                                <Grid item >
                                    <TextField
                                        label={i18n.t('Crop')}
                                        // required
                                        disabled={!this.state.fieldObj.id_kultura_podgrupa}
                                        select
                                        SelectProps={{
                                            native: true,
                                        }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        defaultValue={this.state.fieldObj.id_kultura}
                                        onChange={this.handleChangeValue('id_kultura')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                        <option value='' />
                                        {crops}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        required
                                        label={i18n.t('Land area')}
                                        value={this.state.fieldObj.povrsina}
                                        onChange={this.handleChangeValue('povrsina')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        type="number"
                                    />
                                </Grid>
                                <Grid item >
                                    <TextField
                                        label={i18n.t('Farmer')}
                                        // required
                                        select
                                        SelectProps={{
                                            native: true,
                                        }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        disabled={this.props.id_klijent_zakupac}
                                        defaultValue={this.props.id_klijent_zakupac ? this.props.id_klijent_zakupac : this.state.fieldObj.id_klijent_zakupac}
                                        onChange={this.handleChangeValue('id_klijent_zakupac')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                        <option value='' />
                                        {farmerDropdown}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('The basis of the lease')}
                                        value={this.state.fieldObj.osnov_zakupa}
                                        onChange={this.handleChangeValue('osnov_zakupa')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <DatePicker
                                        keyboard
                                        label={i18n.t('Lease from')}
                                        format="DD.MM.YYYY"
                                        value={this.state.fieldObj.zakup_od ? moment(this.state.fieldObj.zakup_od, 'DD.MM.YYYY') : new Date()}
                                        onChange={this.handleDateChange('zakup_od')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        disableOpenOnEnter
                                        labelFunc={this.renderLabel}
                                        animateYearScrolling={false}
                                    />
                                </Grid>
                                <Grid item>
                                    <DatePicker
                                        keyboard
                                        label={i18n.t('Lease to')}
                                        format="DD.MM.YYYY"
                                        value={this.state.fieldObj.zakup_do ? moment(this.state.fieldObj.zakup_do, 'DD.MM.YYYY') : new Date()}
                                        onChange={this.handleDateChange('zakup_do')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        disableOpenOnEnter
                                        labelFunc={this.renderLabel}
                                        animateYearScrolling={false}
                                    />
                                </Grid>
                                <Grid item>
                                    <DatePicker
                                        keyboard
                                        label={i18n.t('Conversion date')}
                                        format="DD.MM.YYYY"
                                        value={this.state.fieldObj.datum_konverzije ? moment(this.state.fieldObj.datum_konverzije, 'DD.MM.YYYY') : new Date()}
                                        onChange={this.handleDateChange('datum_konverzije')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        disableOpenOnEnter
                                        labelFunc={this.renderLabel}
                                        animateYearScrolling={false}
                                    />
                                </Grid>
                                <Grid item >
                                    <TextField
                                        label={i18n.t('Type of production')}
                                        // required
                                        select
                                        SelectProps={{
                                            native: true,
                                        }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        defaultValue={this.state.fieldObj.id_vrsta_proizvodnje}
                                        onChange={this.handleChangeValue('id_vrsta_proizvodnje')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                        <option value='' />
                                        {typeProductionDropDown}
                                    </TextField>
                                </Grid>
                                <Grid item >
                                    <TextField
                                        label={i18n.t('Color')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px', backgroundColor: this.state.fieldObj.boja && this.state.fieldObj.boja.includes('#') ? `${this.state.fieldObj.boja}` : `#${this.state.fieldObj.boja}` }}
                                        onClick={this.handleClick}
                                        value={this.state.fieldObj.boja}
                                    >
                                    </TextField>
                                    {this.state.displayColorPicker ? <div style={{
                                        position: 'absolute',
                                        zIndex: '2',
                                    }}>
                                        <Modal
                                            aria-labelledby="simple-modal-title"
                                            aria-describedby="simple-modal-description"
                                            open={this.state.openModal}
                                            onClose={this.handleClose}
                                            style={{
                                                position: 'absolute',
                                                left: '50%',
                                                top: '50%',
                                                right: '50%',
                                                bottom: '50%',
                                            }}
                                        >
                                            <SketchPicker color={this.state.fieldObj.boja != null ? this.state.fieldObj.boja : ''} onChange={this.handleChangeComplete} />
                                        </Modal>
                                    </div> : null}
                                {/* </Grid> */}
                                {/* <Grid item xs={12} sm={6} >
                                    <FormControlLabel
                                        label={i18n.t('Seed production')}
                                        style={{ marginTop: '10px', marginBottom: '10px' }}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={this.state.fieldObj.semenska_proizvodnja}
                                                onChange={this.handleChangeCheckbox('semenska_proizvodnja')}
                                                value="semenska_proizvodnja"
                                            />
                                        }
                                    /> */}
                                </Grid>
                                <Grid item >
                                    <FormControlLabel
                                        label={i18n.t('Part of the field')}
                                        style={{ marginTop: '10px', marginBottom: '10px' }}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={this.state.fieldObj.deo_table}
                                                onChange={this.handleChangeCheckbox('deo_table')}
                                                value="deo_table"
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        select
                                        label={i18n.t('Probability Of Flooding')}
                                        required
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        margin="normal"
                                        value={this.state.fieldObj.probability_of_flooding}
                                        onChange={this.handleChangeValue('probability_of_flooding')}
                                        style={{ width: '256px', margin: '10px' }}
                                    >
                                        <option value='' />
                                        {probability}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <FormControlLabel
                                        label={i18n.t('Geometry')}
                                        style={{ marginTop: '10px', marginBottom: '10px' }}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={this.state.fieldObj.geometrija}
                                                onChange={this.handleChangeCheckbox('geometrija')}
                                                value="geometrija"
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Button
                                        style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white' }}
                                        onClick={() => this.addField()}
                                    >
                                        {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
                {/* </Paper> */}
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        fieldPosting: state.fieldReducer.fieldPosting,
        fieldPostingSuccess: state.fieldReducer.fieldPostingSuccess,
        fieldObj: state.fieldReducer.fieldObj,
        fieldPostingFailed: state.fieldReducer.fieldPostingFailed,
        fieldDropDown: state.fieldReducer.fieldDropDown,
        fieldFetchingDropDown: state.fieldReducer.fieldFetchingDropDown,
        fieldFetchingFailedDropDown: state.fieldReducer.fieldFetchingFailedDropDown,
        crops: state.cropReducer.crops,
        cropFetching: state.cropReducer.cropFetching,
        cropFetchingFailed: state.cropReducer.cropFetchingFailed,
        cropGroupDropdown: state.cropReducer.cropGroupDropdown,
        groupFetching: state.cropReducer.groupFetching,
        groupFetchingFailed: state.cropReducer.groupFetchingFailed,
        cropSubgroupDropdown: state.cropReducer.cropSubgroupDropdown,
        subgroupFetching: state.cropReducer.subgroupFetching,
        subgroupFetchingFailed: state.cropReducer.subgroupFetchingFailed,
        farmerDropdown: state.incentiveReducer.farmerDropdown,
        farmerDropdownFetching: state.incentiveReducer.farmerDropdownFetching,
        farmerDropdownFetchingFailed: state.incentiveReducer.farmerDropdownFetchingFailed,
        layerDropDown: state.layerReducer.layerDropDown,
        layerFetchingDropDown: state.layerReducer.layerFetchingDropDown,
        layerFetchingFailedDropDown: state.layerReducer.layerFetchingFailedDropDown,
        typeProductionDropDown: state.fieldReducer.typeProductionDropDown,
        typeProductionFetchingDropDown: state.fieldReducer.typeProductionFetchingDropDown,
        typeProductionFetchingFailedDropDown: state.fieldReducer.typeProductionFetchingFailedDropDown,
        localGoverments: state.farmRegistarReducer.localGoverments,
        localGovermentsFetching: state.farmRegistarReducer.localGovermentsFetching,
        localGovermentsFetchingFailed: state.farmRegistarReducer.localGovermentsFetchingFailed,

    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInitialState: (component) => dispatch(setInitialState(component)),
        addField: (fieldObj) => dispatch(addField(fieldObj)),
        getFieldDropDown: () => dispatch(getFieldDropDown()),
        getCrop: (id) => dispatch(getCrop(id)),
        getGroup: () => dispatch(getGroup()),
        getSubgroup: (id) => dispatch(getSubgroup(id)),
        getFarmersDropdown: () => dispatch(getFarmersDropdown()),
        getLayerDropDown: () => dispatch(getLayerDropDown()),
        getTypeProductiondDropDown: () => dispatch(getTypeProductiondDropDown()),
        getLocalGov: (id_state) => dispatch(getLocalGov(id_state)),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddField)