import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Select from 'react-select';
import { getRegionLayersRead, setRowInReducer, setInitialState } from '../../actions/SuperAdminActions';
import i18n from '../../i18n/i18n';
import immutable from 'seamless-immutable';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import AddRegionLayerGroup from './AddRegionLayerGroup';
const CheckboxTable = checkboxHOC(ReactTable);
export class RegionLayerGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            add: this.props.add,
            edit: this.props.edit,
            showFilters: this.props.showFilters,
            selection: [],
            selectAll: false
        };
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.deleted === true) {
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getRegionLayersRead(wrapped.state);
            this.setState({
                selection: [],
                selectAll: false,
            })
            this.props.setInitialState('deleted');
        }
        if (nextProps.regionLayerGroupPostingSuccess === true) {
            this.setState({
                selection: [],
                name: '',
                selectAll: false
            })
            this.props.handleClose();
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getRegionLayersRead(wrapped.state);
            this.props.setInitialState('regionLayerGroupPostingSuccess');
        }
        this.setState({
            add: nextProps.add,
            showFilters: nextProps.showFilters,
        })
        if (nextProps.add == true && nextProps.edit == false) {
            this.props.setInitialState('regionLayerGroupObj');
            this.setState({
                selection: [],
                name: '',
                selectAll: false
            })
        }
    }

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            selection.push(key);
        }
        this.setState({ selection }, function () {
            if (selection.length == 1) {
                let row1 = this.props.region_layer_grupa.filter((rlg) => {
                    return rlg.id == selection;
                })
                this.props.setRowInReducer(row1[0], 'regionLayerGroupObj');
            }
        });
        this.props.setSelection(selection, 'region_layer_grupa');
    };

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });
        this.props.setSelection(selection, 'region_layer_grupa');

    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    render() {
        let content;
        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "lightgreen" : "inherit"
                        }
                    };
                }
                return {};
            }
        };
        content =
            <div className="page">
                <CheckboxTable data={this.props.region_layer_grupa}
                    columns={this.columns}
                    ref={r => (this.checkboxTable = r)}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    showPaginationTop
                    showPaginationBottom={false}
                    pages={this.props.regionLayersGroupsPages}
                    loading={this.props.regionLayersGroupsFetching}
                    manual
                    onFetchData={(state, instance) => this.props.getRegionLayersRead(state, instance)}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}

                />
                {this.state.add && <AddRegionLayerGroup add={this.state.add} edit={this.props.edit}
                    handleClose={this.props.handleClose} />}
            </div>


        return (
            <div>
                {content}
            </div >
        )
    }

    columns = [
        {
            Header: i18n.t('Title'),
            accessor: 'naziv',
        },
        {
            Header: i18n.t('Region'),
            accessor: 'naziv_regiona',
        },
        {
            Header: i18n.t('Layer group id'),
            accessor: 'id_grupe',
        },
        {
            id: 'vidljivost',
            Header: i18n.t('Visibility'),
            accessor: d => d.vidljivost,
            Cell: ({ value }) => value === true ? i18n.t('Yes') : i18n.t('No')
        },
        {
            Header: i18n.t('Order in tree'),
            accessor: 'order_in_tree'
        }
    ];
};

function mapStateToProps(state) {
    return {
        region_layer_grupa: state.superAdminReducer.region_layer_grupa,
        regionLayersGroupsPages: state.superAdminReducer.regionLayersGroupsPages,
        regionLayersGroupsFetching: state.superAdminReducer.regionLayersGroupsFetching,
        regionLayerGroupPostingSuccess: state.superAdminReducer.regionLayerGroupPostingSuccess,
        deleted: state.superAdminReducer.deleted
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getRegionLayersRead: (state, instance) => dispatch(getRegionLayersRead(state, instance)),
        setRowInReducer: (row, name) => dispatch(setRowInReducer(row, name)),
        setInitialState: (component) => dispatch(setInitialState(component))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegionLayerGroup)