/**
 * Created by pajicv on 12/4/18.
 */
import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import * as LayerActions from '../../../../actions/map/LayerActions';
import DatePicker from '../Node/Tools/DatePicker';
import { getTomorrowDate } from '../../../../utils/constants';
import { Tooltip, IconButton } from "@material-ui/core";
import { getUser } from '../../../../utils/CommonFunctions';

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360
    },
    listItem: {
        paddingLeft: 0,
        paddingRight: 0,
        width: '75%'
    },
    listItemText: {
        padding: 0,
        fontSize: 16,
        overflow: 'hidden'
    },
    htmlTooltip: {
        maxWidth: '600px',
        backgroundColor: 'darkgray'
    },
    collapse: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxWidth: '250px'
    }
});

const iframeStyle = {
    minWidth: '550px',
    minHeight: '500px',
    zIndex: 20000
};

class NodeItem extends React.Component {

    state = {
        open: false
    };

    onLayerVisibilityClick = (e) => {

        const { setLayerVisibility, setBaseLayer, leaf, id, layerGroupId } = this.props;

        if (leaf && layerGroupId === 'baseLayers') {
            setBaseLayer({
                baseLayerId: e.target.value,
                visible: e.target.checked
            });
            return;
        }

        setLayerVisibility({
            leaf,
            id,
            visible: e.target.checked
        });

    };

    handleExpandClick = () => this.setState(state => ({ open: !state.open }));

    handleDateChange = (e) => {
        const { id, setDate } = this.props;
        setDate(id, e.target.value);
    };

    renderVisibilityControls = () => {

        const { id, isChecked, leaf, layerGroupId } = this.props;

        if (id === 'baseLayers') {
            return <Radio
                checked={isChecked}
                onChange={this.onLayerVisibilityClick}
                value={id}
                style={{ opacity: 0 }}
            />;
        }

        if (leaf && layerGroupId === 'baseLayers') {
            return <Radio
                checked={isChecked}
                onChange={this.onLayerVisibilityClick}
                value={id}
            />;
        }

        return <Checkbox
            checked={isChecked}
            onChange={this.onLayerVisibilityClick}
            value={id}
        />

    };

    renderChildNodes = () => {

        const { leaf, date, name, childNodes, depth } = this.props;

        if (leaf) {
            if (date) {
                let tomorrow = getTomorrowDate();
                tomorrow = tomorrow.toISOString().substr(0, 10);
                return <DatePicker date={date} maxDate={tomorrow} onDateChange={this.handleDateChange} />
            }
            return null;
        }

        return childNodes.map(node => {
            return (
                <ConnectedNode
                    {...node}
                    key={node.id}
                    depth={depth + 1}
                    isCollapsed={true}
                />
            )
        });
    };

    render() {
        let user = getUser();
        const { classes, id, icon, name, isChecked, depth, leaf, childNodes, layerGroupId } = this.props;

        return (
            <List
                component="nav"
                className={classes.root}
                disablePadding
                style={{ marginLeft: depth * 10 }}
            >
                <div style={{ display: 'flex' }}>
                    {this.renderVisibilityControls()}
                    <ListItem
                        button
                        onClick={this.handleExpandClick}
                        className={classes.listItem}
                    >
                        <ListItemIcon>
                            {icon && <img
                                style={{ verticalAlign: 'middle' }}
                                src={icon}
                                width={32}
                                height={32}
                            />
                            }
                        </ListItemIcon>
                        <ListItemText className={classes.listItemText} primary={name} />

                        {this.state.open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                </div>
                <Collapse in={this.state.open} timeout="auto" unmountOnExit classes={layerGroupId === 'satelliteImageryLayers' && { wrapperInner: classes.collapse }}>
                    {this.renderChildNodes()}
                    {
                        layerGroupId === 'satelliteImageryLayers'
                        && <Tooltip
                            classes={{
                                tooltip: classes.htmlTooltip
                            }}
                            interactive
                            title={<iframe style={iframeStyle} src={`https://manuals.agrolife.world/sentinel/${id}${user.id_jezik === 1 ? '_rs' : ''}`} />}
                            placement={'left-end'}
                        >
                            <IconButton color="secondary">
                                <span className="icon-info layerIcon" />
                            </IconButton>
                        </Tooltip>
                    }
                </Collapse>
            </List>
        )

    }

}



const ConnectedNode = withStyles(styles)(connect(
    state => { },
    dispatch => ({
        setLayerVisibility: ({ id, leaf, visible }) => dispatch(LayerActions.setLayerVisibility({ id, leaf, visible })),
        setBaseLayer: payload => dispatch(LayerActions.setBaseLayer(payload)),
        setDate: (layerId, date) => dispatch(LayerActions.setSatelliteImageryDate(layerId, date))
    })
) (NodeItem));

export default ConnectedNode;