import React from 'react';
import { connect } from 'react-redux';
import { withStyles, TextField } from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment';
import immutable from 'seamless-immutable';
import ReactSelect from '../../utils/ReactSelect';
import i18n from '../../i18n/i18n';
import { getBasisOfLeaseDropDown } from '../../actions/auction/PublicProcurementActions';
import { getSeasonDropDown } from '../../actions/resources/SeasonsActions';
import { getLocalGov } from '../../actions/FarmRegistarActions';

const styles = theme => ({
    innerContainer: {
        textAlign: 'center',
        display: 'flex',
        margin: '20px 10px',
        flexFlow: 'row-wrap',
        alignItems: 'center',
        justifyContent: 'space-around'
    }
});
export class PublicProcurementInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            publicProcurementObj: {},
            basisOfLeaseDropDown: [],
            seasonDropDown: []
        }
    }

    componentDidMount() {
        const { clientProfile } = this.props;
        this.props.getBasisOfLeaseDropDown();
        this.props.getSeasonDropDown();
        this.props.getLocalGov(clientProfile.id_opstina);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.seasonDropDown !== this.props.seasonDropDown) {
            this.setState({
                seasonDropDown: immutable.asMutable(this.props.seasonDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            })
        }
        if (prevProps.basisOfLeaseDropDown !== this.props.basisOfLeaseDropDown) {
            this.setState({
                basisOfLeaseDropDown: immutable.asMutable(this.props.basisOfLeaseDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            })
        }
        if (this.props.publicProcurementObj && prevState.publicProcurementObj !== this.props.publicProcurementObj) {
            this.setState({
                publicProcurementObj: this.props.publicProcurementObj
            })
        }
        if (prevProps.localGoverments !== this.props.localGoverments) {
            this.setState({
                localGoverments: immutable.asMutable(this.props.localGoverments.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            })
        }
    }

    handleChangeValue = name => selected => {
        const { publicProcurementObj } = this.state;
        const { handleChangeProperty } = this.props
        publicProcurementObj[name] = selected && selected.value;
        this.setState({
            publicProcurementObj: publicProcurementObj
        }, function () {
            handleChangeProperty(name, selected);
        })
    }

    handleChangeStringValue = name => event => {
        const { publicProcurementObj } = this.state;
        const { handleChangeProperty } = this.props;
        publicProcurementObj[name] = event.target.value;
        this.setState({
            publicProcurementObj: publicProcurementObj
        }, function () {
            handleChangeProperty(name, this.state.publicProcurementObj[name]);
        })
    }

    handleChangeDateValue = name => date => {
        const { publicProcurementObj } = this.state;
        const { handleChangeProperty } = this.props
        publicProcurementObj[name] = moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY');
        this.setState({ publicProcurementObj: publicProcurementObj }, function () {
            handleChangeProperty(name, this.state.publicProcurementObj[name])
        });
    }

    renderLabel = (date) => {
        if (date.isValid()) {
            return date.format('DD.MM.YYYY');
        } else {
            return '';
        }
    }

    render() {
        const { classes, clientProfile } = this.props;
        const { publicProcurementObj } = this.state;
        return (
            <div className={classes.innerContainer}>
                <TextField
                    required
                    label={i18n.t('Public bidding code')}
                    value={publicProcurementObj.sifra}
                    onChange={this.handleChangeStringValue('sifra')}
                    className="multi-select-in-filter-dashboards"
                />
                <ReactSelect
                    closeMenuOnSelect={true}
                    isClearable={true}
                    maxMenuHeight={150}
                    value={this.state.seasonDropDown.find(function (element) {
                        return element.value === publicProcurementObj.id_sezona;
                    })}
                    label={i18n.t('Season') + '*'}
                    onChange={this.handleChangeValue('id_sezona')}
                    options={this.state.seasonDropDown}
                    autoSize={true}
                    className="multi-select-in-filter-dashboards"
                    classNamePrefix="select"
                    placeholder={i18n.t('Select')}
                />
                <DatePicker
                    keyboard
                    label={i18n.t('Date *')}
                    format="DD.MM.YYYY"
                    value={moment(publicProcurementObj.datum, 'DD.MM.YYYY')}
                    onChange={this.handleChangeDateValue('datum')}
                    disableOpenOnEnter
                    labelFunc={this.renderLabel}
                    className="multi-select-in-filter-dashboards"
                />
                <ReactSelect
                    closeMenuOnSelect={true}
                    isClearable={true}
                    label={i18n.t('Local government') + '*'}
                    maxMenuHeight={150}
                    value={this.state.localGoverments && this.state.localGoverments.find(function (element) {
                        return element.value === publicProcurementObj.id_katastarska_opstina
                    })}
                    menuPlacement='auto'
                    onChange={this.handleChangeValue('id_katastarska_opstina')}
                    options={this.state.localGoverments}
                    className="multi-select-in-filter-dashboards"
                    classNamePrefix="select"
                    placeholder={i18n.t('Select')}
                />
                <ReactSelect
                    closeMenuOnSelect={true}
                    isClearable={true}
                    label={i18n.t('The basis of the lease') + '*'}
                    maxMenuHeight={150}
                    value={this.state.basisOfLeaseDropDown.find(function (element) {
                        return element.value === publicProcurementObj.id_osnov_zakupa;
                    })}
                    menuPlacement='auto'
                    onChange={this.handleChangeValue('id_osnov_zakupa')}
                    options={this.state.basisOfLeaseDropDown}
                    className="multi-select-in-filter-dashboards"
                    classNamePrefix="select"
                    placeholder={i18n.t('Select')}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        basisOfLeaseDropDown: state.publicProcurementReducer.basisOfLeaseDropDown,
        seasonDropDown: state.seasonReducer.seasonDropDown,
        localGoverments: state.farmRegistarReducer.localGoverments,
        localGovermentsFetching: state.farmRegistarReducer.localGovermentsFetching,
        localGovermentsFetchingFailed: state.farmRegistarReducer.localGovermentsFetchingFailed,
        clientProfile: state.appReducer.clientProfile
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getSeasonDropDown: () => dispatch(getSeasonDropDown()),
        getBasisOfLeaseDropDown: () => dispatch(getBasisOfLeaseDropDown()),
        getLocalGov: (id_state) => dispatch(getLocalGov(id_state))
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(PublicProcurementInfo))