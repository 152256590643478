import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import i18n from '../../i18n/i18n';
import Select from 'react-select';
import { wentWrong } from "../../utils/ToastConfig";
import axios from '../../utils/AxiosWrapper';
import checkboxHOC from "react-table/lib/hoc/selectTable";


export default class NaledReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            zakSrb: {},
            zakRs: {},
            zakCg: {},
            nezSrb: {},
            nezRs: {},
            nezCg: {},
            nezCg: {},
            nezCg: {},
            nezCg: {},
            tablezSrb: {},
            tablezRs: {},
            tablezCg: {},
            tablenSrb: {},
            tablenRs: {},
            tablenCg: {},
            radnizSrb: {},
            radnizRs: {},
            radnizCg: {},
            radninSrb: {},
            radninRs: {},
            radninCg: {},
            jedzSrb: {},
            jedzRs: {},
            jedzCg: {},
            jednSrb: {},
            jednRs: {},
            jednCg: {},
            imaKulturu: {},
            zSveTableSrb: {},
            zSveTableRs: {},
            zSveTableCg: {},
            nSveTableSrb: {},
            nSveTableRs: {},
            nSveTableCg: {},
            zImaKulSrb: {},
            zImaKulRs: {},
            zImaKulCg: {},
            nImaKulSrb: {},
            nImaKulRs: {},
            nImaKulCg: {},
            sot: {},
            sut: {},
            btt: {},
            vst: {},
            int: {},
            prt: {},
            trt: {},
            brt: {},
            srt: {},
            nit: {},
            ult: {},
            sor: {},
            sur: {},
            btr: {},
            vsr: {},
            inr: {},
            prr: {},
            trr: {},
            brr: {},
            srr: {},
            nir: {},
            ulr: {},
            sok: {},
            suk: {},
            btk: {},
            vsk: {},
            ink: {},
            prk: {},
            trk: {},
            brk: {},
            srk: {},
            nik: {},
            ulk: {},



        };
    }

    componentDidMount() {
        this.getByURL('naledreports/zakupciSrb', 'zakSrb');
        this.getByURL('naledreports/zakupciRs', 'zakRs');
        this.getByURL('naledreports/zakupciCg', 'zakCg');
        this.getByURL('naledreports/nezakupciSrb', 'nezSrb');
        this.getByURL('naledreports/nezakupciRs', 'nezRs');
        this.getByURL('naledreports/nezakupciCg', 'nezCg');

        this.getByURL('naledreports/zSveTableSrb', 'zSveTableSrb');
        this.getByURL('naledreports/zSveTableRs', 'zSveTableRs');
        this.getByURL('naledreports/zSveTableCg', 'zSveTableCg');
        this.getByURL('naledreports/nSveTableSrb', 'nSveTableSrb');
        this.getByURL('naledreports/nSveTableRs', 'nSveTableRs');
        this.getByURL('naledreports/nSveTableCg', 'nSveTableCg');

        this.getByURL('naledreports/zImaKulSrb', 'zImaKulSrb');
        this.getByURL('naledreports/zImaKulRs', 'zImaKulRs');
        this.getByURL('naledreports/zImaKulCg', 'zImaKulCg');
        this.getByURL('naledreports/nImaKulSrb', 'nImaKulSrb');
        this.getByURL('naledreports/nImaKulRs', 'nImaKulRs');
        this.getByURL('naledreports/nImaKulCg', 'nImaKulCg');


        // this.getByURL('naledreports/tablezSrb', 'tablezSrb');
        // this.getByURL('naledreports/tablezRs', 'tablezRs');
        // this.getByURL('naledreports/tablezCg', 'tablezCg');
        // this.getByURL('naledreports/tablenSrb', 'tablenSrb');
        // this.getByURL('naledreports/tablenRs', 'tablenRs');
        // this.getByURL('naledreports/tablenCg', 'tablenCg');

        this.getByURL('naledreports/radnizSrb', 'radnizSrb');
        this.getByURL('naledreports/radnizRs', 'radnizRs');
        this.getByURL('naledreports/radnizCg', 'radnizCg');
        this.getByURL('naledreports/radninSrb', 'radninSrb');
        this.getByURL('naledreports/radninRs', 'radninRs');
        this.getByURL('naledreports/radninCg', 'radninCg');

        this.getByURL('naledreports/jedzSrb', 'jedzSrb');
        this.getByURL('naledreports/jedzRs', 'jedzRs');
        this.getByURL('naledreports/jedzCg', 'jedzCg');
        this.getByURL('naledreports/jednSrb', 'jednSrb');
        this.getByURL('naledreports/jednRs', 'jednRs');
        this.getByURL('naledreports/jednCg', 'jednCg');

        this.getByURLzak('naledreports/ot', 'sot', 19);
        this.getByURLzak('naledreports/ot', 'sut', 39);
        this.getByURLzak('naledreports/ot', 'btt', 22);
        this.getByURLzak('naledreports/ot', 'vst', 166);
        this.getByURLzak('naledreports/ot', 'int', 82);

        this.getByURLzak('naledreports/ot', 'prt', 879);
        this.getByURLzak('naledreports/ot', 'trt', 911);
        this.getByURLzak('naledreports/ot', 'brt', 890);
        this.getByURLzak('naledreports/ot', 'srt', 873);

        this.getByURLzak('naledreports/ot', 'nit', 936);
        this.getByURLzak('naledreports/ot', 'ult', 943);


        this.getByURLzakr('naledreports/or', 'sor', 19);
        this.getByURLzakr('naledreports/or', 'sur', 39);
        this.getByURLzakr('naledreports/or', 'btr', 22);
        this.getByURLzakr('naledreports/or', 'vsr', 166);
        this.getByURLzakr('naledreports/or', 'inr', 82);

        this.getByURLzakr('naledreports/or', 'prr', 879);
        this.getByURLzakr('naledreports/or', 'trr', 911);
        this.getByURLzakr('naledreports/or', 'brr', 890);
        this.getByURLzakr('naledreports/or', 'srr', 873);

        this.getByURLzakr('naledreports/or', 'nir', 936);
        this.getByURLzakr('naledreports/or', 'ulr', 943);

        this.getByURLzakr('naledreports/ok', 'sok', 19);
        this.getByURLzakr('naledreports/ok', 'suk', 39);
        this.getByURLzakr('naledreports/ok', 'btk', 22);
        this.getByURLzakr('naledreports/ok', 'vsk', 166);
        this.getByURLzakr('naledreports/ok', 'ink', 82);

        this.getByURLzakr('naledreports/ok', 'prk', 879);
        this.getByURLzakr('naledreports/ok', 'trk', 911);
        this.getByURLzakr('naledreports/ok', 'brk', 890);
        this.getByURLzakr('naledreports/ok', 'srk', 873);

        this.getByURLzakr('naledreports/ok', 'nik', 936);
        this.getByURLzakr('naledreports/ok', 'ulk', 943);



        // this.getByURL('naledreports/imaKulturu', 'imaKulturu');

    }

    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: parseInt(res.data.data.count, 10) }))
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    }
    getByURLzak(url, obj, num) {
        axios.get(`api/${url}`, { params: { num: num } })
            .then(res => this.setState({ [obj]: parseInt(res.data.data.count, 10) }))
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    }
    getByURLzakr(url, obj, num) {
        axios.get(`api/${url}`, { params: { num: num } })
            .then(res => this.setState({ [obj]: parseInt(res.data.data.count, 10) }))
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    }

    // getByURLtable(url, obj, num) {
    //     axios.get(`api/${url}`)
    //         .then(res => this.setState({ [obj]: res.data.data }))
    //         .catch(error => {
    //             wentWrong('Something went wrong, please try again!');
    //             console.log('Error 2 (getByURL): ', error, ', URL: ', url);
    //         })
    // }


    render() {
        let zKorisnici = this.state.zakSrb + this.state.zakRs + this.state.zakCg
        let nKorisnici = this.state.nezSrb + this.state.nezRs + this.state.nezCg
        let uKorisnici = zKorisnici + nKorisnici

        let zSveTable = this.state.zSveTableSrb + this.state.zSveTableRs + this.state.zSveTableCg
        let nSveTable = this.state.nSveTableSrb + this.state.nSveTableRs + this.state.nSveTableCg
        let uSveTable = zSveTable + nSveTable

        let zKul = this.state.zImaKulSrb + this.state.zImaKulRs + this.state.zImaKulCg
        let nKul = this.state.nImaKulSrb + this.state.nImaKulRs + this.state.nImaKulCg
        let uKul = zKul + nKul

        // let zTable = this.state.tablezSrb + this.state.tablezRs + this.state.tablezCg - 1690 - 1624 - 74
        // let nTable = this.state.tablenSrb + this.state.tablenRs + this.state.tablenCg
        // let uTable = zTable + nTable

        let zRadni = this.state.radnizSrb + this.state.radnizRs + this.state.radnizCg
        let nRadni = this.state.radninSrb + this.state.radninRs + this.state.radninCg
        let uRadni = zRadni + nRadni

        let zJed = this.state.jedzSrb + this.state.jedzRs + this.state.jedzCg
        let nJed = this.state.jednSrb + this.state.jednRs + this.state.jednCg
        let uJed = zJed + nJed

        const mystyle = {
            padding: "10px"
        };
        return (
            <div className="page" >
                <table border="3px" solid black="border-collapse: collapse" style={mystyle} width="70%">
                    <tbody font-size="20px">
                        <tr>
                            {/* <td width="8%">Podaci od 1.2.2021</td> */}
                            <td width="8%">Podaci o projektu</td>
                            <td colSpan="3" width="13%">Broj registrovnih korisnika</td>
                            <td colSpan="3" width="13%">Broj tabli</td>
                            <td colSpan="3" width="13%">Broj tabli koje imaju izabranu kulturu</td>
                            <td colSpan="3" width="13%">Broj unetih radnih naloga</td>
                            <td colSpan="3" width="13%">Različitih korisnici koji su uneli radne naloge</td>

                        </tr><tr>
                            <td>Region</td>
                            <td>Zakupci</td>
                            <td> Ostali </td>
                            <td>Ukupno</td>
                            <td>Zakupci</td>
                            <td> Ostali </td>
                            <td>Ukupno</td>
                            <td>Zakupci</td>
                            <td> Ostali </td>
                            <td>Ukupno</td>
                            <td>Zakupci</td>
                            <td> Ostali </td>
                            <td>Ukupno</td>
                            <td>Zakupci</td>
                            <td> Ostali </td>
                            <td>Ukupno</td>
                        </tr>
                        <tr>
                            <td>Srbija</td>
                            <td>{this.state.zakSrb.toString()}</td>
                            <td>{this.state.nezSrb.toString()}</td>
                            <td>{this.state.zakSrb + this.state.nezSrb}</td>
                            <td>{this.state.zSveTableSrb.toString()}</td>
                            <td>{this.state.nSveTableSrb.toString()}</td>
                            <td>{this.state.zSveTableSrb + this.state.nSveTableSrb}</td>
                            <td>{this.state.zImaKulSrb.toString()}</td>
                            <td>{this.state.nImaKulSrb.toString()}</td>
                            <td>{this.state.zImaKulSrb + this.state.nImaKulSrb}</td>
                            <td>{this.state.radnizSrb.toString()}</td>
                            <td>{this.state.radninSrb.toString()}</td>
                            <td >{this.state.radnizSrb + this.state.radninSrb}</td>
                            <td>{this.state.jedzSrb.toString()}</td>
                            <td>{this.state.jednSrb.toString()}</td>
                            <td>{this.state.jedzSrb + this.state.jednSrb}</td>
                        </tr> <tr>
                            <td>Bosna i Hercegovina</td>
                            <td>{this.state.zakRs.toString()}</td>
                            <td>{this.state.nezRs.toString()}</td>
                            <td>{this.state.zakRs + this.state.nezRs}</td>
                            <td>{this.state.zSveTableRs.toString()}</td>
                            <td>{this.state.nSveTableRs.toString()}</td>
                            <td>{this.state.zSveTableRs + this.state.nSveTableRs}</td>
                            <td>{this.state.zImaKulRs.toString()}</td>
                            <td>{this.state.nImaKulRs.toString()}</td>
                            <td>{this.state.zImaKulRs + this.state.nImaKulRs}</td>
                            <td>{this.state.radnizRs.toString()}</td>
                            <td>{this.state.radninRs.toString()}</td>
                            <td>{this.state.radnizRs + this.state.radninRs}</td>
                            <td>{this.state.jedzRs.toString()}</td>
                            <td>{this.state.jednRs.toString()} </td>
                            <td>{this.state.jedzRs + this.state.jednRs}</td>
                        </tr> <tr>
                            <td>Crna gora</td>
                            <td>{this.state.zakCg.toString()}</td>
                            <td>{this.state.nezCg.toString()}</td>
                            <td>{this.state.zakCg + this.state.nezCg}</td>
                            <td>{this.state.zSveTableCg.toString()}</td>
                            <td>{this.state.nSveTableCg.toString()}</td>
                            <td>{this.state.zSveTableCg + this.state.nSveTableCg}</td>
                            <td>{this.state.zImaKulCg.toString()}</td>
                            <td>{this.state.nImaKulCg.toString()}</td>
                            <td>{this.state.zImaKulCg + this.state.nImaKulCg}</td>
                            <td>{this.state.radnizCg.toString()}</td>
                            <td>{this.state.radninCg.toString()}</td>
                            <td>{this.state.radnizCg + this.state.radninCg}</td>
                            <td>{this.state.jedzCg.toString()}</td>
                            <td>{this.state.jednCg.toString()} </td>
                            <td>{this.state.jedzCg + this.state.jednCg}</td>
                        </tr> <tr>
                            <td></td>
                            <td>{zKorisnici}</td>
                            <td>{nKorisnici}</td>
                            <td bgcolor={'yellow'}>{uKorisnici}</td>
                            <td>{zSveTable}</td>
                            <td>{nSveTable}</td>
                            <td bgcolor={'yellow'}>{uSveTable}</td>
                            <td>{zKul}</td>
                            <td>{nKul}</td>
                            <td bgcolor={'yellow'}>{uKul}</td>
                            <td>{zRadni}</td>
                            <td>{nRadni}</td>
                            <td bgcolor={'yellow'}>{uRadni}</td>
                            <td>{zJed}</td>
                            <td>{nJed} </td>
                            <td bgcolor={'yellow'}>{uJed}</td>
                        </tr>
                    </tbody>
                </table >

                <table border="3px" solid black="border-collapse: collapse" style={mystyle} width="35%">
                    <tbody font-size="20px">
                        <tr>
                            <td></td>
                            <td>Broj unetih tabli</td>
                            <td>Broj unetih radnih naloga</td>
                            <td>Broj tabli koje imaju izabranu kulturu</td>
                        </tr>
                        <tr>
                            <td colSpan='4' align='center'>Srbija</td>
                        </tr>
                        <tr>
                            <td>Sombor</td>
                            <td>{this.state.sot.toString()}</td>
                            <td>{this.state.sor.toString()}</td>
                            <td>{this.state.sok.toString()}</td>

                        </tr>
                        <tr>
                            <td>Subotica</td>
                            <td>{this.state.sut.toString()}</td>
                            <td>{this.state.sur.toString()}</td>
                            <td>{this.state.suk.toString()}</td>

                        </tr>
                        <tr>
                            <td>B. Topola</td>
                            <td>{this.state.btt.toString()}</td>
                            <td>{this.state.btr.toString()}</td>
                            <td>{this.state.btk.toString()}</td>

                        </tr>
                        <tr>
                            <td>Vrbas</td>
                            <td>{this.state.vst.toString()}</td>
                            <td>{this.state.vsr.toString()}</td>
                            <td>{this.state.vsk.toString()}</td>

                        </tr>
                        <tr>
                            <td>Indjija</td>
                            <td>{this.state.int.toString()}</td>
                            <td>{this.state.inr.toString()}</td>
                            <td>{this.state.ink.toString()}</td>

                        </tr>
                        <tr>
                            <td colSpan='4' align='center'>Bosna i Hercegovina</td>
                        </tr>
                        <tr>
                            <td>Prijedor</td>
                            <td>{this.state.prt.toString()}</td>
                            <td>{this.state.prr.toString()}</td>
                            <td>{this.state.prk.toString()}</td>

                        </tr>
                        <tr>
                            <td>Trebinje</td>
                            <td>{this.state.trt.toString()}</td>
                            <td>{this.state.trr.toString()}</td>
                            <td>{this.state.trk.toString()}</td>

                        </tr>
                        <tr>
                            <td>Brod</td>
                            <td>{this.state.brt.toString()}</td>
                            <td>{this.state.brr.toString()}</td>
                            <td>{this.state.brk.toString()}</td>

                        </tr>
                        <tr>
                            <td>Srbac</td>
                            <td>{this.state.srt.toString()}</td>
                            <td>{this.state.srr.toString()}</td>
                            <td>{this.state.srk.toString()}</td>

                        </tr>
                        <tr>
                            <td colSpan='4' align='center'>Crna Gora</td>
                        </tr>
                        <tr>
                            <td>Niksic</td>
                            <td>{this.state.nit.toString()}</td>
                            <td>{this.state.nir.toString()}</td>
                            <td>{this.state.nik.toString()}</td>

                        </tr>
                        <tr>
                            <td>Ulcinj</td>
                            <td>{this.state.ult.toString()}</td>
                            <td>{this.state.ulr.toString()}</td>
                            <td>{this.state.ulk.toString()}</td>

                        </tr>
                    </tbody>
                </table>
            </div >
        )
    }
};

function FilterSelect(props) {
    return <Select
        isMulti
        name="naziv"
        onChange={value => { props.onChange(value) }}
        closeMenuOnSelect={false}
        options={props.type}
        className="multi-select-in-filter"
        classNamePrefix="select"
        placeholder={i18n.t('Select')}
    />
}