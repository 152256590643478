import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Card } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './ImageryStyles';
import ndvi from './../satelliteinfo/ndvi.png';

export class NDVI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.props.language === 1 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                NDVI (Indeks normalizovane razlike vegetacije) je jednostavan grafički indikator koji pokazuje da li na
                                posmatranoj površini postoji vegetacija i u kom je stanju. NDVI je pokazatelj intenziteta fotosinteze. Kada
                                je zemljište golo ili su usevi tek nikli, NDVI je blizak nuli. Međutim, kako biljke formiraju više listova i
                                spajaju redove, povećava se intenzitet fotosinteze i NDVI raste. Veći NDVI na početku sezone može
                                označavati brži rast useva, ali i pojavu korova, dok manji NDVI u kasnijim fazama razvoja biljaka može
                                označavati manjak vode, nutritijenata ili pojavu bolesti.
                            </p>
                            <p className={classes.paragraph}>
                                Vrednosti NDVI su u opsegu od -1 do +1. Gusta vegetacija obično ima vrednosti od 0,3 do 0,8. Oblaci i
                                snežni pokrivač imaju negativne vrednosti NDVI. Vodene površine (okeani, mora, jezera i reke) imaju
                                niske refleksije u oba spektralna opsega tako da NDVI za vodene površine ima veoma nisku pozitivnu
                                vrednost ili čak i negativnu. Golo zemljište ima malo veću refleksiju u blisko-infracrvenom delu tako da je
                                vrednost NDVI pozitivna od 0,1 do 0,2.
                            </p>
                            <div className={classes.image}>
                                <img src={ndvi} />
                            </div>
                            <p className={classes.paragraph}>
                                Naziva se i „indeks zelenila“, koristan za utvrđivanje produktivnosti vegetacije, jer hlorofil snažno
                                apsorbuje svetlost u crvenom delu spektra, dok se struktura lisnih ćelija snažno reflektuje u
                                blisko-infracrvenom delu spektra. Intenzitet fotosinteze je u pozitivnoj korealciji sa količinom
                                fotosintetski aktivnog zračenja koje biljka apsorbuje. Ispitivanjem NDVI i drugih sličnih indeksa
                                poljoprivrednici su u stanju da identifikuju varijabilnost useva i da prilagode primene dohrane, kao što su
                                đubrivo i pesticidi, koristeći varijabilne načine aplikacija. Međutim, uvek se savetuje kombinovanje NDVI
                                indeksa sa drugim parametrima za postizanje boljih rezultata.
                            </p>
                        </div>
                    </Card>
                }
                {this.props.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                NDVI (Normalized Difference Vegetation Index) - This most known and used vegetation index is a
                                simple, but effective VI for quantifying green vegetation. It normalizes green leaf scattering in the Near
                                Infra-red wavelength and chlorophyll absorption in the red wavelength.
                            </p>
                            <p className={classes.paragraph}>
                                Values description: The value range of an NDVI is -1 to 1. Negative values of NDVI (values approaching -1)
                                correspond to water. Values close to zero (-0.1 to 0.1) generally correspond to barren areas of rock, sand,
                                or snow. Low, positive values represent shrub and grassland (approximately 0.2 to 0.4), while high values
                                indicate temperate and tropical rainforests (values approaching 1).
                            </p>
                            <div className={classes.image}>
                                <img src={ndvi} />
                            </div>
                        </div>
                    </Card>
                }
                {this.props.language === 4 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                NDVI (Normalized Difference Vegetation Index) je jednostavan grafički indikator koji pokazuje 
                                ima li vegetacije na promatranoj površini i u kakvom je stanju. NDVI je pokazatelj intenziteta 
                                fotosinteze. Kada je tlo golo ili su usjevi tek iznikli, NDVI je blizu nule. Međutim, 
                                kako biljke formiraju više lišća i spajaju redove, povećava se intenzitet fotosinteze i 
                                povećava se NDVI. Viši NDVI na početku sezone može ukazivati na brži rast usjeva, ali i pojavu 
                                korova, dok niži NDVI u kasnijim fazama razvoja biljke može ukazivati na nedostatak vode, 
                                hranjiva ili pojavu bolesti.
                            </p>
                            <p className={classes.paragraph}>
                                NDVI vrijednosti se kreću od -1 do +1. Gusta vegetacija obično ima vrijednosti od 0,3 do 0,8. 
                                Oblaci i snježni pokrivač imaju negativne NDVI vrijednosti. Vodene površine (oceani, mora, jezera i rijeke)
                                imaju nisku refleksiju u oba spektralna pojasa tako da NDVI za vodene površine ima vrlo nisku 
                                pozitivnu ili čak negativnu vrijednost. Golo tlo ima nešto veću refleksiju u bliskom infracrvenom 
                                dijelu tako da je NDVI vrijednost pozitivna od 0,1 do 0,2.
                            </p>
                            <div className={classes.image}>
                                <img src={ndvi} />
                            </div>
                            <p className={classes.paragraph}>
                                Također se naziva i "indeks zelenila", koristan za određivanje produktivnosti vegetacije, 
                                jer klorofil snažno apsorbira svjetlost u crvenom dijelu spektra, dok se struktura stanica 
                                lista snažno reflektira u bliskom infracrvenom dijelu spektra. Intenzitet fotosinteze u 
                                pozitivnoj je korelaciji s količinom fotosintetski aktivnog zračenja koje biljka apsorbira. 
                                Ispitivanjem NDVI i drugih sličnih indeksa farmeri mogu identificirati stanje usjeva i primenit 
                                varijabilne količine gnojiva za ishranu bilja. Međutim, uvijek je preporučljivo kombinirati 
                                NDVI indeks s drugim parametrima kako bi se postigli bolji rezultati.
                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    }
};

export default withStyles(styles)(NDVI);