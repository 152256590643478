import React from 'react';
import { connect } from 'react-redux';
import {
    Button, IconButton, Menu, MenuItem
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { Doughnut, Bar, Pie, HorizontalBar } from 'react-chartjs-2';
import immutable from 'seamless-immutable';
import Select, { components } from 'react-select';
import InputLabel from '@material-ui/core/InputLabel';
import { getGroup } from '../../../actions/resources/CropActions';
import 'react-toastify/dist/ReactToastify.css';
import i18n from '../../../i18n/i18n';
import randomColor from 'randomcolor';
import { getSeedingDashboard } from '../../../actions/reports/ReportsActions';
import DashboardDrawer from '../../../utils/DashboardDrawer';

const IndicatorsContainer = (props) => {
    return (
        <div style={{ background: '#61a50e' }}>
            <components.IndicatorsContainer {...props} />
        </div>
    );
};
export class SeedingDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            id_kultura_grupa: []
        };
    }

    componentDidMount() {
        this.props.getSeedingDashboard({});
        if (this.props.cropGroupDropdown.length === 0) {
            this.props.getGroup();
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            cropGroupDropdown: immutable.asMutable(nextProps.cropGroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
        })
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleClear = () => {
        this.setState({
            id_kultura_grupa: []
        }, function () {
            this.props.getSeedingDashboard({});
        });
    };


    handleOk = () => {
        let filters = {};
        filters['id_kultura_grupa'] = this.state.id_kultura_grupa.map((item) => {
            return item.value;
        })
        this.props.getSeedingDashboard(filters);
    }

    handleChange = name => selectedOption => {
        this.setState({
            [name]: selectedOption
        })
    }


    onPieClick = () => {
        this.props.history.push('/seeding_dashboard');
    }

    onTableClick = () => {
        this.props.history.push('/seeding_statistics');
    }



    render() {
        const { anchorEl } = this.state;
        let cropGroupData = this.cropGroup;
        let seasonData = this.layer;


        if (this.props.seedingItems && this.props.seedingItems.cropGroup) {
            cropGroupData.labels = this.props.seedingItems.cropGroup.labels.asMutable();
            cropGroupData.datasets[0].data = this.props.seedingItems.cropGroup.count.asMutable();
            cropGroupData.datasets[0].data1 = this.props.seedingItems.cropGroup.povrsina.asMutable();
        }
        if (this.props.seedingItems && this.props.seedingItems.layers) {
            seasonData.labels = this.props.seedingItems.layers.labels.asMutable();
            seasonData.datasets[0].data = this.props.seedingItems.layers.count.asMutable();
            seasonData.datasets[0].data1 = this.props.seedingItems.layers.percent.asMutable();
        }



        return (
            <div style={{ 'padding': '20px' }}>

                <Card style={styles.card}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <h1>{i18n.t('Total number of seeding reports')} : {this.props.seedingItems.total} </h1>
                        <div className="filter-panel-dashboard">
                            <IconButton
                                styles={styles.button}
                                aria-owns={anchorEl ? 'simple-menu' : null}
                                aria-haspopup="true"
                                className="action-button-filter"
                                onClick={this.handleClick}
                            >
                                <span className="icon-search headerIcon" />
                            </IconButton>
                        </div>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={this.handleClose}
                        >
                            <InputLabel className="filter-label">{i18n.t('Crop group')}:</InputLabel>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                isClearable={false}
                                value={this.state.id_kultura_grupa}
                                components={{
                                    IndicatorsContainer: IndicatorsContainer
                                }}
                                maxMenuHeight={150}
                                menuPlacement='auto'
                                onChange={this.handleChange('id_kultura_grupa')}
                                options={this.state.cropGroupDropdown}
                                className="multi-select-in-filter-dashboards"
                                classNamePrefix="select"
                                placeholder={i18n.t('Select')}
                            />
                            <div style={{ padding: '30px' }}>
                                <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleOk}>{i18n.t('Ok')}</Button>
                                <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleClose}>{i18n.t('Cancel')}</Button>
                                <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleClear}>{i18n.t('Clear')}</Button>

                            </div>
                        </Menu>
                    </div>
                </Card>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Card style={styles.cardMin}>
                        <h1>{i18n.t('Seeding by crop group and field area')}:</h1>
                        <Pie data={cropGroupData} options={this.povrsinaOptions} />
                    </Card>
                    <Card style={styles.cardMin}>
                        <h1>{i18n.t('Seeding by seasons')}:</h1>
                        <Pie data={seasonData} options={this.options} />
                    </Card>
                </div >

                <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} />
            </div>
        )
    }


    cropGroupColor = randomColor({
        count: 50
    })

    layerColor = randomColor({
        count: 50
    })

    cropGroup = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.cropGroupColor,
            hoverBackgroundColor: this.cropGroupColor
        }]
    };
    layer = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.layerColor,
            hoverBackgroundColor: this.layerColor
        }]
    };

    options = {
        tooltips: {
            mode: 'label',
            callbacks: {

                afterLabel: function (tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex].data1[tooltipItem.index] + '%';
                },

            },
        }
    };

    povrsinaOptions = {
        tooltips: {
            mode: 'label',
            callbacks: {

                afterLabel: function (tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex].data1[tooltipItem.index] + 'ha';
                },

            },
        }
    };



};

function mapStateToProps(state) {
    return {
        seedingItems: state.reportsReducer.seedingItems,
        cropGroupDropdown: state.cropReducer.cropGroupDropdown,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getSeedingDashboard: (filters) => dispatch(getSeedingDashboard(filters)),
        getGroup: () => dispatch(getGroup()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SeedingDashboard);

const styles = {
    card: {
        minWidth: 275,
        padding: '10px',
        margin: '10px'
    },
    cardMin: {
        width: '45%',
        padding: '10px',
        margin: '10px',
        position: 'relative',
        float: 'left'
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
};