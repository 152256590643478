import React from 'react';
import { connect } from 'react-redux';
import styled from 'react-emotion';

const Hamburger = styled('span') `
  transition: transform 0.3s ease-in-out;
  transform: ${props => (props.isDrawerOpen ? 'scale(0.7)' : 'scale(0.9)')};
`;

const Line = styled('span') `
  width: 32px;
  height: 3px;
  background-color:${props => (props.isDrawerOpen ? 'white' : 'white')};
  display: block;
  margin: 7px auto;
  transition: transform 0.3s ease-in-out;
  transform: ${props => {
        let rotate = 'none'
        if (props.top == 'true') {
            rotate = 'translateX(-10px) rotate(-45deg)'
        } else if (props.top == 'false') {
            rotate = 'translateX(-10px) rotate(45deg)'
        }
        return (props.isDrawerOpen ? rotate : 'none')
    }}
`;

export class MenuHamburger extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDrawerOpen: this.props.isDrawerOpen
        }
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            isDrawerOpen: nextProps.isDrawerOpen
        })
    }
    render() {
        return (
            <Hamburger isDrawerOpen={this.props.isDrawerOpen}>
                <Line
                    isDrawerOpen={this.props.isDrawerOpen}
                    top={'true'}
                />
                <Line isDrawerOpen={this.props.isDrawerOpen} top={'null'} />
                <Line
                    isDrawerOpen={this.props.isDrawerOpen}
                    top={'false'}
                />
            </Hamburger>
        );
    }
}

function mapStateToProps(state) {
    return {
        isDrawerOpen: state.appReducer.isDrawerOpen
    };
}
function mapDispatchToProps(dispatch) {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MenuHamburger);

