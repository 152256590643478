/**
 * Created by pajicv on 6/19/18.
 */

export const FEATURE_LOAD_STEPS = {
    IDLE: 'IDLE',
    LOADING: 'LOADING',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS',
    DRAWN: 'DRAWN'
};

export const ALL_STATES_ID = 9999;

export const ALL_LOCAL_GOVS_ID = 9999;

export const SENTINEL_HUB_API_KEY = '641ac229-0837-40af-878f-4e6550ce0400';

export const DAY_INTERVAL = 24 * 60 * 60 * 1000;

export const getTodayDate = () => new Date();

export const getTomorrowDate = () => new Date(getTodayDate().getTime() + DAY_INTERVAL);

export const addDays = (date, numOfDays ) => new Date(date.getTime() + numOfDays * DAY_INTERVAL);

export const getDaysBetween = (firstDate, secondDate) => {

    const t1 = new Date(firstDate);
    const t2 = new Date(secondDate);

    return Math.ceil(Math.abs(t2.getTime() - t1.getTime()) / DAY_INTERVAL);
};

export const FIELD_NOTES_FIRE =     12;
export const FIELD_NOTES_DESEASE =  13;
export const FIELD_NOTES_PEST =     14;
export const FIELD_NOTES_STEAL =    15;
export const FIELD_NOTES_OTHER =    16;
export const FIELD_NOTES_MARK =     17;

export const WMS_LAYER = 'wms';
export const POINT_LAYER = 'point';
export const POLYGON_LAYER = 'polygon';

export const AGROLIFE_PROVIDER = 'agrolife';
export const GEOSERVER_PROVIDER = 'geoserver';
export const SENTINEL_PROVIDER = 'sentinel';

export const GOOGLE_MAPS_API_KEY = 'AIzaSyBuZJYdqpIR_vERdvSmk0zNRrCQMBiMaz0';
