import React from 'react';
import { connect } from 'react-redux';
import styled from 'react-emotion';
import { toggleDrawer, toggleMenu, logout, getUserProfile, getClientProfile } from '../actions/LoginActions';
import { Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
    Menu, MenuList, MenuItem, ListItemText, Avatar, ExpansionPanel, IconButton,
    ExpansionPanelSummary, ExpansionPanelDetails, Typography, TextField, Link
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { withRouter } from 'react-router-dom'
import i18n from '../i18n/i18n';
import Profile from './Profile';
import Settings from './Settings';
import Support from './Support';
import axios from '../utils/AxiosWrapper'
import classnames from 'classnames';


const Paper = styled('aside')`
  position: fixed;
  z-index: ${p => p.theme.zIndex.drawer};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  top: 0;
  left: 0;
  height: 100vh;
  width: ${p => (p.isDrawerOpen || p.isMenuOpen ? `${p.theme.size(20)}` : `${p.theme.size(5)}`)};
  background: ${p => p.theme.palette.secondary.main};
  transition: width 0.2s ease-in-out;
  box-shadow:  1px 0 2px rgba(0,0,0,0.07), 
                 2px 0 4px rgba(0,0,0,0.11), 
                 4px 0 8px rgba(0,0,0,0.14), 
                 8px 0 16px rgba(0,0,0,0.14);
  border-radius: 0px;
`;

const Header = styled('header')`
  display: flex;
  align-items: center;
  height: ${p => p.theme.size(4)};
  width: ${p => (p.isDrawerOpen || p.isMenuOpen ? `${p.theme.size(20)}` : `${p.theme.size(5)}`)};
  transition: width 0.2s ease-in-out;
  background: ${p => (p.isDrawerOpen || p.isMenuOpen ? p.theme.palette.secondary.dark : p.theme.palette.secondary.dark)};
`;

const styles = theme => ({
    primaryMain: {
        backgroundColor: theme.palette.primary.main,
    },
    secondaryMain: {
        backgroundColor: theme.palette.secondary.main,
        background: theme.palette.secondary.main,
    },
    secondaryDark: {
        backgroundColor: theme.palette.secondary.dark,
        background: theme.palette.secondary.dark,
    },
    secondaryLight: {
        backgroundColor: theme.palette.secondary.light,
        background: theme.palette.secondary.light,
    },
    secondarySelected: {
        background: theme.palette.secondary.light,
        borderColor: theme.palette.primary.main,
        borderLeft: 10,
        borderLeftStyle: 'solid'
    },
});


export class MenuDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDrawerOpen: this.props.isDrawerOpen,
            isMenuOpen: this.props.isMenuOpen,
            anchorEl: null,
            profile: false,
            settingsOpen: false,
            support: false,
            sezonaMenu: ''
        }
    }
    componentDidMount() {
        this.props.getUserProfile();
        this.props.getClientProfile(this.getUser().id_klijent);
        this.getSeason()
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            isDrawerOpen: nextProps.isDrawerOpen,
            isMenuOpen: nextProps.isMenuOpen
        })
    }

    getSeason() {
        axios.get('api/season/activity_season')
            .then(res => this.setState({ sezonaMenu: res.data.data[0].naziv }))
            .catch(error => console.log('Something went wrong, please try again!'));
    }

    toggleDrawerOver() {
        this.props.toggleMenu(true);
    }
    toggleDrawerOut() {
        this.props.toggleMenu(false);
    }

    tokenCheck() {
        let token = localStorage.getItem('token');
        return token;
    }

    onLogout() {
        this.props.logout();
        localStorage.clear();
        this.props.history.replace('/');
    }

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    goTo(path, prop) {
        this.props.history.push(path, { ...prop });
    }

    goToManuals() {
        window.open("https://manuals.agrolife.world/webr/", "_blank");
    }

    handleMoreClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMoreClose = () => {
        this.setState({ anchorEl: null });
        this.getSeason()
    };

    onProfileClick = () => {
        this.setState({ profile: !this.state.profile });
        this.handleMoreClose();
    }
    onSettingsClick = () => {
        this.setState({ settingsOpen: !this.state.settingsOpen });
        this.handleMoreClose();
    }

    onSupportClick = () => {
        this.setState({ support: !this.state.support });
    }

    render() {
        let user = this.getUser();
        var avatarSrc = require('../assets/images/profileImage.png');
        const { classes } = this.props;
        if (this.props.userProfile && this.props.userProfile.slika) avatarSrc = this.props.userProfile.signedURL;
        const { anchorEl } = this.state;
        const location = window.location;

        return (
            <Paper isDrawerOpen={this.state.isDrawerOpen}
                isMenuOpen={this.state.isMenuOpen}
            // onMouseOver={() => this.toggleDrawerOver()}
            // onMouseOut={() => this.toggleDrawerOut()}
            >
                <Header isDrawerOpen={this.state.isDrawerOpen}
                    isMenuOpen={this.state.isMenuOpen} />
                <div className="listInsideMenu" >
                    <Divider style={{ width: '260px' }} />
                    <div>
                        <ExpansionPanel className="expansion-panel" style={{ boxShadow: '0px 0px 0px 0px' }} expanded={false}>
                            <ExpansionPanelSummary onClick={() => this.goTo('/dashboard')} className={window.location.pathname.startsWith('/dashboard') ? classnames(classes.secondarySelected, 'expansion-panel-summary-selected') : classnames(classes.secondaryMain, 'expansion-panel-summary')}>
                                <span className="icon-pie-chart menuIcon" />
                                <Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Dashboard')}</Typography>
                            </ExpansionPanelSummary>
                        </ExpansionPanel>
                    </div>

                    {user.moduli.length === 0 || user.moduli.some((item) => {
                        return item.naziv === 'Registracija gazdinstava';
                    }) ? <div>
                        <Divider style={{ width: '260px' }} />
                        <div>
                            <ExpansionPanel className="expansion-panel" style={{ boxShadow: '0px 0px 0px 0px' }} expanded={false}>
                                <ExpansionPanelSummary onClick={() => this.goTo('/farmer')} className={window.location.pathname.startsWith('/farmer') ? classnames(classes.secondarySelected, 'expansion-panel-summary-selected') : classnames(classes.secondaryMain, 'expansion-panel-summary')}>
                                    <span className="icon-users menuIcon" />
                                    <Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Farmers')}</Typography>
                                </ExpansionPanelSummary>
                            </ExpansionPanel>
                            {/* <ExpansionPanel className="expansion-panel" style={{ boxShadow: '0px 0px 0px 0px' }}>
                                <ExpansionPanelSummary className="expansion-panel-summary" expandIcon={<Icons.ExpandMore style={{ color: 'white', fontSize: '20px' }} />}>
                                    <span className="icon-Farmers menuIcon" />
                                    <Typography style={{ color: 'white', fontSize: '16px' }}>{i18n.t('Farmers')}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={location.pathname == '/farmer' ? 'expansion-panel-detail-selected' : 'expansion-panel-detail'}>
                                    <MenuItem onClick={() => this.goTo('/farmer', { showAnchor: false })} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-users menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('List of farmers')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>
                                {user.id_region === 13 && (user.id_klijent_grupa == 8 || user.id_klijent_grupa == 10) &&
                                    <ExpansionPanelDetails className={location.pathname.includes('/farmer_blacklisted') ? 'expansion-panel-detail-selected' : 'expansion-panel-detail'}>
                                        <MenuItem onClick={() => this.goTo('/farmer_blacklisted', { showAnchor: true })} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                            <span className="icon-users menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Blacklisted farmers')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails>
                                }
                                {user.id_klijent_grupa == 14 &&
                                    <ExpansionPanelDetails className={location.pathname.includes('/my_farmers') ? 'expansion-panel-detail-selected' : 'expansion-panel-detail'}>
                                        <MenuItem onClick={() => this.goTo('/my_farmers', { showAnchor: false })} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                            <span className="icon-users menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('My farmers')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails>
                                }
                            </ExpansionPanel> */}
                        </div>
                    </div> : null}

                    {user.id_klijent_grupa === 8 || user.id_klijent_grupa === 7 || user.id_klijent_grupa === 2 || user.id_klijent_grupa === 1 ?
                        <div>
                            <Divider style={{ width: '260px' }} />
                            <div>
                                <ExpansionPanel className="expansion-panel" style={{ boxShadow: '0px 0px 0px 0px' }} expanded={false}>
                                    <ExpansionPanelSummary onClick={() => this.goTo('/agro_production')} className={window.location.pathname.startsWith('/agro_production') ? classnames(classes.secondarySelected, 'expansion-panel-summary-selected') : classnames(classes.secondaryMain, 'expansion-panel-summary')}>
                                        <span className="icon-Lista menuIcon" />
                                        <Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Activities')}</Typography>
                                    </ExpansionPanelSummary>
                                </ExpansionPanel>
                            </div>
                            {/* <Divider style={{ width: '260px' }} /> */}
                        </div>
                        : null}



                    {user.moduli.length === 0 || user.moduli.some((item) => {
                        return item.naziv.includes('reports');
                    }) ? <div>
                        <Divider style={{ width: '260px' }} />
                        <div>
                            <ExpansionPanel className="expansion-panel" style={{ boxShadow: '0px 0px 0px 0px' }} expanded={false}>
                                <ExpansionPanelSummary onClick={() => this.goTo('/activities_book')} className={window.location.pathname.startsWith('/activities_book') ? classnames(classes.secondarySelected, 'expansion-panel-summary-selected') : classnames(classes.secondaryMain, 'expansion-panel-summary')}>
                                    <span className="icon-NoviRadniNalog menuIcon" />
                                    <Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Activities book')}</Typography>
                                </ExpansionPanelSummary>
                            </ExpansionPanel>
                        </div>
                    </div> : null}

                    {user.moduli.length === 0 || user.moduli.some((item) => {
                        return item.naziv === 'maps';
                    }) ? <div>
                        <Divider style={{ width: user.id_klijent_grupa !== 12 ? '260px' : '0px' }} />
                        {user.id_klijent_grupa !== 12 &&
                            <div>
                                <ExpansionPanel className="expansion-panel" style={{ boxShadow: '0px 0px 0px 0px' }} expanded={false}>
                                    <ExpansionPanelSummary onClick={() => this.goTo('/map')} className={window.location.pathname.startsWith('/map') ? classnames(classes.secondarySelected, 'expansion-panel-summary-selected') : classnames(classes.secondaryMain, 'expansion-panel-summary')}>
                                        <span className="icon-Map menuIcon" />
                                        <Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Map')}</Typography>
                                    </ExpansionPanelSummary>
                                </ExpansionPanel>
                            </div>}
                    </div> : null}

                    {user.moduli.length === 0 || user.moduli.some((item) => {
                        return item.naziv === 'maps';
                    }) ? <div>
                        <Divider style={{ width: (user.id_klijent_grupa !== 12 && user.id_region == 29) ? '260px' : '0px' }} />
                        {(user.id_klijent_grupa !== 12 && user.id_region == 29) &&
                        <div>
                        <ExpansionPanel className="expansion-panel" style={{ boxShadow: '0px 0px 0px 0px' }} expanded={false}>
                            <ExpansionPanelSummary onClick={() => this.goTo('/parcel')} className={window.location.pathname.startsWith('/parcel') ? classnames(classes.secondarySelected, 'expansion-panel-summary-selected') : classnames(classes.secondaryMain, 'expansion-panel-summary')}>
                                <span className="icon-TableLista menuIcon" />
                                <Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Parcels')}</Typography>
                            </ExpansionPanelSummary>
                        </ExpansionPanel>
                        </div>}
                        
                        <Divider style={{ width: user.id_klijent_grupa !== 12 ? '260px' : '0px' }} />
                        {user.id_klijent_grupa !== 12 &&
                            <div>
                                <ExpansionPanel className="expansion-panel" style={{ boxShadow: '0px 0px 0px 0px' }} expanded={false}>
                                    <ExpansionPanelSummary onClick={() => this.goTo('/field')} className={window.location.pathname.startsWith('/field') ? classnames(classes.secondarySelected, 'expansion-panel-summary-selected') : classnames(classes.secondaryMain, 'expansion-panel-summary')}>
                                        <span className="icon-Fields menuIcon" />
                                        <Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Fields')}</Typography>
                                    </ExpansionPanelSummary>
                                </ExpansionPanel>
                            </div>}
                    </div> : null}

                    {user.moduli.length === 0 || user.moduli.some((item) => {
                        return item.naziv.includes('reports');
                    }) ? <div>
                        <Divider style={{ width: '260px' }} />
                        <div>
                            <ExpansionPanel className="expansion-panel" style={{ boxShadow: '0px 0px 0px 0px' }} expanded={false}>
                                <ExpansionPanelSummary onClick={() => this.goTo('/crop_rotation')} className={window.location.pathname.startsWith('/crop_rotation') ? classnames(classes.secondarySelected, 'expansion-panel-summary-selected') : classnames(classes.secondaryMain, 'expansion-panel-summary')}>
                                    <span className="icon-Common menuIcon" />
                                    <Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Crop rotation')}</Typography>
                                </ExpansionPanelSummary>
                            </ExpansionPanel>
                        </div>
                    </div> : null}

                    {user.moduli.length === 0 || user.moduli.some((item) => {
                        return item.naziv === 'maps';
                    }) ? <div>
                        <Divider style={{ width: user.id_klijent_grupa !== 12 ? '260px' : '0px' }} />
                        {user.id_klijent_grupa !== 12 &&
                            <div>
                                <ExpansionPanel className="expansion-panel" style={{ boxShadow: '0px 0px 0px 0px' }} expanded={false}>
                                    <ExpansionPanelSummary onClick={() => this.goTo('/sensors')} className={window.location.pathname.startsWith('/sensors') ? classnames(classes.secondarySelected, 'expansion-panel-summary-selected') : classnames(classes.secondaryMain, 'expansion-panel-summary')}>
                                        <span className="icon-feed menuIcon" />
                                        <Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Meteo stations')}</Typography>
                                    </ExpansionPanelSummary>
                                </ExpansionPanel>
                            </div>}
                        {/* <Divider style={{ width: '260px' }} /> */}
                    </div> : null}

                    {user.moduli.length === 0 || user.moduli.some((item) => {
                        return item.naziv.includes('reports');
                    }) ? <div>
                        <Divider style={{ width: '260px' }} />
                        {user.id_region !== 2 &&
                        <div>
                            <ExpansionPanel className="expansion-panel" style={{ boxShadow: '0px 0px 0px 0px' }} expanded={false}>
                                <ExpansionPanelSummary onClick={() => this.goTo('/yearly_report')} className={window.location.pathname.startsWith('/yearly_report') ? classnames(classes.secondarySelected, 'expansion-panel-summary-selected') : classnames(classes.secondaryMain, 'expansion-panel-summary')}>
                                    <span className="icon-Season menuIcon" />
                                    <Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Yearly report')}</Typography>
                                </ExpansionPanelSummary>
                            </ExpansionPanel>
                        </div>}
                    </div> : null}

                    {user.moduli.length === 0 || user.moduli.some((item) => {
                        return item.naziv.includes('resources');
                    }) ? <div>
                        <Divider style={{ width: '260px' }} />
                        <div>
                            <ExpansionPanel className="expansion-panel" style={{ boxShadow: '0px 0px 0px 0px' }}>
                                <ExpansionPanelSummary className="expansion-panel-summary" expandIcon={<Icons.ExpandMore style={{ color: 'white', fontSize: '20px' }} />}>
                                    <span className="icon-Resources menuIcon" />
                                    <Typography style={{ color: 'white', fontSize: '16px' }}>{i18n.t('Resources')}</Typography>
                                </ExpansionPanelSummary>

                                <ExpansionPanelDetails className={window.location.pathname.includes('/material') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/material')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-Material menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Materials')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>

                                <ExpansionPanelDetails className={window.location.pathname.includes('/crop') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/crop')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-Biljka menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Crops')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>

                                <ExpansionPanelDetails className={window.location.pathname.includes('/sort') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/sort')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-Biljka menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Sorts/Hybrids')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>

                                <ExpansionPanelDetails className={window.location.pathname.includes('/machines') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/machines')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-Machines menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Machines')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>

                                <ExpansionPanelDetails className={window.location.pathname.includes('/implement') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/implement')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-Implements menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Implements')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>

                                <ExpansionPanelDetails className={window.location.pathname.includes('/working_operation') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/working_operation')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-Working-Operation menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Working operations')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>
                                {/* <ExpansionPanelDetails className={location.pathname.includes('/season') ? 'expansion-panel-detail-selected' : 'expansion-panel-detail'}>
                                        <MenuItem onClick={() => this.goTo('/season')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                            <span className="icon-Season menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Seasons')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails> */}
                            </ExpansionPanel>
                        </div>
                    </div> : null}
                    
                    {/* {user.moduli.length === 0 || user.moduli.some((item) => {
                        return item.naziv === 'financial_services';
                    }) ? <div>
                        <div>
                            <ExpansionPanel className="expansion-panel" style={{ boxShadow: '0px 0px 0px 0px' }}>
                                <ExpansionPanelSummary className="expansion-panel-summary" expandIcon={<Icons.ExpandMore style={{ color: 'white', fontSize: '20px' }} />}>
                                    <span className="icon-credit-card menuIcon" />
                                    <Typography style={{ color: 'white', fontSize: '16px' }}>{i18n.t('Financial services')}</Typography>
                                </ExpansionPanelSummary>

                                <ExpansionPanelDetails className={window.location.pathname.includes('/subsidies') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/subsidies')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-Beleske menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Subsidies')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>

                                <ExpansionPanelDetails className={window.location.pathname.includes('/institutions') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/institutions')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-Government menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Institutions')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>

                                <ExpansionPanelDetails className={window.location.pathname.includes('/program') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                    <MenuItem onClick={() => this.goTo('/program')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-Beleske menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Programs')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>
                                <ExpansionPanelDetails className={location.pathname.includes('/incentive') ? 'expansion-panel-detail-selected' : 'expansion-panel-detail'}>
                                    <MenuItem onClick={() => this.goTo('/incentive')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                        <span className="icon-Common menuIcon" />
                                        <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Incentives')}</Typography>} />
                                    </MenuItem>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </div>
                    </div> : null} */}

                    {user.id_region === 13 && user.moduli.length === 0 || user.moduli.some((item) => {
                        return item.naziv === 'hierarchy';
                    }) ? <div>
                        <Divider style={{ width: '260px' }} />
                        <div>
                            <ExpansionPanel className="expansion-panel" style={{ boxShadow: '0px 0px 0px 0px' }}>
                                <ExpansionPanelSummary onClick={() => this.goTo('/hierarchy')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }} className={location.pathname.startsWith('/hierarchy') ? 'expansion-panel-summary-selected' : 'expansion-panel-summary'}>
                                    <span className="icon-list menuIcon" />
                                    <Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Hierarchy')}</Typography>
                                </ExpansionPanelSummary>
                            </ExpansionPanel>
                        </div>
                    </div> : null}

                    {/* <Divider style={{ width: '260px' }} />
                    {(user.id_region == 2 && user.id_klijent_grupa != 1) ?
                        <div>
                            <Divider style={{ width: '260px' }} />
                            <div>
                                <ExpansionPanel className="expansion-panel">
                                    <ExpansionPanelSummary onClick={() => this.goTo('/auction')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }} className={location.pathname.startsWith('/auction') ? 'expansion-panel-summary-selected' : 'expansion-panel-summary'}>
                                        <span className="icon-list menuIcon" />
                                        <Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Auction')}</Typography>
                                    </ExpansionPanelSummary>
                                </ExpansionPanel>
                            </div>
                        </div> : null} */}

                    {/* <Divider style={{ width: '260px' }} /> */}
                    {user.id_klijent_grupa == 55 &&
                        <div>
                            <div>
                                <ExpansionPanel className="expansion-panel" style={{ boxShadow: '0px 0px 0px 0px' }}>
                                    <ExpansionPanelSummary className="expansion-panel-summary" expandIcon={<Icons.ExpandMore style={{ color: 'white', fontSize: '20px' }} />}>
                                        <span className="icon-Government menuIcon" />
                                        <Typography style={{ color: 'white', fontSize: '16px' }}>{i18n.t('Administration')}</Typography>
                                    </ExpansionPanelSummary>
                                    {user.id_klijent_grupa == 8 &&
                                        <div>
                                            <ExpansionPanelDetails className={location.pathname.includes('/zonal') ? 'expansion-panel-detail-selected' : 'expansion-panel-detail'}>
                                                <MenuItem onClick={() => this.goTo('/zonal')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                    <Icons.People className="icon-info menuIcon" />
                                                    <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Zonal officer')}</Typography>} />
                                                </MenuItem>
                                            </ExpansionPanelDetails>
                                            <ExpansionPanelDetails className={location.pathname.includes('/regional_offices') ? 'expansion-panel-detail-selected' : 'expansion-panel-detail'}>
                                                <MenuItem onClick={() => this.goTo('/regional_offices')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                    <Icons.People className="icon-info menuIcon" />
                                                    <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Branch officer')}</Typography>} />
                                                </MenuItem>
                                            </ExpansionPanelDetails>
                                            <ExpansionPanelDetails className={location.pathname.includes('/anchors') ? 'expansion-panel-detail-selected' : 'expansion-panel-detail'}>
                                                <MenuItem onClick={() => this.goTo('/anchors')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                    <Icons.People className="icon-info menuIcon" />
                                                    <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Anchors')}</Typography>} />
                                                </MenuItem>
                                            </ExpansionPanelDetails>
                                            <ExpansionPanelDetails className={location.pathname.includes('/agents') ? 'expansion-panel-detail-selected' : 'expansion-panel-detail'}>
                                                <MenuItem onClick={() => this.goTo('/agents')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                    <Icons.People className="icon-info menuIcon" />
                                                    <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Agents')}</Typography>} />
                                                </MenuItem>
                                            </ExpansionPanelDetails>
                                            <ExpansionPanelDetails className={location.pathname.includes('/executives') ? 'expansion-panel-detail-selected' : 'expansion-panel-detail'}>
                                                <MenuItem onClick={() => this.goTo('/executives')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                    <Icons.People className="icon-info menuIcon" />
                                                    <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Executives')}</Typography>} />
                                                </MenuItem>
                                            </ExpansionPanelDetails>
                                        </div>
                                    }

                                    {user.id_klijent_grupa == 9 &&
                                        <ExpansionPanelDetails className={location.pathname.includes('/state_managers') ? 'expansion-panel-detail-selected' : 'expansion-panel-detail'}>
                                            <MenuItem onClick={() => this.goTo('/state_managers')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <Icons.People className="icon-info menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('State officer')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails>
                                    }

                                    {user.id_klijent_grupa == 10 &&
                                        <div>
                                            {/*  <ExpansionPanelDetails className={location.pathname.includes('/anchors') ? 'expansion-panel-detail-selected' : 'expansion-panel-detail'}>
                                            <MenuItem onClick={() => this.goTo('/anchors')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <Icons.People className="icon-info menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Anchors')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails>
                                        <ExpansionPanelDetails className={location.pathname.includes('/agents') ? 'expansion-panel-detail-selected' : 'expansion-panel-detail'}>
                                            <MenuItem onClick={() => this.goTo('/agents')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <Icons.People className="icon-info menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Agents')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails> */}
                                            <ExpansionPanelDetails className={location.pathname.includes('/cooperative_society') ? 'expansion-panel-detail-selected' : 'expansion-panel-detail'}>
                                                <MenuItem onClick={() => this.goTo('/cooperative_society')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                    <Icons.People className="icon-info menuIcon" />
                                                    <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Cluster')}</Typography>} />
                                                </MenuItem>
                                            </ExpansionPanelDetails>
                                        </div>
                                    }

                                    {user.id_klijent_grupa == 11 &&
                                        <ExpansionPanelDetails className={location.pathname.includes('/regional_offices') ? 'expansion-panel-detail-selected' : 'expansion-panel-detail'}>
                                            <MenuItem onClick={() => this.goTo('/regional_offices')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <Icons.People className="icon-info menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Regional officer')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails>
                                    }

                                    {user.id_klijent_grupa == 13 &&
                                        <ExpansionPanelDetails className={location.pathname.includes('/field_managers') ? 'expansion-panel-detail-selected' : 'expansion-panel-detail'}>
                                            <MenuItem onClick={() => this.goTo('/field_managers')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <Icons.People className="icon-info menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Field managers')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails>
                                    }

                                    {user.id_klijent_grupa == 13 &&
                                        <ExpansionPanelDetails className={location.pathname.includes('/farmer') ? 'expansion-panel-detail-selected' : 'expansion-panel-detail'}>
                                            <MenuItem onClick={() => this.goTo('/farmer', { showAnchor: true })} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <span className="icon-users menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('List of farmers')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails>
                                    }

                                    <ExpansionPanelDetails className={location.pathname.includes('/users') ? 'expansion-panel-detail-selected' : 'expansion-panel-detail'}>
                                        <MenuItem onClick={() => this.goTo('/users')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                            <Icons.People className="icon-users menuIcon" />
                                            <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Users')}</Typography>} />
                                        </MenuItem>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                            <Divider style={{ width: '260px' }} />
                        </div>
                    }
                    {user.id_klijent_grupa == 12 &&
                        <div>
                            <Divider style={{ width: '260px' }} />
                            <div>
                                <ExpansionPanel className="expansion-panel" style={{ boxShadow: '0px 0px 0px 0px' }}>
                                    <ExpansionPanelSummary className="expansion-panel-summary" expandIcon={<Icons.ExpandMore style={{ color: 'white', fontSize: '20px' }} />}>
                                        {/* <Icons.Menu className="icon-menu menuIcon" /> */}
                                        <img src="./db.svg" height="22px" style={{ padding: '0px 33px 0px 3px' }} />
                                        <Typography style={{ color: 'white', fontSize: '16px' }}>{i18n.t('Database')}</Typography>
                                    </ExpansionPanelSummary>

                                    <div>
                                        <ExpansionPanelDetails className={location.pathname.includes('/regions') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                            <MenuItem onClick={() => this.goTo('/regions')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <Icons.Public className="icon-public menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Regions')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails>

                                        <ExpansionPanelDetails className={location.pathname.includes('/season') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                            <MenuItem onClick={() => this.goTo('/season')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <span className="icon-Season menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Seasons')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails>

                                        <ExpansionPanelDetails className={location.pathname.includes('/default_season') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                            <MenuItem onClick={() => this.goTo('/default_season')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <span className="icon-Season menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Default Seasons')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails>

                                        <ExpansionPanelDetails className={location.pathname.includes('/layer_cp') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                            <MenuItem onClick={() => this.goTo('/layer_cp')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <span className="icon-stack menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Layers')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails>

                                        <ExpansionPanelDetails className={location.pathname.includes('/sensors_cp') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                            <MenuItem onClick={() => this.goTo('/sensors_cp')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <span className="icon-feed menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Sensors')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails>

                                        {/* <ExpansionPanelDetails className={location.pathname.includes('/client_gm') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                            <MenuItem onClick={() => this.goTo('/client_gm')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <Icons.Menu className="icon-menu menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Client G-M')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails> */}
                                        {/* <ExpansionPanelDetails className={location.pathname.includes('/form_module') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                            <MenuItem onClick={() => this.goTo('/form_module')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <Icons.ViewCompact className="icon-ViewCompact menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Form Module')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails> */}
                                        {/* <ExpansionPanelDetails className={location.pathname.includes('/admin_panel') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                            <MenuItem onClick={() => this.goTo('/admin_panel')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <span className="icon-stack menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Region layers')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails> */}

                                        <ExpansionPanelDetails className={location.pathname.includes('/institution_types') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                            <MenuItem onClick={() => this.goTo('/institution_types')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <span className="icon-Government menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Institution Types')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails>
                                    </div>
                                </ExpansionPanel>
                            </div>
                            <Divider style={{ width: '260px' }} />
                        </div>
                    }

                    {user.id_klijent_grupa == 12 &&
                        <div>
                            <div>
                                <ExpansionPanel className="expansion-panel" style={{ boxShadow: '0px 0px 0px 0px' }}>
                                    <ExpansionPanelSummary className="expansion-panel-summary" expandIcon={<Icons.ExpandMore style={{ color: 'white', fontSize: '20px' }} />}>
                                        {/* <Icons.Menu className="icon-menu menuIcon" /> */}
                                        <img src="./adm.svg" height="22px" style={{ padding: '0px 29px 0px 3px' }} />
                                        <Typography style={{ color: 'white', fontSize: '16px' }}>{i18n.t('Admin portal')}</Typography>
                                    </ExpansionPanelSummary>

                                    <div>
                                        <ExpansionPanelDetails className={location.pathname.includes('/clients') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                            <MenuItem onClick={() => this.goTo('/clients')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <Icons.People className="icon-users menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Clients - Users')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails>

                                        <ExpansionPanelDetails className={location.pathname.includes('/fieldsparcels') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                            <MenuItem onClick={() => this.goTo('/fieldsparcels')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <span className="icon-TableLista menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Fields - Parcels')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails>

                                        <ExpansionPanelDetails className={location.pathname.includes('/accounts') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                            <MenuItem onClick={() => this.goTo('/accounts')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <Icons.Person className="icon-person menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Accounts')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails>

                                        <ExpansionPanelDetails className={location.pathname.includes('/ap_account_management') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                            <MenuItem onClick={() => this.goTo('/ap_account_management')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <span className="icon-user menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Account Management')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails>

                                        <ExpansionPanelDetails className={location.pathname.includes('/ap_map_control_panel') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                            <MenuItem onClick={() => this.goTo('/ap_map_control_panel')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <span className="icon-Map menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Map Control Panel')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails>

                                        <ExpansionPanelDetails className={location.pathname.includes('/ap_activities') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                            <MenuItem onClick={() => this.goTo('/ap_activities')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <Icons.Assignment className="icon-menu menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Activities')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails>

                                        <ExpansionPanelDetails className={location.pathname.includes('/ap_event_tracking') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                            <MenuItem onClick={() => this.goTo('/ap_event_tracking')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <Icons.EventNote className="icon-menu menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Event Tracking')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails>

                                        <ExpansionPanelDetails className={location.pathname.includes('/ap_top_list') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                            <MenuItem onClick={() => this.goTo('/ap_top_list')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <Icons.Menu className="icon-menu menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Top List')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails>

                                        <ExpansionPanelDetails className={location.pathname.includes('/ap_naled_report') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                            <MenuItem onClick={() => this.goTo('/ap_naled_report')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <Icons.Assessment className="icon-menu menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('NALED report')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails>

                                        <ExpansionPanelDetails className={location.pathname.includes('/sombor') ? classnames(classes.secondarySelected, 'expansion-panel-detail-selected') : classnames(classes.secondaryMain, 'expansion-panel-detail')}>
                                            <MenuItem onClick={() => this.goTo('/sombor')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }}>
                                                <Icons.LocationCity className="icon-menu menuIcon" />
                                                <ListItemText disableTypography primary={<Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Sombor')}</Typography>} />
                                            </MenuItem>
                                        </ExpansionPanelDetails>

                                    </div>
                                </ExpansionPanel>
                            </div>
                            <Divider style={{ width: '260px' }} />
                        </div>
                    }

                    {/* 

                    {user.id_klijent_grupa === 12 ?
                        <div>
                            <div>
                                <ExpansionPanel className="expansion-panel">
                                    <ExpansionPanelSummary onClick={() => this.goTo('/admin_panel')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }} className={location.pathname.startsWith('/admin_panel') ? 'expansion-panel-summary-selected' : 'expansion-panel-summary'}>
                                        <span className="icon-user menuIcon" />
                                        <Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Agro Admin')}</Typography>
                                    </ExpansionPanelSummary>
                                </ExpansionPanel>
                            </div>
                            <Divider style={{ width: '260px' }} />
                        </div>
                        : null} */}

                    {user.id_klijent_grupa === 12 ?
                        <div>
                            <div>
                                <ExpansionPanel className="expansion-panel" style={{ boxShadow: '0px 0px 0px 0px' }} expanded={false}>
                                    <ExpansionPanelSummary onClick={() => this.goTo('/testing')} className={window.location.pathname.startsWith('/testing') ? classnames(classes.secondarySelected, 'expansion-panel-summary-selected') : classnames(classes.secondaryMain, 'expansion-panel-summary')}>
                                        {/* <span className="icon-user menuIcon" /> */}
                                        <img src="./test.svg" height="22px" style={{ padding: '0px 33px 0px 5px' }} />
                                        <Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Testing')}</Typography>
                                    </ExpansionPanelSummary>
                                </ExpansionPanel>
                            </div>
                            <Divider style={{ width: '260px' }} />
                        </div>
                        : null}

                    {/* {user.id_klijent === 22594 ?
                        <div>
                            <div>
                                <ExpansionPanel className="expansion-panel">
                                    <ExpansionPanelSummary onClick={() => this.goTo('/fbs')} style={{ boxShadow: '0px 0px 0px 0px', width: '100%' }} className={location.pathname.startsWith('/fbs') ? 'expansion-panel-summary-selected' : 'expansion-panel-summary'}>
                                        <span className="icon-user menuIcon" />
                                        <Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('FDB\'s')}</Typography>
                                    </ExpansionPanelSummary>
                                </ExpansionPanel>
                            </div>
                            <Divider style={{ width: '260px' }} />
                        </div>
                        : null} */}


                    {user.id_klijent_grupa !== 12 &&
                        <div>
                            <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")} style={{ boxShadow: '0px 0px 0px 0px' }} expanded={false}>
                                <ExpansionPanelSummary onClick={() => this.goToManuals()} className={classnames(classes.secondaryMain, 'expansion-panel-summary')}>
                                    <span className="icon-info menuIcon" />
                                    <Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Manuals')}</Typography>
                                </ExpansionPanelSummary>
                            </ExpansionPanel>
                            <ExpansionPanel className={classnames(classes.secondaryDark, "expansion-panel")} style={{ boxShadow: '0px 0px 0px 0px' }} expanded={false}>
                                <ExpansionPanelSummary onClick={() => this.onSupportClick()} className={classnames(classes.secondaryMain, 'expansion-panel-summary')}>
                                    <span className="icon-question menuIcon" />
                                    <Typography style={{ color: '#FFFFFF', fontSize: '16px' }}>{i18n.t('Support')}</Typography>
                                </ExpansionPanelSummary>
                            </ExpansionPanel>

                            <Divider style={{ width: '260px' }} />
                        </div>}
                </div>
                <div style={{ display: 'flex', backgroundColor: '#126038', height: '7em', boxShadow: '0px 2px 5px 4px rgba(18, 96, 56, 0.5), 0px 4px 10px 8px rgba(18, 96, 56, 0.4), 0px 8px 20px 16px rgba(18, 96, 56, 0.3)' }}>
                    {avatarSrc ? <Avatar
                        src={avatarSrc}
                        className="profile-avatar"
                        style={{ opacity: this.props.isDrawerOpen ? '1' : '0', transition: 'opacity 0.2s', marginLeft: '12px' }}
                    /> :
                        <Avatar
                            className="profile-avatar"
                            style={{ alignSelf: 'center', opacity: this.props.isDrawerOpen ? '1' : '0', transition: 'opacity 0.2s', marginLeft: '12px', boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 16px 0px rgb(0 0 0 / 12%)' }}
                        >
                            <Typography color='primary' variant='h4'> {user.ime_prezime.replace('null', '').replace(/(\S)\S*\s*/ig, "$1").toUpperCase()}</Typography>
                        </Avatar>
                    }

                    <div style={{ alignSelf: 'center' }}>
                        <table>
                            <tr>
                                <td>
                                    <Typography className="profile-typography" style={{ opacity: this.props.isDrawerOpen ? '1' : '0', transition: 'opacity 0.2s', marginLeft: '6px' }}>
                                        <div>{user.ime_prezime !== ' ' ? user.ime_prezime.replace('null', '') : user.klijent_naziv}</div>
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography className="profile-typography2" style={{ opacity: this.props.isDrawerOpen ? '1' : '0', transition: 'opacity 0.2s', marginLeft: '6px' }}>
                                        <div>{user.id_region === 13 || user.id_region === 26 || user.id_region === 25 ? user.klijent_grupa_naziv_en : user.klijent_grupa_naziv}</div>
                                    </Typography>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <IconButton
                        variant="fab" aria-label="more"
                        className="menuMoreButton"
                        aria-owns={anchorEl ? 'simple-menu' : null}
                        aria-haspopup="true"
                        style={{ opacity: this.props.isDrawerOpen ? '1' : '0', transition: 'opacity 0.2s', width: '45px', marginLeft: '10px' }}
                        onClick={this.handleMoreClick}>
                        <Icons.MoreVert className="moreIcon" />
                    </IconButton>

                    {/* <Fab
                        color="primary"
                        aria-label="add"
                        className="action-button"
                        style={{ marginTop: '30px', height: '40px !important', width: '40px !important' }}
                        size="large"
                        onClick={this.handleMoreClick}>
                        <span className="icon-Settings" style={{ fontSize: '20px' }} />
                    </Fab> */}

                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleMoreClose}
                    >
                        <MenuItem onClick={() => this.onProfileClick()} >
                            <ListItemText disableTypography primary={<Typography style={{ fontSize: '16px' }}>{i18n.t('Profile')}</Typography>} />
                            <span className="icon-user logOutIcon" />
                        </MenuItem>
                        <MenuItem onClick={() => this.onSettingsClick()} >
                            <ListItemText disableTypography primary={<Typography style={{ fontSize: '16px' }}>{i18n.t('Settings')}</Typography>} />
                            <span className="icon-cog logOutIcon" />
                        </MenuItem>
                        <MenuItem onClick={() => this.onLogout()} >
                            <ListItemText disableTypography primary={<Typography style={{ fontSize: '16px' }}>{i18n.t('Logout')}</Typography>} />
                            <span className="icon-LogOut logOutIcon" />
                        </MenuItem>
                    </Menu>

                    {this.state.profile && <Profile open={this.state.profile}
                        handleClose={this.onProfileClick}
                        userProfile={this.props.userProfile} />}
                    {this.state.settingsOpen && <Settings open={this.state.settingsOpen}
                        handleClose={this.onSettingsClick}
                        settings={this.props.settings} />}

                    {this.state.support && <Support open={this.state.support}
                        handleClose={this.onSupportClick} />}

                </div>
            </Paper >
        );
    }
}

function mapStateToProps(state) {
    return {
        isDrawerOpen: state.appReducer.isDrawerOpen,
        isMenuOpen: state.appReducer.isMenuOpen,
        user: state.appReducer.user,
        userProfile: state.appReducer.userProfile,
        clientProfile: state.appReducer.clientProfile
    };
}
function mapDispatchToProps(dispatch) {
    return {
        toggleDrawer: open => dispatch(toggleDrawer(open)),
        toggleMenu: open => dispatch(toggleMenu(open)),
        logout: open => dispatch(logout(open)),
        getUserProfile: () => dispatch(getUserProfile()),
        getClientProfile: (id) => dispatch(getClientProfile(id)),
    };
}
export default withRouter(withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(MenuDrawer)));