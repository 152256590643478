import React from 'react';
import { connect } from 'react-redux';
import i18n from '../i18n/i18n';
import immutable from 'seamless-immutable';
import {
    Dialog, DialogContent, DialogTitle, DialogActions,
    Button, Avatar, TextField, Tab, Tabs, AppBar, Card,
    InputLabel, IconButton
} from '@material-ui/core';
import {
    changePassword, getLanguages,
    editProfile, setInitialState, editProfilePicture
} from '../actions/LoginActions';
import validator from 'validator';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import { CircleLoader } from 'react-spinners';
import Select, { components } from 'react-select';

const IndicatorsContainer = (props) => {
    return (
        <div style={{ background: '#58B4E5' }}>
            <components.IndicatorsContainer {...props} />
        </div>
    );
};


export class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            userProfile: this.props.userProfile,
            password: {},
            languages: []
        };
    }
    toastConf = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        draggablePercent: 80
    }

    componentDidMount() {
        this.props.getLanguages();
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            languages: immutable.asMutable(nextProps.languages.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            userProfile: nextProps.userProfile
        });
        if (nextProps.passwordChange === true) {
            toast.success(i18n.t('You successfully change your password!'), this.toastConf);
            this.props.setInitialState('passwordChange');
            this.setState({ value: 0 });
        }
        if (nextProps.passwordChangeFetchingFailed === true) {
            toast.error(i18n.t('Wrong entered current password!'), this.toastConf);
            this.props.setInitialState('passwordChangeFetchingFailed');
        }

        if (nextProps.profileEdited === true) {
            toast.success(i18n.t('You successfully updated your profile!'), this.toastConf);
            this.props.setInitialState('profileEdited');
            this.setState({ value: 1 });
        }
        if (nextProps.profileEditedFetchingFailed === true) {
            toast.error(i18n.t('Profile updating failed!'), this.toastConf);
            this.props.setInitialState('profileEditedFetchingFailed');
        }
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    handleChangeIndex = index => {
        this.setState({ value: index });
    };

    handleChangeProfileValue = (name) => event => {
        let obj = Object.assign({}, this.state.userProfile);
        obj[name] = event.target.value;
        this.setState({ userProfile: obj });
    };

    handleChangePasswordValue = (name) => event => {
        let obj = Object.assign({}, this.state.password);
        obj[name] = event.target.value;
        this.setState({ password: obj });
    };

    editProfile = () => {
        this.props.editProfile(this.state.userProfile);
    }

    changePassword = () => {
        if (this.state.password &&
            this.state.password.old_password &&
            this.state.password.new_password &&
            this.state.password.new_password1) {
            if (this.state.password.new_password !== this.state.password.new_password1) {
                toast.error(i18n.t('Passwords do not match!'), this.toastConf);
            } else if (!validator.isLength(this.state.password.new_password, { min: 6, max: 20 })) {
                toast.error(i18n.t('Password must contain at least 6 letters and less then 20 letters!'), this.toastConf);
            } else {
                this.props.changePassword(this.state.password.old_password,
                    this.state.password.new_password);
            }
        } else {
            toast.error(i18n.t('Please enter all fields!'), this.toastConf);
        }
    }

    onDropPicture = ({ accepted, rejected }) => {
        if (!!accepted && accepted.length != 0) {
            this.props.editProfilePicture(accepted);
        }
    }

    onChangeLanguage = selectedOption => {
        let obj = Object.assign({}, this.state.userProfile);
        obj['id_jezik'] = selectedOption.value;
        this.setState({ userProfile: obj }, () => {
            this.props.editProfile(this.state.userProfile);
            i18n.changeLanguage(selectedOption.label);
        });

    }

    render() {
        console.log('valueeeee', this.state.value)
        var avatarSrc = require('../assets/images/profileImage.png');
        if (this.props.userProfile.slika) avatarSrc = this.props.userProfile.signedURL;
        var idJezik = this.state.userProfile.jezik && this.state.userProfile.jezik.id || 2;
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                disableBackdropClick
                maxWidth="md"
                aria-labelledby="responsive-dialog-title"
                PaperProps={{ className: "dialogOverlow" }}>

                <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('Profile')}
                    <IconButton onClick={this.props.handleClose}>
                        <span className="icon-cancel-circle closeIcon" />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="dialogOverlow">
                    <Card className="userProfile-img" >
                        <Avatar
                            src={avatarSrc}
                            className="userProfile-avatar"
                        />

                        {!this.props.profilePictureEditedFetching ?
                            <Dropzone
                                accept={['image/jpeg', 'image/png', 'image/bmp', 'image/svg+xml', 'image/gif']}
                                maxSize={5242880}
                                className="dropZone-profile"
                                multiple={false}
                                onDrop={(accepted, rejected) => this.onDropPicture({ accepted, rejected })}
                            >
                                {({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
                                    return <div className="dropZoneDiv">
                                        <span className=" icon-Izmeni1 dropZoneIcon" />
                                    </div>
                                }}
                            </Dropzone>
                            :
                            <CircleLoader
                                color={'#58B4E5'}
                                loading={this.props.profilePictureEditedFetching}
                            />
                        }
                    </Card>
                    <Card style={{ 'margin': '10px' }}>
                        <AppBar position="static" color="default">
                            <Tabs
                                value={this.state.value}
                                onChange={this.handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                fullWidth
                            >
                                <Tab label={i18n.t('Update profile')}
                                    className="tab" />
                                <Tab label={i18n.t('Change password')}
                                    className="tab" />
                            </Tabs>
                        </AppBar>
                        {this.state.value === 0 &&
                            <Card style={{ 'padding': '20px' }}>
                                <TextField
                                    type="email"
                                    required
                                    onChange={this.handleChangeProfileValue('mejl')}
                                    label={i18n.t('Contact email')}
                                    style={{ width: '300px', marginTop: '10px', marginBottom: '10px' }}
                                    value={this.state.userProfile.mejl}
                                />
                                <br />
                                <TextField
                                    required
                                    onChange={this.handleChangeProfileValue('mobilni')}
                                    label={i18n.t('Phone number')}
                                    style={{ width: '300px', marginTop: '10px', marginBottom: '10px' }}
                                    value={this.state.userProfile.mobilni}
                                />
                                <br />
                                <TextField
                                    required
                                    onChange={this.handleChangeProfileValue('skype')}
                                    label={i18n.t('Skype')}
                                    style={{ width: '300px', marginTop: '10px', marginBottom: '10px' }}
                                    value={this.state.userProfile.skype}
                                />
                                <br />
                                <Button onClick={() => this.editProfile()}
                                    style={{ backgroundColor: '#58B4E5', color: 'white' }}
                                >
                                    {i18n.t('Update')}
                                </Button>
                            </Card>}
                        {this.state.value === 1 &&
                            <Card style={{ 'padding': '20px' }} >
                                <TextField
                                    required
                                    type='password'
                                    onChange={this.handleChangePasswordValue('old_password')}
                                    label={i18n.t('Current password')}
                                    style={{ width: '300px', marginTop: '10px', marginBottom: '10px' }}
                                />
                                <br />
                                <TextField
                                    onChange={this.handleChangePasswordValue('new_password')}
                                    required
                                    type='password'
                                    label={i18n.t('New password')}
                                    style={{ width: '300px', marginTop: '10px', marginBottom: '10px' }}
                                />
                                <br />
                                <TextField
                                    onChange={this.handleChangePasswordValue('new_password1')}
                                    required
                                    type='password'
                                    label={i18n.t('Retype new password')}
                                    style={{ width: '300px', marginTop: '10px', marginBottom: '10px' }}
                                />
                                <br />
                                <Button onClick={() => this.changePassword()}
                                    style={{ backgroundColor: '#58B4E5', color: 'white' }}
                                >
                                    {i18n.t('Change')}
                                </Button>
                            </Card>
                        }
                    </Card>
                    <div style={{ marginLeft: '10px', marginBottom: '120px' }}>
                        <InputLabel className="filter-label">{i18n.t('Default language')}</InputLabel>
                        <Select
                            closeMenuOnSelect={true}
                            isClearable={false}
                            components={{
                                IndicatorsContainer: IndicatorsContainer
                            }}
                            value={this.state.languages.find(function (element) {
                                return element.value === idJezik;
                            })}
                            label={i18n.t('Language')}
                            onChange={this.onChangeLanguage}
                            options={this.state.languages}
                            className="multi-select-in-filter-dashboards"
                            classNamePrefix="select"
                            placeholder={i18n.t('Select')}
                        />
                    </div>

                </DialogContent>
            </Dialog>
        )
    }
};

function mapStateToProps(state) {
    return {
        passwordChange: state.appReducer.passwordChange,
        passwordChangeFetchingFailed: state.appReducer.passwordChangeFetchingFailed,
        profileEdited: state.appReducer.profileEdited,
        profilePictureEditedFetching: state.appReducer.profilePictureEditedFetching,
        languages: state.appReducer.languages
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInitialState: (component) => dispatch(setInitialState(component)),
        changePassword: (old_password, new_password) => dispatch(changePassword(old_password, new_password)),
        editProfile: (obj) => dispatch(editProfile(obj)),
        editProfilePicture: (obj) => dispatch(editProfilePicture(obj)),
        getLanguages: () => dispatch(getLanguages()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Profile);