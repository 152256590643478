import axios from '../utils/AxiosWrapper';
export const Type = {
    //ZONES
    GET_ZONES_CALL: 'GET_ZONES_CALL',
    GET_ZONES_SUCCESS: 'GET_ZONES_SUCCESS',
    GET_ZONES_FAILED: 'GET_ZONES_FAILED',

    ADD_ZONAL_OFFICE_CALL: 'ADD_ZONAL_OFFICE_CALL',
    ADD_ZONAL_OFFICE_SUCCESS: 'ADD_ZONAL_OFFICE_SUCCESS',
    ADD_ZONAL_OFFICE_FAILED: 'ADD_ZONAL_OFFICE_FAILED',

    SET_INITIAL_STATE: 'SET_INITIAL_STATE',
    SET_ADMINISTRATION_ROW_IN_REDUCER: 'SET_ADMINISTRATION_ROW_IN_REDUCER',

    GET_ZONAL_OFFICE_CALL: 'GET_ZONAL_OFFICE_CALL',
    GET_ZONAL_OFFICE_SUCCESS: 'GET_ZONAL_OFFICE_SUCCESS',
    GET_ZONAL_OFFICE_FAILED: 'GET_ZONAL_OFFICE_FAILED',

    //STATES
    GET_STATES_CALL: 'GET_STATES_CALL',
    GET_STATES_SUCCESS: 'GET_STATES_SUCCESS',
    GET_STATES_FAILED: 'GET_STATES_FAILED',

    //BRANCHES
    GET_BRANCHES_CALL: 'GET_BRANCHES_CALL',
    GET_BRANCHES_SUCCESS: 'GET_BRANCHES_SUCCESS',
    GET_BRANCHES_FAILED: 'GET_BRANCHES_FAILED',

    ADD_BRANCH_OFFICE_CALL: 'ADD_BRANCH_OFFICE_CALL',
    ADD_BRANCH_OFFICE_SUCCESS: 'ADD_BRANCH_OFFICE_SUCCESS',
    ADD_BRANCH_OFFICE_FAILED: 'ADD_BRANCH_OFFICE_FAILED',

    GET_BRANCH_OFFICE_CALL: 'GET_BRANCH_OFFICE_CALL',
    GET_BRANCH_OFFICE_SUCCESS: 'GET_BRANCH_OFFICE_SUCCESS',
    GET_BRANCH_OFFICE_FAILED: 'GET_BRANCH_OFFICE_FAILED',

    //AGENTS
    ADD_AGENT_CALL: 'ADD_AGENT_CALL',
    ADD_AGENT_SUCCESS: 'ADD_AGENT_SUCCESS',
    ADD_AGENT_FAILED: 'ADD_AGENT_FAILED',

    GET_AGENTS_CALL: 'GET_AGENTS_CALL',
    GET_AGENTS_SUCCESS: 'GET_AGENTS_SUCCESS',
    GET_AGENTS_FAILED: 'GET_AGENTS_FAILED',

    //SOCIETIES
    ADD_SOCIETY_CALL: 'ADD_SOCIETY_CALL',
    ADD_SOCIETY_SUCCESS: 'ADD_SOCIETY_SUCCESS',
    ADD_SOCIETY_FAILED: 'ADD_SOCIETY_FAILED',

    GET_SOCIETIES_CALL: 'GET_SOCIETIES_CALL',
    GET_SOCIETIES_SUCCESS: 'GET_SOCIETIES_SUCCESS',
    GET_SOCIETIES_FAILED: 'GET_SOCIETIES_FAILED',

    GET_FARMERS_BY_SOCIETY_CALL: 'GET_FARMERS_BY_SOCIETY_CALL',
    GET_FARMERS_BY_SOCIETY_SUCCESS: 'GET_FARMERS_BY_SOCIETY_SUCCESS',
    GET_FARMERS_BY_SOCIETY_FAILED: 'GET_FARMERS_BY_SOCIETY_FAILED',

    GET_FARMER_DROPDOWN_CALL: 'GET_FARMER_DROPDOWN_CALL',
    GET_FARMER_DROPDOWN_SUCCESS: 'GET_FARMER_DROPDOWN_SUCCESS',
    GET_FARMER_DROPDOWN_FAILED: 'GET_FARMER_DROPDOWN_FAILED',

    ADD_FARMERS_TO_SOCIETY_CALL: 'ADD_FARMERS_TO_SOCIETY_CALL',
    ADD_FARMERS_TO_SOCIETY_SUCCESS: 'ADD_FARMERS_TO_SOCIETY_SUCCESS',
    ADD_FARMERS_TO_SOCIETY_FAILED: 'ADD_FARMERS_TO_SOCIETY_FAILED',

    DELETE_FARMERS_CALL: 'DELETE_FARMERS_CALL',
    DELETE_FARMERS_SUCCESS: 'DELETE_FARMERS_SUCCESS',
    DELETE_FARMERS_FAILED: 'DELETE_FARMERS_FAILED',
    //FIELD MANAGERS
    ADD_FM_CALL: 'ADD_FM_CALL',
    ADD_FM_SUCCESS: 'ADD_FM_SUCCESS',
    ADD_FM_FAILED: 'ADD_FM_FAILED',

    GET_FMS_CALL: 'GET_FMS_CALL',
    GET_FMS_SUCCESS: 'GET_FMS_SUCCESS',
    GET_FMS_FAILED: 'GET_FMS_FAILED',

    //STATE MANAGERS
    ADD_STATE_MANAGER_CALL: 'ADD_STATE_MANAGER_CALL',
    ADD_STATE_MANAGER_SUCCESS: 'ADD_STATE_MANAGER_SUCCESS',
    ADD_STATE_MANAGER_FAILED: 'ADD_STATE_MANAGER_FAILED',

    GET_STATE_MANAGERS_CALL: 'GET_STATE_MANAGERS_CALL',
    GET_STATE_MANAGERS_SUCCESS: 'GET_STATE_MANAGERS_SUCCESS',
    GET_STATE_MANAGERS_FAILED: 'GET_STATE_MANAGERS_FAILED',

    //ANCHORS

    GET_ANCHORS_CALL: 'GET_ANCHORS_CALL',
    GET_ANCHORS_SUCCESS: 'GET_ANCHORS_SUCCESS',
    GET_ANCHORS_FAILED: 'GET_ANCHORS_FAILED',

    ADD_ANCHORS_CALL: 'ADD_ANCHORS_CALL',
    ADD_ANCHORS_SUCCESS: 'ADD_ANCHORS_SUCCESS',
    ADD_ANCHORS_FAILED: 'ADD_ANCHORS_FAILED',

    SEND_ANCHOR_DOCUMENTS_CALL: 'SEND_ANCHOR_DOCUMENTS_CALL',
    SEND_ANCHOR_DOCUMENTS_SUCCESS: 'SEND_ANCHOR_DOCUMENTS_SUCCESS',
    SEND_ANCHOR_DOCUMENTS_FAILED: 'SEND_ANCHOR_DOCUMENTS_FAILED',

    ANCHOR_DOCUMENTS_CALL: 'ANCHOR_DOCUMENTS_CALL',
    ANCHOR_DOCUMENTS_SUCCESS: 'ANCHOR_DOCUMENTS_SUCCESS',
    ANCHOR_DOCUMENTS_FAILED: 'ANCHOR_DOCUMENTS_FAILED',

    //USERS
    GET_USERS_CALL: 'GET_USERS_CALL',
    GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
    GET_USERS_FAILED: 'GET_USERS_FAILED',

    ADD_USER_CALL: 'ADD_USER_CALL',
    ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
    ADD_USER_FAILED: 'ADD_USER_FAILED',

    DELETE_USER_CALL: 'DELETE_USER_CALL',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_FAILED: 'DELETE_USER_FAILED',
    //ANCHOR-COS
    ADD_AGENT_COS_CALL: 'ADD_AGENT_COS_CALL',
    ADD_AGENT_COS_SUCCESS: 'ADD_AGENT_COS_SUCCESS',
    ADD_AGENT_COS_FAILED: 'ADD_AGENT_COS_FAILED',

    GET_COS_DROPDOWN_CALL: 'GET_COS_DROPDOWN_CALL',
    GET_COS_DROPDOWN_SUCCESS: 'GET_COS_DROPDOWN_SUCCESS',
    GET_COS_DROPDOWN_FAILED: 'GET_COS_DROPDOWN_FAILED',

    GET_ANCHOR_DROPDOWN_CALL: 'GET_ANCHOR_DROPDOWN_CALL',
    GET_ANCHOR_DROPDOWN_SUCCESS: 'GET_ANCHOR_DROPDOWN_SUCCESS',
    GET_ANCHOR_DROPDOWN_FAILED: 'GET_ANCHOR_DROPDOWN_FAILED',

    GET_COS_BY_AGENT_CALL: 'GET_COS_BY_AGENT_CALL',
    GET_COS_BY_AGENT_SUCCESS: 'GET_COS_BY_AGENT_SUCCESS',
    GET_COS_BY_AGENT_FAILED: 'GET_COS_BY_AGENT_FAILED',

    DELETE_COS_AGENT_CALL: 'DELETE_COS_AGENT_CALL',
    DELETE_COS_AGENT_SUCCESS: 'DELETE_COS_AGENT_SUCCESS',
    DELETE_COS_AGENT_FAILED: 'DELETE_COS_AGENT_FAILED',
    //ANCHOR-AGENT
    DELETE_AGENT_ANCHOR_CALL: 'DELETE_AGENT_ANCHOR_CALL',
    DELETE_AGENT_ANCHOR_SUCCESS: 'DELETE_AGENT_ANCHOR_SUCCESS',
    DELETE_AGENT_ANCHOR_FAILED: 'DELETE_AGENT_ANCHOR_FAILED',

    GET_ANCHOR_AGENT_CALL: 'GET_ANCHOR_AGENT_CALL',
    GET_ANCHOR_AGENT_SUCCESS: 'GET_ANCHOR_AGENT_SUCCESS',
    GET_ANCHOR_AGENT_FAILED: 'GET_ANCHOR_AGENT_FAILED',


    GET_ANCHOR_AGENT_DROPDOWN_CALL: 'GET_ANCHOR_AGENT_DROPDOWN_CALL',
    GET_ANCHOR_AGENT_DROPDOWN_SUCCESS: 'GET_ANCHOR_AGENT_DROPDOWN_SUCCESS',
    GET_ANCHOR_AGENT_DROPDOWN_FAILED: 'GET_ANCHOR_AGENT_DROPDOWN_FAILED',


    GET_AGENT_DROPDOWN_CALL: 'GET_AGENT_DROPDOWN_CALL',
    GET_AGENT_DROPDOWN_SUCCESS: 'GET_AGENT_DROPDOWN_SUCCESS',
    GET_AGENT_DROPDOWN_FAILED: 'GET_AGENT_DROPDOWN_FAILED',

    ADD_ANCHOR_AGENT_CALL: 'ADD_ANCHOR_AGENT_CALL',
    ADD_ANCHOR_AGENT_SUCCESS: 'ADD_ANCHOR_AGENT_SUCCESS',
    ADD_ANCHOR_AGENT_FAILED: 'ADD_ANCHOR_AGENT_FAILED',

    GET_REGISTRATION_COUNT_CALL: 'GET_REGISTRATION_COUNT_CALL',
    GET_REGISTRATION_COUNT_SUCCESS: 'GET_REGISTRATION_COUNT_SUCCESS',
    GET_REGISTRATION_COUNT_FAILED: 'GET_REGISTRATION_COUNT_FAILED',

    //EXECUTIVE
    ADD_EXECUTIVE_CALL: 'ADD_EXECUTIVE_CALL',
    ADD_EXECUTIVE_SUCCESS: 'ADD_EXECUTIVE_SUCCESS',
    ADD_EXECUTIVE_FAILED: 'ADD_EXECUTIVE_FAILED',

    GET_EXECUTIVE_CALL: 'GET_EXECUTIVE_CALL',
    GET_EXECUTIVE_SUCCESS: 'GET_EXECUTIVE_SUCCESS',
    GET_EXECUTIVE_FAILED: 'GET_EXECUTIVE_FAILED',
};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function setRowInReducer(rowInfo, name) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_ADMINISTRATION_ROW_IN_REDUCER,
            rowInfo: rowInfo,
            name: name
        })
    }
}

export function getRegistrationCount() {
    return (dispatch) => {
        dispatch({
            type: Type.GET_REGISTRATION_COUNT_CALL,
        });

        return axios.get('api/reportSuperAdmin/registracija')
            .then(function (response) {
                dispatch({
                    type: Type.GET_REGISTRATION_COUNT_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_REGISTRATION_COUNT_FAILED
                });
            });
    }
}

export function getZones() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_ZONES_CALL
        });

        axios.get('api/zone/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_ZONES_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_ZONES_FAILED
                });
            });
    }
}

export function getStates() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_STATES_CALL
        });

        axios.get('api/opstina/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_STATES_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_STATES_FAILED
                });
            });
    }
}

export function getBranches() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_BRANCHES_CALL
        });

        axios.get('api/branch/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_BRANCHES_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_BRANCHES_FAILED
                });
            });
    }
}

export function getBranchesByState(id_state) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_BRANCHES_CALL
        });

        axios.get(`api/branch/drop_down${id_state ? '?id_opstina=' + id_state : ''}`)
            .then(function (response) {
                dispatch({
                    type: Type.GET_BRANCHES_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_BRANCHES_FAILED
                });
            });
    }
}

export function addZonalOffice(zonalObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_ZONAL_OFFICE_CALL
        });

        axios.post('api/financeAdministration/create', {
            data: zonalObj
        })
            .then(function (response) {
                dispatch({
                    type: Type.ADD_ZONAL_OFFICE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_ZONAL_OFFICE_FAILED
                });
            });
    }
}

export function getZonalOffices(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_ZONAL_OFFICE_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/financeAdministration/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_ZONAL_OFFICE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_ZONAL_OFFICE_FAILED
                });
            });
    }
}

export function addBranchOffice(branchObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_BRANCH_OFFICE_CALL
        });

        axios.post('api/financeAdministration/create_geo', {
            data: branchObj
        })
            .then(function (response) {
                dispatch({
                    type: Type.ADD_BRANCH_OFFICE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_BRANCH_OFFICE_FAILED
                });
            });
    }
}

export function getBranchOffices(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_BRANCH_OFFICE_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/financeAdministration/read_geo', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_BRANCH_OFFICE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_BRANCH_OFFICE_FAILED
                });
            });
    }
}

export function addStateManager(stateObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_STATE_MANAGER_CALL
        });

        axios.post('api/financeAdministration/create_geo', {
            data: stateObj
        })
            .then(function (response) {
                dispatch({
                    type: Type.ADD_STATE_MANAGER_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_STATE_MANAGER_FAILED
                });
            });
    }
}

export function getStateManagers(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_STATE_MANAGERS_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/financeAdministration/read_geo', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_STATE_MANAGERS_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_STATE_MANAGERS_FAILED
                });
            });
    }
}

export function addAgent(agentObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_AGENT_CALL
        });

        axios.post('api/financeAdministration/create', {
            data: agentObj
        })
            .then(function (response) {
                dispatch({
                    type: Type.ADD_AGENT_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_AGENT_FAILED
                });
            });
    }
}

export function getAgents(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_AGENTS_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/financeAdministration/read_agents', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_AGENTS_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_AGENTS_FAILED
                });
            });
    }
}


export function addFieldManager(fmObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_FM_CALL
        });

        axios.post('api/financeAdministration/create', {
            data: fmObj
        })
            .then(function (response) {
                dispatch({
                    type: Type.ADD_FM_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_FM_FAILED
                });
            });
    }
}

export function getFieldManagers(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_FMS_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/financeAdministration/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_FMS_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_FMS_FAILED
                });
            });
    }
}

export function addUser(userObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_USER_CALL
        });

        axios.post('api/korisnik/create',
            userObj
        )
            .then(function (response) {
                dispatch({
                    type: Type.ADD_USER_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_USER_FAILED
                });
            });
    }
}

export function getUsers(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_USERS_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/korisnik/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_USERS_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_USERS_FAILED
                });
            });
    }
}

/* export function addAnchor(anchorObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_ANCHORS_CALL
        });

        axios.post('api/financeAdministration/create_anchor', {
            data: anchorObj
        })
            .then(function (response) {
                dispatch({
                    type: Type.ADD_ANCHORS_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_ANCHORS_FAILED
                });
            });
    }
} */

export function addAnchor(anchorObj, acceptedDocuments) {
    return (dispatch) => {
        dispatch({
            type: Type.ADD_ANCHORS_CALL
        });

        axios.post('api/financeAdministration/create_anchor', { data: anchorObj })
            .then(function (response) {
                if (!!acceptedDocuments && acceptedDocuments.length != 0) {
                    var data = new FormData();

                    acceptedDocuments.map(doc => {
                        data.append('id_anchor', response.data.data.id_klijent);
                        data.append('document', doc);
                    })

                    axios({
                        url: `api/financeAdministration/upload_document`,
                        method: 'POST',
                        onUploadProgress: function (progressEvent) {
                            console.log('wwww', Math.round((progressEvent.loaded * 100) / progressEvent.total));
                        },
                        data: data,
                        headers: { 'Content-Type': 'multipart/form-data' }
                    }).then((resp) => {
                        dispatch({
                            type: Type.SEND_ANCHOR_DOCUMENTS_SUCCESS
                        });
                        dispatch({
                            type: Type.ADD_ANCHORS_SUCCESS,
                            data: response.data
                        });
                    }).catch(function (error) {
                        dispatch({
                            type: Type.SEND_ANCHOR_DOCUMENTS_FAILED
                        })
                    });
                } else {
                    dispatch({
                        type: Type.SEND_ANCHOR_DOCUMENTS_SUCCESS
                    });
                    dispatch({
                        type: Type.ADD_ANCHORS_SUCCESS,
                        data: response.data
                    });
                }

            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_ANCHORS_FAILED
                });
            });
    }
}

export function getAnchorDocuments(id_klijent) {
    return (dispatch) => {

        dispatch({
            type: Type.ANCHOR_DOCUMENTS_CALL,
        });

        axios.get(`api/financeAdministration/document?id_anchor=${id_klijent}`)
            .then(function (response) {
                dispatch({
                    type: Type.ANCHOR_DOCUMENTS_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ANCHOR_DOCUMENTS_FAILED
                });
            });
    }
}

export function getAnchors(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_ANCHORS_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/financeAdministration/read_branch_anchor', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_ANCHORS_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_ANCHORS_FAILED
                });
            });
    }
}

export function addSociety(societyObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_SOCIETY_CALL
        });

        axios.post('api/financeAdministration/create_farmers_cooperative', {
            data: societyObj
        })
            .then(function (response) {
                dispatch({
                    type: Type.ADD_SOCIETY_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_SOCIETY_FAILED
                });
            });
    }
}

export function getSocieties(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_SOCIETIES_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/financeAdministration/read_branch_farmers_cooperative', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_SOCIETIES_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_SOCIETIES_FAILED
                });
            });
    }
}

export function getFarmersBySociety(state, instance, id_klijent) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_FARMERS_BY_SOCIETY_CALL
        });
        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered,
                id_klijent
            }
        };
        axios.get('api/financeAdministration/read_farmers_by_society', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_FARMERS_BY_SOCIETY_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_FARMERS_BY_SOCIETY_FAILED
                });
            });
    }
}

export function getFarmerDropdown(id_katastarska_opstina) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_FARMER_DROPDOWN_CALL
        });

        axios.get('api/financeAdministration/drop_down_farmers_cooperation?id_katastarska_opstina=' + id_katastarska_opstina)
            .then(function (response) {
                dispatch({
                    type: Type.GET_FARMER_DROPDOWN_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_FARMER_DROPDOWN_FAILED
                });
            });
    }

}


export function addFarmersToSociety(id_klijent_society, selected) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_FARMERS_TO_SOCIETY_CALL
        });
        axios.post('api/financeAdministration/create_farmers_cooperation/farmers', { id_klijent_society, selected })
            .then(function (response) {
                dispatch({
                    type: Type.ADD_FARMERS_TO_SOCIETY_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_FARMERS_TO_SOCIETY_FAILED
                });
            });
    }
}

export function deleteFarmersFromSociety(selection) {
    return (dispatch) => {

        dispatch({
            type: Type.DELETE_FARMERS_CALL,
        });

        axios.post(`api/financeAdministration/delete_farmers_cooperation`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_FARMERS_SUCCESS,
                    data: response.data,
                    forDelete: selection
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_FARMERS_FAILED
                });
            });
    }
}

export function addAgentCos(id_klijent1, selected) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_AGENT_COS_CALL
        });
        axios.post('api/financeAdministration/create_agent_cos', { id_klijent1, selected })
            .then(function (response) {
                dispatch({
                    type: Type.ADD_AGENT_COS_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_AGENT_COS_FAILED
                });
            });
    }
}

export function getCosDropdown(id_katastarska_opstina, id_anchor) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_COS_DROPDOWN_CALL
        });

        axios.get('api/financeAdministration/drop_down_cooperation?id_katastarska_opstina=' + id_katastarska_opstina + '&id_anchor=' + id_anchor)
            .then(function (response) {
                dispatch({
                    type: Type.GET_COS_DROPDOWN_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_COS_DROPDOWN_FAILED
                });
            });
    }

}
export function getAnchorDropdown(id_katastarska_opstina) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_ANCHOR_DROPDOWN_CALL
        });

        axios.get('api/financeAdministration/drop_down_anchor?id_katastarska_opstina=' + id_katastarska_opstina)
            .then(function (response) {
                dispatch({
                    type: Type.GET_ANCHOR_DROPDOWN_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_ANCHOR_DROPDOWN_FAILED
                });
            });
    }

}


export function getCosByAgent(state, instance, id_klijent) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_COS_BY_AGENT_CALL
        });
        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered,
                id_klijent
            }
        };
        axios.get('api/financeAdministration/read_cos_by_agent', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_COS_BY_AGENT_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_COS_BY_AGENT_FAILED
                });
            });
    }
}

export function deleteCosAgent(selection) {
    return (dispatch) => {

        dispatch({
            type: Type.DELETE_COS_AGENT_CALL,
        });

        axios.post(`api/financeAdministration/delete_cos_agent`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_COS_AGENT_SUCCESS,
                    data: response.data,
                    forDelete: selection
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_COS_AGENT_FAILED
                });
            });
    }
}

///////
export function addAnchorAgent(id_klijent1, selected) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_ANCHOR_AGENT_CALL
        });
        axios.post('api/financeAdministration/create_anchor_agent', { id_klijent1, selected })
            .then(function (response) {
                dispatch({
                    type: Type.ADD_ANCHOR_AGENT_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_ANCHOR_AGENT_FAILED
                });
            });
    }
}

export function getAgentDropdown(id_katastarska_opstina) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_AGENT_DROPDOWN_CALL
        });

        axios.get('api/financeAdministration/drop_down_agent?id_katastarska_opstina=' + id_katastarska_opstina)
            .then(function (response) {
                dispatch({
                    type: Type.GET_AGENT_DROPDOWN_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_AGENT_DROPDOWN_FAILED
                });
            });
    }

}

export function getAnchorAgent(state, instance, id_klijent) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_ANCHOR_AGENT_CALL
        });
        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered,
                id_klijent
            }
        };
        axios.get('api/financeAdministration/read_anchor_agent', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_ANCHOR_AGENT_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_ANCHOR_AGENT_FAILED
                });
            });
    }
}

export function deleteAgentAnchor(selection) {
    return (dispatch) => {

        dispatch({
            type: Type.DELETE_AGENT_ANCHOR_CALL,
        });

        axios.post(`api/financeAdministration/delete_agent_anchor`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_AGENT_ANCHOR_SUCCESS,
                    data: response.data,
                    forDelete: selection
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_AGENT_ANCHOR_FAILED
                });
            });
    }
}

export function getAgentByAnchor(id_anchor) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_ANCHOR_AGENT_DROPDOWN_CALL
        });

        axios.get('api/financeAdministration/drop_down_agent_by_anchor?id_anchor=' + id_anchor)
            .then(function (response) {
                dispatch({
                    type: Type.GET_ANCHOR_AGENT_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_ANCHOR_AGENT_DROPDOWN_FAILED
                });
            });
    }
}

export function addExecutives(executivesObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_EXECUTIVE_CALL
        });

        return axios.post('api/financeAdministration/create_executive', { data: executivesObj })
            .then(function (response) {
                dispatch({
                    type: Type.ADD_EXECUTIVE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_EXECUTIVE_FAILED
                });
            });
    }
}

export function getExecutives(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_EXECUTIVE_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered,
            }
        };
        return axios.get('api/financeAdministration/read_executives', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_EXECUTIVE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_EXECUTIVE_FAILED
                });
            });
    }
}



