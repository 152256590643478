import immutable from 'seamless-immutable';
import { Type as CropType } from '../../actions/resources/CropActions';
import { stat } from 'fs';


export const INITIAL_STATE = immutable({
    //kulture
    cropPosting: false,
    cropPostingSuccess: false,
    cropPostingFailed: false,
    crop: [],
    cropsFetching: false,
    cropsFetchingFailed: false,
    cropsPages: 1,
    cropObj: {},
    //dropdown
    cropGroupDropdown: [],
    groupFetching: false,
    groupFetchingFailed: false,
    cropSubgroupDropdown: [],
    subgroupFetching: false,
    subgroupFetchingFailed: false,
    crops: [],
    cropFetching: false,
    cropFetchingFailed: false,
    //grupe
    crop_group: [],
    cropGroupObj: {},
    cropGroupsFetching: false,
    cropGroupsFetchingFailed: false,
    cropGroupPages: 1,
    cropGroupPosting: false,
    cropGroupPostingSuccess: false,
    cropGroupPostingFailed: false,
    //podgrupe
    crop_subgroup: [],
    cropSubgroupObj: {},
    cropSubgroupsFetching: false,
    cropSubgroupsFetchingFailed: false,
    cropSubgroupsPages: 1,
    cropSubgroupsPosting: false,
    cropSubgroupsPostingSuccess: false,
    cropSubgroupsPostingFailed: false,

    deleted: false,
    deleteCropFailed: false,

    //kat_kulture
    kat_kulture: [],
    kat_kultureFetching: false,
    kat_kultureFetchingFailed: false,

});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case CropType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;


        case CropType.GET_CROP_CALL: {
            const cropFetching = true;
            return state.merge({ cropFetching });
            break;
        }

        case CropType.GET_CROP_SUCCESS: {
            const crops = action.data.data;
            const cropFetching = false;
            return state.merge({ crops, cropFetching });
            break;
        }

        case CropType.GET_CROP_FAILED: {
            const cropFetchingFailed = true;
            const cropFetching = false;
            return state.merge({ cropFetchingFailed, cropFetching });
            break;
        }

        case CropType.GET_KAT_KULTURA_CALL: {
            const kat_kultureFetching = true;
            return state.merge({ kat_kultureFetching });
            break;
        }

        case CropType.GET_KAT_KULTURA_SUCCESS: {
            const kat_kulture = action.data.data;
            const kat_kultureFetching = false;
            return state.merge({ kat_kulture, kat_kultureFetching });
            break;
        }

        case CropType.GET_KAT_KULTURA_FAILED: {
            const kat_kultureFetchingFailed = true;
            const kat_kultureFetching = false;
            return state.merge({ kat_kultureFetchingFailed, kat_kultureFetching });
            break;
        }


        case CropType.GET_CROP_GROUP_DROPDOWN_CALL: {
            const groupFetching = true;
            return state.merge({ groupFetching });
            break;
        }

        case CropType.GET_CROP_GROUP_DROPDOWN_SUCCESS: {
            const cropGroupDropdown = action.data.data;
            const groupFetching = false;
            return state.merge({ cropGroupDropdown, groupFetching });
            break;
        }

        case CropType.GET_CROP_GROUP_DROPDOWN_FAILED: {
            const groupFetchingFailed = true;
            const groupFetching = false;
            return state.merge({ groupFetchingFailed, groupFetching });
            break;
        }

        case CropType.GET_CROP_SUBGROUP_DROPDOWN_CALL: {
            const subgroupFetching = true;
            return state.merge({ subgroupFetching });
            break;
        }

        case CropType.GET_CROP_SUBGROUP_DROPDOWN_SUCCESS: {
            const cropSubgroupDropdown = action.data.data;
            const subgroupFetching = false;
            return state.merge({ cropSubgroupDropdown, subgroupFetching });
            break;
        }

        case CropType.GET_CROP_SUBGROUP_DROPDOWN_FAILED: {
            const subgroupFetchingFailed = true;
            const subgroupFetching = false;
            return state.merge({ subgroupFetchingFailed, subgroupFetching });
            break;
        }

        case CropType.ADD_CROP_CALL: {
            const cropPosting = true;
            return state.merge({ cropPosting });
            break;
        }

        case CropType.ADD_CROP_SUCCESS: {
            const cropPosting = false;
            const cropPostingSuccess = true;
            return state.merge({ cropPostingSuccess: cropPostingSuccess, cropPosting, cropObj: INITIAL_STATE.cropObj })
            break;
        }

        case CropType.ADD_CROP_FAILED: {
            const cropPosting = false;
            const cropPostingFailed = true;
            return state.merge({ cropPosting, cropPostingFailed });
            break;
        }

        case CropType.GET_VIEW_CROP_CALL: {
            const cropsFetching = true;
            return state.merge({ cropsFetching });
            break;
        }

        case CropType.GET_VIEW_CROP_SUCCESS: {
            const crop = action.data.data;
            const cropsFetching = false;
            const cropsPages = action.data.total_pages
            return state.merge({ crop, cropsFetching, cropsPages });
            break;
        }

        case CropType.GET_VIEW_CROP_FAILED: {
            const cropsFetchingFailed = true;
            const cropsFetching = false;
            return state.merge({ cropsFetching, cropsFetchingFailed });
            break;
        }


        case CropType.GET_CROP_GROUP_CALL: {
            const cropGroupsFetching = true;
            return state.merge({ cropGroupsFetching });
            break;
        }

        case CropType.GET_CROP_GROUP_SUCCESS: {
            const crop_group = action.data.data;
            const cropGroupsFetching = false;
            const cropGroupPages = action.data.total_pages
            return state.merge({ crop_group, cropGroupsFetching, cropGroupPages });
            break;
        }

        case CropType.GET_CROP_GROUP_FAILED: {
            const cropGroupsFetchingFailed = true;
            const cropGroupsFetching = false;
            return state.merge({ cropGroupsFetching, cropGroupsFetchingFailed });
            break;
        }

        case CropType.ADD_CROP_GROUP_CALL: {
            const cropGroupPosting = true;
            return state.merge({ cropGroupPosting });
            break;
        }

        case CropType.ADD_CROP_GROUP_SUCCESS: {
            const cropGroupPosting = false;
            const cropGroupPostingSuccess = true;
            return state.merge({ cropGroupPostingSuccess, cropGroupPosting, cropGroupObj: INITIAL_STATE.cropGroupObj })
            break;
        }

        case CropType.ADD_CROP_GROUP_FAILED: {
            const cropGroupPosting = false;
            const cropGroupPostingFailed = true;
            return state.merge({ cropGroupPosting, cropGroupPostingFailed });
            break;
        }

        case CropType.GET_CROP_SUBGROUP_CALL: {
            const cropSubgroupsFetching = true;
            return state.merge({ cropSubgroupsFetching });
            break;
        }

        case CropType.GET_CROP_SUBGROUP_SUCCESS: {
            const crop_subgroup = action.data.data;
            const cropSubgroupsFetching = false;
            const cropSubgroupsPages = action.data.total_pages
            return state.merge({ crop_subgroup, cropSubgroupsFetching, cropSubgroupsPages });
            break;
        }

        case CropType.GET_CROP_SUBGROUP_FAILED: {
            const cropSubgroupsFetchingFailed = true;
            const cropSubgroupsFetching = false;
            return state.merge({ cropSubgroupsFetching, cropSubgroupsFetchingFailed });
            break;
        }
        case CropType.ADD_CROP_SUBGROUP_CALL: {
            const cropSubgroupsPosting = true;
            return state.merge({ cropSubgroupsPosting });
            break;
        }

        case CropType.ADD_CROP_SUBGROUP_SUCCESS: {
            const cropSubgroupsPosting = false;
            const cropSubgroupsPostingSuccess = true;
            return state.merge({ cropSubgroupsPostingSuccess, cropSubgroupsPosting, cropSubgroupObj: INITIAL_STATE.cropSubgroupObj })
            break;
        }

        case CropType.ADD_CROP_SUBGROUP_FAILED: {
            const cropSubgroupsPosting = false;
            const cropSubgroupsPostingFailed = true;
            return state.merge({ cropSubgroupsPosting, cropSubgroupsPostingFailed });
            break;
        }

        case CropType.SET_CROP_ROW_IN_REDUCER: {
            const data = action.rowInfo.asMutable();
            return state.merge({ [action.name]: data })
            break;
        }

        case CropType.DELETE_CROP_SUCCESS: {
            const array = state[action.name].asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ [action.name]: array, deleted: deleted })
            break;
        }

        case CropType.DELETE_CROP_FAILED: {
            const deleteCropFailed = true;
            return state.merge({ deleteCropFailed });
            break;
        }

        default:
            return state;
    }
}