import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import 'react-table/react-table.css';
import {
    getFarmOffices, getLocalGov, getTitle, getMaritalStatus, getAnchoredUsers, verifyBVN,
    getIdentificationDoc, setInitialState, setRowInReducer, addAnchorForFarmer, deleteAnchor
} from '../../actions/FarmRegistarActions';
import { getStates, getBranches, getAgentDropdown, getAnchorDropdown } from '../../actions/AdministrationActions';
import i18n from '../../i18n/i18n';
import Select from 'react-select';
import immutable from 'seamless-immutable';
import { withRouter } from 'react-router-dom'
import {
    Fab, TextField, Grid, IconButton, Typography,
    Dialog, DialogContent, DialogTitle
} from '@material-ui/core';
import AddFarmers from './AddFarmers';
import FarmersInformations from './FarmersInformations';
import DatePickerForFilter from '../../utils/DatePickerForFilter';
import { exportDocument } from '../../actions/ExportActions';
import Export from '../../utils/Export';
import ProfilePreview from '../../utils/ProfilePreview';
import DashboardDrawer from '../../utils/DashboardDrawer';
import moment from 'moment';
import * as Icons from '@material-ui/icons';
import { getUser } from '../../utils/CommonFunctions';
import { withStyles } from '@material-ui/core/styles';
const CheckboxTable = checkboxHOC(ReactTable);
const styles = theme => ({
    primaryMain: {
        backgroundColor: theme.palette.primary.main,
    },
    center: {
        textAlign: 'center'
    },
    starButton: {
        padding: '3px',
    },
    card: {
        height: 500,
        width: 600,
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column'
    }
});

export class ViewFarmers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            add: false,
            selection: [],
            selectAll: false,
            profile: false,
            farmers: [],
            deleteAnchor: false,
            user: {},
            editActive: false,
            verifyBVNPopup: false
        };
        this.tableScrollTop = 0;
    }

    componentDidMount() {
        let genders = [
            { label: i18n.t('Male'), value: i18n.t('Male') },
            { label: i18n.t('Female'), value: i18n.t('Female') }
        ]
        this.props.getStates();
        this.props.getLocalGov();
        this.props.getTitle();
        this.props.getMaritalStatus();
        this.props.getIdentificationDoc();
        this.props.getBranches();
        this.props.getAgentDropdown();
        this.props.getAnchorDropdown();
        this.setState({
            user: getUser(),
            genders
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.farmPostingSuccess === true) {
            this.setState({
                add: false
            })
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getFarmOffices(wrapped.state, null, window.location.pathname === '/farmer_blacklisted');
            this.props.setInitialState('farmPostingSuccess');
        }
        this.setState({
            farmers: nextProps.farmers,
            farmersFetching: nextProps.farmersFetching,
            states: immutable.asMutable(nextProps.states.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            title: immutable.asMutable(nextProps.title.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            maritalStatus: immutable.asMutable(nextProps.maritalStatus.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            identificationDoc: immutable.asMutable(nextProps.identificationDoc.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            localGoverments: immutable.asMutable(nextProps.localGoverments.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            branches: immutable.asMutable(nextProps.branches.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            anchorDropdown: immutable.asMutable(nextProps.anchorDropdown.map((item) => { return { label: item.kontakt_osoba, value: item.id } }), { deep: true }),
            agentDropdown: immutable.asMutable(nextProps.agentDropdown.map((item) => { return { label: item.kontakt_osoba, value: item.id } }), { deep: true }),
            statesFetching: nextProps.statesFetching,
            statesFetchingFailed: nextProps.statesFetchingFailed,
        })
        if (nextProps.getAnchorForFarmerSuccess === true) {
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getFarmOffices(wrapped.state, null, window.location.pathname === '/farmer_blacklisted');
            this.props.setInitialState('getAnchorForFarmerSuccess')
        }
        if (nextProps.deleteAnchorSuccess === true) {
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getFarmOffices(wrapped.state, null, window.location.pathname === '/farmer_blacklisted');
            this.props.setInitialState('deleteAnchorSuccess')

        }
    }

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    handleClick = () => {
        this.setState({ add: !this.state.add }, () => {
            this.props.setInitialState('farmerObj');
            this.setState({
                selection: [],
                name: '',
                selectAll: false,
            })
        });
    };

    onExportClick = () => {
        this.setState({ export: !this.state.export });
    }

    exportFunction = () => {
        const table = this.checkboxTable.getWrappedInstance();
        this.props.exportDocument(this.state.docTitle, table.state, 'api/farmRegistar/read', this.state.docFormat || 'xlsx');
        this.setState({ export: !this.state.export });
    }


    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
            this.props.setInitialState('farmerObj');
        } else {
            selection.push(key);
        }
        this.setState({ selection }, function () {
            if (selection.length == 1) {
                let row1 = this.props.farmers.filter((farm) => {
                    return farm.id == selection;
                })
                this.props.setRowInReducer(row1[0], 'farmerObj');
            }
        });
    };
    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });
    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    onProfileClick = () => {
        this.setState({ profile: !this.state.profile });
    }

    onPieClick = () => {
        this.props.history.push('/farmer_dashboard')
    }

    onTableClick = () => {
        this.props.history.push('/farmer');
    }


    handleChangeDropdown = selectedOption => {
        this.setState({ usersSelected: selectedOption });
    }

    onEditClick = () => {
        this.setState({ editActive: !this.state.editActive });
        const wrapped = this.checkboxTable.getWrappedInstance();
        this.props.getFarmOffices(wrapped.state, null, window.location.pathname === '/farmer_blacklisted');
    }

    onVerifyBVN = () => {
        const { farmerObj, verifyBVN, setInitialState, verifyBVNFailed } = this.props;
        const { verifyBVNPopup } = this.state;
        verifyBVN(farmerObj.bpg)
        this.setState({ verifyBVNPopup: !verifyBVNPopup }, function () {
            if (verifyBVNPopup === false && !verifyBVNFailed) {
                setInitialState('bvnImage');
            }
            if (verifyBVNPopup === false && verifyBVNFailed === true) {
                setInitialState('verifyBVNFailed');
            }
        });
    }

    addAnchorForFarmer = (row) => {
        this.props.addAnchorForFarmer(row.original.id);
    }

    renderImage = () => {
        const { farmerImage } = this.props;
        if (farmerImage === 'none') {
            return null;
        } else {
            return <img src={`data:image/jpeg;charset=utf-8;base64,${farmerImage}`} height="450" width="350" style={{ padding: '10px' }} />
        }
    }

    render() {
        const { theme, classes, bvnImage, verifyBVNSuccess, verifyBVNFailed, verifyBVNFetching, farmerObj } = this.props;
        const { selectAll, user, editActive } = this.state;
        const { toggleSelection, toggleAll, isSelected } = this;
        const columns = [
            // {
            //     Header: i18n.t('Add'),
            //     accessor: "favorit",
            //     filterable: false,
            //     width: 60,
            //     show: user.id_klijent_grupa == 14 ? true : false,
            //     Cell: (row) => {
            //         return (
            //             <div className={classes.center}>
            //                 {/* <Tooltip title={row.value ? i18n.t('Remove from active') : i18n.t('Add')}> */}
            //                 <IconButton
            //                     className={classes.starButton}
            //                     style={{ color: row.value ? '#f2ce3e' : 'gray' }}
            //                     onClick={() => this.addAnchorForFarmer(row)}>
            //                     {
            //                         row.value ? <Icons.GradeRounded /> :
            //                             <Icons.GradeOutlined />
            //                     }
            //                 </IconButton>
            //                 {/* </Tooltip> */}
            //             </div >
            //         );
            //     }
            // },
            // {
            //     Header: i18n.t('Farmer ID'),
            //     accessor: 'id',
            //     className: 'tr-head-50',
            //     headerClassName: 'tr-head-50'
            // },
            {
                Header: i18n.t('Title'),
                accessor: 'titula_naziv',
                className: 'tr-head-50',
                headerClassName: 'tr-head-50',
                show: user.id_region == 13 ? true : false
            },
            {
                Header: i18n.t('Contact person'),
                accessor: 'kontakt_osoba',
                className: 'tr-head-150',
                headerClassName: 'tr-head-150'
            },
            {
                Header: i18n.t('Phone'),
                accessor: 'kontakt_telefon'
            },
            {
                Header: i18n.t('Address'),
                accessor: 'ulica_i_broj'
            },
            {
                Header: i18n.t('Email'),
                accessor: 'kontakt_mejl'
            },
            {
                Header: i18n.t('State'),
                accessor: 'opstina_naziv',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.states}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            // {
            //     Header: i18n.t('Local government'),
            //     accessor: 'katastarska_opstina_naziv',
            //     Filter: ({ filter, onChange }) =>
            //         <Select
            //             isMulti
            //             name="naziv"
            //             onChange={value => {
            //                 onChange(value)
            //             }}
            //             closeMenuOnSelect={false}
            //             options={this.state.localGoverments}
            //             className="multi-select-in-filter"
            //             classNamePrefix="select"
            //             placeholder={i18n.t('Select')}
            //         />
            // },
            {
                Header: i18n.t('Marital status'),
                accessor: 'bracni_status_naziv',
                show: user.id_region == 13 ? true : false,
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.maritalStatus}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Branch'),
                accessor: 'naziv_branch',
                show: user.id_region == 13 ? true : false,
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.branches}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            // {
            //     Header: i18n.t('Anchor'),
            //     accessor: 'anchor_naziv',
            //     show: (user.id_klijent_grupa == 14 ? false : true || user.id_region == 2 ? false : true),
            //     Filter: ({ filter, onChange }) =>
            //         <Select
            //             isMulti
            //             name="naziv"
            //             onChange={value => {
            //                 onChange(value)
            //             }}
            //             closeMenuOnSelect={false}
            //             options={this.state.anchorDropdown}
            //             className="multi-select-in-filter"
            //             classNamePrefix="select"
            //             placeholder={i18n.t('Select')}
            //         />
            // },
            // {
            //     Header: i18n.t('Agent'),
            //     accessor: 'agent_naziv',
            //     show: user.id_region == 2 ? false : true,
            //     Filter: ({ filter, onChange }) =>
            //         <Select
            //             isMulti
            //             name="naziv"
            //             onChange={value => {
            //                 onChange(value)
            //             }}
            //             closeMenuOnSelect={false}
            //             options={this.state.agentDropdown}
            //             className="multi-select-in-filter"
            //             classNamePrefix="select"
            //             placeholder={i18n.t('Select')}
            //         />
            // },
            // {
            //     Header: i18n.t('Cluster'),
            //     accessor: 'naziv_cos',
            //     show: user.id_region == 2 ? false : true,
            // },
            // {
            //     Header: i18n.t('Identification document'),
            //     accessor: 'identifikacioni_dokument_naziv',
            //     show: user.id_region == 2 ? false : true,
            //     Filter: ({ filter, onChange }) =>
            //         <Select
            //             isMulti
            //             name="naziv"
            //             onChange={value => {
            //                 onChange(value)
            //             }}
            //             closeMenuOnSelect={false}
            //             options={this.state.identificationDoc}
            //             className="multi-select-in-filter"
            //             classNamePrefix="select"
            //             placeholder={i18n.t('Select')}
            //         />
            // },
            // {
            //     Header: i18n.t('ID number'),
            //     accessor: 'identifikacioni_broj',
            //     show: user.id_region == 2 ? false : true,
            // },
            // {
            //     Header: i18n.t('Place of birth'),
            //     accessor: 'mesto_rodjenja_naziv',
            //     show: user.id_region == 13,
            //     Filter: ({ filter, onChange }) =>
            //         <Select
            //             isMulti
            //             name="naziv"
            //             onChange={value => {
            //                 onChange(value)
            //             }}
            //             closeMenuOnSelect={false}
            //             options={this.state.states}
            //             className="multi-select-in-filter"
            //             classNamePrefix="select"
            //             placeholder={i18n.t('Select')}
            //         />
            // },
            // {
            //     id: 'dateOfBirth',
            //     Header: i18n.t('Date of birth'),
            //     accessor: d => d.datum_rodjenja ? moment(d.datum_rodjenja, 'DD.MM.YYYY').format('DD.MM.YYYY') : '',
            //     Filter: ({ filter, onChange }) =>
            //         <DatePickerForFilter
            //             onChange={({ startDate, endDate }) => {
            //                 onChange({ startDate, endDate });
            //             }}

            //         />
            // },
            // {
            //     Header: i18n.t('Gender'),
            //     accessor: 'pol',
            //     // className: 'tr-head-60',
            //     // headerClassName: 'tr-head-60',
            //     Filter: ({ filter, onChange }) =>
            //         <Select
            //             isMulti
            //             name="naziv"
            //             onChange={value => {
            //                 onChange(value)
            //             }}
            //             closeMenuOnSelect={false}
            //             options={this.state.genders}
            //             className="multi-select-in-filter"
            //             classNamePrefix="select"
            //             placeholder={i18n.t('Select')}
            //         />

            // },
            {
                Header: i18n.t('BVN'),
                accessor: 'bpg',
                show: user.id_klijent_grupa == 14 ? false : true,
            },

        ];
        // if (this.props.location.state.showAnchor === false) { columns.shift(); }

        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: rowInfo.original.black_list_agent && rowInfo.original.black_list_branch ? 'gray' : rowInfo.original.black_list_agent ? 'lightgray' : selected ? "#ACDAF1" : "inherit"
                        }
                    };
                }
                return {};
            }
        };
        return (
            <div className="page">
                <div className="table-header">
                    {(user.id_klijent_grupa === 13 || user.id_klijent_grupa === 7 || user.id_klijent_grupa === 1) &&
                        <Fab
                            onClick={() => this.handleClick()}
                            color="secondary"
                            aria-label="add"
                            className="action-button"
                        >
                            <span className="icon-Plus headerIcon" />
                        </Fab>
                    }
                    <Fab
                        onClick={() => this.onExportClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-download headerIcon" />
                    </Fab>
                    <Fab
                        onClick={() => this.onSearchClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-search headerIcon" />
                    </Fab>
                    {/* {(user.id_klijent_grupa === 13 || user.id_klijent_grupa === 10 || user.id_klijent_grupa === 1) && this.state.selection.length === 1 &&
                        <Fab
                            onClick={() => this.onEditClick()}
                            color="secondary"
                            aria-label="edit"
                            className="action-button"
                        >
                            <span className=" icon-Izmeni1 headerIcon" />
                        </Fab>
                    } */}
                    {this.state.selection.length === 1 &&
                        <Fab
                            onClick={() => this.onProfileClick()}
                            color="secondary"
                            aria-label="edit"
                            className="action-button"
                        >
                            <span className=" icon-Informacija headerIcon" />
                        </Fab>
                    }
                    {this.state.selection.length === 1 && user.id_klijent_grupa == 13 &&
                        <Fab
                            onClick={this.onVerifyBVN}
                            color="secondary"
                            aria-label="edit"
                            className="action-button"
                        >
                            <span className=" icon-Stiklic headerIcon" />
                        </Fab>
                    }
                </div>
                <CheckboxTable
                    ref={r => (this.checkboxTable = r)}
                    data={this.props.farmers}
                    getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                    columns={columns}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.props.farmersPages}
                    loading={this.props.farmersFetching}
                    manual
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => this.props.getFarmOffices(state, instance, window.location.pathname === '/farmer_blacklisted')}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF',
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
                {/* <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} /> */}
                {this.state.add && <AddFarmers add={this.state.add}
                    handleClose={this.handleClick} />}
                {this.state.export && <Export
                    export={this.state.export}
                    onTextChange={({ title }) => {
                        this.setState({ docTitle: title });
                    }}
                    changeFormat
                    onformatChange={(format) => {
                        this.setState({ docFormat: format });
                    }}
                    handleClose={this.onExportClick}
                    exportDoc={this.exportFunction} />
                }
                {this.state.profile &&
                    <ProfilePreview
                        profile={this.state.profile}
                        handleClose={this.onProfileClick}
                        clientId={this.state.selection[0]}
                        contactPerson={this.props.farmerObj.kontakt_osoba}
                        clientTitle={this.props.farmerObj.naziv}
                        showFarmersInfo={true}
                    />}
                {editActive && <FarmersInformations id_klijent={this.state.selection[0]} editActive={editActive} farmerObj={farmerObj} handleClose={this.onEditClick} />}
                {this.state.verifyBVNPopup &&
                    <Dialog
                        open={this.state.verifyBVNPopup}
                        onClose={this.onVerifyBVN}
                        disableBackdropClick
                        maxWidth="l"
                    >
                        <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('BVN verification')}
                            <IconButton onClick={this.onVerifyBVN}>
                                <span className="icon-cancel-circle closeIcon" />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                                {verifyBVNFetching &&
                                    <div style={{ padding: 50 }}>
                                        <Typography variant="title">{i18n.t('Loading...')}</Typography>
                                    </div>
                                }
                                {bvnImage.length > 0 &&
                                    <div>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            {this.renderImage()}
                                            <img src={`data:image/jpeg;base64,${bvnImage}`} height="250" width="350" style={{ padding: '10px' }} />
                                        </div>
                                        <Grid item>
                                            <TextField
                                                label={i18n.t('Name surname')}
                                                value={this.props.farmerObj.kontakt_osoba}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                margin="normal"
                                                style={{ width: '256px', marginTop: '10px', marginBottom: '10px', padding: '10px' }}
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                label={i18n.t('BVN')}
                                                value={this.props.farmerObj.bpg}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                margin="normal"
                                                style={{ width: '256px', marginTop: '10px', marginBottom: '10px', padding: '10px' }}
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                label={i18n.t('State')}
                                                value={this.props.farmerObj.opstina_naziv}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                margin="normal"
                                                style={{ width: '256px', marginTop: '10px', marginBottom: '10px', padding: '10px' }}
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                label={i18n.t('Local government')}
                                                value={this.props.farmerObj.katastarska_opstina_naziv}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                margin="normal"
                                                style={{ width: '256px', marginTop: '10px', marginBottom: '10px', padding: '10px' }}
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                label={i18n.t('Date of birth')}
                                                value={this.props.farmerObj.datum_rodjenja}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                margin="normal"
                                                style={{ width: '256px', marginTop: '10px', marginBottom: '10px', padding: '10px' }}
                                            >
                                            </TextField>
                                        </Grid>
                                    </div>
                                }
                                {verifyBVNFailed &&
                                    <div style={{ padding: 20 }}>
                                        <Typography variant="title">{i18n.t('No record for this BVN!')}</Typography>
                                    </div>
                                }
                            </Grid>
                        </DialogContent>
                    </Dialog>
                }
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        farmers: state.farmRegistarReducer.farmers,
        farmerObj: state.farmRegistarReducer.farmerObj,
        farmPostingSuccess: state.farmRegistarReducer.farmPostingSuccess,
        farmersFetching: state.farmRegistarReducer.farmersFetching,
        farmersPages: state.farmRegistarReducer.farmersPages,
        localGoverments: state.farmRegistarReducer.localGoverments,
        localGovermentsFetching: state.farmRegistarReducer.localGovermentsFetching,
        localGovermentsFetchingFailed: state.farmRegistarReducer.localGovermentsFetchingFailed,
        title: state.farmRegistarReducer.title,
        titleFetching: state.farmRegistarReducer.titleFetching,
        titleFetchingFailed: state.farmRegistarReducer.titleFetchingFailed,
        maritalStatus: state.farmRegistarReducer.maritalStatus,
        maritalStatusFetching: state.farmRegistarReducer.maritalStatusFetching,
        maritalStatusFetchingFailed: state.farmRegistarReducer.maritalStatusFetchingFailed,
        identificationDoc: state.farmRegistarReducer.identificationDoc,
        identificationDocFetching: state.farmRegistarReducer.identificationDocFetching,
        identificationDocFetchingFailed: state.farmRegistarReducer.identificationDocFetchingFailed,
        states: state.administrationReducer.states,
        statesFetching: state.administrationReducer.statesFetching,
        statesFetchingFailed: state.administrationReducer.statesFetchingFailed,
        verifyBVNFailed: state.farmRegistarReducer.verifyBVNFailed,
        verifyBVNSuccess: state.farmRegistarReducer.verifyBVNSuccess,
        bvnImage: state.farmRegistarReducer.bvnImage,
        verifyBVNFetching: state.farmRegistarReducer.verifyBVNFetching,
        farmerImage: state.farmRegistarReducer.farmerImage,
        getAnchorForFarmerSuccess: state.farmRegistarReducer.getAnchorForFarmerSuccess,
        branches: state.administrationReducer.branches,
        branchesFetching: state.administrationReducer.branchesFetching,
        anchorDropdown: state.administrationReducer.anchorDropdown,
        anchorDropdownFetching: state.administrationReducer.anchorDropdownFetching,
        agentDropdown: state.administrationReducer.agentDropdown,
        agentDropdownFetching: state.administrationReducer.agentDropdownFetching,

    }
}

function mapDispatchToProps(dispatch) {
    return {
        getStates: () => dispatch(getStates()),
        getLocalGov: () => dispatch(getLocalGov()),
        getBranches: () => dispatch(getBranches()),
        getAnchorDropdown: () => dispatch(getAnchorDropdown()),
        getAgentDropdown: () => dispatch(getAgentDropdown()),
        getTitle: () => dispatch(getTitle()),
        setInitialState: (component) => dispatch(setInitialState(component)),
        setRowInReducer: (rowInfo, name) => dispatch(setRowInReducer(rowInfo, name)),
        getIdentificationDoc: () => dispatch(getIdentificationDoc()),
        getMaritalStatus: () => dispatch(getMaritalStatus()),
        getFarmOffices: (state, instance, hash) => dispatch(getFarmOffices(state, instance, hash)),
        exportDocument: (title, state, url, format) => dispatch(exportDocument(title, state, url, format)),
        verifyBVN: (bvn) => dispatch(verifyBVN(bvn)),
        addAnchorForFarmer: (id_farmer, selected) => dispatch(addAnchorForFarmer(id_farmer, selected))


    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewFarmers)));