import React from 'react';
import { Card, withStyles, } from '@material-ui/core';
import axios from '../../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../InfoStyles';

export class InfoPressure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.language === 1 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Pritisak koji vazdušni omotač svojom težinom vrši na Zemlju i tela na njoj naziva se atmosferski pritisak. 
                            Zavisi od sastava i vlažnosti vazduha i od nadmorske visine - kako nadmorska visina raste, atmosferski pritisak opada.
                            Sprovedena istraživanja pokazuju da izlaganje semena biljaka čestim promenama atmosferskog pritiska skraćuje vreme klijanja, 
                            podstiče brzinu rasta mladih biljaka i izaziva masovniji i brži rast korena.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            The pressure that the air envelope exerts on the Earth with its weight is called atmospheric pressure. 
                            It depends on the composition and humidity of the air and the elevation - as the elevation increases the atmospheric pressure decreases.
                            Conducted research shows that exposure of plant seeds to frequent changes in atmospheric pressure shortens the germination time, 
                            stimulates the growth rate of young plants and causes more massive and faster root growth.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 4 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Tlak kojim zračni omotač svojom težinom djeluje na Zemlju i tijela na njoj naziva se atmosferski tlak. Ovisi o sastavu i vlažnosti zraka te o nadmorskoj visini - s povećanjem nadmorske visine atmosferski tlak opada.
                            Provedena istraživanja pokazuju da izlaganje sjemena biljaka čestim promjenama atmosferskog tlaka skraćuje vrijeme klijanja, potiče brzinu rasta mladih biljaka te uzrokuje masivniji i brži rast korijena.
                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    };
};

export default withStyles(styles)(InfoPressure);