import immutable from 'seamless-immutable';
import { Type as ProgramType } from '../../actions/financial/ProgramActions';


export const INITIAL_STATE = immutable({
    programPosting: false,
    programPostingSuccess: false,
    programPostingFailed: false,

    program: [],
    programObj: {},
    programFetching: false,
    programPages: 1,
    programType: [],
    programTypeFetching: false,
    programTypeFetchingFailed: false,
    sectorType: [],
    sectorTypeFetching: false,
    sectorTypeFetchingFailed: false,
    institution: [],
    institutionFetching: false,
    institutionFetchingFailed: false,
    enterpriesType: [],
    enterpriesTypeFetching: false,
    enterpriesTypeFetchingFailed: false,
    deleted: false,
    deleteProgramFailed: false,
    programDocuments: [],
    programItems: {},
    programItemsFetching: false,
    programItemsFetchingFailed: false,

    programDocumentsFetching: false,
    programDocumentsFetchingFailed: false,

});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case ProgramType.SET_INITIAL_STATE: {
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;
        }

        case ProgramType.ADD_PROGRAM_CALL: {
            const programPosting = true;
            return state.merge({ programPosting });
            break;
        }

        case ProgramType.ADD_PROGRAM_SUCCESS: {
            const programPosting = false;
            const programPostingSuccess = true;
            return state.merge({ programPostingSuccess, programPosting, programObj: INITIAL_STATE.programObj })
            break;
        }

        case ProgramType.ADD_PROGRAM_FAILED: {
            const programPosting = false;
            const programPostingFailed = true;
            return state.merge({ programPosting, programPostingFailed });
            break;
        }

        case ProgramType.GET_PROGRAM_READ_CALL: {
            const programFetching = true;
            return state.merge({ programFetching });
            break;
        }

        case ProgramType.GET_PROGRAM_READ_SUCCESS: {
            const program = action.data.data;
            const programFetching = false;
            const programPages = action.data.total_pages
            return state.merge({ program, programFetching, programPages });
            break;
        }

        case ProgramType.GET_PROGRAM_READ_FAILED: {
            const programFetchingFailed = true;
            const programFetching = false;
            return state.merge({ programFetching, programFetchingFailed });
            break;
        }

        case ProgramType.GET_PROGRAM_TYPE_CALL: {
            const programTypeFetching = true;
            return state.merge({ programTypeFetching });
            break;
        }

        case ProgramType.GET_PROGRAM_TYPE_SUCCESS: {
            const programType = action.data.data;
            const programTypeFetching = false;
            return state.merge({ programType, programTypeFetching });
            break;
        }

        case ProgramType.GET_PROGRAM_TYPE_FAILED: {
            const programTypeFetchingFailed = true;
            const programTypeFetching = false;
            return state.merge({ programTypeFetching, programTypeFetchingFailed });
            break;
        }

        case ProgramType.GET_SECTOR_TYPE_CALL: {
            const sectorTypeFetching = true;
            return state.merge({ sectorTypeFetching });
            break;
        }

        case ProgramType.GET_SECTOR_TYPE_SUCCESS: {
            const sectorType = action.data.data;
            const sectorTypeFetching = false;
            return state.merge({ sectorType, sectorTypeFetching });
            break;
        }

        case ProgramType.GET_SECTOR_TYPE_FAILED: {
            const sectorTypeFetchingFailed = true;
            const sectorTypeFetching = false;
            return state.merge({ sectorTypeFetching, sectorTypeFetchingFailed });
            break;
        }

        case ProgramType.GET_ENTERPRIES_TYPE_CALL: {
            const enterpriesTypeFetching = true;
            return state.merge({ enterpriesTypeFetching });
            break;
        }

        case ProgramType.GET_ENTERPRIES_TYPE_SUCCESS: {
            const enterpriesType = action.data.data;
            const enterpriesTypeFetching = false;
            return state.merge({ enterpriesType, enterpriesTypeFetching });
            break;
        }

        case ProgramType.GET_ENTERPRIES_TYPE_FAILED: {
            const enterpriesTypeFetchingFailed = true;
            const enterpriesTypeFetching = false;
            return state.merge({ enterpriesTypeFetching, enterpriesTypeFetchingFailed });
            break;
        }

        case ProgramType.GET_INSTITUTION_CALL: {
            const institutionFetching = true;
            return state.merge({ institutionFetching });
            break;
        }

        case ProgramType.GET_INSTITUTION_SUCCESS: {
            const institution = action.data.data;
            const institutionFetching = false;
            return state.merge({ institution, institutionFetching });
            break;
        }

        case ProgramType.GET_INSTITUTION_FAILED: {
            const institutionFetchingFailed = true;
            const institutionFetching = false;
            return state.merge({ institutionFetching, institutionFetchingFailed });
            break;
        }

        case ProgramType.GET_PROGRAM_DASHBOARD_CALL: {
            const programItemsFetching = true;
            return state.merge({ programItemsFetching });
            break;
        }

        case ProgramType.GET_PROGRAM_DASHBOARD_SUCCESS: {
            const programItems = action.data;
            const programItemsFetching = false;
            return state.merge({ programItems, programItemsFetching });
            break;
        }

        case ProgramType.GET_PROGRAM_DASHBOARD_FAILED: {
            const programItemsFetchingFailed = true;
            const programItemsFetching = false;
            return state.merge({ programItemsFetching, programItemsFetchingFailed });
            break;
        }



        case ProgramType.DELETE_PROGRAM_SUCCESS: {
            const array = state['program'].asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ program: array, deleted: deleted })
            break;
        }

        case ProgramType.DELETE_PROGRAM_FAILED: {
            const deleteProgramFailed = true;
            return state.merge({ deleteProgramFailed });
            break;
        }

        case ProgramType.SET_PROGRAM_ROW_IN_REDUCER: {
            const data = action.rowInfo.asMutable();
            return state.merge({ programObj: data })
            break;
        }


        case ProgramType.PROGRAM_DOCUMENTS_CALL: {
            const programDocumentsFetching = true;
            return state.merge({ programDocumentsFetching });
            break;
        }

        case ProgramType.PROGRAM_DOCUMENTS_SUCCESS: {
            const programDocuments = action.data.data;
            const programDocumentsFetching = false;
            return state.merge({ programDocuments, programDocumentsFetching });
            break;
        }

        case ProgramType.PROGRAM_DOCUMENTS_FAILED: {
            const programDocumentsFetchingFailed = true;
            const programDocumentsFetching = false;
            return state.merge({ programDocumentsFetching, programDocumentsFetchingFailed });
            break;
        }

        case ProgramType.DELETE_PROGRAM_DOCUMENT_SUCCESS: {
            const array = state['programDocuments'].asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                if (array[i] && array[i].id === forDelete) {
                    array.splice(i, 1);
                }
            }
            return state.merge({ programDocuments: array })
            break;
        }

        default:
            return state;
    }
}