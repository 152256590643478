import React from 'react';
import { Card, withStyles, } from '@material-ui/core';
import axios from '../../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../InfoStyles';

export class InfoDewPoint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.language === 1 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Tačka rošenja je temperatura na kojoj se vodena para suspendovana u vazduhu kondenzuje i postaje tečna. 
                            Kada su temperatura tačke rose i temperatura vazduha jednake, vlažnost je 100% i tada dolazi do pojave rose. 
                            Na grafikonu je to predstavljeno kao momenat preklapanja dve linije. 
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Dew point is the temperature at which water vapor suspended in the air condenses and becomes liquid. 
                            When the dew point temperature and the air temperature are the same, the humidity is 100% and then dew occurs. 
                            On the graph, it is represented as the moment of overlapping of two lines.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 4 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Točka rosišta je temperatura na kojoj se vodena para suspendirana u zraku kondenzira i postaje tekuća. 
                            Kada su temperatura tačke rosišta i temperatura zraka jednake, vlažnost je 100% i tada 
                            dolazi do rošenja. Na grafu je to prikazano kao trenutak preklapanja dviju linija.
                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    };
};

export default withStyles(styles)(InfoDewPoint);