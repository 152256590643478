import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Card } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './ImageryStyles';
import nbr from './../satelliteinfo/nbr.png';
import nbr2 from './../satelliteinfo/nbr2.png';

export class NBR extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.props.language === 1 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                The Normalized Burn Ratio (NBR) označava koliko su ozbiljne posledice suše.
                            </p>
                            <div className={classes.image}>
                                <img src={nbr} />
                            </div>
                            <div className={classes.image}>
                                <img src={nbr2} />
                            </div>
                        </div>
                    </Card>
                }
                {this.props.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                The Normalized burn ratio (NBR) is used to identify burned areas. The formula is similar to a normalized
                                difference vegetation index (NDVI), except that it uses near-infrared (NIR) and shortwave-infrared (SWIR)
                                portions of the electromagnetic spectrum.
                            </p>
                            <div className={classes.image}>
                                <img src={nbr} />
                            </div>
                            <div className={classes.image}>
                                <img src={nbr2} />
                            </div>
                        </div>
                    </Card>
                }
                {this.props.language === 4 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                NBR (The Normalized Burn Ratio) označava koliko su ozbiljne posledice suše.
                            </p>
                            <div className={classes.image}>
                                <img src={nbr} />
                            </div>
                            <div className={classes.image}>
                                <img src={nbr2} />
                            </div>
                        </div>
                    </Card>
                }
            </div>
        )
    }
};

export default withStyles(styles)(NBR);