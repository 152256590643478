import React from 'react';
import 'react-table/react-table.css';
import { Map, TileLayer, WMSTileLayer, LayersControl, FeatureGroup, Popup, LayerGroup, Marker } from 'react-leaflet';
import { GoogleLayer } from "react-leaflet-google"
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'proj4leaflet';
import AddField from '../catastral_data/field/AddField';
import AddNote from '../note/AddNote';
import { calculateArea } from '../../utils/MapUtils';
import axios from '../../utils/AxiosWrapper';
import * as querystring from 'querystring';
import { zoomFormat } from './ZoomAndCenter';
import i18n from '../../i18n/i18n';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SearchIcon from '@material-ui/icons/Search';
import SunIcon from '@material-ui/icons/WbSunny';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import DatePicker from './../Map/LayerTree/Node/Tools/DatePicker';
import { Tooltip } from "@material-ui/core";
import { styles } from './styles';
import { createGrid } from './../../utils/MapUtils';
import { getTodayDate, getDaysBetween } from './../../utils/constants';
import { MoonLoader } from 'react-spinners';
import { wentWrong, wentRight } from '../../utils/ToastConfig';
import { Dialog } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { TextField, Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import RestoreIcon from '@material-ui/icons/Restore';
import Select, { components } from 'react-select';
import SensorDialog from './../sensor/SensorDialog';
import SatelliteInfo from './satellite/SatelliteInfo';


const iframeStyle = {
    minWidth: '560px',
    minHeight: '500px',
    zIndex: 20000,
    opacity: 1,
    backgroundColor: 'white',
    border: '1px solid grey'
};

export class Maps extends React.Component {
    constructor(props) {
        super(props);

        this.EMPTY_FEATURE = { type: null, isDrawn: false, isCompleted: false, geometry: null, attributes: null };
        this.state = {
            add: false,
            newFeature: { ...this.EMPTY_FEATURE },
            season: [], seasons: [], layer_season: [], field_list: [], field_selected: [],
            center: [45.75, 19.44], bounds: [], zoom_latitude: null, zoom_longitude: null,
            zoom: 6, mounting: true, open: true, zoomMenu: false,
            layers: {}, layerGroups: {},
            baseLayersOpen: true, osmLayerShown: true, googleLayerShown: false,
            cadastralLayersOpen: true, fieldsLayerShown: true, parcelLayerShown: false,
            administrativeLayersOpen: true, administrativeLayerData: {}, selectedAdministrative: ['states'],
            satteliteLayersOpen: false, selectedSatellite: null, satelliteLayerData: {},
            transportLayersOpen: false, transportLayerData: {}, selectedTransport: [],
            weatherLayersOpen: false, forecast10daysShown: false, spinner: false,
            fieldNotesLayersOpen: false, notesLayersShown: false, addNote: false, note_x: null, note_y: null,
            minZoom: 6, date: null, tomorrow: null, weather_date: null, max_date: null,
            day_interval: 24 * 60 * 60 * 1000, forecasts: [],
            row: {}, sensors: [], openSensorDialog: false, sensor_temp: null, flyto_state: false,
            stateType: [], states: [39, 19, 82, 166, 22, 873, 879, 890, 911, 1023], filter: null, meteoShown: false, clearStateColor: '#dbdbdb',
            ddstate_selected: [], ddstate_list: [], zoom_state: false,
        
            satteliteLayersIds: ["ndvi", "savi", "sr", "bathymetric", "ndwi", "ndmi", "lai", "evi", "burn"],
            satteliteLayers: ["NDVI", "SAVI", "SR", "BATHYMETRIC", "NDWI", "NDMI", "LAI", "EVI", "NBR"],
            satelliteLayersNames: ['NDVI', 'SAVI', 'SR', 'RGB', 'NDWI', 'NDMI', 'LAI', 'EVI', 'NBR'],
        };
    }

    componentDidMount() {
        let that = this;
        this.setState({ weather_date: this.getTodayDate(), date: this.getTodayDate(), max_date: this.getTodayDate() });

        axios.get('api/map/init')
            .then(res => { this.setState({ layers: res.data.layers.byId, layerGroups: res.data.layerGroups.byId }); })
            .catch(err => { console.log(err); })

        axios.get('api/season/drop_down')
            .then(res => { this.setState({ seasons: res.data.data }); })
            .catch(err => { console.log(err) })

        axios.get('api/season/activity_season')
            .then(res => {
                this.setState({ season: [...this.state.season, { label: res.data.data[0].naziv, value: res.data.data[0].id_default_sezona }] },
                    () => { this.loadFields(); });
            })
            .catch(err => { console.log(err) })

        axios.get('api/opstina/opstine_mapa')
            .then(res => { this.setState({ ddstate_list: res.data.data }) })
            .catch(err => { console.log(err) })

        const map = this.map.leafletElement;
        // console.log('Map: ', map)
    };

    getTodayDate = () => {
        let date = new Date();
        let day = date.getDate();
        let month = new String(date.getMonth());
        let year = date.getFullYear();
        if (month.length === 1) month = '0' + month;
        return year + '-' + month + '-' + day;
    };

    getForecast = (mapBounds, day) => {
        const that = this;
        // axios.post(`api/forecast/10days`, { locations: createGrid(mapBounds, 6, 4), day: day })
        axios.post(`api/forecast/10days`, { locations: createGrid(mapBounds, 6, 4), day: 1 })
            .then(response => { that.setState({ forecasts: response.data.data }, () => { this.setState({ spinner: false }) }); })
            .catch(error => { console.log(error); that.setState({ forecasts: [], spinner: false }); wentWrong(i18n.t('Something went wrong.')); });
    };

    getSensorData = () => {
        let user = this.getUser();

        axios.get('map/read_map', { params: { id_opstina : user.id_opstina } })
            .then(res => {
                this.setState({ sensors: res.data.data });
                if (this.state.states.includes(user.id_opstina)) {
                    let x = res.data.data.map(item => Number(item.x))
                    let y = res.data.data.map(item => Number(item.y))
                    let minx = Math.min(...x);
                    let miny = Math.min(...y);
                    let maxx = Math.max(...x);
                    let maxy = Math.max(...y);
                    let centerx = (minx + maxx) / 2;
                    let centery = (miny + maxy) / 2;
                    this.map.leafletElement.flyTo([centery, centerx], 14, { animate: true, duration: 0.5 });
                    console.log('asda', centery, centerx)
                }
                else this.map.leafletElement.flyTo([45.64, 19.7], 7, { animate: true, duration: 0.5 });
            })
            .catch(err => { console.log(err) })
    }

    saveField = (attributes) => {
        const { newFeature: { geometry } } = this.state;
        let user = this.getUser();
        axios.post('api/field/create', { ...attributes, geometry, id_default_sezona: user.id_sezona })
            .then(res => {
                this.setState({ add: false, newFeature: { ...this.EMPTY_FEATURE } }, () => {
                    this.map.leafletElement.eachLayer(layer => { if (layer._path != null) { layer.remove(); } });
                    this.setState({ mounting: false }, () => { this.loadFields(); })
                });
            })
            .catch(err => { console.log(err); })
    };

    saveNote = (attributes) => {
        axios.post('api/note/create', { ...attributes, x: this.state.note_x, y: this.state.note_y })
            .then(res => {
                this.setState({ addNote: false, note_x: null, note_y: null }, () => {
                    this.map.leafletElement.removeLayer(this.drawnFeature);
                    this.map.leafletElement.eachLayer(layer => {
                        if (layer._icon) {
                            if (layer._icon.src) {
                                if (layer._icon.src.includes('icon')) {
                                    layer.remove();
                                }
                            }
                        }
                    });
                    this.setState({ fieldNotesLayersOpen: true, notesLayersShown: true }, () => {
                        if (this.state.fieldsLayerShown === true) {
                            this.loadFields();
                            this.map.leafletElement.eachLayer(layer => { if (layer._path != null) { layer.remove(); } });
                        }
                        this.loadNotes();
                    })
                })
            })
    }

    loadForecast = (forecast, map, date) => {
        if (forecast === true) {
            this.setState({ spinner: true }, () => {
                const bounds = map.getBounds();
                const mapBounds = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];

                this.setState({ weather_date: date, max_date: this.getTodayDate() }, () => {
                    const day = getDaysBetween(this.getTodayDate(), this.state.weather_date);
                    this.getForecast(mapBounds, day);
                });
            });
        }
    }

    loadFields = () => {
        const map = this.map.leafletElement;

        const bounds = map.getBounds();
        const mapBounds = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];

        const bbox = mapBounds.join(',');
        const crs = 'EPSG:4326';
        let seasons = [];
        if (this.state.season) this.state.season.map((item, i) => { seasons.push(item.value) });

        let that = this;
        let mounting = this.state.mounting;
        let fields = [];
        if (that.state.field_selected) fields = that.state.field_selected.map((item, i) => item.value);

        let states = [];
        if (that.state.ddstate_selected) states = that.state.ddstate_selected.map((item, i) => item.value);

        axios.get('api/field/field_list', { params: { seasons, states } })
            .then(res => {
                let field_list = [];
                for (let i = 0; i < res.data.data.length; i++) {
                    let ime = '';
                    let prezime = '';
                    if (res.data.data[i].ime !== null) ime = res.data.data[i].ime;
                    if (res.data.data[i].prezime !== null) prezime = res.data.data[i].prezime;
                    let gazdinstvo = ime + ' ' + prezime;
                    if (
                        res.data.data[i].ime === null && res.data.data[i].prezime === null ||
                        res.data.data[i].ime === "" && res.data.data[i].prezime === ""
                    ) {
                        gazdinstvo = res.data.data[i].naziv_klijent;
                    }
                    field_list.push({ id: res.data.data[i].id, name: gazdinstvo + ' - ' + res.data.data[i].naziv });
                }
                field_list.sort((a, b) => (a.name > b.name) ? 1 : -1);
                this.setState({ field_list });
            })
            .catch(err => { console.log(err) })

        axios.get('api/field?' + querystring.stringify({ bbox, seasons, crs, fields, states }))
            .then(response => {
                let parsed = JSON.parse(response.data.data.geojson);

                if (this.state.zoom_state === true) {
                    let zoom_data = {};
                    axios.get('api/opstina/geom_opstina_more', { params: { states } })
                        .then(res => {
                            parsed = (JSON.parse(res.data.data.rows[0].geojson));
                            if (parsed.features.length !== 0) {
                                zoom_data = zoomFormat(parsed);
                                if (zoom_data) that.setState({ center: zoom_data.center, zoom: zoom_data.zoom });
                            }
                            this.setState({ zoom_state: false })
                        })
                        .catch(err => { console.log(err) })
                }
                else {
                    if (fields.length > 0) {
                        let zoom_data = {};
                        zoom_data = zoomFormat(parsed);
                        if (zoom_data) that.setState({ center: zoom_data.center, zoom: zoom_data.zoom });
                    }

                    if (fields.length === 0) {
                        let user = this.getUser();
                        if (user.id_klijent_grupa === 8) {
                            // ministarstvo federal
                            if (user.id_region === 2) {
                                this.map.leafletElement.flyTo([44.1144, 20.7268], 7, { animate: true, duration: 0.5 });
                            }
                            else if (user.id_region === 20 || user.id_region === 21) {
                                this.map.leafletElement.flyTo([43.9034, 17.7681], 8, { animate: true, duration: 0.5 });
                            }
                            else if (user.id_region === 22) {
                                this.map.leafletElement.flyTo([46.0221, 14.7381], 8, { animate: true, duration: 0.5 });
                            }
                            else if (user.id_region === 23) {
                                this.map.leafletElement.flyTo([42.6792, 19.0206], 8, { animate: true, duration: 0.5 });
                            }
                            else if (user.id_region === 13) {
                                this.map.leafletElement.flyTo([8.9979, 7.9145], 6, { animate: true, duration: 0.5 });
                            }
                            else {
                                this.map.leafletElement.flyTo([44.1144, 20.7268], 6, { animate: true, duration: 0.5 });
                            }
                        }

                        else {
                            let zoom_data = {};

                            axios.get('api/opstina/geom_opstina')
                                .then(res => {
                                    parsed = (JSON.parse(res.data.data.rows[0].geojson));
                                    if (parsed.features.length !== 0) {
                                        if (mounting === true) {
                                            zoom_data = zoomFormat(parsed);
                                            if (zoom_data) that.setState({ center: zoom_data.center, zoom: zoom_data.zoom });
                                        }
                                    }
                                })
                                .catch(err => { console.log(err) })
                        }
                    }
                }

                L.geoJSON(parsed).addTo(map);

                map.eachLayer(layer => {
                    let popup = '';
                    if (layer.feature) {
                        if (layer.feature.geometry.type === "Polygon" && layer.feature.properties.boja !== undefined) {

                            let field = layer.feature.properties;
                            let slash = ' / ', new_line = '</br>';
                            if (field.kultura_podgrupa_naziv === null) { slash = ''; new_line = ''; }

                            if (field.kultura_naziv === null) field.kultura_naziv = '';
                            if (field.kultura_grupa_naziv === null) field.kultura_grupa_naziv = '';
                            if (field.kultura_podgrupa_naziv === null) field.kultura_podgrupa_naziv = '';

                            let both = ``;
                            let osoba = ``;
                            let vlasnik = ``;
                            if (field.naziv_vlasnik !== null) vlasnik = `${field.naziv_vlasnik}`;
                            if (field.kontakt_osoba !== null) osoba = `${field.kontakt_osoba}`;
                            if (field.naziv_vlasnik !== null || field.kontakt_osoba !== null) {
                                both = `${i18n.t('Owner')}: ${osoba} ${vlasnik}`;
                            }

                            popup = L.popup().setContent(`
                                    <p style="font-size:16px;font-family:roboto;font-weight:bold">
                                        ${i18n.t('Name')}: ${field.naziv} </br>
                                        <p style="font-size:14px;font-family:roboto;font-weight:400">
                                            ${both}
                                            </br>
                                            ${field.kultura_naziv}${new_line}
                                            ${field.kultura_grupa_naziv}${slash}${field.kultura_podgrupa_naziv}
                                        </p>
                                        <p style="font-size:16px;font-family:roboto;font-weight:bold">${i18n.t('Land area')}: ${field.povrsina} ha</p>
                                    </p>`
                            );
                            layer.bindPopup(popup, { width: 'auto' });

                            layer.setStyle({ color: field.boja === null ? "#4692fb" : field.boja, opacity: 1, fillColor: field.boja === null ? "#4692fb" : field.boja, fillOpacity: 0.3, weight: 2 });
                            layer.on('mouseover', (e) => { layer.setStyle({ color: field.boja === null ? "#4692fb" : field.boja, opacity: 1, fillColor: field.boja === null ? "#4692fb" : field.boja, fillOpacity: 0.4, weight: 2 }); });
                            layer.on('mouseout', (e) => { layer.setStyle({ color: field.boja === null ? "#4692fb" : field.boja, opacity: 1, fillColor: field.boja === null ? "#4692fb" : field.boja, fillOpacity: 0.3, weight: 2 }); });
                        }
                    }
                });
            })
            .catch(err => console.log(err));
        if (mounting === false) { this.setState({ mounting: true }) };
    };

    loadNotes = () => {
        const map = this.map.leafletElement;
        let that = this;

        axios.get('api/note?', {})
            .then(res => {
                let parsed = JSON.parse(res.data.data.geojson);
                let markers = [];
                parsed.features.map((item, i) => {
                    let marker_coordinates = [item.geometry.coordinates[1], item.geometry.coordinates[0]];
                    let vrsta_beleske = item.properties.vrsta_beleske_id;

                    let Icon = null;
                    if (vrsta_beleske === 12) Icon = L.icon({ iconUrl: './note/fire-icon-new.png', iconSize: [32, 32], id: item.id });
                    else if (vrsta_beleske === 13) Icon = L.icon({ iconUrl: './note/disease-icon-new.png', iconSize: [32, 32], id: item.id });
                    else if (vrsta_beleske === 14) Icon = L.icon({ iconUrl: './note/pest-icon-new.png', iconSize: [32, 32], id: item.id });
                    else if (vrsta_beleske === 15) Icon = L.icon({ iconUrl: './note/steal-icon-new.png', iconSize: [32, 32], id: item.id });
                    else if (vrsta_beleske === 17) Icon = L.icon({ iconUrl: './note/mark-icon-new.png', iconSize: [32, 32], id: item.id });
                    else if (vrsta_beleske === 18) Icon = L.icon({ iconUrl: './note/weed-icon-new.png', iconSize: [32, 32], id: item.id });
                    else if (vrsta_beleske === 19) Icon = L.icon({ iconUrl: './note/analysis-icon-new.png', iconSize: [32, 32], id: item.id });
                    else Icon = L.icon({ iconUrl: './note/other-icon-new.png', iconSize: [32, 32], id: item.id });

                    let naslov = '', ime_prezime = '', vrsta_beleske_naziv = '', datum_dogadjaja = '', opis = '';
                    if (item.properties.naslov !== null) naslov = `<div style="font-size:18px;font-weight:bold">${item.properties.naslov}</div>`;
                    if (item.properties.vrsta_beleske_naziv !== null) vrsta_beleske_naziv = `<div style="font-size:14px;font-weight:bold">${item.properties.vrsta_beleske_naziv}</div>`;
                    if (item.properties.korisnik_ime !== null && item.properties.korisnik_prezime !== null) ime_prezime = `<div style="font-size:14px;font-weight:normal">${item.properties.korisnik_ime} ${item.properties.korisnik_prezime}</div>`;
                    if (item.properties.datum_dogadjaja !== null) datum_dogadjaja = `<div style="margin-top:8px">${item.properties.datum_dogadjaja}</div>`;
                    if (item.properties.opis !== null) opis = `<div style="margin-top:4px">${item.properties.opis}</div>`;

                    let marker = L.marker(marker_coordinates, { icon: Icon }).bindPopup(
                        `<div>` + naslov + vrsta_beleske_naziv + ime_prezime + datum_dogadjaja + opis + `</div>`
                    );
                    markers.push(marker);
                })
                L.layerGroup(markers).addTo(map)
            })
            .catch(err => { console.log(err) })
    }

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    handleClick = (item) => { this.setState({ openSensorDialog: !this.state.openSensorDialog, row: item }) };
    handleClickSatellite = (item) => { this.setState({ satteliteDialog: !this.state.satteliteDialog, row: item }) };
    handleCloseZoomMenu = () => { this.setState({ zoomMenu: !this.state.zoomMenu }); };

    handleFields = () => {
        this.setState({ fieldsLayerShown: !this.state.fieldsLayerShown }, () => {
            if (this.state.fieldsLayerShown === true) this.loadFields();
            else this.map.leafletElement.eachLayer(layer => {
                if (layer.feature) {
                    if (layer.feature.geometry.type === "Polygon" && layer.feature.properties.boja !== undefined) {
                        layer.remove();
                    }
                }
            });
        });
    };

    handleNotes = () => {
        this.setState({ notesLayersShown: !this.state.notesLayersShown }, () => {
            if (this.state.notesLayersShown === true) this.loadNotes();
            else this.map.leafletElement.eachLayer(layer => {
                if (layer._icon) {
                    if (layer._icon.src) {
                        if (layer._icon.src.includes('icon')) {
                            layer.remove();
                        }
                    }
                }
            });
        });
    };

    handleSatellites = (key, index) => {
        this.setState({ satelliteLayerData: {} }, () => {
            if (this.state.selectedSatellite === key) {
                this.setState({ selectedSatellite: null });
                this.map.leafletElement.options.minZoom = 6;
            }
            else {
                this.setState({ selectedSatellite: key }, () => {
                    let zoom = this.map.leafletElement.getZoom();
                    if (zoom < 10) {
                        this.map.leafletElement.options.minZoom = 10;
                        this.map.leafletElement.flyTo(this.state.center, 10, { animate: true, duration: 0.5 });
                    }
                })
            };
            this.setState({ satelliteLayerData: key });
        })
    };

    handleTransports = (key, index) => {
        if (this.state.selectedTransport.includes(key)) {
            this.setState({ selectedTransport: this.state.selectedTransport.filter(item => item !== key) });
        }
        else this.setState({ selectedTransport: [...this.state.selectedTransport, key] });
    };

    handleTransportGroup = () => { this.setState({ transportLayersOpen: !this.state.transportLayersOpen }); };
    handleMeteo = () => {
        if (this.state.meteoShown === true) { this.setState({ filter: null }); };

        this.setState({ meteoShown: !this.state.meteoShown }, () => {
            if (this.state.meteoShown === true) { this.getSensorData(this.state.filter); };
        });
    };

    handleAdministrative = (key, index) => {
        if (this.state.selectedAdministrative.includes(key)) {
            this.setState({ selectedAdministrative: this.state.selectedAdministrative.filter(item => item !== key) });
        }
        else this.setState({ selectedAdministrative: [...this.state.selectedAdministrative, key] });
    };

    handleAdministrativeGroup = () => { this.setState({ administrativeLayersOpen: !this.state.administrativeLayersOpen }); };

    handleBaseLayers = () => { this.setState({ osmLayerShown: !this.state.osmLayerShown, googleLayerShown: !this.state.googleLayerShown }); };
    handleDateChange = (e) => { this.setState({ date: e.target.value }) };

    handleForecasts = () => {
        if (this.state.spinner === true) this.setState({ spinner: false });
        this.setState({ forecast10daysShown: !this.state.forecast10daysShown }, () => {
            if (this.state.forecast10daysShown === true && this.state.weather_date !== null) this.loadForecast(this.state.forecast10daysShown, this.map.leafletElement, this.state.weather_date);
        });
    };

    handleWeatherDateChange = (e) => {
        this.setState({ weather_date: e.target.value }, () => {
            if (this.state.forecast10daysShown === true) this.loadForecast(this.state.forecast10daysShown, this.map.leafletElement, this.state.weather_date);
        })
    };

    render() {
        let user = this.getUser();
        const { classes } = this.props;
        return (
            <div className="page" style={{ height: '100%' }}>
                <Map center={this.state.center} zoom={this.state.zoom} className={this.state.open ? classes.mapClosed : classes.mapOpen}
                    doubleClickZoom={false} minZoom={this.state.minZoom} maxZoom={18} ref={(c) => { this.map = c }}>
                    {this.state.osmLayerShown && <TileLayer zIndex={2000} attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />}
                    {this.state.googleLayerShown && <GoogleLayer zIndex={2000} googlekey={'AIzaSyBuZJYdqpIR_vERdvSmk0zNRrCQMBiMaz0'} maptype={'SATELLITE'} />}
                    {this.state.administrativeLayerData !== {} && this.state.selectedAdministrative !== [] && this.state.layers &&
                        Object.keys(this.state.layers).map((key, index) => {
                            if (this.state.layers[key].layerGroupId === "administrativeLayers" && this.state.selectedAdministrative.includes(key)) {
                                return <WMSTileLayer
                                    key={key}
                                    format={this.state.layers[key].format}
                                    layers={this.state.layers[key].layers}
                                    // url={this.state.layers[key].url}
                                    url={'map/geoserver'}
                                    // http://agrolife.greensoft.co:8080/geoserver/repsrp/wms
                                    tileSize={this.state.layers[key].tileSize}
                                    zIndex={2200}
                                    transparent={true} />
                            }
                        })
                    }
                    {this.state.transportLayerData !== {} && this.state.selectedTransport !== [] && this.state.layers &&
                        Object.keys(this.state.layers).map((key, index) => {
                            if (this.state.layers[key].layerGroupId === "transportLayers" && this.state.selectedTransport.includes(key)) {
                                return <WMSTileLayer
                                    format={this.state.layers[key].format}
                                    layers={this.state.layers[key].layers}
                                    // url={this.state.layers[key].url}
                                    url={'map/geoserver'}
                                    tileSize={this.state.layers[key].tileSize}
                                    zIndex={2300}
                                    transparent={true} />
                            }
                        })
                    }
                    {this.state.forecasts !== [] && this.state.forecast10daysShown && this.state.spinner === false && this.state.forecasts.map(marker => {
                        return <div>
                            <Marker position={[marker.latitude, marker.longitude]}
                                icon={new L.DivIcon({
                                    iconSize: null,
                                    html: `<div class="weather-icon-box">` +
                                        `<img class="weather-icon-size" src="weather/icon${marker.icon_code_day || marker.icon_code_night}.png" />` +
                                        `<div style="width:max-content;color:${this.state.googleLayerShown ? 'white' : 'black'};font-weight: bold">${marker.max_temp} °C | ${marker.min_temp} °C</div>` +
                                        `</div>`
                                })}>
                                <Popup style={{ marginLeft: '32px' }} margin={0} minWidth={400} minHeight={200} width={400} height={200} autoPan={false}>
                                    <div style={{ width: 400, overflow: 'scroll', fontSize: 'small', lineHeight: 0.4 }}>
                                        <div align='center'>
                                            <div><h3>{marker.dow}</h3></div>
                                            <div align="center" style={{ width: '50%', float: 'left' }}>
                                                <div><b>{i18n.t('Day')}</b></div>
                                                {/* <div><img width="64px" src={`weather/icon${marker.icon_code_day}.png`} alt="" /></div> */}
                                                <div><p>{marker.max_temp} &#176;C</p></div>
                                                <div><p>{marker.phrase_day}</p></div>
                                                <div><p>{i18n.t('Humidity')}: {marker.rh_day} %</p></div>
                                                <div><p>{i18n.t('Wind')}: {marker.wind_speed_day}m/s {marker.wind_direction_day}</p></div>
                                            </div>
                                            <div align="center" style={{ width: '50%', float: 'left' }}>
                                                <div><b>{i18n.t('Night')}</b></div>
                                                {/* <div><img width="64px" src={`weather/icon${marker.icon_code_night}.png`} alt="" /></div> */}
                                                <div><p>{marker.min_temp} &#176;C</p></div>
                                                <div><p>{marker.phrase_night}</p></div>
                                                <div><p>{i18n.t('Humidity')}: {marker.rh_night} %</p></div>
                                                <div><p>{i18n.t('Wind')}: {marker.wind_speed_day}m/s {marker.wind_direction_night}</p></div>
                                            </div>
                                        </div>
                                    </div>
                                </Popup>
                            </Marker>
                        </div>
                    })}
                    {this.state.sensors.length > 0 && this.state.sensors !== undefined && this.state.meteoShown === true && this.state.sensors.map(((item, i) => {
                        return <Marker key={i} position={[Number(item.y), Number(item.x)]} onClick={() => {
                            this.setState({ sensor_temp: null });
                            let data_path = '/data/optimized/' + item.uid + '/hourly/last/1h';
                            axios.post('/api/testMeteo/meteo', { path: data_path, method: "GET" })
                                .then(res => { this.setState({ sensor_temp: res.data.data.data["18_X_X_506"].aggr.avg[0] }); })
                                .catch(err => { console.log(err) })
                        }}>
                            <Popup className="popup-sensor">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography style={{ fontSize: '18px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {item.naziv.substring(0, 15)}
                                            {item.naziv.length > 15 && '...'}
                                            {this.state.sensor_temp !== null &&
                                                <Typography style={{ fontSize: '18px', marginTop: '0px', marginBottom: '0px' }}> {this.state.sensor_temp}°C</Typography>
                                            }
                                        </div>
                                    </Typography>
                                    <Button className={classes.open_button} onClick={() => { this.handleClick(item); }}>{i18n.t('More')}</Button>
                                </div>
                            </Popup>
                        </Marker>
                    }))}
                    {this.state.satelliteLayerData !== {} && this.state.selectedSatellite !== null && this.state.date !== null &&
                        <WMSTileLayer
                            url={"https://services.sentinel-hub.com/ogc/wms/4a0d1b51-6ef0-4545-a0e7-d46d38cf3c3d"}
                            time={this.state.date}
                            format={"image/jpeg"}
                            maxcc={20}
                            zIndex={2100}
                            layers={this.state.satelliteLayerData}
                            showLogo={false}
                        />
                    }
                </Map>
                {this.state.spinner === true && <div style={{ left: '38%', top: '40%', zIndex: 20000, position: 'absolute' }}> <MoonLoader loading={this.state.load} size={100} /></div>}
                <React.Fragment>
                    <div className={this.state.open ? classes.toggleButtonOpen : classes.toggleButtonClose}
                        onClick={() => {
                            setTimeout(() => { this.map.leafletElement.invalidateSize() }, 400);
                            this.setState({ open: !this.state.open });
                        }} >
                        {this.state.open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </div>
                    <div className={this.state.open ? classes.searchButtonOpen : classes.searchButtonClose}>
                        <IconButton onClick={() => {
                            this.setState({ zoomMenu: true })
                        }}>
                            <SearchIcon style={{ fontSize: 36 }} />
                        </IconButton>
                    </div>

                    <div className={this.state.open ? classes.seasonalForecastButtonOpen : classes.seasonalForecastButtonClose}>
                        <IconButton onClick={() => {
                            if (user.id_klijent_grupa === 8) {
                                // ministarstvo federal
                                if (user.id_region === 2) {
                                    this.map.leafletElement.flyTo([44.1144, 20.7268], 7, { animate: true, duration: 0.5 });
                                }
                                else if (user.id_region === 20 || user.id_region === 21) {
                                    this.map.leafletElement.flyTo([43.9034, 17.7681], 8, { animate: true, duration: 0.5 });
                                }
                                else if (user.id_region === 22) {
                                    this.map.leafletElement.flyTo([46.0221, 14.7381], 8, { animate: true, duration: 0.5 });
                                }
                                else if (user.id_region === 23) {
                                    this.map.leafletElement.flyTo([42.6792, 19.0206], 8, { animate: true, duration: 0.5 });
                                }
                                else if (user.id_region === 13) {
                                    this.map.leafletElement.flyTo([8.9979, 7.9145], 6, { animate: true, duration: 0.5 });
                                }
                                else {
                                    this.map.leafletElement.flyTo([44.1144, 20.7268], 6, { animate: true, duration: 0.5 });
                                }
                            }
                            else this.map.leafletElement.flyTo(this.state.center, this.state.zoom, { animate: true, duration: 0.5 })
                        }}>
                            <RestoreIcon style={{ fontSize: 36 }} />
                        </IconButton>
                    </div>

                    <Drawer
                        variant="permanent"
                        anchor="right"
                        className={classNames(classes.drawer, { [classes.drawerOpen]: this.state.open, [classes.drawerClose]: !this.state.open, })}
                        classes={{ paper: classNames({ [classes.drawerOpen]: this.state.open, [classes.drawerClose]: !this.state.open }), }}
                        open={this.state.open}>
                        <div className={classes.toolbar} />
                        <List style={{ minHeight: '-webkit-fill-available' }}>
                            {this.state.season && this.state.seasons &&
                                <Select style={{ marginLeft: '16px', minWidth: '90%' }}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    isClearable={false}
                                    value={this.state.season}
                                    defaultValue={this.state.season}
                                    maxMenuHeight={200}
                                    onChange={(e) => {
                                        this.setState({ season: e }, () => {
                                            this.setState({ mounting: false }, () => {
                                                this.map.leafletElement.eachLayer(layer => { if (layer._path != null) { layer.remove(); } });
                                                this.loadFields();
                                            })
                                        })
                                    }}
                                    options={this.state.seasons.map((item) => { return { label: item.naziv, value: item.id } })}
                                    className="map-selects"
                                    classNamePrefix="select"
                                    placeholder={`‏‏‎ ‎ ‏‏‎ ‎` + i18n.t('Select seasons')}
                                />}
                            {this.state.season.length > 0 && this.state.seasons && this.state.ddstate_list && user.id_klijent_grupa === 8 &&
                                <div style={{ marginTop: '8px' }}>
                                    <Select style={{ marginLeft: '16px', minWidth: '90%' }}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        isClearable={false}
                                        value={this.state.ddstate_selected}
                                        defaultValue={this.state.ddstate_selected}
                                        maxMenuHeight={200}
                                        onChange={(e) => {
                                            this.setState({ ddstate_selected: e }, () => {
                                                if (e.length === 0) {
                                                    this.setState({ mounting: false, zoom_state: false }, () => {
                                                        this.map.leafletElement.eachLayer(layer => { if (layer._path != null) { layer.remove(); } });
                                                        this.loadFields();
                                                    })
                                                }
                                                else {
                                                    this.setState({ mounting: false, zoom_state: true }, () => {
                                                        this.map.leafletElement.eachLayer(layer => { if (layer._path != null) { layer.remove(); } });
                                                        this.loadFields();
                                                    })
                                                }
                                            })
                                        }}
                                        options={this.state.ddstate_list.map((item) => { return { label: item.naziv, value: item.id } })}
                                        className="map-selects"
                                        classNamePrefix="select"
                                        placeholder={`‏‏‎ ‎ ‏‏‎ ‎` + i18n.t('Select states')}
                                    />
                                </div>}
                            {this.state.layers.fields && this.state.layerGroups.cadastralLayers && this.state.season.length > 0 &&
                                <div>
                                    <List component="div" disablePadding>
                                        <ListItem button className={[classes.nested, classes.list_item]} onClick={() => this.handleFields()} >
                                            <ListItemIcon onClick={() => this.handleFields()}>
                                                <img style={{ width: '25px', height: '25px' }} src='./field-new2.png' />
                                            </ListItemIcon>
                                            <ListItemText primary={this.state.layers.fields.name} />
                                            <Checkbox style={{ marginRight: '3px' }} edge="end" onChange={() => this.handleFields()} checked={this.state.fieldsLayerShown} />
                                        </ListItem>
                                    </List>
                                </div>
                            }
                            {this.state.field_list && this.state.fieldsLayerShown === true && this.state.season.length > 0 &&
                                <Select style={{ marginLeft: '16px', minWidth: '90%', marginTop: '0px' }}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    isClearable={false}
                                    value={this.state.field_selected}
                                    defaultValue={this.state.field_selected}
                                    maxMenuHeight={200}
                                    onChange={(e) => {
                                        this.setState({ field_selected: e }, () => {
                                            if (e.length === 0) {
                                                this.setState({ mounting: true }, () => {
                                                    this.map.leafletElement.eachLayer(layer => { if (layer._path != null) { layer.remove(); } });
                                                    this.loadFields();
                                                })
                                            }
                                            else this.setState({ mounting: false }, () => {
                                                this.map.leafletElement.eachLayer(layer => { if (layer._path != null) { layer.remove(); } });
                                                this.loadFields();
                                            })
                                        })
                                    }}
                                    options={this.state.field_list.map((item) => { return { label: item.name, value: item.id } })}
                                    className="map-selects"
                                    classNamePrefix="select"
                                    placeholder={`‏‏‎ ‎ ‏‏‎ ‎` + i18n.t('Select fields')}
                                />}
                            {this.state.layers.notes && this.state.layerGroups.fieldNotes &&
                                <div className={classes.menuGroupStyle}>
                                    <List component="div" disablePadding>
                                        <ListItem button className={[classes.nested, classes.list_item]} onClick={() => this.handleNotes()}>
                                            <ListItemIcon onClick={() => this.handleNotes()}>
                                                <img style={{ width: '25px', height: '25px' }} src={'./note-icon-new2.png'} />
                                            </ListItemIcon>
                                            <ListItemText primary={this.state.layers.notes.name} />
                                            <Checkbox style={{ marginRight: '3px' }} edge="end" onChange={() => this.handleNotes()} checked={this.state.notesLayersShown} />
                                        </ListItem>
                                    </List>
                                </div>
                            }
                            {this.state.layers.osm && this.state.layers.google && this.state.layerGroups.baseLayers &&
                                <div>
                                    <ListItem button onClick={() => this.setState({ baseLayersOpen: !this.state.baseLayersOpen })}>
                                        <ListItemText primary={i18n.t('Base layers')} />
                                        {this.state.baseLayersOpen ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                    <Collapse in={this.state.baseLayersOpen} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItem button className={[classes.nested, classes.list_item]} onClick={() => this.handleBaseLayers()}>
                                                <ListItemIcon onClick={() => this.handleBaseLayers()}>
                                                    <img style={{ width: '25px', height: '25px' }} src='./osm-icon.png' />
                                                </ListItemIcon>
                                                <ListItemText primary={this.state.layers.osm.name} />
                                                <Radio style={{ marginRight: '3px' }} edge="end" onChange={() => this.handleBaseLayers()} checked={this.state.osmLayerShown} />
                                            </ListItem>
                                            <ListItem button className={[classes.nested, classes.list_item]} onClick={() => this.handleBaseLayers()}>
                                                <ListItemIcon onClick={() => this.handleBaseLayers()}>
                                                    <img style={{ width: '25px', height: '25px' }} src='./google-icon.png' />
                                                </ListItemIcon>
                                                <ListItemText primary={this.state.layers.google.name} />
                                                <Radio style={{ marginRight: '3px' }} edge="end" onChange={() => this.handleBaseLayers()} checked={this.state.googleLayerShown} />
                                            </ListItem>
                                        </List>
                                    </Collapse>
                                </div>
                            }
                            {this.state.layerGroups.administrativeLayers &&
                                <div>
                                    <ListItem button onClick={() => this.handleAdministrativeGroup()}>
                                        <ListItemText primary={this.state.layerGroups.administrativeLayers.name} />
                                        {this.state.administrativeLayersOpen ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                    <Collapse in={this.state.administrativeLayersOpen} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {this.state.layerGroups.administrativeLayers &&
                                                Object.keys(this.state.layers).map((key, index) => {
                                                    if (this.state.layers[key].layerGroupId === "administrativeLayers") {
                                                        return <div key={key}>
                                                            <ListItem button className={[classes.nested, classes.list_item]} onClick={() => this.handleAdministrative(key, index)}>
                                                                <ListItemIcon onClick={() => this.handleAdministrative(key, index)}>
                                                                    <img style={{ width: '25px', height: '25px' }} src={this.state.layers[key].icon} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={this.state.layers[key].name} />
                                                                <Checkbox style={{ marginRight: '3px' }} edge="end" onChange={() => this.handleAdministrative(key, index)} checked={this.state.selectedAdministrative.includes(key)} />
                                                            </ListItem>
                                                        </div>
                                                    }
                                                })
                                            }
                                        </List>
                                    </Collapse>
                                </div>
                            }
                            {this.state.layerGroups.transportLayers && this.state.layerGroups.transportLayers.layers.length > 0 &&
                                <div className={classes.menuGroupStyle}>
                                    <ListItem button onClick={() => this.handleTransportGroup()}>
                                        <ListItemText primary={this.state.layerGroups.transportLayers.name} />
                                        {this.state.transportLayersOpen ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                    <Collapse in={this.state.transportLayersOpen} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {this.state.layerGroups.transportLayers &&
                                                Object.keys(this.state.layers).map((key, index) => {

                                                    let icon = this.state.layers[key].icon;
                                                    if (this.state.layers[key].id === "port") icon = './port-icon.png';
                                                    else if (this.state.layers[key].id === "railroad") icon = './railroad-icon.png';

                                                    if (this.state.layers[key].layerGroupId === "transportLayers") {
                                                        return <div key={key}>
                                                            <ListItem button className={[classes.nested, classes.list_item]} onClick={() => this.handleTransports(key, index)}>
                                                                <ListItemIcon onClick={() => this.handleTransports(key, index)}>
                                                                    <img style={{ width: '25px', height: '25px' }} src={icon} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={this.state.layers[key].name} />
                                                                <Checkbox style={{ marginRight: '3px' }} edge="end" onChange={() => this.handleTransports(key, index)} checked={this.state.selectedTransport.includes(key)} />
                                                            </ListItem>
                                                        </div>
                                                    }
                                                })
                                            }
                                        </List>
                                    </Collapse>
                                </div>
                            }

                            {/* {this.state.layers.forecast10days && this.state.forecasts && this.state.layerGroups.weatherLayers &&
                                <div>
                                    <List component="div" disablePadding>
                                        <ListItem button className={classes.nested} onClick={() => this.handleForecasts()} className={classes.list_item}>
                                            <ListItemIcon onClick={() => this.handleForecasts()}>
                                                <img style={{ width: '25px', height: '25px' }} src={this.state.layers.forecast10days.icon} />
                                            </ListItemIcon>
                                            <ListItemText primary={this.state.layers.forecast10days.name} />
                                            <Checkbox style={{ marginRight: '3px' }} edge="end" onChange={() => this.handleForecasts()} checked={this.state.forecast10daysShown} />
                                        </ListItem>
                                    </List>
                                </div>
                            } */}


                            <div className={classes.menuGroupStyle} />

                            <div>
                                <ListItem button onClick={() => this.setState({ satteliteLayersOpen: !this.state.satteliteLayersOpen })} style={{ paddingTop: '16px' }}>
                                    <ListItemText primary={i18n.t('Satellite Imagery')} />
                                    {this.state.satteliteLayersOpen ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={this.state.satteliteLayersOpen} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {this.state.satteliteLayers.map((key, index) => {
                                            return <div key={key}>
                                                <ListItem button onClick={() => this.handleSatellites(key, index)} className={classes.list_item}>
                                                    <ListItemIcon onClick={() => this.handleSatellites(key, index)}>
                                                        <img style={{ width: '25px', height: '25px', borderRadius: '5px' }} src={`./satellite/${key}.png`} />
                                                    </ListItemIcon>
                                                    <ListItemText style={{ lineHeight: '1px' }} primary={this.state.satelliteLayersNames[index]} className={classes.listItemText} />
                                                    <Radio edge="end" onChange={() => this.handleSatellites(key, index)} checked={this.state.selectedSatellite === key}/>
                                                </ListItem>
                                                <Collapse disablePadding in={this.state.selectedSatellite === key}>
                                                    <List component="div" disablePadding style={{ marginTop: '-10px' }}>
                                                        <div style={{ display: 'flex', marginLeft: '-91px', }}>
                                                            <DatePicker date={this.state.date} onDateChange={this.handleDateChange} />
                                                            {/* <Tooltip
                                                                classes={{ tooltip: classes.htmlTooltip }} interactive placement={'left-end'}
                                                                title={<iframe style={iframeStyle} src={`https://manuals.agrolife.world/sentinel/${this.state.satteliteLayersIds[index]}${user.id_jezik === 1 ? '_rs' : ''}`} />}>
                                                                <IconButton color="secondary" style={{ marginRight: '1px' }}><span className="icon-info layerIcon" /></IconButton>
                                                            </Tooltip> */}
                                                            <span className="icon-info layerIcon" style={{ cursor: 'pointer', marginTop: '12px', marginRight: '12px' }} onClick={() => {
                                                                this.setState({ satteliteDialog: true });
                                                            }}></span>
                                                        </div>
                                                    </List>
                                                </Collapse>
                                            </div>
                                        })}
                                    </List>
                                </Collapse>
                            </div>

                            {this.state.sensors &&
                                <div>
                                    <List component="div" disablePadding>
                                        <ListItem button className={[classes.nested, classes.list_item]} onClick={() => this.handleMeteo()}>
                                            <ListItemIcon onClick={() => this.handleMeteo()}>
                                                <img style={{ width: '25px', height: '25px' }} src='./meteo-icon.png' />
                                            </ListItemIcon>
                                            <ListItemText primary={i18n.t('Meteo stations')} />
                                            <Checkbox style={{ marginRight: '3px' }} edge="end" onChange={() => this.handleMeteo()} checked={this.state.meteoShown} />
                                        </ListItem>
                                        {/* <div>
                                            {this.state.meteoShown === true &&
                                                <div style={{ width: '92%', marginLeft: '16px', display: 'flex' }}>
                                                    <div style={{ width: '92%' }}>
                                                        <Select
                                                            closeMenuOnSelect={false}
                                                            isClearable={false}
                                                            value={this.state.filter}
                                                            defaultValue={this.state.filter}
                                                            maxMenuHeight={200}
                                                            onChange={(e) => { this.setState({ filter: { label: e.label, value: e.value } }, () => { this.getSensorData(e.value); }); }}
                                                            options={[
                                                                { value: 39, label: 'Subotica' },
                                                                { value: 19, label: 'Sombor' },
                                                                { value: 82, label: 'Inđija' },
                                                                { value: 166, label: 'Vrbas' },
                                                                { value: 22, label: 'Bačka Topola' },
                                                                { value: 873, label: 'Srbac' },
                                                                { value: 879, label: 'Prijedor' },
                                                                { value: 890, label: 'Brod' },
                                                                { value: 911, label: 'Trebinje' },
                                                                { value: 1023, label: 'Ljubuski' },
                                                            ]}
                                                            placeholder={`‏‏‎ ‎ ‏‏‎ ‎` + i18n.t('Select states')} />
                                                    </div>
                                                    <span className={classes.sensor_span} style={{ backgroundColor: `${this.state.clearStateColor}` }}
                                                        onMouseEnter={() => { this.setState({ clearStateColor: '#e3e3e3' }) }}
                                                        onMouseLeave={() => { this.setState({ clearStateColor: '#dbdbdb' }) }}
                                                        onMouseDown={() => { this.setState({ clearStateColor: '#f0f0f0' }) }}
                                                        onClick={() => { this.setState({ filter: null }, () => { this.getSensorData(null) }); }}
                                                    >X</span>
                                                </div>
                                            }
                                        </div> */}
                                    </List>
                                </div>
                            }
                        </List>
                    </Drawer>
                </React.Fragment>
                {
                    this.state.add &&
                    <AddField
                        edit={false}
                        add={this.state.add}
                        povrsina={this.state.newFeature.attributes.povrsina}
                        handleClose={() => {
                            this.setState({ add: false, newFeature: { ...this.EMPTY_FEATURE } });
                            this.map.leafletElement.removeLayer(this.drawnFeature);

                            this.map.leafletElement.eachLayer(layer => { if (layer._path != null) { layer.remove(); } });
                            this.loadFields();
                        }}
                        onSave={this.saveField}
                    />
                }
                {
                    this.state.addNote &&
                    <AddNote
                        edit={false}
                        add={this.state.addNote}
                        handleClose={() => {
                            this.setState({ addNote: false, note_x: null, note_y: null });
                            this.map.leafletElement.removeLayer(this.drawnFeature);

                            this.map.leafletElement.eachLayer(layer => { if (layer._path != null) { layer.remove(); } });
                            this.loadFields();
                        }}
                        onSave={this.saveNote}
                    />
                }
                {this.state.openSensorDialog && <SensorDialog open={this.state.openSensorDialog} handleClick={this.handleClick} row={this.state.row} />}
                {this.state.satteliteDialog && <SatelliteInfo open={this.state.satteliteDialog} handleClick={this.handleClickSatellite} row={this.state.row} selectedSatellite={this.state.selectedSatellite} />}
                <Dialog
                    open={this.state.zoomMenu}
                    onClose={() => this.handleCloseZoomMenu()}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle style={{ backgroundColor: '#04764e', color: '#FFFFFF' }} id="simple-dialog-title">
                        <div style={{ display: 'flex' }}>
                            <p style={{ marginLeft: '20px', color: '#FFFFFF', marginTop: '0px', marginBottom: '0px' }}>{i18n.t('Zoom')}</p>
                            <IconButton style={{ margin: '0px', marginLeft: '81%', padding: '0px' }} onClick={() => this.handleCloseZoomMenu()}>
                                <span className="icon-cancel-circle closeIcon" />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <table>
                        <tr style={{ textAlign: '-webkit-center' }}>
                            <td>
                                <TextField type="number"
                                    inputProps={{ className: 'digitsOnly' }}
                                    floatingLabelText={i18n.t('Latitude')}
                                    value={this.state.zoom_latitude}
                                    style={{ margin: '10px' }}
                                    onChange={e => this.setState({ zoom_latitude: e.target.value })}
                                    helperText={i18n.t('Latitude')}
                                    id={i18n.t('Latitude')} name={i18n.t('Latitude')} />
                            </td>
                            <td>
                                <TextField type="number"
                                    inputProps={{ className: 'digitsOnly' }}
                                    floatingLabelText={i18n.t('Longitude')}
                                    value={this.state.zoom_longitude}
                                    style={{ margin: '10px' }}
                                    helperText={i18n.t('Longitude')}
                                    onChange={e => this.setState({ zoom_longitude: e.target.value })}
                                    id={i18n.t('Longitude')} name={i18n.t('Longitude')} />
                            </td>
                            <td>
                                <Button onClick={() => {
                                    if (this.state.zoom_latitude && this.state.zoom_longitude) {
                                        this.map.leafletElement.flyTo([this.state.zoom_latitude, this.state.zoom_longitude], this.state.zoom, { animate: true, duration: 0.5 });
                                    }
                                }}>{i18n.t('Zoom')}</Button>
                            </td>
                        </tr>
                    </table>
                </Dialog >
            </div >
        )
    }
};

export default withStyles(styles)(Maps);