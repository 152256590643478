/**
 * Created by pajicv on 5/31/18.
 */

import React, { Component } from 'react';

import { connect } from 'react-redux';

import 'leaflet.pm'
import 'leaflet.pm/dist/leaflet.pm.css'
import 'proj4leaflet'

import * as MapActions from "../../actions/map/MapActions";
import MapPanel from '../../components/Map/MapPanel';

import './MapContainer.css';

class MapContainer extends Component {

    updateDimensions() {
        //za sada je hardkodirano, srediti da se izracunava
        const headerHeight = 10;
        const height = window.innerWidth >= 992 ? (window.innerHeight - headerHeight) : 400;
        this.setState({
            height: height
        });
    }

    componentWillMount() {

        this.updateDimensions();

    }

    componentDidMount() {

        window.addEventListener("resize", this.updateDimensions.bind(this));

        this.props.fetchMapInit();

    }

    componentWillUnmount() {

        window.removeEventListener("resize", this.updateDimensions.bind(this))

    }

    render() {

        const {mapInitFetching, mapInitFetchingFailed} = this.props;

        if(mapInitFetching) {
            return (
                <div>
                    <p>Loading...</p>
                </div>
            )
        }

        if(mapInitFetchingFailed) {
            return (
                <div>
                    <p>Error</p>
                </div>
            )
        }

        return <MapPanel/>;

    }
}

function mapStateToProps(state) {
    return {
        mapInitFetching: state.layersReducer.mapInitFetching,
        mapInitFetchingFailed:  state.layersReducer.mapInitFetchingFailed
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchMapInit: () => dispatch(MapActions.fetchMapInit())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MapContainer);