import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import {
    TextField, Button, Grid, Avatar, Card, LinearProgress
} from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';
import DocumentGridList from '../../utils/DocumentGridList';
import { DatePicker } from 'material-ui-pickers';
import { getInputInfo, saveInputInfo } from '../../actions/FarmerInfoActions';
import moment from 'moment'
const styles = {
    card: {
        padding: '10px',
        width: '100%',
        margin: '10px',
        display: 'flex',
        flexWrap: 'wrap',
    },
}

export class InputDistribution extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputDistributionObj: {}
        };
    }

    componentDidMount() {
        const { getInputInfo, id_klijent } = this.props;
        getInputInfo(id_klijent);
    }

    componentDidUpdate(prevProps) {
        const { inputDistributionObj } = this.props;
        if (prevProps.inputDistributionObj !== inputDistributionObj) {
            this.setState({
                inputDistributionObj
            })
        }
    }

    handleChangeValue = name => event => {
        const { inputDistributionObj } = this.state;
        let obj = Object.assign({}, inputDistributionObj);
        obj[name] = event.target.value;
        this.setState({ inputDistributionObj: obj });
    };

    handleDateChange = name => date => {
        const { inputDistributionObj } = this.state;
        let obj = Object.assign({}, inputDistributionObj);
        obj[name] = moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY');
        this.setState({ inputDistributionObj: obj });
    }


    renderLabel = (date) => {
        if (date.isValid()) {
            return date.format('DD.MM.YYYY');
        } else {
            return '';
        }
    }

    saveInputInfo = () => {
        let { inputDistributionObj } = this.state;
        const { saveInputInfo, id_klijent } = this.props;
        inputDistributionObj.id_klijent = id_klijent;
        saveInputInfo(inputDistributionObj);
    }

    render() {
        const { inputDistributionObj } = this.state;
        const { farmerInfoFetching } = this.props;
        return (
            <div style={{ padding: 20 }}>
                {farmerInfoFetching && <LinearProgress />}
                <Card style={styles.card}>
                    <Grid item>
                        <TextField
                            label={i18n.t('Types of input collected')}
                            required
                            value={inputDistributionObj.types_of_input}
                            onChange={this.handleChangeValue('types_of_input')}
                            style={{ width: '256px', margin: '10px' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label={i18n.t('Number of input type collected')}
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={inputDistributionObj.number_of_input_type}
                            onChange={this.handleChangeValue('number_of_input_type')}
                            style={{ width: '256px', margin: '10px' }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label={i18n.t('Vegetation: Soil Type')}
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={inputDistributionObj.soil_type}
                            onChange={this.handleChangeValue('soil_type')}
                            style={{ width: '256px', margin: '10px' }}
                        />
                    </Grid>
                    <Grid item>
                        <DatePicker
                            keyboard
                            label={i18n.t('Date of input distribution')}
                            format="DD.MM.YYYY"
                            style={{ width: '256px', margin: '10px' }}
                            disableOpenOnEnter
                            value={inputDistributionObj.date_of_input_distribution ? moment(inputDistributionObj.date_of_input_distribution, 'DD.MM.YYYY') : new Date()}
                            style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                            onChange={this.handleDateChange('date_of_input_distribution')}
                            labelFunc={this.renderLabel}
                            animateYearScrolling={false}
                        />
                    </Grid>
                    <Grid item xl={12} style={{ paddingBottom: '20px', paddingTop: '20px' }}>
                        <DocumentGridList
                            documentArray={[]}
                            showDelete={false}
                            title={i18n.t('Farmers Photo with inputs collected')}
                        />
                    </Grid>
                    <Grid item xl={12} style={{ paddingBottom: '20px', paddingTop: '20px' }}>
                        <DocumentGridList
                            documentArray={[]}
                            showDelete={false}
                            title={i18n.t('Confirmation of input collection (Farmers biometric)')}
                        />
                    </Grid>
                </Card>
                <Grid item xs>
                    <Button
                        style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white' }}
                        onClick={() => this.saveInputInfo()}
                    >
                        {i18n.t('Save')}
                    </Button>
                </Grid>
                <Grid item xs={3}>
                </Grid>
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        inputDistributionObj: state.farmerInfoReducer.inputDistributionObj,
        farmerInfoFetching: state.farmerInfoReducer.farmerInfoFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getInputInfo: (id_klijent) => dispatch(getInputInfo(id_klijent)),
        saveInputInfo: (info) => dispatch(saveInputInfo(info))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InputDistribution)
