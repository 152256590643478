import axios from '../../utils/AxiosWrapper';
export const Type = {
    //VIEW
    GET_VIEW_SEASONS_CALL: 'GET_VIEW_SEASONS_CALL',
    GET_VIEW_SEASONS_SUCCESS: 'GET_VIEW_SEASONS_SUCCESS',
    GET_VIEW_SEASONS_FAILED: 'GET_VIEW_SEASONS_FAILED',
    //ADD
    ADD_SEASONS_CALL: 'ADD_SEASONS_CALL',
    ADD_SEASONS_SUCCESS: 'ADD_SEASONS_SUCCESS',
    ADD_SEASONS_FAILED: 'ADD_SEASONS_FAILED',
    //DROPDOWN
    GET_SEASON_CALL: 'GET_SEASON_CALL',
    GET_SEASON_SUCCESS: 'GET_SEASON_SUCCESS',
    GET_SEASON_FAILED: 'GET_SEASON_FAILED',
    //DELETE
    DELETE_SEASON_CALL: 'DELETE_SEASON_CALL',
    DELETE_SEASON_SUCCESS: 'DELETE_SEASON_SUCCESS',
    DELETE_SEASON_FAILED: 'DELETE_SEASON_FAILED',

    //SETTERS
    SET_SEASON_ROW_IN_REDUCER: 'SET_SEASON_ROW_IN_REDUCER',
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',

};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function setRowInReducer(rowInfo, name) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_SEASON_ROW_IN_REDUCER,
            rowInfo: rowInfo,
            name: name
        })
    }
}

export function addSeason(seasonObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_SEASONS_CALL
        });

        axios.post('api/season/create', seasonObj)
            .then(function (response) {
                dispatch({
                    type: Type.ADD_SEASONS_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_SEASONS_FAILED
                });
            });
    }
}

export function getSeason(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_VIEW_SEASONS_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/season/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_VIEW_SEASONS_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_VIEW_SEASONS_FAILED
                });
            });
    }
}

export function getSeasonDropDown() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_SEASON_CALL
        });

        axios.get('api/season/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_SEASON_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_SEASON_FAILED
                });
            });
    }
}

export function deleteSeason(selection, name) {
    return (dispatch) => {

        dispatch({
            type: Type.DELETE_SEASON_CALL,
        });

        axios.post(`api/${name}/delete`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_SEASON_SUCCESS,
                    data: response.data,
                    name: name,
                    forDelete: selection
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_SEASON_FAILED
                });
            });
    }
}
