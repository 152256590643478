import React from 'react';
import { Card, withStyles, } from '@material-ui/core';
import axios from '../../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../InfoStyles';

export class InfoUVIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.language === 1 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Sunčevo zračenje je glavni izvor energije za sve procese koji se dešavaju na Zemlji. 
                            Njegov intenzitet ima uticaj na hemijski sastav biljaka a samim tim i na kvalitet poljoprivrednih useva. 
                            S jedne strane, UV zračenje povoljno utiče na životni ciklus biljaka, pojačava intenzitet fotosinteze, olakšava proizvodnju hlorofila i apsorpciju hranljivih materija. 
                            S druge strane, prekomerno izlaganje UV zračenju može izazvati degradaciju proteina u ćelijama lista, što dovodi do propadanja biljaka. 
                            Opasnost nastaje kada doza zračenja prouzrokuje štetu koja prevazilazi regenerativne sposobnosti biljke, što dovodi do smanjenja prinosa i kvaliteta proizvoda. 
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                               No text
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 4 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Sunčevo zračenje glavni je izvor energije za sve procese koji se odvijaju na Zemlji. Njegov intenzitet utječe na kemijski sastav biljaka, a time i na kvalitetu poljoprivrednih usjeva.
                            S jedne strane, UV zračenje povoljno djeluje na životni ciklus biljaka, pojačava intenzitet fotosinteze, olakšava stvaranje klorofila i apsorpciju hranjivih tvari.
                            S druge strane, pretjerano izlaganje UV zračenju može uzrokovati razgradnju proteina u stanicama lista, što dovodi do propadanja biljke. Opasnost nastaje kada doza zračenja uzrokuje štetu koja premašuje regenerativne sposobnosti biljke, što dovodi do smanjenja prinosa i kvalitete proizvoda.
                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    };
};

export default withStyles(styles)(InfoUVIndex);