import React from 'react';
import i18n from '../../i18n/i18n';
import axios from '../../utils/AxiosWrapper'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Paper, Button, TextField, IconButton, Link } from '@material-ui/core';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import validator from 'validator';
/// FOOTER CSS
var style = {
    textAlign: "center",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "40px",
    lineHeight: "50px",
    width: "98%",
    color: '#ffffff',
    fontSize: 24,
}
const styles = theme => ({ primaryMain: { backgroundColor: theme.palette.primary.main } });

export class AdminReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            region: '', regions: [],
            opstina: '', opstine: [], opstinaDisabled: true,
            katastarska: '', katastarske: [], katastarskaDisabled: true,
            countRegion: '', countOpstina: '', countKatastarska: '', countRegionAll: '', countOpstinaAll: '',
        };
    }
    componentDidMount() {
        this.getByURL('api/admin_report/filter_region', 'regions');
    }

    getByURL(url, obj, value, name) {
        // url: api/'url' // obj: this.state.obj // value: params value // name: params value name
        value = value || '';
        name = name || '';
        if (value != null) {
            axios.get(`/${url}`, { params: { [name]: value } })
                .then(res => this.setState({ [obj]: res.data.data }))
                .catch(error => console.log('Something went wrong, please try again!'));
        }
    }
    getAdminReportDataRegion(region) {
        axios.get('api/admin_report/admin_data_region', { params: { region: region } })
            .then(res => { this.setState({ countRegion: res.data.data[0].count }); })
            .catch(error => console.log('Something went wrong, please try again!'));
    }

    getAdminReportDataOpstina(region, opstina) {
        axios.get('api/admin_report/admin_data_opstina', { params: { region: region, opstina: opstina } })
            .then(res => { this.setState({ countOpstina: res.data.data[0].count }); })
            .catch(error => console.log('Something went wrong, please try again!'));
    }

    getAdminReportDataRegionAll(region) {
        axios.get('api/admin_report/admin_data_region_all', { params: { region: region } })
            .then(res => { this.setState({ countRegionAll: res.data.data[0].count }); })
            .catch(error => console.log('Something went wrong, please try again!'));
    }

    getAdminReportDataOpstinaAll(region, opstina) {
        axios.get('api/admin_report/admin_data_opstina_all', { params: { region: region, opstina: opstina } })
            .then(res => { this.setState({ countOpstinaAll: res.data.data[0].count }); })
            .catch(error => console.log('Something went wrong, please try again!'));
    }

    getAdminReportDataKatastarska(region, opstina, katastarska) {
        axios.get('api/admin_report/admin_data_katastarska', { params: { region: region, opstina: opstina, katastarska: katastarska } })
            .then(res => { this.setState({ countKatastarska: res.data.data[0].count }); })
            .catch(error => console.log('Something went wrong, please try again!'));
    }

    render() {
        const { classes } = this.props;
        return (
            <div style={{ 'padding': '20px' }}>
                <TextField required
                    label={i18n.t('Region')}
                    select
                    helperText={i18n.t('Region')}
                    value={this.state.region}
                    SelectProps={{ native: true, }}
                    style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                    onChange={(e) => {
                        this.setState({
                            region: e.target.value,
                            opstina: '',
                            opstinaDisabled: false,
                            katastarska: '',
                            katastarskaDisabled: true,
                        }, () => {
                            this.getAdminReportDataRegion(this.state.region);
                            this.getAdminReportDataRegionAll(this.state.region);
                        });
                        this.getByURL('api/admin_report/filter_opstina_uslov', 'opstine', e.target.value, 'id_region');
                    }} >
                    <option value='' />
                    {this.state.regions.map((regions) => <option key={regions.id} value={regions.id}>{regions.naziv}</option>)}
                </TextField>
                <TextField required
                    label={i18n.t('State')}
                    select
                    helperText={i18n.t('State')}
                    disabled={this.state.opstinaDisabled}
                    value={this.state.opstina}
                    SelectProps={{ native: true, }}
                    style={{ width: '256px', marginBottom: '10px', marginRight: '48px' }}
                    onChange={(e) => {
                        this.setState({
                            opstina: e.target.value,
                            katastarska: '',
                            katastarskaDisabled: false,
                        }, () => {
                            this.getAdminReportDataOpstina(this.state.region, this.state.opstina);
                            this.getAdminReportDataOpstinaAll(this.state.region, this.state.opstina);
                        });
                        this.getByURL('api/admin_report/filter_kat_opstina_uslov', 'katastarske', e.target.value, 'id_opstina');
                    }} >
                    <option value='' />
                    {this.state.opstine.map((opstine) => <option key={opstine.id} value={opstine.id}>{opstine.naziv}</option>)}
                </TextField>
                {this.state.region !== '' ? <div>
                    <h2>{i18n.t('Total number of registered farms(TENANTS) in the region')}: {this.state.countRegion}</h2>
                </div> : null}
                {this.state.opstina !== '' ? <div>
                    <h2>{i18n.t('Total number of registered farms(TENANTS) in the municipality')}: {this.state.countOpstina}</h2>
                </div> : null}
                {this.state.region !== '' ? <div>
                    <h2>{i18n.t('Total number of registered farms in the region')}: {this.state.countRegionAll}</h2>
                </div> : null}
                {this.state.opstina !== '' ? <div>
                    <h2>{i18n.t('Total number of registered farms in the municipality')}: {this.state.countOpstinaAll}</h2>
                </div> : null}
                {this.state.katastarska !== '' ? <div>
                    <h2>{i18n.t('Total number of registered farms in the cadastral municipality')}: {this.state.countKatastarska}</h2>
                </div> : null}
            </div>
        )
    }
}

export default AdminReport;