import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import RegionLayerGroup from './RegionLayerGroup';
import RegionLayer from './RegionLayer';
import Fab from '@material-ui/core/Fab';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { deleteRegionLayer, setInitialState } from '../../actions/SuperAdminActions';
import { toast } from 'react-toastify';
import { Delete } from "../../utils/Delete";
const options = [
    i18n.t('Region layer'),
    i18n.t('Region layer group')
]
export class AdminMainPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            add: false,
            showFilters: false,
            anchorEl: null,
            delete: false,
            selectedIndex: 0,
            selection: [],
            name: '',
            edit: false,
        };
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.deleted === true) {
            this.setState({
                selection: [],
                name: '',
            })
        }
        // if (nextProps.materialPostingSuccess == true || nextProps.materialGroupPostingSuccess == true || nextProps.materialSubgroupsPostingSuccess == true) {
        //     this.setState({
        //         selection: [],
        //         name: '',
        //     })
        // }
        if (nextProps.deleteFailed === true) {
            toast.error(i18n.t('This item can not be deleted!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('deleteFailed');
        }
    }


    handleClick = () => {
        if (this.state.edit == true && this.state.add == false) {
            this.setState({ edit: false })
        }
        this.setState({ add: !this.state.add }, () => {
            if (this.state.add == true && this.state.edit == false) {
                this.setState({
                    selection: [],
                    name: '',
                })
            };
        });
    };


    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    handleClickAnchor = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleCloseAnchor = () => {
        this.setState({ anchorEl: null });
    };
    handleMenuItemClick = (event, index) => {
        this.setState({ selectedIndex: index, anchorEl: null, selection: [], name: '' });
    };


    handleSelection = (selection, name) => {
        this.setState({
            selection: selection,
            name: name
        })
    }

    onEditClick() {
        this.setState({ add: !this.state.add, edit: true });
    }


    onDeleteClick = () => {
        this.setState({ delete: !this.state.delete });
    }

    deleteItem = () => {
        this.props.deleteRegionLayer(this.state.selection, this.state.name);
        this.setState({ delete: false })
    }



    render() {
        let content;
        const { anchorEl } = this.state;
        content =
            <div className="table-header">
                <Fab
                    onClick={this.handleClick}
                    color="primary"
                    aria-label="add"
                    className="action-button"
                >
                    <span className="icon-Plus headerIcon" />
                </Fab>
                <Fab
                    onClick={() => this.onSearchClick()}
                    color="primary"
                    aria-label="add"
                    className="action-button"
                >
                    <span className="icon-search headerIcon" />
                </Fab>
                <Fab color="primary" aria-label="add" className="action-button"
                    aria-owns={anchorEl ? 'simple-menu' : null}
                    aria-haspopup="true"
                    onClick={this.handleClickAnchor}>
                    <span className="icon-Vise headerIcon" />
                </Fab>
                <Menu
                    id="lock-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleCloseAnchor}
                >
                    {options.map((option, index) => (
                        <MenuItem
                            key={option}
                            selected={index === this.state.selectedIndex}
                            onClick={event => this.handleMenuItemClick(event, index)}
                        >
                            {i18n.t(option)}
                        </MenuItem>
                    ))}
                </Menu>
                {this.state.selection.length > 0 ?
                    <Fab
                        onClick={() => this.onDeleteClick()}
                        color="primary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-Kanta headerIcon" />
                    </Fab>
                    : null
                }
                {this.state.selection.length === 1 ?
                    <Fab
                        onClick={() => this.onEditClick()}
                        color="primary"
                        aria-label="edit"
                        className="action-button"
                    >
                        <span className="icon-Izmeni1 headerIcon" />
                    </Fab>
                    : null
                }
            </div >
        return (
            <div>
                {content}
                {this.state.selectedIndex === 0 &&
                    <RegionLayer setSelection={this.handleSelection} edit={this.state.edit} add={this.state.add} showFilters={this.state.showFilters} handleClose={this.handleClick} />
                }
                {this.state.selectedIndex === 1 &&
                    <RegionLayerGroup setSelection={this.handleSelection} edit={this.state.edit} add={this.state.add} showFilters={this.state.showFilters} handleClose={this.handleClick} />
                }
                {this.state.delete && <Delete delete={this.state.delete} handleClose={this.onDeleteClick} deleteItem={this.deleteItem} />}
            </div >
        )
    }
};

function mapStateToProps(state) {
    return {
        deleted: state.superAdminReducer.deleted,
        deleteFailed: state.superAdminReducer.deleteFailed,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deleteRegionLayer: (selection, name) => dispatch(deleteRegionLayer(selection, name)),
        setInitialState: (component) => dispatch(setInitialState(component)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminMainPage);