import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setInitialState, getFormType, getModuleType, addFormModule } from '../../../actions/control_panel/FormModuleActions';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

export class AddFormModules extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formModuleObj: this.props.formModuleObj,
        }
    }

    componentDidMount() {
        if (this.props.formType.length === 0) {
            this.props.getFormType();
        }
        if (this.props.moduleType.length === 0) {
            this.props.getModuleType();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.formModulePostingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('formModulePostingFailed');
        }
    }

    addFormModule() {
        if (this.state.formModuleObj.id_modul &&
            this.state.formModuleObj.id_form) {
            this.props.addFormModule({
                id_modul: this.state.formModuleObj.id_modul,
                id_form: this.state.formModuleObj.id_form,
                aktivan: this.state.formModuleObj.aktivan,
            })
        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    handleChangeValue = name => event => {
        let obj = Object.assign({}, this.state.formModuleObj);
        obj[name] = event.target.value;
        this.setState({ formModuleObj: obj });
    };

    handleSwitch = name => event => {
        let obj = Object.assign({}, this.state.formModuleObj);
        obj[name] = !this.state.formModuleObj.aktivan;
        this.setState({ formModuleObj: obj });
    };

    render() {
        let formType = [];
        if (this.props.formType && !this.props.formTypeFetching) {
            formType = this.props.formType.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv_prozora}</option>
            });
        }
        let moduleType = [];
        if (this.props.moduleType && !this.props.moduleTypeFetching) {
            moduleType = this.props.moduleType.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }

        return (
            <div style={{ padding: 20 }}>

                <Dialog open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit Form Module') : i18n.t('Add Form Module')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-form-module">
                            <Grid container justify="space-between" alignContent="space-between" >
                                <Grid item>
                                    <TextField required
                                        label={i18n.t('Type of module')}
                                        select
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        defaultValue={this.state.formModuleObj.id_modul}
                                        onChange={this.handleChangeValue('id_modul')} >
                                        <option value='' />
                                        {moduleType}
                                    </TextField>
                                </Grid>
                                <Grid item >
                                    <TextField
                                        label={i18n.t('Type of form')}
                                        select
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={this.handleChangeValue('id_form')} >
                                        <option value='' />
                                        {formType}
                                    </TextField>
                                </Grid>
                                <Grid item container>
                                    <Typography>{i18n.t('Active')}</Typography>
                                    <Switch
                                        checked={this.state.formModuleObj.aktivan}
                                        onChange={this.handleSwitch('aktivan')}
                                        name="aktivan"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', marginTop: '20px', marginBottom: '10px' }}
                                        onClick={() => this.addFormModule()}
                                        disabled={this.props.formModulePosting} >
                                        {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        formModulePosting: state.formModuleReducer.formModulePosting,
        formModulePostingSuccess: state.formModuleReducer.formModulePostingSuccess,
        formModuleObj: state.formModuleReducer.formModuleObj,
        formModulePostingFailed: state.formModuleReducer.formModulePostingFailed,

        formType: state.formModuleReducer.formType,
        formTypeFetching: state.formModuleReducer.formTypeFetching,
        formTypeFetchingFailed: state.formModuleReducer.formTypeFetchingFailed,

        moduleType: state.formModuleReducer.moduleType,
        moduleTypeFetching: state.formModuleReducer.moduleTypeFetching,
        moduleTypeFetchingFailed: state.formModuleReducer.moduleTypeFetchingFailed,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInitialState: (component) => dispatch(setInitialState(component)),

        addFormModule: (formModuleObj) => dispatch(addFormModule(formModuleObj)),

        getFormType: () => dispatch(getFormType()),
        getModuleType: () => dispatch(getModuleType()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddFormModules);