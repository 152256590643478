import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n/i18n';
import { Fab, MenuItem, Menu } from '@material-ui/core';
import ViewWorkingOperation from './ViewWorkingOperation'
import ViewWorkingOperationGroup from './ViewWorkingOperationGroup';
import { toast } from 'react-toastify';
import { deleteWorkingOperation, setInitialState } from '../../../actions/resources/WorkingOperationsActions';
import { Delete } from "../../../utils/Delete";

const options = [
    i18n.t('Working operation'),
    i18n.t('Working operation group')
]
export class WorkingOperationMainComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            add: false,
            showFilters: false,
            anchorEl: null,
            delete: false,
            selectedIndex: 0,
            selection: [],
            name: '',
            edit: false,
        };
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.deleted === true) {
            this.setState({
                selection: [],
                name: '',
            })
        }
        if (nextProps.workingOperationPostingSuccess == true || nextProps.workingOperationGroupsPostingSuccess == true) {
            this.setState({
                selection: [],
                name: '',
            })
        }
        if (nextProps.deleteOperationFailed === true) {
            toast.error(i18n.t('This item can not be deleted!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('deleteOperationFailed');
        }
    }
    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    handleClick = () => {
        if (this.state.edit == true && this.state.add == false) {
            this.setState({ edit: false })
        }
        this.setState({ add: !this.state.add }, () => {
            if (this.state.add == true && this.state.edit == false) {
                this.setState({
                    selection: [],
                    name: '',
                })
            };
        });
    };


    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    handleClickAnchor = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleCloseAnchor = () => {
        this.setState({ anchorEl: null });
    };
    handleMenuItemClick = (event, index) => {
        this.setState({ selectedIndex: index, anchorEl: null, selection: [], name: '' });
    };


    handleSelection = (selection, name) => {
        this.setState({
            selection: selection,
            name: name
        })
    }

    onDeleteClick = () => {
        this.setState({ delete: !this.state.delete });
    }

    deleteItem = () => {
        this.props.deleteWorkingOperation(this.state.selection, this.state.name);
        this.setState({ delete: false })
    }



    onEditClick() {
        this.setState({ add: !this.state.add, edit: true });
    }


    render() {
        let user = this.getUser();
        let content;
        const { anchorEl } = this.state;
        content =
            <div className="table-header">
                {user && user.id_klijent_grupa === 8 &&
                    <Fab
                        onClick={this.handleClick}
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-Plus headerIcon" />
                    </Fab>
                }
                <Fab
                    onClick={() => this.onSearchClick()}
                    color="secondary"
                    aria-label="add"
                    className="action-button"
                >
                    <span className="icon-search headerIcon" />
                </Fab>
                <Fab color="secondary" aria-label="add" className="action-button"
                    aria-owns={anchorEl ? 'simple-menu' : null}
                    aria-haspopup="true"
                    onClick={this.handleClickAnchor}>
                    <span className="icon-Vise headerIcon" />
                </Fab>
                <Menu
                    id="lock-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleCloseAnchor}
                >
                    {options.map((option, index) => (
                        <MenuItem
                            key={option}
                            selected={index === this.state.selectedIndex}
                            onClick={event => this.handleMenuItemClick(event, index)}
                        >
                            {i18n.t(option)}
                        </MenuItem>
                    ))}
                </Menu>
                {user && user.id_klijent_grupa === 8 && this.state.selection.length > 0 ?
                    <Fab
                        onClick={() => this.onDeleteClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-Kanta headerIcon" />
                    </Fab>
                    : null
                }
                {user && user.id_klijent_grupa === 8 && this.state.selection.length === 1 ?
                    <Fab
                        onClick={() => this.onEditClick()}
                        color="secondary"
                        aria-label="edit"
                        className="action-button"
                    >
                        <span className="icon-Izmeni1 headerIcon" />
                    </Fab>
                    : null
                }
            </div >
        return (
            <div>
                {content}
                {this.state.selectedIndex === 0 && <ViewWorkingOperation setSelection={this.handleSelection} edit={this.state.edit} add={this.state.add} showFilters={this.state.showFilters}
                    handleClose={this.handleClick} />}
                {this.state.selectedIndex === 1 && <ViewWorkingOperationGroup setSelection={this.handleSelection} edit={this.state.edit} add={this.state.add} showFilters={this.state.showFilters}
                    handleClose={this.handleClick} />}
                {this.state.delete && <Delete delete={this.state.delete} handleClose={this.onDeleteClick} deleteItem={this.deleteItem} />}
            </div>
        )
    }


};

function mapStateToProps(state) {
    return {
        deleteOperationFailed: state.workingOperationReducer.deleteOperationFailed,
        deleted: state.workingOperationReducer.deleted,
        workingOperationGroupsPostingSuccess: state.workingOperationReducer.workingOperationGroupsPostingSuccess,
        workingOperationPostingSuccess: state.workingOperationReducer.workingOperationPostingSuccess
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deleteWorkingOperation: (selection, name) => dispatch(deleteWorkingOperation(selection, name)),
        setInitialState: (component) => dispatch(setInitialState(component)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkingOperationMainComponent)