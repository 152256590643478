import React from 'react';
import { connect } from 'react-redux';
import {
    Button, IconButton, Menu, MenuItem
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { Doughnut, Bar, Pie, HorizontalBar } from 'react-chartjs-2';
import immutable from 'seamless-immutable';
import { getParcelDashboards, getOwnershipDropDown } from '../../actions/catastral_data/ParcelActions';
import { getFieldsDashboards, getTypeProductiondDropDown } from '../../actions/catastral_data/FieldActions';
import Select, { components } from 'react-select';
import InputLabel from '@material-ui/core/InputLabel';
import i18n from '../../i18n/i18n';
import randomColor from 'randomcolor';
import {
    getLocalGov
} from '../../actions/FarmRegistarActions';
import { getCrop } from '../../actions/resources/CropActions';
const IndicatorsContainer = (props) => {
    return (
        <div style={{ background: '#61a50e' }}>
            <components.IndicatorsContainer {...props} />
        </div>
    );
};
export class MapDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            anchorElTable: null,
            id_vlasnistvo: [],
            id_katastarska_opstina: [],
            id_kultura: [],
            id_vrsta_proizvodnje: []
        };
    }

    componentDidMount() {
        this.props.getParcelDashboards({});
        this.props.getFieldsDashboards({});
        if (this.props.ownershipDropDown.length == 0) {
            this.props.getOwnershipDropDown();
        }
        if (this.props.localGoverments.length == 0) {
            this.props.getLocalGov();
        }
        if (this.props.crops.length === 0) {
            this.props.getCrop();
        }
        if (this.props.typeProductionDropDown.length === 0) {
            this.props.getTypeProductiondDropDown();
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            localGoverments: immutable.asMutable(nextProps.localGoverments.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            ownershipDropDown: immutable.asMutable(nextProps.ownershipDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            crops: immutable.asMutable(nextProps.crops.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            typeProductionDropDown: immutable.asMutable(nextProps.typeProductionDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
        })
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClickTable = event => {
        this.setState({ anchorElTable: event.currentTarget });
    };

    handleCloseTable = () => {
        this.setState({
            anchorElTable: null
        })
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleClear = name => event => {
        if (name === 'parcels') {
            this.setState({
                id_katastarska_opstina: [],
                id_vlasnistvo: [],
            }, function () {
                this.props.getParcelDashboards({});
            });
        } else {
            this.setState({
                id_kultura: [],
                id_vrsta_proizvodnje: []
            }, function () {
                this.props.getFieldsDashboards({});
            });
        }
    };

    handleOk = name => event => {
        let filters = {};
        if (name === 'parcels') {
            filters['id_katastarska_opstina'] = this.state.id_katastarska_opstina.map((item) => {
                return item.value;
            })
            filters['id_vlasnistvo'] = this.state.id_vlasnistvo.map((item) => {
                return item.value;
            });
            this.props.getParcelDashboards(filters);
        } else {
            filters['id_kultura'] = this.state.id_kultura.map((item) => {
                return item.value;
            })
            filters['id_vrsta_proizvodnje'] = this.state.id_vrsta_proizvodnje.map((item) => {
                return item.value;
            });
            this.props.getFieldsDashboards(filters);
        }
    }

    handleChange = name => selectedOption => {
        this.setState({
            [name]: selectedOption
        })
    }


    render() {
        const { anchorEl, anchorElTable } = this.state;
        let parcelData = this.parcels;
        let fieldCrop = this.fieldCrop;
        let fieldProduction = this.fieldProduction;
        let fieldSeason = this.fieldSeason;
        let ownershipData = this.ownershipData;
        if (this.props.parcelItems && this.props.parcelItems.LocalGov) {
            parcelData.labels = this.props.parcelItems.LocalGov.labels.asMutable();
            parcelData.datasets[0].data = this.props.parcelItems.LocalGov.count.asMutable();
            parcelData.datasets[0].data1 = this.props.parcelItems.LocalGov.percent.asMutable();
        }

        if (this.props.parcelItems && this.props.parcelItems.Ownership) {
            ownershipData.labels = this.props.parcelItems.Ownership.labels.asMutable();
            ownershipData.datasets[0].data = this.props.parcelItems.Ownership.count.asMutable();
            ownershipData.datasets[0].data1 = this.props.parcelItems.Ownership.percent.asMutable();
        }

        if (this.props.fieldItems && this.props.fieldItems.Crop) {
            fieldCrop.labels = this.props.fieldItems.Crop.labels.asMutable();
            fieldCrop.datasets[0].data = this.props.fieldItems.Crop.count.asMutable();
            fieldCrop.datasets[0].data1 = this.props.fieldItems.Crop.percent.asMutable();
        }

        if (this.props.fieldItems && this.props.fieldItems.TypeProduction) {
            fieldProduction.labels = this.props.fieldItems.TypeProduction.labels.asMutable();
            fieldProduction.datasets[0].data = this.props.fieldItems.TypeProduction.count.asMutable();
            fieldProduction.datasets[0].data1 = this.props.fieldItems.TypeProduction.percent.asMutable();
        }

        if (this.props.fieldItems && this.props.fieldItems.Layer) {
            fieldSeason.labels = this.props.fieldItems.Layer.labels.asMutable();
            fieldSeason.datasets[0].data = this.props.fieldItems.Layer.count.asMutable();
            fieldSeason.datasets[0].data1 = this.props.fieldItems.Layer.percent.asMutable();
        }


        return (
            <div style={{ 'padding': '20px' }}>

                <Card style={styles.card}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <h1>{i18n.t('Total number of fields')} : {this.props.parcelItems.total} </h1>
                        {/* <div className="filter-panel-dashboard">
                            <IconButton
                                aria-owns={anchorEl ? 'simple-menu' : null}
                                aria-haspopup="true"
                                className="action-button-filter"
                                onClick={this.handleClick}
                            >
                                <span className="icon-search headerIcon" />
                            </IconButton>
                        </div> */}
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={this.handleClose}
                        >
                            <InputLabel className="filter-label">{i18n.t('Local governments')}:</InputLabel>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                isClearable={false}
                                value={this.state.id_katastarska_opstina}
                                components={{
                                    IndicatorsContainer: IndicatorsContainer
                                }}
                                maxMenuHeight={150}
                                onChange={this.handleChange('id_katastarska_opstina')}
                                options={this.state.localGoverments}
                                className="multi-select-in-filter-dashboards"
                                classNamePrefix="select"
                                placeholder={i18n.t('Select')}
                            />
                            <InputLabel className="filter-label">{i18n.t('Ownership')}:</InputLabel>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                isClearable={false}
                                value={this.state.id_vlasnistvo}
                                components={{
                                    IndicatorsContainer: IndicatorsContainer
                                }}
                                maxMenuHeight={150}
                                onChange={this.handleChange('id_vlasnistvo')}
                                options={this.state.ownershipDropDown}
                                className="multi-select-in-filter-dashboards"
                                classNamePrefix="select"
                                placeholder={i18n.t('Select')}
                            />
                            <div style={{ padding: '30px' }}>
                                <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleOk('parcels')}>{i18n.t('Ok')}</Button>
                                <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleClose}>{i18n.t('Cancel')}</Button>
                                <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleClear('parcels')}>{i18n.t('Clear')}</Button>

                            </div>
                        </Menu>
                    </div>
                </Card>
                <Card style={styles.cardMin}>
                    <h1>{i18n.t('Fields by local governments')}:</h1>
                    <Pie data={parcelData} options={this.options} />
                </Card>

                <Card style={styles.cardMin}>
                    <h1>{i18n.t('Parcels by ownership')}:</h1>
                    <Pie data={ownershipData} options={this.options} />
                </Card>

                <Card style={styles.card}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <h1>{i18n.t('Total number of fields')} : {this.props.fieldItems.total} </h1>
                        <div className="filter-panel-dashboard">
                            <IconButton
                                styles={styles.button}
                                aria-owns={anchorElTable ? 'simple-menu' : null}
                                aria-haspopup="true"
                                className="action-button-filter"
                                onClick={this.handleClickTable}
                            >
                                <span className="icon-search headerIcon" />
                            </IconButton>
                        </div>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorElTable}
                            open={Boolean(anchorElTable)}
                            onClose={this.handleCloseTable}
                        >
                            <InputLabel className="filter-label">{i18n.t('Type of production')}:</InputLabel>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                isClearable={false}
                                value={this.state.id_vrsta_proizvodnje}
                                components={{
                                    IndicatorsContainer: IndicatorsContainer
                                }}
                                maxMenuHeight={150}
                                onChange={this.handleChange('id_vrsta_proizvodnje')}
                                options={this.state.typeProductionDropDown}
                                className="multi-select-in-filter-dashboards"
                                classNamePrefix="select"
                                placeholder={i18n.t('Select')}
                            />
                            <InputLabel className="filter-label">{i18n.t('Crops')}:</InputLabel>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                isClearable={false}
                                value={this.state.id_kultura}
                                components={{
                                    IndicatorsContainer: IndicatorsContainer
                                }}
                                maxMenuHeight={150}
                                onChange={this.handleChange('id_kultura')}
                                options={this.state.crops}
                                className="multi-select-in-filter-dashboards"
                                classNamePrefix="select"
                                placeholder={i18n.t('Select')}
                            />
                            <div style={{ padding: '30px' }}>
                                <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleOk('field')}>{i18n.t('Ok')}</Button>
                                <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleCloseTable}>{i18n.t('Cancel')}</Button>
                                <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleClear('field')}>{i18n.t('Clear')}</Button>

                            </div>
                        </Menu>
                    </div>
                </Card>
                <Card style={styles.cardMin}>
                    <h1>{i18n.t('Fields by production')}:</h1>
                    <Pie data={fieldProduction} options={this.options} />
                </Card>
                <Card style={styles.cardMin}>
                    <h1>{i18n.t('Fields by season')}:</h1>
                    <Pie data={fieldSeason} options={this.options} />
                </Card>
                <HorizontalBar height={150} data={fieldCrop} options={this.options} />
            </div>
        )
    }

    parcelsColor = randomColor({
        count: 30
    })

    fieldCropColor = randomColor({
        count: 100
    })

    fieldProductionColor = randomColor({
        count: 10
    })

    fieldSeasonColor = randomColor({
        count: 10
    })

    ownershipDataColor = randomColor({
        count: 10
    })

    parcels = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.parcelsColor,
            hoverBackgroundColor: this.parcelsColor
        }]
    };
    ownershipData = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.ownershipDataColor,
            hoverBackgroundColor: this.ownershipDataColor
        }]
    };
    fieldCrop = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            label: i18n.t('Crops on fields'),
            backgroundColor: this.fieldCropColor,
            borderColor: this.fieldCropColor,
            borderWidth: 1,
            hoverBackgroundColor: this.fieldCropColor,
            hoverBorderColor: this.fieldCropColor,
        }]
    };
    fieldProduction = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.fieldProductionColor,
            hoverBackgroundColor: this.fieldProductionColor
        }]
    };
    fieldSeason = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.fieldSeasonColor,
            hoverBackgroundColor: this.fieldSeasonColor
        }]
    };
    options = {
        tooltips: {
            mode: 'label',
            callbacks: {

                afterLabel: function (tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex].data1[tooltipItem.index] + '%';
                },

            },
        }
    };


};

function mapStateToProps(state) {
    return {
        parcelItems: state.parcelReducer.parcelItems,
        fieldItems: state.fieldReducer.fieldItems,
        localGoverments: state.farmRegistarReducer.localGoverments,
        ownershipDropDown: state.parcelReducer.ownershipDropDown,
        typeProductionDropDown: state.fieldReducer.typeProductionDropDown,
        crops: state.cropReducer.crops,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getParcelDashboards: (filters) => dispatch(getParcelDashboards(filters)),
        getFieldsDashboards: (filters) => dispatch(getFieldsDashboards(filters)),
        getLocalGov: () => dispatch(getLocalGov()),
        getOwnershipDropDown: () => dispatch(getOwnershipDropDown()),
        getCrop: () => dispatch(getCrop()),
        getTypeProductiondDropDown: () => dispatch(getTypeProductiondDropDown()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MapDashboard);

const styles = {
    card: {
        minWidth: 275,
        padding: '10px',
        margin: '10px'
    },
    cardMin: {
        width: '45%',
        padding: '10px',
        margin: '10px',
        position: 'relative',
        float: 'left'
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
};