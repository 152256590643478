import immutable from 'seamless-immutable';
import { Type as ExportType } from '../actions/ExportActions';


export const INITIAL_STATE = immutable({
    exportFetching: false,
    exportFetchingSuccess: false,
    exportProgress: null
});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case ExportType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;

        case ExportType.EXPORT_PROGRESS: {
            const exportFetching = true;
            const exportProgress = action.data;
            return state.merge({ exportFetching, exportProgress });
            break;
        }

        case ExportType.EXPORT_SUCCESS: {
            const exportFetchingSuccess = true;
            const exportFetching = false;
            return state.merge({ exportFetching, exportFetchingSuccess });
            break;
        }

        case ExportType.EXPORT_FAILED: {
            const exportFetching = false;
            return state.merge({ exportFetching });
            break;
        }

        default:
            return state;
    }
}