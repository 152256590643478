import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import {
    TextField, Button, Grid, Card, LinearProgress
} from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';
import { getAnchorCOS, saveAnchorCOS } from '../../actions/FarmerInfoActions';
import { getAnchorDropdown, getCosDropdown } from '../../actions/AdministrationActions';
const styles = {
    card: {
        padding: '10px',
        width: '100%',
        margin: '10px',
        display: 'flex',
        flexWrap: 'wrap',
    },
}

export class AnchorCOS extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorCOSObj: {}
        };
    }

    componentDidMount() {
        const { getAnchorCOS, id_klijent, farmerObj } = this.props;
        this.props.getAnchorDropdown(farmerObj.id_katastarska_opstina);
        this.props.getCosDropdown(farmerObj.id_katastarska_opstina);
        getAnchorCOS(id_klijent);
    }

    componentDidUpdate(prevProps) {
        const { anchorCOSObj } = this.props;
        if (prevProps.anchorCOSObj !== anchorCOSObj) {
            this.setState({
                anchorCOSObj
            })
        }
    }

    handleChangeValue = name => event => {
        const { anchorCOSObj } = this.state;
        let obj = Object.assign({}, anchorCOSObj);
        obj[name] = event.target.value;
        this.setState({ anchorCOSObj: obj }, () => {
            if (name == 'id_anchor') this.props.getCosDropdown(this.props.farmerObj.id_katastarska_opstina, this.state.anchorCOSObj.id_anchor);
        });
    };

    saveAnchorCOS = () => {
        let { anchorCOSObj } = this.state;
        const { saveAnchorCOS, id_klijent } = this.props;
        anchorCOSObj.id_klijent = id_klijent;
        saveAnchorCOS(anchorCOSObj);
    }

    render() {
        const { anchorCOSObj } = this.state;
        const { farmerInfoFetching, farmerObj } = this.props;

        let cosDropdown = [];
        if (this.props.cosDropdown) {
            cosDropdown = this.props.cosDropdown.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.kontakt_osoba}</option>
            })
        }
        let anchorDropdown = [];
        if (this.props.anchorDropdown && !this.props.anchorDropdownFetching) {
            anchorDropdown = this.props.anchorDropdown.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.kontakt_osoba}</option>
            })
        }
        return (
            <div style={{ padding: 20 }}>
                {farmerInfoFetching && <LinearProgress />}
                <Card style={styles.card}>
                    <Grid item>
                        <TextField
                            select
                            InputLabelProps={{ shrink: true, }}
                            label={i18n.t('Anchor')}
                            SelectProps={{ native: true, }}
                            value={anchorCOSObj.id_anchor}
                            onChange={this.handleChangeValue('id_anchor')}
                            margin="normal"
                            style={{ width: '256px', margin: '10px' }}
                        >
                            <option value='' />
                            {anchorDropdown}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField
                            select
                            InputLabelProps={{ shrink: true, }}
                            label={i18n.t('Cluster')}
                            SelectProps={{ native: true, }}
                            value={anchorCOSObj.id_cos}
                            onChange={this.handleChangeValue('id_cos')}
                            margin="normal"
                            style={{ width: '256px', margin: '10px' }}
                        >
                            <option value='' />
                            {cosDropdown}
                        </TextField>
                    </Grid>
                </Card>
                <Grid item xs>
                    <Button
                        style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white' }}
                        onClick={() => this.saveAnchorCOS()}
                    >
                        {i18n.t('Save')}
                    </Button>
                </Grid>
                <Grid item xs={3}>
                </Grid>
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        anchorCOSObj: state.farmerInfoReducer.anchorCOSObj,
        farmerInfoFetching: state.farmerInfoReducer.farmerInfoFetching,
        cosDropdown: state.administrationReducer.cosDropdown,
        anchorDropdown: state.administrationReducer.anchorDropdown,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAnchorCOS: (id_klijent) => dispatch(getAnchorCOS(id_klijent)),
        saveAnchorCOS: (info) => dispatch(saveAnchorCOS(info)),
        getAnchorDropdown: (id_katastarska_opstina) => dispatch(getAnchorDropdown(id_katastarska_opstina)),
        getCosDropdown: (id_katastarska_opstina, id_anchor) => dispatch(getCosDropdown(id_katastarska_opstina, id_anchor)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AnchorCOS)
