import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setInitialState, getRegionType, addRegion } from '../../../actions/control_panel/RegionActions';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { getLanguages, getCurrencyes } from '../../../actions/LoginActions';
export class AddRegion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            regionObj: this.props.regionObj,
        }
    }

    componentDidMount() {
        if (this.props.regionType.length === 0) {
            this.props.getRegionType();
        }
        this.props.getLanguages();
        this.props.getCurrencyes();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.regionPostingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('regionPostingFailed');
        }
    }

    addRegion() {
        if (this.state.regionObj.naziv) {
            this.props.addRegion(
                this.state.regionObj,
            )
        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    handleChangeValue = name => event => {
        let obj = Object.assign({}, this.state.regionObj);
        obj[name] = event.target.value;
        this.setState({ regionObj: obj });
    };

    handleLock = name => event => {
        let obj = Object.assign({}, this.state.regionObj);
        obj[name] = !this.state.regionObj.zakljucan;
        this.setState({ regionObj: obj });
    };

    handleReal = name => event => {
        let obj = Object.assign({}, this.state.regionObj);
        obj[name] = !this.state.regionObj.pravi;
        this.setState({ regionObj: obj });
    };

    render() {

        let languages = [];
        if (this.props.languages) {
            languages = this.props.languages.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }

        let currencyes = [];
        if (this.props.currencyes) {
            currencyes = this.props.currencyes.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }

        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="xs"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">
                        {this.props.edit ? i18n.t('Lock or set region as real') : i18n.t('Add region')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-region">
                            <Grid container justify="space-between" alignContent="space-between" >
                                {!this.props.edit &&
                                    <Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField required
                                                label={i18n.t('Region name')}
                                                style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                                value={this.state.regionObj.naziv}
                                                onChange={this.handleChangeValue('naziv')}
                                            />
                                        </Grid>

                                    </Grid>}
                                {this.props.edit &&
                                    <Grid>
                                        <Grid item xs={6} sm={6} container>
                                            <Typography>{i18n.t('Locked')}</Typography>
                                            <Switch checked={this.state.regionObj.zakljucan}
                                                onChange={this.handleLock('zakljucan')}
                                                name="zakljucan"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} container>
                                            <Typography>{i18n.t('Real')}</Typography>
                                            <Switch
                                                checked={this.state.regionObj.pravi}
                                                onChange={this.handleReal('pravi')}
                                                name="pravi"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            />
                                        </Grid>
                                    </Grid>}
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        select
                                        label={i18n.t('Language')}
                                        value={this.state.regionObj.id_jezik}
                                        onChange={this.handleChangeValue('id_jezik')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        required
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <option value='' />
                                        {languages}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        select
                                        label={i18n.t('Currency')}
                                        value={this.state.regionObj.id_valuta}
                                        onChange={this.handleChangeValue('id_valuta')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        required
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <option value='' />
                                        {currencyes}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <Button style={{ alignSelf: 'center', backgroundColor: '#58B4E5', color: 'white', marginTop: '20px', marginBottom: '10px' }}
                                        onClick={() => this.addRegion()}
                                        disabled={this.props.regionPosting} >
                                        {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog >
            </div >
        )
    }
};

function mapStateToProps(state) {
    return {
        regionPosting: state.regionReducer.regionPosting,
        regionPostingSuccess: state.regionReducer.regionPostingSuccess,
        regionObj: state.regionReducer.regionObj,
        regionPostingFailed: state.regionReducer.regionPostingFailed,
        languages: state.appReducer.languages,
        currencyes: state.appReducer.currencyes,

        regionType: state.regionReducer.regionType,
        regionTypeFetching: state.regionReducer.regionTypeFetching,
        regionTypeFetchingFailed: state.regionReducer.regionTypeFetchingFailed,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInitialState: (component) => dispatch(setInitialState(component)),
        addRegion: (regionObj) => dispatch(addRegion(regionObj)),
        getRegionType: () => dispatch(getRegionType()),
        getLanguages: () => dispatch(getLanguages()),
        getCurrencyes: () => dispatch(getCurrencyes()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddRegion);