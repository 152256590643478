import React from 'react';
import { Card, withStyles, } from '@material-ui/core';
import axios from '../../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../InfoStyles';

export class DataInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.language === 1 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                Istorijski pregled podataka veoma je značajan za poljoprivrednu proizvodnju, jer omogućava analiziranje izmerenih vrednosti i praćenje njihovog trenda kretanja, što proizvođačima pruža pomoć u planiranju setvene strukture, zaštiti i ishrani bilja i ostalim tehnološkim operacijama koje se obavljaju na poljima.
                            <br/>
                            <br/>
                                AgroLIFE omogućava istorijski pregled vremenskih podataka koje je stanica zabeležila od momenta postavljanja na polju. 
                            <br/>
                            <br/>
                                Sa leve strane prozora nalazi se spisak vremenskih parametara za koje stanica daje podatke. Klikom na naziv vremenskog parametra, u desnom delu prozora prikazuju se izmerene vrednosti.
                            <br/>
                            <br/>
                                Farmer bira opseg dana za koji želi da pogleda podatke i oni su prikazani na grafikonima i u tabeli. Prikaz podataka je po danima u vremenskom intervalu od nekoliko sati. Podaci na grafikonu čitaju se sa desne na levu stranu, pri čemu je sa leve strane najranije izabrani datum. 
                            <br/>
                            <br/>
                                U okviru svakog prozora nalazi se Info dugme i klikom na njega otvara se prozor gde farmer može da pročita kratak opis odabranog vremenskog parametra i koji je njegov značaj za poljoprivredu.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                Historical overview of data is very important for agricultural production. It enables the analysis of measured values and the monitoring of their trend which provides assistance to farmers in planning the sowing structure, protection and nutrition of plants and other working operations.
                            <br/>
                            <br/>
                                AgroLIFE enables a historical review of the weather data recorded by the station since the moment it was placed in the field.
                            <br/>
                            <br/>
                                On the left side of the window is a list of weather parameters for which the station provides data. By clicking on the time parameter name, the measured values are displayed in the right part of the window.
                            <br/>
                            <br/>
                                The farmer selects the range of days for which he wants to view the data and it is displayed in graphs and in a table. Data display is by day in a time interval of several hours.The data on the graph is read from right to left, with the earliest selected date on the left.
                            <br/>
                            <br/>
                                There is an Info button in each window. Clicking on it new window is open, where the farmer can read a short description of the selected weather parameter and its importance for agriculture.
                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    };
};

export default withStyles (styles)(DataInfo);
