import React from 'react';
import { connect } from 'react-redux';
import {
    Button, IconButton, Menu, MenuItem, Typography
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { Doughnut, Bar } from 'react-chartjs-2';
import immutable from 'seamless-immutable';
import DashboardDrawer from '../../../utils/DashboardDrawer';
import Select, { components } from 'react-select';
import InputLabel from '@material-ui/core/InputLabel';
import {
    getInstitution, getProgramType, getSectorType,
    getProgramDashboard
} from '../../../actions/financial/ProgramActions';
import i18n from '../../../i18n/i18n';
import randomColor from 'randomcolor';

const IndicatorsContainer = (props) => {
    return (
        <div style={{ background: '#61a50e' }}>
            <components.IndicatorsContainer {...props} />
        </div>
    );
};
export class ProgramDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            id_vrsta_programa: [],
            id_vrsta_sektor: [],
            id_institucija: [],

        };
    }

    componentDidMount() {
        this.props.getProgramDashboard({});
        if (this.props.programType.length == 0) {
            this.props.getProgramType();
        }
        if (this.props.sectorType.length == 0) {
            this.props.getSectorType();
        }
        if (this.props.institution.length == 0) {
            this.props.getInstitution();
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            programType: immutable.asMutable(nextProps.programType.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            sectorType: immutable.asMutable(nextProps.sectorType.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            institution: immutable.asMutable(nextProps.institution.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
        })
    }

    onPieClick = () => {
        this.props.history.push('/program_dashboard');
    }

    onTableClick = () => {
        this.props.history.push('/program');
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleClear = () => {
        this.setState({
            id_vrsta_programa: [],
            id_vrsta_sektor: [],
            id_institucija: [],
        }, function () {
            this.props.getProgramDashboard({});
        });
    };

    handleOk = () => {
        let filters = {};
        filters['id_vrsta_programa'] = this.state.id_vrsta_programa.map((item) => {
            return item.value;
        })
        filters['id_vrsta_sektor'] = this.state.id_vrsta_sektor.map((item) => {
            return item.value;
        });
        filters['id_institucija'] = this.state.id_institucija.map((item) => {
            return item.value;
        });
        this.props.getProgramDashboard(filters);
    }

    handleChange = name => selectedOption => {
        this.setState({
            [name]: selectedOption
        })
    }


    render() {
        const { anchorEl } = this.state;
        let programsData = this.programs;
        let sectorData = this.sectors;
        let institutionsData = this.institutions;
        if (this.props.programItems && this.props.programItems.TypePrograms) {
            programsData.labels = this.props.programItems.TypePrograms.labels.asMutable();
            programsData.datasets[0].data = this.props.programItems.TypePrograms.count.asMutable();
            programsData.datasets[0].data1 = this.props.programItems.TypePrograms.percent.asMutable();
        }

        if (this.props.programItems && this.props.programItems.TypeSector) {
            sectorData.labels = this.props.programItems.TypeSector.labels.asMutable();
            sectorData.datasets[0].data = this.props.programItems.TypeSector.count.asMutable();
            sectorData.datasets[0].data1 = this.props.programItems.TypeSector.percent.asMutable();
        }
        if (this.props.programItems && this.props.programItems.Institution) {
            institutionsData.labels = this.props.programItems.Institution.labels.asMutable();
            institutionsData.datasets[0].data = this.props.programItems.Institution.count.asMutable();
            institutionsData.datasets[0].data1 = this.props.programItems.Institution.percent.asMutable();
        }

        return (
            <div style={{ 'padding': '20px' }}>
                {this.props.programItems.total > 0 &&
                    <div>
                        <Card style={styles.card}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <h1>{i18n.t('Total number of programs')} : {this.props.programItems.total} </h1>
                                {!this.props.global &&
                                    <div>
                                        <div className="filter-panel-dashboard">
                                            <IconButton
                                                styles={styles.button}
                                                aria-owns={anchorEl ? 'simple-menu' : null}
                                                aria-haspopup="true"
                                                className="action-button-filter"
                                                onClick={this.handleClick}
                                            >
                                                <span className="icon-search headerIcon" />
                                            </IconButton>
                                        </div>
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={this.handleClose}
                                        >
                                            <InputLabel className="filter-label">{i18n.t('Type of program')}</InputLabel>
                                            <Select
                                                isMulti
                                                closeMenuOnSelect={false}
                                                isClearable={false}
                                                components={{
                                                    IndicatorsContainer: IndicatorsContainer
                                                }}
                                                maxMenuHeight={150}
                                                value={this.state.id_vrsta_programa}
                                                onChange={this.handleChange('id_vrsta_programa')}
                                                options={this.state.programType}
                                                className="multi-select-in-filter-dashboards"
                                                classNamePrefix="select"
                                                placeholder={i18n.t('Select')}
                                            />
                                            <InputLabel className="filter-label">{i18n.t('Type of sector')}</InputLabel>
                                            <Select
                                                isMulti
                                                closeMenuOnSelect={false}
                                                isClearable={false}
                                                components={{
                                                    IndicatorsContainer: IndicatorsContainer
                                                }}
                                                maxMenuHeight={150}
                                                value={this.state.id_vrsta_sektor}
                                                onChange={this.handleChange('id_vrsta_sektor')}
                                                options={this.state.sectorType}
                                                className="multi-select-in-filter-dashboards"
                                                classNamePrefix="select"
                                                placeholder={i18n.t('Select')}
                                            />
                                            <InputLabel className="filter-label">{i18n.t('Institution')}</InputLabel>
                                            <Select
                                                isMulti
                                                closeMenuOnSelect={false}
                                                isClearable={false}
                                                components={{
                                                    IndicatorsContainer: IndicatorsContainer
                                                }}
                                                maxMenuHeight={150}
                                                value={this.state.id_institucija}
                                                onChange={this.handleChange('id_institucija')}
                                                options={this.state.institution}
                                                className="multi-select-in-filter-dashboards"
                                                classNamePrefix="select"
                                                placeholder={i18n.t('Select')}
                                            />
                                            <div style={{ padding: '30px' }}>
                                                <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleOk}>{i18n.t('Ok')}</Button>
                                                <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleClose}>{i18n.t('Cancel')}</Button>
                                                <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleClear}>{i18n.t('Clear')}</Button>

                                            </div>
                                        </Menu>
                                    </div>
                                }
                            </div>
                        </Card>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Card className="dashboard-cards" raised={true}>
                                <Typography className="dashboard-title">{i18n.t('Programs by type')}:</Typography>
                                {this.props.programItems && this.props.programItems.TypePrograms &&
                                    <Doughnut data={programsData} options={this.options} />
                                }
                            </Card>
                            <Card className="dashboard-cards" raised={true}>
                                <Typography className="dashboard-title">{i18n.t('Programs by type of sector')}:</Typography>
                                {this.props.programItems && this.props.programItems.TypeSector &&
                                    <Doughnut data={sectorData} options={this.options} />
                                }
                            </Card>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Card className="dashboard-cards" raised={true}>
                            <Typography className="dashboard-title">{i18n.t('Programs by institutions')}:</Typography>
                            {this.props.programItems && this.props.programItems.Institution &&
                                <Doughnut data={institutionsData} options={this.options} />
                            }
                        </Card>
                        </div>
                        {this.props.global ?
                            null :
                            <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} />
                        }
                    </div>
                }

            </div>
        )
    }

    programsColor = randomColor({
        count: 30
    })

    sectorsColor = randomColor({
        count: 50
    })

    institutionsColor = randomColor({
        count: 50
    })

    programBarColor = randomColor()
    sectorBarColor = randomColor()

    programs = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.programsColor,
            hoverBackgroundColor: this.programsColor
        }]
    };
    sectors = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.sectorsColor,
            hoverBackgroundColor: this.sectorsColor
        }]
    };
    institutions = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.institutionsColor,
            hoverBackgroundColor: this.institutionsColor
        }]
    };
    institutionsBy = {
        labels: [],
        datasets: [{
            data: [],
            label: i18n.t('Programs by type'),
            backgroundColor: this.programBarColor,
            borderColor: this.programBarColor,
            borderWidth: 1,
            hoverBackgroundColor: this.programBarColor,
            hoverBorderColor: this.programBarColor,
        },
        {
            data: [],
            label: i18n.t('Programs by sector'),
            backgroundColor: this.sectorBarColor,
            borderColor: this.sectorBarColor,
            borderWidth: 1,
            hoverBackgroundColor: this.sectorBarColor,
            hoverBorderColor: this.sectorBarColor,
        }]
    };
    options = {
        legend: {
            position: 'bottom',
            labels: {
                boxWidth: 10,
                fontSize: 12
            }
        },
        tooltips: {
            mode: 'label',
            callbacks: {

                afterLabel: function (tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex].data1[tooltipItem.index] + '%';
                },

            },
        }
    };


};

function mapStateToProps(state) {
    return {
        programItems: state.programReducer.programItems,
        programType: state.programReducer.programType,
        sectorType: state.programReducer.sectorType,
        institution: state.programReducer.institution,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getProgramDashboard: (filters) => dispatch(getProgramDashboard(filters)),
        getProgramType: () => dispatch(getProgramType()),
        getSectorType: () => dispatch(getSectorType()),
        getInstitution: () => dispatch(getInstitution()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProgramDashboard);

const styles = {
    card: {
        padding: '10px',
        margin: '10px'
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
};