import React from 'react';
import i18n from '../i18n/i18n';
import Dropzone from 'react-dropzone';
import {
    ExpansionPanel,
    ExpansionPanelSummary, ExpansionPanelDetails
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';

export class ImageDropZone extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onChange = () => {
        let accepted;
        const reader = new FileReader();
        reader.onload = (event) => {
            accepted = event.target.result;
            this.props.onDropAccepted(accepted);
        };
    }

    render() {
        return (
            <ExpansionPanel style={{ width: '100%' }}>
                <ExpansionPanelSummary expandIcon={<Icons.ExpandMore style={{ color: 'hhhhhh', height: '50px' }} />}>
                    {i18n.t('Upload documents')}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails >
                    <Dropzone
                        accept={['image/png']}
                        maxSize={1097152}
                        className="dropZone-doc"
                        activeClassName="dropZone-doc-act"
                        acceptClassName="dropZone-doc-acpt"
                        rejectClassName="dropZone-doc-rej"
                        disabledClassName="dropZone-doc-dis"
                        multiple={this.props.multiple}
                        onDrop={this.onChange}
                    >
                        {({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
                            if (isDragActive) {
                                return < div className="dropZoneDiv" >
                                    <span className=" icon-upload dropZoneIcon" />
                                    <br />
                                    <span>{i18n.t("This file is authorized")}</span>
                                </div>;
                            }
                            if (isDragReject) {
                                return < div className="dropZoneDiv" >
                                    <span className=" icon-upload dropZoneIcon" />
                                    <br />
                                    <span>{i18n.t("This file is not authorized")}</span>
                                </div>;
                            }
                            if (acceptedFiles.length || rejectedFiles.length) {
                                return <div>
                                    {i18n.t('Accepted files')}:<br />
                                    {acceptedFiles.map((item, ind) => {
                                        return <span className='dropZone-fil' key={ind}>{item.name} <br /></span>
                                    })}
                                    {i18n.t('Rejected files')}:<br />
                                    {rejectedFiles.map((item, ind) => {
                                        return <span className='dropZone-fil' key={ind}>{item.name} <br /></span>
                                    })}
                                </div>
                            } else {
                                return <div className="dropZoneDiv">
                                    <span className=" icon-upload dropZoneIcon" />
                                    <br />
                                    <span>{i18n.t("Try dropping some files.")}</span>
                                </div>
                            }
                        }}
                    </Dropzone>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default ImageDropZone;