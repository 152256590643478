import React from 'react';
import i18n from '../../../i18n/i18n';
import { Button, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from '../../../utils/AxiosWrapper';
import { wentWrong } from '../../../utils/ToastConfig';
import { partial } from 'lodash';
export class AddSort extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            
            kultura_grupa: null, cropGroupType: [],
            kultura_podgrupa: null, cropSubgroupType: [], kpg_disabled: true,
            kultura: null, cropType: [],  kultura_disabled: true,
            naziv: null,  id: null,
            new:false,
            povrsina_disabled: false, 
            user_id_region: null

        }
    }

    componentDidMount() {
     
        let user = this.getUser();
        this.setState({ user_id_region: user.id_region })
        this.getByURL('crop_group/drop_down', 'cropGroupType');

         if (this.props.edit === true) {

            axios.get('api/crop_subgroup/drop_down', { params: { id_grupa: this.props.data.id_kultura_grupa } })
                .then(res => { this.setState({ cropSubgroupType: res.data.data, kpg_disabled: false }); })
                .catch(err => { console.log(err) })

            axios.get('api/crop/drop_down', { params: { id_podgrupa: this.props.data.id_kultura_podgrupa } })
                .then(res => { this.setState({ cropType: res.data.data, kultura_disabled: false }); })
                .catch(err => { console.log(err) })
                
                this.setState({
                id: this.props.data.id,
                kultura: this.props.data.id_kultura,
                kultura_grupa: this.props.data.id_kultura_grupa,
                kultura_podgrupa: this.props.data.id_kultura_podgrupa,
                kultura_sorta: this.props.data.id_kultura_sorta,
                naziv: this.props.data.naziv,
            }
            );
        }
    }

    getByURL(url, obj, event, required) {
        event = event || '';
        required = required || '';
        if (event != null) {
            axios.get(`api/${url}`, { params: { [required]: event } })
                .then(res => this.setState({ [obj]: res.data.data }))
                .catch(error => wentWrong('Something went wrong, please try again!'));
        }

    }

    async addSort() {
        const { onSave, handleClose } = this.props;
        if (this.state.naziv && this.state.kultura_grupa && this.state.kultura_podgrupa && this.state.kultura) {
            let data = {
                id: this.props.data.id, // da prepozna za edit na backendu
                naziv: this.state.naziv,
                id_kultura_grupa: (this.state.kultura_grupa),
                id_kultura_podgrupa: (this.state.kultura_podgrupa),
                id_kultura: (this.state.kultura),
            }
            axios.post('api/sort/create', { data: data })
                .then(res => {
                    this.props.handleClose();
                })
                .catch(error => {
                    wentWrong('Something went wrong, please try again!');
                    console.log(`Error: ${error}`);
                })
                // {this.props.getData('field/read_parts', this.props.state)}
        
            
        }
        else wentWrong('Please enter all required fields!');
    
}
    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.oriSgin);
        return userObject;
    }
    //onSwitchClick = () => { this.setState({ polj_radovi: !this.state.polj_radovi }); }

    render() {
        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e' }} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit sort/hybrid') : i18n.t('Add sort/hybrid')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-layer">
                            <Grid container justify="space-between" alignContent="space-between" >

                                <Grid xs={12}>
                                {this.state.new === false &&
                                <TextField required
                                    label={i18n.t('Title')}
                                    value={this.state.naziv}
                                    style={{ width: '456px', marginBottom: '10px', marginTop: '20px', marginLeft: '10px', textAlign: 'center' }}
                                    onChange={(e) => { this.setState({ naziv: e.target.value, field: null }); }} >
                                </TextField>
                                }
                                </Grid>

                                <Grid item xs={6}>
                                <TextField required
                                    label={i18n.t('Crop group')}
                                    InputLabelProps={{ shrink: true }}
                                    select
                                    value={this.state.kultura_grupa}
                                    SelectProps={{ native: true, }}
                                    style={{ width: '256px', marginBottom: '10px', marginLeft: '10px' }}
                                    onChange={(e) => {
                                        this.setState({ kultura_grupa: e.target.value }, () => {
                                            axios.get('api/crop_subgroup/drop_down', { params: { id_grupa: this.state.kultura_grupa } })
                                                .then(res => { this.setState({ cropSubgroupType: res.data.data, kpg_disabled: false }); })
                                                .catch(err => { console.log(err) })
                                        });
                                    }} >
                                    <option value='' />
                                    {this.state.cropGroupType.map((cg) => <option key={cg.id} value={cg.id}>{cg.naziv}</option>)}
                                </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                <TextField required
                                    label={i18n.t('Crop subgroup')}
                                    InputLabelProps={{ shrink: true }}
                                    select
                                    disabled={this.state.kpg_disabled}
                                    value={this.state.kultura_podgrupa}
                                    SelectProps={{ native: true, }}
                                    style={{ width: '256px', marginBottom: '10px', marginLeft: '20px' }}
                                    onChange={(e) => {
                                        this.setState({ kultura_podgrupa: e.target.value }, () => {
                                            axios.get('api/crop/drop_down', { params: { id_podgrupa: this.state.kultura_podgrupa } })
                                                .then(res => { this.setState({ cropType: res.data.data, kultura_disabled: false }); })
                                                .catch(err => { console.log(err) })
                                        });
                                    }} >
                                    <option value='' />
                                    {this.state.cropSubgroupType.map((csg) => <option key={csg.id} value={csg.id}>{csg.naziv}</option>)}
                                </TextField>
                                </Grid>

                                <Grid item xs={6}>
                                <TextField required
                                    label={i18n.t('Crop')}
                                    InputLabelProps={{ shrink: true }}
                                    select
                                    disabled={this.state.kultura_disabled}
                                    value={this.state.kultura}
                                    SelectProps={{ native: true, }}
                                    style={{ width: '256px', marginLeft: '10px' }}
                                    onChange={(e) => {
                                        this.setState({ kultura: e.target.value });
                                    }}>
                                    <option value='' />
                                    {this.state.cropType.map((c) => <option key={c.id} value={c.id}>{c.naziv}</option>)}
                                </TextField>
                                </Grid>

                                <Grid item xs={12}>
                                    <Button style={{ alignSelf: 'center', backgroundColor: '#04764e', color: 'white', marginTop: '20px', marginBottom: '10px' }}
                                        onClick={() => this.addSort()} 
                                        disabled={this.props.layerPosting} >
                                        {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                                        
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
};