import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import 'react-table/react-table.css';
import { getRegions, getRegionType, setInitialState, setRowInReducer } from '../../../actions/control_panel/RegionActions';
import i18n from '../../../i18n/i18n';
import Select from 'react-select';
import immutable from 'seamless-immutable';
import { Fab } from '@material-ui/core';
import AddRegion from './AddRegion';
import DoubleArrow from '@material-ui/icons/DoubleArrow';
import Block from '@material-ui/icons/Block';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CopyRegion from "./CopyRegion";

const CheckboxTable = checkboxHOC(ReactTable);;

export class ViewRegions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            add: false,
            showCopyDialog: false,
            showLockDialog: false,
            showTrueDialog: false,
            selection: [],
            selectAll: false,
            edit: false
        };
        this.tableScrollTop = 0;
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.deleted === true) {
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getRegions(wrapped.state);
            this.setState({
                selection: [],
                selectAll: false,
            })
            this.props.setInitialState('regionObj');
            this.props.setInitialState('deleted');
        }
        if (nextProps.regionPostingSuccess === true) {
            this.setState({
                selection: [],
                name: '',
                selectAll: false
            })
            this.handleClick();
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getRegions(wrapped.state);
            this.props.setInitialState('regionPostingSuccess');
        }
        if (nextProps.copyResourcesPostingSuccess === true) {
            this.onCopyRegionPopup();
        }
        this.setState({
            region: nextProps.region,
            regionFetching: nextProps.regionFetching,
        })
    }

    handleClick = () => {
        if (this.state.edit == true && this.state.add == false) {
            this.setState({ edit: false })
            this.props.setInitialState('regionObj');
        } else if (this.state.selection.length > 0 && this.state.add == false && this.state.edit == false) {
            this.props.setInitialState('regionObj');
        }
        this.setState({ add: !this.state.add }, () => {
            if (this.state.add == true && this.state.edit == false) {
                this.setState({
                    selection: [],
                    name: '',
                    selectAll: false,
                })
            }
        });
    };

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
            this.props.setInitialState('regionObj');
        } else {
            selection.push(key);
        }
        this.setState({ selection }, function () {
            if (selection.length == 1) {
                let row1 = this.props.region.filter((reg) => {
                    return reg.id == selection;
                })
                this.props.setRowInReducer(row1[0], 'regionObj');
            }
        });
    };

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });
    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    onTableClick = () => {
        this.props.history.push('/region');
    }

    onCopyRegionPopup() { this.setState({ showCopyDialog: !this.state.showCopyDialog }) }


    onEditClick() {
        this.setState({ add: !this.state.add, edit: true });
    }

    render() {
        const columns = [
            {
                Header: i18n.t('ID'),
                accessor: 'id'
            },
            {
                Header: i18n.t('Naziv regiona'),
                accessor: 'naziv',
            },
            {
                id: 'zakljucan',
                Header: i18n.t('Zakljucan'),
                accessor: d => d.zakljucan, // Custom value accessors!
                Cell: ({ value }) => value != 0 ? i18n.t('Yes') : i18n.t('No')
            },
            {
                id: 'pravi',
                Header: i18n.t('Pravi'),
                accessor: d => d.pravi, // Custom value accessors!
                Cell: ({ value }) => value != 0 ? i18n.t('Yes') : i18n.t('No')
            },
        ];

        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "#ACDAF1" : "inherit"
                        }
                    };
                }
                return {};
            }
        };

        return (
            <div className="page">

                <CopyRegion regions={this.props.regions}
                    open={this.state.showCopyDialog}
                    onClose={() => this.onCopyRegionPopup()} />


                <div className="table-header">
                    <Fab onClick={() => this.handleClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-Plus headerIcon" />
                    </Fab>
                    <Fab onClick={() => this.onCopyRegionPopup()}
                        color="secondary"
                        aria-label="add"
                        className="action-button">
                        <DoubleArrow />
                    </Fab>
                    {this.state.selection.length === 1 ?
                        <Fab onClick={() => this.onEditClick()}
                            color="secondary"
                            aria-label="edit"
                            className="action-button">
                            <span className="icon-blocked headerIcon" />
                        </Fab>
                        : null
                    }

                </div>
                <CheckboxTable ref={r => (this.checkboxTable = r)}
                    data={this.props.region}
                    getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                    columns={columns}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={false}
                    pages={this.props.regionPages}
                    loading={this.props.regionFetching}
                    manual
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => this.props.getRegions(state, instance)}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF',
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />

                {this.state.add && <AddRegion add={this.state.add} edit={this.state.edit} handleClose={this.handleClick} />}
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        region: state.regionReducer.region,
        regionFetching: state.regionReducer.regionFetching,
        regionPages: state.regionReducer.regionPages,
        regionObj: state.regionReducer.regionObj,
        regionPostingSuccess: state.regionReducer.regionPostingSuccess,
        copyResourcesPostingSuccess: state.regionReducer.copyResourcesPostingSuccess,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getRegions: (state, instance) => dispatch(getRegions(state, instance)),

        setInitialState: (component) => dispatch(setInitialState(component)),
        setRowInReducer: (rowInfo) => dispatch(setRowInReducer(rowInfo)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewRegions);