import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import {
    TextField, Button, Paper, Grid, IconButton,
    Dialog, DialogAction, DialogContent, DialogTitle
} from '@material-ui/core';
import { getStates, getCosDropdown, getBranchesByState, getAnchorDropdown, getAgentByAnchor } from '../../actions/AdministrationActions';
import { getUser } from '../../utils/CommonFunctions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    getLocalGov, getTitle, getMaritalStatus, getIdentificationDoc,
    setInitialState,
    addFarmOffice
} from '../../actions/FarmRegistarActions';
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment';


export class AddFarmers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: null,
            email: null,
            phone: null,
            id_state: null,
            first_name: null,
            middle_name: null,
            id_katastarska_opstina: null,
            bpg: null,
            id_titula: undefined,
            id_bracni_status: undefined,
            id_identifikacioni_dokument: null,
            last_name: null,
            username: null,
            password: null,
            pol: null,
            datum_rodjenja: new Date(),
            id_mesto_rodjenja: undefined,
            id_branch: null,
            id_anchor: null,
            id_agent: null,
            user: {}
        };
    }

    async componentDidMount() {
        const { getCosDropdown } = this.props;
        this.props.getStates();
        this.props.getLocalGov(this.state.id_state);
        let user = await getUser();
        if (user) {
            getCosDropdown(user.id_katastarska_opstina);
        }
        this.props.getTitle();
        this.props.getMaritalStatus();
        this.props.getIdentificationDoc();
        this.props.getBranchesByState(this.state.id_state);
        this.props.getAnchorDropdown(this.state.id_katastarska_opstina)
        // this.props.getAgenstByAnchor(this.state.id_agent)
        this.setState({
            user: user
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.farmPostingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('farmPostingFailed');
        }
        if (nextProps.fdb) {
            this.setState({ username: nextProps.fdb.naziv });
        }
    }

    handleChangeDropDown = name => event => {
        this.setState({ [name]: event.target.value });
        if ([name] == 'id_state') {
            this.props.getLocalGov(event.target.value);
            this.props.getBranchesByState(event.target.value);
        }
        if ([name] == 'id_katastarska_opstina') {
            this.props.getAnchorDropdown(event.target.value)
        }
        if ([name] == 'id_anchor') {
            this.props.getAgentByAnchor(event.target.value)
        }
    };

    addFarmer() {
        const { user } = this.state;
        const condition = user.id_region === 13 && this.state.id_cos && this.state.bpg && this.state.email && this.state.id_katastarska_opstina && this.state.id_branch && this.state.id_anchor && this.state.id_agent || true;

        if (
            this.state.id_state &&
            this.state.first_name &&
            this.state.last_name &&
            this.state.username &&
            this.state.password &&
            condition
        ) {
            this.props.addFarmOffice(
                this.state.first_name,
                this.state.last_name,
                this.state.pol,
                this.state.id_mesto_rodjenja,
                this.state.datum_rodjenja,
                this.state.username,
                this.state.password,
                this.state.phone,
                this.state.email,
                this.state.middle_name,
                this.state.id_state,
                this.state.id_katastarska_opstina,
                this.state.bpg,
                this.state.address,
                this.state.id_titula,
                this.state.id_bracni_status,
                this.state.id_identifikacioni_dokument,
                this.props.fdb,
                this.state.id_cos,
                this.state.id_branch,
                this.state.id_anchor,
                this.state.id_agent
            )
        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        }
    }


    render() {
        const { user } = this.state;
        let localGoverments = [];
        if (this.props.localGoverments && !this.props.localGovermentsFetching) {
            localGoverments = this.props.localGoverments.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let states = [];
        if (this.props.states && !this.props.statesFetching) {
            states = this.props.states.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let titule = [];
        if (this.props.title && !this.props.titleFetching) {
            titule = this.props.title.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            })
        }
        let maritalStatus = [];
        if (this.props.maritalStatus && !this.props.maritalStatusFetching) {
            maritalStatus = this.props.maritalStatus.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            })
        }
        let identificationDoc = [];
        if (this.props.identificationDoc && !this.props.identificationDocFetching) {
            identificationDoc = this.props.identificationDoc.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            })
        }
        let pol = [];
        let polovi = [
            { value: i18n.t('Male') },
            { value: i18n.t('Female') }
        ]
        pol = polovi.map((s, i) => {
            return <option key={s.id} value={s.value}>{s.value}</option>
        })
        let cosDropdownArr = [];
        if (this.props.cosDropdown) {
            cosDropdownArr = this.props.cosDropdown.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.kontakt_osoba}</option>
            })
        }
        let branchesArray = [];
        if (this.props.branches && !this.props.branchesFetching) {
            branchesArray = this.props.branches.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            })
        }
        let anchorDropdown = [];
        if (this.props.anchorDropdown && !this.props.anchorDropdownFetching) {
            anchorDropdown = this.props.anchorDropdown.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.kontakt_osoba}</option>
            })
        }
        let anchorAgentDropdown = [];
        if (this.props.anchorAgentDropdown && !this.props.anchorAgentDropdownFetching) {
            anchorAgentDropdown = this.props.anchorAgentDropdown.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            })
        }
        return (
            <div style={{ padding: 20 }}>
                <Dialog
                    open={this.props.add}
                    onClose={this.props.handleClose}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('Add farmer')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <form id="register-farmer">
                            <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('First name')}
                                        required
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={(event) => this.setState({ first_name: event.target.value })}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('Middle name')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={(event) => this.setState({ middle_name: event.target.value })}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('Last name')}
                                        required
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={(event) => this.setState({ last_name: event.target.value })}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('Email')}
                                        required={user.id_region == 2 ? false : true}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={(event) => this.setState({ email: event.target.value })}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('Username')}
                                        required
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={(event) => this.setState({ username: event.target.value })}
                                        value={this.props.fdb && this.props.fdb.naziv}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('Password')}
                                        required
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={(event) => this.setState({ password: event.target.value })}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        select
                                        label={i18n.t('State')}
                                        value={this.state.id_state}
                                        required
                                        onChange={this.handleChangeDropDown('id_state')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}

                                    >
                                        <option value='' />
                                        {states}
                                    </TextField>
                                </Grid>
                                {/* {user.id_region != 2 &&
                                    <Grid item>
                                        <TextField
                                            select
                                            label={i18n.t('Branch')}
                                            value={this.state.id_branch}
                                            required
                                            disabled={!this.state.id_state}
                                            onChange={this.handleChangeDropDown('id_branch')}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            margin="normal"
                                            style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        >
                                            <option value='' />
                                            {branchesArray}
                                        </TextField>
                                    </Grid>
                                } */}
                                <Grid item>
                                    <TextField
                                        select
                                        disabled={!this.state.id_state}
                                        label={i18n.t('Local government')}
                                        required={user.id_region == 2 ? false : true}
                                        value={this.state.id_katastarska_opstina}
                                        onChange={this.handleChangeDropDown('id_katastarska_opstina')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <option value='' />
                                        {localGoverments}
                                    </TextField>
                                </Grid>
                                {/* {user.id_region != 2 &&
                                    <>
                                        <Grid item>
                                            <TextField
                                                select
                                                disabled={!this.state.id_state}
                                                label={i18n.t('Anchor')}
                                                value={this.state.id_anchor}
                                                required
                                                onChange={this.handleChangeDropDown('id_anchor')}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                margin="normal"
                                                style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                            >
                                                <option value='' />
                                                {anchorDropdown}
                                            </TextField>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                select
                                                label={i18n.t('Agent')}
                                                value={this.state.id_agent}
                                                required
                                                onChange={this.handleChangeDropDown('id_agent')}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                margin="normal"
                                                style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                            >
                                                <option value='' />
                                                {anchorAgentDropdown}
                                            </TextField>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                select
                                                label={i18n.t('Cluster')}
                                                value={this.state.id_cos}
                                                onChange={this.handleChangeDropDown('id_cos')}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                margin="normal"
                                                style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}

                                            >
                                                <option value='' />
                                                {cosDropdownArr}
                                            </TextField>
                                        </Grid>
                                    </>
                                } */}
                                <Grid item>
                                    <TextField
                                        label={i18n.t('BVN')}
                                        required={user.id_region == 2 ? false : true}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={(event) => this.setState({ bpg: event.target.value })}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('Address')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={(event) => this.setState({ address: event.target.value })}
                                    />
                                </Grid>
                                {/* {user.id_region != 2 &&
                                    <>
                                        <Grid item>
                                            <TextField
                                                select
                                                label={i18n.t('Title')}
                                                value={this.state.id_titula}
                                                onChange={this.handleChangeDropDown('id_titula')}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                margin="normal"
                                                style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}

                                            >
                                                <option value='' />
                                                {titule}
                                            </TextField>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                select
                                                label={i18n.t('Marital status')}
                                                value={this.state.id_bracni_status}
                                                onChange={this.handleChangeDropDown('id_bracni_status')}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                margin="normal"
                                                style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}

                                            >
                                                <option value='' />
                                                {maritalStatus}
                                            </TextField>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                select
                                                required
                                                label={i18n.t('Identification document')}
                                                value={this.state.id_identifikacioni_dokument}
                                                onChange={this.handleChangeDropDown('id_identifikacioni_dokument')}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                margin="normal"
                                                style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}

                                            >
                                                <option value='' />
                                                {identificationDoc}
                                            </TextField>
                                        </Grid>
                                    </>
                                } */}
                                <Grid item>

                                    <TextField
                                        label={i18n.t('Phone')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={(event) => this.setState({ phone: event.target.value })}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        select
                                        label={i18n.t('Place of birth')}
                                        value={this.state.id_mesto_rodjenja}
                                        onChange={this.handleChangeDropDown('id_mesto_rodjenja')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}

                                    >
                                        <option value='' />
                                        {states}
                                    </TextField>
                                </Grid>
                                {/* {user.id_region != 2 &&
                                    <>
                                        <Grid item>
                                            <TextField
                                                select
                                                label={i18n.t('Gender')}
                                                value={this.state.pol}
                                                onChange={this.handleChangeDropDown('pol')}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                margin="normal"
                                                style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}

                                            >
                                                <option value='' />
                                                {pol}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <DatePicker
                                                keyboard
                                                label={i18n.t('Date of birth')}
                                                format="DD.MM.YYYY"
                                                value={moment(this.state.datum_rodjenja, 'DD.MM.YYYY')}
                                                style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                                onChange={(date) => this.setState({ datum_rodjenja: moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY') })}
                                                disableOpenOnEnter
                                                animateYearScrolling={false}
                                            />
                                        </Grid>
                                    </>
                                } */}
                                <Grid item xs>
                                    <Button
                                        style={{ alignSelf: 'center', backgroundColor: '#58B4E5', color: 'white' }}
                                        onClick={() => this.addFarmer()}
                                    >
                                        {i18n.t('Add')}
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        localGoverments: state.farmRegistarReducer.localGoverments,
        localGovermentsFetching: state.farmRegistarReducer.localGovermentsFetching,
        localGovermentsFetchingFailed: state.farmRegistarReducer.localGovermentsFetchingFailed,
        title: state.farmRegistarReducer.title,
        farmPostingFailed: state.farmRegistarReducer.farmPostingFailed,
        titleFetching: state.farmRegistarReducer.titleFetching,
        titleFetchingFailed: state.farmRegistarReducer.titleFetchingFailed,
        maritalStatus: state.farmRegistarReducer.maritalStatus,
        maritalStatusFetching: state.farmRegistarReducer.maritalStatusFetching,
        maritalStatusFetchingFailed: state.farmRegistarReducer.maritalStatusFetchingFailed,
        identificationDoc: state.farmRegistarReducer.identificationDoc,
        identificationDocFetching: state.farmRegistarReducer.identificationDocFetching,
        identificationDocFetchingFailed: state.farmRegistarReducer.identificationDocFetchingFailed,
        states: state.administrationReducer.states,
        statesFetching: state.administrationReducer.statesFetching,
        statesFetchingFailed: state.administrationReducer.statesFetchingFailed,
        cosDropdown: state.administrationReducer.cosDropdown,
        branches: state.administrationReducer.branches,
        branchesFetching: state.administrationReducer.branchesFetching,
        anchorDropdown: state.administrationReducer.anchorDropdown,
        anchorDropdownFetching: state.administrationReducer.anchorDropdownFetching,
        anchorAgentDropdown: state.administrationReducer.anchorAgentDropdown,
        anchorAgentDropdownFetching: state.administrationReducer.anchorAgentDropdownFetching

    }
}

function mapDispatchToProps(dispatch) {
    return {
        getStates: () => dispatch(getStates()),
        getLocalGov: (id_state) => dispatch(getLocalGov(id_state)),
        getTitle: () => dispatch(getTitle()),
        setInitialState: (component) => dispatch(setInitialState(component)),
        getIdentificationDoc: () => dispatch(getIdentificationDoc()),
        getMaritalStatus: () => dispatch(getMaritalStatus()),
        getCosDropdown: (id_katastarska_opstina) => dispatch(getCosDropdown(id_katastarska_opstina)),
        getBranchesByState: (id_state) => dispatch(getBranchesByState(id_state)),
        getAnchorDropdown: (id_katastarska_opstina) => dispatch(getAnchorDropdown(id_katastarska_opstina)),
        getAgentByAnchor: (id_anchor) => dispatch(getAgentByAnchor(id_anchor)),
        addFarmOffice: (first_name, last_name, pol, id_mesto_rodjenja, datum_rodjenja, username, password, phone, email, srednje_ime, id_state, id_katastarska_opstina, bpg, address, id_titula, id_bracni_status, id_identifikacioni_dokument, fdb, id_cos, id_branch, id_anchor, id_agent) => dispatch(addFarmOffice(first_name, last_name, pol, id_mesto_rodjenja, datum_rodjenja, username, password, phone, email, srednje_ime, id_state, id_katastarska_opstina, bpg, address, id_titula, id_bracni_status, id_identifikacioni_dokument, fdb, id_cos, id_branch, id_anchor, id_agent)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddFarmers)