import immutable from 'seamless-immutable';
import { Type as SuperAdminType } from '../actions/SuperAdminActions';


export const INITIAL_STATE = immutable({


    region_layer_grupa: [],
    regionLayerGroupObj: {},
    regionLayersGroupsFetching: false,
    regionLayersGroupsFetchingFailed: false,
    regionLayersGroupsPages: 1,
    regionLayerGroupPosting: false,
    regionLayerGroupPostingSuccess: false,
    regionLayerGroupPostingFailed: false,

    region_lejer: [],
    regionLayerObj: {},
    regionLayerFetching: false,
    regionLayerFetchingFailed: false,
    regionLayerPages: 1,
    regionLayerPosting: false,
    regionLayerPostingSuccess: false,
    regionLayerPostingFailed: false,

    testing: [],
    testingPages: 1,
    testingFetching: false,
    testingFetchingFailed: false,

    form: {},
    formFetching: false,
    formFetchingFailed: false,

    deleted: false,
    deleteFailed: false,

    regionLayerGroupDropdown: [],
    regionLayerGroupDropdownFetching: false,
    regionLayerGroupDropdownFetchingFailed: false,
    regionLayerGroupsNigeria: [
        { id: 'cadastralLayers', naziv: 'cadastralLayers' },
        { id: 'administrativeLayers', naziv: 'administrativeLayers' },
        { id: 'hydrographyLayers', naziv: 'hydrographyLayers' },
        { id: 'transportLayers', naziv: 'transportLayers' },
        { id: 'institutionalLayers', naziv: 'institutionalLayers' },
        { id: 'satelliteImageryLayers', naziv: 'satelliteImageryLayers' },
        { id: 'weatherLayers', naziv: 'weatherLayers' },
    ],

    regionLayerGroupsSerbia: [
        { id: 'cadastralLayers', naziv: 'Katastarski podaci' },
        { id: 'administrativeLayers', naziv: 'Administrativne jedinice' },
        { id: 'institutionalLayers', naziv: 'Institucije' },
        { id: 'satelliteImageryLayers', naziv: 'Satelitski snimci' },
        { id: 'weatherLayers', naziv: 'Vremenska prognoza' },
    ],


});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case SuperAdminType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;

        case SuperAdminType.SET_REGION_LAYER_IN_REDUCER: {
            const data = action.rowInfo.asMutable();
            return state.merge({ [action.name]: data })
            break;
        }

        case SuperAdminType.GET_REGION_LAYER_GROUP_DROPDOWN_CALL: {
            const regionLayerGroupDropdownFetching = true;
            return state.merge({ regionLayerGroupDropdownFetching });
            break;
        }

        case SuperAdminType.GET_REGION_LAYER_GROUP_DROPDOWN_SUCCESS: {
            const regionLayerGroupDropdown = action.data.data;
            const regionLayerGroupDropdownFetching = false;
            return state.merge({ regionLayerGroupDropdown, regionLayerGroupDropdownFetching, });
            break;
        }

        case SuperAdminType.GET_REGION_LAYER_GROUP_DROPDOWN_FAILED: {
            const regionLayerGroupDropdownFetchingFailed = true;
            const regionLayerGroupDropdownFetching = false;
            return state.merge({ regionLayerGroupDropdownFetching, regionLayerGroupDropdownFetchingFailed });
            break;
        }

        case SuperAdminType.GET_REGION_LAYERS_GROUP_READ_CALL: {
            const regionLayersGroupsFetching = true;
            return state.merge({ regionLayersGroupsFetching });
            break;
        }

        case SuperAdminType.GET_REGION_LAYERS_GROUP_READ_SUCCESS: {
            const region_layer_grupa = action.data.data;
            const regionLayersGroupsFetching = false;
            const regionLayersGroupsPages = action.data.total_pages
            return state.merge({ region_layer_grupa, regionLayersGroupsFetching, regionLayersGroupsPages });
            break;
        }

        case SuperAdminType.GET_REGION_LAYERS_GROUP_READ_FAILED: {
            const regionLayersGroupsFetchingFailed = true;
            const regionLayersGroupsFetching = false;
            return state.merge({ regionLayersGroupsFetching, regionLayersGroupsFetchingFailed });
            break;
        }


        case SuperAdminType.GET_REGION_LAYERS_READ_CALL: {
            const regionLayerFetching = true;
            return state.merge({ regionLayerFetching });
            break;
        }

        case SuperAdminType.GET_REGION_LAYERS_READ_SUCCESS: {
            const region_lejer = action.data.data;
            const regionLayerFetching = false;
            const regionLayerPages = action.data.total_pages
            return state.merge({ region_lejer, regionLayerFetching, regionLayerPages });
            break;
        }

        case SuperAdminType.GET_REGION_LAYERS_READ_FAILED: {
            const regionLayerFetchingFailed = true;
            const regionLayerFetching = false;
            return state.merge({ regionLayerFetching, regionLayerFetchingFailed });
            break;
        }

        case SuperAdminType.ADD_REGION_LAYER_GROUP_CALL: {
            const regionLayerGroupPosting = true;
            return state.merge({ regionLayerGroupPosting });
            break;
        }

        case SuperAdminType.ADD_REGION_LAYER_GROUP_SUCCESS: {
            const regionLayerGroupPosting = false;
            const regionLayerGroupPostingSuccess = true;
            return state.merge({ regionLayerGroupPostingSuccess, regionLayerGroupPosting, regionLayerGroupObj: INITIAL_STATE.regionLayerGroupObj })
            break;
        }

        case SuperAdminType.ADD_REGION_LAYER_GROUP_FAILED: {
            const regionLayerGroupPosting = false;
            const regionLayerGroupPostingFailed = true;
            return state.merge({ regionLayerGroupPosting, regionLayerGroupPostingFailed });
            break;
        }

        case SuperAdminType.ADD_REGION_LAYER_CALL: {
            const regionLayerPosting = true;
            return state.merge({ regionLayerPosting });
            break;
        }

        case SuperAdminType.ADD_REGION_LAYER_SUCCESS: {
            const regionLayerPosting = false;
            const regionLayerPostingSuccess = true;
            return state.merge({ regionLayerPostingSuccess, regionLayerPosting, regionLayerObj: INITIAL_STATE.regionLayerObj })
            break;
        }

        case SuperAdminType.ADD_REGION_LAYER_FAILED: {
            const regionLayerPosting = false;
            const regionLayerPostingFailed = true;
            return state.merge({ regionLayerPosting, regionLayerPostingFailed });
            break;
        }

        case SuperAdminType.GET_TESTING_READ_CALL: {
            const testingFetching = true;
            return state.merge({ testingFetching });
            break;
        }

        case SuperAdminType.GET_TESTING_READ_SUCCESS: {
            const testing = action.data.data;
            const testingFetching = false;
            const testingPages = action.data.total_pages
            return state.merge({ testing, testingFetching, testingPages });
            break;
        }

        case SuperAdminType.GET_TESTING_READ_FAILED: {
            const testingFetchingFailed = true;
            const testingFetching = false;
            return state.merge({ testingFetching, testingFetchingFailed });
            break;
        }

        case SuperAdminType.FORM_READ_CALL: {
            const formFetching = true;
            return state.merge({ formFetching })
        }

        case SuperAdminType.FORM_READ_SUCCESS: {
            const formFetching = false;
            const form = action.data.data;
            return state.merge({ formFetching, form });
        }

        case SuperAdminType.FORM_READ_FAILED: {
            const formFetching = false;
            const formFetchingFailed = true;
            return state.merge({ formFetching, formFetchingFailed });
        }

        case SuperAdminType.DELETE_SUCCESS: {
            const array = state[action.name].asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ [action.name]: array, deleted: deleted })
            break;
        }

        case SuperAdminType.DELETE_FAILED: {
            const deletedFailed = true;
            return state.merge({ deletedFailed });
            break;
        }


        default:
            return state;
    }
}