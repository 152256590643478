import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import {
     IconButton,
    Dialog, DialogContent, DialogTitle
} from '@material-ui/core';

import DocumentGridList from '../../utils/DocumentGridList';
import 'react-toastify/dist/ReactToastify.css';
import {
    getAnchorDocuments
} from '../../actions/AdministrationActions';
export class ViewAnchorDocument extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getAnchorDocuments(this.props.id_klijent)
    }

    render() {

        return (
            <div style={{ padding: 20 }}>
                <Dialog
                    open={this.props.documents}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('Anchors documents')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        {this.props.documents && this.props.anchorDocuments && this.props.anchorDocuments.length !== 0 ?
                            <DocumentGridList
                                documentArray={this.props.anchorDocuments}
                                open={true}
                                //showDelete={true}
                            />
                            : null}

                    </DialogContent>
                </Dialog>
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        anchorDocuments: state.administrationReducer.anchorDocuments,
        anchorDocumentsFetching: state.administrationReducer.anchorDocumentsFetching,
        anchorDocumentsFetchingFailed: state.administrationReducer.anchorDocumentsFetchingFailed,

    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAnchorDocuments: (id_klijent) => dispatch(getAnchorDocuments(id_klijent)),
      
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewAnchorDocument)