import React from 'react';
import i18n from '../../../i18n/i18n';
import axios from '../../../utils/AxiosWrapper';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import DatePickerMeteo from '../../../utils/DatePickerMeteo';
import { formatData, formatDiseaseUni } from './../DataFormatters';
import { withStyles } from '@material-ui/core/styles';
import { MoonLoader } from 'react-spinners';
import MeteoTable from '../MeteoTable';
import {formatDiseaseDaily, formatDiseaseDailyNulls } from './../DataFormatters';


const styles = {};

export class UniDisease extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            station_id: this.props.row.uid,
            diseaseKey: this.props.diseaseKey,
            param: this.props.param,
            param1: this.props.param1,
            param2: this.props.param2,
            param3: this.props.param3,
            startDate: this.props.daily ? moment().subtract(7, 'days') : moment().subtract(1, 'days'),
            endDate: moment(),
            disease: {},
            loadDisease: true,
            loadData: true,
            loadData: true,
            chosenformat: this.props.dateFormat,
            barColors:[],
            daily:this.props.daily,
            min : this.props.min,
            max : this.props.max,
            minTicksLimit : this.props.minTicksLimit,
            maxTicksLimit : this.props.maxTicksLimit,
            daily:this.props.daily,
            slicedDates: [],
            slicedValues: [],
        };
    }
    setDates = (start, end) => { this.setState({ startDate: start, endDate: end }, () => { this.getPessl(); }); }

    // Get funkcije za preuzimanje podataka sa stanica samo po potrebi ubaciti, pored Disease-a uvek mora i Data zbog datuma
    getDisease = (path, disease) => {
        let obj = {};
        axios.post('/api/testMeteo/meteo', path)
            .then(res => { obj = formatDiseaseUni(res, obj, this.state.chosenformat); this.setState({ [disease]: obj, loadDisease: false }); })
            .catch(err => console.log('Error: ', err))
    }
    getDiseaseDaily = (path, disease) => {
        let obj = {};    
        if(this.props.daily){
            console.log('2')
            axios.post('/api/testMeteo/meteo', path)
            .then(res => { obj = formatDiseaseDailyNulls(res, obj, this.state.param); this.setState({ [disease]: obj, loadDisease: false });})
            .catch(err => console.log('Error: ', err))
        }else{ 
        axios.post('/api/testMeteo/meteo', path)
            .then(res => { obj = formatDiseaseDaily(res, obj); this.setState({ [disease]: obj, loadDisease: false });})
            .catch(err => console.log('Error: ', err))
    }
    }

    getPessl = () => {
        let start_date = moment(this.state.startDate).unix();
        let end_date = moment(this.state.endDate).unix();
        let station_id = this.state.station_id;
        let diseaseKey = this.state.diseaseKey;


        // URL-ovi za podatke sa meteo stanica
        let disease_path = '/data/' + station_id + '/hourly/from/' + start_date + '/to/' + end_date;
        // let data_path = '/data/' + station_id + '/hourly/from/' + start_date + '/to/' + end_date;

       // Disease mora POST method, za Data i Forecast mora GET method
       if (this.props.daily !== true) {
        this.getDisease({ path: disease_path, name: diseaseKey, method: "POST" }, 'disease');
        console.log(this.state.daily)
        }else{
            console.log('1')
        this.getDiseaseDaily({ path: disease_path, name: diseaseKey, method: "POST" }, 'disease');
        }
    }

    // generateColors(){    
    //     let colors = [];
    //     for (let i = 0; i < this.state.disease['Severity DSV'].length; i++) {
    //         const element = this.state.disease['Severity DSV'][i];
    //         if (element === 8) {
    //             colors.push('#990000')
    //         } else if (element === 7) {
    //             colors.push('#e73614')
    //         } else if (element === 6) {
    //             colors.push('#eb5216')
    //         } else if (element === 5) {
    //             colors.push('#ee6d19')
    //         } else if (element === 4) {
    //             colors.push('#f1881b')
    //         } else if (element === 3) {
    //             colors.push('#f4a41f')
    //         } else if (element === 2) {
    //             colors.push('#f8c025')
    //         } else if (element === 1) {
    //             colors.push('#fada25')
    //         } else {
    //             colors.push('#fada25')
    //         }
    //     }
    //     this.setState({ barColors: colors, load: false });
    // }
    

    render() {
        let param1 = this.state.param1
        let param2 = this.state.param2
        let param3 = this.state.param3
        console.log(this.state.disease)
        let graph1 = {};
        let graph2 = {};
        let graph3 = {};
        let graph4 = {};
        let graph5 = {};
        let graph6 = {};
        let graph7 = {};
        let graph8 = {};
        let graph9 = {};
        let graph10 = {};
        let graph11 = {};
        let graph12 = {};
        let graph13 = {};
        let graph14 = {};
        let graph15 = {};
        let graph16 = {};
        let graph17 = {};
        let graph18 = {};
        let graph19 = {};
        let graph20 = {};
        let graph21 = {};
        //Clasic one line graph
        const options1 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[%]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            minTicksLimit:100,
                            max: 100,
                            maxTicksLimit:100
                        }
                    },
                ],
            },
        };
        const options2 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-2',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[°C]'
                        },
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-3',
                        gridLines: {
                            drawOnChartArea: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[%]'
                        },
                        ticks: {
                            beginAtZero: false,
                            min: 20,
                            max: 100
                        }
                    },
                ],
            },
        };
        const options3 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-4',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[mm]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 5
                        }
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-5',
                        gridLines: {
                            drawOnChartArea: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[min/h]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 60
                        }
                    },
                ],
            },
        };
        const options4 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-6',
                        gridLines: {
                            drawOnChartArea: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[min/h]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 60
                        }
                    },
                ],
            },
        };
        // 3 line risk
        const options5 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-7',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[%]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 100,
                        }
                    },
                
                ],
            },
        };
        // 2 line risk
        const options6 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-8',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[%]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 100
                        }
                    },
                
                ],
            },
        };
        const options7 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-9',
                        scaleLabel: {
                            display: true,
                            labelString: '[°C]'
                        },
                        gridLines: {
                            drawOnChartArea: false
                        }
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-10',
                        scaleLabel: {
                            display: true,
                            labelString: '[min/h]'
                        },
                        gridLines: {
                            drawOnChartArea: false,
                        },
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-11',
                        scaleLabel: {
                            display: true,
                            labelString: '[W/m2]'
                        },
                        gridLines: {
                            drawOnChartArea: false,
                        },
                    },
                ],
            },
        };
        const options8 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-12',
                        scaleLabel: {
                            display: true,
                            labelString: '[°C]'
                        },
                        gridLines: {
                            drawOnChartArea: false
                        }
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-13',
                        scaleLabel: {
                            display: true,
                            labelString: '[W/m2]'
                        },
                        gridLines: {
                            drawOnChartArea: false,
                        },
                    },
                ],
            },
        };
        const options9 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-14',
                        scaleLabel: {
                            display: true,
                            labelString: '[°C]'
                        },
                        gridLines: {
                            drawOnChartArea: false
                        }
                    },
                ],
            },
        };
        
        const options10 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-15',
                        scaleLabel: {
                            display: true,
                            labelString: '[°C]'
                        },
                        gridLines: {
                            drawOnChartArea: false
                        }
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-16',
                        scaleLabel: {
                            display: true,
                            labelString: '[min/h]'
                        },
                        gridLines: {
                            drawOnChartArea: false,
                        },
                    },
                    
                ],
            },
        };
        const options11 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-17',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[%]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 5,
                        }
                    },
                ],
            },
        };

        const options12 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-18',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[°C]'
                        },
                    },
                ],
            },
        };
        const options13 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-19',
                        scaleLabel: {
                            display: true,
                            labelString: '[°C]'
                        },
                        gridLines: {
                            drawOnChartArea: false
                        }
                    },
                ],
            },
        };
        const options14 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-20',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[mm]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 5
                        }
                    },
                ],
            },
        };

        const options15 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-21',
                        scaleLabel: {
                            display: true,
                            labelString: '[W/m2]'
                        },
                        gridLines: {
                            drawOnChartArea: false,
                        },
                    },

                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-22',
                        gridLines: {
                            drawOnChartArea: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[min/h]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 60
                        }
                    },
                ],
            },
        };
        const options16 = {
            scales: {
                yAxes: [
                    
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-23',
                        gridLines: {
                            drawOnChartArea: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[%]'
                        },
                        ticks: {
                            beginAtZero: false,
                            min: 20,
                            max: 100
                        }
                    },
                        {
                            type: 'linear',
                            display: true,
                            position: 'left',
                            id: 'y-axis-24',
                            gridLines: {
                                drawOnChartArea: false,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: '[min/h]'
                            },
                            ticks: {
                                beginAtZero: true,
                                min: 0,
                                max: 60
                            }
                        },
                ],
            },
        };
        const options17 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-25',
                        scaleLabel: {
                            display: true,
                            labelString: '[°C]'
                        },
                        gridLines: {
                            drawOnChartArea: false
                        }
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-26',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[mm]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 5
                        }
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-27',
                        scaleLabel: {
                            display: true,
                            labelString: '[min/h]'
                        },
                        gridLines: {
                            drawOnChartArea: false,
                        },
                    },
                ],
            },
        };
        const options18 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-28',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[°C]'
                        },
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-29',
                        gridLines: {
                            drawOnChartArea: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[%]'
                        },
                        ticks: {
                            beginAtZero: false,
                            min: 20,
                            max: 100
                        }
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-30',
                        scaleLabel: {
                            display: true,
                            labelString: '[°C]'
                        },
                        gridLines: {
                            drawOnChartArea: false
                        }
                    },

                ],
            },
        };
        const options19 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-31',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[°C]'
                        },
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-32',
                        scaleLabel: {
                            display: true,
                            labelString: '[°C]'
                        },
                        gridLines: {
                            drawOnChartArea: false
                        }
                    },
                    
                ],
            },
        };


        const options20 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-33',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[%]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: this.state.min ? this.state.max : 0,
                            minTicksLimit: this.state.minTicksLimit ? this.state.minTicksLimitx : 5,
                            max: this.state.max ? this.state.max : 5,
                            maxTicksLimit: this.state.maxTicksLimit ? this.state.maxTicksLimit :5
                        }
                    },
                ],
            },
        };

        const options21 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-33',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[%]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: this.state.min ? this.state.max : 0,
                            minTicksLimit: this.state.minTicksLimit ? this.state.minTicksLimitx : 5,
                            max: this.state.max ? this.state.max : 5,
                            maxTicksLimit: this.state.maxTicksLimit ? this.state.maxTicksLimit :5
                        }
                    },
                ],
            },
        };

        if (this.state.disease && this.state.disease.dates) {
            graph1 = {
                labels: this.state.disease.dates,
                datasets: [
                    {
                        data: this.state.disease[this.state.param],
                        label: this.props.label != null ? i18n.t(this.props.label): i18n.t('Infection [%]'),
                        backgroundColor: 'transparent',
                        borderColor: 'red',
                        type: 'line',
                        borderWidth: 2,
                        pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                        yAxisID: 'y-axis-1',
                    },
                ],
            };
        };
        if (this.state.disease && this.state.disease.dates){
            graph2 = {
            labels: this.state.disease.dates,
            datasets: [
                    {
                        data: this.state.disease['HC Air temperature'],
                        label: i18n.t('Temperature [°C]'),
                        backgroundColor: 'transparent',
                        borderColor: 'red',
                        type: 'line',
                        borderWidth: 2,
                        pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                        yAxisID: 'y-axis-2',
                    },{
                        data: this.state.disease['HC Relative humidity'],
                        label: i18n.t('Humidity [%]'),
                        type: 'line',
                        backgroundColor: 'transparent',
                        borderColor: '#00BFFF',
                        borderWidth: 2,
                        pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                        yAxisID: 'y-axis-3',
                    },

                ],
            }
        };
        if (this.state.disease && this.state.disease.dates){
            graph3 = {
                labels: this.state.disease.dates,
                datasets: [
                        {
                            data: this.state.disease['Precipitation'],
                            label: i18n.t('Precipitation [mm]'),
                            backgroundColor: 'rgb(70, 130, 180,0.9)',
                            borderColor: '#4682B4',
                            type: 'line',
                            borderWidth: 2,
                            pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                            yAxisID: 'y-axis-4',
                        },{
                            data: this.state.disease['Leaf Wetness'],
                            label: i18n.t('Leaf wetness [min/h]'),
                            backgroundColor: '#7CFC00',
                            borderColor: '#7CFC00',
                            type: 'bar',
                            borderWidth: 2,
                            pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                            yAxisID: 'y-axis-5',
                        },
    
                    ],
            }
        };
        if (this.state.disease && this.state.disease.dates){
            graph4 = {
                labels: this.state.disease.dates,
                datasets: [
                        {
                            data: this.state.disease['Leaf Wetness'],
                            label: i18n.t('Leaf wetness [min/h]'),
                            backgroundColor: '#7CFC00',
                            borderColor: '#7CFC00',
                            type: 'bar',
                            borderWidth: 2,
                            pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                            yAxisID: 'y-axis-6',
                        },
    
                    ],
            }
        };
        // 3 line
        if (this.state.disease.dates && this.state.disease){
            graph5 = {
                labels: this.state.disease.dates,
                datasets: [
                    {
                        data: this.state.disease[this.state.param1],
                        label: this.props.label1 != null ? i18n.t(this.props.label1): i18n.t('Light Infection [%]'),
                        backgroundColor: 'transparent',
                        borderColor: 'yellow',
                        type: 'line',
                        borderWidth: 2,
                        pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                        yAxisID: 'y-axis-7',
                    },
                    {
                        data: this.state.disease[this.state.param2],
                        label: this.props.label2 != null ? i18n.t(this.props.label2): i18n.t('Moderate Infection [%]'),
                        backgroundColor: 'transparent',
                        borderColor: '#e3c542',
                        type: 'line',
                        borderWidth: 2,
                        pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                        yAxisID: 'y-axis-7',
                    },
                    {
                        data: this.state.disease[this.state.param3],
                        label: this.props.label3 != null ? i18n.t(this.props.label3): i18n.t('Severe Infection [%]'),
                        backgroundColor: 'transparent',
                        borderColor: 'red',
                        type: 'line',
                        borderWidth: 2,
                        pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                        yAxisID: 'y-axis-7',
                    },
                ],
            };
        };
        if (this.state.disease.dates && this.state.disease){
            graph6 = {
                labels: this.state.disease.dates,
                datasets: [
                    {
                        data: this.state.disease[this.state.param1],
                        label: this.props.label1 != null ? i18n.t(this.props.label1): i18n.t(param1 + ' [%]'),
                        backgroundColor: 'transparent',
                        borderColor: 'green',
                        type: 'line',
                        borderWidth: 2,
                        pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                        yAxisID: 'y-axis-8',
                    },
                    {
                        data: this.state.disease[this.state.param2],
                        label: this.props.label2 != null ? i18n.t(this.props.label2): i18n.t(param2 + ' [%]'),
                        backgroundColor: 'transparent',
                        borderColor: 'red',
                        type: 'line',
                        borderWidth: 2,
                        pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                        yAxisID: 'y-axis-8',
                    },
                ],
            };
        };
        if (this.state.disease.dates && this.state.disease){
        graph7 = {
            labels: this.state.disease.dates,
            datasets: [
                {
                    data: this.state.disease['HC Air temperature'],
                    label: i18n.t('Temperature [°C]'),
                    backgroundColor: 'transparent',
                    borderColor: 'red',
                    type: 'line',
                    borderWidth: 2,
                    pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                    yAxisID: 'y-axis-9',
                },
                {
                    data: this.state.disease['Leaf Wetness'],
                    label: i18n.t('Leaf wetness [min/h]'),
                    backgroundColor: '#7CFC00',
                    borderColor: '#7CFC00',
                    type: 'bar',
                    borderWidth: 2,
                    pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                    yAxisID: 'y-axis-10',
                },
                {
                    data: this.state.disease['Solar radiation'],
                    label: i18n.t('Solar radiation [W/m2]'),
                    type: 'line',
                    backgroundColor: 'rgba(255,255,180,0.7)',
                    borderColor: 'rgb(216,216,120)',
                    borderWidth: 2,
                    pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                    yAxisID: 'y-axis-11',
                },
            ],
            };
        };
    if (this.state.disease.dates && this.state.disease){
        graph8 = {
            labels: this.state.disease.dates,
            datasets: [
                {
                    data: this.state.disease['HC Air temperature'],
                    label: i18n.t('Temperature [°C]'),
                    backgroundColor: 'transparent',
                    borderColor: 'red',
                    type: 'line',
                    borderWidth: 2,
                    pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                    yAxisID: 'y-axis-12',
                }, 
                {
                    data: this.state.disease['Solar radiation'],
                    label: i18n.t('Solar radiation [W/m2]'),
                    type: 'line',
                    backgroundColor: 'rgba(255,255,180,0.7)',
                    borderColor: 'rgb(216,216,120)',
                    borderWidth: 2,
                    pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                    yAxisID: 'y-axis-13',
                },
            ],
            };
        };
        if (this.state.disease.dates && this.state.disease){
            graph9 = {
                labels: this.state.disease.dates,
                datasets: [
                    {
                        data: this.state.disease['Solar radiation'],
                    label: i18n.t('Solar radiation [W/m2]'),
                    type: 'line',
                    backgroundColor: 'rgba(255,255,180,0.7)',
                    borderColor: 'rgb(216,216,120)',
                    borderWidth: 2,
                    pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                    yAxisID: 'y-axis-13',

                    data: this.state.disease['Soil temperature'],
                    label: i18n.t('Soil temperature [°C]'),
                    type: 'line',
                    backgroundColor: 'transparent',
                    borderColor: 'rgb(234, 182, 79)',
                    borderWidth: 2,
                    pointRadius: this.state.disease.dates.length > 72 ? 0 : 4,
                    yAxisID: 'y-axis-14',

                    },
                ],
                };
            };
        

            if (this.state.disease.dates && this.state.disease){
                graph10 = {
                    labels: this.state.disease.dates,
                    datasets: [
                        {
                            data: this.state.disease['HC Air temperature'],
                            label: i18n.t('Temperature [°C]'),
                            backgroundColor: 'transparent',
                            borderColor: 'red',
                            type: 'line',
                            borderWidth: 2,
                            pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                            yAxisID: 'y-axis-15',
                        },
                        {
                            data: this.state.disease['Leaf Wetness'],
                            label: i18n.t('Leaf wetness [min/h]'),
                            backgroundColor: '#7CFC00',
                            borderColor: '#7CFC00',
                            type: 'bar',
                            borderWidth: 2,
                            pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                            yAxisID: 'y-axis-16',
                        },
                    ],
                    };
                };

                if (this.state.disease.dates && this.state.disease){
                    graph11 = {
                        labels: this.state.disease.dates,
                        datasets: [
                            {
                                data: this.state.disease[this.state.param],
                                label: i18n.t('intensity of infection'),
                                backgroundColor: 'transparent',
                                borderColor: 'red',
                                type: 'line',
                                borderWidth: 2,
                                pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                                yAxisID: 'y-axis-17',
                            },                   
                        ],
                        };
                    };
                    if (this.state.disease && this.state.disease.dates){
                        graph12 = {
                        labels: this.state.disease.dates,
                        datasets: [
                                {
                                    data: this.state.disease['HC Air temperature'],
                                    label: i18n.t('Temperature [°C]'),
                                    backgroundColor: 'transparent',
                                    borderColor: 'red',
                                    type: 'line',
                                    borderWidth: 2,
                                    pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                                    yAxisID: 'y-axis-18',
                                },
            
                            ],
                        }
                    };
                    if (this.state.disease.dates && this.state.disease){
                        graph13 = {
                            labels: this.state.disease.dates,
                            datasets: [
                                {
                                data: this.state.disease['Soil temperature'],
                                label: i18n.t('Soil temperature [°C]'),
                                type: 'line',
                                backgroundColor: 'transparent',
                                borderColor: 'rgb(234, 182, 79)',
                                borderWidth: 2,
                                pointRadius: this.state.disease.dates.length > 72 ? 0 : 4,
                                yAxisID: 'y-axis-19',
            
                                },
                            ],
                            };
                        };
                        if (this.state.disease && this.state.disease.dates){
                            graph14 = {
                                labels: this.state.disease.dates,
                                datasets: [
                                        {
                                            data: this.state.disease['Precipitation'],
                                            label: i18n.t('Precipitation [mm]'),
                                            backgroundColor: 'rgb(70, 130, 180,0.9)',
                                            borderColor: '#4682B4',
                                            type: 'line',
                                            borderWidth: 2,
                                            pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                                            yAxisID: 'y-axis-20',
                                        },
                    
                                    ],
                            }
                        };
                        if (this.state.disease.dates && this.state.disease){
                            graph15 = {
                                labels: this.state.disease.dates,
                                datasets: [
                                    {
                                        data: this.state.disease['Solar radiation'],
                                    label: i18n.t('Solar radiation [W/m2]'),
                                    type: 'line',
                                    backgroundColor: 'rgba(255,255,180,0.7)',
                                    borderColor: 'rgb(216,216,120)',
                                    borderWidth: 2,
                                    pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                                    yAxisID: 'y-axis-21',
                
                                    },

                                    {
                                        data: this.state.disease['Leaf Wetness'],
                                        label: i18n.t('Leaf wetness [min/h]'),
                                        backgroundColor: '#7CFC00',
                                        borderColor: '#7CFC00',
                                        type: 'bar',
                                        borderWidth: 2,
                                        pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                                        yAxisID: 'y-axis-22',
                                    },
                                ],
                                };
                            };
                            if (this.state.disease.dates && this.state.disease){
                                graph16 = {
                                    labels: this.state.disease.dates,
                                    datasets: [
                                        
    
                                        {
                                            data: this.state.disease['Leaf Wetness'],
                                            label: i18n.t('Leaf wetness [min/h]'),
                                            backgroundColor: '#7CFC00',
                                            borderColor: '#7CFC00',
                                            type: 'bar',
                                            borderWidth: 2,
                                            pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                                            yAxisID: 'y-axis-23',
                                        },
                                        {
                                            data: this.state.disease['HC Relative humidity'],
                                            label: i18n.t('Humidity [%]'),
                                            type: 'line',
                                            backgroundColor: 'transparent',
                                            borderColor: '#00BFFF',
                                            borderWidth: 2,
                                            pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                                            yAxisID: 'y-axis-24',
                                        },
                                    ],
                                    };
                                };
                                if (this.state.disease.dates && this.state.disease){
                                    graph17 = {
                                        labels: this.state.disease.dates,
                                        datasets: [
                                            
                                            {
                                                data: this.state.disease['Soil temperature'],
                                                label: i18n.t('Soil temperature [°C]'),
                                                type: 'line',
                                                backgroundColor: 'transparent',
                                                borderColor: 'rgb(234, 182, 79)',
                                                borderWidth: 2,
                                                pointRadius: this.state.disease.dates.length > 72 ? 0 : 4,
                                                yAxisID: 'y-axis-25',
                            
                                                },
                                            {
                                                data: this.state.disease['Precipitation'],
                                                label: i18n.t('Precipitation [mm]'),
                                                backgroundColor: 'rgb(70, 130, 180,0.9)',
                                                borderColor: '#4682B4',
                                                type: 'line',
                                                borderWidth: 2,
                                                pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                                                yAxisID: 'y-axis-26',
                                            },
                                            {
                                                data: this.state.disease['Leaf Wetness'],
                                                label: i18n.t('Leaf wetness [min/h]'),
                                                backgroundColor: '#7CFC00',
                                                borderColor: '#7CFC00',
                                                type: 'bar',
                                                borderWidth: 2,
                                                pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                                                yAxisID: 'y-axis-27',
                                            },
                                            
                                        ],
                                        };
                                    };

                                    if (this.state.disease.dates && this.state.disease){
                                        graph18 = {
                                            labels: this.state.disease.dates,
                                            datasets: [
                                                {
                                                    data: this.state.disease['HC Air temperature'],
                                                    label: i18n.t('Temperature [°C]'),
                                                    backgroundColor: 'transparent',
                                                    borderColor: 'red',
                                                    type: 'line',
                                                    borderWidth: 2,
                                                    pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                                                    yAxisID: 'y-axis-28',
                                                },{
                                                    data: this.state.disease['HC Relative humidity'],
                                                    label: i18n.t('Humidity [%]'),
                                                    type: 'line',
                                                    backgroundColor: 'transparent',
                                                    borderColor: '#00BFFF',
                                                    borderWidth: 2,
                                                    pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                                                    yAxisID: 'y-axis-29',
                                                },                                              
                                                {
                                                    data: this.state.disease['Soil temperature'],
                                                    label: i18n.t('Soil temperature [°C]'),
                                                    type: 'line',
                                                    backgroundColor: 'transparent',
                                                    borderColor: 'rgb(234, 182, 79)',
                                                    borderWidth: 2,
                                                    pointRadius: this.state.disease.dates.length > 72 ? 0 : 4,
                                                    yAxisID: 'y-axis-30',
                                
                                                    },                                                                           
                                            ],
                                            };
                                        };
                                        if (this.state.disease && this.state.disease.dates){
                                            graph19 = {
                                            labels: this.state.disease.dates,
                                            datasets: [
                                                    {
                                                        data: this.state.disease['HC Air temperature'],
                                                        label: i18n.t('Temperature [°C]'),
                                                        backgroundColor: 'transparent',
                                                        borderColor: 'red',
                                                        type: 'line',
                                                        borderWidth: 2,
                                                        pointRadius: this.state.disease.dates.length > 72 ? 0 : 2,
                                                        yAxisID: 'y-axis-31',
                                                    },
                                                    {
                                                        data: this.state.disease['Soil temperature'],
                                                        label: i18n.t('Soil temperature [°C]'),
                                                        type: 'line',
                                                        backgroundColor: 'transparent',
                                                        borderColor: 'rgb(234, 182, 79)',
                                                        borderWidth: 2,
                                                        pointRadius: this.state.disease.dates.length > 72 ? 0 : 4,
                                                        yAxisID: 'y-axis-32',
                                    
                                                        },    
                                
                                                ],
                                            }
                                        };

                                        if (this.state.disease && this.state.disease.dates) {
                                            graph20 = {
                                                labels: this.state.disease.slicedDates ? this.state.disease.slicedDates : this.state.disease.dates,
                                                datasets: [
                                                    {
                                                        data: this.state.disease.slicedValues ? this.state.disease.slicedValues : this.state.disease[this.state.param],
                                                        label: this.props.label != null ? i18n.t(this.props.label): i18n.t('Infection [%]'),
                                                        backgroundColor: 'transparent',
                                                        borderColor: 'red',
                                                        type: 'line',
                                                        borderWidth: 2,
                                                        pointRadius: this.props.daily ? 2 : (this.state.disease.dates.length > 72 ? 0 : 2),
                                                        yAxisID: 'y-axis-33',
                                                    },
                                                ],
                                            };
                                        };

                                        
    
        return (
            <div style={{ paddingLeft: '35px' }}>
                 <div style= {{display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                        <p style={{fontSize: 20, marginRight: 4 }}> {i18n.t(this.props.title)}</p>
                        <p style={{fontSize: 20, fontStyle: 'italic'}}>({this.props.latinTitle})</p>
                    </div>
                </div>
                <DatePickerMeteo startDate={this.state.startDate} endDate={this.state.endDate} setDates={this.setDates} getPessl={this.getPessl} />
                {(this.state.loadDisease === true ) && <div style={{ marginLeft: '39%', marginTop: '14%' }}> <MoonLoader loading={this.state.loadDisease === true || this.state.loadData === true} size={100} /></div>}
                {this.state.loadDisease === false &&
                <div>

{(this.props.intensityofinfection === true) &&
                    <div>
                        <div style={{ width: '95%', marginTop: '10px' }}>
                            <Bar data={graph11} height={300} width={600} options={options11} />
                        </div>
                        <div style={{ padding: '20px', borderRadius: '8px', boxShadow: ' 0 5px 10px rgba(154, 160, 185, 0.2), 0 15px 40px rgba(166, 173, 201, 0.1)', backgroundColor: 'white !important', marginRight: '35px' }}>
                            <p style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Info:</p>
                            <p>{i18n.t('1 - intensity of infection is greater than 10%')}</p>
                            <p>{i18n.t('2 - intensity of infection is greater than 20%')}</p>
                            <p>{i18n.t('3 - intensity of infection is greater than 30%')}</p>
                            <p>{i18n.t('4 - intensity of infection is greater than 40%')}</p>
                            <p>{i18n.t('5 - intensity of infection is greater than 50%')}</p>
                        </div>
                    </div> }
                    {(this.props.favcon === true) &&
                    <div>
                        <div style={{ width: '95%', marginTop: '10px' }}>
                            <Bar data={graph20} height={this.props.height ? this.props.height:300} width={600} options={options20} />
                        </div>
                    {(this.props.legend1 === true)&& 
                        <div style={{ padding: '20px', borderRadius: '8px', boxShadow: ' 0 5px 10px rgba(154, 160, 185, 0.2), 0 15px 40px rgba(166, 173, 201, 0.1)', backgroundColor: 'white !important', marginRight: '35px' }}>
                            <p style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Info:</p>
                            <p>{i18n.t('0 - Favorable conditions for the appearance of the disease have not been achieved')}</p>
                            <p>{i18n.t('1 - The conditions for the appearance of the disease have been met')}</p>                         
                        </div>

                        }
                        {(this.props.legend2 === true)&&
                        <div style={{ padding: '20px', borderRadius: '8px', boxShadow: ' 0 5px 10px rgba(154, 160, 185, 0.2), 0 15px 40px rgba(166, 173, 201, 0.1)', backgroundColor: 'white !important', marginRight: '35px' }}>
                            <p style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Info:</p>
                            <p>{i18n.t('1 - the risk of sporulation and infection is greater than 10%')}</p>
                            <p>{i18n.t('2 - the risk of sporulation and infection is greater than 20%')}</p>
                            <p>{i18n.t('3 - the risk of sporulation and infection is greater than 30%')}</p>
                            <p>{i18n.t('4 - the risk of sporulation and infection is greater than 40%')}</p>
                            <p>{i18n.t('5 - the risk of sporulation and infection is greater than 50%')}</p>
                        </div>
    }
    {(this.props.legend3 === true)&&
    
                        <div style={{ padding: '20px', borderRadius: '8px', boxShadow: ' 0 5px 10px rgba(154, 160, 185, 0.2), 0 15px 40px rgba(166, 173, 201, 0.1)', backgroundColor: 'white !important', marginRight: '35px' }}>
                        <p style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Info:</p>
                        <p>{i18n.t('1 - intensity of infection is greater than 10%')}</p>
                        <p>{i18n.t('2 - intensity of infection is greater than 20%')}</p>
                        <p>{i18n.t('3 - intensity of infection is greater than 30%')}</p>
                        <p>{i18n.t('4 - intensity of infection is greater than 40%')}</p>
                        <p>{i18n.t('5 - intensity of infection is greater than 50%')}</p>
                    </div>
    }
                    </div> }
                    {(this.props.primpest === true) &&
                    <div>
                    <div style={{ width: '95%', marginTop: '10px' }}>
                            <Bar data={graph1} height={300} width={600} options={options1} />
                        </div>
                        <div style={{ padding: '20px', borderRadius: '8px', boxShadow: ' 0 5px 10px rgba(154, 160, 185, 0.2), 0 15px 40px rgba(166, 173, 201, 0.1)', backgroundColor: 'white !important', marginRight: '35px' }}>
                            <p style={{ fontWeight: 'bold', fontStyle: 'italic' }}>Info:</p>
                            <p>{i18n.t('1 - Very low risk of pest occurrence')}</p>
                            <p>{i18n.t('2 - Low risk of pests')}</p>
                            <p>{i18n.t('3 - High risk of pests')}</p>
                            <p>{i18n.t('4 - Very high risk of pest occurrence')}</p>
                            </div> 
                        </div>}

                    {(this.props.priminf === true) &&
                    <div>
                        <div style={{ width: '95%', marginTop: '10px' }}>
                            <Bar data={graph5} height={300} width={600} options={options5} />
                        </div>
                        
                    </div> }
                {(this.props.diseaseGraphType === 'oneline') &&
                    <div>
                        <div style={{ width: '95%', marginTop: '10px' }}>
                            <Bar data={graph1} height={300} width={600} options={options1} />
                        </div>
                    </div>}

                {(this.props.diseaseGraphType === 'twoline') &&
                    <div>
                        <div style={{ width: '95%', marginTop: '10px' }}>
                            <Bar data={graph6} height={300} width={600} options={options6} />
                        </div>
                    </div>}

                {(this.props.diseaseGraphType === 'threeline')  &&
                    <div>
                        <div style={{ width: '95%', marginTop: '20px' }}>
                            <Bar data={graph5} height={200} width={400} options={options5} />
                        </div>
                    </div> }

                {(this.props.temphum === true) &&
                    <div>
                        <div style={{ width: '95%', marginTop: '20px' }}>
                            <Bar data={graph2} height={200} width={400} options={options2} />
                        </div>
                        <MeteoTable data={{
                            headers: [i18n.t('Date and time'), i18n.t('Temperature [°C]'), i18n.t('Relative humidity [%]')],
                            dates: this.state.disease.dates,
                            temperatures: this.state.disease['HC Air temperature'],
                            humidity: this.state.disease['HC Relative humidity']
                        }} />
                    </div> }

                {(this.props.templeafsol === true) &&
                    <div>
                        <div style={{ width: '95%', marginTop: '20px' }}>
                            <Bar data={graph7} height={200} width={400} options={options7} />
                        </div>
                        <MeteoTable data={{
                            headers: [i18n.t('Date and time'), i18n.t('Temperature [°C]'), i18n.t('Leaf wetness [min/h]'), i18n.t('Solar radiation [W/m2]') ],
                            dates: this.state.disease.dates,
                            temperatures: this.state.disease['HC Air temperature'],
                            leafwetness: this.state.disease['Leaf Wetness'],
                            solar: this.state.disease['Solar radiation']
                        }} />
                    </div> }
                
                {(this.props.tempsol === true) &&
                    <div>
                        <div style={{ width: '95%', marginTop: '20px' }}>
                            <Bar data={graph8} height={200} width={400} options={options8} />
                        </div>
                        <MeteoTable data={{
                            headers: [i18n.t('Date and time'), i18n.t('Temperature [°C]'), i18n.t('Leaf wetness [min/h]'), i18n.t('Solar radiation [W/m2]') ],
                            dates: this.state.disease.dates,
                            temperatures: this.state.disease['HC Air temperature'],
                            solar: this.state.disease['Solar radiation']
                        }} />
                    </div> }            

                {(this.props.leafprec === true) &&
                    <div>
                        <div style={{ width: '95%', marginTop: '20px' }}>
                            <Bar data={graph3} height={200} width={400} options={options3} />
                        </div>
                        <MeteoTable data={{
                            headers: [i18n.t('Date and time'), i18n.t('Precipitation [mm]'), i18n.t('Leaf wetness [min/h]')],
                            dates: this.state.disease.dates,
                            precipitation: this.state.disease['Precipitation'],
                            leafwetness: this.state.disease['Leaf Wetness']
                        }} />
                    </div> }

                {(this.props.leaf === true) &&
                    <div>
                        <div style={{ width: '95%', marginTop: '20px' }}>
                            <Bar data={graph4} height={200} width={400} options={options4} />
                        </div>
                        <MeteoTable data={{
                            headers: [i18n.t('Date and time'), i18n.t('Leaf wetness [min/h]')],
                            dates: this.state.disease.dates,
                            leafwetness: this.state.disease['Leaf Wetness']
                        }} />
                    </div> }

                   
                    

                    {(this.props.templeaf === true) &&
                    <div>
                        <div style={{ width: '95%', marginTop: '20px' }}>
                            <Bar data={graph10} height={200} width={400} options={options10} />
                        </div>
                        <MeteoTable data={{
                            headers: [i18n.t('Date and time'), i18n.t('Temperature [°C]'), i18n.t('Leaf wetness [min/h]')],
                            dates: this.state.disease.dates,
                            temperatures: this.state.disease['HC Air temperature'],
                            leafwetness: this.state.disease['Leaf Wetness'],
                        }} />
                    </div> }

                    {(this.props.temphumleaf === true) &&
                    <div>
                        <div style={{ width: '95%', marginTop: '20px' }}>
                            <Bar data={graph2} height={200} width={400} options={options2} />
                        </div>
                        <MeteoTable data={{
                            headers: [i18n.t('Date and time'), i18n.t('Temperature [°C]'), i18n.t('Leaf wetness [min/h]'), i18n.t('Relative humidity [%]')],
                            dates: this.state.disease.dates,
                            temperatures: this.state.disease['HC Air temperature'],
                            leafwetness: this.state.disease['Leaf Wetness'],
                            humidity: this.state.disease['HC Relative humidity']
                        }} />
                    </div> }

                    {(this.props.temp === true) &&
                    <div>
                        <div style={{ width: '95%', marginTop: '20px' }}>
                            <Bar data={graph12} height={200} width={400} options={options12} />
                        </div>
                        <MeteoTable data={{
                            headers: [i18n.t('Date and time'), i18n.t('Temperature [°C]')],
                            dates: this.state.disease.dates,
                            temperatures: this.state.disease['HC Air temperature']
                        }} />
                    </div> }

                    {(this.props.soil === true) &&
                    <div>
                        <div style={{ width: '95%', marginTop: '20px' }}>
                            <Bar data={graph13} height={200} width={400} options={options13} />
                        </div>
                        <MeteoTable data={{
                            headers: [i18n.t('Date and time'), i18n.t('Soil temperature'),],
                            dates: this.state.disease.dates,
                            soil: this.state.disease['Soil temp']
                        }} />
                    </div> }
                    {(this.props.prec === true) &&
                    <div>
                        <div style={{ width: '95%', marginTop: '20px' }}>
                            <Bar data={graph14} height={200} width={400} options={options14} />
                        </div>
                        <MeteoTable data={{
                            headers: [i18n.t('Date and time'), i18n.t('Precipitation [mm]')],
                            dates: this.state.disease.dates,
                            precipitation: this.state.disease['Precipitation']
                        }} />
                    </div> }
                    {(this.props.solarleaf === true) &&
                    <div>
                        <div style={{ width: '95%', marginTop: '20px' }}>
                            <Bar data={graph15} height={200} width={400} options={options15} />
                        </div>
                        <MeteoTable data={{
                            headers: [i18n.t('Date and time'), i18n.t('Solar radiation [W/m2]'), i18n.t('Leaf wetness [min/h]')],
                            dates: this.state.disease.dates,
                            solar: this.state.disease['Solar radiation'],
                            leafwetness: this.state.disease['Leaf Wetness']
                        }} />
                    </div> }
                    {(this.props.humleaf === true) &&
                    <div>
                        <div style={{ width: '95%', marginTop: '20px' }}>
                            <Bar data={graph16} height={200} width={400} options={options16} />
                        </div>
                        <MeteoTable data={{
                            headers: [i18n.t('Date and time'), i18n.t('HC Relative humidity'), i18n.t('Leaf wetness [min/h]')],
                            dates: this.state.disease.dates,
                            humidity: this.state.disease['HC Relative humidity'],
                            leafwetness: this.state.disease['Leaf Wetness']
                        }} />
                    </div> }
                    {(this.props.soilprecleaf === true) &&
                    <div>
                        <div style={{ width: '95%', marginTop: '20px' }}>
                            <Bar data={graph17} height={200} width={400} options={options17} />
                        </div>
                        <MeteoTable data={{
                            headers: [i18n.t('Date and time'), i18n.t('Soil temperature'), i18n.t('Precipitation [mm]'), i18n.t('Leaf wetness [min/h]')],
                            dates: this.state.disease.dates,
                            soil: this.state.disease['Soil temperature'],
                            precipitation: this.state.disease['Precipitation'],
                            leafwetness: this.state.disease['Leaf Wetness']
                        }} />
                    </div> }
                
                    {(this.props.temphumsoil === true) &&
                    <div>
                        <div style={{ width: '95%', marginTop: '20px' }}>
                            <Bar data={graph18} height={200} width={400} options={options18} />
                        </div>
                        <MeteoTable data={{
                            headers: [i18n.t('Date and time'), i18n.t('Temperature [°C]'), i18n.t('Relative humidity [%]'), i18n.t('Soil temp')],
                            dates: this.state.disease.dates,
                            temperatures: this.state.disease['HC Air temperature'],
                            humidity: this.state.disease['HC Relative humidity'],
                            soil: this.state.disease['Soil temperature']
                        }} />
                    </div> }
                    {(this.props.tempsoil === true) &&
                    <div>
                        <div style={{ width: '95%', marginTop: '20px' }}>
                            <Bar data={graph19} height={200} width={400} options={options19} />
                        </div>
                        <MeteoTable data={{
                            headers: [i18n.t('Date and time'), i18n.t('Temperature [°C]'), i18n.t('Soil temp')],
                            dates: this.state.disease.dates,
                            temperatures: this.state.disease['HC Air temperature'],
                            soil: this.state.disease['Soil temperature']
                        }} />
                    </div> }
                
                        <div style={{ marginTop: '20px' }}></div>
                    </div>
                }
            </div>
        );
    }
};

export default withStyles(styles)(UniDisease);
