import immutable from 'seamless-immutable';
import { Type as RegionsType } from '../../actions/control_panel/RegionActions';

export const INITIAL_STATE = immutable({
    regionPosting: false,
    regionPostingSuccess: false,
    regionPostingFailed: false,

    region: [],
    regionObj: {},
    regionFetching: false,
    regionPages: 1,

    regionType: [],
    regionTypeFetching: false,
    regionTypeFetchingFailed: false,

    copyFrom: [],
    copyFromFetching: {},

    copyTo: [],
    copyToFetching: {},

    copyResourcesPosting: [],
    copyResourcesPostingSuccess: false,
    copyResourcesPostingFailed: false,

});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case RegionsType.SET_INITIAL_STATE: {
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;
        }

        case RegionsType.ADD_REGION_CALL: {
            const regionPosting = true;
            return state.merge({ regionPosting });
            break;
        }

        case RegionsType.ADD_REGION_SUCCESS: {
            const regionPosting = false;
            const regionPostingSuccess = true;
            return state.merge({ regionPostingSuccess, regionPosting, regionObj: INITIAL_STATE.regionObj })
            break;
        }

        case RegionsType.ADD_REGION_FAILED: {
            const regionPosting = false;
            const regionPostingFailed = true;
            return state.merge({ regionPosting, regionPostingFailed });
            break;
        }

        case RegionsType.GET_REGION_READ_CALL: {
            const regionFetching = true;
            return state.merge({ regionFetching });
            break;
        }

        case RegionsType.GET_REGION_READ_SUCCESS: {
            const region = action.data.data;
            const regionFetching = false;
            const regionPages = action.data.total_pages
            return state.merge({ region, regionFetching, regionPages });
            break;
        }

        case RegionsType.GET_REGION_READ_FAILED: {
            const regionFetchingFailed = true;
            const regionFetching = false;
            return state.merge({ regionFetching, regionFetchingFailed });
            break;
        }

        case RegionsType.GET_REGION_TYPE_CALL: {
            const regionTypeFetching = true;
            return state.merge({ regionTypeFetching });
            break;
        }

        case RegionsType.GET_REGION_TYPE_SUCCESS: {
            const regionType = action.data.data;
            const regionTypeFetching = false;
            return state.merge({ regionType, regionTypeFetching });
            break;
        }

        case RegionsType.GET_REGION_TYPE_FAILED: {
            const regionTypeFetchingFailed = true;
            const regionTypeFetching = false;
            return state.merge({ regionTypeFetching, regionTypeFetchingFailed });
            break;
        }

        case RegionsType.SET_REGION_ROW_IN_REDUCER: {
            const data = action.rowInfo;
            return state.merge({ regionObj: data })
            break;
        }

        case RegionsType.GET_COPY_FROM_CALL: {
            const copyFromFetching = true;
            return state.merge({ copyFromFetching });
            break;
        }

        case RegionsType.GET_COPY_FROM_SUCCESS: {
            const copyFrom = action.data.data;
            const copyFromFetching = false;
            return state.merge({ copyFrom, copyFromFetching });
            break;
        }

        case RegionsType.GET_COPY_FROM_FAILED: {
            const copyFromFetchingFailed = true;
            const copyFromFetching = false;
            return state.merge({ copyFromFetching, copyFromFetchingFailed });
            break;
        }

        case RegionsType.GET_COPY_TO_CALL: {
            const copyToFetching = true;
            return state.merge({ copyToFetching });
            break;
        }

        case RegionsType.GET_COPY_TO_SUCCESS: {
            const copyTo = action.data.data;
            const copyToFetching = false;
            return state.merge({ copyTo, copyToFetching });
            break;
        }

        case RegionsType.GET_COPY_TO_FAILED: {
            const copyToFetchingFailed = true;
            const copyToFetching = false;
            return state.merge({ copyToFetching, copyToFetchingFailed });
            break;
        }

        case RegionsType.LOCK_REGION_CALL: {
            const regionPosting = true;
            return state.merge({ regionPosting });
            break;
        }

        case RegionsType.LOCK_REGION_SUCCESS: {
            const regionPosting = false;
            const regionPostingSuccess = true;
            return state.merge({ regionPostingSuccess, regionPosting, regionObj: INITIAL_STATE.regionObj })
            break;
        }

        case RegionsType.LOCK_REGION_FAILED: {
            const regionPosting = false;
            const regionPostingFailed = true;
            return state.merge({ regionPosting, regionPostingFailed });
            break;
        }

        case RegionsType.COPY_RESOURCES_CALL: {
            const copyResourcesPosting = true;
            return state.merge({ copyResourcesPosting });
            break;
        }

        case RegionsType.COPY_RESOURCES_SUCCESS: {
            const copyResourcesPosting = false;
            const copyResourcesPostingSuccess = true;
            return state.merge({ copyResourcesPostingSuccess, copyResourcesPosting})
            break;
        }

        case RegionsType.COPY_RESOURCES_FAILED: {
            const copyResourcesPosting = false;
            const copyResourcesPostingFailed = true;
            return state.merge({ copyResourcesPosting, copyResourcesPostingFailed });
            break;
        }

        default:
            return state;
    }
}