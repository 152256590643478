import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {
    getAgroProduction, getTemplate, setInitialState, signActivity, declineActivity
} from '../../actions/reports/ReportsActions';
import { getImplement } from '../../actions/resources/ImplementsActions';
import { getMachinesDropdown } from '../../actions/resources/MachinesActions';
import { getStates } from '../../actions/AdministrationActions';
import { getSeasonDropDown } from '../../actions/resources/SeasonsActions';
import { getLocalGov } from '../../actions/FarmRegistarActions';
import { getFieldDropDown } from '../../actions/catastral_data/FieldActions';
import { getWODropdown } from '../../actions/resources/WorkingOperationsActions';
import i18n from '../../i18n/i18n';
import Select from 'react-select';
import immutable from 'seamless-immutable';
import {
    Button, IconButton, Grid, TextField, Fab,
    Dialog, DialogContent, DialogTitle, DialogActions
} from '@material-ui/core';
import { SignActivity } from '../../utils/SignActivity';
import DatePickerForFilter from '../../utils/DatePickerForFilter';
import { DatePicker } from 'material-ui-pickers';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import DashboardDrawer from '../../utils/DashboardDrawer';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { exportDocument } from '../../actions/ExportActions';
import Export from '../../utils/Export';
import { getUser } from '../../utils/CommonFunctions';
import axios from '../../utils/AxiosWrapper';
import ForBooleanFilter from '../../utils/ForBooleanFilter';

const CheckboxTable = checkboxHOC(ReactTable);
export class AgroProduction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            add: false,
            selection: [],
            selectAll: false,
            potpisan: false,
            odbijen: false,
            view: false,
            declined: false,
            signedPopup: false,
            opis: '',
            user: {},
            statesDropdown: []
        };
        this.tableScrollTop = 0;
    }

    componentDidMount() {
        if (this.props.seasonDropDown.length === 0) {
            this.props.getSeasonDropDown();
        }
        if (this.props.states.length === 0) {
            this.props.getStates();
        }
        if (this.props.localGoverments.length === 0) {
            this.props.getLocalGov();
        }
        if (this.props.fieldDropDown.length == 0) {
            this.props.getFieldDropDown();
        }
        if (this.props.woDropdown.length === 0) {
            this.props.getWODropdown();
        }
        if (this.props.implementDropdown.length === 0) {
            this.props.getImplement();
        }
        if (this.props.machineDropdown.length === 0) {
            this.props.getMachinesDropdown();
        }
        axios.get('api/katastarska_opstina/filter')
            .then(res => { this.setState({ statesDropdown: res.data.data }) })
            .catch(err => { console.log(err) })

        let user = getUser();
        this.setState({ user })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.activityPostingSuccess == true) {
            toast.success(i18n.t('Activity successfully signed!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('activityPostingSuccess');
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.setState({ potpisan: false, odbijen: false, selection: [] }, function () {
                this.props.getAgroProduction(wrapped.state, { potpisan: this.state.potpisan, odbijen: this.state.odbijen });
            })
        }
        if (nextProps.activityPostingFailed == true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('activityPostingFailed');
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.setState({ potpisan: false, odbijen: false, selection: [] }, function () {
                this.props.getAgroProduction(wrapped.state, { potpisan: this.state.potpisan, odbijen: this.state.odbijen });
            })
        }
        if (nextProps.activityRejectingSuccess == true) {
            toast.success(i18n.t('Activity successfully rejected!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('activityRejectingSuccess');
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.setState({ potpisan: false, odbijen: false, selection: [] }, function () {
                this.props.getAgroProduction(wrapped.state, { potpisan: this.state.potpisan, odbijen: this.state.odbijen });
            })
        }
        if (nextProps.activityRejectingFailed == true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('activityRejectingFailed');
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.setState({ potpisan: false, odbijen: false, selection: [] }, function () {
                this.props.getAgroProduction(wrapped.state, { potpisan: this.state.potpisan, odbijen: this.state.odbijen });
            })
        }
        this.setState({
            machineDropdown: immutable.asMutable(nextProps.machineDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            implementDropdown: immutable.asMutable(nextProps.implementDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            states: immutable.asMutable(nextProps.states.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            seasonDropDown: immutable.asMutable(nextProps.seasonDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            fieldDropDown: immutable.asMutable(nextProps.fieldDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            localGoverments: immutable.asMutable(nextProps.localGoverments.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            woDropdown: immutable.asMutable(nextProps.woDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
        })
    }

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }


    onPieClick = () => {
        this.props.history.push('/agro_production_dashboard');
    }

    onTableClick = () => {
        this.props.history.push('/agro_production');
    }

    handleChangeCheckbox = name => event => {
        this.setState({ [name]: event.target.checked }, function () {
            const wrapped = this.checkboxTable.getWrappedInstance();
            let filters = {};
            filters['odbijen'] = this.state.odbijen;
            filters['potpisan'] = this.state.potpisan;
            this.props.getAgroProduction(wrapped.state, filters);
        });
    };

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            selection.push(key);
        }
        this.setState({ selection }, function () {
            if (selection.length == 1) {
                this.props.getTemplate(selection);
            } else {
                this.props.setInitialState('activityObj');
            }
        });
    };

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });
    };

    viewClick = () => {
        this.setState({ view: !this.state.view });
    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    signActivity = () => {
        this.props.signActivity(true, false, this.state.selection[0]);
        this.setState({ signedPopup: false, view: false })
    }

    declineActivity = () => {
        this.setState({ declined: !this.state.declined, opis: '' })
    }

    declineActivityFunction = () => {
        this.props.declineActivity(false, true, this.state.opis, this.state.selection[0]);
        this.setState({ declined: false, opis: '' });
    }

    signActivityPopup = () => {
        this.setState({ signedPopup: !this.state.signedPopup });
    }

    onExportClick = () => {
        this.setState({ export: !this.state.export });
    }
    exportFunction = () => {
        const table = this.checkboxTable.getWrappedInstance();
        this.props.exportDocument(this.state.docTitle, table.state, 'api/agronom_rn/read', this.state.docFormat || 'xlsx', { filters: { potpisan: this.state.potpisan, odbijen: this.state.odbijen } });
        this.setState({ export: !this.state.export });
    }

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    render() {
        let statesDropdown = this.state.statesDropdown.map((item) => { return { label: item.naziv, value: item.id } });
        // const { user } = this.state;
        let user = this.getUser();
        const columns = [
            // {
            //     Header: i18n.t('Activity number'),
            //     accessor: 'id',
            //     width: 80
            // },
            {
                Header: i18n.t('BVN'),
                accessor: 'bpg'
            },

            // {
            //     id: 'potpisan',
            //     Header: i18n.t('Signed'),
            //     accessor: d => d.potpisan,
            //     Cell: ({ value }) => value == true ? i18n.t('Yes') : i18n.t('No'),
            //     Filter: ({ filter, onChange }) =>
            //     <ForBooleanFilter
            //         onChange={(s) => {
            //             onChange(s);
            //         }}
            //     />,
            //     width: 80
            // }, 
            // {
            //     id: 'odbijen',
            //     Header: i18n.t('Rejected'),
            //     accessor: d => d.odbijen,
            //     Cell: ({ value }) => value == true ? i18n.t('Yes') : i18n.t('No'),
            //     Filter: ({ filter, onChange }) =>
            //     <ForBooleanFilter
            //         onChange={(s) => {
            //             onChange(s);
            //         }}
            //     />,
            //     width: 80
            // },

            {
                Header: i18n.t('Name surname'),
                accessor: 'naziv_klijent',
            },
            {
                Header: i18n.t('State'),
                accessor: 'naziv_opstina',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.states}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            // {
            //     Header: i18n.t('Local government'),
            //     accessor: 'naziv_katastarska_opstina',
            //     Filter: ({ filter, onChange }) =>
            //         <Select
            //             isMulti
            //             name="naziv"
            //             onChange={value => {
            //                 onChange(value)
            //             }}
            //             closeMenuOnSelect={false}
            //             options={statesDropdown}
            //             className="multi-select-in-filter"
            //             classNamePrefix="select"
            //             placeholder={i18n.t('Select')}
            //         />
            // },

            // {
            //     Header: i18n.t('Field manager'),
            //     id: 'field_manager_naziv',
            //     accessor: d => d.field_manager_naziv,
            // },
            {
                Header: i18n.t('Season'),
                accessor: 'naziv_sezona',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.seasonDropDown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            // {
            //     Header: i18n.t('Field'),
            //     accessor: 'tabla_naziv',
            //     Filter: ({ filter, onChange }) =>
            //         <Select
            //             isMulti
            //             name="naziv"
            //             onChange={value => {
            //                 onChange(value)
            //             }}
            //             closeMenuOnSelect={false}
            //             options={this.state.fieldDropDown}
            //             className="multi-select-in-filter"
            //             classNamePrefix="select"
            //             placeholder={i18n.t('Select')}
            //         />
            // },
            // {
            //     id: 'deo_table',
            //     Header: i18n.t('Part of the field'),
            //     accessor: d => d.deo_table,// Custom value accessors!  
            //     Cell: ({ value }) => value == true ? i18n.t('Yes') : i18n.t('No')
            // },
            // {
            //     Header: i18n.t('Main field'),
            //     accessor: 'glavna_tabla_naziv',
            //     Filter: ({ filter, onChange }) =>
            //         <Select
            //             isMulti
            //             name="naziv"
            //             onChange={value => {
            //                 onChange(value)
            //             }}
            //             closeMenuOnSelect={false}
            //             options={this.state.fieldDropDown}
            //             className="multi-select-in-filter"
            //             classNamePrefix="select"
            //             placeholder={i18n.t('Select')}
            //         />
            // },
            // {
            //     Header: i18n.t('Working operation'),
            //     accessor: 'naziv_radna_operacija',
            //     Filter: ({ filter, onChange }) =>
            //         <Select
            //             isMulti
            //             name="naziv"
            //             onChange={value => {
            //                 onChange(value)
            //             }}
            //             closeMenuOnSelect={false}
            //             options={this.state.woDropdown}
            //             className="multi-select-in-filter"
            //             classNamePrefix="select"
            //             placeholder={i18n.t('Select')}
            //         />
            // },
            // {
            //     Header: i18n.t('Crop'),
            //     accessor: 'naziv_kulture',
            // },
            // {
            //     Header: i18n.t('Machines'),
            //     accessor: 'pogonska_masina',
            //     Filter: ({ filter, onChange }) =>
            //         <Select
            //             isMulti
            //             name="naziv"
            //             onChange={value => {
            //                 onChange(value)
            //             }}
            //             closeMenuOnSelect={false}
            //             options={this.state.machineDropdown}
            //             className="multi-select-in-filter"
            //             classNamePrefix="select"
            //             placeholder={i18n.t('Select')}
            //         />
            // },
            // {
            //     Header: i18n.t('Implements'),
            //     accessor: 'prikljucna_masina',
            //     Filter: ({ filter, onChange }) =>
            //         <Select
            //             isMulti
            //             name="naziv"
            //             onChange={value => {
            //                 onChange(value)
            //             }}
            //             closeMenuOnSelect={false}
            //             options={this.state.implementDropdown}
            //             className="multi-select-in-filter"
            //             classNamePrefix="select"
            //             placeholder={i18n.t('Select')}
            //         />
            // },
            // {
            //     id: 'datum_planiran',
            //     Header: i18n.t('Planned date'),
            //     accessor: d => d.datum_planiran ? moment(d.datum_planiran, 'DD.MM.YYYY').format('DD.MM.YYYY') : '',
            //     Filter: ({ filter, onChange }) =>
            //         <DatePickerForFilter
            //             onChange={({ startDate, endDate }) => {
            //                 onChange({ startDate, endDate });
            //             }}

            //         />
            // },
            // {

            //     Header: i18n.t('Start date'),
            //     accessor:'datum_pocetka',

            // },
            // {

            //     Header: i18n.t('End date'),
            //     accessor:'datum_zavrsetka',

            // },
            {
                id: 'vreme_kreiranja', Header: i18n.t('Date created'), accessor: item => item.vreme_kreiranja, Cell: ({ value }) => {
                    if (value !== null) return value.substring(8, 10) + '-' + value.substring(5, 7) + '-' + value.substring(0, 4);
                    else return '';
                },
                width: 100
            },
            {
                id: 'vreme_kreiranja', Header: i18n.t('Time created'), accessor: item => item.vreme_kreiranja, Cell: ({ value }) => {
                    if (value !== null) return value.substring(11, 19)
                    else return '';
                },
                width: 100
            },
            {
                Header: i18n.t('Comment'),
                accessor: 'komentar'
            },
            // {
            //     id: 'planski',
            //     Header: i18n.t('Planned'),
            //     accessor: d => d.planski,// Custom value accessors!  
            //     Cell: ({ value }) => value == true ? i18n.t('Yes') : i18n.t('No')
            // },
            // {
            //     id: 'zakljucan',
            //     Header: i18n.t('Locked'),
            //     accessor: d => d.zakljucan,// Custom value accessors!  
            //     Cell: ({ value }) => value == true ? i18n.t('Yes') : i18n.t('No')
            // },

        ];

        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "#ACDAF1" : "inherit"
                        }
                    };
                }
                return {};
            }
        };

        return (
            <div className="page">
                <div className="table-header">
                    <Fab
                        onClick={() => this.onSearchClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-search headerIcon" />
                    </Fab>
                    {this.state.selection.length === 1 ?
                        <Fab
                            onClick={() => this.viewClick()}
                            color="secondary"
                            aria-label="edit"
                            className="action-button"
                        >
                            <span className=" icon-Informacija headerIcon" />
                        </Fab>
                        : null
                    }
                    <Fab
                        onClick={() => this.onExportClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-download headerIcon" />
                    </Fab>
                    {/* <FormControlLabel
                        label={i18n.t('Signed')}
                        className="action-button-checkbox"
                        control={
                            <Checkbox
                                color="secondary"
                                checked={this.state.potpisan}
                                onChange={this.handleChangeCheckbox('potpisan')}
                                value="potpisan"
                            />
                        }
                    />
                    <FormControlLabel
                        label={i18n.t('Rejected')}
                        className="action-button-checkbox"
                        control={
                            <Checkbox
                                color="secondary"
                                checked={this.state.odbijen}
                                onChange={this.handleChangeCheckbox('odbijen')}
                                value="odbijen"
                            />
                        }
                    /> */}
                </div>

                <CheckboxTable
                    ref={r => (this.checkboxTable = r)}
                    data={this.props.agroProduction}
                    getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                    columns={columns}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.props.agroProductionPages}
                    loading={this.props.agroProductionFetching}
                    manual
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => this.props.getAgroProduction(state, { potpisan: this.state.potpisan, odbijen: this.state.odbijen })}
                    className={this.props.isStatisticShown ? "-striped-highlight" : "-striped -highlight"}
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF',
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
                {/* <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} /> */}
                {this.state.export && <Export
                    export={this.state.export}
                    onTextChange={({ title }) => {
                        this.setState({ docTitle: title });
                    }}
                    // changeFormat
                    // onformatChange={(format) => {
                    //     this.setState({ docFormat: format });
                    // }}
                    handleClose={this.onExportClick}
                    exportDoc={this.exportFunction} />
                }
                {this.state.declined &&
                    <Dialog
                        open={this.state.declined}
                        onClose={this.declineActivity}
                        disableBackdropClick
                        maxWidth="md"
                    >
                        <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('Reject activity')}
                            <IconButton onClick={this.declineActivity}>
                                <span className="icon-cancel-circle closeIcon" />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <TextField
                                multiline
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                id="full-width"
                                margin="normal"
                                label={i18n.t('Comment')}
                                style={{ marginTop: '10px', marginBottom: '10px' }}
                                value={this.state.opis}
                                onChange={(event) => this.setState({ opis: event.target.value })}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.declineActivityFunction} style={{ backgroundColor: '#61a50e', color: 'white' }}>
                                {i18n.t('Ok')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
                {this.state.signedPopup && <SignActivity sign={this.state.signedPopup} handleClose={this.signActivityPopup} signActivity={this.signActivity} />
                }
                {this.state.view && this.props.activityObj &&
                    <Dialog
                        open={this.state.view}
                        onClose={this.viewClick}
                        disableBackdropClick
                        maxWidth="md"
                    >
                        <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('View activity')}
                            <IconButton onClick={this.viewClick}>
                                <span className="icon-cancel-circle closeIcon" />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            {this.props.activityObj.potpisan == false &&
                                <div>
                                    {user.id_klijent_grupa === 1 &&
                                        <div>
                                            {/* <Button
                                                style={{ alignSelf: 'center', backgroundColor: '#58B4E5', color: 'white', margin: '10px' }}
                                                onClick={() => this.signActivityPopup()}
                                            >
                                                {i18n.t('Sign')}
                                            </Button>
                                            <Button
                                                style={{ alignSelf: 'center', backgroundColor: '#58B4E5', color: 'white', margin: '10px' }}
                                                onClick={() => this.declineActivity()}
                                            >
                                                {i18n.t('Decline')}
                                            </Button> */}
                                        </div>
                                    }
                                </div>
                            }
                            <h2>{i18n.t('Activity data')}</h2>
                            <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('Season')}
                                        defaultValue={this.props.activityObj._sezona}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('Working operation')}
                                        defaultValue={this.props.activityObj._radna_operacija}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <div style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}></div>
                                </Grid>
                                <Grid item>
                                    <DatePicker
                                        label={i18n.t('Start date')}
                                        format="DD.MM.YYYY"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.props.activityObj.datum_pocetka ? moment(this.props.activityObj.datum_pocetka, 'DD.MM.YYYY') : null}
                                        animateYearScrolling={false}
                                        onChange={(date) => console.log(date)}
                                        disableOpenOnEnter
                                    />
                                </Grid>
                                <Grid item>
                                    <DatePicker
                                        label={i18n.t('Planned date')}
                                        format="DD.MM.YYYY"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.props.activityObj.datum_planiran ? moment(this.props.activityObj.datum_planiran, 'DD.MM.YYYY') : null}
                                        animateYearScrolling={false}
                                        onChange={(date) => console.log(date)}
                                        disableOpenOnEnter
                                    />
                                </Grid>
                                <Grid item>
                                    <DatePicker
                                        label={i18n.t('End date')}
                                        format="DD.MM.YYYY"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.props.activityObj.datum_zavrsetka ? moment(this.props.activityObj.datum_zavrsetka, 'DD.MM.YYYY') : null}
                                        animateYearScrolling={false}
                                        onChange={(date) => console.log(date)}
                                        disableOpenOnEnter
                                    />
                                </Grid>
                                {/* <Grid item>
                                    <table cellPadding="7" cellSpacing="1" border="1" className="tableCss" width="100%">
                                        <tbody>
                                            <tr className="tableRowCss">
                                                <th colSpan="8">{i18n.t('Work time')} </th>
                                                <th colSpan="8">{i18n.t('Working hours(two-piece)')}</th>
                                            </tr>
                                            <tr>
                                                <td>{i18n.t('Begin')}: </td>
                                                <td>{this.props.activityObj.sat1_od}</td>
                                                <td>: </td>
                                                <td>{this.props.activityObj.minut1_od}</td>
                                                <td>{i18n.t('End')}: </td>
                                                <td>{this.props.activityObj.sat1_do}</td>
                                                <td>: </td>
                                                <td>{this.props.activityObj.minut1_do}</td>
                                                <td>{i18n.t('Begin')}: </td>
                                                <td>{this.props.activityObj.sat2_od}</td>
                                                <td>: </td>
                                                <td>{this.props.activityObj.minut2_od}</td>
                                                <td>{i18n.t('End')}: </td>
                                                <td>{this.props.activityObj.sat2_do}</td>
                                                <td>: </td>
                                                <td>{this.props.activityObj.minut2_do}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br></br>
                                    <div>
                                        <div><b>{i18n.t('Comment')}: </b></div>
                                        <div>{this.props.activityObj.komentar}</div>

                                    </div>
                                </Grid> */}
                                <Grid item xs={12}>
                                    <div>
                                        <div><b>{i18n.t('Comment')}: </b></div>
                                        <div>{this.props.activityObj.komentar}</div>

                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <h2>{i18n.t('Materials')}</h2>
                                    <table className="tableCss" cellPadding="7" cellSpacing="1" border="1" width="100%">
                                        <tbody>
                                            <tr className="tableRowCss">
                                                <th>{i18n.t('Title')}</th>
                                                <th>{i18n.t('Unit')}</th>
                                                <th>{i18n.t('Spent')}</th> 
                                                <th>{i18n.t('Cultivated area')}</th>                                      
                                            </tr>
                                            {this.props.activityObj.items.map(item => {
                                                if (item.utroseno) return (
                                                    <tr>
                                                        <td>{item.naziv}</td>
                                                        <td>{item.jedinica_mere}</td>
                                                        <td>{item.utroseno}</td>
                                                        <td>{item.povrsina_ukupno}</td>
                                                        {/* <td>{item.normativ}</td>
                                                        <td>{item.dozvoljeno_odstupanje}</td>
                                                        <td>{item.normativ_ukupno}</td>
                                                        <td>{item.cena_po_jm}</td> */}
                                                    </tr>
                                                );
                                                return;
                                            })}
                                        </tbody>
                                    </table>
                                </Grid>
                                <Grid item xs={6}>
                                    <h2>{i18n.t('Yield')}</h2>
                                    <table className="tableCss" cellPadding="7" cellSpacing="1" border="1" width="100%">
                                        <tbody>
                                            <tr className="tableRowCss">
                                                <th>{i18n.t('Title')}</th>
                                                <th>{i18n.t('Unit')}</th>
                                                <th>{i18n.t('Yield')}</th>
                                            </tr>
                                            {this.props.activityObj.items.map(item => {
                                                if (item.prinos) return (
                                                    <tr>
                                                        <td>{item.naziv}</td>
                                                        <td>{item.jedinica_mere}</td>
                                                        {/* <td>{item.povrsina_ukupno}</td> */}
                                                        <td>{item.prinos}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </Grid>
                                <Grid item xs={12}>
                                </Grid>
                                <Grid item>
                                    <h2>{i18n.t('Tables')}</h2>
                                    <div>
                                        {this.props.activityObj.tables.map(item => {
                                            return (
                                                <li>
                                                    {item}
                                                </li>
                                            );
                                        })}
                                    </div>
                                </Grid>
                                <Grid item>
                                    <h2>{i18n.t('Machines')}</h2>
                                    <div>
                                        {this.props.activityObj.machines.map(item => {
                                            return (
                                                <li>
                                                    {item}
                                                </li>
                                            );
                                        })}
                                    </div>
                                </Grid>
                                <Grid item>
                                    <h2>{i18n.t('Implements')}</h2>
                                    <div>
                                        {this.props.activityObj.impls.map(item => {
                                            return (
                                                <li>
                                                    {item}
                                                </li>
                                            );
                                        })}
                                    </div>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                }
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        agroProduction: state.reportsReducer.agroProduction,
        agroProductionFetching: state.reportsReducer.agroProductionFetching,
        agroProductionPages: state.reportsReducer.agroProductionPages,
        activityObj: state.reportsReducer.activityObj,
        activityPostingSuccess: state.reportsReducer.activityPostingSuccess,
        activityPostingFailed: state.reportsReducer.activityPostingFailed,
        activityRejectingSuccess: state.reportsReducer.activityRejectingSuccess,
        activityRejectingFailed: state.reportsReducer.activityRejectingFailed,
        implementDropdown: state.implementReducer.implementDropdown,
        machineDropdown: state.machinesReducer.machineDropdown,
        states: state.administrationReducer.states,
        statesFetching: state.administrationReducer.statesFetching,
        statesFetchingFailed: state.administrationReducer.statesFetchingFailed,
        localGoverments: state.farmRegistarReducer.localGoverments,
        localGovermentsFetching: state.farmRegistarReducer.localGovermentsFetching,
        localGovermentsFetchingFailed: state.farmRegistarReducer.localGovermentsFetchingFailed,
        seasonDropDown: state.seasonReducer.seasonDropDown,
        seasonDropDownFetching: state.seasonReducer.seasonDropDownFetching,
        seasonDropDownFetchingFailed: state.seasonReducer.seasonDropDownFetchingFailed,
        woDropdown: state.workingOperationReducer.woDropdown,
        fieldDropDown: state.fieldReducer.fieldDropDown,
    }
}

function mapDispatchToProps(dispatch) {
    return {

        getAgroProduction: (state, filters) => dispatch(getAgroProduction(state, filters)),
        getLocalGov: () => dispatch(getLocalGov()),
        getImplement: () => dispatch(getImplement()),
        getMachinesDropdown: () => dispatch(getMachinesDropdown()),
        getStates: () => dispatch(getStates()),
        getTemplate: (id) => dispatch(getTemplate(id)),
        declineActivity: (potpisan, odbijen, komentar, id) => dispatch(declineActivity(potpisan, odbijen, komentar, id)),
        signActivity: (potpisan, odbijen, id) => dispatch(signActivity(potpisan, odbijen, id)),
        getSeasonDropDown: () => dispatch(getSeasonDropDown()),
        getFieldDropDown: () => dispatch(getFieldDropDown()),
        getWODropdown: () => dispatch(getWODropdown()),
        setInitialState: (component) => dispatch(setInitialState(component)),
        exportDocument: (title, state, url, format, options) => dispatch(exportDocument(title, state, url, format, options))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AgroProduction);