import React from 'react';
import { connect } from 'react-redux';
import ViewZonalOffice from '../components/administration/ViewZonalOffice';
import ViewFarmers from '../components/farmRegistar/ViewFarmers';
import LoginPage from '../components/page/LoginPage';
import ForgotPassword from '../components/page/ForgotPassword';
import ResetPassword from '../components/page/ResetPassword'
import { Route, Redirect } from 'react-router-dom';
import { toggleDrawer, setInitialState } from '../actions/LoginActions';
import styled from 'react-emotion';
import Header from '../utils/Header/Index';
import MenuDrawer from '../components/MenuDrawer';
import { ThemeProvider } from 'emotion-theming';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import theme from '../utils/theme';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { withRouter } from 'react-router-dom'
import GlobalDashboard from '../components/GlobalDashboard';
import MapContainer from '../components/Map/MapContainer';
import MaterialMainComponent from '../components/resources/materials/MaterialMainComponent';
import MachinesMainComponent from '../components/resources/machines/MachinesMainComponent';
import WorkingOperationMainComponent from '../components/resources/workingOperations/WorkingOperationMainComponent';
import CropMainComponent from '../components/resources/crop/CropMainComponent';
import ImplementMainComponent from '../components/resources/implements/ImplementMainComponent';
import ViewSeason from '../components/resources/season/ViewSeason';
import ViewLayer from '../components/catastral_data/layer/ViewLayer';
import ViewParcel from '../components/catastral_data/parcel/ViewParcel';
import ViewField from '../components/catastral_data/field/ViewField';
import ViewProgram from '../components/financial/program/ViewProgram';
import ViewIncentives from '../components/financial/incentives/ViewIncentives';
import ViewSubsidies from '../components/financial/subsidies/ViewSubsidies';
import CropRotation from '../components/reports/CropRotation';
import Note from '../components/note/Note';
import Seeding from '../components/reports/Seeding';
import Statistics from '../components/reports/Statistics';
import FarmDashboard from '../components/farmRegistar/FarmDashboard';
import SubsidiesDashboard from '../components/financial/subsidies/SubsidiesDashboard';
import ProgramDashboard from '../components/financial/program/ProgramDashboard';
import IncentivesDashboard from '../components/financial/incentives/IncentivesDashboard';
import MapDashboard from '../components/Map/MapDashboard';
import StatisticsDashboard from '../components/reports/Dashboards/StatisticsDashboard';
import SeedingDashboard from '../components/reports/Dashboards/SeedingDashboard';
import NoteDashboard from '../components/reports/Dashboards/NoteDashboard';
import CropRotationDashboard from '../components/reports/Dashboards/CropRotationDashboard';
import Hierarchy from '../components/Hierarchy';
import AgroProduction from '../components/fieldManager/AgroProduction';
import AdminMainPage from '../components/admin/AdminMainPage';
import ProtectedRoute from './ProtectedRoute';
import AgroProductionDashboard from '../components/fieldManager/AgroProductionDashboard';
import ViewFDB from '../components/fdb/ViewFDB';
import ViewPublicProcurement from '../components/auction/ViewPublicProcurement';
import ViewTicket from '../components/ticket/ViewTicket';
import TicketDashboard from '../components/ticket/TicketDashboard';
import ViewBranches from '../components/administration/ViewBranches';
import ViewExecutives from '../components/administration/ViewExecutives';
import ViewAgents from '../components/administration/ViewAgents';
import ViewFieldManagers from '../components/administration/ViewFieldManagers';
import ViewStateManagers from '../components/administration/ViewStateManagers';
import ViewUsers from '../components/administration/ViewUsers';
import ViewAnchors from '../components/administration/ViewAnchors';
import ViewSocieties from '../components/administration/ViewSocieties';
import Testing from '../components/admin/Testing';
import ViewTest from '../components/admin/ViewTest';
import BlacklistedFarmers from '../components/farmRegistar/BlacklistedFarmers';
import ViewMyFarmers from '../components/farmRegistar/ViewMyFarmers';
import ViewRegions from '../components/control_panel/regions/ViewRegions';
import ViewClients from '../components/control_panel/clients/ViewClients';
// import ViewUsersCP from '../components/control_panel/users/ViewUsers';
import ViewLayers from '../components/control_panel/layers/ViewLayers';
import ViewClientGroupModules from '../components/control_panel/client_group_module/ViewClientGroupModules';
import ViewFormModules from '../components/control_panel/form_module/ViewFormModules';
import ViewFieldsParcels from '../components/control_panel/fields/ViewFieldsParcels';
import ViewDefaultSeasons from '../components/control_panel/default_season/ViewDefaultSeasons';
import ViewInstitutionTypes from '../components/control_panel/institutions/ViewInstitutionTypes';
import AddAccounts from '../components/control_panel/account/AddAccount';
import ViewInstitutions from '../components/financial/institutions/ViewInstitutions';
import ViewSensors from '../components/control_panel/sensor/ViewSensors';
import ViewActivities from '../components/admin_portal/ViewActivities';
import AccountManagement from '../components/admin_portal/AccountManagement';
import Maps from '../components/Maps/Maps';
import SensorList from './../components/sensor/SensorList';
import SensorMap from './../components/sensor/SensorMap';
import EventTracking from './../components/admin_portal/EventTracking';
import NaledReport from './../components/admin_portal/NaledReport';
import TopList from './../components/admin_portal/TopList';
import ActivitiesBook from './../components/reports/ActivitiesBook';
import MapControlPanel from './../components/admin_portal/MapControlPanel';
import ViewYearlyReport from '../components/reports/yearly/ViewYearlyReport';
import Sombor from './../components/admin_portal/Sombor';
import ViewSort from '../components/resources/crop/ViewSort';

const Container = styled('main')`
  width: calc(100vw-10px);
`;

const muiTheme = createMuiTheme({
    palette: theme.palette,
    overrides: {
        MuiCollapse: {
            entered: {
                height: "auto",
                overflow: "visible"
            }
        },
        MuiExpansionPanel: {
            root: {
                '&$expanded': {
                    margin: 0
                }
            }
        }
    }
});


const Content = styled('section')`
  padding-top: 0px;
  padding-left: ${p => p.authenticated ? p.theme.size(5) : 0};
  transform-origin: left 2px;
  transition: width  0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: 
    ${p =>
        p.isDrawerOpen
            ? `translateX(${p.theme.size(13)})`
            : 'none'};
  width:${p =>
        p.isDrawerOpen
            ? `calc(100% - ${p.theme.size(18)})`
            : `calc(100% - ${p.theme.size(5)})`};
`;

export class Agrolife extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            isDrawerOpen: !this.props.isDrawerOpen,
            userProfile: this.props.userProfile,
            authenticated: false
        };
    }

    componentDidMount() {
        let token = localStorage.getItem('token');
        if (token) {
            this.setState({
                authenticated: true
            })
        } else {
            this.setState({
                authenticated: false
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.token && nextProps.user && nextProps.loginSuccess === true) {
            this.setState({
                authenticated: true
            })
            toast.success('Welcome' + ' ' + nextProps.user.username + '', {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
            });
            this.props.setInitialState('loginSuccess');
        }
        this.setState({
            token: nextProps.token,
            isDrawerOpen: nextProps.isDrawerOpen,
            userProfile: nextProps.userProfile
        })
    }

    tokenCheck() {
        let token = localStorage.getItem('token');
        return token;
    }

    render() {
        let content;
        const { authenticated } = this.state;
        content =
            <MuiThemeProvider theme={muiTheme}>
                <ThemeProvider theme={theme}>
                    <div>
                        <Container>
                            <Content authenticated={authenticated} isDrawerOpen={this.state.isDrawerOpen} style={{ paddingLeft: authenticated ? '91px' : '', width: authenticated ? 'calc(100% - 260px)' : '' }}>
                                <ProtectedRoute exact path="/zonal" authorized={authenticated} component={ViewZonalOffice} />
                                <ProtectedRoute exact path="/farmer" authorized={authenticated} component={ViewFarmers} />
                                <ProtectedRoute exact path="/farmer_blacklisted" authorized={authenticated} component={BlacklistedFarmers} />
                                <ProtectedRoute exact path="/my_farmers" authorized={authenticated} component={ViewMyFarmers} />
                                <ProtectedRoute exact path="/farmer_dashboard" authorized={authenticated} component={FarmDashboard} />
                                <ProtectedRoute exact path="/program_dashboard" authorized={authenticated} component={ProgramDashboard} />
                                <ProtectedRoute exact path="/subsidies_dashboard" authorized={authenticated} component={SubsidiesDashboard} />
                                <ProtectedRoute exact path="/map" authorized={authenticated} component={Maps} />
                                <ProtectedRoute exact path="/material" authorized={authenticated} component={MaterialMainComponent} />
                                <ProtectedRoute exact path="/machines" authorized={authenticated} component={MachinesMainComponent} />
                                <ProtectedRoute exact path="/crop" authorized={authenticated} component={CropMainComponent} />
                                <ProtectedRoute exact path="/sort" authorized={authenticated} component={ViewSort} />
                                <ProtectedRoute exact path="/implement" authorized={authenticated} component={ImplementMainComponent} />
                                <ProtectedRoute exact path="/working_operation" authorized={authenticated} component={WorkingOperationMainComponent} />
                                <ProtectedRoute exact path="/season" authorized={authenticated} component={ViewSeason} />
                                <ProtectedRoute exact path="/layer" authorized={authenticated} component={ViewLayer} />
                                <ProtectedRoute exact path="/parcel" authorized={authenticated} component={ViewParcel} />
                                <ProtectedRoute exact path="/field" authorized={authenticated} component={ViewField} />
                                <ProtectedRoute exact path="/program" authorized={authenticated} component={ViewProgram} />
                                <ProtectedRoute exact path="/incentive" authorized={authenticated} component={ViewIncentives} />
                                <ProtectedRoute exact path="/subsidies" authorized={authenticated} component={ViewSubsidies} />
                                <ProtectedRoute exact path="/statistics" authorized={authenticated} component={Statistics} />
                                <ProtectedRoute exact path="/crop_rotation" authorized={authenticated} component={CropRotation} />
                                <ProtectedRoute exact path="/seeding_statistics" authorized={authenticated} component={Seeding} />
                                <ProtectedRoute exact path="/notes" authorized={authenticated} component={Note} />
                                <ProtectedRoute exact path="/incentive_dashboard" authorized={authenticated} component={IncentivesDashboard} />
                                <ProtectedRoute exact path="/map_dashboard" authorized={authenticated} component={MapDashboard} />
                                <ProtectedRoute exact path="/statistics_dashboard" authorized={authenticated} component={StatisticsDashboard} />
                                <ProtectedRoute exact path="/seeding_statistics_dashboard" authorized={authenticated} component={SeedingDashboard} />
                                <ProtectedRoute exact path="/notes_dashboard" authorized={authenticated} component={NoteDashboard} />
                                <ProtectedRoute exact path="/crop_rotation_dashboard" authorized={authenticated} component={CropRotationDashboard} />
                                <ProtectedRoute exact path="/dashboard" authorized={authenticated} component={GlobalDashboard} />
                                <ProtectedRoute exact path="/hierarchy" authorized={authenticated} component={Hierarchy} />
                                <ProtectedRoute exact path="/agro_production" authorized={authenticated} component={AgroProduction} />
                                <ProtectedRoute exact path="/agro_production_dashboard" authorized={authenticated} component={AgroProductionDashboard} />
                                <ProtectedRoute exact path="/admin_panel" authorized={authenticated} component={AdminMainPage} />
                                <ProtectedRoute exact path="/fbs" authorized={authenticated} component={ViewFDB} />
                                <ProtectedRoute exact path="/auction" authorized={authenticated} component={ViewPublicProcurement} />
                                <ProtectedRoute exact path="/ticket" authorized={authenticated} component={ViewTicket} />
                                <ProtectedRoute exact path="/ticket_dashboard" authorized={authenticated} component={TicketDashboard} />
                                <ProtectedRoute exact path="/regional_offices" authorized={authenticated} component={ViewBranches} />
                                <ProtectedRoute exact path="/agents" authorized={authenticated} component={ViewAgents} />
                                <ProtectedRoute exact path="/executives" authorized={authenticated} component={ViewExecutives} />
                                <ProtectedRoute exact path="/field_managers" authorized={authenticated} component={ViewFieldManagers} />
                                <ProtectedRoute exact path="/state_managers" authorized={authenticated} component={ViewStateManagers} />
                                <ProtectedRoute exact path="/users" authorized={authenticated} component={ViewUsers} />
                                <ProtectedRoute exact path="/regions" authorized={authenticated} component={ViewRegions} />
                                <ProtectedRoute exact path="/clients" authorized={authenticated} component={ViewClients} />
                                {/* <ProtectedRoute exact path="/users_cp" authorized={authenticated} component={ViewUsersCP} /> */}
                                <ProtectedRoute exact path="/layer_cp" authorized={authenticated} component={ViewLayers} />
                                <ProtectedRoute exact path="/anchors" authorized={authenticated} component={ViewAnchors} />
                                <ProtectedRoute exact path="/cooperative_society" authorized={authenticated} component={ViewSocieties} />
                                <ProtectedRoute exact path="/testing" authorized={authenticated} component={Testing} />
                                <ProtectedRoute exact path="/ViewTest" authorized={authenticated} component={ViewTest} />
                                <ProtectedRoute exact path="/client_gm" authorized={authenticated} component={ViewClientGroupModules} />
                                <ProtectedRoute exact path="/form_module" authorized={authenticated} component={ViewFormModules} />
                                <ProtectedRoute exact path="/fieldsparcels" authorized={authenticated} component={ViewFieldsParcels} />
                                <ProtectedRoute exact path="/default_season" authorized={authenticated} component={ViewDefaultSeasons} />
                                <ProtectedRoute exact path="/institution_types" authorized={authenticated} component={ViewInstitutionTypes} />
                                <ProtectedRoute exact path="/institutions" authorized={authenticated} component={ViewInstitutions} />
                                <ProtectedRoute exact path="/accounts" authorized={authenticated} component={AddAccounts} />
                                <ProtectedRoute exact path="/sensors_cp" authorized={authenticated} component={ViewSensors} />
                                <ProtectedRoute exact path="/ap_activities" authorized={authenticated} component={ViewActivities} />
                                <ProtectedRoute exact path="/ap_account_management" authorized={authenticated} component={AccountManagement} />
                                <ProtectedRoute exact path="/sensors" authorized={authenticated} component={SensorList} />
                                <ProtectedRoute exact path="/sensor_map" authorized={authenticated} component={SensorMap} />
                                <ProtectedRoute exact path="/ap_event_tracking" authorized={authenticated} component={EventTracking} />
                                <ProtectedRoute exact path="/ap_naled_report" authorized={authenticated} component={NaledReport} />
                                <ProtectedRoute exact path="/ap_top_list" authorized={authenticated} component={TopList} />
                                <ProtectedRoute exact path="/activities_book" authorized={authenticated} component={ActivitiesBook} />
                                <ProtectedRoute exact path="/ap_map_control_panel" authorized={authenticated} component={MapControlPanel} />
                                <ProtectedRoute exact path="/yearly_report" authorized={authenticated} component={ViewYearlyReport} />
                                <ProtectedRoute exact path="/sombor" authorized={authenticated} component={Sombor} />

                                <Route exact path="/" component={LoginPage} />
                                <Route exact path="/forgotpass" component={ForgotPassword} />
                                <Route exact path="/resetpass" component={ResetPassword} />
                                {
                                    authenticated && window.location.pathname === '/' ?
                                        <Redirect from='/' to="dashboard" />
                                        : null
                                }
                            </Content>
                        </Container>
                        {authenticated &&
                            <div>
                                <MenuDrawer />
                                <Header />
                            </div>
                        }
                    </div>
                </ThemeProvider>
            </MuiThemeProvider>
        return (
            <div className="container">
                {content}
                <ToastContainer draggable={false} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        token: state.appReducer.token,
        user: state.appReducer.user,
        isDrawerOpen: state.appReducer.isDrawerOpen,
        loginSuccess: state.appReducer.loginSuccess,
        userProfile: state.appReducer.userProfile
    }
}

function mapDispatchToProps(dispatch) {
    return {
        toggleDrawer: open => dispatch(toggleDrawer(open)),
        setInitialState: (component) => dispatch(setInitialState(component)),
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Agrolife))