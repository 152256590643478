import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { getGroup } from '../../actions/resources/CropActions';
import { getSeedingReport } from '../../actions/reports/ReportsActions';
import { getSeasonDropDown } from '../../actions/resources/SeasonsActions';
import i18n from '../../i18n/i18n';
import Select from 'react-select';
import immutable from 'seamless-immutable';
import { Fab } from '@material-ui/core';
import { exportDocument } from '../../actions/ExportActions';
import Export from '../../utils/Export';
import DashboardDrawer from '../../utils/DashboardDrawer';

export class Seeding extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
        };
        this.tableScrollTop = 0;
    }

    componentDidMount() {
        if (this.props.cropGroupDropdown.length === 0) {
            this.props.getGroup();
        }
        if (this.props.seasonDropDown.length === 0) {
            this.props.getSeasonDropDown();
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            cropGroupDropdown: immutable.asMutable(nextProps.cropGroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            seasonDropDown: immutable.asMutable(nextProps.seasonDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
        })

    }

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    onExportClick = () => {
        this.setState({ export: !this.state.export });
    }

    exportFunction = () => {
        const table = this.checkboxTable;
        this.props.exportDocument(this.state.docTitle, table.state, 'api/report/seeding_statistic');
        this.setState({ export: !this.state.export });
    }

    onPieClick = () => {
        this.props.history.push('/seeding_statistics_dashboard');
    }

    onTableClick = () => {
        this.props.history.push('/seeding_statistics');
    }



    render() {
        const { clientProfile } = this.props;
        const columns = [
            {
                Header: i18n.t('Season'),
                accessor: 'sezona',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        defaultValue={this.state.seasonDropDown && this.state.seasonDropDown.find(function (element) {
                            if (clientProfile) {
                                return element.value === clientProfile.id_default_sezona;
                            }
                        })}
                        closeMenuOnSelect={false}
                        options={this.state.seasonDropDown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Crop group'),
                accessor: 'kultura',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.cropGroupDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Land area'),
                accessor: 'povrsina',
                Footer: (
                    <span>
                        <strong>{i18n.t('Total')}:</strong>{" "}
                        {this.props.seedingReport &&
                            this.props.seedingReport.reduce((acc, current) => acc += parseFloat(current.povrsina), 0)}
                    </span>
                )
            },
        ];
        const filterOptions = {
            defaultFiltered:
                [
                    {
                        id: 'sezona',
                        value: clientProfile && this.state.seasonDropDown ? [this.state.seasonDropDown.find(function (element) {
                            return element.value === clientProfile.id_default_sezona;
                        })] : []
                    }
                ]
        };

        const checkboxProps = {
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    return { style: { backgroundColor: "inherit" } };
                }
                return {};
            }
        };
        return (
            <div className="page">
                <div className="table-header">
                    <Fab
                        onClick={() => this.onSearchClick()}
                        color="primary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-search headerIcon" />
                    </Fab>
                    <Fab
                        onClick={() => this.onExportClick()}
                        color="primary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-download headerIcon" />
                    </Fab>
                </div>
                <ReactTable
                    ref={r => (this.checkboxTable = r)}
                    data={this.props.seedingReport}
                    getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                    columns={columns}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.props.seedingReportPages}
                    loading={this.props.seedingReportFetching}
                    manual
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => this.props.getSeedingReport(state, instance)}
                    className="-striped -highlight table-drawer"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...filterOptions}
                    {...checkboxProps}
                />
                <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} />
                {this.state.export && <Export
                    export={this.state.export}
                    onTextChange={({ title }) => {
                        this.setState({ docTitle: title });
                    }}
                    handleClose={this.onExportClick}
                    exportDoc={this.exportFunction} />
                }
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        seedingReport: state.reportsReducer.seedingReport,
        seedingReportFetching: state.reportsReducer.seedingReportFetching,
        seedingReportPages: state.reportsReducer.seedingReportPages,
        crops: state.cropReducer.crops,
        cropFetching: state.cropReducer.cropFetching,
        cropFetchingFailed: state.cropReducer.cropFetchingFailed,
        cropGroupDropdown: state.cropReducer.cropGroupDropdown,
        groupFetching: state.cropReducer.groupFetching,
        groupFetchingFailed: state.cropReducer.groupFetchingFailed,
        clientProfile: state.appReducer.clientProfile,
        seasonDropDown: state.seasonReducer.seasonDropDown,
        seasonDropDownFetching: state.seasonReducer.seasonDropDownFetching,
        seasonDropDownFetchingFailed: state.seasonReducer.seasonDropDownFetchingFailed,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getSeedingReport: (state, instance) => dispatch(getSeedingReport(state, instance)),
        getGroup: () => dispatch(getGroup()),
        getSeasonDropDown: () => dispatch(getSeasonDropDown()),
        exportDocument: (title, state, url) => dispatch(exportDocument(title, state, url)),


    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Seeding);