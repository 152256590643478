import React from 'react';
import i18n from '../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';
import { wentWrong } from "../../../utils/ToastConfig";
import axios from '../../../utils/AxiosWrapper';
import { CSVReader } from 'react-papaparse'
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import generator from 'generate-password';

export class ImportClients extends React.Component {
    constructor(props) {
        super();
        this.state = {
            seasons: [], season: null,
            regions: [], region: null,
            languages: [], language: null,
            currencies: [], currency: null,
            workspaces: [], workspace: null,
            clientGroup: null, clientGroups: [],
            userGroup: null, userGroups: [],
            opstine: [], opstina: null, opstina_naziv: null,
            kat_opstine: [], kat_opstina: null, kat_opstina_naziv: null,
            clients: [], clientPosting: false,
            problems: [],
            clientList: []
        }
    }

    componentDidMount() {
        this.getByURL('region/filter_region', 'regions')
        this.getByURL('klijent_korisnik/language', 'languages');
        this.getByURL('klijent_korisnik/currency', 'currencies');
        this.getByURL('klijent_korisnik/workspace', 'workspaces');
        this.getByURL('region/filter_klijent_grupa', 'clientGroups');
        this.getByURL('region/filter_korisnik_grupa', 'userGroups');
    }

    getByURL(url, obj, value, name) {
        value = value || '';
        name = name || '';
        if (value != null) {
            axios.get(`api/${url}`, { params: { [name]: value } })
                .then(res => this.setState({ [obj]: res.data.data }))
                .catch(error => wentWrong('Something went wrong, please try again!'));
        }
    }

    addClients(clients) {
        if (
            this.state.region &&
            this.state.season &&
            this.state.language &&
            this.state.currency &&
            this.state.workspace &&
            this.state.opstina &&
            this.state.clients
        ) {
            const client = clients.shift();
            if (client) {
                axios.post('api/klijent_korisnik/create', client)
                    .then(res => {
                        this.addClients(clients);
                    })
                    .catch(error => {
                        this.addClients(clients);
                        this.setState({ problems: [...this.state.problems, client] });
                    });
            }
        }
        else wentWrong('Please enter all required fields!');
    }

    handleOnDrop = (data) => {
        this.setState({ clients: [] });
        let client = []; let clients = [];
        let clientList = []; let clientAccount = [];

        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < 4; j++) {
                if (data[i].data[j] == "" || data[i].data[j] == undefined) {
                    data[i].data[j] = null;
                }
            }
            if (data[i].data[0] != null) {

                const password = generator.generate({
                    length: 8,
                    numbers: true,
                    exclude: 'YyZzWwXxQq',
                    excludeSimilarCharacters: true,
                    lowercase: true,
                    uppercase: false
                });

                client = {
                    // CSV
                    naziv: data[i].data[0],
                    kontakt_mejl: data[i].data[0],
                    username: data[i].data[0],
                    bpg: data[i].data[1],
                    kontakt_osoba: data[i].data[2],
                    ulica_i_broj: data[i].data[3],
                    kontakt_telefon: data[i].data[4],
                    kontakt_mobilni: data[i].data[4],
                    telefon: data[i].data[4],
                    mobilni: data[i].data[4],
                    ime: data[i].data[5],
                    prezime: data[i].data[6],

                    // DROPDOWN
                    id_region: Number(this.state.region),
                    id_default_sezona: Number(this.state.season),
                    geoserver_workspace: this.state.workspace,
                    id_opstina: Number(this.state.opstina),
                    id_katastarska_opstina: Number(this.state.kat_opstina),
                    id_jezik: Number(this.state.language),
                    mesto: this.state.opstina_naziv,
                    id_valuta: Number(this.state.currency),
                    id_klijent_grupa: Number(this.state.clientGroup),
                    id_korisnik_grupa: Number(this.state.userGroup),

                    // GENERATED
                    password: password,
                    aktivan: true,
                };
                clientAccount = {
                    username: data[i].data[0],
                    password: password
                }
            }
            clientList.push(clientAccount);
            clients.push(client);
            this.setState({ clients, clientList });
        }
        console.log(this.state.clientList);
    }

    render() {
        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.props.open}
                    onClose={this.props.onClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('Import clients and users')}
                        <IconButton onClick={this.props.onClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <form id="add-client">
                            <Grid container justify="space-between" alignContent="space-between" style={{ marginLeft: '10px' }}>

                                <Grid item xs={6}>
                                    <TextField required
                                        label={i18n.t('Regions')}
                                        select
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginBottom: '10px' }}
                                        onChange={(e) => {
                                            this.setState({ region: e.target.value });
                                            this.getByURL('klijent_korisnik/season', 'seasons', e.target.value, 'id_region');
                                            this.getByURL('field/filter_opstina_uslov', 'opstine', e.target.value, 'id_region');
                                        }} >
                                        <option value='' />
                                        {this.state.regions.map((region) => <option key={region.id} value={region.id}>{region.naziv}</option>)}
                                    </TextField>
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField required
                                        label={i18n.t('Language')}
                                        select
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginBottom: '10px' }}
                                        onChange={(e) => this.setState({ language: e.target.value })} >
                                        <option value='' />
                                        {this.state.languages.map((language) => <option key={language.id} value={language.id}>{language.naziv}</option>)}
                                    </TextField>
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField required
                                        label={i18n.t('Currency')}
                                        select
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginBottom: '10px' }}
                                        onChange={(e) => this.setState({ currency: e.target.value })} >
                                        <option value='' />
                                        {this.state.currencies.map((currency) => <option key={currency.id} value={currency.id}>{currency.naziv}</option>)}
                                    </TextField>
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField required
                                        label={i18n.t('Seasons')}
                                        disabled={!this.state.region}
                                        select
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginBottom: '10px' }}
                                        onChange={(e) => this.setState({ season: e.target.value })} >
                                        <option value='' />
                                        {this.state.seasons.map((season) => <option key={season.id} value={season.id}>{season.naziv}</option>)}
                                    </TextField>
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        label={i18n.t('Opstina')}
                                        disabled={!this.state.region}
                                        select
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginBottom: '10px' }}
                                        onChange={(e) => {
                                            this.setState({ opstina: e.target.value });
                                            for (let i = 0; i < this.state.opstine.length; i++) {
                                                if (this.state.opstine[i].id == Number(e.target.value)) {
                                                    this.setState({ opstina_naziv: this.state.opstine[i].naziv });
                                                }
                                            }
                                            this.getByURL('klijent_korisnik/kat_opstina_dropdown', 'kat_opstine', e.target.value, 'id_opstina');
                                        }}
                                    >
                                        <option value='' />
                                        {this.state.opstine.map((opstina) => <option key={opstina.id} value={opstina.id}>{opstina.naziv}</option>)}
                                    </TextField>
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        label={i18n.t('Katastarska opstina')}
                                        disabled={!this.state.opstina}
                                        select
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginBottom: '10px' }}
                                        onChange={(e) => { this.setState({ kat_opstina: e.target.value }); }}
                                    >
                                        <option value='' />
                                        {this.state.kat_opstine.map((kat_opstina) => <option key={kat_opstina.id} value={kat_opstina.id}>{kat_opstina.naziv}</option>)}
                                    </TextField>
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField required
                                        label={i18n.t('Client Group')}
                                        select
                                        value={this.state.clientGroup}
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginBottom: '10px' }}
                                        onChange={(e) => { this.setState({ clientGroup: e.target.value }); }} >
                                        <option value='' />
                                        {this.state.clientGroups.map((clientGroups) => <option key={clientGroups.id} value={clientGroups.id}>{clientGroups.naziv}</option>)}
                                    </TextField>
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField required
                                        label={i18n.t('User Group')}
                                        select
                                        value={this.state.userGroup}
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginBottom: '10px' }}
                                        onChange={(e) => { this.setState({ userGroup: e.target.value }); }} >
                                        <option value='' />
                                        {this.state.userGroups.map((userGroups) => <option key={userGroups.id} value={userGroups.id}>{userGroups.naziv}</option>)}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField required
                                        label={i18n.t('Geoserver Workspace')}
                                        select
                                        SelectProps={{ native: true, }}
                                        style={{ width: '532px', marginBottom: '25px' }}
                                        onChange={(e) => this.setState({ workspace: e.target.value })} >
                                        <option value='' />
                                        {this.state.workspaces.map((workspace) => <option key={workspace.id} value={workspace.id}>{workspace.geoserver_workspace}</option>)}
                                    </TextField>
                                </Grid>

                                <Typography>Problems:</Typography>
                                <TextareaAutosize style={{ width: '527px', height: '120px', marginBottom: '0px' }}
                                    rowsMin={10}
                                    aria-label="maximum height"
                                    value={JSON.stringify(this.state.problems)}
                                />
                                {
                                    this.state.region &&
                                    this.state.season &&
                                    this.state.language &&
                                    this.state.currency &&
                                    this.state.workspace &&
                                    this.state.opstina &&

                                    <CSVReader onDrop={this.handleOnDrop}
                                        onError={this.handleOnError}
                                        addRemoveButton
                                        onRemoveFile={this.handleOnRemoveFile}>
                                        <span>Drop CSV file here or click to upload.</span>
                                    </CSVReader>
                                }

                                <Grid>
                                    <Button style={{ alignSelf: 'center', backgroundColor: '#58B4E5', color: 'white', marginTop: '20px', marginBottom: '10px' }}
                                        onClick={() => this.addClients(this.state.clients)}
                                        disabled={this.state.clientPosting} >
                                        {i18n.t('Add')}
                                    </Button>
                                </Grid>

                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
};
