/**
 * Created by pajicv on 7/13/18.
 */

import React, { Component } from 'react';

import L from 'leaflet';

import { LayersControl, Popup, FeatureGroup, Marker, Tooltip } from 'react-leaflet';

import { createGrid } from '../utils/MapUtils';

import { getTodayDate, getDaysBetween } from '../utils/constants';

import axios from '../utils/AxiosWrapper';

import { connect } from 'react-redux';

const { Overlay } = LayersControl;

class Forecast10Days extends Component {

    constructor(props) {
        super(props);

        this.state = {
            forecasts: []
        }
    }

    componentDidMount() {

        const { mapBounds, forecast10DaysLayer } =  this.props;

        const day = getDaysBetween(getTodayDate(), forecast10DaysLayer.date);

        this.getForecast(mapBounds, day);

    }

    componentWillReceiveProps(nextProps) {

        const { mapBounds, forecast10DaysLayer } = this.props;

        if( nextProps.mapBounds[0] === mapBounds[0] &&
            nextProps.mapBounds[1] === mapBounds[1] &&
            nextProps.mapBounds[2] === mapBounds[2] &&
            nextProps.mapBounds[3] === mapBounds[3] &&
            nextProps.forecast10DaysLayer.visible === forecast10DaysLayer.visible &&
            nextProps.forecast10DaysLayer.date === forecast10DaysLayer.date) {

            return;

        }

        const day = getDaysBetween(getTodayDate(), nextProps.forecast10DaysLayer.date);

        this.getForecast(nextProps.mapBounds, day);

    }

    getForecast = (mapBounds, day) => {

        const { forecast10DaysLayer } = this.props;

        if(!forecast10DaysLayer.visible) {
            return;
        }

        const grid = createGrid(mapBounds, 6, 3);

        const self = this;

        axios.post(`api/forecast/10days`, {
            locations: grid,
            day: day
        })
            .then(function (response) {

                self.setState({forecasts: response.data.data});

            })
            .catch(function (error) {
                console.log(error);

                self.setState({forecasts: []});
            });

    };

    render() {

        const { removeLayer, removeLayerControl, addOverlay  } = this.props;

        const markers = this.state.forecasts.map(marker =>
            <Marker position={[marker.latitude, marker.longitude]}
                                                                   icon={L.icon({
                                                                        iconUrl: `https://insights-for-weather-demo.mybluemix.net/images/weathericons/icon${marker.icon_code_day || marker.icon_code_night}.png`,
                                                                        iconSize: [64, 64]
                                                                   })}>
                <Popup minWidth={400} minHeight={200} width={400} height={200} autoPan={false}>
                    <div style={{height: 200, width: 400, overflow: 'scroll', fontSize:'small'}}>
                    <div align='center'>
                    <div><h3>{marker.dow}</h3></div>
                        <div align="center" style={{width: '50%', float: 'left'}}>
                            <div><b>DAY</b></div>
                            <div><img width="64px" src={`https://insights-for-weather-demo.mybluemix.net/images/weathericons/icon${marker.icon_code_day}.png`} alt="" /></div>
                            <div><p>{marker.max_temp} &#176;C</p></div>
                            <div><p>{marker.phrase_day}</p></div>
                            <div><p>Humidity: {marker.rh_day} %</p></div>
                            <div><p>Wind: {marker.wind_speed_day}m/s {marker.wind_direction_day}</p></div>
                        </div>
                        <div align="center" style={{width: '50%', float: 'left'}}>
                            <div><b>NIGHT</b></div>
                            <div><img width="64px" src={`https://insights-for-weather-demo.mybluemix.net/images/weathericons/icon${marker.icon_code_night}.png`} alt="" /></div>
                            <div><p>{marker.min_temp} &#176;C</p></div>
                            <div><p>{marker.phrase_night}</p></div>
                            <div><p>Humidity: {marker.rh_night} %</p></div>
                            <div><p>Wind: {marker.wind_speed_day}m/s {marker.wind_direction_night}</p></div>
                        </div>
                    </div>
                    </div>
                </Popup>
                <Tooltip  className="institutional-layer-label-tooltip" direction="bottom" permanent>
                    <p className="institutional-layer-label-tooltip-text">{marker.max_temp} &#176;C | {marker.min_temp} &#176;C</p>
                </Tooltip>
            </Marker>);
        return (
            <Overlay checked={this.props.forecast10DaysLayer.visible}
                     name="Weather forecast"
                     addOverlay={addOverlay}
                     removeLayer={removeLayer}
                     removeLayerControl={removeLayerControl}>
                <FeatureGroup visible={this.props.forecast10DaysLayer.visible}>
                    {markers}
                </FeatureGroup>
            </Overlay>
        )

    }
}

function mapStateToProps(state) {
    return {
        forecast10DaysLayer: state.layersReducer.layers.byId.forecast10days
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Forecast10Days);