import React from 'react';
import { Card, withStyles, } from '@material-ui/core';
import axios from '../../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../InfoStyles';

export class InfoSnow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.language === 1 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Sneg čini značajan deo godišnjih padavina i samim tim obezbeđuju vlažnost zemljišta. 
                            Zimske snežne padavine pomažu tokom vegetacije zbog uskladištene vlage koja se spušta u zemljište kako se sneg topi. 
                            Sloj snega na ratarskim ozimim kulturama, kao što su npr. ozime strnine i uljana replica, služi kao izolator i sprečava izmrzavanje biljaka. 
                            Sneg takođe doprinosi plodnosti zemljišta i oduvek se smatrao “đubrivom za siromašne”. 
                            Snežne pahulje sadrže rastvoren organski azot, koji je neophodan biljakama za vegetativni porast. 
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                               No text
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 4 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Snijeg čini značajan dio godišnjih oborina i time osigurava vlažnost tla. Zimske snježne oborine pomažu tijekom vegetacijske sezone zbog pohranjene vlage koja se spušta u tlo dok se snijeg topi.
                            Sloj snijega na poljoprivrednim ozimim usjevima, kao što su npr. pšenica i uljana repica služe kao izolator i sprječavaju smrzavanje biljaka.
                            Snijeg pridonosi i plodnosti tla te se oduvijek smatrao "gnojivom za siromašne". Snježne pahulje sadrže otopljeni organski dušik koji je biljkama potreban za vegetativni rast.
                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    };
};

export default withStyles(styles)(InfoSnow);