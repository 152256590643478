import immutable from 'seamless-immutable';
import { Type as UserType } from '../../actions/control_panel/UserActions';

export const INITIAL_STATE = immutable({
    userPosting: false,
    userPostingSuccess: false,
    userPostingFailed: false,

    user: [],
    userObj: {},
    userFetching: false,
    userPages: 1,

    regionType: [],
    regionTypeFetching: false,
    regionTypeFetchingFailed: false,

    roleType: [],
    roleTypeFetching: false,
    roleTypeFetchingFailed: false,

    deleted: false,
    deleteProgramFailed: false,
});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case UserType.SET_INITIAL_STATE: {
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;
        }

        case UserType.ADD_USER_CALL: {
            const userPosting = true;
            return state.merge({ userPosting });
            break;
        }

        case UserType.ADD_USER_SUCCESS: {
            const userPosting = false;
            const userPostingSuccess = true;
            return state.merge({ userPostingSuccess, userPosting, userObj: INITIAL_STATE.userObj })
            break;
        }

        case UserType.ADD_USER_FAILED: {
            const userPosting = false;
            const userPostingFailed = true;
            return state.merge({ userPosting, userPostingFailed });
            break;
        }

        case UserType.GET_USER_READ_CALL: {
            const userFetching = true;
            return state.merge({ userFetching });
            break;
        }

        case UserType.GET_USER_READ_SUCCESS: {
            const user = action.data.data;
            const userFetching = false;
            const userPages = action.data.total_pages
            return state.merge({ user, userFetching, userPages });
            break;
        }

        case UserType.GET_USER_READ_FAILED: {
            const userFetchingFailed = true;
            const userFetching = false;
            return state.merge({ userFetching, userFetchingFailed });
            break;
        }

        case UserType.GET_REGION_TYPE_CALL: {
            const regionTypeFetching = true;
            return state.merge({ regionTypeFetching });
            break;
        }

        case UserType.GET_REGION_TYPE_SUCCESS: {
            const regionType = action.data.data;
            const regionTypeFetching = false;
            return state.merge({ regionType, regionTypeFetching });
            break;
        }

        case UserType.GET_REGION_TYPE_FAILED: {
            const regionTypeFetchingFailed = true;
            const regionTypeFetching = false;
            return state.merge({ regionTypeFetching, regionTypeFetchingFailed });
            break;
        }

        case UserType.GET_ROLE_TYPE_CALL: {
            const roleTypeFetching = true;
            return state.merge({ roleTypeFetching });
            break;
        }

        case UserType.GET_ROLE_TYPE_SUCCESS: {
            const roleType = action.data.data;
            const roleTypeFetching = false;
            return state.merge({ roleType, roleTypeFetching });
            break;
        }

        case UserType.GET_ROLE_TYPE_FAILED: {
            const roleTypeFetchingFailed = true;
            const roleTypeFetching = false;
            return state.merge({ roleTypeFetching, roleTypeFetchingFailed });
            break;
        }

        case UserType.DELETE_USER_SUCCESS: {
            const array = state['user'].asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ user: array, deleted: deleted })
            break;
        }

        case UserType.DELETE_USER_FAILED: {
            const deleteUserFailed = true;
            return state.merge({ deleteUserFailed });
            break;
        }

        case UserType.SET_USER_ROW_IN_REDUCER: {
            const data = action.rowInfo;
            return state.merge({ userObj: data })
            break;
        }

        default:
            return state;
    }
}