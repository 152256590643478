import React from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import { Bar, HorizontalBar } from 'react-chartjs-2';
import randomColor from 'randomcolor';
import immutable from 'seamless-immutable';
import { getRegistrationCount } from '../../actions/AdministrationActions';
import 'react-toastify/dist/ReactToastify.css';
import i18n from '../../i18n/i18n';
import { getUser } from '../../utils/CommonFunctions';

export class RegistrationCount extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            id_opstina: [],
            id_katastarska_opstina: [],
            id_vrsta_programa: [],
            id_aplikacija_status: [],
            id_zona: []
        };
    }

    async componentDidMount() {
        const { getRegistrationCount } = this.props;
        let user = await getUser();
        getRegistrationCount();
        this.setState({ user })
    }

    render() {
        const { user } = this.state;
        const { registrationItems } = this.props;
        let monthsData = this.months;
        let byDateDates = this.days;
        if (registrationItems && registrationItems.dates) {
            monthsData.labels = registrationItems.dates.labels.asMutable();
            monthsData.datasets[0].data = registrationItems.dates.count.asMutable();
        }

        if (registrationItems && registrationItems.groupedDates) {
            byDateDates.labels = registrationItems.groupedDates.labels.asMutable();
            byDateDates.datasets[0].data = registrationItems.groupedDates.count.asMutable();
        }

        return (
            <div style={{ 'padding': '20px' }}>
                <Card style={styles.card}>
                    <h1>{i18n.t('Total number of registrations on mobile app')} : {registrationItems.total} </h1>
                </Card>
                <div>
                    <Card className="dashboard-cards-full" raised={true}>
                        <Bar
                            data={monthsData}
                            height={100}
                        />
                    </Card>

                    <Card className="dashboard-cards-full" raised={true}>
                        <HorizontalBar
                            data={byDateDates}
                            height={1500}
                        />
                    </Card>
                </div>
            </div >
        )
    }
    monthsColor = randomColor()
    daysColor = randomColor();

    months = {
        labels: [],
        datasets: [{
            data: [],
            label: i18n.t('Registrations by month'),
            backgroundColor: this.monthsColor,
            borderColor: this.monthsColor,
            borderWidth: 1,
            hoverBackgroundColor: this.monthsColor,
            hoverBorderColor: this.monthsColor,
        }],
    };

    days = {
        labels: [],
        datasets: [{
            data: [],
            label: i18n.t('Registrations by days'),
            backgroundColor: this.daysColor,
            borderColor: this.daysColor,
            borderWidth: 1,
            hoverBackgroundColor: this.daysColor,
            hoverBorderColor: this.daysColor,
        }],
    };

    options = {
        legend: {
            position: 'bottom',
            labels: {
                boxWidth: 10,
                fontSize: 12
            }
        },
        tooltips: {
            mode: 'label',
            callbacks: {

                afterLabel: function (tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex].data1[tooltipItem.index] + '%';
                },

            },
        }
    };
};

function mapStateToProps(state) {
    return {
        registrationItems: state.administrationReducer.registrationItems,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getRegistrationCount: () => dispatch(getRegistrationCount()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegistrationCount);

const styles = {
    card: {
        padding: '10px',
        margin: '10px',
    },
    button: {
        height: 40
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
};