import React from 'react';
import { Card, withStyles, } from '@material-ui/core';
import axios from '../../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../InfoStyles';

export class InfoDataGeneral extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.language === 1 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            AgroLIFE omogućava istorijski pregled vremenskih podataka koje je stanica zabeležila od momenta postavljanja na polju. 
                            Farmer bira vremenski okvir za koji želi da pogleda podatke i isti su mu prikazani na grafikonima i u tabeli.
                            Istorijski pregled podataka veoma je značajan za poljoprivrednu proizvodnju, jer omogućava analiziranje izmerenih vrednosti i praćenje njihovog trenda kretanja, 
                            što proizvođačima pruža pomoć u planiranju setvene strukture, zaštiti i ishrani bilja i ostalim tehnološkim operacijama koje se obavljaju na poljima.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                No text
                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    };
};

export default withStyles(styles)(InfoDataGeneral);
