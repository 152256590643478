import axios from '../../utils/AxiosWrapper';
export const Type = {
    //VIEW
    GET_VIEW_LAYERS_CALL: 'GET_VIEW_LAYERS_CALL',
    GET_VIEW_LAYERS_SUCCESS: 'GET_VIEW_LAYERS_SUCCESS',
    GET_VIEW_LAYERS_FAILED: 'GET_VIEW_LAYERS_FAILED',
    //ADD
    ADD_LAYERS_CALL: 'ADD_LAYERS_CALL',
    ADD_LAYERS_SUCCESS: 'ADD_LAYERS_SUCCESS',
    ADD_LAYERS_FAILED: 'ADD_LAYERS_FAILED',
    //DELETE
    DELETE_LAYER_CALL: 'DELETE_LAYER_CALL',
    DELETE_LAYER_SUCCESS: 'DELETE_LAYER_SUCCESS',
    DELETE_LAYER_FAILED: 'DELETE_LAYER_FAILED',
    //DROPDOWN
    GET_LAYERS_CALL_DROP_DOWN: 'GET_LAYERS_CALL_DROP_DOWN',
    GET_LAYERS_SUCCESS_DROP_DOWN: 'GET_LAYERS_SUCCESS_DROP_DOWN',
    GET_LAYERS_FAILED_DROP_DOWN: 'GET_LAYERS_FAILED_DROP_DOWN',

    SET_INITIAL_STATE: 'SET_INITIAL_STATE',
    SET_LAYER_ROW_IN_REDUCER: 'SET_LAYER_ROW_IN_REDUCER',
};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function setRowInReducer(rowInfo) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_LAYER_ROW_IN_REDUCER,
            rowInfo: rowInfo,
        })
    }
}

export function addLayer(layerObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_LAYERS_CALL
        });

        axios.post('api/layer/create', 
            layerObj
        )
            .then(function (response) {
                dispatch({
                    type: Type.ADD_LAYERS_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_LAYERS_FAILED
                });
            });
    }
}

export function getLayer(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_VIEW_LAYERS_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/layer/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_VIEW_LAYERS_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_VIEW_LAYERS_FAILED
                });
            });
    }
}

export function getLayerDropDown() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_LAYERS_CALL_DROP_DOWN
        });

        axios.get('api/layer/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_LAYERS_SUCCESS_DROP_DOWN,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_LAYERS_FAILED_DROP_DOWN
                });
            });
    }
}

export function deleteLayer(selection, name) {
    return (dispatch) => {

        dispatch({
            type: Type.DELETE_LAYER_CALL,
        });

        axios.post(`api/${name}/delete`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_LAYER_SUCCESS,
                    data: response.data,
                    name: name,
                    forDelete: selection
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_LAYER_FAILED
                });
            });
    }
}
