import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import {
    TextField, Button, Grid, IconButton,
    Dialog, DialogContent, DialogTitle
} from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    addBranchOffice, getStates,
    setInitialState, getBranches
} from '../../actions/AdministrationActions';
import { getLocalGov } from '../../actions/FarmRegistarActions';
import { getUser } from '../../utils/CommonFunctions';


export class AddBranch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            branchObj: {}
        };
    }

    async componentDidMount() {
        const { getStates, getLocalGov, getBranches } = this.props;
        getStates();
        getBranches();
        let user = await getUser();
        if (user) {
            getLocalGov(user.id_opstina);
            const { branchObj } = this.state;
            let obj = Object.assign({}, branchObj);
            obj.id_opstina = user.id_opstina;
            this.setState({
                branchObj: obj
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.branchPostingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('branchPostingFailed');
        }
    }

    handleChangeValue = name => event => {
        const { branchObj } = this.state;
        let obj = Object.assign({}, branchObj);
        obj[name] = event.target.value;
        this.setState({ branchObj: obj });
    };

    addBranchOffice() {
        const { branchObj } = this.state;
        const { addBranchOffice } = this.props;
        if (branchObj.kontakt_mejl &&
            branchObj.id_katastarska_opstina &&
            branchObj.id_opstina &&
            branchObj.ime &&
            branchObj.prezime &&
            branchObj.username &&
            branchObj.password &&
            branchObj.id_branch) {
            addBranchOffice(
                branchObj
            )

        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        }
    }


    render() {
        const { localGoverments, localGovermentsFetching, states, statesFetching, branches, branchesFetching, add, handleClose } = this.props;
        const { branchObj } = this.state;
        let localGovermentsArray = [];
        if (localGoverments && !localGovermentsFetching) {
            localGovermentsArray = localGoverments.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let statesArray = [];
        if (states && !statesFetching) {
            statesArray = states.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let branchesArray = [];
        if (branches && !branchesFetching) {
            branchesArray = branches.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        return (
            <div style={{ padding: 20 }}>
                <Dialog
                    open={add}
                    onClose={handleClose}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('Add branch officer')}
                        <IconButton onClick={handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-zonal">
                            <Grid container justify="space-between" alignContent="space-between" spacing={16} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                                <TextField
                                    label={i18n.t('First name')}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={branchObj.ime}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('ime')}
                                />
                                <TextField
                                    label={i18n.t('Last name')}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={branchObj.prezime}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('prezime')}
                                />
                                <TextField
                                    label={i18n.t('Username')}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={branchObj.username}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('username')}
                                />
                                <TextField
                                    label={i18n.t('Password')}
                                    type="password"
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('password')}
                                />
                                <TextField
                                    select
                                    label={i18n.t('State')}
                                    value={branchObj.id_opstina}
                                    required
                                    disabled
                                    onChange={this.handleChangeValue('id_opstina')}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}

                                >

                                    <option value='' />
                                    {statesArray}
                                </TextField>

                                <TextField
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label={i18n.t('Local government')}
                                    value={branchObj.id_katastarska_opstina}
                                    required
                                    onChange={this.handleChangeValue('id_katastarska_opstina')}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    margin="normal"
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}

                                >

                                    <option value='' />
                                    {localGovermentsArray}
                                </TextField>
                                <TextField
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label={i18n.t('Branch')}
                                    value={branchObj.id_branch}
                                    required
                                    onChange={this.handleChangeValue('id_branch')}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    margin="normal"
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                >
                                    <option value='' />
                                    {branchesArray}
                                </TextField>

                                <TextField
                                    label={i18n.t('Address')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={branchObj.ulica_i_broj}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('ulica_i_broj')}
                                />

                                <TextField
                                    label={i18n.t('Zip code')}
                                    value={branchObj.postanski_broj}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('postanski_broj')}
                                />

                                <TextField
                                    label={i18n.t('Email')}
                                    value={branchObj.kontakt_mejl}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('kontakt_mejl')}
                                />

                                <TextField
                                    label={i18n.t('Phone')}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={branchObj.kontakt_telefon}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    onChange={this.handleChangeValue('kontakt_telefon')}
                                />
                                <Grid item xs>
                                    <Button
                                        style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white' }}
                                        onClick={() => this.addBranchOffice()}
                                    >
                                        {i18n.t('Add')}
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        states: state.administrationReducer.states,
        statesFetching: state.administrationReducer.statesFetching,
        branches: state.administrationReducer.branches,
        branchesFetching: state.administrationReducer.branchesFetching,
        branchObj: state.administrationReducer.branchObj,
        statesFetchingFailed: state.administrationReducer.statesFetchingFailed,
        branchPostingFailed: state.administrationReducer.branchPostingFailed,
        localGoverments: state.farmRegistarReducer.localGoverments,
        localGovermentsFetching: state.farmRegistarReducer.localGovermentsFetching,
        localGovermentsFetchingFailed: state.farmRegistarReducer.localGovermentsFetchingFailed,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getStates: () => dispatch(getStates()),
        getBranches: () => dispatch(getBranches()),
        getLocalGov: (id_state) => dispatch(getLocalGov(id_state)),
        setInitialState: (component) => dispatch(setInitialState(component)),
        addBranchOffice: (first_name, last_name, username, password, id_local_government, id_state, address, zip_code, email, phone) => dispatch(addBranchOffice(first_name, last_name, username, password, id_local_government, id_state, address, zip_code, email, phone)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddBranch)