import React from 'react';
import { connect } from 'react-redux';

// import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({

});
export class StageCrop extends React.Component {
    render() {
        return (
            <div style={{ maxWidth: '100%' }}>
               yyy
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(StageCrop));