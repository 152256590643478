import axios from '../../utils/AxiosWrapper';
export const Type = {

    //ADD
    ADD_USER_CALL: 'ADD_USER_CALL',
    ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
    ADD_USER_FAILED: 'ADD_USER_FAILED',

    //GET
    GET_USER_READ_CALL: 'GET_USER_READ_CALL',
    GET_USER_READ_SUCCESS: 'GET_USER_READ_SUCCESS',
    GET_USER_READ_FAILED: 'GET_USER_READ_FAILED',

    //DELETE
    DELETE_USER_CALL: 'DELETE_USER_CALL',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_FAILED: 'DELETE_USER_FAILED',

    //REGION
    GET_REGION_TYPE_CALL: 'GET_REGION_TYPE_CALL',
    GET_REGION_TYPE_SUCCESS: 'GET_REGION_TYPE_SUCCESS',
    GET_REGION_TYPE_FAILED: 'GET_REGION_TYPE_FAILED',

    //ROLE
    GET_ROLE_TYPE_CALL: 'GET_ROLE_TYPE_CALL',
    GET_ROLE_TYPE_SUCCESS: 'GET_ROLE_TYPE_SUCCESS',
    GET_ROLE_TYPE_FAILED: 'GET_ROLE_TYPE_FAILED',

    //SET
    SET_USER_ROW_IN_REDUCER: 'SET_USER_ROW_IN_REDUCER',
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',
};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}


export function getRegionType() {
    return (dispatch) => {
        dispatch({
            type: Type.GET_REGION_TYPE_CALL
        });

        axios.get('api/region/filter_region')
            .then(function (response) {
                dispatch({
                    type: Type.GET_REGION_TYPE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_REGION_TYPE_FAILED
                });
            });
    }
}

export function getRoleType() {
    return (dispatch) => {
        dispatch({
            type: Type.GET_ROLE_TYPE_CALL
        });

        axios.get('api/korisnik/role_type')
            .then(function (response) {
                dispatch({
                    type: Type.GET_ROLE_TYPE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_ROLE_TYPE_FAILED
                });
            });
    }
}

export function getUsers(state, instance) {
    return (dispatch) => {
        dispatch({
            type: Type.GET_USER_READ_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/korisnik/read_control_panel', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_USER_READ_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_USER_READ_FAILED
                });
            });
    }
}

export function deleteUser(selection) {
    return (dispatch) => {
        dispatch({
            type: Type.DELETE_USER_CALL,
        });

        axios.post(`api/korisnik/delete_control_panel`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_USER_SUCCESS,
                    data: response.data,
                    forDelete: selection
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_USER_FAILED
                });
            });
    }
}

export function addUser(userObj) {
    return (dispatch) => {
        dispatch({
            type: Type.ADD_USER_CALL
        });

        axios.post('api/korisnik/create_control_panel', userObj)
            .then(function (response) {
                dispatch({
                    type: Type.ADD_USER_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_USER_FAILED
                });
            });
    }
}


export function setRowInReducer(rowInfo) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_USER_ROW_IN_REDUCER,
            rowInfo: rowInfo
        })
    }
}