import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setInitialState, getRegionType, getClientGroupType, getModuleType, addClientGM } from '../../../actions/control_panel/ClientGroupModuleActions';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

export class AddClientGroupModules extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clientGMObj: this.props.clientGMObj,
        }
    }

    componentDidMount() {
        if (this.props.regionType.length === 0) {
            this.props.getRegionType();
        }
        if (this.props.clientGroupType.length === 0) {
            this.props.getClientGroupType();
        }
        if (this.props.moduleType.length === 0) {
            this.props.getModuleType();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.clientGMPostingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('clientGMPostingFailed');
        }
    }

    addClientGM() {
        if (this.state.clientGMObj.id_modul &&
            this.state.clientGMObj.id_klijent_grupa) {
            this.props.addClientGM({
                id_klijent_grupa: this.state.clientGMObj.id_klijent_grupa,
                id_modul: this.state.clientGMObj.id_modul,
                id_region: this.state.clientGMObj.id_region,
                aktivan: this.state.clientGMObj.aktivan,
            })
        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    handleChangeValue = name => event => {
        let obj = Object.assign({}, this.state.clientGMObj);
        obj[name] = event.target.value;
        this.setState({ clientGMObj: obj });
    };

    handleSwitch = name => event => {
        let obj = Object.assign({}, this.state.clientGMObj);
        obj[name] = !this.state.clientGMObj.aktivan;
        this.setState({ clientGMObj: obj });
    };

    render() {
        let regionType = [];
        if (this.props.regionType && !this.props.regionTypeFetching) {
            regionType = this.props.regionType.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let clientGroupType = [];
        if (this.props.clientGroupType && !this.props.clientGroupTypeFetching) {
            clientGroupType = this.props.clientGroupType.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let moduleType = [];
        if (this.props.moduleType && !this.props.moduleTypeFetching) {
            moduleType = this.props.moduleType.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }

        return (
            <div style={{ padding: 20 }}>

                <Dialog open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit client') : i18n.t('Add client')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-clientGM">
                            <Grid container justify="space-between" alignContent="space-between" >
                                <Grid item>
                                    <TextField required
                                        label={i18n.t('Type of client group')}
                                        SelectProps={{ native: true, }}
                                        select
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        defaultValue={this.state.clientGMObj.id_klijent_grupa}
                                        onChange={this.handleChangeValue('id_klijent_grupa')} >
                                        <option value='' />
                                        {clientGroupType}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField required
                                        label={i18n.t('Type of module')}
                                        select
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        defaultValue={this.state.clientGMObj.id_modul}
                                        onChange={this.handleChangeValue('id_modul')} >
                                        <option value='' />
                                        {moduleType}
                                    </TextField>
                                </Grid>
                                <Grid item >
                                    <TextField
                                        label={i18n.t('Type of region')}
                                        select
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={this.handleChangeValue('id_region')} >
                                        <option value='' />
                                        {regionType}
                                    </TextField>
                                </Grid>
                                <Grid item container>
                                    <Typography>{i18n.t('Active')}</Typography>
                                    <Switch
                                        checked={this.state.clientGMObj.aktivan}
                                        onChange={this.handleSwitch('aktivan')}
                                        name="aktivan"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', marginTop: '20px', marginBottom: '10px' }}
                                        onClick={() => this.addClientGM()}
                                        disabled={this.props.clientGMPosting} >
                                        {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        clientGMPosting: state.clientGMReducer.clientGMPosting,
        clientGMPostingSuccess: state.clientGMReducer.clientGMPostingSuccess,
        clientGMObj: state.clientGMReducer.clientGMObj,
        clientGMPostingFailed: state.clientGMReducer.clientGMPostingFailed,

        regionType: state.clientGMReducer.regionType,
        regionTypeFetching: state.clientGMReducer.regionTypeFetching,
        regionTypeFetchingFailed: state.clientGMReducer.regionTypeFetchingFailed,

        clientGroupType: state.clientGMReducer.clientGroupType,
        clientGroupTypeFetching: state.clientGMReducer.clientGroupTypeFetching,
        clientGroupTypeFetchingFailed: state.clientGMReducer.clientGroupTypeFetchingFailed,

        moduleType: state.clientGMReducer.moduleType,
        moduleTypeFetching: state.clientGMReducer.moduleTypeFetching,
        moduleTypeFetchingFailed: state.clientGMReducer.moduleTypeFetchingFailed,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInitialState: (component) => dispatch(setInitialState(component)),

        addClientGM: (clientGMObj) => dispatch(addClientGM(clientGMObj)),

        getRegionType: () => dispatch(getRegionType()),
        getClientGroupType: () => dispatch(getClientGroupType()),
        getModuleType: () => dispatch(getModuleType()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddClientGroupModules);