import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {
    getHierarchy, setInitialState
} from '../actions/HierarchyActions';
import i18n from '../i18n/i18n';
import Select from 'react-select';
import immutable from 'seamless-immutable';
import { Fab } from '@material-ui/core';
import { exportDocument } from '../actions/ExportActions';
import Export from '../utils/Export';
import { getZones, getStates, getBranches } from '../actions/AdministrationActions';

export class Hierarchy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
        };
    }

    componentDidMount() {
        if (this.props.zones.length === 0) {
            this.props.getStates();
        }
        if (this.props.states.length === 0) {
            this.props.getZones();
        }
        if (this.props.branches.length === 0) {
            this.props.getBranches();
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            hierarchys: immutable.asMutable(nextProps.hierarchys),
            hierarchyFetching: nextProps.hierarchyFetching,
            states: immutable.asMutable(nextProps.states.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            statesFetching: nextProps.statesFetching,
            zones: immutable.asMutable(nextProps.zones.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            branches: immutable.asMutable(nextProps.branches.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            // localGoverments: immutable.asMutable(nextProps.localGoverments.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
        })

    }

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    onExportClick = () => {
        this.setState({ export: !this.state.export });
    }

    exportFunction = () => {
        const table = this.treeTable;
        this.props.exportDocument(this.state.docTitle, table.state, 'api/hierarchy/read', this.state.docFormat || 'xlsx');
        this.setState({ export: !this.state.export });
    }


    render() {
        const columns = [
            {
                Header: i18n.t('Zone'),
                accessor: 'zona_naziv',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.zones}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },

            {
                Header: i18n.t('State'),
                accessor: 'opstina_naziv',
                aggregate: vals => '',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.states}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Branch'),
                accessor: "naziv",
                aggregate: vals => '',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.branches}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Address'),
                accessor: 'adresa',
                aggregate: vals => ''
            }
        ];
        return (
            <div className="page">
                <div className="table-header">
                    <Fab
                        onClick={() => this.onExportClick()}
                        color="primary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-download headerIcon" />
                    </Fab>
                    <Fab
                        onClick={() => this.onSearchClick()}
                        color="primary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-search headerIcon" />
                    </Fab>

                </div>
                <ReactTable
                    ref={r => (this.treeTable = r)}
                    data={this.state.hierarchys}
                    columns={columns}
                    filterable={this.state.showFilters}
                    loading={this.props.hierarchyFetching}
                    pivotBy={["zona_naziv", "opstina_naziv"]}
                    manual
                    treeTableIndent={50}
                    showPagination={false}
                    onFetchData={(state, instance) => this.props.getHierarchy(state, instance)}
                    className="-striped -highlight pivot-without-paging"
                />
                {this.state.export && <Export
                    export={this.state.export}
                    onTextChange={({ title }) => {
                        this.setState({ docTitle: title });
                    }}
                    changeFormat
                    onformatChange={(format) => {
                        this.setState({ docFormat: format });
                    }}
                    handleClose={this.onExportClick}
                    exportDoc={this.exportFunction} />
                }
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        hierarchys: state.hierarchyReducer.hierarchys,
        hierarchyFetching: state.hierarchyReducer.hierarchyFetching,
        localGoverments: state.farmRegistarReducer.localGoverments,
        localGovermentsFetching: state.farmRegistarReducer.localGovermentsFetching,
        localGovermentsFetchingFailed: state.farmRegistarReducer.localGovermentsFetchingFailed,
        zones: state.administrationReducer.zones,
        states: state.administrationReducer.states,
        statesFetching: state.administrationReducer.statesFetching,
        branches: state.administrationReducer.branches,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getZones: () => dispatch(getZones()),
        getStates: () => dispatch(getStates()),
        getBranches: () => dispatch(getBranches()),
        getHierarchy: (state, instance) => dispatch(getHierarchy(state, instance)),
        setInitialState: (component) => dispatch(setInitialState(component)),
        exportDocument: (title, state, url, format) => dispatch(exportDocument(title, state, url, format)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Hierarchy);