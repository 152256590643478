/**
 * Created by pajicv on 5/10/18.
 */

import React from 'react';
import './DatePicker.css';
const DatePicker = ({date, minDate, maxDate, onDateChange}) => (
    <div className="datePicker">
        <div>
            <input type="date"
                   id="imageryDate"
                   name="imageryDate"
                   value={date}
                   min={minDate}
                   max={maxDate}
                   onChange={onDateChange}/>
        </div>
    </div>
);


export default DatePicker;
