import React from 'react';
import i18n from '../../i18n/i18n';
import axios from '../../utils/AxiosWrapper'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Paper, Button, TextField, IconButton, Link } from '@material-ui/core';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import validator from 'validator';
import { Doughnut } from 'react-chartjs-2';
/// FOOTER CSS
var style = {
    textAlign: "center",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "40px",
    lineHeight: "50px",
    width: "98%",
    color: '#ffffff',
    fontSize: 24,
}
const styles = theme => ({ primaryMain: { backgroundColor: theme.palette.primary.main } });

export class OpstinaLanding extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            countZakupac: '',
            countUkupno: '',
            rnZakupci: '',
            tableZakupci: '',
            tbZakupci: ''
        };
    }
    componentDidMount() {
        this.getAdminReportDataOpstinaLandingZakupci()
        this.getAdminReportDataOpstinaLandingUkupno()
        this.getOpstinaReportrnZakupci()
        this.getOpstinaReportTableZakupci()
        this.getOpstinaReportTb()
    }

    getAdminReportDataOpstinaLandingZakupci() {
        axios.get('api/admin_report/admin_data_opstina_landing')
            .then(res => this.setState({ countZakupac: res.data.data[0].count }))
            .catch(error => console.log('Something went wrong, please try again!'));
    }

    getAdminReportDataOpstinaLandingUkupno() {
        axios.get('api/admin_report/admin_data_opstina_landing_ukupno')
            .then(res => this.setState({ countUkupno: res.data.data[0].count }))
            .catch(error => console.log('Something went wrong, please try again!'));
    }

    getOpstinaReportrnZakupci() {
        axios.get('api/admin_report/opstina_report_table_rn')
            .then(res => this.setState({ rnZakupci: res.data.data[0].count }))
            .catch(error => console.log('Something went wrong, please try again!'));
    }
    getOpstinaReportTableZakupci() {
        axios.get('api/admin_report/opstina_report_table_zakupci')
            .then(res => this.setState({ tableZakupci: res.data.data[0].count }))
            .catch(error => console.log('Something went wrong, please try again!'));
    }
    getOpstinaReportTb() {
        axios.get('api/admin_report/opstina_report_tb')
            .then(res => this.setState({ tbZakupci: res.data.data[0].count }))
            .catch(error => console.log('Something went wrong, please try again!'));
    }



    render() {
        const dataKorisnici = {
            labels: [i18n.t('Lessees'), i18n.t('Others')],
            datasets: [
                {
                    data: [this.state.countZakupac, this.state.countUkupno],
                    backgroundColor: [
                        '#4C9A2A',
                        '#ffa213'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                    ],
                    borderWidth: 1,
                },
            ],
        };
        const dataAktivnost = {
            labels: [i18n.t('Tables'), i18n.t('Activities'), i18n.t('Notes')],
            datasets: [
                {
                    data: [this.state.tableZakupci, this.state.rnZakupci, this.state.tbZakupci],
                    backgroundColor: [
                        '#4C9A2A',
                        '#ffa213',
                        'red'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                    ],
                    borderWidth: 1,
                },
            ],
        };

        return (
            <div>
                <div style={{ display: 'flex', width: '100%', height: '50%' }}>
                    <div style={{ padding: '20px', width: '50%', height: '100%' }}>
                        <h3 style={{ textAlign: 'center' }}>{i18n.t('Users')}</h3>
                        <Doughnut data={dataKorisnici} />
                        <div>
                            {i18n.t('Total number of users in the municipality/city')}: {Number(this.state.countZakupac) + Number(this.state.countUkupno)}
                        </div>
                    </div>
                    <div style={{ padding: '20px', width: '50%', height: '100%' }}>
                        <h3 style={{ textAlign: 'center' }}>{i18n.t('Tenant activities')}</h3>
                        <Doughnut data={dataAktivnost} />
                        <div>
                            {i18n.t('Total number of tenant fields in the municipality/city')}: {Number(this.state.tableZakupci)}
                        </div>
                        <div>
                            {i18n.t('The total number of work orders of tenants in the municipality/city')}: {Number(this.state.rnZakupci)}
                        </div>
                        <div>
                            {i18n.t('Total number of field notes of tenants in the municipality/city')}: {Number(this.state.tbZakupci)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default OpstinaLanding;