import immutable from 'seamless-immutable';
import { Type as LoginType } from '../actions/LoginActions';
import i18n from '../i18n/i18n';

export const INITIAL_STATE = immutable({
    token: null,
    logInFailed: false,
    checkingCredentials: false,
    loginSuccess: false,
    openAlert: false,
    alertText: '',
    alertTitle: '',
    isDrawerOpen: true,
    isMenuOpen: false,
    user: {},
    bundle: null,
    clientProfile: {},
    clientProfileFetching: false,
    clientProfileFetchingFailed: false,
    userProfile: {},
    userProfileFetching: false,
    userProfileFetchingFailed: false,
    passwordChange: false,
    passwordChangeFetching: false,
    passwordChangeFetchingFailed: false,
    profileEdited: false,
    profileEditedFetching: false,
    profileEditedFetchingFailed: false,
    profilePictureEdited: false,
    profilePictureEditedFetching: false,
    profilePictureEditedFetchingFailed: false,
    languages: [],
    languagesFetching: false,
    languagesFetchingFailed: false,
    currencyes: [],
    currencyesFetching: false,
    currencyesFetchingFailed: false,
    clientProfilePictureEditedFetching: false,
    message: '',
    ticketSentSuccess: false,
    ticketSentFailed: false,
    region: [],
    regionFetching: false,
    regionFetchingFailed: false,
    usernameCheck: false,
    usernameCheckFetching: false,
    usernameCheckFetchingFailed: false,
    createpassword: false,
    createpasswordFetching: false,
    createpasswordFetchingFailed: false

});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case LoginType.LOGOUT:
            localStorage.clear();
            window.location.replace(window.location.origin);
            return INITIAL_STATE;
            break;

        case LoginType.SET_INITIAL_STATE: {
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;
        }
        case LoginType.LOGIN_CALL: {
            const checkingCredentials = true;
            return state.merge({ checkingCredentials });
            break;
        }

        case LoginType.LOGIN_SUCCESS: {
            const token = action.data.token;
            const user = action.data.user;
            const checkingCredentials = false;
            const loginSuccess = true;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
            return state.merge({ token, user, checkingCredentials, loginSuccess });
            break;
        }

        case LoginType.LOGIN_FAILED: {
            const checkingCredentials = false;
            const logInFailed = true;
            const message = action.message;
            return state.merge({ logInFailed, message: message });
            break;
        }

        case LoginType.FORGOT_PASSWORD_CALL: {
            const usernameCheckFetching = true;
            return state.merge({ usernameCheckFetching });
            break;
        }

        case LoginType.FORGOT_PASSWORD_SUCCESS: {
            const usernameCheck = true;
            const usernameCheckFetching = false;
            return state.merge({ usernameCheck, usernameCheckFetching });
            break;
        }

        case LoginType.FORGOT_PASSWORD_FAILED: {
            const usernameCheckFetching = false;
            const usernameCheckFetchingFailed = true;
            return state.merge({ usernameCheckFetching, usernameCheckFetchingFailed });
            break;
        }

        case LoginType.CREATE_NEW_PASSWORD_CALL: {
            const createpasswordFetching = true;
            return state.merge({ createpasswordFetching });
            break;
        }

        case LoginType.CREATE_NEW_PASSWORD_SUCCESS: {
            const createpassword = true;
            const createpasswordFetching = false;
            return state.merge({ createpassword, createpasswordFetching });
            break;
        }

        case LoginType.CREATE_NEW_PASSWORD_FAILED: {
            const createpasswordFetchingFailed = true;
            const createpasswordFetching = false;
            return state.merge({ createpasswordFetchingFailed, createpasswordFetching });
            break;
        }

        case LoginType.SHOW_MENU_DRAWER: {
            const isDrawerOpen = action.data;
            return state.merge({ isDrawerOpen });
            break;
        }
        case LoginType.SHOW_MENU: {
            const isMenuOpen = action.data;
            return state.merge({ isMenuOpen });
            break;
        }

        case LoginType.CLIENT_PROFILE_CALL: {
            const clientProfileFetching = true;
            return state.merge({ clientProfileFetching });
            break;
        }

        case LoginType.CLIENT_PROFILE_SUCCESS: {
            const clientProfile = action.data.data[0];
            const clientProfileFetching = false;
            return state.merge({ clientProfile, clientProfileFetching });
            break;
        }

        case LoginType.CLIENT_PROFILE_FAILED: {
            const clientProfileFetchingFailed = true;
            const clientProfileFetching = false;
            return state.merge({ clientProfileFetchingFailed, clientProfileFetching });
            break;
        }

        case LoginType.USER_PROFILE_CALL: {
            const userProfileFetching = true;
            return state.merge({ userProfileFetching });
            break;
        }

        case LoginType.USER_PROFILE_SUCCESS: {
            const userProfile = action.data.data;
            const userProfileFetching = false;
            i18n.changeLanguage(userProfile.jezik.naziv);
            return state.merge({ userProfile, userProfileFetching });
            break;
        }

        case LoginType.USER_PROFILE_FAILED: {
            const userProfileFetchingFailed = true;
            const userProfileFetching = false;
            return state.merge({ userProfileFetchingFailed, userProfileFetching });
            break;
        }

        case LoginType.CHANGE_PASSWORD_CALL: {
            const passwordChangeFetching = true;
            return state.merge({ passwordChangeFetching });
            break;
        }

        case LoginType.CHANGE_PASSWORD_SUCCESS: {
            const passwordChange = true;
            const passwordChangeFetching = false;
            return state.merge({ passwordChange, passwordChangeFetching });
            break;
        }

        case LoginType.CHANGE_PASSWORD_FAILED: {
            const passwordChangeFetchingFailed = true;
            const passwordChangeFetching = false;
            return state.merge({ passwordChangeFetchingFailed, passwordChangeFetching });
            break;
        }

        case LoginType.EDIT_PROFILE_CALL: {
            const profileEditedFetching = true;
            return state.merge({ profileEditedFetching });
            break;
        }

        case LoginType.EDIT_PROFILE_SUCCESS: {
            const profileEdited = true;
            const profileEditedFetching = false;
            return state.merge({ profileEdited, profileEditedFetching });
            break;
        }

        case LoginType.EDIT_PROFILE_FAILED: {
            const profileEditedFetchingFailed = true;
            const profileEditedFetching = false;
            return state.merge({ profileEditedFetchingFailed, profileEditedFetching });
            break;
        }

        case LoginType.EDIT_PROFILE_PICTURE_CALL: {
            const profilePictureEditedFetching = true;
            return state.merge({ profilePictureEditedFetching });
            break;
        }

        case LoginType.EDIT_PROFILE_PICTURE_SUCCESS: {
            const profilePictureEdited = true;
            const profilePictureEditedFetching = false;
            return state.merge({ profilePictureEdited, profilePictureEditedFetching });
            break;
        }

        case LoginType.EDIT_PROFILE_PICTURE_FAILED: {
            const profilePictureEditedFetchingFailed = true;
            const profilePictureEditedFetching = false;
            return state.merge({ profilePictureEditedFetchingFailed, profilePictureEditedFetching });
            break;
        }
        case LoginType.EDIT_CLIENT_PROFILE_PICTURE_CALL: {
            const clientProfilePictureEditedFetching = true;
            return state.merge({ clientProfilePictureEditedFetching });
            break;
        }

        case LoginType.EDIT_CLIENT_PROFILE_PICTURE_SUCCESS: {
            const clientProfilePictureEditedFetching = false;
            return state.merge({ clientProfilePictureEditedFetching });
            break;
        }

        case LoginType.GET_LANGUAGE_CALL:
            languagesFetching = true;
            return state.merge({ languagesFetching });
            break;

        case LoginType.GET_LANGUAGE_SUCCESS:
            let languages = action.data;
            let languagesFetching = false;
            return state.merge({ languages, languagesFetching });
            break;

        case LoginType.GET_LANGUAGE_FAILED:
            languagesFetching = false;
            let languagesFetchingFailed = true;
            return state.merge({ languagesFetching, languagesFetchingFailed });
            break;

        case LoginType.GET_CURRENCY_CALL:
            currencyesFetching = true;
            return state.merge({ currencyesFetching });
            break;

        case LoginType.GET_CURRENCY_SUCCESS:
            let currencyes = action.data;
            let currencyesFetching = false;
            return state.merge({ currencyes, currencyesFetching });
            break;

        case LoginType.GET_CURRENCY_FAILED:
            currencyesFetching = false;
            let currencyesFetchingFailed = true;
            return state.merge({ currencyesFetching, currencyesFetchingFailed });
            break;

        case LoginType.TICKET_REST_SUCCESS: {
            const ticketSentSuccess = true;
            return state.merge({ ticketSentSuccess });
            break;
        }

        case LoginType.TICKET_REST_FAILED: {
            const ticketSentFailed = true;
            return state.merge({ ticketSentFailed });
            break;
        }

        case LoginType.REGION_CALL:
            regionFetching = true;
            return state.merge({ regionFetching });
            break;

        case LoginType.REGION_SUCCESS:
            let region = action.data.data;
            let regionFetching = false;
            return state.merge({ region, regionFetching });
            break;

        case LoginType.REGION_FAILED:
            regionFetching = false;
            let regionFetchingFailed = true;
            return state.merge({ regionFetching, regionFetchingFailed });
            break;


        default:
            return state;
    }
}
