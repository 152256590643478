import React from 'react';
import i18n from '../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';
import { wentWrong } from "../../../utils/ToastConfig";
import axios from '../../../utils/AxiosWrapper';
import { CSVReader } from 'react-papaparse'
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { DateTextField } from 'material-ui-pickers/_shared/DateTextField';

export class ImportFields extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            regions: [], region: null,
            layers: [], layer: null,
            units: [], unit: null,
            opstine: [], opstina: null, opstina_naziv: null,
            kat_opstine: [], kat_opstina: null, kat_opstina_naziv: null,
            fieldPosting: false,
            fields: [],
            problems: [],
        }
    }

    componentDidMount() {
        this.getByURL('region/filter_region', 'regions');
        this.getByURL('field/filter_unit', 'units');
    }

    getByURL(url, obj, value, name) {
        value = value || '';
        name = name || '';
        if (value != null) {
            axios.get(`api/${url}`, { params: { [name]: value } })
                .then(res => this.setState({ [obj]: res.data.data }))
                .catch(error => wentWrong('Something went wrong, please try again!'));
        }
    }

    addFields(fields) {
        if (
            this.state.region &&
            this.state.layer &&
            this.state.unit &&
            this.state.opstina &&
            this.state.fields
        ) {
            const field = fields.shift();
            if (field) {
                axios.post('api/field/create_fields', field)
                    .then(res => {
                        // console.log('field added=' + res.data);
                        this.addFields(fields);
                    })
                    .catch(error => {
                        // console.log(`addClient error=${error}`);
                        this.addFields(fields);
                        this.setState({ problems: [...this.state.problems, field] });
                    });
            }
        }
        else wentWrong('Please enter all required fields!');
    }

    handleOnDrop = (data) => {
        this.setState({ fields: [] });
        let field = []; let fields = [];

        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < 4; j++) {
                if (data[i].data[j] == "" || data[i].data[j] == undefined) {
                    data[i].data[j] = null;
                }
            }
            if (data[i].data[0] != null) {
                field = {
                    // CSV
                    // naziv: data[i].data[0] + ' - ' + data[i].data[1],
                    naziv: data[i].data[0],
                    broj: data[i].data[0],
                    potes: data[i].data[1],
                    povrsina: Number(data[i].data[2]),
                    povrsina_drz: Number(data[i].data[2]),
                    povrsina_mj: Number(data[i].data[2]),
                    zakup_od: data[i].data[3],
                    zakup_do: data[i].data[4],
                    username: data[i].data[5], // mail

                    // DROPDOWN
                    id_region: Number(this.state.region),
                    id_layer: Number(this.state.layer),
                    id_opstina: Number(this.state.opstina),
                    id_katastarska_opstina: Number(this.state.kat_opstina),
                    id_merna_jedinica: Number(this.state.unit),

                    // HARDCODE
                    aktivan: true,
                };
            }
            console.log(field);
            fields.push(field);
            this.setState({ fields });
        }
    }

    render() {
        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.props.open}
                    onClose={this.props.onClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('Import fields and parcels')}
                        <IconButton onClick={this.props.onClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <form id="add-field">
                            <Grid container justify="space-between" alignContent="space-between" >

                                <Grid item >
                                    <TextField required
                                        label={i18n.t('Region')}
                                        select
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={(e) => {
                                            this.setState({ region: e.target.value });
                                            this.getByURL('field/filter_lejer_uslov', 'layers', e.target.value, 'id_region');
                                            this.getByURL('field/filter_opstina_uslov', 'opstine', e.target.value, 'id_region');
                                        }} >
                                        <option value='' />
                                        {this.state.regions.map((region) => <option key={region.id} value={region.id}>{region.naziv}</option>)}
                                    </TextField>
                                </Grid>

                                <Grid item >
                                    <TextField required
                                        label={i18n.t('Unit')}
                                        select
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={(e) => { this.setState({ unit: e.target.value }); }} >
                                        <option value='' />
                                        {this.state.units.map((unit) => <option key={unit.id} value={unit.id}>{unit.naziv}</option>)}
                                    </TextField>
                                </Grid>

                                <Grid item >
                                    <TextField required
                                        label={i18n.t('Layer')}
                                        select
                                        disabled={!this.state.region}
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={(e) => { this.setState({ layer: e.target.value }); }} >
                                        <option value='' />
                                        {this.state.layers.map((layer) => <option key={layer.id} value={layer.id}>{layer.naziv}</option>)}
                                    </TextField>
                                </Grid>

                                <Grid item >
                                    <TextField required
                                        label={i18n.t('Opstina')}
                                        select
                                        disabled={!this.state.region}
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={(e) => {
                                            this.setState({ opstina: e.target.value });
                                            for (let i = 0; i < this.state.opstine.length; i++) {
                                                if (this.state.opstine[i].id == Number(e.target.value)) {
                                                    this.setState({ opstina_naziv: this.state.opstine[i].naziv });
                                                }
                                            }
                                            this.getByURL('klijent_korisnik/kat_opstina_dropdown', 'kat_opstine', e.target.value, 'id_opstina');
                                        }} >
                                        <option value='' />
                                        {this.state.opstine.map((opstina) => <option key={opstina.id} value={opstina.id}>{opstina.naziv}</option>)}
                                    </TextField>
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        label={i18n.t('Katastarska opstina')}
                                        disabled={!this.state.opstina}
                                        select
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginBottom: '10px' }}
                                        onChange={(e) => { this.setState({ kat_opstina: e.target.value }); }}
                                    >
                                        <option value='' />
                                        {this.state.kat_opstine.map((kat_opstina) => <option key={kat_opstina.id} value={kat_opstina.id}>{kat_opstina.naziv}</option>)}
                                    </TextField>
                                </Grid>

                                <Typography>Problems:</Typography>
                                <TextareaAutosize style={{ width: '600px', height: '200px', marginBottom: '10px' }}
                                    rowsMin={10}
                                    aria-label="maximum height"
                                    value={JSON.stringify(this.state.problems)}
                                />
                                {
                                    this.state.region &&
                                    this.state.layer &&
                                    this.state.unit &&
                                    this.state.opstina &&

                                    <CSVReader onDrop={this.handleOnDrop}
                                        onError={this.handleOnError}
                                        addRemoveButton
                                        onRemoveFile={this.handleOnRemoveFile}>
                                        <span>Drop CSV file here or click to upload.</span>
                                    </CSVReader>
                                }

                                <Grid>
                                    <Button style={{ alignSelf: 'center', backgroundColor: '#58B4E5', color: 'white', marginTop: '20px', marginBottom: '10px' }}
                                        onClick={() => this.addFields(this.state.fields)}
                                        disabled={this.state.fieldPosting} >
                                        {i18n.t('Add')}
                                    </Button>
                                </Grid>

                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
};