import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import 'react-table/react-table.css';
import {
    getLocalGov,
} from '../../../actions/FarmRegistarActions';
import { getStates } from '../../../actions/AdministrationActions';

import 'react-toastify/dist/ReactToastify.css';
import {
    getProgramType
} from '../../../actions/financial/ProgramActions';
import { getFarmersDropdown } from '../../../actions/financial/IncentiveActions';
import {
    getApplications, setInitialState, setRowInReducer,
    getApplicationStatuses, getApplicationDocuments
} from '../../../actions/financial/ApplicationActions';
import i18n from '../../../i18n/i18n';
import Select from 'react-select';
import immutable from 'seamless-immutable';
import { toast } from 'react-toastify';
import EditSubsidies from './EditSubsidies';
import { withRouter } from 'react-router-dom'
import {
    Button, Grid, TextField, IconButton, Fab,
    Dialog, DialogContent, DialogTitle
} from '@material-ui/core';
import Statistics from "../../reports/Statistics";
import DocumentGridList from '../../../utils/DocumentGridList';
import GPSReport from "../../financial/incentives/GPSReport";
import ProfilePreview from '../../../utils/ProfilePreview';
import { exportDocument } from '../../../actions/ExportActions';
import Export from '../../../utils/Export';
import DashboardDrawer from '../../../utils/DashboardDrawer';
import ViewField from "../../catastral_data/field/ViewField";
import ViewParcel from "../../catastral_data/parcel/ViewParcel";
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment';
import { getUser } from '../../../utils/CommonFunctions';
import _ from 'lodash';
import printPdf from './PrintPdf';
const CheckboxTable = checkboxHOC(ReactTable);;
export class ViewSubsidies extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            selection: [],
            selectAll: false,
            view: false,
            add: false,
            isStatisticShown: false,
            isFieldShown: false,
            isParcelShown: false,
            profile: false,
            expanded: {}
        };
        this.tableScrollTop = 0;
    }

    async componentDidMount() {
        if (this.props.states.length === 0) {
            this.props.getStates();
        }
        if (this.props.localGoverments.length === 0) {
            this.props.getLocalGov();
        }
        if (this.props.farmerDropdown.length === 0) {
            this.props.getFarmersDropdown();
        }
        if (this.props.appStatuses.length === 0) {
            this.props.getApplicationStatuses();
        }
        if (this.props.programType.length == 0) {
            this.props.getProgramType();
        }
        let user = await getUser();
        if (user) {
            this.setState({
                id_klijent_grupa: user.id_klijent_grupa
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.appPostingSuccess == true) {
            this.setState({
                add: false,
                view: false,
                selection: [],
                selectAll: false
            })
            toast.success(i18n.t('Successfully updated subsidie!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getApplications(wrapped.state);
            this.props.setInitialState('appPostingSuccess');
        }
        this.setState({
            appStatuses: immutable.asMutable(nextProps.appStatuses.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            programType: immutable.asMutable(nextProps.programType.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            states: immutable.asMutable(nextProps.states.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            localGoverments: immutable.asMutable(nextProps.localGoverments.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            farmerDropdown: immutable.asMutable(nextProps.farmerDropdown.map((item) => { return { label: item.kontakt_osoba, value: item.id } }), { deep: true }),
        })
    }

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    onExportClick = () => {
        this.setState({ export: !this.state.export });
    }
    exportFunction = () => {
        const table = this.checkboxTable.getWrappedInstance();
        this.props.exportDocument(this.state.docTitle, table.state, 'api/application/read', this.state.docFormat || 'xlsx');
        this.setState({ export: !this.state.export });
    }

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            selection.push(key);
        }
        this.setState({ selection }, function () {
            if (selection.length == 1) {
                let row1 = this.props.applications.filter((app) => {
                    return app.id == selection;
                })
                this.props.setRowInReducer(row1[0], 'applicationsObj');
            }
        });
    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    onEditClick = () => {
        this.setState({ add: !this.state.add });
    }
    onProfileClick = () => {
        this.setState({ profile: !this.state.profile });
    }

    showField = () => {
        this.setState({ isFieldShown: !this.state.isFieldShown })
    }
    showParcel = () => {
        this.setState({ isParcelShown: !this.state.isParcelShown })
    }

    viewClick = () => {
        this.setState({ view: !this.state.view });
        this.props.getApplicationDocuments(this.state.selection[0]);
    };

    showReport = () => {
        this.setState({ isStatisticShown: !this.state.isStatisticShown })
    }

    handleGPSReportOkClick = () => {
        this.setState({ isGPSReportShown: false })
    };

    showGPSReport = () => {
        this.setState({ isGPSReportShown: true });
    };

    onPieClick = () => {
        this.props.history.push('/subsidies_dashboard');
    }

    onTableClick = () => {
        this.props.history.push('/subsidies');
    }

    renderLabel = (date) => {
        if (date.isValid()) {
            return date.format('DD.MM.YYYY');
        } else {
            return '';
        }
    }

    onExpandedChange = (expanded) => {
        this.setState({ expanded });
    }

    printClick = () => {
        const { expanded } = this.state;
        var second;
        var first = Object.keys(expanded).filter((item) => expanded[item] !== false &&
            (typeof expanded[item] === 'object' &&
                Object.keys(expanded[item]).filter((it) => {
                    if (expanded[item][it] !== false) {
                        second = it;
                        return true;
                    }
                    return false;
                })).length === 1)[0];
        // console.log('bbbb', first, second, this.checkboxTable.getWrappedInstance().state);
        var data = this.checkboxTable.getWrappedInstance().state.resolvedData[first]._subRows[second];
        printPdf(data, this.props.clientProfile);
    }

    render() {
        const { id_klijent_grupa, expanded } = this.state;
        let filterable = this.props.kontakt_osoba ? { filterable: false } : {};
        const columns = [
            {
                Header: i18n.t('Farmers corporative society'),
                accessor: 'klijent_society_naziv',
            },
            {
                Header: i18n.t('Title'),
                accessor: 'titula_naziv',
                aggregate: (vals, rows) => _.uniq(vals).join(','),
            },
            {
                Header: i18n.t('Contact person'),
                accessor: 'kontakt_osoba',
                aggregate: (vals, rows) => _.uniq(vals).join(','),
                ...filterable
            },
            {
                Header: i18n.t('Phone'),
                accessor: 'kontakt_telefon',
                aggregate: (vals, rows) => _.uniq(vals).join(','),
            },
            {
                Header: i18n.t('Address'),
                accessor: 'ulica_i_broj',
                aggregate: (vals, rows) => _.uniq(vals).join(','),
            },
            {
                Header: i18n.t('State'),
                accessor: 'opstina',
                aggregate: (vals, rows) => _.uniq(vals).join(','),
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.states}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Local government'),
                accessor: 'katastarska_opstina',
                aggregate: (vals, rows) => _.uniq(vals).join(','),
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.localGoverments}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('BVN'),
                accessor: 'bpg',
                show: id_klijent_grupa == 14 ? false : true,
                aggregate: (vals, rows) => _.uniq(vals).join(','),
            },
            {
                Header: i18n.t('Email'),
                accessor: 'kontakt_mejl',
                aggregate: (vals, rows) => _.uniq(vals).join(','),
            },
            {
                id: 'datum_rodjenja',
                Header: i18n.t('Date of birth'),
                aggregate: (vals, rows) => _.uniq(vals).join(','),
                accessor: d => d.datum_rodjenja ? moment(d.datum_rodjenja).format('DD.MM.YYYY') : '',
            },
            {
                id: 'gazdinstvo',
                Header: i18n.t('Verified farmer'),
                accessor: d => d.gazdinstvo,
                aggregate: (vals, rows) => _.uniq(vals).join(','),
                Aggregated: row => <span></span>,
                Cell: ({ value }) => value === true ? i18n.t('Yes') : i18n.t('No')
            },
            {
                Header: i18n.t('Farmers name'),
                accessor: 'kontakt_osoba',
                aggregate: (vals, rows) => _.uniq(vals).join(','),
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.farmerDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Program'),
                accessor: 'program_naziv',
                aggregate: (vals, rows) => _.uniq(vals).join(','),
            },
            {
                Header: i18n.t('Type of program'),
                accessor: 'naziv_vrsta_programa',
                aggregate: (vals, rows) => _.uniq(vals).join(','),
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.programType}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                id: 'datum_pocetka',
                Header: i18n.t('Start Date'),
                aggregate: (vals, rows) => _.uniq(vals).join(','),
                accessor: d => d.datum_pocetka ? moment(d.datum_pocetka, 'DD.MM.YYYY').format('DD.MM.YYYY') : '',
            },
            {
                id: 'datum_zavrsetka',
                Header: i18n.t('End date'),
                aggregate: (vals, rows) => _.uniq(vals).join(','),
                accessor: d => d.datum_zavrsetka ? moment(d.datum_zavrsetka, 'DD.MM.YYYY').format('DD.MM.YYYY') : '',
            },
            {
                id: 'datum_apliciranja',
                Header: i18n.t('Date of application'),
                aggregate: (vals, rows) => _.uniq(vals).join(','),
                accessor: d => d.datum_apliciranja ? moment(d.datum_apliciranja, 'DD.MM.YYYY').format('DD.MM.YYYY') : '',
            },
            {
                id: 'id_aplikacija_status',
                Header: i18n.t('Application status'),
                accessor: 'aplikacija_status_naziv',
                aggregate: (vals, rows) => _.uniq(vals).join(','),
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.appStatuses}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                id: 'value_asked',
                Header: i18n.t('Amount asked(NGN)'),
                aggregate: vals => {
                    var values = vals.map(i => {
                        if (i == null) return 0;
                        return parseFloat(i)
                    });
                    return _.sum(values)
                },
                accessor: d => d.value_asked ? parseInt(d.value_asked).toFixed() : 0
            },
            {
                id: 'value_approved',
                Header: i18n.t('Amount approved(NGN)'),
                aggregate: vals => {
                    var values = vals.map(i => {
                        if (i == null) return 0;
                        return parseFloat(i)
                    });
                    return _.sum(values)
                },
                accessor: d => d.value_approved ? parseInt(d.value_approved).toFixed() : 0
            },
            {
                id: 'value',
                Header: i18n.t('Amount paid(NGN)'),
                aggregate: vals => {
                    var values = vals.map(i => {
                        if (i == null) return 0;
                        return parseFloat(i)
                    });
                    return _.sum(values)
                },
                accessor: d => d.value ? parseInt(d.value).toFixed() : 0,
                display: rowInfo => <span>{rowInfo.value}</span>,
            },
            {
                Header: i18n.t('Description'),
                accessor: 'opis',
                aggregate: (vals, rows) => _.uniq(vals).join(','),
            },

        ];
        const { toggleSelection, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            // selectAll,
            isSelected,
            toggleSelection,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo && rowInfo.original) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: rowInfo.original.black_list_branch ? 'gray' : selected ? "#ACDAF1" : "inherit"
                        }
                    };
                }
                return {};
            }
        };
        const filterOptions = {
            defaultFiltered:
                [
                    {
                        id: 'kontakt_osoba',
                        value: this.props.kontakt_osoba ? this.props.kontakt_osoba : []
                    }
                ]
        };

        const pivotProps = id_klijent_grupa === 14 ? {
            pivotBy: ["klijent_society_naziv", "program_naziv"],
            freezeWhenExpanded: true
        } : {};

        return (
            <div className="page">
                {this.props.isSubsidiesShown ?
                    <div>
                        <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('Subsidies')}
                            <IconButton onClick={this.props.handleCloseSubsidies}>
                                <span className="icon-cancel-circle closeIcon" />
                            </IconButton>
                        </DialogTitle>
                    </div>
                    : null}
                <div className="table-header">
                    <Fab
                        onClick={() => this.onSearchClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-search headerIcon" />
                    </Fab>
                    <Fab
                        onClick={() => this.onExportClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-download headerIcon" />
                    </Fab>
                    {this.state.selection.length === 1 ?
                        <Fab
                            onClick={() => this.viewClick()}
                            color="secondary"
                            aria-label="edit"
                            className="action-button"
                        >
                            <span className=" icon-Informacija headerIcon" />
                        </Fab>
                        : null
                    }
                    {Object.keys(expanded).filter((item) => expanded[item] !== false && (typeof expanded[item] === 'object' && Object.keys(expanded[item]).filter((it) => expanded[item][it] !== false)).length === 1).length === 1 ?
                        <Fab
                            onClick={() => this.printClick()}
                            color="secondary"
                            aria-label="edit"
                            className="action-button"
                        >
                            <span className=" icon-file-pdf headerIcon" />
                        </Fab>
                        : null
                    }
                </div>
                <CheckboxTable
                    ref={r => (this.checkboxTable = r)}
                    data={this.props.applications}
                    getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                    columns={columns}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.props.applicationsPages}
                    loading={this.props.applicationsFetching}
                    manual
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => this.props.getApplications(state, instance)}
                    className={this.props.isSubsidiesShown ? "-striped -highlight" : '-striped -highlight table-drawer'}
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    onExpandedChange={(expanded) => this.onExpandedChange(expanded)}
                    {...pivotProps}
                    {...checkboxProps}
                    {...filterOptions}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF',
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
                <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} />
                {this.state.export && <Export
                    export={this.state.export}
                    onTextChange={({ title }) => {
                        this.setState({ docTitle: title });
                    }}
                    changeFormat
                    onformatChange={(format) => {
                        this.setState({ docFormat: format });
                    }}
                    handleClose={this.onExportClick}
                    exportDoc={this.exportFunction} />
                }
                {this.state.add && <EditSubsidies add={this.state.add}
                    handleClose={this.onEditClick} />}
                {this.state.profile &&
                    <ProfilePreview
                        profile={this.state.profile}
                        handleClose={this.onProfileClick}
                        clientId={this.props.applicationsObj.id_klijent_naziv}
                    />}
                {this.state.view &&
                    <Dialog
                        open={this.state.view}
                        onClose={this.viewClick}
                        disableBackdropClick
                        maxWidth="md"
                    >
                        <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('View application')}
                            <IconButton onClick={this.viewClick}>
                                <span className="icon-cancel-circle closeIcon" />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('Name surname')}
                                        defaultValue={this.props.applicationsObj.kontakt_osoba}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('BVN')}
                                        defaultValue={this.props.applicationsObj.bpg}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('Farmer')}
                                        defaultValue={this.props.applicationsObj.kontakt_osoba}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('Type of program')}
                                        defaultValue={this.props.applicationsObj.naziv_vrsta_programa}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <DatePicker
                                        label={i18n.t('Start date')}
                                        format="DD.MM.YYYY"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={moment(this.props.applicationsObj.datum_pocetka, 'DD.MM.YYYY')}
                                        animateYearScrolling={false}
                                        labelFunc={this.renderLabel}
                                        disableOpenOnEnter
                                    />
                                </Grid>
                                <Grid item>
                                    <DatePicker
                                        label={i18n.t('End date')}
                                        format="DD.MM.YYYY"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={moment(this.props.applicationsObj.datum_zavrsetka, 'DD.MM.YYYY')}
                                        animateYearScrolling={false}
                                        labelFunc={this.renderLabel}
                                        disableOpenOnEnter
                                    />
                                </Grid>
                                <Grid item>
                                    <DatePicker
                                        label={i18n.t('Date of application')}
                                        format="DD.MM.YYYY"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={moment(this.props.applicationsObj.datum_apliciranja, 'DD.MM.YYYY')}
                                        animateYearScrolling={false}
                                        labelFunc={this.renderLabel}
                                        disableOpenOnEnter
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        label={i18n.t('Application status')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.props.applicationsObj.aplikacija_status_naziv}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        label={i18n.t('Amount asked(NGN)')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.props.applicationsObj.value_asked ? parseInt(this.props.applicationsObj.value_asked).toFixed() : null}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        label={i18n.t('Amount approved(NGN)')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.props.applicationsObj.value_approved ? parseInt(this.props.applicationsObj.value_approved).toFixed() : null}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        label={i18n.t('Amount paid(NGN)')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.props.applicationsObj.value ? parseInt(this.props.applicationsObj.value).toFixed() : null}
                                    />
                                </Grid>
                                <TextField
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    multiline
                                    fullWidth
                                    id="full-width"
                                    margin="normal"
                                    label={i18n.t('Description')}
                                    value={this.props.applicationsObj.opis}
                                />
                                {this.props.applicationDocuments && this.props.applicationDocuments.length !== 0 ?
                                    <DocumentGridList
                                        documentArray={this.props.applicationDocuments}
                                    />
                                    : null}
                                <Grid item>
                                    <Button onClick={this.onEditClick} style={{ backgroundColor: '#58B4E5', color: 'white', marginTop: '10px' }}
                                    >
                                        {i18n.t('Edit data')}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={this.onProfileClick} style={{ backgroundColor: '#58B4E5', color: 'white', marginTop: '10px' }}
                                    >
                                        {i18n.t('Profile')}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={this.showParcel} style={{ backgroundColor: '#58B4E5', color: 'white', marginTop: '10px' }}
                                    >
                                        {i18n.t('Parcel')}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={this.showField} style={{ backgroundColor: '#58B4E5', color: 'white', marginTop: '10px' }}
                                    >
                                        {i18n.t('Field')}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={this.showReport} style={{ backgroundColor: '#58B4E5', color: 'white', marginTop: '10px' }}>
                                        {i18n.t('Report')}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={this.showGPSReport} style={{ backgroundColor: '#58B4E5', color: 'white', marginTop: '10px' }}>
                                        {i18n.t('GPS report')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                }
                {this.state.isStatisticShown &&
                    <Dialog
                        open={this.state.isStatisticShown}
                        maxWidth="xl"
                        aria-labelledby="responsive-dialog-title"
                    >
                        <Statistics kontakt_osoba={this.props.applicationsObj.kontakt_osoba} isStatisticShown={this.state.isStatisticShown} handleClose={this.showReport} />
                    </Dialog>}
                {this.state.isGPSReportShown &&
                    <Dialog
                        open={this.state.isGPSReportShown}
                        disableBackdropClick
                        maxWidth="md"
                        aria-labelledby="responsive-dialog-title"
                    >
                        <GPSReport idClient={this.props.applicationsObj.id_klijent_naziv} handleClose={this.handleGPSReportOkClick} />
                    </Dialog>
                }
                {this.state.isFieldShown &&
                    <Dialog
                        open={this.state.isFieldShown}
                        maxWidth="xl"
                        aria-labelledby="responsive-dialog-title"
                    >
                        <ViewField kontakt_osoba={this.props.applicationsObj.kontakt_osoba} isFieldShown={this.state.isFieldShown} handleCloseField={this.showField} />
                    </Dialog>}
                {this.state.isParcelShown &&
                    <Dialog
                        open={this.state.isParcelShown}
                        maxWidth="xl"
                        aria-labelledby="responsive-dialog-title"
                    >
                        <ViewParcel klijent_naziv={this.props.applicationsObj.klijent_naziv} isParcelShown={this.state.isParcelShown} handleCloseParcel={this.showParcel} />
                    </Dialog>}
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        applications: state.applicationReducer.applications,
        applicationsObj: state.applicationReducer.applicationsObj,
        applicationsFetching: state.applicationReducer.applicationsFetching,
        appPostingSuccess: state.applicationReducer.appPostingSuccess,
        applicationsPages: state.applicationReducer.applicationsPages,
        farmerDropdown: state.incentiveReducer.farmerDropdown,
        appStatuses: state.applicationReducer.appStatuses,
        states: state.administrationReducer.states,
        localGoverments: state.farmRegistarReducer.localGoverments,
        programType: state.programReducer.programType,
        applicationDocuments: state.applicationReducer.applicationDocuments,
        clientProfile: state.appReducer.clientProfile,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInitialState: (component) => dispatch(setInitialState(component)),
        getFarmersDropdown: () => dispatch(getFarmersDropdown()),
        getStates: () => dispatch(getStates()),
        getLocalGov: () => dispatch(getLocalGov()),
        getProgramType: () => dispatch(getProgramType()),
        getApplicationStatuses: () => dispatch(getApplicationStatuses()),
        setRowInReducer: (rowInfo, name) => dispatch(setRowInReducer(rowInfo, name)),
        getApplications: (state, instance) => dispatch(getApplications(state, instance)),
        getApplicationDocuments: (id) => dispatch(getApplicationDocuments(id)),
        exportDocument: (title, state, url, format) => dispatch(exportDocument(title, state, url, format)),
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewSubsidies));