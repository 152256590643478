import React from 'react';
import { connect } from 'react-redux';
import {
    Button, IconButton, Menu, Typography
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { Bar } from 'react-chartjs-2';
import DashboardDrawer from '../../utils/DashboardDrawer';
import {
    getAgroProductionDashboard, getActivitiesCount
} from '../../actions/reports/ReportsActions';
import randomColor from 'randomcolor';
import i18n from '../../i18n/i18n';
import { CircleLoader } from 'react-spinners';
import { getUser } from '../../utils/CommonFunctions';
export class AgroProductionDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {}
        };
    }

    componentDidMount() {
        this.props.getAgroProductionDashboard();
    }

    async componentDidMount() {
        const { getAgroProductionDashboard, getActivitiesCount } = this.props;
        let user = await getUser();
        if (user.id_region != 1) {
            getAgroProductionDashboard();
        } else {
            getActivitiesCount();
        }
        this.setState({ user })
    }

    onPieClick = () => {
        this.props.history.push('/agro_production_dashboard')
    }

    onTableClick = () => {
        this.props.history.push('/agro_production');
    }



    render() {
        const { user } = this.state;
        const { agroProductionItems, agroProductionItemsFetching } = this.props;
        let potpisani = this.confPotpisani;
        if (agroProductionItems && agroProductionItems.potpisani && agroProductionItems.odbijeni) {
            potpisani.labels = [i18n.t('Signed'), i18n.t('Rejected')];
            potpisani.datasets[0].data = agroProductionItems.potpisani.count.asMutable();
            potpisani.datasets[0].data1 = agroProductionItems.potpisani.percent;
            potpisani.datasets[1].data = agroProductionItems.odbijeni.count.asMutable();
            potpisani.datasets[1].data1 = agroProductionItems.odbijeni.percent;
        }

        return (
            <div style={{ 'padding': '20px' }}>
                <div>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center' }}>
                        <CircleLoader
                            color={'#61a50e'}
                            loading={agroProductionItemsFetching}
                        />
                    </div>
                    {agroProductionItems.total > 0 && !agroProductionItemsFetching && user.id_region != 1 &&
                        < div >
                            <Card style={styles.card}>
                                <h1>{i18n.t('Total number of activies')} : {agroProductionItems.total} </h1>
                            </Card>
                            <div style={{ marginTop: '30px' }}>
                                {agroProductionItems && agroProductionItems.potpisani &&
                                    <Card className="dashboard-cards-agronom" raised={true}>
                                        <Typography className="dashboard-title">{i18n.t('Signed/Rejected activities')}:</Typography>
                                        <Bar data={potpisani} options={this.options} />
                                    </Card>
                                }
                            </div>
                            <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} />
                        </div>
                    }
                    {user.id_region === 1 &&
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
                            <h1>{i18n.t('Total number of activities in Serbia')} : {agroProductionItems.totalSerbia} </h1>
                            <h1>{i18n.t('Total number of activities in Nigeria')} : {agroProductionItems.totalNigeria} </h1>
                        </div>
                    }
                     {user.id_region === 1 &&
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
                            <h1>{i18n.t('Total number of activities in Enterprise')} : {agroProductionItems.totalEnterprise} </h1>
                        </div>
                    }
                </div>
            </div>
        )
    }


    potpisaniColor = randomColor()

    odbijeniColor = randomColor()

    confPotpisani = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            label: i18n.t('Signed'),
            backgroundColor: this.potpisaniColor,
            borderColor: this.potpisaniColor,
            borderWidth: 1,
            hoverBackgroundColor: this.potpisaniColor,
            hoverBorderColor: this.potpisaniColor,
        }, {
            data: [],
            data1: [],
            label: i18n.t('Rejected'),
            backgroundColor: this.odbijeniColor,
            borderColor: this.odbijeniColor,
            borderWidth: 1,
            hoverBackgroundColor: this.odbijeniColor,
            hoverBorderColor: this.odbijeniColor,
        }]
    };

    options = {
        legend: {
            position: 'left',
            labels: {
                boxWidth: 10,
                fontSize: 12
            }
        },
        tooltips: {
            mode: 'label',
            callbacks: {

                afterLabel: function (tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex].data1[tooltipItem.index] + '%';
                },

            },
        }
    };

};

function mapStateToProps(state) {
    return {
        agroProductionItems: state.reportsReducer.agroProductionItems,
        agroProductionItemsFetching: state.reportsReducer.agroProductionItemsFetching,
        agroProductionItemsFetchingFailed: state.reportsReducer.agroProductionItemsFetchingFailed
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAgroProductionDashboard: () => dispatch(getAgroProductionDashboard()),
        getActivitiesCount: () => dispatch(getActivitiesCount()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AgroProductionDashboard);

const styles = {
    card: {
        minWidth: 275,
        padding: '10px',
        margin: '10px',
    },
    cardMin: {
        width: '100%',
        padding: '10px',
        margin: '10px',
        position: 'relative',
        float: 'left'
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
};