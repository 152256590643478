import React from 'react';
import { connect } from 'react-redux';
import 'react-table/react-table.css';
import {
    Button, Card, CardHeader, CardContent, Typography, Tooltip, IconButton,Fab,
    Table, TableBody, TableCell, TableHead, TableRow, Modal, CircularProgress
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SearchBar from 'material-ui-search-bar';
import i18n from '../../i18n/i18n';
import * as Icons from '@material-ui/icons';
import { getFdbs } from '../../actions/FDBActions';
import { CircleLoader } from 'react-spinners';
import { exportDocument } from '../../actions/ExportActions';
import Export from '../../utils/Export';
import AddFarmers from '../farmRegistar/AddFarmers';
import { setInitialState } from '../../actions/FarmRegistarActions';
//------------/ako se ikada bude radilo srediti export
const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        height: '45px',
        fontWeight: '400',
        fontSize: 18
    },
    body: {
        fontSize: 16,
    },
    root: {
        padding: '5px',
        textAlign: 'left',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        verticalAlign: 'inherit'
    }
}))(TableCell);
const styles = theme => ({
    button: {
        marginRight: 10
    },
    page: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '20px',
        height: '100%'
    },
    card: {
        width: '100%'
    },
    cardHeader: {
        margin: "0 15px",
        background: theme.palette.secondary.light,
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        justifyContent: 'center'
    },
    panel: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#DCDCDC'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    search: {
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, 0)',
        width: '40%',
        height: 40,
        borderRadius: '25px'
    },
    typography: {
        color: '#fff'
    },
    table: {
        width: '96%'
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    fabProgress: {
        position: 'absolute',
        top: -6,
        left: '18px',
        zIndex: 1,
    },
    wrapper: {
        position: 'absolute',
        top: `50%`,
        left: `50%`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
});
export class ViewFDB extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            add: false,
        };
    }

    handleSearch = search => {
        this.setState({ searchValue: search }, () => {
            this.state.searchValue && this.state.searchValue !== '' ? this.props.getFdbs(this.state.searchValue)
                : this.setState({
                    fdbs: []
                })
        })
    }
    onExportClick = (docType) => {
        this.setState({ export: !this.state.export, docType: docType });
    }
    exportFunction = () => {
        this.props.exportDocument(this.state.docTitle, '', 'api/fdb/export', { type: this.state.docType });
        this.setState({ export: !this.state.export });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fdbs !== this.props.fdbs) {
            this.setState({
                fdbs: this.props.fdbs
            });
        }
        if (prevProps.farmPostingSuccess !== this.props.farmPostingSuccess && this.props.farmPostingSuccess === true) {
            this.setState({
                add: false
            });
            this.props.getFdbs(this.state.searchValue);
            this.props.setInitialState('farmPostingSuccess');
        }
    }

    onActivateClick = (fdb) => {
        this.setState({ add: !this.state.add, fdb }, () => {
            this.props.setInitialState('farmerObj');
        });
    };

    render() {
        const { theme, classes } = this.props;
        const { fdbs, fdb } = this.state;
        const fdbRender = fdbs && fdbs.map((fdb, i) => {
            if (!fdb) return null;
            return <TableRow key={i}>
                <CustomTableCell>{fdb.id}</CustomTableCell>
                <CustomTableCell>{fdb.naziv}</CustomTableCell>
                <CustomTableCell>{fdb.active === false ? i18n.t("No") : i18n.t("Yes")}</CustomTableCell>
                <CustomTableCell>
                    <Tooltip title={i18n.t("Activate")}>
                        <IconButton aria-label="activate"
                            color="secondary"
                            disabled={fdb.active}
                            onClick={() => this.onActivateClick(fdb)}
                        >
                            <Icons.Edit />
                        </IconButton>
                    </Tooltip>
                </CustomTableCell>
            </TableRow>
        })
        return (
            <div className={classes.page}>
                <Card className={classes.card}>
                    <CardHeader
                        className={classes.cardHeader}
                        title={
                            <div className={classes.row}>
                                <Typography className={classes.typography} variant='headline'>{i18n.t('FDB\'s Access & Manage Right')}</Typography>
                                <SearchBar
                                    className={classes.search}
                                    placeholder={i18n.t('Search')}
                                    cancelOnEscape={true}
                                    onChange={(searchValue) => !searchValue && this.handleSearch('')}
                                    onRequestSearch={(searchValue) => this.handleSearch(searchValue)}
                                    onCancelSearch={() => this.handleSearch('')}
                                    closeIcon={!this.props.fdbFetching ? <Icons.Clear style={{ color: '#9e9e9e' }} /> :
                                        <CircleLoader
                                            color={'#61a50e'}
                                            loading={this.props.fdbFetching}
                                            size={25}
                                        />}
                                />
                            </div>
                        }
                        action={
                            <div >
                                <Button
                                    onClick={() => this.onExportClick('csv')}
                                    variant="contained"
                                    aria-label="add"
                                    className={classes.button}
                                >
                                    Export to CSV
                                </Button>
                                <Button
                                    onClick={() => this.onExportClick('rtf')}
                                    variant="contained"
                                    aria-label="add"
                                    className={classes.button}
                                >
                                    Export to Printable
                                </Button>
                            </div>
                        }

                    />
                    <CardContent className={classes.content}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <CustomTableCell >{i18n.t('ID')}</CustomTableCell>
                                    <CustomTableCell >{i18n.t('Username')}</CustomTableCell>
                                    <CustomTableCell >{i18n.t('Active')}</CustomTableCell>
                                    <CustomTableCell >{i18n.t('Activate')}</CustomTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {fdbRender}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
                {this.state.export && <Export
                    export={this.state.export}
                    onTextChange={({ title }) => {
                        this.setState({ docTitle: title });
                    }}
                    handleClose={this.onExportClick}
                    exportDoc={this.exportFunction} />
                }
                {this.props.exportFetching && <Modal
                    open={this.props.exportFetching}
                >
                    <div className={classes.wrapper}>
                        <div>
                            <Fab
                                color="primary"
                            >
                                {`${(this.props.exportProgress / 1000000).toFixed(2)} MB`}
                            </Fab>
                            <CircularProgress size={68} color="secondary" className={classes.fabProgress} />
                        </div>
                        <br />
                        <Typography variant='subheading'>Downloading...</Typography>
                    </div>
                </Modal>
                }
                {this.state.add && <AddFarmers add={this.state.add}
                    handleClose={this.onActivateClick}
                    fdb={{ id: fdb.id, naziv: fdb.naziv }} />}
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        fdbs: state.fdbReducer.fdbs,
        fdbFetching: state.fdbReducer.fdbFetching,
        farmPostingSuccess: state.farmRegistarReducer.farmPostingSuccess,
        exportFetching: state.exportReducer.exportFetching,
        exportProgress: state.exportReducer.exportProgress
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getFdbs: (search) => dispatch(getFdbs(search)),
        setInitialState: (component) => dispatch(setInitialState(component)),
        exportDocument: (title, state, url, fil) => dispatch(exportDocument(title, state, url, fil))
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewFDB));