/**
 * Created by pajicv on 12/17/18.
 */
import React from 'react';
import ZoomToState  from './ZoomToState';
import ZoomToLocalGovernment from './ZoomToLocalGovernment';

const ZoomToAdministrative = ({states, selectedStateId, onStateSelected, localGovernments, selectedLocalGovId, onLocalGovSelected }) => (
    <div>
        <ZoomToState states={states} selectedStateId={selectedStateId} onStateSelected={onStateSelected}/>
        <ZoomToLocalGovernment localGovernments={localGovernments} selectedLocalGovId={selectedLocalGovId} onLocalGovSelected={onLocalGovSelected}/>
    </div>
);

export default ZoomToAdministrative;