import axios from '../utils/AxiosWrapper';
export const Type = {
    //LOCAL_GOVR
    GET_LOCAL_GOV_CALL: 'GET_LOCAL_GOV_CALL',
    GET_LOCAL_GOV_SUCCESS: 'GET_LOCAL_GOV_SUCCESS',
    GET_LOCAL_GOV_FAILED: 'GET_LOCAL_GOV_FAILED',

    //TITLE
    GET_TITLE_CALL: 'GET_TITLE_CALL',
    GET_TITLE_SUCCESS: 'GET_TITLE_SUCCESS',
    GET_TITLE_FAILED: 'GET_TITLE_FAILED',

    //MARITAL
    GET_MARITAL_STATUS_CALL: 'GET_MARITAL_STATUS_CALL',
    GET_MARITAL_STATUS_SUCCESS: 'GET_MARITAL_STATUS_SUCCESS',
    GET_MARITAL_STATUS_FAILED: 'GET_MARITAL_STATUS_FAILED',

    //IDENTIFICATION_DOC
    GET_IDENTIFICATION_DOC_CALL: 'GET_IDENTIFICATION_DOC_CALL',
    GET_IDENTIFICATION_DOC_SUCCESS: 'GET_IDENTIFICATION_DOC_SUCCESS',
    GET_IDENTIFICATION_DOC_FAILED: 'GET_IDENTIFICATION_DOC_FAILED',

    ADD_FARM_OFFICE_CALL: 'ADD_FARM_OFFICE_CALL',
    ADD_FARM_OFFICE_SUCCESS: 'ADD_FARM_OFFICE_SUCCESS',
    ADD_FARM_OFFICE_FAILED: 'ADD_FARM_OFFICE_FAILED',

    GET_FARM_OFFICE_CALL: 'GET_FARM_OFFICE_CALL',
    GET_FARM_OFFICE_SUCCESS: 'GET_FARM_OFFICE_SUCCESS',
    GET_FARM_OFFICE_FAILED: 'GET_FARM_OFFICE_FAILED',

    GET_FARMDASHBOARD_CALL: 'GET_FARMDASHBOARD_CALL',
    GET_FARMDASHBOARD_SUCCESS: 'GET_FARMDASHBOARD_SUCCESS',
    GET_FARMDASHBOARD_FAILED: 'GET_FARMDASHBOARD_FAILED',

    GET_FARMERS_COUNT_CALL: 'GET_FARMERS_COUNT_CALL',
    GET_FARMERS_COUNT_SUCCESS: 'GET_FARMERS_COUNT_SUCCESS',
    GET_FARMERS_COUNT_FAILED: 'GET_FARMERS_COUNT_FAILED',

    SET_INITIAL_STATE: 'SET_INITIAL_STATE',
    SET_FARMER_ROW_IN_REDUCER: 'SET_FARMER_ROW_IN_REDUCER',

    //ANCHOR
    ADD_ANCHOR_CALL: 'ADD_ANCHOR_CALL',
    ADD_ANCHOR_SUCCESS: 'ADD_ANCHOR_SUCCESS',
    ADD_ANCHOR_FAILED: 'ADD_ANCHOR_FAILED',
    DELETE_ANCHOR_CALL: 'DELETE_ANCHOR_CALL',
    DELETE_ANCHOR_SUCCESS: 'DELETE_ANCHOR_SUCCESS',
    DELETE_ANCHOR_FAILED: 'DELETE_ANCHOR_FAILED',
    GET_ANCHORED_USERS_CALL: 'GET_ANCHORED_USERS_CALL',
    GET_ANCHORED_USERS_SUCCESS: 'GET_ANCHORED_USERS_SUCCESS',
    GET_ANCHORED_USERS_FAILED: 'GET_ANCHORED_USERS_FAILED',
    //ANCHOR DROPDOWN
    GET_ANCHOR_DROP_DOWN_CALL: 'GET_ANCHOR_DROP_DOWN_CALL',
    GET_ANCHOR_DROP_DOWN_SUCCESS: 'GET_ANCHOR_DROP_DOWN_SUCCESS',
    GET_ANCHOR_DROP_DOWN_FAILED: 'GET_ANCHOR_DROP_DOWN_FAILED',

    GET_USERS_CALL: 'GET_USERS_CALL',
    GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
    GET_USERS_FAILED: 'GET_USERS_FAILED',

    //BVN 
    BVN_VALIDATION_CALL: 'BVN_VALIDATION_CALL',
    BVN_VALIDATION_SUCCESS: 'BVN_VALIDATION_SUCCESS',
    BVN_VALIDATION_FAILED: 'BVN_VALIDATION_FAILED',

    //MYFarmers
    GET_MY_FARM_OFFICE_CALL: 'GET_MY_FARM_OFFICE_CALL',
    GET_MY_FARM_OFFICE_SUCCESS: 'GET_MY_FARM_OFFICE_SUCCESS',
    GET_MY_FARM_OFFICE_FAILED: 'GET_MY_FARM_OFFICE_FAILED',

    GET_ANCHOR_FOR_FARMER_CALL: 'GET_ANCHOR_FOR_FARMER_CALL',
    GET_ANCHOR_FOR_FARMER_SUCCESS: 'GET_ANCHOR_FOR_FARMER_SUCCESS',
    GET_ANCHOR_FOR_FARMER_FAILED: 'GET_ANCHOR_FOR_FARMER_FAILED',

};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function setRowInReducer(rowInfo, name) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_FARMER_ROW_IN_REDUCER,
            rowInfo: rowInfo,
            name: name
        })
    }
}


export function getLocalGov(id_state) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_LOCAL_GOV_CALL
        });

        axios.get(`api/katastarska_opstina/drop_down${id_state ? '?id_opstina=' + id_state : ''}`)
            .then(function (response) {
                dispatch({
                    type: Type.GET_LOCAL_GOV_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_LOCAL_GOV_FAILED
                });
            });
    }
}

export function getLocalGovByState(stateId) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_LOCAL_GOV_CALL
        });

        axios.get('api/katastarska_opstina/drop_down?id_opstina=' + stateId)
            .then(function (response) {
                dispatch({
                    type: Type.GET_LOCAL_GOV_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_LOCAL_GOV_FAILED
                });
            });
    }
}

export function getTitle() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_TITLE_CALL
        });

        axios.get('api/titula/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_TITLE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_TITLE_FAILED
                });
            });
    }
}

export function verifyBVN(bvn) {
    return (dispatch) => {
        dispatch({
            type: Type.BVN_VALIDATION_CALL
        });

        return axios.get("/verifyBVN?bvn=" + bvn)
            .then(function (response) {
                dispatch({
                    type: Type.BVN_VALIDATION_SUCCESS,
                    data: response
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.BVN_VALIDATION_FAILED,
                });
            });
    }
}

export function getMaritalStatus() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_MARITAL_STATUS_CALL
        });

        axios.get('api/bracni_status/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_MARITAL_STATUS_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MARITAL_STATUS_FAILED
                });
            });
    }
}

export function getIdentificationDoc() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_IDENTIFICATION_DOC_CALL
        });

        axios.get('api/identifikacioni_dokument/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_IDENTIFICATION_DOC_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_IDENTIFICATION_DOC_FAILED
                });
            });
    }
}

export function addFarmOffice(first_name, last_name, pol, id_mesto_rodjenja, datum_rodjenja, username, password, phone, email, srednje_ime, id_state, id_katastarska_opstina, bpg, address,
    id_titula, id_bracni_status, id_identifikacioni_dokument, fdb, id_cos, id_branch, id_anchor, id_agent) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_FARM_OFFICE_CALL
        });

        axios.post('api/farmRegistar/create', {
            name: first_name,
            lastname: last_name,
            pol: pol,
            id_mesto_rodjenja: id_mesto_rodjenja,
            datum_rodjenja: datum_rodjenja,
            username: username,
            password: password,
            phone: phone,
            email: email,
            srednje_ime: srednje_ime,
            id_opstina: id_state,
            id_katastarska_opstina: id_katastarska_opstina,
            bpg: bpg,
            address: address,
            id_titula: id_titula,
            id_bracni_status: id_bracni_status,
            id_identifikacioni_dokument: id_identifikacioni_dokument,
            fdb: fdb,
            id_cos: id_cos,
            id_branch: id_branch,
            id_anchor: id_anchor,
            id_agent: id_agent
        })
            .then(function (response) {
                dispatch({
                    type: Type.ADD_FARM_OFFICE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_FARM_OFFICE_FAILED
                });
            });
    }
}

export function getFarmOffices(state, instance, hash) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_FARM_OFFICE_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered,
                blacklisted: hash
            }
        };
        axios.get('api/farmRegistar/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_FARM_OFFICE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_FARM_OFFICE_FAILED
                });
            });
    }
}

export function getMyFarmOffices(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_MY_FARM_OFFICE_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/farmRegistar/my_farmers_read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_MY_FARM_OFFICE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MY_FARM_OFFICE_FAILED
                });
            });
    }
}

export function getFarmerDashboard(filters, id_region) {
    return (dispatch) => {
        dispatch({
            type: Type.GET_FARMDASHBOARD_CALL,
        });

        return axios.get('api/farmRegistar/dashboard', { params: { filters, id_region } })
            .then(function (response) {
                dispatch({
                    type: Type.GET_FARMDASHBOARD_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_FARMDASHBOARD_FAILED
                });
            });
    }
}

export function getFarmersCount() {
    return (dispatch) => {
        dispatch({
            type: Type.GET_FARMERS_COUNT_CALL,
        });

        return axios.get('api/reportSuperAdmin/farmers')
            .then(function (response) {
                dispatch({
                    type: Type.GET_FARMERS_COUNT_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_FARMERS_COUNT_FAILED
                });
            });
    }
}

export function addAnchor(id_klijent_anchor, selected) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_ANCHOR_CALL
        });
        axios.post('api/farmRegistar/addAnchor', { id_klijent_anchor, selected })
            .then(function (response) {
                dispatch(getAnchorDropdown());
                dispatch({
                    type: Type.ADD_ANCHOR_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_ANCHOR_FAILED
                });
            });
    }
}

export function deleteAnchor(id_klijent_anchor) {
    return (dispatch) => {
        dispatch({
            type: Type.DELETE_ANCHOR_CALL,
        });
        axios.post(`api/farmRegistar/deleteAnchor`, { id_klijent_anchor })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_ANCHOR_SUCCESS,
                    data: response.data,
                });
                dispatch(getAnchorDropdown());
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_ANCHOR_FAILED
                });
            });
    }
}

export function getAnchoredUsers(id_klijent_anchor) {
    return (dispatch) => {
        dispatch({
            type: Type.GET_ANCHORED_USERS_CALL,
        });
        axios.post(`api/farmRegistar/getAnchoredUsers`, { id_klijent_anchor })
            .then(function (response) {
                dispatch({
                    type: Type.GET_ANCHORED_USERS_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_ANCHORED_USERS_FAILED
                });
            });
    }
}

export function getUsers(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_USERS_CALL
        });


        axios.get('api/korisnik/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_USERS_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_USERS_FAILED
                });
            });
    }
}

export function getAnchorDropdown() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_ANCHOR_DROP_DOWN_CALL
        });

        axios.get('api/farmRegistar/drop_down_anchor')
            .then(function (response) {
                dispatch({
                    type: Type.GET_ANCHOR_DROP_DOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_ANCHOR_DROP_DOWN_FAILED
                });
            });
    }
}

export function addAnchorForFarmer(id_farmer) {
    return (dispatch) => {
        dispatch({
            type: Type.GET_ANCHOR_FOR_FARMER_CALL,
        });
        axios.post(`api/farmRegistar/addAnchorForFarmer`, { id_farmer })
            .then(function (response) {
                dispatch({
                    type: Type.GET_ANCHOR_FOR_FARMER_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_ANCHOR_FOR_FARMER_FAILED
                });
            });
    }
}



