/**
 * Created by pajicv on 7/9/18.
 */

export const getUser = () => {

    let user = localStorage.getItem('user');

    let userObject = null;

    try {
        userObject = JSON.parse(user);
    } catch(err) {
        console.log(err);
    }

    return userObject;

};