import React from 'react';
import { connect } from 'react-redux';
import 'react-table/react-table.css';
import 'react-toastify/dist/ReactToastify.css';
import { setInitialState, setRowInReducer, getApplicationStatuses, updateSubsidie } from '../../../actions/financial/ApplicationActions';
import i18n from '../../../i18n/i18n';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import { withRouter } from 'react-router-dom'
import { Button, IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
export class EditSubsidies extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            applicationsObj: this.props.applicationsObj
        };
    }

    componentDidMount() {
        this.props.getApplicationStatuses();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.appPostingFailed == true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('appPostingFailed');
        }
    }

    editSubsidie() {
        if (this.state.applicationsObj.value_approved && parseInt(this.state.applicationsObj.value_asked).toFixed() < parseInt(this.state.applicationsObj.value_approved).toFixed()) {
            toast.error(i18n.t('Approved amount of money can\'t be bigger then amount asked!'), {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        } else if (this.state.applicationsObj.value && parseInt(this.state.applicationsObj.value).toFixed() > parseInt(this.state.applicationsObj.value_approved).toFixed()) {
            toast.error(i18n.t('Paid amount of money can\'t be bigger then amount approved!'), {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        }
        else {
            this.props.updateSubsidie(this.state.applicationsObj);
        }
    }

    handleChangeValue = name => event => {
        let obj = Object.assign({}, this.state.applicationsObj);
        obj[name] = event.target.value;
        this.setState({ applicationsObj: obj });
    };



    render() {
        let appStatus = [];
        if (this.props.appStatuses && !this.props.appStatusesFetching) {
            appStatus = this.props.appStatuses.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        } return (
            <Dialog
                open={this.props.add}
                onClose={this.props.handleClose}
                disableBackdropClick
                maxWidth="sm"
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('Edit subsidies')}
                    <IconButton onClick={this.props.handleClose}>
                        <span className="icon-cancel-circle closeIcon" />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                        <Grid item>
                            <TextField
                                label={i18n.t('Application status')}
                                required
                                select
                                SelectProps={{
                                    native: true,
                                }}
                                style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                value={this.state.applicationsObj.id_aplikacija_status}
                                onChange={this.handleChangeValue('id_aplikacija_status')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                                <option value='' />
                                {appStatus}
                            </TextField>
                        </Grid>
                        <Grid item>
                            <TextField
                                type="number"
                                onChange={this.handleChangeValue('value_approved')}
                                label={i18n.t('Amount approved(NGN)')}
                                style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                value={this.state.applicationsObj.value_approved ? parseInt(this.state.applicationsObj.value_approved).toFixed() : null}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                type="number"
                                onChange={this.handleChangeValue('value')}
                                label={i18n.t('Amount paid(NGN)')}
                                style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                value={this.state.applicationsObj.value ? parseInt(this.state.applicationsObj.value).toFixed() : null}
                            />
                        </Grid>
                        <TextField
                            multiline
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            id="full-width"
                            margin="normal"
                            label={i18n.t('Description')}
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                            value={this.state.applicationsObj.opis}
                        />
                        <Grid item xs>
                            <Button onClick={() => this.editSubsidie()}
                                style={{ backgroundColor: '#58B4E5', color: 'white' }}
                            >
                                {i18n.t('Save')}
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                        </Grid>
                        <Grid item xs>
                            <Button onClick={this.props.handleClose} style={{ backgroundColor: '#58B4E5', color: 'white' }}
                            >
                                {i18n.t('Cancel')}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        )
    }
};

function mapStateToProps(state) {
    return {
        applicationsObj: state.applicationReducer.applicationsObj,
        appStatuses: state.applicationReducer.appStatuses,
        appStatusesFetching: state.applicationReducer.appStatusesFetching,
        appStatusesFetchingFailed: state.applicationReducer.appStatusesFetchingFailed,
        appPostingFailed: state.applicationReducer.appPostingFailed,

    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInitialState: (component) => dispatch(setInitialState(component)),
        setRowInReducer: (rowInfo, name) => dispatch(setRowInReducer(rowInfo, name)),
        getApplicationStatuses: () => dispatch(getApplicationStatuses()),
        updateSubsidie: (applicationsObj) => dispatch(updateSubsidie(applicationsObj)),
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(EditSubsidies));