import axios from '../utils/AxiosWrapper';
export const Type = {

    GET_FDBS_CALL: 'GET_FDBS_CALL',
    GET_FDBS_SUCCESS: 'GET_FDBS_SUCCESS',
    GET_FDBS_FAILED: 'GET_FDBS_FAILED',

    SET_INITIAL_STATE: 'SET_INITIAL_STATE',

};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function getFdbs(search) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_FDBS_CALL
        });

        var config = {
            params: {
                search
            }
        };
        axios.get('api/fdb/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_FDBS_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_FDBS_FAILED
                });
            });
    }
}




