import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import { Button, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setInitialState, addRegionLayerGroup } from '../../actions/SuperAdminActions';
import { getRegionType } from '../../actions/control_panel/RegionActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export class AddRegionLayerGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            regionLayerGroupObj: this.props.regionLayerGroupObj,
        }
    }

    componentDidMount() {
        if (this.props.regionType.length === 0) {
            this.props.getRegionType();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.regionLayerGroupPostingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('regionLayerGroupPostingFailed');
        }
    }

    addRegionLayer() {
        if (this.state.regionLayerGroupObj.naziv &&
            this.state.regionLayerGroupObj.id_region
            // &&
            // this.state.regionLayerGroupObj.id_grupe
        ) {
            this.props.addRegionLayerGroup(
                this.state.regionLayerGroupObj
            )

        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    handleChangeValue = name => event => {
        let obj = Object.assign({}, this.state.regionLayerGroupObj);
        obj[name] = event.target.value;
        this.setState({ regionLayerGroupObj: obj });
    };


    handleChangeCheckbox = name => event => {
        let obj = Object.assign({}, this.state.regionLayerGroupObj);
        obj[name] = event.target.checked;
        this.setState({ regionLayerGroupObj: obj });
    };


    render() {

        let regionType = [];
        if (this.props.regionType && !this.props.regionTypeFetching) {
            regionType = this.props.regionType.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }

        let layer_group_id;
        if (this.state.regionLayerGroupObj.id_region == 13) {
            layer_group_id = this.props.regionLayerGroupsNigeria.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        } else if (this.state.regionLayerGroupObj.id_region != 13) {
            layer_group_id = this.props.regionLayerGroupsSerbia.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        return (
            <div style={{ padding: 20 }}>

                {/* <Paper className="roundedBoxMaterial" zdepth={4}> */}
                <Dialog
                    open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="xs"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit region layer group') : i18n.t('Add region layer group')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-material-group">
                            <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                                <Grid item xs>
                                    <TextField
                                        required
                                        label={i18n.t('Title')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.state.regionLayerGroupObj.naziv}
                                        onChange={this.handleChangeValue('naziv')}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        select
                                        label={i18n.t('Region')}
                                        value={this.state.regionLayerGroupObj.id_region}
                                        onChange={this.handleChangeValue('id_region')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        required
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <option value='' />
                                        {/* <option key={13} value={13}>{i18n.t('Nigeria')}</option>
                                        <option key={2} value={2}>{i18n.t('Serbia')}</option> */}
                                        {regionType}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        // required
                                        label={i18n.t('Order in tree')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.state.regionLayerGroupObj.order_in_tree}
                                        onChange={this.handleChangeValue('order_in_tree')}
                                    />
                                </Grid>
                                {this.state.regionLayerGroupObj.id_region &&
                                    <Grid item>
                                        <TextField
                                            select
                                            label={i18n.t('Layer group ID')}
                                            value={this.state.regionLayerGroupObj.id_grupe}
                                            onChange={this.handleChangeValue('id_grupe')}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            required
                                            margin="normal"
                                            style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        >
                                            <option value='' />
                                            {layer_group_id}
                                        </TextField>
                                    </Grid>
                                }
                                <Grid>
                                    <FormControlLabel
                                        style={{ marginTop: '10px', marginBottom: '10px' }}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={this.state.regionLayerGroupObj.vidljivost}
                                                onChange={this.handleChangeCheckbox('vidljivost')}
                                                value="vidljivost"
                                            />
                                        }
                                        label={i18n.t('Visibility')}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Button
                                        style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white' }}
                                        onClick={() => this.addRegionLayer()}
                                    >
                                        {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                                    </Button>
                                </Grid>

                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
                {/* </Paper> */}
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        regionLayerGroupObj: state.superAdminReducer.regionLayerGroupObj,
        regionLayerGroupPostingFailed: state.superAdminReducer.regionLayerGroupPostingFailed,
        regionLayerGroupsNigeria: state.superAdminReducer.regionLayerGroupsNigeria,
        regionLayerGroupsSerbia: state.superAdminReducer.regionLayerGroupsSerbia,

        regionType: state.regionReducer.regionType,
        regionTypeFetching: state.regionReducer.regionTypeFetching,
        regionTypeFetchingFailed: state.regionReducer.regionTypeFetchingFailed,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInitialState: (component) => dispatch(setInitialState(component)),
        addRegionLayerGroup: (regionLayerGroupObj) => dispatch(addRegionLayerGroup(regionLayerGroupObj)),
        getRegionType: () => dispatch(getRegionType()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddRegionLayerGroup)