import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setInitialState, getRegionType, getSeasonType, addLayer } from '../../../actions/control_panel/LayerActions';

export class AddLayers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            layerObj: this.props.layerObj,
        }
    }

    componentDidMount() {
        if (this.props.regionType.length === 0) {
            this.props.getRegionType();
        }
        if (this.props.seasonType.length === 0) {
            this.props.getSeasonType();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.layerPostingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('layerPostingFailed');
        }
    }

    addLayer() {
        if (this.state.layerObj.naziv &&
            this.state.layerObj.id_region &&
            this.state.layerObj.id_sezona) {
            this.props.addLayer(
                this.state.layerObj,
            )
        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    handleChangeValue = name => event => {
        let obj = Object.assign({}, this.state.layerObj);
        obj[name] = event.target.value;
        this.setState({ layerObj: obj });
    };

    render() {
        let regionType = [];
        if (this.props.regionType && !this.props.regionTypeFetching) {
            regionType = this.props.regionType.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let seasonType = [];
        if (this.props.seasonType && !this.props.seasonTypeFetching) {
            seasonType = this.props.seasonType.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }

        return (
            <div style={{ padding: 20 }}>

                <Dialog open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit layer') : i18n.t('Add layer')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-layer">
                            <Grid container justify="space-between" alignContent="space-between" >
                                <Grid item>
                                    <TextField required
                                        label={i18n.t('Title')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        value={this.state.layerObj.naziv}
                                        onChange={this.handleChangeValue('naziv')}
                                    />
                                </Grid>
                                <Grid item >
                                    <TextField required
                                        label={i18n.t('Type of region')}
                                        select
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        defaultValue={this.state.layerObj.id_region}
                                        onChange={this.handleChangeValue('id_region')} >
                                        <option value='' />
                                        {regionType}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField required
                                        label={i18n.t('Type of season')}
                                        select
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        defaultValue={this.state.layerObj.id_sezona}
                                        onChange={this.handleChangeValue('id_sezona')} >
                                        <option value='' />
                                        {seasonType}
                                    </TextField>
                                </Grid>
                                <Grid xs={12}>
                                    <Typography>
                                        ID: {this.state.layerObj.id_sezona}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button style={{ alignSelf: 'center', backgroundColor: '#58B4E5', color: 'white', marginTop: '20px', marginBottom: '10px' }}
                                        onClick={() => this.addLayer()}
                                        disabled={this.props.layerPosting} >
                                        {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        layerPosting: state.layerReducerCP.layerPosting,
        layerPostingSuccess: state.layerReducerCP.layerPostingSuccess,
        layerObj: state.layerReducerCP.layerObj,
        layerPostingFailed: state.layerReducerCP.layerPostingFailed,

        regionType: state.layerReducerCP.regionType,
        regionTypeFetching: state.layerReducerCP.regionTypeFetching,
        regionTypeFetchingFailed: state.layerReducerCP.regionTypeFetchingFailed,

        seasonType: state.layerReducerCP.seasonType,
        seasonTypeFetching: state.layerReducerCP.seasonTypeFetching,
        seasonTypeFetchingFailed: state.layerReducerCP.seasonTypeFetchingFailed,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInitialState: (component) => dispatch(setInitialState(component)),

        addLayer: (layerObj) => dispatch(addLayer(layerObj)),

        getRegionType: () => dispatch(getRegionType()),
        getSeasonType: () => dispatch(getSeasonType()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddLayers);