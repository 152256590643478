import axios from '../../utils/AxiosWrapper';
export const Type = {

    //ADD
    ADD_LAYER_CALL: 'ADD_LAYER_CALL',
    ADD_LAYER_SUCCESS: 'ADD_LAYER_SUCCESS',
    ADD_LAYER_FAILED: 'ADD_LAYER_FAILED',

    //GET
    GET_LAYER_READ_CALL: 'GET_LAYER_READ_CALL',
    GET_LAYER_READ_SUCCESS: 'GET_LAYER_READ_SUCCESS',
    GET_LAYER_READ_FAILED: 'GET_LAYER_READ_FAILED',

    //DELETE
    DELETE_LAYER_CALL: 'DELETE_LAYER_CALL',
    DELETE_LAYER_SUCCESS: 'DELETE_LAYER_SUCCESS',
    DELETE_LAYER_FAILED: 'DELETE_LAYER_FAILED',

    //REGION
    GET_REGION_TYPE_CALL: 'GET_REGION_TYPE_CALL',
    GET_REGION_TYPE_SUCCESS: 'GET_REGION_TYPE_SUCCESS',
    GET_REGION_TYPE_FAILED: 'GET_REGION_TYPE_FAILED',

    //SEASON
    GET_SEASON_TYPE_CALL: 'GET_SEASON_CALL',
    GET_SEASON_TYPE_SUCCESS: 'GET_SEASON_SUCCESS',
    GET_SEASON_TYPE_FAILED: 'GET_SEASON_FAILED',

    //SET
    SET_LAYER_ROW_IN_REDUCER: 'SET_LAYER_ROW_IN_REDUCER',
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',
};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function getRegionType() {
    return (dispatch) => {
        dispatch({
            type: Type.GET_REGION_TYPE_CALL
        });

        axios.get('api/region/filter_region')
            .then(function (response) {
                dispatch({
                    type: Type.GET_REGION_TYPE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_REGION_TYPE_FAILED
                });
            });
    }
}

export function getSeasonType() {
    return (dispatch) => {
        dispatch({
            type: Type.GET_SEASON_TYPE_CALL
        });

        axios.get('api/season/filter_sezona')
            .then(function (response) {
                dispatch({
                    type: Type.GET_SEASON_TYPE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_SEASON_TYPE_FAILED
                });
            });
    }
}


export function getLayers(state, instance) {
    return (dispatch) => {
        dispatch({
            type: Type.GET_LAYER_READ_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/layer/read_cp', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_LAYER_READ_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_LAYER_READ_FAILED
                });
            });
    }
}

export function deleteLayer(selection) {
    return (dispatch) => {
        dispatch({
            type: Type.DELETE_LAYER_CALL,
        });

        axios.post(`api/layer/delete`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_LAYER_SUCCESS,
                    data: response.data,
                    forDelete: selection
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_LAYER_FAILED
                });
            });
    }
}

export function addLayer(layerObj) {
    return (dispatch) => {
        dispatch({
            type: Type.ADD_LAYER_CALL
        });

        axios.post('api/layer/create', layerObj)
            .then(function (response) {
                dispatch({
                    type: Type.ADD_LAYER_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_LAYER_FAILED
                });
            });
    }
}


export function setRowInReducer(rowInfo) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_LAYER_ROW_IN_REDUCER,
            rowInfo: rowInfo
        })
    }
}