export const styles = t => ({
    htmlTooltip: {
        maxWidth: '600px',
        border: '1px solid grey'
    },
    list_item: {
        padding: 0,
        paddingLeft: 30,
        paddingRight: 4
    },
    nested: {
        paddingLeft: 4
    },
    drawer: {
        width: 360,
        flexShrink: 0,
        whiteSpace: "nowrap"
    },
    drawerOpen: {
        width: 360,
        transition: t.transitions.create("width", {
            easing: t.transitions.easing.sharp,
            duration: t.transitions.duration.enteringScreen
        }),
        overflowX: "hidden"
    },
    drawerClose: {
        transition: t.transitions.create("width", {
            easing: t.transitions.easing.sharp,
            duration: t.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
        width: 0
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px"
    },
    toggleButtonOpen: {
        zIndex: 1e3,
        position: "absolute",
        top: "50%",
        right: 360,
        width: 24,
        height: 60,
        backgroundColor: "rgba(228, 228, 228, 1)",
        transition: t.transitions.create("right", {
            easing: t.transitions.easing.sharp,
            duration: t.transitions.duration.enteringScreen
        }),
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    toggleButtonClose: {
        zIndex: 1e3,
        position: "absolute",
        top: "50%",
        width: 24,
        height: 60,
        right: 1,
        backgroundColor: "rgba(228, 228, 228, 1)",
        transition: t.transitions.create("right", {
            easing: t.transitions.easing.sharp,
            duration: t.transitions.duration.leavingScreen
        }),
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    searchButtonOpen: {
        zIndex: 1e3,
        position: "absolute",
        top: 11,
        right: 370,
        width: 50,
        height: 50,
        backgroundColor: "rgba(228, 228, 228, 1)",
        transition: t.transitions.create("right", {
            easing: t.transitions.easing.sharp,
            duration: t.transitions.duration.enteringScreen
        }),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#fff",
        borderRadius: 5,
        border: "1px solid rgba(0,0,0,1)"
    },
    searchButtonClose: {
        zIndex: 1e3,
        position: "absolute",
        top: 11,
        width: 50,
        height: 50,
        right: 10,
        backgroundColor: "rgba(228, 228, 228, 1)",
        transition: t.transitions.create("right", {
            easing: t.transitions.easing.sharp,
            duration: t.transitions.duration.leavingScreen
        }),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#fff",
        borderRadius: 5,
        border: "1px solid rgba(0,0,0,1)"
    },
    seasonSelectOpen: {
        zIndex: 1e3,
        position: "absolute",
        top: 11,
        right: 430,
        backgroundColor: "rgba(228, 228, 228, 1)",
        transition: t.transitions.create("right", {
            easing: t.transitions.easing.sharp,
            duration: t.transitions.duration.enteringScreen
        }),
        width: 256,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        background: "#fff",
        borderRadius: 5,
        border: "1px solid rgba(0,0,0,1)",
    },
    seasonSelectClose: {
        zIndex: 1e3,
        position: "absolute",
        top: 11,
        right: 70,
        backgroundColor: "rgba(228, 228, 228, 1)",
        transition: t.transitions.create("right", {
            easing: t.transitions.easing.sharp,
            duration: t.transitions.duration.leavingScreen
        }),
        width: 256,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        background: "#fff",
        borderRadius: 5,
        border: "1px solid rgba(0,0,0,1)",
    },
    seasonalForecastButtonOpen: {
        zIndex: 1e3,
        position: "absolute",
        top: 11,
        // right: 696,
        right: 432,
        width: 50,
        height: 50,
        backgroundColor: "rgba(228, 228, 228, 1)",
        transition: t.transitions.create("right", {
            easing: t.transitions.easing.sharp,
            duration: t.transitions.duration.enteringScreen
        }),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#fff",
        borderRadius: 5,
        border: "1px solid rgba(0,0,0,1)"
    },
    seasonalForecastButtonClose: {
        zIndex: 1e3,
        position: "absolute",
        top: 11,
        width: 50,
        height: 50,
        // right: 336,
        right: 72,
        backgroundColor: "rgba(228, 228, 228, 1)",
        transition: t.transitions.create("right", {
            easing: t.transitions.easing.sharp,
            duration: t.transitions.duration.leavingScreen
        }),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#fff",
        borderRadius: 5,
        border: "1px solid rgba(0,0,0,1)"
    },
    menuGroupStyle: {
        borderBottom: '1px lightgrey solid',
        // borderLeft: '3px lightgrey solid',
    },
    mapOpen: {
        height: '100vh', width: '100%', width: 'calc(100%)', marginLeft: '0px',

    },
    mapClosed: {
        height: '100vh', width: '100%', width: 'calc(100% - 360px)', marginLeft: '0px',
        transition: t.transitions.create("width", {
            easing: t.transitions.easing.sharp,
            duration: t.transitions.duration.enteringScreen
        }),
    },
    open_button: { backgroundColor: '#58B4E5', color: 'white', marginLeft: '10px' },
    sensor_span: {
        alignSelf: 'center',
        width: '25px',
        height: '25px',
        marginLeft: '4%',
        // backgroundColor: 'lightgrey',
        cursor: 'pointer',
        textAlign: 'center',
        lineHeight: '25px',
        borderRadius: '5px'
    }
});