import React from 'react';
import { Card, withStyles, } from '@material-ui/core';
import axios from '../../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../InfoStyles';

export class InfoET extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.language === 1 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Evapotranspiracija (ET) je termin koji se koristi za označavanje kombinovanih procesa kojima se voda kreće iz zemlje u atmosferu. 
                            Obuhvata isparavanje vode (kretanje vode u vazduh direktno iz zemlje, krošnji drveća i vodenih površina) i transpiraciju (kretanje vode iz zemljišta kroz korenje i stablo na lišće a zatim u vazduh). 
                            Evapotranspiracija je važan deo ciklusa vode i klime, a njeno merenje igra ključnu ulogu u određivanju vremena navodnjavanja poljoprivrednih useva i količinu zalivnih normi.

                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Evapotranspiration (ET) is the term used to refer to the combined processes by which water moves from the soil to the atmosphere. 
                            It includes water evaporation (the movement of water into the air directly from the soil, tree tops and water areas) and transpiration (the movement of water from the soil through the roots 
                            and trunk to the leaves and then into the air).
                            Evapotranspiration is an important part of the water cycle and measured values farmers use for determining the time of irrigation and the amount of irrigation norms.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 4 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Evapotranspiracija (ET) je izraz koji se koristi za označavanje kombiniranih procesa kojima se voda kreće iz tla u atmosferu. Uključuje isparavanje vode (kretanje vode u zrak izravno iz tla, krošnji drveća i vodenih tijela) i transpiraciju (kretanje vode iz tla kroz korijenje i deblo do lišća i potom u zrak).
                            Evapotranspiracija je važan dio ciklusa vode i klime, a njezino mjerenje ima ključnu ulogu u određivanju vremena navodnjavanja poljoprivrednih usjeva i količine normi navodnjavanja.
                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    };
};

export default withStyles(styles)(InfoET);