import axios from '../../utils/AxiosWrapper';
export const Type = {

    //ADD
    ADD_REGION_CALL: 'ADD_REGION_CALL',
    ADD_REGION_SUCCESS: 'ADD_REGION_SUCCESS',
    ADD_REGION_FAILED: 'ADD_REGION_FAILED',

    //GET
    GET_REGION_READ_CALL: 'GET_REGION_READ_CALL',
    GET_REGION_READ_SUCCESS: 'GET_REGION_READ_SUCCESS',
    GET_REGION_READ_FAILED: 'GET_REGION_READ_FAILED',

    //REGION
    GET_REGION_TYPE_CALL: 'GET_REGION_TYPE_CALL',
    GET_REGION_TYPE_SUCCESS: 'GET_REGION_TYPE_SUCCESS',
    GET_REGION_TYPE_FAILED: 'GET_REGION_TYPE_FAILED',

    //SET
    SET_REGION_ROW_IN_REDUCER: 'SET_REGION_ROW_IN_REDUCER',
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',

    //COPY_FROM
    GET_COPY_FROM_CALL: 'GET_COPY_FROM_CALL',
    GET_COPY_FROM_SUCCESS: 'GET_COPY_FROM_SUCCESS',
    GET_COPY_FROM_FAILED: 'GET_COPY_FROM_FAILED',

    //COPY_TO
    GET_COPY_TO_CALL: 'GET_COPY_TO_CALL',
    GET_COPY_TO_SUCCESS: 'GET_COPY_TO_SUCCESS',
    GET_COPY_TO_FAILED: 'GET_COPY_TO_FAILED',

    //LOCK
    LOCK_REGION_CALL: 'LOCK_REGION_CALL',
    LOCK_REGION_SUCCESS: 'LOCK_REGION_SUCCESS',
    LOCK_REGION_FAILED: 'LOCK_REGION_FAILED',

    //COPY_Resources
    COPY_RESOURCES_CALL: 'COPY_RESOURCES_CALL',
    COPY_RESOURCES_SUCCESS: 'COPY_RESOURCES_SUCCESS',
    COPY_RESOURCES_FAILED: 'COPY_RESOURCES_FAILED',


};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function getCopyFrom() {
    return (dispatch) => {
        dispatch({
            type: Type.GET_COPY_FROM_CALL
        });

        axios.get('api/region/copy_from')
            .then(function (response) {
                dispatch({
                    type: Type.GET_COPY_FROM_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_COPY_FROM_FAILED
                });
            });
    }
}

export function getCopyTo() {
    return (dispatch) => {
        dispatch({
            type: Type.GET_COPY_TO_CALL
        });

        axios.get('api/region/copy_to')
            .then(function (response) {
                dispatch({
                    type: Type.GET_COPY_TO_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_COPY_TO_FAILED
                });
            });
    }
}

export function getRegionType() {
    return (dispatch) => {
        dispatch({
            type: Type.GET_REGION_TYPE_CALL
        });

        axios.get('api/region/filter_region')
            .then(function (response) {
                dispatch({
                    type: Type.GET_REGION_TYPE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_REGION_TYPE_FAILED
                });
            });
    }
}

export function getRegions(state, instance) {
    return (dispatch) => {
        dispatch({
            type: Type.GET_REGION_READ_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/region/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_REGION_READ_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_REGION_READ_FAILED
                });
            });
    }
}

export function addRegion(regionObj) {
    return (dispatch) => {
        dispatch({
            type: Type.ADD_REGION_CALL
        });

        axios.post('api/region/create', regionObj)
            .then(function (response) {
                dispatch({
                    type: Type.ADD_REGION_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_REGION_FAILED
                });
            });
    }
}

export function lockRegion(regionObj) {
    return (dispatch) => {
        dispatch({
            type: Type.LOCK_REGION_CALL
        });

        axios.post('api/region/lock', regionObj)
            .then(function (response) {
                dispatch({
                    type: Type.LOCK_REGION_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.LOCK_REGION_FAILED
                });
            });
    }
}

export function copyResources(from, to) {
    return (dispatch) => {
        dispatch({
            type: Type.COPY_RESOURCES_CALL
        });

        axios.post('api/copy_resource/copy', { from, to })
            .then(function (response) {
                dispatch({
                    type: Type.COPY_RESOURCES_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.COPY_RESOURCES_FAILED
                });
            });
    }
}

export function setRowInReducer(rowInfo) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_REGION_ROW_IN_REDUCER,
            rowInfo: rowInfo
        })
    }
}