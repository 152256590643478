import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Fab } from '@material-ui/core';
import i18n from '../../../i18n/i18n';
import { toast } from 'react-toastify';
import { getLayer, deleteLayer, setInitialState, setRowInReducer } from '../../../actions/catastral_data/LayerActions';
import AddLayer from './AddLayer';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import { Delete } from '../../../utils/Delete';
import { getUser } from '../../../utils/CommonFunctions';
const CheckboxTable = checkboxHOC(ReactTable);

export class ViewLayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            add: false,
            showFilters: false,
            selection: [],
            delete: false,
            selectAll: false,
            name: '',
            edit: false
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            layer: nextProps.layer,
            layerFetching: nextProps.layerFetching,
        })
        if (nextProps.deleted === true) {
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getLayer(wrapped.state);
            this.setState({
                selection: [],
                selectAll: false,
                name: ''
            })
            this.props.setInitialState('layerObj');
            this.props.setInitialState('deleted');
        }
        if (nextProps.layerPostingSuccess === true) {
            this.handleClick();
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getLayer(wrapped.state);
            this.props.setInitialState('layerPostingSuccess');
        }
        if (nextProps.deleteLayerFailed === true) {
            toast.error(i18n.t('This item can not be deleted!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('deleteLayerFailed');
        }

    }

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    handleClick = () => {
        if (this.state.edit == true && this.state.add == false) {
            this.setState({ edit: false })
            this.props.setInitialState('layerObj');
        } else if (this.state.selection.length > 0 && this.state.add == false && this.state.edit == false) {
            this.props.setInitialState('layerObj');
        }
        this.setState({ add: !this.state.add }, () => {
            if (this.state.add == true && this.state.edit == false) {
                this.setState({
                    selection: [],
                    name: '',
                    selectAll: false,
                })
            }
        });
    };

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
            this.props.setInitialState('layerObj');
        } else {
            selection.push(key);
        }
        this.setState({ selection, name: 'layer' }, function () {
            if (selection.length == 1) {
                let row1 = this.props.layer.filter((layer) => {
                    return layer.id == selection;
                })
                this.props.setRowInReducer(row1[0]);
            }
        });
    };

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection, name: 'layer' });


    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    onDeleteClick = () => {
        this.setState({ delete: !this.state.delete });
    }

    deleteItem = () => {
        this.props.deleteLayer(this.state.selection, this.state.name);
        this.setState({ delete: false })
    }

    onEditClick() {
        this.setState({ add: !this.state.add, edit: true });
    }


    render() {
        let user = getUser();
        const columns = [
            {
                Header: i18n.t('Title'),
                accessor: 'naziv'
            },
            {
                Header: i18n.t('Season name'),
                accessor: 'sezona_naziv',
                // Filter: ({ filter, onChange }) =>
                //     <Select
                //         isMulti
                //         name="naziv"
                //         onChange={value => {
                //             onChange(value)
                //         }}
                //         closeMenuOnSelect={false}
                //         options={this.state.crops}
                //         className="multi-select-in-filter"
                //         classNamePrefix="select"
                //     />
            },
        ];
        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "lightgreen" : "inherit"
                        }
                    };
                }
                return {};
            }
        };
        return (
            <div className="page">
                {user.id_klijent_grupa !== 1 &&
                    <div className="table-header">
                        <Fab
                            onClick={() => this.handleClick()}
                            color="primary"
                            aria-label="add"
                            className="action-button"
                        >
                            <span className="icon-Plus headerIcon" />
                        </Fab>
                        <Fab
                            onClick={() => this.onSearchClick()}
                            color="primary"
                            aria-label="add"
                            className="action-button"
                        >
                            <span className="icon-search headerIcon" />
                        </Fab>
                        {this.state.selection.length > 0 ?
                            <Fab
                                onClick={() => this.onDeleteClick()}
                                color="primary"
                                aria-label="add"
                                className="action-button"
                            >
                                <span className="icon-Kanta headerIcon" />
                            </Fab>
                            : null
                        }
                        {this.state.selection.length === 1 ?
                            <Fab
                                onClick={() => this.onEditClick()}
                                color="primary"
                                aria-label="edit"
                                className="action-button"
                            >
                                <span className="icon-Izmeni1 headerIcon" />
                            </Fab>
                            : null
                        }

                    </div>
                }
                <CheckboxTable data={this.props.layer}
                    ref={r => (this.checkboxTable = r)}
                    keyField={'id'}
                    getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                    columns={columns}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.props.layerPages}
                    loading={this.props.layerFetching}
                    manual
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => this.props.getLayer(state, instance)}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                />
                {this.state.add && <AddLayer add={this.state.add} edit={this.state.edit}
                    handleClose={this.handleClick} />}
                {this.state.delete && <Delete delete={this.state.delete} handleClose={this.onDeleteClick} deleteItem={this.deleteItem} />}

            </div>

        )
    }


};

function mapStateToProps(state) {
    return {
        layer: state.layerReducer.layer,
        layerObj: state.layerReducer.layerObj,
        layerFetching: state.layerReducer.layerFetching,
        layerPages: state.layerReducer.layerPages,
        deleted: state.layerReducer.deleted,
        deleteLayerFailed: state.layerReducer.deleteLayerFailed,
        layerPostingSuccess: state.layerReducer.layerPostingSuccess
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getLayer: (state, instance) => dispatch(getLayer(state, instance)),
        deleteLayer: (selection, name) => dispatch(deleteLayer(selection, name)),
        setInitialState: (component) => dispatch(setInitialState(component)),
        setRowInReducer: (rowInfo) => dispatch(setRowInReducer(rowInfo)),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewLayer)