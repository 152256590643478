import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import i18n from '../../i18n/i18n';
import Select from 'react-select';
import { Fab } from '@material-ui/core';
import { wentWrong, wentRight } from "../../utils/ToastConfig";
import axios from '../../utils/AxiosWrapper';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography, Paper, Card } from '@material-ui/core';

export default class AccountManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search_user: null, search_name: null,
            user_id: null, client_id: null,
            username: null, clientname: null,
            loading: false,
            regions: [], region: null,
            opstine: [], opstinaDisabled: true, opstina: null,
            katastarske: [], katastarskaDisabled: true, katastarska: null,
            user_group: null, userGroups: [],
            ime: null, prezime: null, mejl: null, birth: null,
            telefon: null, mobilni: null, skype: null, pol: null,
            language: null, languages: [],
            currency: null, currencies: [],
            pass1: null, pass2: null,

            ulica_i_broj: null, k_naziv: null, k_mobilni: null,
            mesto: null, drzava: null, kontakt_osoba: null, k_telefon: null,
            k_mejl: null, k_id_region: null,
            client_group: null, clientGroups: [], k_vreme_kreiranja: null,
            k_id_jezik: null, season: null, seasons: [], seasonDisabled: true,
            workspace: null, workspaces: [],
            k_opstina: null, k_katastarska: null, bpg: null, farmer_id: null,
            k_opstinaDisabled: true, k_katastarskaDisabled: true,
        };
    }

    componentDidMount() {
        this.getByURL('region/filter_region', 'regions');
        this.getByURL('region/filter_korisnik_grupa', 'userGroups');
        this.getByURL('klijent_korisnik/language', 'languages');
        this.getByURL('klijent_korisnik/currency', 'currencies');
        this.getByURL('klijent_korisnik/workspace', 'workspaces');

        // this.getByURL('region/filter_region', 'regions');
        // this.getByURL('region/filter_klijent_grupa', 'clientGroups');

        // this.getByURL('klijent_korisnik/season', 'seasons');
    }

    getByURL(url, obj, value, name) {
        // url: api/'url' // obj: this.state.obj // value: params value // name: params value name
        value = value || '';
        name = name || '';
        if (value != null) {
            axios.get(`api/${url}`, { params: { [name]: value } })
                .then(res => this.setState({ [obj]: res.data.data }))
                .catch(error => wentWrong('Something went wrong, please try again!'));
        }
    }

    render() {
        let data = {};
        data = {
            id: this.state.user_id,
            id_region: this.state.region,
            id_opstina: this.state.opstina,
            id_katastarska_opstina: this.state.katastarska,
            username: this.state.username,
            id_korisnik_grupa: this.state.user_group,
            ime: this.state.ime,
            prezime: this.state.prezime,
            mejl: this.state.mejl,
            telefon: this.state.telefon,
            mobilni: this.state.mobilni,
            skype: this.state.skype,
            pol: this.state.pol,
            datum_rodjenja: this.state.birth,
            id_jezik: this.state.language,
            id_valuta: this.state.currency,
        };

        let client = {};
        client = {
            id: this.state.client_id,
            ulica_i_broj: this.state.ulica_i_broj,
            naziv: this.state.k_naziv,
            kontakt_mobilni: this.state.k_mobilni,
            mesto: this.state.mesto,
            drzava: this.state.drzava,
            kontakt_osoba: this.state.kontakt_osoba,
            kontakt_telefon: this.state.k_telefon,
            kontakt_mejl: this.state.k_mejl,
            id_region: this.state.k_id_region,
            id_klijent_grupa: this.state.client_group,
            id_jezik: this.state.k_id_jezik,
            id_default_sezona: this.state.season,
            geoserver_workspace: this.state.workspace,
            id_opstina: this.state.k_opstina,
            id_katastarska_opstina: this.state.k_katastarska,
            bpg: this.state.bpg,
        };
        // console.log(data, client);
        console.log(data.id_opstina, data.id_katastarska_opstina)

        return (
            <div className="page">
                <div style={{ display: 'flex' }}>
                    <TextField required
                        label={i18n.t('User')}
                        variant="outlined"
                        helperText="Username korisnika"
                        value={this.state.search_user}
                        InputLabelProps={{ shrink: true }}
                        style={{ width: '256px', marginBottom: '10px', marginRight: '10px', marginLeft: '30px', marginTop: '20px' }}
                        onChange={(e) => { this.setState({ search_user: e.target.value }); }} >
                    </TextField>
                    <Button onClick={() => {
                        this.setState({ loading: true });

                        axios.get('api/korisnik/read_by_id', { params: { username: this.state.search_user } })
                            .then(res => {
                                this.setState({
                                    user_id: res.data.data[0].id,
                                    client_id: res.data.data[0].id_klijent,
                                    region: res.data.data[0].id_region,
                                    opstina: res.data.data[0].id_opstina,
                                    katastarska: res.data.data[0].id_katastarska_opstina,
                                    username: res.data.data[0].username,
                                    clientname: res.data.data[0].k_naziv,
                                    user_group: res.data.data[0].id_korisnik_grupa,
                                    ime: res.data.data[0].ime,
                                    prezime: res.data.data[0].prezime,
                                    mejl: res.data.data[0].mejl,
                                    telefon: res.data.data[0].telefon,
                                    mobilni: res.data.data[0].mobilni,
                                    skype: res.data.data[0].skype,
                                    pol: res.data.data[0].pol,
                                    birth: res.data.data[0].datum_rodjenja,
                                    language: res.data.data[0].id_jezik,
                                    currency: res.data.data[0].id_valuta,

                                    ulica_i_broj: res.data.data[0].ulica_i_broj,//
                                    k_naziv: res.data.data[0].k_naziv,//
                                    k_mobilni: res.data.data[0].k_mobilni,//
                                    mesto: res.data.data[0].mesto,//
                                    drzava: res.data.data[0].drzava,//
                                    kontakt_osoba: res.data.data[0].kontakt_osoba,//
                                    k_telefon: res.data.data[0].k_telefon,//
                                    k_mejl: res.data.data[0].k_mejl,//
                                    k_id_region: res.data.data[0].k_id_region,//
                                    client_group: res.data.data[0].id_klijent_grupa,//
                                    k_vreme_kreiranja: res.data.data[0].k_vreme_kreiranja,
                                    k_id_jezik: res.data.data[0].k_id_jezik,
                                    season: res.data.data[0].id_default_sezona,//
                                    workspace: res.data.data[0].geoserver_workspace,
                                    k_opstina: res.data.data[0].k_id_opstina,//
                                    k_katastarska: res.data.data[0].k_id_katastarska_opstina,//
                                    bpg: res.data.data[0].bpg,//
                                    farmer_id: res.data.data[0].farmer_id,//

                                    loading: false,
                                    opstinaDisabled: false,
                                    k_opstinaDisabled: false,
                                    seasonDisabled: false,
                                    katastarskaDisabled: false,
                                    k_katastarskaDisabled: false
                                }, () => {
                                    if (res.data.data[0].id_region === null) this.setState({ region: '' }, () => { this.setState({ region: null }) });
                                    if (res.data.data[0].id_opstina === null) this.setState({ opstina: '' }, () => { this.setState({ opstina: null }) });
                                    if (res.data.data[0].id_katastarska_opstina === null) this.setState({ katastarska: '' }, () => { this.setState({ katastarska: null }) });
                                    if (res.data.data[0].id_korisnik_grupa === null) this.setState({ user_group: '' }, () => { this.setState({ user_group: null }) });

                                    if (res.data.data[0].k_id_region === null) this.setState({ k_id_region: '' }, () => { this.setState({ k_id_region: null }) });
                                    if (res.data.data[0].geoserver_workspace === null) this.setState({ workspace: '' }, () => { this.setState({ workspace: null }) });
                                    if (res.data.data[0].k_id_opstina === null) this.setState({ k_opstina: '' }, () => { this.setState({ k_opstina: null }) });
                                    if (res.data.data[0].k_id_katastarska_opstina === null) this.setState({ k_katastarska: '' }, () => { this.setState({ k_katastarska: null }) });
                                    if (res.data.data[0].id_klijent_grupa === null) this.setState({ client_group: '' }, () => { this.setState({ client_group: null }) });
                                    if (res.data.data[0].id_default_sezona === null) this.setState({ season: '' }, () => { this.setState({ season: null }) });
                                });
                                if (res.data.data[0].id_region !== null) this.getByURL('field/filter_opstina_uslov', 'opstine', res.data.data[0].id_region, 'id_region');
                                if (res.data.data[0].id_region !== null) this.getByURL('klijent_korisnik/season', 'seasons', res.data.data[0].id_region, 'id_region');
                                if (res.data.data[0].id_opstina !== null) this.getByURL('field/filter_kat_opstina_uslov', 'katastarske', res.data.data[0].id_opstina, 'id_opstina');
                            })
                            .catch(err => {
                                console.log(err);
                                wentWrong('Something went wrong, please try again!');
                            })
                    }}
                        style={{ height: '32px', marginTop: '30px' }}>{i18n.t('Search')}</Button>
                    <Typography style={{ fontSize: '20px', color: 'red', marginTop: '-18px', marginLeft: '32px' }}>Nije moguce izmenuti  </Typography>
                    <Typography style={{ fontSize: '20px', color: 'black', marginTop: '-18px' }}>/  Bitno  /</Typography>
                    <Typography style={{ fontSize: '20px', color: 'blue', marginTop: '-18px' }}>  Nebitno</Typography>
                </div>
                <div style={{ width: '100%', borderTop: '3px solid' }}></div>
                <div>
                    <Typography style={{ fontSize: '20px', color: 'black', marginTop: '12px', marginLeft: '32px' }}>{i18n.t('User')}</Typography>
                    <table style={{ marginTop: '25px', marginLeft: '30px' }}>
                        <tr>
                            <td>
                                <TextField
                                    label={i18n.t('User ID')}
                                    type="number"
                                    helperText="Redni broj korisnika"
                                    variant="outlined"
                                    value={this.state.user_id}
                                    InputLabelProps={{ shrink: true, style: { color: 'red' } }}
                                    inputProps={{ style: { color: 'red', readOnly: true } }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }}>
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('Client ID')}
                                    type="number"
                                    helperText="Redni broj klijenta"
                                    variant="outlined"
                                    value={this.state.client_id}
                                    InputLabelProps={{ shrink: true, style: { color: 'red' } }}
                                    inputProps={{ style: { color: 'red', readOnly: true } }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }} >
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('Region')}
                                    select
                                    helperText="Region korisnika"
                                    variant="outlined"
                                    value={this.state.region}
                                    SelectProps={{ native: true }}
                                    InputLabelProps={{ shrink: true }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }}
                                    onChange={(e) => {
                                        this.setState({
                                            region: e.target.value,
                                            opstinaDisabled: false,
                                            opstina: null,
                                            katastarskaDisabled: true,
                                            katastarska: null
                                        });
                                        this.getByURL('field/filter_opstina_uslov', 'opstine', e.target.value, 'id_region');
                                    }} >
                                    <option value='' />
                                    {this.state.regions.map((regions) => <option key={regions.id} value={regions.id}>{regions.naziv}</option>)}
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('Opstina')}
                                    select
                                    helperText="Opstina korisnika"
                                    variant="outlined"
                                    disabled={this.state.opstinaDisabled}
                                    value={this.state.opstina}
                                    SelectProps={{ native: true }}
                                    InputLabelProps={{ shrink: true }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }}
                                    onChange={(e) => {
                                        this.setState({
                                            opstina: e.target.value,
                                            katastarskaDisabled: false,
                                            katastarska: null
                                        });
                                        this.getByURL('field/filter_kat_opstina_uslov', 'katastarske', e.target.value, 'id_opstina');
                                    }} >
                                    <option value='' />
                                    {this.state.opstine.map((opstine) => <option key={opstine.id} value={opstine.id}>{opstine.naziv}</option>)}
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('Katastarska opstina')}
                                    select
                                    variant="outlined"
                                    helperText="Kat. opstina korisnika"
                                    disabled={this.state.katastarskaDisabled}
                                    value={this.state.katastarska}
                                    SelectProps={{ native: true }}
                                    InputLabelProps={{ shrink: true }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }}
                                    onChange={(e) => { this.setState({ katastarska: e.target.value }); }} >
                                    <option value='' />
                                    {this.state.katastarske.map((katastarske) => <option key={katastarske.id} value={katastarske.id}>{katastarske.naziv}</option>)}
                                </TextField>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <TextField
                                    label={i18n.t('Username')}
                                    value={this.state.username}
                                    variant="outlined"
                                    helperText="Username korisnika"
                                    InputLabelProps={{ shrink: true }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }}
                                    onChange={(e) => { this.setState({ username: e.target.value }); }} >
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('Client name')}
                                    value={this.state.clientname}
                                    variant="outlined"
                                    helperText="Naziv klijenta"
                                    InputLabelProps={{ shrink: true, style: { color: 'red' } }}
                                    inputProps={{ style: { color: 'red', readOnly: true } }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }} >
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('User Group')}
                                    select
                                    helperText="Korisnik grupa"
                                    variant="outlined"
                                    value={this.state.user_group}
                                    SelectProps={{ native: true }}
                                    InputLabelProps={{ shrink: true }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }}
                                    onChange={(e) => { this.setState({ user_group: e.target.value }); }} >
                                    <option value='' />
                                    {this.state.userGroups.map((userGroups) => <option key={userGroups.id} value={userGroups.id}>{userGroups.naziv}</option>)}
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('First name')}
                                    value={this.state.ime}
                                    variant="outlined"
                                    helperText="Ime u dnu aplikacije (dole levo)"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => { this.setState({ ime: e.target.value }); }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }} >
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('Last name')}
                                    value={this.state.prezime}
                                    variant="outlined"
                                    helperText="Prezime u dnu aplikacije (dole levo)"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => { this.setState({ prezime: e.target.value }); }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }} >
                                </TextField>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <TextField
                                    label={i18n.t('E-mail')}
                                    value={this.state.mejl}
                                    variant="outlined"
                                    helperText="E-mail korisnika"
                                    InputLabelProps={{ shrink: true, style: { color: 'blue' } }}
                                    inputProps={{ style: { color: 'blue', readOnly: true } }}
                                    onChange={(e) => { this.setState({ mejl: e.target.value }); }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }} >
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('Phone')}
                                    value={this.state.telefon}
                                    variant="outlined"
                                    helperText="Broj telefona korisnika"
                                    InputLabelProps={{ shrink: true, style: { color: 'blue' } }}
                                    inputProps={{ style: { color: 'blue', readOnly: true } }}
                                    onChange={(e) => { this.setState({ telefon: e.target.value }); }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }} >
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('Mobile phone')}
                                    value={this.state.mobilni}
                                    variant="outlined"
                                    helperText="Mobilni telefon korisnika"
                                    InputLabelProps={{ shrink: true, style: { color: 'blue' } }}
                                    inputProps={{ style: { color: 'blue', readOnly: true } }}
                                    onChange={(e) => { this.setState({ mobilni: e.target.value }); }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }} >
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('Skype')}
                                    value={this.state.skype}
                                    variant="outlined"
                                    helperText="Skype ime korisnika"
                                    InputLabelProps={{ shrink: true, style: { color: 'blue' } }}
                                    inputProps={{ style: { color: 'blue', readOnly: true } }}
                                    onChange={(e) => { this.setState({ skype: e.target.value }); }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }} >
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('Gender')}
                                    value={this.state.pol}
                                    variant="outlined"
                                    helperText="Pol korisnika"
                                    InputLabelProps={{ shrink: true, style: { color: 'blue' } }}
                                    inputProps={{ style: { color: 'blue', readOnly: true } }}
                                    onChange={(e) => { this.setState({ pol: e.target.value }); }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }} >
                                </TextField>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <TextField
                                    label={i18n.t('Birth date')}
                                    value={this.state.birth}
                                    variant="outlined"
                                    helperText="Datum rodjenja korisnika"
                                    InputLabelProps={{ shrink: true, style: { color: 'blue' } }}
                                    inputProps={{ style: { color: 'blue', readOnly: true } }}
                                    onChange={(e) => { this.setState({ birth: e.target.value }); }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }} >
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('Currency')}
                                    select
                                    helperText="Valuta korisnika"
                                    value={this.state.currency}
                                    SelectProps={{ native: true }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true, style: { color: 'blue' } }}
                                    inputProps={{ style: { color: 'blue', readOnly: true } }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }}
                                    onChange={(e) => { this.setState({ currency: e.target.value }); }} >
                                    <option value='' />
                                    {this.state.currencies.map((currencies) => <option key={currencies.id} value={currencies.id}>{currencies.naziv}</option>)}
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('Language')}
                                    select
                                    value={this.state.language}
                                    variant="outlined"
                                    helperText="Jezik korisnika"
                                    SelectProps={{ native: true }}
                                    InputLabelProps={{ shrink: true, style: { color: 'blue' } }}
                                    inputProps={{ style: { color: 'blue', readOnly: true } }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }}
                                    onChange={(e) => { this.setState({ language: e.target.value }); }} >
                                    <option value='' />
                                    {this.state.languages.map((languages) => <option key={languages.id} value={languages.id}>{languages.naziv}</option>)}
                                </TextField>
                            </td>
                            <td>
                                <Button onClick={() => {
                                    axios.post('api/korisnik/account', data)
                                        .then(res => {
                                            console.log(res);
                                            wentRight('Korisnik izmenjen');
                                        })
                                        .catch(err => { wentWrong('Something went wrong') })
                                }} style={{ height: '32px', marginTop: '-30px' }}>{i18n.t('Edit')}</Button>
                            </td>
                        </tr>
                    </table>
                </div>
                <div style={{ width: '100%', borderTop: '3px solid' }}></div>
                <div>
                    <Typography style={{ fontSize: '20px', color: 'black', marginTop: '12px', marginLeft: '32px' }}>{i18n.t('Client')}</Typography>
                    <table style={{ marginTop: '25px', marginLeft: '30px' }}>
                        <tr>
                            <td>
                                <TextField
                                    label={i18n.t('Street')}
                                    value={this.state.ulica_i_broj}
                                    variant="outlined"
                                    helperText="Ulica i broj klijenta"
                                    InputLabelProps={{ shrink: true, style: { color: 'blue' } }}
                                    inputProps={{ style: { color: 'blue' } }}
                                    onChange={(e) => { this.setState({ ulica_i_broj: e.target.value }); }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }} >
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('Naziv')}
                                    value={this.state.k_naziv}
                                    variant="outlined"
                                    helperText="Naziv klijenta"
                                    InputLabelProps={{ shrink: true, style: { color: 'blue' } }}
                                    inputProps={{ style: { color: 'blue' } }}
                                    onChange={(e) => { this.setState({ k_naziv: e.target.value }); }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }} >
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('Mobile phone')}
                                    value={this.state.k_mobilni}
                                    variant="outlined"
                                    helperText="Mobilni telefon klijenta"
                                    InputLabelProps={{ shrink: true, style: { color: 'blue' } }}
                                    inputProps={{ style: { color: 'blue' } }}
                                    onChange={(e) => { this.setState({ k_mobilni: e.target.value }); }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }} >
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('Location')}
                                    value={this.state.mesto}
                                    variant="outlined"
                                    helperText="Mesto klijenta"
                                    InputLabelProps={{ shrink: true, style: { color: 'blue' } }}
                                    inputProps={{ style: { color: 'blue' } }}
                                    onChange={(e) => { this.setState({ mesto: e.target.value }); }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }} >
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('Country')}
                                    value={this.state.drzava}
                                    variant="outlined"
                                    helperText="Drzava klijenta"
                                    InputLabelProps={{ shrink: true, style: { color: 'blue' } }}
                                    inputProps={{ style: { color: 'blue' } }}
                                    onChange={(e) => { this.setState({ drzava: e.target.value }); }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }} >
                                </TextField>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <TextField
                                    label={i18n.t('Contact')}
                                    value={this.state.kontakt_osoba}
                                    variant="outlined"
                                    helperText="Kontakt osoba klijenta"
                                    InputLabelProps={{ shrink: true, style: { color: 'blue' } }}
                                    inputProps={{ style: { color: 'blue' } }}
                                    onChange={(e) => { this.setState({ kontakt_osoba: e.target.value }); }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }} >
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('Contact phone')}
                                    value={this.state.k_telefon}
                                    variant="outlined"
                                    helperText="Kontakt telefon klijenta"
                                    InputLabelProps={{ shrink: true, style: { color: 'blue' } }}
                                    inputProps={{ style: { color: 'blue' } }}
                                    onChange={(e) => { this.setState({ k_telefon: e.target.value }); }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }} >
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('Contact e-mail')}
                                    value={this.state.k_mejl}
                                    variant="outlined"
                                    helperText="Kontakt e-mail klijenta"
                                    InputLabelProps={{ shrink: true, style: { color: 'blue' } }}
                                    inputProps={{ style: { color: 'blue' } }}
                                    onChange={(e) => { this.setState({ k_mejl: e.target.value }); }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }} >
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('BPG')}
                                    value={this.state.bpg}
                                    variant="outlined"
                                    helperText="BPG klijenta"
                                    InputLabelProps={{ shrink: true, style: { color: 'black' } }}
                                    inputProps={{ style: { color: 'black' } }}
                                    onChange={(e) => { this.setState({ bpg: e.target.value }); }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }} >
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('Farmer ID')}
                                    value={this.state.farmer_id}
                                    variant="outlined"
                                    helperText="Farmer ID klijenta"
                                    InputLabelProps={{ shrink: true, style: { color: 'red' } }}
                                    inputProps={{ style: { color: 'red', readOnly: true } }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }} >
                                </TextField>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <TextField
                                    label={i18n.t('Region')}
                                    select
                                    helperText="Region klijenta"
                                    value={this.state.k_id_region}
                                    SelectProps={{ native: true }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }}
                                    onChange={(e) => {
                                        this.setState({
                                            k_id_region: e.target.value,
                                            k_opstinaDisabled: false,
                                            k_opstina: null,
                                            k_katastarskaDisabled: true,
                                            k_katastarska: null,
                                            seasonDisabled: false,
                                            season: null
                                        });
                                        this.getByURL('field/filter_opstina_uslov', 'opstine', e.target.value, 'k_id_region');
                                        this.getByURL('klijent_korisnik/season', 'seasons', e.target.value, 'k_id_region');
                                    }} >
                                    <option value='' />
                                    {this.state.regions.map((regions) => <option key={regions.id} value={regions.id}>{regions.naziv}</option>)}
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('Opstina')}
                                    select
                                    disabled={this.state.k_opstinaDisabled}
                                    value={this.state.k_opstina}
                                    variant="outlined"
                                    helperText="Opstina klijenta"
                                    SelectProps={{ native: true }}
                                    InputLabelProps={{ shrink: true }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }}
                                    onChange={(e) => {
                                        this.setState({
                                            k_opstina: e.target.value,
                                            k_katastarskaDisabled: false,
                                            k_katastarska: null
                                        });
                                        this.getByURL('field/filter_kat_opstina_uslov', 'katastarske', e.target.value, 'k_id_opstina');
                                    }} >
                                    <option value='' />
                                    {this.state.opstine.map((opstine) => <option key={opstine.id} value={opstine.id}>{opstine.naziv}</option>)}
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('Katastarska opstina')}
                                    select
                                    disabled={this.state.k_katastarskaDisabled}
                                    value={this.state.k_katastarska}
                                    SelectProps={{ native: true }}
                                    helperText="Kat. opstina klijenta"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }}
                                    onChange={(e) => { this.setState({ k_katastarska: e.target.value }); }} >
                                    <option value='' />
                                    {this.state.katastarske.map((katastarske) => <option key={katastarske.id} value={katastarske.id}>{katastarske.naziv}</option>)}
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('Client Group')}
                                    select
                                    value={this.state.client_group}
                                    SelectProps={{ native: true }}
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    helperText="Klijent grupa"
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }}
                                    onChange={(e) => { this.setState({ client_group: e.target.value }); }} >
                                    <option value='' />
                                    {this.state.clientGroups.map((clientGroups) => <option key={clientGroups.id} value={clientGroups.id}>{clientGroups.naziv}</option>)}
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('Season')}
                                    select
                                    disabled={this.state.seasonDisabled}
                                    value={this.state.season}
                                    variant="outlined"
                                    helperText="Odabrana sezona klijenta"
                                    SelectProps={{ native: true }}
                                    InputLabelProps={{ shrink: true }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }}
                                    onChange={(e) => { this.setState({ season: e.target.value, }); }} >
                                    <option value='' />
                                    {this.state.seasons.map((seasons) => <option key={seasons.id} value={seasons.id}>{seasons.naziv}</option>)}
                                </TextField>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <TextField
                                    label={i18n.t('Language')}
                                    select
                                    value={this.state.k_id_jezik}
                                    SelectProps={{ native: true }}
                                    variant="outlined"
                                    helperText="Jezik klijenta"
                                    InputLabelProps={{ shrink: true, style: { color: 'black' } }}
                                    inputProps={{ style: { color: 'black' } }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }}
                                    onChange={(e) => { this.setState({ k_id_jezik: e.target.value }); }} >
                                    <option value='' />
                                    {this.state.languages.map((languages) => <option key={languages.id} value={languages.id}>{languages.naziv}</option>)}
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('Workspace')}
                                    select
                                    value={this.state.workspace}
                                    variant="outlined"
                                    helperText="Geoserver workspace klijenta"
                                    SelectProps={{ native: true }}
                                    InputLabelProps={{ shrink: true, style: { color: 'black' } }}
                                    inputProps={{ style: { color: 'black' } }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }}
                                    onChange={(e) => { this.setState({ workspace: e.target.value }); }} >
                                    <option value='' />
                                    {this.state.workspaces.map((workspaces) => <option key={workspaces.id} value={workspaces.id}>{workspaces.geoserver_workspace}</option>)}
                                </TextField>
                            </td>
                            <td>
                                <TextField
                                    label={i18n.t('Time created')}
                                    value={this.state.k_vreme_kreiranja}
                                    variant="outlined"
                                    helperText="Vreme kreiranja klijenta"
                                    InputLabelProps={{ shrink: true, style: { color: 'red' } }}
                                    inputProps={{ style: { color: 'red', readOnly: true } }}
                                    style={{ width: '15vw', marginBottom: '10px', marginRight: '10px' }} >
                                </TextField>
                            </td>
                            <td>
                                <Button onClick={() => {
                                    axios.post('api/klijent/account', client)
                                        .then(res => {
                                            console.log(res);
                                            wentRight('Klijent izmenjen');
                                        })
                                        .catch(err => { wentWrong('Something went wrong') })
                                }} style={{ height: '32px', marginTop: '-30px' }}>{i18n.t('Edit')}</Button>
                            </td>
                        </tr>
                    </table>
                </div>
                <div style={{ width: '100%', borderTop: '3px solid' }}></div>
                <Typography style={{ fontSize: '20px', color: 'black', marginTop: '12px', marginLeft: '32px' }}>{i18n.t('Password')}</Typography>
                <table>
                    <tr >
                        <td>
                            <TextField
                                onChange={(e) => { this.setState({ pass1: e.target.value }); }}
                                label={i18n.t('New password')}
                                helperText="Nova sifra korisnika"
                                variant="outlined"
                                style={{ width: '15vw', marginTop: '10px', marginBottom: '10px', marginRight: '10px', marginLeft: '30px' }}
                            />
                        </td>
                        <td>
                            <TextField
                                onChange={(e) => { this.setState({ pass2: e.target.value }); }}
                                label={i18n.t('Retype new password')}
                                helperText="Potvrda nove sifre korisnika"
                                variant="outlined"
                                style={{ width: '15vw', marginTop: '10px', marginBottom: '10px', marginRight: '10px' }}
                            />
                        </td>
                        <td>
                            <Button style={{ marginTop: '-24px' }} onClick={() => {
                                if (this.state.pass1 !== null && this.state.pass2 !== null) {
                                    if (this.state.pass1 === this.state.pass2) {
                                        let data_pass = { password: this.state.pass1, id: this.state.user_id }
                                        axios.post('api/korisnik/password', data_pass)
                                            .then(res => { wentRight('Password chaged') })
                                            .catch(err => { wentWrong('Something went wrong') })
                                    }
                                    else wentWrong('Passwords have to match');
                                }
                                else wentWrong('You have to enter both passwords');
                            }}>{i18n.t('Submit password')}</Button>
                        </td>
                    </tr>
                </table>
            </div >
        )
    }
};