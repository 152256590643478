import React from 'react';
import i18n from '../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { wentWrong } from "../../../utils/ToastConfig";
import axios from '../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import Switch from '@material-ui/core/Switch';

export default class AddSensors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            region: null, regions: [],
            opstina: null, opstine: [],
            kat_opstina: null, kat_opstine: [],
            station_id: null,
            title: null,
            aktivan: true,
            x: null, y: null,
        }
    }

    componentDidMount() {
        this.getByURL('region/filter_region', 'regions');

        if (this.props.edit === true) {
            this.setState({
                region: this.props.data.id_region,
                opstina: null, katastarska_opstina: null,
                station_id: this.props.data.uid,
                title: this.props.data.naziv,
                aktivan: this.props.data.aktivan,
            }, () => { this.getByURL('field/filter_opstina_uslov', 'opstine', this.state.region, 'id_region'); });
        }
    }

    getByURL(url, obj, event, required) {
        event = event || '';
        required = required || '';
        if (event != null) {
            axios.get(`api/${url}`, { params: { [required]: event } })
                .then(res => this.setState({ [obj]: res.data.data }))
                .catch(error => wentWrong('Something went wrong, please try again!'));
        }
    }

    addInstitutionType() {
        if (
            this.state.region &&
            this.state.opstina &&
            this.state.kat_opstina &&
            this.state.station_id &&
            this.state.title
        ) {
            let data = {
                id: this.props.data.id,
                naziv: this.state.title,
                id_region: Number(this.state.region),
                id_opstina: Number(this.state.opstina),
                id_katastarska_opstina: Number(this.state.kat_opstina),
                uid: this.state.station_id,
                aktivan: this.state.aktivan,
                x: this.state.x,
                y: this.state.y,
            }

            axios.post('api/sensor/create', { data: data })
                .then(res => {
                    this.props.handleClose();
                })
                .catch(error => {
                    wentWrong('Something went wrong, please try again!');
                    console.log(`Error: ${error}`);
                })
        }
        else wentWrong('Please enter all required fields!');
    }

    render() {
        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit Sensor') : i18n.t('Add Sensor')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-layer">
                            <Grid container justify="space-between" alignContent="space-between" >

                                <Grid item xs={6}>
                                    <TextField required
                                        label={i18n.t('Title')}
                                        value={this.state.title}
                                        InputLabelProps={{ shrink: true }}
                                        style={{ width: '256px', marginBottom: '10px' }}
                                        onChange={(e) => { this.setState({ title: e.target.value }); }} >
                                    </TextField>
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField required
                                        label={i18n.t('Region')}
                                        select
                                        value={this.state.region}
                                        InputLabelProps={{ shrink: true }}
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginBottom: '10px' }}
                                        onChange={(e) => {
                                            this.setState({ region: e.target.value });
                                            this.getByURL('field/filter_opstina_uslov', 'opstine', e.target.value, 'id_region');
                                        }} >
                                        <option value='' />
                                        {this.state.regions.map((regions) => <option key={regions.id} value={regions.id}>{regions.naziv}</option>)}
                                    </TextField>
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField required
                                        label={i18n.t('State')}
                                        disabled={!this.state.region}
                                        select
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginBottom: '10px' }}
                                        onChange={(e) => {
                                            this.setState({ opstina: e.target.value });
                                            this.getByURL('klijent_korisnik/kat_opstina_dropdown', 'kat_opstine', e.target.value, 'id_opstina');
                                        }}
                                    >
                                        <option value='' />
                                        {this.state.opstine.map((opstina) => <option key={opstina.id} value={opstina.id}>{opstina.naziv}</option>)}
                                    </TextField>
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField required
                                        label={i18n.t('Local government')}
                                        disabled={!this.state.opstina}
                                        select
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginBottom: '10px' }}
                                        onChange={(e) => { this.setState({ kat_opstina: e.target.value }); }}
                                    >
                                        <option value='' />
                                        {this.state.kat_opstine.map((kat_opstina) => <option key={kat_opstina.id} value={kat_opstina.id}>{kat_opstina.naziv}</option>)}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField required
                                        label={i18n.t('Station ID')}
                                        value={this.state.station_id}
                                        InputLabelProps={{ shrink: true }}
                                        style={{ width: '532px', marginBottom: '10px' }}
                                        onChange={(e) => { this.setState({ station_id: e.target.value }); }} >
                                    </TextField>
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField required
                                        label={i18n.t('X axis (numbers only)')}
                                        value={this.state.x}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ type: 'number' }}
                                        style={{ width: '256px', marginBottom: '10px' }}
                                        onChange={(e) => { this.setState({ x: e.target.value }); }} >
                                    </TextField>
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField required
                                        label={i18n.t('Y axis (numbers only)')}
                                        value={this.state.y}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ type: 'number' }}
                                        style={{ width: '256px', marginBottom: '10px' }}
                                        onChange={(e) => { this.setState({ y: e.target.value }); }} >
                                    </TextField>
                                </Grid>

                                <Grid item container>
                                    <Typography>{i18n.t('Active')}</Typography>
                                    <Switch
                                        checked={this.state.aktivan}
                                        onChange={(e) => { this.setState({ aktivan: !this.state.aktivan }) }}
                                        name="aktivan"
                                        color="primary"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Button style={{ alignSelf: 'center', backgroundColor: '#58B4E5', color: 'white', marginTop: '20px', marginBottom: '10px' }}
                                        onClick={() => this.addInstitutionType()}
                                        disabled={this.props.layerPosting} >
                                        {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                                    </Button>
                                </Grid>

                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
};