import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import {
    Grid, IconButton,
    Dialog, DialogContent, DialogTitle
} from '@material-ui/core';
import { getStates } from '../../actions/AdministrationActions';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import 'react-toastify/dist/ReactToastify.css';
import {
    getLocalGov, getTitle, getMaritalStatus, getIdentificationDoc,
    setInitialState,
} from '../../actions/FarmRegistarActions';
import FarmerRegistration from './FarmerRegistration';
import KnowYourCustomer from './KnowYourCustomer';
import FarmIdentificationMaping from './FarmIdentificationMaping';
import InputDistribution from './InputDistribution';
import MonitoringAndEvaluation from './MonitoringAndEvaluation';
import ViewField from '../catastral_data/field/ViewField';
import AnchorCOS from './AnchorCOS';

const styles = {
    card: {
        padding: '10px',
        width: '100%',
        margin: '10px',
        display: 'flex',
        flexWrap: 'wrap',
    },
}

export class FarmersInformations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0
        };
    }

    componentDidMount() {
        this.props.getStates();
        this.props.getLocalGov();
        this.props.getTitle();
        this.props.getMaritalStatus();
        this.props.getIdentificationDoc();
    }


    handleChangeDropDown = name => event => {
        this.setState({ [name]: event.target.value });
        if ([name] == 'id_state') {
            this.props.getLocalGov(event.target.value);
        }
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    render() {
        let user = this.getUser();

        const { editActive, handleClose, id_klijent, farmerObj } = this.props;
        const { value } = this.state;
        let localGoverments = [];
        if (this.props.localGoverments && !this.props.localGovermentsFetching) {
            localGoverments = this.props.localGoverments.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let states = [];
        if (this.props.states && !this.props.statesFetching) {
            states = this.props.states.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let titule = [];
        if (this.props.title && !this.props.titleFetching) {
            titule = this.props.title.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            })
        }
        let maritalStatus = [];
        if (this.props.maritalStatus && !this.props.maritalStatusFetching) {
            maritalStatus = this.props.maritalStatus.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            })
        }
        let identificationDoc = [];
        if (this.props.identificationDoc && !this.props.identificationDocFetching) {
            identificationDoc = this.props.identificationDoc.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            })
        }
        let pol = [];
        const polovi = [
            { value: i18n.t('Male') },
            { value: i18n.t('Female') }
        ]
        pol = polovi.map((s, i) => {
            return <option key={s.id} value={s.value}>{s.value}</option>
        })
        let landOwnership = []
        const ownershipArr = [
            { value: i18n.t('Rental') },
            { value: i18n.t('Owned') }
        ]
        landOwnership = ownershipArr.map((s, i) => {
            return <option key={s.id} value={s.value}>{s.value}</option>
        })
        let probability = []
        const probabilityArr = [
            { value: true, label: i18n.t('Yes') },
            { value: false, label: i18n.t('No') }
        ]
        probability = probabilityArr.map((s, i) => {
            return <option key={s.id} value={s.value}>{s.label}</option>
        })
        let farmerRating = []
        const ratingArray = [
            { value: i18n.t('None') },
            { value: i18n.t('Low') },
            { value: i18n.t('Medium') },
            { value: i18n.t('High') }
        ]
        farmerRating = ratingArray.map((s, i) => {
            return <option key={s.id} value={s.value}>{s.value}</option>
        })

        return (
            <div style={{ padding: 20 }}>
                <Dialog
                    open={editActive}
                    onClose={handleClose}
                    disableBackdropClick
                    maxWidth="lg"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('Data capture fields')}
                        <IconButton onClick={handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                            {user.id_klijent_grupa === 1
                                ?
                                <div>
                                    <Tabs className="tabContainer" value={value} onChange={this.handleChange}
                                        scrollButtons="auto"
                                        indicatorColor="primary"
                                        variant="scrollable">
                                        <Tab className={value == 0 ? "tabs" : ""} label={i18n.t("Farmer Registration")} />
                                    </Tabs>
                                    {value === 0 && <FarmerRegistration id_klijent={id_klijent} states={states} localGoverments={localGoverments} />}
                                </div>
                                :
                                <div>
                                    <Tabs className="tabContainer" value={value} onChange={this.handleChange}
                                        scrollButtons="auto"
                                        indicatorColor="primary"
                                        variant="scrollable">
                                        <Tab className={value == 0 ? "tabs" : ""} label={i18n.t("Farmer Registration")} />
                                        <Tab className={value == 1 ? "tabs" : ""} label={i18n.t("Farm Identification and Mapping")} />
                                        <Tab className={value == 2 ? "tabs" : ""} label={i18n.t("Know Your Customer(KYC)")} />
                                        <Tab className={value == 3 ? "tabs" : ""} label={i18n.t("Input distribution")} />
                                        <Tab className={value == 4 ? "tabs" : ""} label={i18n.t("Monitoring and Evaluation Visits")} />
                                        <Tab className={value == 5 ? "tabs" : ""} label={i18n.t("Fields")} />
                                        <Tab className={value == 6 ? "tabs" : ""} label={i18n.t("Anchor/COS")} />
                                    </Tabs>
                                    {value === 0 && <FarmerRegistration id_klijent={id_klijent} states={states} localGoverments={localGoverments} />}
                                    {value === 1 && <FarmIdentificationMaping id_klijent={id_klijent} probability={probability} landOwnership={landOwnership} />}
                                    {value === 2 && <KnowYourCustomer id_klijent={id_klijent} states={states} maritalStatus={maritalStatus} pol={pol} identificationDoc={identificationDoc} localGoverments={localGoverments} />}
                                    {value === 3 && <InputDistribution id_klijent={id_klijent} />}
                                    {value === 4 && <MonitoringAndEvaluation farmerRating={farmerRating} id_klijent={id_klijent} />}
                                    {value === 5 && <ViewField id_klijent_zakupac={id_klijent} />}
                                    {value === 6 && <AnchorCOS id_klijent={id_klijent} farmerObj={farmerObj} />}
                                </div>
                            }



                            {/* <Grid item xs>
                                <Button
                                    style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white' }}
                                    onClick={() => handleClose}
                                >
                                    {i18n.t('Add')}
                                </Button>
                            </Grid>
                            <Grid item xs={3}>
                            </Grid> */}
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        localGoverments: state.farmRegistarReducer.localGoverments,
        localGovermentsFetching: state.farmRegistarReducer.localGovermentsFetching,
        localGovermentsFetchingFailed: state.farmRegistarReducer.localGovermentsFetchingFailed,
        title: state.farmRegistarReducer.title,
        farmPostingFailed: state.farmRegistarReducer.farmPostingFailed,
        titleFetching: state.farmRegistarReducer.titleFetching,
        titleFetchingFailed: state.farmRegistarReducer.titleFetchingFailed,
        maritalStatus: state.farmRegistarReducer.maritalStatus,
        maritalStatusFetching: state.farmRegistarReducer.maritalStatusFetching,
        maritalStatusFetchingFailed: state.farmRegistarReducer.maritalStatusFetchingFailed,
        identificationDoc: state.farmRegistarReducer.identificationDoc,
        identificationDocFetching: state.farmRegistarReducer.identificationDocFetching,
        identificationDocFetchingFailed: state.farmRegistarReducer.identificationDocFetchingFailed,
        states: state.administrationReducer.states,
        statesFetching: state.administrationReducer.statesFetching,
        statesFetchingFailed: state.administrationReducer.statesFetchingFailed,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getStates: () => dispatch(getStates()),
        getLocalGov: (id_state) => dispatch(getLocalGov(id_state)),
        getTitle: () => dispatch(getTitle()),
        setInitialState: (component) => dispatch(setInitialState(component)),
        getIdentificationDoc: () => dispatch(getIdentificationDoc()),
        getMaritalStatus: () => dispatch(getMaritalStatus()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FarmersInformations)