import immutable from 'seamless-immutable';
import { Type as LayerType } from '../../actions/catastral_data/LayerActions';


export const INITIAL_STATE = immutable({
    //sezone
    layerPosting: false,
    layerPostingSuccess: false,
    layerPostingFailed: false,
    layer: [],
    layerObj: {},
    layerFetching: false,
    layerFetchingFailed: false,
    layerDropDown: [],
    layerFetchingDropDown: false,
    layerFetchingFailedDropDown: false,
    layerPages: 1,
    deleted: false,
    deleteLayerFailed: false,

});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case LayerType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;

        case LayerType.ADD_LAYERS_CALL: {
            const layerPosting = true;
            return state.merge({ layerPosting });
            break;
        }

        case LayerType.ADD_LAYERS_SUCCESS: {
            const layerPosting = false;
            const layerPostingSuccess = true;
            return state.merge({ layerPostingSuccess, layerPosting, layerObj: INITIAL_STATE.layerObj })
            break;
        }

        case LayerType.ADD_LAYERS_FAILED: {
            const layerPosting = false;
            const layerPostingFailed = true;
            return state.merge({ layerPosting, layerPostingFailed });
            break;
        }

        case LayerType.GET_VIEW_LAYERS_CALL: {
            const layerFetching = true;
            return state.merge({ layerFetching });
            break;
        }

        case LayerType.GET_VIEW_LAYERS_SUCCESS: {
            const layer = action.data.data;
            const layerFetching = false;
            const layerPages = action.data.total_pages
            return state.merge({ layer, layerFetching, layerPages });
            break;
        }

        case LayerType.GET_VIEW_LAYERS_FAILED: {
            const layerFetchingFailed = true;
            const layerFetching = false;
            return state.merge({ layerFetching, layerFetchingFailed });
            break;
        }

        case LayerType.GET_LAYERS_CALL_DROP_DOWN: {
            const layerFetchingDropDown = true;
            return state.merge({ layerFetchingDropDown });
            break;
        }

        case LayerType.GET_LAYERS_SUCCESS_DROP_DOWN: {
            const layerDropDown = action.data.data;
            const layerFetchingDropDown = false;
            const layerPages = action.data.total_pages
            return state.merge({ layerDropDown, layerFetchingDropDown, layerPages });
            break;
        }

        case LayerType.GET_LAYERS_FAILED_DROP_DOWN: {
            const layerFetchingFailedDropDown = true;
            const layerFetchingDropDown = false;
            return state.merge({ layerFetchingDropDown, layerFetchingFailedDropDown });
            break;
        }


        case LayerType.DELETE_LAYER_SUCCESS: {
            const array = state[action.name].asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ [action.name]: array, deleted: deleted })
            break;

        }

        case LayerType.DELETE_LAYER_FAILED: {
            const deleteLayerFailed = true;
            return state.merge({ deleteLayerFailed });
            break;
        }

        case LayerType.SET_LAYER_ROW_IN_REDUCER: {
            const data = action.rowInfo.asMutable();
            return state.merge({ layerObj: data })
            break;
        }


        default:
            return state;
    }
}