import React from 'react';
import i18n from '../../i18n/i18n';
import axios from '../../utils/AxiosWrapper';
import styles from './OpstinaWeather.module.css';
import moment from 'moment';
import { MoonLoader } from 'react-spinners';
import { LinearProgress } from '@material-ui/core';
import { Degrees } from '../../utils/Degrees';
import NavigationIcon from '@material-ui/icons/Navigation';
import { zoomFormat } from './../Maps/ZoomAndCenter';
import vp from './../../assets/images/vp1.png';

const today = moment().format('YYYY-MM-DD');
const packageName = 'basic-day';
const apiKey = 'OUP4p7JqGlARwkMF';


export default class OpstinaWeather extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            weather: []
        };
    }
    componentDidMount() {
        this.getOpstinaGeometry()
    }

    getOpstinaGeometry = () => {
        axios.get('api/opstina/geom_opstina')
        .then(res => {
            let parsed = (JSON.parse(res.data.data.rows[0].geojson));
            if (parsed.features.length !== 0) {
               
                    let zoom_data = zoomFormat(parsed);
                    if (zoom_data) {
                        this.setState({ center: zoom_data.center},()=> this.getWeatherData());
                    }
            }
        
        })
        .catch(err => console.log(err))
    }

    getWeatherData = async () => {

        const storageWeather = JSON.parse(localStorage.getItem('weather-data')) || {}


        let weatherResult;

        if (Object.keys(storageWeather).length && storageWeather.data.data_day.time[0] === today) {
            weatherResult = storageWeather
            this.setState({
                weather: weatherResult,
                loading: false
            })
        } else {
            weatherResult = await axios.get(`https://my.meteoblue.com/packages/${packageName}?lat=${this.state.center[0]}&lon=${this.state.center[1]}&apikey=${apiKey}`);
            localStorage.setItem('weather-data', JSON.stringify(weatherResult))
            console.log('AXIOS')
           
        }
        this.setState({
            weather: weatherResult,
            loading: false
        })
    }

    render() {
        let weatherData;
if(this.state.weather) {
     weatherData = this.state.weather
}

        return (
            <div className={styles.container} >
                <div className={styles.forecastCard} >
                <img src={vp} style={{maxHeight : '450px'}}/>
                </div>
                {/* {this.state.loading === true && (<div style={{ marginLeft: '40%', marginTop: '10%', backgroundColor:'white' }}><MoonLoader loading={true} size={50} /></div>)
                
                }
                {this.state.loading === false && Object.keys(weatherData).length > 0 && (
                    <div className={styles.forecastCard}>
                        {weatherData.data.data_day.time.map((item, i) => {
                            let time = weatherData.data.data_day.time[i];
                            time = moment(time).format('ddd');
                            time = time.toUpperCase();

                            let icon = weatherData.data.data_day.pictocode[i];
                            icon = `${icon}.svg`;

                            let min = weatherData.data.data_day.temperature_min[i];
                            let max = weatherData.data.data_day.temperature_max[i];

                            let string_degrees = Degrees(weatherData.data.data_day.winddirection[i]);
                            return(<div className={i<6 ? styles.oneCard : styles.oneCard_last} key={i}>
                                <div style={{textAlign:'center'}}>
                                    <p className={styles.titleTime}>{i18n.t(time)}</p>
                                    <img className={styles.icon} src={`./meteoblue/${icon}`} />
                                </div>
                                <div>
                                <div className={styles.temp1}>
                                <p className={styles.tempTitleMax}>MAX</p>
                                <p className={styles.tempValueMax}>{Math.round(max) + '°'}</p>
                                </div>
                                <div className={styles.temp2}>
                                <p className={styles.tempTitleMin}>MIN</p>
                                <p className={styles.tempValueMin}>{Math.round(min) + '°'}</p>
                                </div>
                                </div>

                                            <div className={styles.predictability_div}>
                                                <LinearProgress variant="determinate" value={weatherData.data.data_day.predictability[i]} color='secondary' />
                                                <p className={styles.predictability_tooltip}>{i18n.t('Predictability')}</p>
                                            </div> 
                                            <div className={styles.precipitation_div}>
                                            <img className={styles.rain_icon} src={`./meteoblue/rain.png`} />
                                            <p className={styles.precipitation_probability}>{weatherData.data.data_day.precipitation_probability[i]} %</p>
                                        </div>
                                        <div className={styles.wind_div}>
                                            <NavigationIcon style={{ transform: `rotate(${weatherData.data.data_day.winddirection[i] + 180}deg)`, height: '25px', width: '25px', color: 'rgb(82, 162, 209)' }} />
                                            <p className={styles.wind_degrees}>{string_degrees}</p>
                                            <p className={styles.wind_speed}>{weatherData.data.data_day.windspeed_mean[i]} Km/h</p>
                                        </div>

                                        <div>
                                            <p className={styles.humidity}>{weatherData.data.data_day.relativehumidity_mean[i]} %</p>
                                            <p className={styles.tooltip}>{i18n.t('Humidity')}</p>
                                        </div>
                                        <div>
                                            <p className={styles.pressure}>{weatherData.data.data_day.sealevelpressure_mean[i]} mBar</p>
                                            <p className={styles.tooltip}>{i18n.t('Pressure')}</p>
                                        </div>
                               
                            </div>)
                        })}
                    </div>
                )}
                {this.state.loading === false && Object.keys(weatherData).length === 0 && (
                    <div>{i18n.t('Error in establishing a MeteoBlue connection')}</div>
                )} */}
              
            </div>
        )
    }
}