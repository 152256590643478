import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import i18n from '../../../i18n/i18n';
import Select from 'react-select';
import { Fab, IconButton } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import { wentWrong } from "../../../utils/ToastConfig";
import axios from '../../../utils/AxiosWrapper';
import ReactSelect from '../../../utils/ReactSelect';
import NumberRangeForFilter from '../../../utils/NumberRangeForFilter';
import DatePickerForFilter from '../../../utils/DatePickerForFilter';
import { withStyles } from '@material-ui/core/styles';
import { ExportEnterprise } from '../../../utils/ExportEnterprise';
import moment from 'moment';

var FileSaver = require('file-saver');
const styles = theme => ({ primaryMain: { backgroundColor: theme.palette.primary.main } });

export class ViewParcelOneFarmer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            layerType: [], seasonType: [], sectorType: [], cropType: [], fieldType: [], statusType: [], woType: [], materialType: [], cropGroupType: [], cropSubgroupType: [],
            productionType: [], localGovType: [],
            // stateType: [],
            showFilters: false, loading: true,
            page: 0, pageSize: 25, sorted: null, filtered: null,
            totalPages: 0, totalData: 0,
            data: [], docTitle: null, user_season: null, export: false, user_layer: null,
            season: null, seasons: []
        };
    }

    componentDidMount() {

        let user = this.getUser();
        this.setState({ user });

        axios.get('api/season/drop_down')
            .then(res => {
                this.setState({ seasonType: res.data.data }, () => {
                    axios.get('api/season/activity_season')
                        .then(res => this.setState({ user_season: res.data.data[0].id_default_sezona, loading: false }, () => {
                            this.getByURL('crop_group/drop_down', 'cropGroupType');
                            this.getByURL('crop_subgroup/drop_down', 'cropSubgroupType');
                            this.getByURL('crop/drop_down', 'cropType');
                            this.getByURL('vrsta_proizvodnje/drop_down', 'productionType');
                            //this.getByURL('opstina/drop_down', 'stateType');
                            this.getByURL('katastarska_opstina/filter_app', 'localGovType');
                        }))
                        .catch(err => { console.log(err) })
                })
            })
            .catch(err => { console.log(err) })
    }

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }
    getData(url, state, instance) {
        this.setState({ loading: true, data: [] });
        axios.get(`api/${url}`, {
            params: { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered, id_klijent: this.props.id_klijent }
        })
            .then(res => {
                this.setState({
                    data: res.data.data,
                    totalPages: res.data.total_pages,
                    totalData: res.data.total,
                }, () => {
                    if (this.state.user_season !== null) this.setState({ loading: false })
                });
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: res.data.data }))
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    }

    exportFunction = () => {
        const table = this.reactTable;
        this.exportDocument(this.state.docTitle, table.state, 'api/report/parcels_app_onefarmer');
        this.setState({ export: !this.state.export });
    };

    exportDocument(title, state, url, filters) {
        let params = { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered, filename: title };
        if (filters) params = { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered, filename: title, filters };

        return axios({ url: url, method: 'GET', responseType: 'blob', params: params })
            .then(response => FileSaver.saveAs(new Blob([response.data]), `${title || 'file'}.xlsx`))
            .catch(err => console.log(err))
    };

    onSearchClick = () => this.setState({ showFilters: !this.state.showFilters });
    onExportClick = () => this.setState({ export: !this.state.export });

    render() {

        let layerType = this.state.layerType.map((item) => { return { label: item.naziv, value: item.id } });
        let cropType = this.state.cropType.map((item) => { return { label: item.naziv, value: item.id } });
        let cropGroupType = this.state.cropGroupType.map((item) => { return { label: item.naziv, value: item.id } });
        let cropSubgroupType = this.state.cropSubgroupType.map((item) => { return { label: item.naziv, value: item.id } });
        let layers = layerType.map(item => item.value);
        let productionType = this.state.productionType.map((item) => { return { label: item.naziv, value: item.id } });
        //let stateType = this.state.stateType.map((item) => { return { label: item.naziv, value: item.id } });
        let localGovType = this.state.localGovType.map((item) => { return { label: item.naziv, value: item.id } });
        let seasonType = this.state.seasonType.map((item) => { return { label: item.naziv, value: item.id } });
        let seasons = seasonType.map(item => item.value);

        const columns = [
            {
                Header: i18n.t('Number'),
                accessor: 'broj'
            }/* ,
            {
                Header: i18n.t('Crop'),
                accessor: 'naziv_kat_kulture',
            } */,
            {
                Header: i18n.t('Land area'),
                accessor: 'povrsina'
            },
            {
                Header: i18n.t('Ownership'),
                accessor: 'vlasnistvo',
                width: 200,
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.ownershipDropDown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Local government'),
                accessor: 'naziv_kat_opstine',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.localGoverments}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Potes'),
                accessor: 'potes'
            },
            {
                Header: i18n.t('Immovable property number'),
                accessor: 'br_lista'
            },
            {
                Header: i18n.t('Class'),
                accessor: 'klasa'
            },
            {
                Header: i18n.t('Klijent'),
                accessor: 'kontakt_osoba',
                width: 200
            },
            {
                Header: i18n.t('Lease from'),
                accessor: 'zakup_od'
            },
            {
                Header: i18n.t('Lease to'),
                accessor: 'zakup_do'
            },

        ];
        // const columns = [
        //     {
        //         Header: i18n.t('Season'), accessor: 'naziv_sezona',
        //         Filter: ({ filter, onChange }) => {
        //             return <ReactSelect
        //                 filter
        //                 isMulti
        //                 name="label"
        //                 onChange={value => { onChange(value) }}
        //                 defaultValue={seasonType.filter((element) => {
        //                     if (seasons.includes(this.state.user_season)) return element.value === this.state.user_season;
        //                 })}
        //                 closeMenuOnSelect={false}
        //                 options={seasonType}
        //                 className="multi-select-in-filter"
        //                 classNamePrefix="select"
        //                 placeholder={i18n.t('Select')}
        //             />
        //         }
        //     },
        //     { Header: i18n.t('Field'), accessor: 'naziv_tabla' },
        //     { Header: i18n.t('Crop group'), accessor: 'naziv_kultura_grupa', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={cropGroupType} /> },
        //     { Header: i18n.t('Crop subgroup'), accessor: 'naziv_kultura_podgrupa', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={cropSubgroupType} /> },
        //     { Header: i18n.t('Crop'), accessor: 'naziv_kultura', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={cropType} /> },
        //     // { Header: i18n.t('State'), accessor: 'naziv_opstina', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={stateType} /> },
        //     { Header: i18n.t('Local government'), accessor: 'naziv_katastarska_opstina', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={localGovType} /> },
        //     { Header: i18n.t('Land area'), accessor: 'povrsina', Filter: ({ filter, onChange }) => <NumberRangeForFilter onChange={(s) => onChange(s)} /> },
        //     { Header: i18n.t('Farmer'), accessor: 'naziv_klijent' },
        //     { Header: i18n.t('BPG'), accessor: 'bpg' },
        //     { id: 'has_activity', Header: i18n.t('Has activities'), accessor: d => d.has_activity, Cell: ({ value }) => value != 0 ? i18n.t('Yes') : i18n.t('No'), width: 75 },
        //     { Header: i18n.t('Type of production'), accessor: 'vrsta_proizvodnje', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={productionType} /> },
        //     { Header: i18n.t('The basis of the lease'), accessor: 'osnov_zakupa' },
        //     { Header: i18n.t('Lease from'), accessor: 'zakup_od' },
        //     { Header: i18n.t('Lease to'), accessor: 'zakup_do' },
        //     { id: 'semenska_proizvodnja', Header: i18n.t('Seed production'), accessor: d => d.semenska_proizvodnja, Cell: ({ value }) => value != 0 ? i18n.t('Yes') : i18n.t('No') },
        //     { id: 'geom_mobile', Header: i18n.t('Geometry'), accessor: d => d.geom_mobile, Cell: ({ value }) => value != null ? i18n.t('Yes') : i18n.t('No') },
        //     { Header: i18n.t('Conversion date'), accessor: 'datum_konverzije' },

        // ];

        const url = 'report/parcels_app_onefarmer';

        const checkboxProps = {
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) { return { style: { backgroundColor: "inherit", alignItems: 'flex-end' } }; }
                return {};
            },
            getTheadFilterProps: () => { return { style: { backgroundColor: '#3E9571' } }; },
            getTheadTrProps: () => { return { style: { backgroundColor: '#04764E', color: '#FFFFFF' } }; },
        };

        const filterOptions = {
            defaultFiltered: [
                {
                    id: 'naziv_layer',
                    value: layerType.filter((element) => {
                        if (layers.includes(this.state.user_layer)) return element.value === this.state.user_layer;
                    })
                }
            ]
        };

        return (
            <div className="page">
                {this.props.isParcelShown ?
                    <div>
                        <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('Farmer')}
                            <IconButton onClick={this.props.handleCloseParcel}>
                                <span className="icon-cancel-circle closeIcon" />
                            </IconButton>
                        </DialogTitle>
                    </div>
                    : null}
                <div className="table-header">
                    <Fab onClick={() => this.onSearchClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button">
                        <span className="icon-search headerIcon" />
                    </Fab>
                    <Fab onClick={() => this.onExportClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button">
                        <span className="icon-download headerIcon" />
                    </Fab>
                </div>
                <ReactTable
                    ref={r => (this.reactTable = r)}
                    getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                    manual
                    data={this.state.data}
                    paging={true}
                    columns={columns}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.state.totalPages}
                    showPaginationTop
                    showPaginationBottom={false}
                    // getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                    onFetchData={(state, instance) => { this.getData(url, state, instance); }}
                    loading={this.state.loading}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    {...filterOptions}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF',
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: '#4FA2CD',
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
                {this.state.export && <ExportEnterprise
                    export={this.state.export}
                    onTextChange={({ title }) => { this.setState({ docTitle: title }); }}
                    handleClose={this.onExportClick}
                    exportDoc={this.exportFunction} />
                }
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ViewParcelOneFarmer);

function FilterSelect(props) {
    return <Select
        isMulti
        name="naziv"
        onChange={value => { props.onChange(value) }}
        closeMenuOnSelect={false}
        options={props.type}
        className="multi-select-in-filter"
        classNamePrefix="select"
        placeholder={i18n.t('Select')}
    />
}