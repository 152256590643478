import axios from '../../utils/AxiosWrapper';
export const Type = {

    //ADD
    ADD_PROGRAM_CALL: 'ADD_PROGRAM_CALL',
    ADD_PROGRAM_SUCCESS: 'ADD_PROGRAM_SUCCESS',
    ADD_PROGRAM_FAILED: 'ADD_PROGRAM_FAILED',

    //read
    GET_PROGRAM_READ_CALL: 'GET_PROGRAM_READ_CALL',
    GET_PROGRAM_READ_SUCCESS: 'GET_PROGRAM_READ_SUCCESS',
    GET_PROGRAM_READ_FAILED: 'GET_PROGRAM_READ_FAILED',

    //DELETE
    DELETE_PROGRAM_CALL: 'DELETE_PROGRAM_CALL',
    DELETE_PROGRAM_SUCCESS: 'DELETE_PROGRAM_SUCCESS',
    DELETE_PROGRAM_FAILED: 'DELETE_PROGRAM_FAILED',

    DELETE_PROGRAM_DOCUMENT_CALL: 'DELETE_PROGRAM_DOCUMENT_CALL',
    DELETE_PROGRAM_DOCUMENT_SUCCESS: 'DELETE_PROGRAM_DOCUMENT_SUCCESS',
    DELETE_PROGRAM_DOCUMENT_FAILED: 'DELETE_PROGRAM_DOCUMENT_FAILED',

    //
    GET_PROGRAM_TYPE_CALL: 'GET_PROGRAM_TYPE_CALL',
    GET_PROGRAM_TYPE_SUCCESS: 'GET_PROGRAM_TYPE_SUCCESS',
    GET_PROGRAM_TYPE_FAILED: 'GET_PROGRAM_TYPE_FAILED',

    //
    GET_SECTOR_TYPE_CALL: 'GET_SECTOR_TYPE_CALL',
    GET_SECTOR_TYPE_SUCCESS: 'GET_SECTOR_TYPE_SUCCESS',
    GET_SECTOR_TYPE_FAILED: 'GET_SECTOR_TYPE_FAILED',

    //
    GET_INSTITUTION_CALL: 'GET_INSTITUTION_CALL',
    GET_INSTITUTION_SUCCESS: 'GET_INSTITUTION_SUCCESS',
    GET_INSTITUTION_FAILED: 'GET_INSTITUTION_FAILED',
    //VRSTA_ENTERPRAJSA
    GET_ENTERPRIES_TYPE_CALL: 'GET_ENTERPRIES_TYPE_CALL',
    GET_ENTERPRIES_TYPE_SUCCESS: 'GET_ENTERPRIES_TYPE_SUCCESS',
    GET_ENTERPRIES_TYPE_FAILED: 'GET_ENTERPRIES_TYPE_FAILED',

    PROGRAM_DOCUMENTS_CALL: 'PROGRAM_DOCUMENTS_CALL',
    PROGRAM_DOCUMENTS_SUCCESS: 'PROGRAM_DOCUMENTS_SUCCESS',
    PROGRAM_DOCUMENTS_FAILED: 'PROGRAM_DOCUMENTS_FAILED',

    //DASHBOARD
    GET_PROGRAM_DASHBOARD_CALL: 'GET_PROGRAM_DASHBOARD_CALL',
    GET_PROGRAM_DASHBOARD_SUCCESS: 'GET_PROGRAM_DASHBOARD_SUCCESS',
    GET_PROGRAM_DASHBOARD_FAILED: 'GET_PROGRAM_DASHBOARD_FAILED',

    SEND_PROGRAM_DOCUMENT_CALL: 'SEND_PROGRAM_DOCUMENT_CALL',
    SEND_PROGRAM_DOCUMENT_SUCCESS: 'SEND_PROGRAM_DOCUMENT_SUCCESS',
    SEND_PROGRAM_DOCUMENT_FAILED: 'SEND_PROGRAM_DOCUMENT_FAILED',

    //SET
    SET_PROGRAM_ROW_IN_REDUCER: 'SET_PROGRAM_ROW_IN_REDUCER',
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',
};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function getProgramType() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_PROGRAM_TYPE_CALL
        });

        axios.get('api/program_type/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_PROGRAM_TYPE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_PROGRAM_TYPE_FAILED
                });
            });
    }
}

export function getEnterpriesType() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_ENTERPRIES_TYPE_CALL
        });

        axios.get('api/vrsta_enterprajsa/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_ENTERPRIES_TYPE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_ENTERPRIES_TYPE_FAILED
                });
            });
    }
}

export function getSectorType() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_SECTOR_TYPE_CALL
        });

        axios.get('api/sector_type/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_SECTOR_TYPE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_SECTOR_TYPE_FAILED
                });
            });
    }
}

export function getInstitution() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_INSTITUTION_CALL
        });

        axios.get('api/institution/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_INSTITUTION_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_INSTITUTION_FAILED
                });
            });
    }
}


export function getProgramRead(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_PROGRAM_READ_CALL
        });
        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/program/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_PROGRAM_READ_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_PROGRAM_READ_FAILED
                });
            });
    }
}

export function deleteProgram(selection) {
    return (dispatch) => {

        dispatch({
            type: Type.DELETE_PROGRAM_CALL,
        });

        axios.post(`api/program/delete`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_PROGRAM_SUCCESS,
                    data: response.data,
                    forDelete: selection
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_PROGRAM_FAILED
                });
            });
    }
}

export function addProgram(programObj, acceptedDocuments) {
    return (dispatch) => {
        dispatch({
            type: Type.ADD_PROGRAM_CALL
        });
        // console.log('ssss', acceptedDocuments);

        axios.post('api/program/create', programObj)
            .then(function (response) {
                if (!!acceptedDocuments && acceptedDocuments.length != 0) {
                    var data = new FormData();

                    acceptedDocuments.map(doc => {
                        data.append('id_program', response.data.data.id);
                        data.append('document', doc);
                    })

                    axios({
                        url: `api/program/upload_document`,
                        method: 'POST',
                        onUploadProgress: function (progressEvent) {
                            console.log('wwww', Math.round((progressEvent.loaded * 100) / progressEvent.total));
                        },
                        data: data,
                        headers: { 'Content-Type': 'multipart/form-data' }
                    }).then((resp) => {
                        dispatch({
                            type: Type.SEND_PROGRAM_DOCUMENT_SUCCESS
                        });
                        dispatch({
                            type: Type.ADD_PROGRAM_SUCCESS,
                            data: response.data
                        });
                    }).catch(function (error) {
                        dispatch({
                            type: Type.SEND_PROGRAM_DOCUMENT_FAILED
                        })
                    });
                } else {
                    dispatch({
                        type: Type.SEND_PROGRAM_DOCUMENT_SUCCESS
                    });
                    dispatch({
                        type: Type.ADD_PROGRAM_SUCCESS,
                        data: response.data
                    });
                }

            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_PROGRAM_FAILED
                });
            });
    }
}


export function setRowInReducer(rowInfo) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_PROGRAM_ROW_IN_REDUCER,
            rowInfo: rowInfo
        })
    }
}

export function getProgramDocuments(id_program) {
    return (dispatch) => {

        dispatch({
            type: Type.PROGRAM_DOCUMENTS_CALL,
        });

        axios.get(`api/program/document?id_program=${id_program}`)
            .then(function (response) {
                dispatch({
                    type: Type.PROGRAM_DOCUMENTS_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.PROGRAM_DOCUMENTS_FAILED
                });
            });
    }
}

export function deleteProgramDocument(document) {
    return (dispatch) => {

        dispatch({
            type: Type.DELETE_PROGRAM_DOCUMENT_CALL,
        });

        return axios.post(`api/program/document_delete`, document)
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_PROGRAM_DOCUMENT_SUCCESS,
                    data: response.data,
                    forDelete: document.id
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_PROGRAM_DOCUMENT_FAILED
                });
            });
    }
}

export function getProgramDashboard(filters) {
    return (dispatch) => {
        dispatch({
            type: Type.GET_PROGRAM_DASHBOARD_CALL,
        });

        return axios.get('api/program/dashboard', { params: { filters } })
            .then(function (response) {
                dispatch({
                    type: Type.GET_PROGRAM_DASHBOARD_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_PROGRAM_DASHBOARD_FAILED
                });
            });
    }
}