import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Button, IconButton, DialogTitle, Fab } from '@material-ui/core';
import i18n from '../../i18n/i18n';
import { toast } from 'react-toastify';
import Select from 'react-select';
import {
    getPublicProcurement, getBasisOfLeaseDropDown, setInitialState, deletePublicProcurement,
    getPublicProcurementById
} from '../../actions/auction/PublicProcurementActions';
import PublicProcurementMain from './PublicProcurementMain';
import { getSeasonDropDown } from '../../actions/resources/SeasonsActions';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import immutable from 'seamless-immutable';
import { Delete } from '../../utils/Delete';
const CheckboxTable = checkboxHOC(ReactTable);

export class ViewPublicProcurement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            add: false,
            showFilters: false,
            selection: [],
            delete: false,
            selectAll: false,
            name: '',
            edit: false
        };
    }

    componentDidMount() {
        const { clientProfile } = this.props;
        if (this.props.basisOfLeaseDropDown.length == 0) {
            this.props.getBasisOfLeaseDropDown();
        }
        if (this.props.seasonDropDown.length === 0) {
            this.props.getSeasonDropDown();
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            publicProcurement: nextProps.publicProcurement,
            publicProcurementFetching: nextProps.publicProcurementFetching,
            seasonDropDown: immutable.asMutable(nextProps.seasonDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            basisOfLeaseDropDown: immutable.asMutable(nextProps.basisOfLeaseDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
        })
        if (nextProps.deleted === true) {
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getPublicProcurement(wrapped.state);
            this.setState({
                selection: [],
                selectAll: false,
                name: ''
            })
            this.props.setInitialState('publicProcurementObj');
            this.props.setInitialState('deleted');
        }
        if (nextProps.publicProcurementPostingSuccess === true) {
            this.handleAddClick();
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getPublicProcurement(wrapped.state);
            this.props.setInitialState('publicProcurementPostingSuccess');
        }
        if (nextProps.deletePublicProcurementFailed === true) {
            toast.error(i18n.t('This item can not be deleted!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('deletePublicProcurementFailed');
        }
    }

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    handleAddClick = () => {
        if (this.state.edit == true && this.state.add == false) {
            this.setState({ edit: false })
            this.props.setInitialState('publicProcurementObj');
        } else if (this.state.selection.length > 0 && this.state.add == false && this.state.edit == false) {
            this.props.setInitialState('publicProcurementObj');
        }
        this.setState({ add: !this.state.add }, () => {
            if (this.state.add == true && this.state.edit == false) {
                this.setState({
                    selection: [],
                    name: '',
                    selectAll: false,
                })
            }
        });
    };

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
            this.props.setInitialState('publicProcurementObj');
        } else {
            selection.push(key);
        }
        this.setState({ selection, name: 'publicProcurement' });
    };

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection, name: 'publicProcurement' });


    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    onDeleteClick = () => {
        this.setState({ delete: !this.state.delete });
    }

    deleteItem = () => {
        this.props.deletePublicProcurement(this.state.selection);
        this.setState({ delete: false })
    }

    onEditClick() {
        this.setState({ add: !this.state.add, edit: true }, () => {
            this.props.getPublicProcurementById(this.state.selection[0])
        });
    }



    render() {
        const { clientProfile } = this.props;
        const columns = [
            {
                Header: i18n.t('Public bidding code'),
                accessor: 'sifra'
            },
            {
                Header: i18n.t('Season'),
                accessor: 'sezona',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        defaultValue={this.state.seasonDropDown && this.state.seasonDropDown.find(function (element) {
                            if (clientProfile) {
                                return element.value === clientProfile.id_default_sezona;
                            }
                        })}
                        closeMenuOnSelect={false}
                        options={this.state.seasonDropDown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Date'),
                accessor: 'datum'
            },
            {
                Header: i18n.t('Local government'),
                accessor: 'katastarska_opstina',
                // Filter: ({ filter, onChange }) =>
                //     <Select
                //         isMulti
                //         name="naziv"
                //         onChange={value => {
                //             onChange(value)
                //         }}
                //         defaultValue={this.state.seasonDropDown && this.state.seasonDropDown.find(function (element) {
                //             if (clientProfile) {
                //                 return element.value === clientProfile.id_default_sezona;
                //             }
                //         })}
                //         closeMenuOnSelect={false}
                //         options={this.state.seasonDropDown}
                //         className="multi-select-in-filter"
                //         classNamePrefix="select"
                //         placeholder={i18n.t('Select')}
                //     />
            },
            {
                Header: i18n.t('The basis of the lease'),
                accessor: 'osnov_zakupa',
                Filter: ({ filter, onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.basisOfLeaseDropDown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Number of fields'),
                accessor: 'broj_tabli'
            },
            {
                Header: i18n.t('Total area'),
                accessor: 'povrsina_tabli'
            }
        ];
        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "lightgreen" : "inherit"
                        }
                    };
                }
                return {};
            }
        };
        const filterOptions = {
            defaultFiltered:
                [
                    {
                        id: 'sezona',
                        value: clientProfile && this.state.seasonDropDown ? [this.state.seasonDropDown.find(function (element) {
                            return element.value === clientProfile.id_default_sezona;
                        })] : []
                    }
                ]
        };
        return (
            <div className="page">
                {this.props.isParcelShown ?
                    <div>
                        <DialogTitle disableTypography={true} className="responsive-dialog-title">{i18n.t('Parcels')}
                            <IconButton onClick={this.props.handleCloseParcel}>
                                <span className="icon-cancel-circle closeIcon" />
                            </IconButton>
                        </DialogTitle>
                    </div>
                    : null}
                <div className="table-header">
                    {!this.props.klijent_naziv ?
                        <Fab
                            onClick={() => this.handleAddClick()}
                            color="primary"
                            aria-label="add"
                            className="action-button"
                        >
                            <span className="icon-Plus headerIcon" />
                        </Fab> : null}
                    {!this.props.klijent_naziv ?
                        <Fab
                            onClick={() => this.onSearchClick()}
                            color="primary"
                            aria-label="add"
                            className="action-button"
                        >
                            <span className="icon-search headerIcon" />
                        </Fab> : null}
                    {this.state.selection.length > 0 ?
                        <Fab
                            onClick={() => this.onDeleteClick()}
                            color="primary"
                            aria-label="add"
                            className="action-button"
                        >
                            <span className="icon-Kanta headerIcon" />
                        </Fab>
                        : null
                    }
                    {this.state.selection.length === 1 ?
                        <Button
                            onClick={() => this.onEditClick()}
                            variant="contained"
                            color="primary"
                            aria-label="edit"
                            className="action-buttonEdit"
                        >
                            {i18n.t('Edit')}
                        </Button>
                        : null
                    }
                </div>
                <CheckboxTable data={this.props.publicProcurement}
                    ref={r => (this.checkboxTable = r)}
                    keyField={'id'}
                    getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                    columns={columns}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.props.publicProcurementPages}
                    loading={this.props.publicProcurementFetching}
                    manual
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => this.props.getPublicProcurement(state, instance)}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    {...filterOptions}
                />
                {this.state.add && <PublicProcurementMain add={this.state.add} edit={this.state.edit}
                    handleClose={this.handleAddClick} />}
                {this.state.delete && <Delete delete={this.state.delete} handleClose={this.onDeleteClick} deleteItem={this.deleteItem} />}

            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        publicProcurementPostingSuccess: state.publicProcurementReducer.publicProcurementPostingSuccess,
        clientProfile: state.appReducer.clientProfile,
        publicProcurementPages: state.publicProcurementReducer.publicProcurementPages,
        publicProcurementFetching: state.publicProcurementReducer.publicProcurementFetching,
        publicProcurement: state.publicProcurementReducer.publicProcurement,
        deleted: state.publicProcurementReducer.deleted,
        deletePublicProcurementFailed: state.publicProcurementReducer.deletePublicProcurementFailed,
        seasonDropDown: state.seasonReducer.seasonDropDown,
        seasonDropDownFetching: state.seasonReducer.seasonDropDownFetching,
        seasonDropDownFetchingFailed: state.seasonReducer.seasonDropDownFetchingFailed,
        basisOfLeaseDropDown: state.publicProcurementReducer.basisOfLeaseDropDown,
        basisOfLeaseDropDownFetching: state.publicProcurementReducer.basisOfLeaseDropDownFetching,
        basisOfLeaseDropDownFetchingFailed: state.publicProcurementReducer.basisOfLeaseDropDownFetchingFailed,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deletePublicProcurement: (selection, name) => dispatch(deletePublicProcurement(selection, name)),
        setInitialState: (component) => dispatch(setInitialState(component)),
        getSeasonDropDown: () => dispatch(getSeasonDropDown()),
        getBasisOfLeaseDropDown: () => dispatch(getBasisOfLeaseDropDown()),
        getPublicProcurement: (state, instance) => dispatch(getPublicProcurement(state, instance)),
        getPublicProcurementById: (id) => dispatch(getPublicProcurementById(id))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewPublicProcurement)